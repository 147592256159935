import { camelCase, } from 'lodash';
import {
  camelizeKeys,
  snakelizeKeys,
  makePostRequestCreator
} from 'common';

const CommonDisplayPreferences = {
    type: 'text',
    display: true,
    resizable: false,
    filterable: false,
  };
const ListDisplayPreferences={
    mos:{
        width:200
    },
    startTime:{
        width:120
    },
    userId:{
        width: 260
    },
    talkDurationSeconds:{
        width: 125
    },
    direction:{
        width: 250
    }
}
const header_order =['start_time', 'user_id', 'mos', 'talk_duration_seconds', 'direction']
const axiosRepeat = makePostRequestCreator();

export const getUserActivityTable = async (params) => {
  try {
    const { url, ...others } = params;
    const { data } = await axiosRepeat(url, snakelizeKeys(others));
    return normalizeCustomReport(camelizeKeys(data));
  } catch (e) {
    return e;
  }
};

export function normalizeCustomReport(data) {
    const {
      limit,
      offset,
      totalCount,
      tables: [table],
    } = data;

    let _headers = [];
    table.headers.forEach(headerItem =>{
        const index = header_order.indexOf(headerItem.id);
        if(index > -1){
            _headers.splice(index, 0, headerItem)
        }
    })
    return {
      pagination: {
        limit,
        offset,
        totalItems: totalCount,
      },
      sortData: {
        key: camelCase(table.sort.header),
      },
      table: {
        rows: table.rows,
        headers: _headers.filter(Boolean).map(column => {
          const name = camelCase(column.id);
          return {
            name,
            value: name,
            label: column.label,
            sortable: false,
            ...CommonDisplayPreferences,
            ...ListDisplayPreferences[name],
          }
        }),
      },
    };
  }