

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

class SimpleDropdown extends Component {
  static propTypes = {
    /** The initial value of the simple dropdown */
    initialValue: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    /** The initial index of the simple dropdown */
    initialIndex: PropTypes.number,
    /** Array of the options for the simple dropdown */
    options: PropTypes.array,
    /** Handler for when the simple dropdown is changed */
    onChange: PropTypes.func,
    /** The classname of the simple dropdown */
    className: PropTypes.string,
    /** The label of the simple dropdown */
    ariaLabel: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = {
      value: null,
    };
  }

  handleChange = (event) => {
    this.setState({ value: event.target.value });
    this.props.onChange(this.props.options[event.target.value]);
  }

  findInitialIndex = () => {
    const { initialValue, initialIndex } = this.props;
    if (this.state.value) {
      return this.state.value;
    } else if (initialIndex && Number.isInteger(initialIndex) && initialIndex >= 0) {
      return initialIndex;
    } else if (initialValue) {
      return _.findIndex(this.props.options, (opt) => (opt.id === initialValue) || (opt.id === initialValue.id));
    }
    return 0;
  }

  render() {
    const opts = _.map(this.props.options, (option, index) => (
      <option key={index} value={index}>{option.label}</option>
    ));

    return (
      <select
        className={this.props.className || ''}
        value={this.findInitialIndex()}
        onChange={this.handleChange}
        aria-label={this.props.ariaLabel}
      >
        {opts}
      </select>
    );
  }
}

export default SimpleDropdown;
