import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import localStore from 'store2';
import type from 'type-of';
import ReactTimeout from 'react-timeout';
import { DefaultSpinner } from 'components';

import { camelizeKeys } from 'common';
import AppData from 'app_data';
import TileOptionsContainer from 'modules/monitoring/custom_dashboard/tile_options_container';
import { prepareData } from 'modules/reporting';
import Filters from 'modules/reporting/custom_filters';
import Chart from 'components/charts/chart';
import Tree from 'modules/reporting/report_tree';
import SearchablePagination from 'components/searchable_pagination';
import { convertToTable } from 'utils/chart_utils';
import ReportTable from 'components/report_table';
import ChartDropdown from 'components/icon_dropdown';
import Utils from 'utils/common_utils';
import { CustomReportTileControls } from 'components/custom_dashboard';

import { ReactComponent as PopoutImg } from 'img/popout.svg';


class CustomReportTileOptions extends PureComponent {
  static propTypes = {
    tileInfo: PropTypes.shape({
      report: PropTypes.string,
      reportId: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      displayType: PropTypes.string,
      displayTypes: PropTypes.array,
      title: PropTypes.string,
      displayLegend: PropTypes.string,
    }),
    options: PropTypes.array,
    onRemoveItem: PropTypes.func,
    onUpdateItem: PropTypes.func,
    onCancel: PropTypes.func,
  };

  state = {
    reportId: this.props.tileInfo.reportId || this.props.tileInfo.report || '',
    title: this.props.tileInfo.title || '',
    displayTypes: this.props.tileInfo.displayTypes || [],
    displayType: this.props.tileInfo.displayType || '',
    /** Whether or not the legend is displayed or not */
    displayLegend: this.props.tileInfo.displayLegend || 'legendEnabled',
    errors: {},
  };

  onDisplayTypeChange = (value) => {
    const { key: displayType } = value;
    this.setState({
      displayType,
    });
  };

  onLegendChange = (value) => {
    const { id: displayLegend } = value;
    this.setState({
      displayLegend,
    });
  };

  onSelectChange = (value) => {
    const { report_id: reportId } = value;
    AppData.getCustomReport(reportId).then((response) => {
      const { data } = response;
      const displayTypes = this.parseDisplayTypes(data);
      this.setState({
        reportId,
        displayTypes,
        displayType: '',
      });
    });
  };

  onTitleChange = (event) => {
    this.setState({
      title: event.target.value,
    });
  };

  getDisplayTypeOptions = (displayTypes) => displayTypes.map((type) => <option key={type.key} value={type.key}>{type.label}</option>);

  parseDisplayTypes = (data) => {
    const treeLabels = [
      data.reportGroup,
      data.report,
      data.format,
      data.breakdown,
    ];

    let node = Tree.get();
    const treeIndices = [];

    _.each(treeLabels, (key, index) => {
      const ix = _.findIndex(node, { key: key.toLowerCase() });
      treeIndices[index] = ix;
      node = node[ix].items;
    });

    return node;
  };

  validate = (page) => {
    const validators = [
      this.validateReportInfo,
    ];
    return validators[page]();
  };

  validateReportInfo = () => {
    const errors = {};
    if (!this.state.title) {
      errors.title = true;
    }
    if (!this.state.reportId) {
      errors.report = true;
    }
    if (!this.state.displayType) {
      errors.displayType = true;
    }
    if (!this.state.displayLegend) {
      errors.displayLegend = true;
    }
    if (Object.keys(errors).length) {
      this.setState({ errors });
      return false;
    }
    return true;
  };

  renderReportInfo = () => {
    const { options } = this.props;
    return (
      <CustomReportTileControls
        tileInfo={this.state}
        reportOptions={options}
        onTitleChange={this.onTitleChange}
        onReportChange={this.onSelectChange}
        onDisplayTypeChange={this.onDisplayTypeChange}
        onLegendChange={this.onLegendChange}
      />
    );
  };

  render() {
    const { onRemoveItem, onCancel, onUpdateItem } = this.props;
    const pages = [this.renderReportInfo];
    return (
      <TileOptionsContainer
        onRemoveItem={onRemoveItem}
        onCancel={onCancel}
        onUpdate={onUpdateItem.bind(this, this.state)}
        validate={this.validate}
        pages={pages}
      />
    );
  }
}

class CustomReportTile extends PureComponent {
  static propTypes = {
    tileInfo: PropTypes.shape({
      reportId: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      display: PropTypes.string,
      title: PropTypes.string,
      displayLegend: PropTypes.string,
    }).isRequired,
    staticTile: PropTypes.bool,
    timeRange: PropTypes.object.isRequired,
    realTime: PropTypes.bool,
    onEditItem: PropTypes.func,
    onUpdateItem: PropTypes.func,
    editButton: PropTypes.node.isRequired,
    push: PropTypes.func,
  };

  state = {
    filters: null,
    filterLabels: [],
    loading: true,
    intervalID: null,
  };

  componentDidMount() {
    // force update on resize so charts fill width
    window.onresize = _.debounce(() => { this.forceUpdate(); }, 100);

    // Check for URL params, otherwise run from local storage
    this.getReport();

    if (this.props.realTime) {
      const intervalID = setInterval(this.getReport, AppData.user.refresh_rate * 1000);
      this.setState({ intervalID: intervalID });
      return;
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.realTime) {
      this.getReport(nextProps);
      const intervalID = setInterval(() => { this.getReport(nextProps); }, AppData.user.refresh_rate * 1000);
      this.setState({ intervalID: intervalID });
    } else {
      clearInterval(this.state.intervalID);
    }
    if ((!_.isEqual(this.props.tileInfo, nextProps.tileInfo)) ||
        (this.props.timeRange !== nextProps.timeRange) ||
        (this.props.realTime !== nextProps.realTime)) {
      this.setState({}, this.getReport);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const propsChanged = !_.isEqual(nextProps, this.props);
    const stateChanged = !_.isEqual(nextState, this.state);
    return propsChanged || stateChanged;
  }

  componentWillUnmount() {
    window.onresize = null;
  }

  getReport = (props = this.props) => {
    const { tileInfo } = props;
    const reportId = tileInfo.reportId || tileInfo.report;
    let timeRange;
    const display = tileInfo.displayType;

    const onSuccess = (response) => {
      const { data } = response;
      if (data) {
        // conversions to seconds
        if (data.display === 'table') {
          _.each(data.results, (result) => {
            _.each(result.data, (row) => {
              // call log
              if (row.call_duration_seconds) {
                row.call_duration_seconds = Utils.humanizeSeconds(row.call_duration_seconds);
              }

              // talk time report, call keys but category
              if (data.report === 'talk_time') {
                _.each(_.keys(row), (key) => {
                  if (key !== 'category') {
                    row[key] = Utils.humanizeSeconds(row[key]);
                  }
                });
              }
            });
          });
        }
        const reportData = this.prepareData(camelizeKeys(data, ['results']));
        const newState = Object.assign({}, reportData, { displayTypes: this.parseDisplayTypes(data), loading: false });
        this.setState(newState);
      }
    };

    if (props.realTime) {
      timeRange = _.assign({}, props.timeRange);
      delete timeRange.end;
    } else {
      timeRange = props.timeRange;
    }

    if (reportId) {
      AppData.getCustomReportData({ id: reportId, time_ranges: [timeRange], display }).then(onSuccess);
    } else {
      const payload = localStore.get('customReport');
      AppData.getCustomReportData(payload).then(onSuccess);
    }
  };

  setDisplay = (display) => {
    const { tileInfo, onUpdateItem } = this.props;
    this.setState({ display, loading: true }, this.getReport);
    if (onUpdateItem) {
      const newTileInfo = Object.assign({}, tileInfo, { displayType: display.key });
      onUpdateItem(newTileInfo);
    }
  };

  handlePopout = () => {
    const params = {};
    params.componentState = _.merge({}, _.omit(this.props, 'editButton'), this.state);

    AppData.savePopoutParams(params).then((response) => {
      const { data } = response;
      const route = `${process.env.REACT_APP_PUBLIC_PATH || ''}/popout/custom-dashboard/${data.id}`;
      const open = window.open(route);

      // ipad blocks popouts, redirect page
      if (open == null || type(open) === 'undefined') {
        window.location.href = route;
      }
    });
  };

  parseDisplayTypes = (data) => {
    const treeLabels = [
      data.reportGroup,
      data.report,
      data.format,
      data.breakdown,
    ];

    let node = Tree.get();
    const treeIndices = [];

    _.each(treeLabels, (key, index) => {
      const ix = _.findIndex(node, { key: key.toLowerCase() });
      treeIndices[index] = ix;
      node = node[ix].items;
    });

    return node;
  };

  prepareData = prepareData.bind(this);

  callTypes = Filters.flatten();

  render() {
    if (this.state.report) {
      let reportInfo;
      let legendPosition = 'top';
      let tables = [];
      const { editButton, tileInfo } = this.props;
      const { displayLegend } = tileInfo;
      const { loading } = this.state;
      const display = tileInfo.displayType;
      const loader = (
        <DefaultSpinner />
      );

      // TODO error screen
      if (!this.state.results) {
        return <div />;
      }

      if (this.state.report === 'call_duration') {
        legendPosition = 'top';
      } else if (this.state.format === 'period') {
        legendPosition = 'bottom';
      }

      if (display === 'table') {
        if (this.state.results.length > 10) {
          tables = [];
          const tableLabels = [];
          _.each(this.state.results, (result, index) => {
            const config = convertToTable(result, this.state.timeRanges);

            const table = (
              <ReportTable
                key={index}
                className="standard-table custom-table"
                autoSize
                data={config}
              />
            );
            tables.push(table);
            tableLabels.push(config.altHeader);
          });

          tables = (
            <SearchablePagination
              searchOn={tableLabels}
            >
              {tables}
            </SearchablePagination>
          );
        } else {
          tables = _.map(this.state.results, (result, index) => {
            const config = convertToTable(result, this.state.timeRanges);
            return (
              <ReportTable
                key={index}
                className="standard-table custom-table"
                data={config}
              />
            );
          });
        }
        reportInfo = tables;
      } else {
        reportInfo = (
          <Chart
            forceRender
            chart={{ name: display }}
            unit={this.state.unit}
            chartData={this.state.results[0]}
            legendPosition={legendPosition}
            timeRange={this.state.timeRanges[0]}
            title={this.state.title}
            responsive
            legendEnabled={displayLegend === 'legendEnabled'}
          />
        );
      }

      const displayTypes = (
        <ChartDropdown
          defaultValue={this.state.displayTypes.filter((displayType) => displayType.key === tileInfo.displayType)[0] || this.state.displayTypes[0]}
          options={this.state.displayTypes}
          onEdit={this.setDisplay}
        />
      );

      return (
        <div className="custom_dashboard_tile_content">
          <div className="custom_dashboard_tile_header">
            <div className="custom_dashboard_tile_content_title">{tileInfo.title}</div>

            <div className="custom_dashboard_actions">
              {displayTypes}

              <PopoutImg onClick={this.handlePopout} />

              {editButton}
            </div>
          </div>

          {loading ? loader : reportInfo}
        </div>
      );
    }
    return (
      <DefaultSpinner />
    );
  }
}

const CustomReport = ReactTimeout(CustomReportTile);

export { CustomReport };
export default CustomReportTileOptions;
