import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import _ from 'lodash';
import moment from 'moment-timezone';
import cx from 'classnames';
import fattable from 'lib/fattable';
import FileSaver from 'file-saver';

import { convertToTable } from 'utils/chart_utils';

const rowToJSON = (row) => {
  const last = row.length - 1;
  return row.map((inner, index) => {
    if (index !== last) {
      return `"${inner}"`;
    }
    return `"${inner}"\n`;
  });
};

class ReportTable extends Component {
  static propTypes = {
    /** Whether or not to swap the report table */
    swap: PropTypes.bool,
    /** The data to be shown in the report table */
    data: PropTypes.shape({
      swap: PropTypes.bool,
    }),
    /** The container width of the report table */
    containerWidth: PropTypes.number,
    /** The width of the header in the report table */
    headerWidth: PropTypes.number,
    /** The maximum number of rows in the report table */
    maxRows: PropTypes.number,
    /** Whether or not to auto size the report table */
    autoSize: PropTypes.bool,
    /** The height of the report table */
    height: PropTypes.number,
    /** ClassName of the report table */
    className: PropTypes.string,
    /** Is this sample data */
    sample: PropTypes.bool,
  };

  state = {
    rowCount: 0,
  };

  componentWillMount() {
    this.resizeFn = _.debounce(() => {
      this.createTable();
    }, 50);

    window.addEventListener('resize', this.resizeFn);

    this.swap = this.props.swap || this.props.data.swap;
    this.containerWidth = this.props.containerWidth || this.containerWidth;
    this.id = _.uniqueId();
  }

  componentDidMount() {
    this.createTable();
  }

  componentWillReceiveProps(nextProps) {
    this.createTable(false, nextProps);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeFn);
  }

  getWidth = () => this.table.getBoundingClientRect().width - 12;

  computeHeaderWidth = (headers) => {
    // assumes equal header widths based off the largest
    this.headerWidth = this.props.headerWidth || 200;
    this.headerLength = headers.length;

    _.each(headers, (header) => {
      if (header) {
        const width = header.toString().length * 10 + 50;
        this.headerWidth = Math.max(width, this.headerWidth);
      }
    });

    if (headers.length * this.headerWidth < 1149) {
      this.headerWidth = this.containerWidth / headers.length;
    }
  };

  containerWidth = 1138;

  createTable = (outputToCSV = false, nextProps = null) => {
    const props = Object.assign({}, nextProps || this.props);
    props.data.outputToCSV = outputToCSV;
    const columnWidths = [];
    let dataSource;
    let headers;
    let rows;

    this.containerWidth = this.getWidth();

    if (!props.data.rows) {
      // serial data
      dataSource = convertToTable(props.data);
    } else {
      dataSource = props.data;
    }

    if (this.swap) {
      headers = _.map(dataSource.headers, (header, index) => header);

      rows = _.map(dataSource.rows, (row, trIndex) => {
        const tds = [].concat(_.map(row.data, (td, tdIndex) => {
          if (row.suffix) {
            td += row.suffix;
          }

          return td;
        }));

        return tds;
      });
    } else {
      rows = [];
      headers = [dataSource.altHeader];
      headers = headers.concat(
        _.map(dataSource.rows, (row, index) => row.data[0])
      );

      _.forEach(dataSource.headers, (header, index) => {
        if (index === 0) {
          return;
        }
        const tds = [header];

        _.forEach(dataSource.rows, (row) => {
          const td = row.data[index];
          tds.push(td);
        });
        rows.push(tds);
      });
    }

    if (outputToCSV) {
      const filename = `analytics-table-${moment().format('YMD')}.csv`;
      const rawData = [headers].concat(rows);
      const csvData = _.map(rawData, (row) => rowToJSON(row));

      if (navigator.msSaveBlob) {
        let finalVal = '';
        for (let i = 0; i < csvData.length; i++) {
          const value = csvData[i];

          for (let j = 0; j < value.length; j++) {
            const innerValue = value[j] === null ? '' : value[j].toString();
            const result = innerValue.replace(/"/g, '"');
            if (j > 0) {
              finalVal += ',';
            }
            finalVal += result;
          }
        }

        return navigator.msSaveBlob(new Blob([finalVal], { type: 'text/csv;charset=utf-8;' }), filename);
      }

      const csvBlob = new Blob(csvData, { type: 'text/csv;charset=utf-8;' });
      FileSaver.saveAs(csvBlob, filename);

      return;
    }

    this.computeHeaderWidth(headers);
    for (let i = 0; i < headers.length; i++) {
      columnWidths.push(this.headerWidth);
    }

    const painter = new fattable.Painter();
    painter.fillCell = (cellDiv, data) => {
      cellDiv.textContent = data.content;
      cellDiv.title = data.content;
      if (data.columnId === 0) {
        cellDiv.className = 'first-column';
      } else {
        cellDiv.className = '';
      }
    };

    painter.fillHeader = (headerDiv, data) => {
      headerDiv.textContent = data.content;
      if (data.columnId === 0) {
        headerDiv.className = 'header-icon';
      } else {
        headerDiv.className = '';
      }
    };

    const tableData = new fattable.SyncTableModel();

    tableData.getCellSync = (i, j) => {
      if (rows[i]) {
        return {
          content: rows[i][j],
          rowId: i,
          columnId: j,
        };
      }
      return {
        content: null,
        rowId: i,
        columnId: j,
      };
    };

    tableData.getHeaderSync = (i) => ({
      content: headers[i],
      columnId: i,
    });
    this.setState({
      rowCount: rows.length,
    }, () => {
      fattable({
        container: `.table${this.id}`,
        model: tableData,
        nbRows: rows.length,
        rowHeight: this.rowHeight,
        headerHeight: this.rowHeight,
        painter,
        columnWidths,
      });
    });
  };

  handleExport = () => {
    this.createTable(true);
  };

  handleSwap = () => {
    this.swap = !this.swap;
    this.createTable();
  };

  headerWidth = 200;
  rowHeight = 40;

  render() {
    let disableHScroll;
    let disableVScroll;
    const style = {};
    const rowCount = this.props.maxRows ? Math.min(this.props.maxRows, this.state.rowCount) : this.state.rowCount;
    const height = (this.rowHeight * rowCount) + this.rowHeight + 15;

    if (this.props.autoSize) {
      style.height = `${height}px`;
      disableVScroll = true;
    } else {
      style.height = this.props.height;
    }


    if (height > style.height) {
      disableVScroll = true;
    }

    if (this.headerWidth * this.headerLength <= this.containerWidth) {
      disableHScroll = true;
    }

    return (
      <div className="report-table">
        <div className="pull-right table-links">
          <div onClick={this.handleSwap}>swap</div>
          <div onClick={this.handleExport}>export</div>
        </div>
        <div
          style={style}
          ref={(node) => { this.table = node; }}
          className={cx(
            {
              [`fat-table fattable table${this.id}`]: true,
              [this.props.className]: true,
              'disable-h-scroll': disableHScroll,
              'disable-v-scroll': disableVScroll,
            }
          )}
        />
        {this.props.sample ?
          <div className="sample-table-overlay">SAMPLE</div>
        : null}
      </div>
    );
  }
}

export default ReportTable;
