import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { AccordionItem } from '@nextiva/next-ui';
import { IntervalRunner } from '@nextiva/analytics-ui';
import localStore from 'store2';
import cx from 'classnames';
import { omit } from 'lodash';

import { getCustomReport } from '../../dataProvider';
import {
  NoData,
  DateRanges,
  ErrorBoundary,
  DefaultSpinner,
  ErrorPlaceholder,
} from 'components';
import AppData from 'app_data';

import Chart from '../Chart';
import Dropzone from 'components/dropzone';

import Styles from './CustomReport.module.scss';

const onSelectDropzone = () => {};


class CustomReport extends PureComponent {
  static propTypes = {
    match: PropTypes.object,
    isExternal: PropTypes.bool,
  };

  static defaultProps = {
    getReport: getCustomReport,
    isExternal: false,
  };

  state = {
    isLoading: true,
    isOpenFilters: false,
  };

  componentDidMount() {
    // Check for URL params, otherwise run from local storage
    this.getReport();
  }

  // the backend sends call log data in a different format for custom reports
  // than it does for the call-log endpoint.  Fix it here until the backend
  // can be fixed.
  formatCallLogData(response) {
    return {
      "tables": [
        {
          "rows": response.data[0],
          "headers": [
            {
              "id": "direction_icon"
            },
            {
              "id": "user_id",
              "label": "Name"
            },
            {
              "id": "start_time",
              "label": "Time of Call",
            },
            {
              "id": "talk_duration_seconds",
              "label": "Duration",
              "duration": true,
            },
            {
              "id": "direction",
              "label": "Direction",
            },
            {
              "id": "connected",
              "label": "Answered",
            },
            {
              "id": "customer_area_code_state",
              "label": "State",
            },
            {
              "id": "calling_number",
              "label": "From",
            },
            {
              "id": "called_number",
              "label": "To",
            }
          ],
          "sort": {
            "header": "start_time"
          }
        }
      ],
      "limit": response.data[0].length,
      "total_count": response.data[0].length,
      "offset": 0
    };
  }

  getReport = async () => {
    const { getReport, match, isExternal } = this.props;
    const { id } = match.params;
    const { realTime, data } = this.state;
    const { timeRanges, format } = data || {};

    const report = Object.assign(
      { id },
      !id && localStore.get('customReport'),
      timeRanges && { timeRanges },
    );

    if (timeRanges) {
      report.timeRanges = realTime
        ? timeRanges.map((timeRange) => omit(timeRange, 'end'))
        : timeRanges;
    }

    this.setState({
      isError: false,
      isLoading: true,
    });

    const response = await getReport(report);

    if (response.isCancel) {
      return;
    }

    if (response.errors) {
      this.setState({
        isError: true,
        isLoading: false,
      });
      return;
    }

    /**
      If the report is external we fix up the call log data that comes from
      the public endpoint for use, otherwise unset is so the component will
      call the call-log endpoint.
    */
    if(response.format === 'call_log') {
        response.data = isExternal ? this.formatCallLogData(response) : undefined;
    }

    this.setState({
      data: response,
      isLoading: false,
    });
  };


  onRealTimeChange = (realTime) => {
    this.setState({
      realTime,
    });
  };


  onDateChange = (timeRanges) => {
    const { data } = this.state;
    this.setState({
      data: {
        ...data,
        timeRanges,
      },
    }, this.getReport);
  };


  onToggleFilters = () => {
    const { isOpenFilters } = this.state;

    this.setState({
      isOpenFilters: !isOpenFilters,
    });
  }


  render() {
    const {
      data,
      realTime,
      isError,
      isLoading,
      isOpenFilters,
    } = this.state;

    const { isDisabledData } = this.props;

    if (!data) {
      return isLoading ? (
        <DefaultSpinner />
      ) : (
        <ErrorPlaceholder isAbsolutePosition />
      )
    } else if (Object.keys(data).length === 0) {
      return (
        <NoData isAbsolutePosition />
      );
    }

    const {
      title,
      timezone,
      timeRanges,
      filterLabels,
    } = data;

    return (
      <div className={Styles.report}>
        <ErrorBoundary>
          {title && (
            <h2 data-testid="custom-report-title" className={Styles.title}>{title}</h2>
          )}

          {timeRanges && (
            <DateRanges
              isRealTime={realTime}
              timeRanges={timeRanges}
              timezone={timezone || AppData.user.timezone}
              onChange={this.onDateChange}
              onRealTimeChange={this.onRealTimeChange}
              disabled={isDisabledData}
              withRealTimeIcon={!isDisabledData}
              theme={{ dateRange: Styles.dateRange }}
            />
          )}

          <IntervalRunner isActive={realTime} action={this.getReport}>
            <div className={cx(Styles.content, { [Styles.chartLoading]: isLoading })}>
              {isLoading && (
                <DefaultSpinner />
              )}

              {isError ? (
                <ErrorPlaceholder />
              ) : (
                <Chart {...data} realTime={realTime} />
              )}
            </div>

            <AccordionItem
              hideBar
              label="Details"
              isOpen={isOpenFilters}
              className={Styles.filters}
              onItemToggleRequest={this.onToggleFilters}
            >
              <Dropzone
                onSelect={onSelectDropzone} // for showing labels without changing
                items={filterLabels}
                perPage={24}
                static
              />
            </AccordionItem>
          </IntervalRunner>
        </ErrorBoundary>
      </div>
    );
  }
}


export default CustomReport;
