import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import AppData from 'app_data';
import AvatarPopup from 'components/avatar_popup';
import vex from 'lib/vex';
import Button from 'components/button';
import { mapStateToProps, mapDispatchToProps } from '../connector';

class UserManagementEdit extends Component {
  static propTypes = {
    /** User management redux state */
    user_management: PropTypes.object,
    /** User management redux actions */
    userManagementActions: PropTypes.object,
    push: PropTypes.func,
  };

  state = {
    avatar: this.props.user_management.avatar,
    isOpen: false,
  };

  componentWillMount() {
    if (!this.props.user_management.userId) {
      this.props.push('/settings/user-management');
    }
  }

  componentWillUnmount() {
    this.props.userManagementActions.clear();
  }

  cancel = () => {
    this.props.push('/settings/user-management');
  };

  handleImageUpdate = (img) => {
    this.setState({
      avatar: img,
      isOpen: false,
    });
  };

  save = () => {
    const { avatar } = this.state;
    const { user_management, userManagementActions } = this.props;
    const { userId } = user_management;

    if (avatar === user_management.avatar) {
      return;
    }
    userManagementActions.saveAvatar(userId, avatar)
      .then(() => {
        AppData.avatars[userId].avatar = avatar;
        this.props.push('/settings/user-management');
      })
      .catch((err) => {
        vex.dialog.open({
          message: `<div class="vex-modal-message">${err.response.data.error}</div>`,
          contentClassName: 'error',
          buttons: [],
        });
      });
  };

  togglePopup = (e) => {
    const { isOpen } = this.state;
    const ignoreClickedClasses = ['.vex-overlay', '.vex-close', '.user_management_popover'];
    if (e.target && ignoreClickedClasses.some((cls => e.target.closest(cls)))) {
      return;
    }
    this.setState({ isOpen: !isOpen });
  };

  validate = () => {
    // validation may not be needed
  };

  render() {
    const { isOpen, avatar } = this.state;
    const { displayName } = this.props.user_management;

    return (
      <div className="manage-user">
        <div className="title">
          <h1>Edit Gamification Settings</h1>
        </div>

        <div className="manage-user-info-container">
          <div className="manage-user-row">
            <div className="manage-user-item manage-user-avatar">
              <Button className="btn-transparent" onClick={this.togglePopup}>
                <AvatarPopup
                  isOpen={isOpen}
                  link={avatar}
                  onOuterAction={this.togglePopup}
                  handleImageUpdate={this.handleImageUpdate}
                >
                  <span>
                    <img className="avatar-lg" src={avatar} alt={`Avatar for ${displayName}`} />
                    <div className="edit-avatar blue">Edit avatar</div>
                  </span>
                </AvatarPopup>
              </Button>
            </div>

            <div className="manage-user-item"><h2>{displayName}</h2></div>
          </div>

          <div className="buttons">
            <Button className="btn" onClick={this.cancel}>Cancel</Button>
            <Button className="btn btn-green" onClick={this.save}>Save</Button>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserManagementEdit);
