import React from 'react';
import PropTypes from 'prop-types';
import ReportPageChart from 'components/charts/report_page_chart';
import QualityUserTable from './component/Quality_user_table';
import _ from 'lodash';


class QualityUserActivity extends React.Component {

  static propTypes = {
    actions: PropTypes.object,
    state: PropTypes.object,
  };

  shouldComponentUpdate(nextProps, nextState) {
    const propsChanged = !_.isEqual(nextProps, this.props);
    const stateChanged = !_.isEqual(nextState, this.state);

    return propsChanged || stateChanged;
  }

  render() {
    const { state } = this.props;
    const chartData = [
      {
        callLogUrl: "/nextos/reports/quality/call-log",
        charts: [
          {icon: "pie.svg", name: "pie", type: "pie"}
        ],
        detail_period_options: [{id: "summary", label: "summary"}],
        detail_url: undefined,
        extra_params: undefined,
        filters: state.apiFilters,
        label: "Mean Opinion Score",
        realTime: state.realTime,
        size: "half",
        timeout: 0,
        timeRange: state.timeRange,
        units: [],
        legends:['Good', 'Moderate', 'Poor'],
        unitSelects: {"mos": [
          {label: "Good", metric: "good"},
          {label: "Moderate", metric: "moderate"},
          {label: "Poor", metric: "poor"},
        ]},
        url: "/nextos/reports/quality/mos/category"
      },
      {
        callLogUrl: "/nextos/reports/quality/call-log",
        charts: [
          {icon: "bar.svg", name: "column", type: "serial"}
        ],
        detail_period_options: [{id: "summary", label: "summary"}],
        detail_url: undefined,
        extra_params: undefined,
        filters: state.apiFilters,
        label: "End Points",
        realTime: state.realTime,
        size: "half",
        timeout: 0,
        timeRange: state.timeRange,
        units: [],
        legends:['Upstream', 'Downstream'],
        unitSelects: {"mos": [
          {label: "App Mobile", metric: "mos"},
          {label: "App Desktop", metric: "mos"},
          {label: "Phone Mobile", metric: "mos"},
        ]},
        url: "/nextos/reports/quality/mos/endpoints",
        columnWidthOverride: '10%',
      },
    ];

    let charts, table;

    /// user has deselected every option in which case there can't be any data.
    if((_.has(state.apiFilters, 'locations') && _.isEmpty(state.apiFilters.locations)) ||
       (_.has(state.apiFilters, 'users') && _.isEmpty(state.apiFilters.users))) {
      charts = null;
      table = <div className="no-data">No data with these options.</div>;
    } else {
      table = <QualityUserTable {...state}/>;
      charts = chartData.map(function(chart, index) {
        return (
          <div key={index} className={`col-${chart.size}`}>
            <ReportPageChart
              timeout={0}
              charts={chart.charts}
              url={chart.url}
              units={chart.units}
              unitSelects={chart.unitSelects}
              title={chart.label}
              timeRange={state.timeRange}
              realTime={state.realTime}
              filters={state.apiFilters}
              popoutButton
              detailUrl={chart.detail_url}
              detailPeriodOptions={chart.detail_period_options}
              callLogUrl={chart.call_log_url}
              extraParams={chart.extra_params}
              legends={chart.legends && chart.legends}
              columnWidthOverride={chart.columnWidthOverride || null}
            />
          </div>
        );
      });
    }

    return (
      <div className="user_activity">
        <div className="reports">
          {charts}
          <div className='col-full'>
            {table}
          </div>
        </div>
      </div>
    );
  }
}

export default QualityUserActivity;
