import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ReportTableContainer from 'components/report_table_container';
import SimpleDropdown from 'components/simple_dropdown';
import DropdownDrilldown from 'components/dropdown_drilldown';

class AnalyticsDetailTables extends Component {
  static propTypes = {
    /** The dropdown period options for a detail table */
    periodOptions: PropTypes.array,
    /** The URL for the table data */
    url: PropTypes.string,
    /** The kind of unit being displayed (calls, minutes, etc...) */
    unit: PropTypes.string,
    /** The selected filter options for the detail table */
    filters: PropTypes.object,
    /** The data fields that are expected to be returned: (start_time,
    user_id, calling_number, called_number, talk_duration_seconds, etc...) */
    selects: PropTypes.array,
    /** The time range being represented by the table */
    timeRange: PropTypes.object,
    /** An array of the time ranges for a chart with multiple time ranges */
    timeRanges: PropTypes.array,
    /** Whether or not the data is in real time */
    realTime: PropTypes.bool,
    /** The initial value of the simple dropdown */
    initialValue: PropTypes.object,
    /** Whether or not the chart is drilling down */
    isDrillingDown: PropTypes.bool,
    /** Whether or not the chart is at the second level of drilling down */
    isSecondLevel: PropTypes.bool,
    /** The chart this table should populate data for. */
    chart: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.state = {
      period: this.props.periodOptions[0],
    };
  }

  handlePeriodChange = (value) => {
    this.setState({ period: value });
  }

  render() {
    let initialValue = this.props.periodOptions[0];
    if (this.props.initialValue) {
      initialValue = this.props.initialValue;
    }

    return (
      <div className="analytics-tables-container">
        <div className="period-select">
          <DropdownDrilldown
            initialValue={initialValue}
            options={this.props.periodOptions}
            onChange={this.handlePeriodChange}
            ariaLabel="Select detail period"
            isDrillingDown={this.props.isDrillingDown}
            isSecondLevel={this.props.isSecondLevel}
          />
        </div>
        <div className="table-container">
          <ReportTableContainer
            url={this.props.url}
            unit={this.props.unit}
            filters={this.props.filters}
            selects={this.props.selects}
            period={this.state.period}
            timeRange={this.props.timeRange}
            timeRanges={this.props.timeRanges}
            realTime={this.props.realTime}
            chart={this.props.chart}
          />
        </div>
      </div>
    );
  }
}

export default AnalyticsDetailTables;
