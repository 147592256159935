import React from 'react';
import PropTypes from 'prop-types';
import AppData from 'app_data';
import { IntervalRunner } from '@nextiva/analytics-ui';
import './attention_table.scss';

class AttentionTable extends React.Component {
  static propTypes = {
    actions: PropTypes.object,
    state: PropTypes.object,
  };

  state = {
    loadingTable: false,
    attentionTable: undefined,
    pagination: {
      limit: 5,
      offset: 0,
      totalItems: 100,
    },
  };

  updateData = (props) => {
    const params = {
      time_range: props.timeRange,
      filters: props.apiFilters,
    };

    this.setState({
      loadingTable: true
    });

    AppData.getProblemLocationData(params)
      .then((response) => {
        this.setState({ attentionTable: response.data.tables[0] });
      });

    this.setState({
      loadingTable: false,
    });
  };

  componentDidMount() {
    this.updateData(this.props.state);
  };

  attentionChart() {
    if (!this.state.attentionTable) {
      return;
    };

    return (
      <IntervalRunner isActive={this.props.state.realTime} action={this.getAttentionTable}>
        <div className="attention_div">
          <div className="header_div">
            <p className="attention_header_div">Needs Attention</p>
            <div className="legend_div">
              <p className="good_legend legend"></p><p>Good</p>
              <p className="moderate_legend legend"></p><p>Moderate</p>
              <p className="poor_legend legend"></p><p>Poor</p>
            </div>
          </div>
          <div><p className="attention_location_header">Location</p></div>
          <div className="attention_table">
            <table>
              <thead>
                <tr className="attention_table_header">
                  {this.state.attentionTable.headers.map((name, index) => (
                    <th className="single_table_header" key={index}>{name.label}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {this.state.attentionTable.rows.map((item, index) => (
                  <tr className="attention_table_row" key={index}>
                    <td className="single_table_row">{item.location}</td>
                    <td className="single_table_row">{item.total_calls}</td>
                    {item.up_mos > 3.8 ? <td className="single_table_row good_value">{item.up_mos}</td> : item.up_mos < 3.5 ? <td className="single_table_row poor_value">{item.up_mos}</td> : <td className="single_table_row moderate_value">{item.up_mos}</td>}
                    {item.down_mos > 3.8 ? <td className="single_table_row good_value">{Math.round(item.down_mos * 100) / 100}</td> : item.down_mos < 3.5 ? <td className="single_table_row poor_value">{Math.round(item.down_mos * 100) / 100}</td> : <td className="single_table_row moderate_value">{Math.round(item.down_mos * 100) / 100}</td>}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </IntervalRunner>
    );
  };

  render() {
    return (
      <div>
        {this.attentionChart()}
      </div>
    );
  };
};

export default AttentionTable;


