import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import { DefaultSpinner } from 'components';

import TeamActions from 'actions/group_actions';
import Filters from 'components/filters';
import Dropzone from 'components/dropzone';
import Button from 'components/button';
import vex from 'lib/vex';

const mapStateToProps = (state) => ({
  teamState: state.team.ui,
});

const mapDispatchToProps = (dispatch) => ({
  push: bindActionCreators(push, dispatch),
  teamActions: bindActionCreators(TeamActions, dispatch),
});


// TODO: add willRoute from current page
class Group extends Component {
  static propTypes = {
    /** View or edit */
    mode: PropTypes.string,
    /** Custom group redux state */
    teamState: PropTypes.object,
    /** Custom group redux actions */
    teamActions: PropTypes.object,
    /** The route that rendered this component */
    route: PropTypes.object,
    /** The dynamic segments of the URL */
    params: PropTypes.object,
    push: PropTypes.func,
    idGroup: PropTypes.string,
  };

  state = {};

  componentWillMount() {
    const { idGroup } = this.props;
    this.hasChange = false;
    this.props.teamActions.getFilters()
      .then(() => {
        this.loadTeam(idGroup);
        this.forceUpdate();
      });
  }


  componentWillReceiveProps(nextProps) {
    const { idGroup } = this.props;
    const { idGroup: newIdGroup } = nextProps;

    if (idGroup !== newIdGroup) {
      this.loadTeam(newIdGroup);
    }
  }

  componentWillUnmount() {
    this.props.teamActions.clear();
  }

  getTeamState = () => ({ ...this.props.teamState })

  displayUnsavedChangesDialog = (callback) => {
    vex.dialog.buttons.YES.text = 'Yes';
    vex.dialog.buttons.NO.text = 'No';
    vex.dialog.confirm({
      message: 'Are you sure you want to cancel changes to this custom group?',
      callback,
    });
  };

  handleCancel = () => {
    this.props.push('/settings/groups');
  };

  handleDescriptionChange = (e) => {
    const { teamState, teamActions } = this.props;
    if (e.target.value !== teamState.description) {
      teamActions.setDescription(e.target.value);
      this.hasChange = true;
    }
  };

  handleDisableAll = () => {
    this.props.teamActions.disableAll();
    this.hasChange = true;
  };

  handleEnableAll = () => {
    this.props.teamActions.enableAll();
    this.hasChange = true;
  };

  handleEnableFilter = (...args) => {
    this.props.teamActions.enableFilter(...args);
    this.hasChange = true;
  };

  handleSelect = (key, index, parentIndex, item) => {
    if (!key) return;
    this.props.teamActions.disableFilter(item.parent, key);
    this.hasChange = true;
  };

  handleTitleChange = (e) => {
    const { teamState } = this.props;
    if (e.target.value !== teamState.title) {
      this.hasChange = true;
      this.props.teamActions.setTitle(e.target.value);
    }
  };

  handleTypeChange = (e) => {
    this.props.teamActions.chooseGroupType(e.target.value);
    this.hasChange = true;
  };

  loadTeam = (idGroup) => {
    const { teamActions } = this.props;
    if (idGroup) {
      teamActions.loadTeam(idGroup);
    } else {
      teamActions.chooseGroupType('users');
    }
  };

  routerWillLeave = (nextLocation) => {
    if (this.hasChange && !this.overrideTransition) {
      const callback = (value) => {
        if (value) {
          this.overrideTransition = true;
          this.props.teamActions.clear();
          this.props.push(nextLocation);
        } else {
          this.overrideTransition = false;
        }
      };
      this.displayUnsavedChangesDialog(callback);
      return false;
    }
  };

  save = () => {
    this.validate(() => {
      const { idGroup } = this.props;
      this.props.teamActions.save(idGroup)
        .then(() => {
          this.overrideTransition = true;
          this.props.push('/settings/groups');
        })
        .catch((err) => {
          vex.dialog.open({
            message: `<div class="vex-modal-message">${err.error_description}</div>`,
            contentClassName: 'error',
            buttons: [],
          });
        });
    });
  };

  validate = (cb, options = {}) => {
    const errors = this.props.teamActions.validate(options);

    if (!errors) {
      cb();
    } else {
      vex.error(errors);
    }
  };

  render() {
    const { teamState: uiState, mode } = this.props;
    const title = mode === 'edit' ? 'Edit group' : 'Create group';
    const boxes = [[]];

    _.each(uiState.dropzones, (dropzone, index) => {
      if (!uiState.filters[dropzone]) return;
      const filters = _.filter(uiState.filters[dropzone].items, { selected: true });

      _.each(filters, (item) => {
        const color = `${item.key}-color`;
        item = _.assign({}, item,
          {
            closeable: true,
            className: `dragged-chart-item ${color}`,
          }
        );

        boxes[index].push(item);
      });
    });

    const options = uiState.selectOptions.map((option) => <option key={option.key} value={option.key}>{option.label}</option>);
    options.unshift(<option key="no_group" value="" disabled>Group by</option>);


    if (uiState.status === 'READY') {
      return (
        <div className="teams-container">
          <div className="manage-team">
            <div className="title">
              <h1>{title}</h1>
            </div>
            <div>
              <Filters
                reducerPath="team.ui"
                enableFilter={this.handleEnableFilter}
              />
            </div>
            <section className="manage-team-info-container">
              <div className="manage-team-info-inputs">
                <input
                  type="text"
                  className={cx({ 'manage-team-info-name': true, error: uiState.errors.title.hasError })}
                  onBlur={this.handleTitleChange}
                  placeholder="Group name"
                  defaultValue={uiState.title}
                />
                <input
                  type="text"
                  className="manage-team-info-description"
                  onBlur={this.handleDescriptionChange}
                  placeholder="Group description"
                  defaultValue={uiState.description}
                />
                <select
                  className="manage-team-info-filter"
                  onChange={this.handleTypeChange}
                  value={uiState.team_type}
                >
                  {options}
                </select>
              </div>
              <Dropzone
                items={boxes[0]}
                onSelect={this.handleSelect}
                enableAll={this.handleEnableAll}
                disableAll={this.handleDisableAll}
                hasError={uiState.errors.filter.hasError}
                perPage={16}
              />
              <div className="buttons">
                <Button
                  className="btn"
                  onClick={this.handleCancel}
                  aria-label="Cancel"
                >
                  Cancel
                </Button>
                <Button
                  className="btn btn-green"
                  onClick={this.save}
                  aria-label="Save"
                >
                  Save
                </Button>
              </div>
            </section>
          </div>
        </div>
      );
    }
    return (
      <DefaultSpinner />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Group);
