

import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { DefaultSpinner } from 'components';

import { serialToPies, fetchChartData, handleRetry } from 'utils/chart_utils';
import Chart from 'components/charts/chart';

class MultiPieContainer extends React.Component {
  static propTypes = {
    /** The chart object */
    chart: PropTypes.object,
    /** The url of the report */
    url: PropTypes.string,
    /** The time period being represented by the chart */
    timeRange: PropTypes.object,
    /** The api filters for the data returned */
    filters: PropTypes.object,
    /** The call data fields that are expected to be returned, an array of objects
    with id, label, metric, and type */
    selects: PropTypes.array,
    /** An array of object, each object is for a 5 minute span in time */
    minuteBuckets: PropTypes.array,
    /** The position of the legend: 'top', 'bottom', 'none' */
    legendPosition: PropTypes.string,
    /** Whether or not to hide the small pie charts */
    hideSmallPies: PropTypes.bool,
  };

  state = {
    loading: true,
    error: false,
    chartData: null,
    chart: this.props.chart,
    timeRange: this.props.timeRange,
    filters: this.props.filters,
  };

  componentDidMount() {
    this.fetchChartData();
  }

  componentWillReceiveProps(newProps) {
    this.setState({ loading: true });
    this.fetchChartData(newProps);
  }

  fetchChartData = fetchChartData.bind(this)

  handleRetry = handleRetry.bind(this)

  render() {
    let charts;
    const data = serialToPies(this.state.chartData || {});
    const ancillaryData = data.slice(1);
    const chartWidth = 100 / ancillaryData.length;

    if (!this.props.hideSmallPies) {
      charts = _.map(ancillaryData, (chart, index) => (
        <div key={index} className="col small-pie-container" style={{ width: `${chartWidth}%` }}>
          {chart.meta.title}
          <Chart
            forceRender
            chart={{ name: 'pie' }}
            className="small-pie"
            chartData={chart}
            legendPosition={'none'}
            pieColor={'#333'}
            pieLabelText={'[[value]]'}
            disableDownload
            title={chart.meta.title}
            timeRange={this.props.timeRange}
          />
        </div>
      ));
    }

    return (
      <div>
        {!this.state.loading ?
          <div className="multi-pie-container">
            <div className="chart-title-container">
              <div className="chart-title">
                <div className="chart-title-bar" />
                {data[0].meta.title}
                <div className="chart-title-bar" />
              </div>
            </div>
            <div className="large-pie-container">
              <Chart
                forceRender
                chart={{ name: 'pie' }}
                chartData={data[0]}
                className="large-pie"
                legendPosition={'top'}
                pieColor={'#333'}
                pieLabelText={'[[value]] Calls'}
                alignLegend={'center'}
                title={data[0].meta.title}
                timeRange={this.props.timeRange}
              />
            </div>
            <div className="row">
              {charts}
            </div>
          </div>
        :
          <DefaultSpinner />
        }
      </div>
    );
  }
}

export default MultiPieContainer;
