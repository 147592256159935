import Wrapper from 'modules/analytics/Wrapper';


const routes = [
  {
    path: '/analytics/summary',
    title: 'Summary',
    component: Wrapper,
    parameter: '/:filterType?/:filter?',
  },
  {
    path: '/analytics/location',
    title: 'Location',
    component: Wrapper,
    parameter: '/:filterType?/:filter?',
  },
  {
    path: '/analytics/call-group',
    title: 'Call Group',
    component: Wrapper,
    parameter: '/:filterType?/:filter?',
  },
  {
    path: '/analytics/user-activity',
    title: 'User Activity',
    component: Wrapper,
    parameter: '/:filterType?/:filter?',
  },
  {
    path: '/analytics/phone-number',
    title: 'Phone Number',
    component: Wrapper,
    parameter: '/:filterType?/:filter?',
  },
  {
    path: '/analytics/custom-group',
    title: 'Custom Group',
    component: Wrapper,
    parameter: '/:filterType?/:filter?',
  },
];


export default routes;
