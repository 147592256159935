import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { flow, sortBy, reverse } from 'lodash/fp';
import FlipMove from 'react-flip-move';
import Slider from 'react-slick';
import ReactTimeout from 'react-timeout';
import { DefaultSpinner } from 'components';

import { LeaderboardRow } from 'modules/monitoring/gamification/leaderboard';
import AppData from 'app_data';
import Utils from 'utils/common_utils';
import { calculateTrend } from 'modules/monitoring/gamification/gamification_mixins';

class Leaderboard extends Component {
  static propTypes = {
    displayType: PropTypes.string.isRequired,
    avatarStyle: PropTypes.string,
    sort: PropTypes.string,
    perPage: PropTypes.number,
    nBuckets: PropTypes.number,
    realTime: PropTypes.bool,
    timeRange: PropTypes.object,
  };

  state = {
    sort: 'desc',
    loading: true,
    data: null,
    trendLabels: null,
    currentSlide: 0,
  };

  componentDidMount() {
    this.fetchData(null, this.props.displayType);
  }

  componentWillReceiveProps(nextProps) {
    clearInterval(this.state.intervalID);

    if (nextProps.displayType &&
       (nextProps.displayType !== this.props.displayType)) {

      this.setState({
        loading: true,
        displayType: nextProps.displayType,
      }, () => {
        this.fetchData(nextProps, this.state.displayType);
      });
    } else {
      this.fetchData(nextProps, nextProps.displayType);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.realTime && prevState.currentSlide !== this.state.currentSlide && this.state.currentSlide === 0) {
      this.fetchData(this.props, this.state.displayType, false);
    }
  }

  getMetric = (displayType, callType) => {
    if (displayType === 'calls') {
      return callType;
    }
    if (callType.metric.includes('_duration')) {
      return callType;
    }
    return { label: callType.label, metric: `${callType.metric}_duration` };
  };

  fetchData = (nextProps, displayType, reLoad = true) => {
    this.setState({
      loading: reLoad,
      displayType,
    });

    const props = nextProps || this.props;
    const metric = this.getMetric(displayType, props.callType);

    const params = {
      time_range: props.timeRange,
      metric,
      n_buckets: props.nBuckets,
      bucket_type: _.snakeCase(props.filterType.id),
      filters: { [_.snakeCase(props.filterType.id)]: _.map(props.filters, 'id') },
      grouping: props.grouping,
      sort: props.sort,
    };

    const onSuccess = (response, tick) => {
      const { data } = response;

      const newData = flow(
        sortBy((x) => x.label),
        sortBy((x) => x.value),
        reverse
      )(data.top_n_buckets);

      this.setState({
        loading: false,
        data: newData,
        trendLabels: tick > 0 ? calculateTrend(this.state.data, newData, this.state.trendLabels) : null,
      });
    };

    let tick = 0;
    if (props.realTime && props.perPage === props.nBuckets) {
      delete params.end;
      AppData.getGamificationTopNByType(params).then((response) => {
        onSuccess(response, tick);
      });
      const intervalID = setInterval(() => {
        tick++;
        AppData.getGamificationTopNByType(params).then((response) => {
          onSuccess(response, tick);
        });
      }, AppData.user.refresh_rate * 1000);
      this.setState({ intervalID: intervalID });
    } else {
      AppData.getGamificationTopNByType(params).then(onSuccess);
    }
  };

  beforeChange = (e) => {
    this.setState({
      currentSlide: e,
    });
  };

  genLeaderboard = () => {
    const max = this.state.data[0].value;
    const min = this.state.data[this.state.data.length - 1].value || 0;
    const maxWidth = 100;
    const minWidth = 80;
    const data = this.state.data.slice(0);
    let enterAnimation = 'fade';
    let leaveAnimation = 'fade';

    if (this.props.sort === 'asc') {
      data.reverse();
      enterAnimation = 'elevator';
      leaveAnimation = 'elevator';
    }
    const items = _.map(data, (row, index) => {
      const width = ((row.value - min) / (max - min)) * (maxWidth - minWidth) + minWidth;
      let userAvatar = null;
      let place;
      if (this.props.sort === 'asc') {
        place = data.length - index;
      } else {
        place = index + 1;
      }
      const suffix = Utils.getSuffix(place);
      if (AppData.avatars[row.id]) {
        userAvatar = AppData.avatars[row.id].avatar;
      }
      return (
        <LeaderboardRow
          key={row.id}
          width={width}
          place={place}
          suffix={suffix}
          name={row.label}
          userAvatar={userAvatar}
          avatarStyle={this.props.avatarStyle}
          displayType={this.props.displayType}
          arrow={this.state.trendLabels && this.state.trendLabels[index]}
          value={row.value}
        />
      );
    });

    const groupedItems = _.chunk(items, this.props.perPage);
    const groupedItemsContent = _.map(groupedItems, (row, index) => (
      <div key={index} className="slick-slide-div">
        <FlipMove staggerDurationBy="30" duration="2000" enterAnimation={enterAnimation} leaveAnimation={leaveAnimation}>
          {row}
        </FlipMove>
      </div>
    ));

    return (
      <Slider
        beforeChange={this.beforeChange}
        arrows={Boolean(false)}
        autoplay={this.props.perPage < this.props.nBuckets}
        autoplaySpeed={30000}
        dots={Boolean(false)}
        draggable={Boolean(false)}
        fade={Boolean(true)}
        infinite={Boolean(true)}
        speed={2000}
        slidesToShow={1}
        slidesToScroll={1}
      >
        {groupedItemsContent}
      </Slider>
    );
  };

  render() {
    let leaderboardRows;
    if (!this.state.loading) {
      if (this.state.data.length) {
        leaderboardRows = this.genLeaderboard();
      } else {
        return (<div className="gamification-no-data">No data with these options.</div>);
      }
    } else {
      leaderboardRows = <DefaultSpinner />;
    }

    return (
      <div className="leaderboard">
        {leaderboardRows}
      </div>
    );
  }
}

export default ReactTimeout(Leaderboard);
