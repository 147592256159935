import React, { Component } from 'react';
import PropTypes from 'prop-types';

class InputCharacterCount extends Component {
  static propTypes = {
    /** The max amount of characters for the input */
    maxCharacters: PropTypes.number,
    /** Function for getting the input on change */
    onChange: PropTypes.func,
  }

  static defaultProps = {
    maxCharacters: 200,
    onChange: () => {},
  }

  constructor(props) {
    super(props);
    this.state = {
      remaining: props.maxCharacters,
    };
  }

  componentWillReceiveProps(newProps) {
    const { maxCharacters } = this.props;
    const { remaining } = this.state;
    const difference = newProps.maxCharacters - maxCharacters;
    this.setState({
      remaining: remaining + difference,
    });
  }

  handleChange = (e) => {
    const { maxCharacters, onChange } = this.props;
    const input = e.target.value;
    onChange(input);
    this.setState({
      remaining: maxCharacters - input.length,
    });
  }

  render() {
    const { maxCharacters } = this.props;
    const { remaining } = this.state;
    return (
      <div>
        <input
          type="text"
          maxLength={maxCharacters}
          onChange={this.handleChange}
        />
        <p>{`${remaining} characters remaining`}</p>
      </div>
    );
  }
}

export default InputCharacterCount;
