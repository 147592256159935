import _ from 'lodash';

const Compare = function (ui) {
  this.ui = ui;
  this.params = { ui };
  const params = this.params;

  this.periodSummary = () => {
    params.rand = this.aggregateRand;
    params.tableLabels = [this.ui.chart.groupLabel, 'Call Type'];
    params.xGrouping = this.getSelects(1);
    if (this.buckets) {
      params.tableLabels = ['Call Type', 'Buckets'];
      params.selects = this.getBuckets();
    } else {
      params.selects = this.getSelects(0);
    }
  };

  this.dailySummary = () => {
    // special case, generate multiple tables
    params.rand = this.aggregateRand;
    params.tableSwap = true;
    params.xGrouping = this.generateDates();
    params.dateFormat = 'YYYY-MM-DD';
    if (this.buckets) {
      params.selects = this.getBuckets();
    } else {
      params.selects = this.getSelects(0);
    }

    const tables = _.map(this.selectedFilters, (user) => {
      params.tableLabels = [user.label, user.label];
      return this.createData();
    });

    // override createData for caller
    this.createData = () => tables;
  };

  this.dailyAverage = () => {
    // identical to period summary except for rand fn
    this.periodSummary();
    params.rand = this.averageRand;
  };
};

export default Compare;
