

import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import cx from 'classnames';

import ChartDropdown from 'components/icon_dropdown';
import { ReactComponent as PopoutImg } from 'img/popout.svg';

const BasicChartControls = (props) => {
  const unitAnchors = _.map(props.units, (unit, index) => (
    <a
      key={index}
      onClick={() => { props.onUnitChange(unit); }}
      className={cx({ unit: true, bold: props.unit === unit })}
    >
      {unit[0].toUpperCase() + unit.slice(1)}
    </a>
  ));

  return (
    <div data-testid="chart-header-controls" className="pull-right">
      {props.charts.length > 1 && (
        <ChartDropdown
          defaultValue={props.charts[0]}
          onEdit={props.onChartChange}
          options={props.charts}
        />
      )}

      {!props.hidePopout && (
        <PopoutImg data-testid="chart-fullscreen-button" className="popout-element" onClick={props.onPopout} aria-disabled={props.disablePopoutButton}/>
      )}
      {!props.hideUnits && unitAnchors}
    </div>
  );
};

BasicChartControls.propTypes = {
  /** The kind of unit that the chart is using */
  unit: PropTypes.string,
  /** The potential units that the chart could use */
  units: PropTypes.array,
  /** The charts that could potentially be displayed */
  charts: PropTypes.array,
  /** A handler for a change in the unit */
  onUnitChange: PropTypes.func,
  /** A handler for an edit to the chart */
  onChartChange: PropTypes.func,
  /** A handler for when the chart popout button is selected */
  onPopout: PropTypes.func,
  /** Whether or not the popout button is displayed */
  hidePopout: PropTypes.bool,
  /** Whether or not the popout button is disabled */
  disablePopoutButton: PropTypes.bool,
  /** Whether or not the Units button is displayed */
  hideUnits: PropTypes.bool,
};

export default BasicChartControls;
