import React from 'react';

import CustomDashboard from 'modules/monitoring/custom_dashboard';

const CustomDashboardView = (props) => (
  <CustomDashboard
    {...props}
    viewing
    isShowIconPopout
  />
);

export default CustomDashboardView;
