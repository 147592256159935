import _ from 'lodash';

const SMSLog = function (ui) {
  this.ui = ui;
  this.params = { ui };
  const params = this.params;

  this.individualCalls = () => {
    params.rand = this.dailyRand;
    params.selects = this.getSelects(0);
    params.items = params.selects;
    params.dates = this.ui.timeRanges[0];
    params.tableSwap = true;
    params.tableLabels = ['Type', 'Type'];

    this.createData = () => this.createSMSLogData(params);
  };
};

export default SMSLog;
