import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { actions } from 'react-redux-form';
import { connect } from 'react-redux';
import validator from 'validator';
import { omit } from 'lodash';

import vex from 'lib/vex';

import { AdditionalTypes } from '../../types';
import {
  saveNote,
  sendEmails,
  getCallRecording,
  saveQuestionGroupAnswers,
} from '../../dataProvider';
import { CallRecording } from '../../components';


const AdditionalTabs = [
  AdditionalTypes.NOTE,
  AdditionalTypes.RATE_CALL,
  AdditionalTypes.EMAIL,
];


class CallRecordingContainer extends PureComponent {

  state = {
    note: '',
    email: '',
    emailErrors: [],
    selectedTab: AdditionalTabs[0],
    isLoading: true,
  };


  async componentDidMount() {
    const {
      groupId,
      endTime,
      bwUserId,
      startTime,
      localCallId,
      externalTrackingId,
    } = this.props.info;

    const params = {
      groupId,
      endTime,
      startTime,
      externalTrackingId,
      userId: bwUserId,
      localCallId: localCallId.replace(/[^0-9:]/g, ''),
    };

    const data = await getCallRecording(params);

    if (data.errorMessage) {
      this.setState({
        isLoading: false,
        errorMessage: data.errorMessage,
      });
    } else {
      this.setState({
        id: data.id,
        note: data.note,
        isLoading: false,
        clipInfo: data.clipInfo,
        streamUrl: data.streamUrl,
      });
    }
  }


  componentDidCatch(error) {
    this.setState({
      errorMessage: error.message,
    });
  }


  onChangeTextArea = (_, note) => {
    this.setState({ note });
  };


  onChangeTab = (selectedTab) => {
    this.setState({
      selectedTab: AdditionalTabs[selectedTab],
    });
  }

  onSaveModal = async (e) => {
    e.preventDefault();

    const {
      id,
      note,
      email,
      clipInfo,
      emailErrors,
      selectedTab,
    } = this.state;

    const {
      userId,
      bwUserId,
      bwRemoteUserId,
      remoteUserId,
      startTime,
      endTime,
      source,
      connected,
      outbound,
      internal,
      externalTrackingId,
     } = this.props.info;

    const params = {
      id,
      note,
      bwUserId,
      externalTrackingId,
      segments: clipInfo || {},
    };

    this.setState({
      isLoadingSaveButton: true,
    });

    const { data, errorMessage } = await saveNote(params);

    if (data) {
      this.setState({
        id: data.id,
      });

      if (selectedTab === AdditionalTypes.NOTE) {
        vex.success('Your changes have been saved');
      }
    } else {
      vex.error([errorMessage]);
      this.setState({
        isLoadingSaveButton: false,
      });
      return;
    }

    switch (selectedTab) {
      case AdditionalTypes.RATE_CALL: {
        const { questionGroup, answers } = this.state;
        const { form, questionsFormActions } = this.props;

        if (!form.touched) {
          vex.error(['You need to select/change options']);
          break;
        }

        const callInfo = {
          bwUserId,
          userId,
          bwRemoteUserId,
          remoteUserId,
          startTime,
          endTime,
          source,
          connected,
          outbound,
          internal,
        };

        const { errorMessage } = await saveQuestionGroupAnswers(
          questionGroup,
          {
            ...omit(answers, 'call_info', 'external_tracking_id'),
            callInfo,
            externalTrackingId,
          }
        );

        if (errorMessage) {
          vex.error([errorMessage]);
        } else {
          questionsFormActions.setSubmitted('questions_group_response');
          vex.success('Your changes have been saved');
        }

        break;
      }

      case AdditionalTypes.EMAIL: {
        if (email.length === 0) {
          vex.error(['Please enter at least one email address']);
          break;
        }

        if (emailErrors.length !== 0) {
          vex.error(['Please enter a correct emails']);
          break;
        }

        const { errorMessage } = await sendEmails({
          emails: email,
          callId: data.id,
          callInfo: this.props.info,
        });

        if (errorMessage) {
          vex.error([errorMessage]);
        } else {
          this.setState({
            email: '',
          });
          vex.success('Your email has been sent successfully.');
        }

        break;
      }

      default:
        break;
    }

    this.setState({
      isLoadingSaveButton: false,
    });
  };


  onClipChange = (clipInfo) => {
    this.setState({
      clipInfo,
    });
  };


  onChangeEmail = (e) => {
    this.setState({
      email: e.target.value,
    });
  }

  onBlurEmail = () => {
    const { email } = this.state;

    if (email.length === 0) {
      this.setState({
        emailErrors: [],
      });
      return;
    }

    const emails = email.split(',');
    const emailErrors = emails.filter((email) =>
      !validator.isEmail(email.trim())
    );

    this.setState({
      emailErrors,
    });
  }

  changeAnswerQuestions = (questionGroup, answers) => {
    this.setState({ questionGroup, answers });
  };


  render() {
    const {
      note,
      email,
      clipInfo,
      isLoading,
      streamUrl,
      emailErrors,
      selectedTab,
      errorMessage,
      isLoadingSaveButton,
    } = this.state;
    const { info, onCloseModal } = this.props;

    return (
      <CallRecording
        note={note}
        email={email}
        title={info.userId}
        clipInfo={clipInfo}
        date={info.startTime}
        isLoading={isLoading}
        streamUrl={streamUrl}
        emailErrors={emailErrors}
        errorMessage={errorMessage}
        phoneNumber={info.userNumber}
        directionIcon={info.directionIcon}
        duration={info.talkDurationSeconds}
        isLoadingSaveButton={isLoadingSaveButton}
        externalTrackingId={info.externalTrackingId}
        textButtonSave={selectedTab === AdditionalTypes.EMAIL
          ? 'Send'
          : 'Save'
        }
        onCloseModal={onCloseModal}
        onBlurEmail={this.onBlurEmail}
        onChangeTab={this.onChangeTab}
        onSaveModal={this.onSaveModal}
        onClipChange={this.onClipChange}
        onChangeEmail={this.onChangeEmail}
        onChangeTextArea={this.onChangeTextArea}
        changeAnswerQuestions={this.changeAnswerQuestions}
      />
    );
  }
}


// TODO: need to replace it after refactoring Question Groups

const mapStateToProps = (state) => ({
  form: state.forms.questions_group_response.$form,
});

const mapDispatchToProps = (dispatch) => ({
  questionsFormActions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CallRecordingContainer);
