

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Draggable from 'react-draggable';

class Volume extends React.Component {
  static propTypes = {
    /** ref='volumeComponent' */
    ref: PropTypes.string,
    /** Handler for when the volume is changed */
    onChange: PropTypes.func,
    /** Handler for when the volume bar is clicked */
    onVolumeBarClick: PropTypes.func,
    /** The volume of the clip */
    volume: PropTypes.number,
    /** The maximum volume possible shown in the UI */
    volumeHeight: PropTypes.number,
  };

  render() {
    const height = Number(this.props.volumeHeight);
    const bottom = height - this.props.volume * height;

    return (
      <div className="volume-container">
        <div ref="volumeProgress" className="volume-progress-container" onClick={this.props.onVolumeBarClick}>
          <div
            style={{
              height: `${this.props.volume * 100}%`,
            }}
            className="volume-progress"
          />
          <Draggable
            axis="y"
            position={{ x: 0, y: bottom }}
            onDrag={this.props.onChange}
            bounds={{ top: 0, bottom: height }}
            zIndex={200}
          >
            <div>
              <div className="handle" />
            </div>
          </Draggable>
        </div>
      </div>
    );
  }
}

export default Volume;
