import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Cropper from 'react-cropper';
import Popover from 'react-popover';

import vex from 'lib/vex';

const MAX_FILE_SIZE = 100000;
const VALID_FILE_TYPES = 'image/jpeg, image/png';
const MAX_HEIGHT = 128;
const MAX_WIDTH = 128;

class AvatarPopup extends Component {
  static propTypes = {
    /** Whether or not the avatar popup is open */
    isOpen: PropTypes.bool.isRequired,
    /** The link that clicking the avatar leads to */
    link: PropTypes.string.isRequired,
    /** Handler for clicking outside of the popup */
    onOuterAction: PropTypes.func.isRequired,
    /** Handler for clicking outside of the popup */
    handleImageUpdate: PropTypes.func.isRequired,
    /** The content of the avatar popout */
    children: PropTypes.node,
  };

  constructor(props) {
    super(props);

    this.state = {
      cropperLink: null,
    };
  }

  getPopoverContent = (link) => {
    const cropperLink = this.state.cropperLink || link;
    const content = (
      <div className="avatar-popup">
        <div className="avatar-crop">
          <Cropper
            ref="cropper"
            src={cropperLink}
            style={{ height: 256, width: 256 }}
            aspectRatio={1 / 1}
            preview=".img-preview"
            guides={false}
            crop={this._crop}
          />
        </div>
        <div className="avatar-controls">
          <h4>Preview</h4>
          <div className="img-preview" />

          <h5>Upload a new image</h5>
          <input
            type="file"
            onChange={this._onChange}
            accept={VALID_FILE_TYPES}
          />

          <p>
            <button className="btn" onClick={this._cropImage}>
              Update image
            </button>
          </p>
        </div>
      </div>
    );
    return content;
  }

  _checkFileSize = (file) => file.size <= MAX_FILE_SIZE;

  _checkFileType = (file) => VALID_FILE_TYPES.includes(file.type);

  _cropImage = () => {
    if (typeof this.refs.cropper.getCroppedCanvas() === 'undefined') {
      return;
    }
    const cropResult = this.refs.cropper.getCroppedCanvas({ width: MAX_WIDTH, height: MAX_HEIGHT }).toDataURL();
    this.props.handleImageUpdate(cropResult);
  }

  _onChange = (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }

    const validFileSize = this._checkFileSize(files[0]);
    if (!validFileSize) {
      vex.dialog.open({
        unsafeMessage: '<div class="vex-modal-message">Select file smaller than 100KB.</div>',
        contentClassName: 'error',
        buttons: [],
      });
      return;
    }

    const validFileType = this._checkFileType(files[0]);
    if (!validFileType) {
      vex.dialog.open({
        unsafeMessage: '<div class="vex-modal-message">Select either a JPG or PNG.</div>',
        contentClassName: 'error',
        buttons: [],
      });
      return;
    }

    const reader = new FileReader();
    reader.onload = () => {
      this.setState({ cropperLink: reader.result });
    };
    reader.readAsDataURL(files[0]);
  }

  render() {
    const { isOpen, link, onOuterAction } = this.props;
    return (
      <Popover isOpen={isOpen} className="user_management_popover" body={this.getPopoverContent(link)} preferPlace="row" onOuterAction={onOuterAction}>
        {this.props.children}
      </Popover>
    );
  }
}

export default AvatarPopup;
