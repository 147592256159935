import _ from 'lodash';
import type from 'type-of';
import update from 'immutability-helper';

import Constants from 'constants.js';
import Filters from 'modules/reporting/custom_filters';
import Utils from 'utils/common_utils';
import appDefaults from './app_defaults';

function team(state = {
  ui: appDefaults.team.ui,
}, action) {
  switch (action.type) {
    case Constants.TEAM_DISABLE_ALL:
    case Constants.TEAM_ENABLE_ALL:
    case Constants.TEAM_ENABLE_FILTER:
    case Constants.TEAM_DISABLE_FILTER:
      return update(state, {
        ui: {
          filters: { $set: filtersReducer(
            state.ui.filters,
            action,
            state.ui.dropzones[0]
          ) },
          errors: { $set: errorsReducer(state.ui, action) },
        },
      });

    case Constants.TEAM_CHOOSE_TYPE: {
      let uiState = {};
      if (action.team_type) {
        uiState = Parser.parse(action.team_type);
      }
      uiState.title = state.ui.title;
      uiState.description = state.ui.description;
      uiState.status = 'READY';

      return _.merge({}, appDefaults.team, {
        ui: uiState,
      });
    }

    case Constants.TEAM_LOAD_TEAM: {
      const data = action.data;
      const ui = Parser.parse(data.team_type);

      ui.title = data.label;
      ui.description = data.description;
      ui.team_type = data.team_type;
      ui.status = 'READY';

      const filterDropzone = ui.dropzones[0];
      const filterSet = new Set(data.members);

      ui.filters[filterDropzone].items = _.map(ui.filters[filterDropzone].items, (filter) => {
        if (filterSet.has(filter.key)) {
          filter.selected = true;
        }

        return filter;
      });

      return _.merge({}, appDefaults.team, { ui });
    }

    case Constants.TEAM_VALIDATE: {
      const errors = _.cloneDeep(appDefaults.team.ui.errors);
      const dropzones = state.ui.dropzones;

      if (!action.options.excludeTitle) {
        // title is required
        if (!(state.ui.title && state.ui.title.length)) {
          errors.title.hasError = true;
        }
      }

      if (dropzones.length === 0) {
        errors.grouping.hasError = true;
      }

      // at least 1 filter
      if (dropzones.length > 0 && !_.filter(state.ui.filters[dropzones[0]].items, { selected: true }).length) {
        errors.filter.hasError = true;
      }

      return update(state, {
        ui: {
          errors: { $set: errors },
        },
      });
    }

    case Constants.TEAM_SET_STATUS:
    case Constants.TEAM_SET_TITLE:
    case Constants.TEAM_SET_DESCRIPTION:
      return update(state, {
        ui: {
          [action.field]: { $set: action.value },
          errors: { $set: errorsReducer(state.ui, action) },
        },
      });

    case Constants.TEAM_CLEAR:
      return appDefaults.team;

    default:
      return state;
  }
}

function errorsReducer(uiState, action) {
  switch (action.type) {
    case Constants.TEAM_SET_TITLE: {
      if (action.value.length && uiState.errors.title.hasError) {
        const title = _.assign({}, uiState.errors.title, {
          hasError: false,
        });

        return _.assign({}, uiState.errors, {
          title,
        });
      }
      return uiState.errors;
    }

    case Constants.TEAM_ENABLE_FILTER:
    case Constants.TEAM_ENABLE_ALL: {
      // ENABLE_FILTER uses action.dropzone, otherwise the 2nd dropzone (filter) is used;
      const dropzone = action.dropzone || uiState.dropzones[0];
      const errorKey = uiState.dropzones.indexOf(dropzone) === 0 ? 'select' : 'filter';

      const errorObj = _.assign({}, uiState.errors[errorKey], {
        hasError: false,
      });

      return _.assign({}, uiState.errors, {
        [errorKey]: errorObj,
      });
    }

    default:
      return uiState.errors;
  }
}

function filtersReducer(state, action, filterDropzone) {
  const { op, dropzone, key } = action;
  const newFilter = _.assign({}, state[dropzone || filterDropzone]);

  switch (action.type) {
    case Constants.TEAM_ENABLE_FILTER:
    case Constants.TEAM_DISABLE_FILTER: {
      const index = _.findIndex(state[dropzone].items, { key });

      newFilter.items = [
        ...state[dropzone].items.slice(0, index),
        ...state[dropzone].items.slice(index + 1),
        _.assign({}, state[dropzone].items[index], {
          selected: op === 'enable',
        }),
      ];

      return update(state, {
        [dropzone]: { $set: newFilter },
      });
    }

    case Constants.TEAM_DISABLE_ALL:
    case Constants.TEAM_ENABLE_ALL:
      newFilter.items = _.map(state[filterDropzone].items, (item, index) => {
        const newItem = _.assign({}, item);
        newItem.selected = action.type === Constants.TEAM_ENABLE_ALL;

        return newItem;
      });

      return update(state, {
        [filterDropzone]: { $set: newFilter },
      });

    default:
      return state;
  }
}

const Parser = {
  validKeys: [
    'availableFilters',
    'dropzones',
    'limitDropzone',
    'dropzoneDefault',
    'dateDefault',
    'minuteBucketsDefault',
    'groupLabel',
  ],
  ui: {},
  defaults: {
    ui: {
      dropzones: [],
      filters: {},
    },
  },
  parse(team_type) {
    team_type = _.cloneDeep(team_type);
    this.ui = _.cloneDeep(this.defaults.ui);

    const team_type_label = Utils.capitalizeWords(team_type.replace('_', ' '));

    // set node to first object in tree
    const node = {
      label: team_type_label,
      key: team_type,
      availableFilters: [{
        copyFrom: team_type,
        key: team_type,
        label: team_type_label,
      }],
      filterBy: {
        type: team_type,
      },
      dropzones: [team_type],
    };

    this.checkKeys(node);

    return this.ui;
  },
  checkKeys(node) {
    _.each(this.validKeys, (key) => {
      if (node[key]) {
        this[`parse${Utils.capitalize(key)}`](node[key]);
      }
    });
  },
  parseDropzoneDefault(arr) {
    _.each(arr, (obj) => {
      let filters = this.ui.filters[obj.dropzone].items;
      // set all filters to false before incase of overwriting
      _.each(filters, (filter) => {
        filter.selected = false;
      });

      filters = obj.fn(filters);

      _.each(filters, (filter) => {
        filter.selected = true;
      });
    });
  },
  parseDateDefault(arr) {
    this.ui.timeRanges = arr;
  },
  parseDropzones(val) {
    this.ui.dropzones = val;
  },
  parseLimitDropzone(arr) {
    _.each(arr, (dropzone) => {
      this.ui.dropzoneLimits[_.snakeCase(dropzone.field)] = dropzone.len;
    });
  },
  parseAvailableFilters(arr) {
    let filterSet;

    _.each(arr, (obj) => {
      let filters;
      const key = obj.key;

      filterSet = {
        key,
        label: obj.label,
        items: [],
      };

      if (type(obj.copyFrom) === 'string') {
        filters = _.cloneDeep(Filters[obj.copyFrom].items);
      } else {
        filters = _.flatten(
          _.map(obj.copyFrom, (k) => _.cloneDeep(Filters[k].items))
        );
      }

      const parentLabel = _.snakeCase(key);

      _.each(filters, (filter) => {
        filter.parent = parentLabel;
        filterSet.items.push(filter);
      });

      const dropzone = filterSet.key;

      this.ui.filters[dropzone] = filterSet;
    });
  },
  parseChart(obj) {
    this.ui.chart.name = obj.name;
  },
};

export default team;
