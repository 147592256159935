import React, { Component,useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import _ from 'lodash';

import { DefaultSpinner } from 'components';
import { ControlsActions } from 'actions/controls_actions';
import QualityControls from 'components/quality_controls';
import QualitySummary from './quality_summary';
import QualityUserActivity  from './quality_user_activity';
import QualityCalls from './quality_calls';
import QualityAdoption from './quality_adoption';


const COMPONENT_MAP = {
  'summary': QualitySummary,
  'location': QualityUserActivity,
  'user-activity': QualityUserActivity,
  'calls': QualityCalls,
  'adoption-and-usage': QualityAdoption,
};

function pathEnd(pathname) { 
  return pathname.split('/').reverse()[0]
} 

const mapStateToProps = ({ controls, router }) => {
  return ({
    section: pathEnd(router.location.pathname),
    controlsState: controls && controls.quality || {},
  });
};

const mapDispatchToProps = (dispatch) => ({
  controlsActions: bindActionCreators(ControlsActions, dispatch),
});

class QualityPage extends Component {
  static propTypes = {
    section: PropTypes.string,
    controlsState: PropTypes.object,
    controlsActions: PropTypes.object,
  };

  componentWillMount() {
    this.getQualityMetadata(this.props.section);
  }

  componentWillReceiveProps(nextProps) {
    if(!this.props.section) {
      push(`/quality/summary`);
      return;
    }

    if (nextProps.section !== this.props.section) {
      this.getAnalyticsMetadata(nextProps.section);
    }
  }

  componentWillUnmount() {
    this.props.controlsActions.setInactive(this.props.section);
  }

  getQualityMetadata = (section) => this.props.controlsActions.getQualityMetadata(section);
  getComponent = (section) => _.get(COMPONENT_MAP, section, QualitySummary);

  shouldStopSpinning = (section, state) => {
    if(state.ready !== true) {
      return false;
    }

    /// everything requires a timeRange.
    if(_.isEmpty(state.timeRange)) {
      return false;
    }

    /// sections with apiFilters should wait for them.
    if((section !== 'summary' && section !== 'adoption-and-usage') && _.isEmpty(state.apiFilters)) {
      return false;
    }

    /// sometimes when switching there is a delay in updating apiFilters and it tries to render
    if((section === 'location' && !_.has(state.apiFilters, 'locations')) ||
       (section === 'user-activity' && !_.has(state.apiFilters, 'users'))
    ) {
      return false;
    }

    return true;
  }

  render() {
    const {
      section,
      controlsState,
      controlsActions,
    } = this.props;

    const Component = this.getComponent(section);

    return (
      <div className="analytics analytics-trend">
        <QualityControls />
        {this.shouldStopSpinning(section, controlsState)
          ? (
            <Component state={controlsState} actions={controlsActions} />
          ) : (
            <DefaultSpinner />
          )
        }
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(QualityPage);
