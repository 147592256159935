const Timezones = [
  { id: 'US/Alaska', label: 'Alaska' },
  { id: 'America/Phoenix', label: 'Arizona' },
  { id: 'America/Chicago', label: 'Central' },
  { id: 'America/New_York', label: 'Eastern' },
  { id: 'US/Hawaii', label: 'Hawaii' },
  { id: 'America/Denver', label: 'Mountain' },
  { id: 'America/Los_Angeles', label: 'Pacific' },
  { id: 'America/Puerto_Rico', label: 'Puerto Rico' },
];

export default Timezones;
