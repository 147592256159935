import { camelCase } from 'lodash';

import { 
  axios, 
  camelizeKeys,
  snakelizeKeys,
  makePostRequestCreator,
} from 'common';
import AppData from 'app_data';
import Utils from 'utils/common_utils';
import { convertToTable } from 'utils/chart_utils';

const axiosRepeat = makePostRequestCreator();

export const getCustomReport = async (params) => {
  try {
    const { data } = await axiosRepeat(
      '/v2/nextos/reports/custom/run',
      snakelizeKeys(params, ['reportGroup']),
    );

    return normalizeCustomReport(camelizeKeys(data, ['results']));
  } catch (e) {
    return e;
  }
};


export const getExternalReport = async ({ id }) => {
  try {
    const { data } = await axios.get(`/nextos/reports/public/${id}`);

    if (!data) {
      return {};
    }

    return normalizeCustomReport(camelizeKeys(data, ['results']));
  } catch (e) {
    return e;
  }
};


export function prepareData(data) {
  const {
    select,
    teamType,
    timeRanges,
    reportGroup,
    displayFilters,
    ...other
  } = data;

  const filterType = camelCase(teamType || reportGroup);
  const filterLabels = displayFilters[filterType]
    .map((item) => ({
      key: item,
      label: item,
    }));

  return {
    ...other,
    select,
    timeRanges,
    filterLabels,
    callTypes: select,
  };
}

function normalizeCustomReport(report) {
  const {
    format,
    display,
    results,
    timezone,
    ...otherProps
  } = report;
  let data = results;
  let legendPosition;

  if (timezone) {
    AppData.setUserTimezone(timezone);
  }

  // conversions to seconds
  if (display === 'table') {
    data = results.map((table) => {
      const data = table.data.map((row) => {
        // call log
        if (row.call_duration_seconds) {
          row.call_duration_seconds = Utils.humanizeSeconds(row.call_duration_seconds);
        }

        // talk time report, call keys but category
        if (row.report === 'talk_time') {
          Object.keys(row).forEach((key) => {
            if (key !== 'category') {
              row[key] = Utils.humanizeSeconds(row[key]);
            }
          });
        }

        return {
          ...row,
        }
      });

      if (format === 'call_log' || format === 'sms_log') {
        return data;
      }

      return convertToTable({ ...table, data }, report.timeRanges);
    });
  }

  if (format === 'period') {
    legendPosition = 'bottom';
  }

  return prepareData({
    ...otherProps,
    data,
    format,
    display,
    timezone,
    legendPosition,
  });
}
