import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Route,
  Switch,
  Redirect,
  withRouter,
} from 'react-router-dom';

import { CustomReport } from 'modules';

import PopOutHandler from 'modules/popout/popout_handler';
import CustomDashboardView from 'modules/monitoring/custom_dashboard_view';
import { DashboardPopout } from 'modules/popout';
import {
  PageNavigation,
  Landing,
  ExternalReports,
} from 'components';
import Wrapper from '../Wrapper';
import routes from './routes';


class Routes extends Component {
  static propTypes = {
    history: PropTypes.object,
  };


  // constructor(props) {
  //   super(props);
  //   const { history } = props;
  //   const { location } = history;
	//
  //   if (location.hash.startsWith('#')) {
  //     history.replace(location.hash.substr(1));
  //   }
  // }

  render() {
    return (
      <Switch>
        <Route path="/popout" component={PopOutHandler.Container} />
        <Route path="/dashboard/:id" component={DashboardPopout} />
        <Route path="/external-reports/:id?" component={ExternalReports} />

        <Wrapper>
          <Switch>
            <Route exact path="/" component={Landing}/>

            {routes.map((route) => {
              const {
                path,
                props,
                redirect,
              } = route;

              // TODO: React.Fragment for <Redirect from to />
              return (
                <Route
                  key={path}
                  path={path} render={({ location }) => (
                  new RegExp(`${path}(\/)?$`).test(location.pathname)
                    ? <Redirect to={redirect}/>
                    : <PageNavigation {...props} />
                )}
                />
              );
            })}

            <Route path="/custom/:id?" component={CustomReport} />
            <Route path="/custom-dashboard/:dashboardId?" component={CustomDashboardView}/>
          </Switch>
        </Wrapper>
      </Switch>
    );
  }
}


export default withRouter(Routes);
