export const CLEAR = 'CUSTOM_DASHBOARD_CLEAR';
export const CANCEL = 'CUSTOM_DASHBOARD_CANCEL';
export const CANCEL_NEW = 'CUSTOM_DASHBOARD_CANCEL_NEW';
export const SET_TITLE = 'CUSTOM_DASHBOARD_SET_TITLE';
export const SET_DESCRIPTION = 'CUSTOM_DASHBOARD_SET_DESCRIPTION';
export const SET_TIME_RANGE = 'CUSTOM_DASHBOARD_SET_TIME_RANGE';
export const SET_REAL_TIME = 'CUSTOM_DASHBOARD_SET_REAL_TIME';
export const SET_STATUS = 'CUSTOM_DASHBOARD_SET_STATUS';
export const VALIDATE = 'CUSTOM_DASHBOARD_VALIDATE';
export const ADD_TILE = 'CUSTOM_DASHBOARD_ADD_TILE';
export const REMOVE_TILE = 'CUSTOM_DASHBOARD_REMOVE_TILE';
export const SET_TILE = 'CUSTOM_DASHBOARD_SET_TILE';
export const UPDATE_TILE = 'CUSTOM_DASHBOARD_UPDATE_TILE';
export const EDIT_TILE = 'CUSTOM_DASHBOARD_EDIT_TILE';
export const LAYOUT_CHANGE = 'CUSTOM_DASHBOARD_LAYOUT_CHANGE';
export const BREAKPOINT_CHANGE = 'CUSTOM_DASHBOARD_BREAKPOINT_CHANGE';
export const LOAD_DASHBOARD_REQUEST = 'CUSTOM_DASHBOARD_LOAD_DASHBOARD_REQUEST';
export const LOAD_DASHBOARD_SUCCESS = 'CUSTOM_DASHBOARD_LOAD_DASHBOARD_SUCCESS';
export const LOAD_DASHBOARD_FAILURE = 'CUSTOM_DASHBOARD_LOAD_DASHBOARD_FAILURE';
export const SAVE_DASHBOARD_REQUEST = 'CUSTOM_DASHBOARD_SAVE_DASHBOARD_REQUEST';
export const SAVE_DASHBOARD_SUCCESS = 'CUSTOM_DASHBOARD_SAVE_DASHBOARD_SUCCESS';
export const SAVE_DASHBOARD_FAILURE = 'CUSTOM_DASHBOARD_SAVE_DASHBOARD_FAILURE';
export const START_NEW_DASHBOARD = 'CUSTOM_DASHBOARD_START_NEW_DASHBOARD';
export const SET_OPTIONS_PAGE = 'CUSTOM_DASHBOARD_SET_OPTIONS_PAGE';
export const TOGGLE_EDIT_DASHBOARD = 'CUSTOM_DASHBOARD_TOGGLE_EDIT_DASHBOARD';
