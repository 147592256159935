import _ from 'lodash';
import type from 'type-of';
import Theme from 'theme';
import qs from 'qs';
import axios from 'axios_client';
import { snakelizeKeys } from 'common';
import Avatar from 'img/xbert_avatar.png';
import configureStore from 'store/configureStore.dev';

const AppData = {
  user: null,
  avatars: {},
  setUserTimezone(timezone) {
    if (!this.user) {
      this.user = {};
    }
    this.user.timezone = timezone;
  },
  logout() {
    const url = '/users/login';
    return axios.delete(url);
  },
  loadUserData() {
    const url = '/users/me';
    return axios.get(url).then(response => {
      const userData = response.data;

      this.user = userData;
      this.user.refresh_rate = 30;
      Theme.setTheme(userData.theme || 'clean');
      return response;
    });
  },
  loadAvatars() {
    const url = '/nextos/all';
    return axios.get(url).then(response => {
      const { data } = response;
      const validTypes = ['Normal', 'Hunt Group'];
      _.each(data, user => {
        if (_.includes(validTypes, user.type)) {
          if (!user.avatar) {
            user.avatar = Avatar;
          }
          this.avatars[user.id] = {
            id: user.id,
            type: user.type,
            avatar: user.avatar,
            label: user.label,
            locationName: user.locationName,
          };
        }
      });
      return response;
    }).catch((e) => {
      console.log(e.message);
    });
  },
  init() {
    return axios.all([this.loadUserData(), this.loadAvatars()]);
  },
  getAllFilters() {
    const url = '/nextos/all-types';
    return axios.get(url);
  },
  getNextosGlobalSettings() {
    const url = '/nextos/settings/global';
    return axios.get(url);
  },
  putNextosGlobalSettings(params) {
    const url = '/nextos/settings/global';
    return axios.put(url, params);
  },
  getNextosThemeSettings() {
    const url = '/nextos/settings/theme';
    return axios.get(url);
  },
  putNextosThemeSettings(params) {
    const url = '/nextos/settings/theme';
    return axios.put(url, params);
  },
  getReportingSectionMetadata(section) {
    const url = `/nextos/reporting/${section}`;
    return axios.get(url);
  },
  getAnalyticsSectionMetadata(filterType, reportType) {
    const url = `/nextos/analytics/${filterType}/${reportType}`;
    return axios.get(url);
  },
  getMonitoringSectionMetadata(reportType) {
    const url = `/nextos/monitoring/${reportType}`;
    return axios.get(url);
  },
  getQualitySectionMetadata(section) {
    const url = `/nextos/quality/${section}`;
    return axios.get(url);
  },

  async getCallFilters() {
    let filterOptions = [{id: "directions", label: [{id: "inbound", label: "Inbound"}, {id: "outbound", label: "Outbound"}]},{ id:"answered", label:[{id:"yes", label:"Yes"},{id:"no", label: "No"}]},{id:"internal", label:[{id:"yes", label:"Yes"},{id:"no" , label:"No"}]},{id:"quality", label:[{id: "poor", label: "Poor"}, {id: "good", label: "Good"}, {id: "moderate", label: "Moderate"}]}];
    const response = await this.getAllFilters()
    const data = response.data;
    const ArrayOfUsersByUnique = [...new Map(data.users.map(item => [item['id'], item])).values()];
    data.users = ArrayOfUsersByUnique;

    Object.keys(data).filter(key => key === "locations" || key === "users").forEach((key) =>{
      filterOptions.unshift({id: key, label: _.map(data[key], (item) => ({
      id: String(item.id), label: item.label,
        }))});
      });
      return Promise.resolve({data: filterOptions});
  },

  async getNextOsType(section) {
    if (section === 'locations') {
      return this.getLocations();
    } else if (section === 'users') {
      return this.getUsers();
    } else if (section === 'call_groups') {
      return this.getCallGroups();
    } else if (section === 'phone_numbers') {
      return this.getPhoneNumbers();
    } else if (section === 'customer-area-code') {
      return this.getCustomerAreaCodes();
    } else if (section === 'teams' || section === 'custom-group') {
      return this.getTeams();
    } else if (section === 'dashboards') {
      return this.getDashboards();
    }
      else if (section === 'calls') {
      return await this.getCallFilters();
    }
    // eslint-disable-next-line no-console
    console.warn(`Invalid NextOsType request: ${section}`);
  },

  getAll() {
    const url = '/nextos/all';
    return axios.get(url);
  },

  getUsers() {
    const url = '/nextos/users';
    return axios.get(url);
  },

  getUser(id) {
    const url = `/nextos/users/${id}`;
    return axios.get(url);
  },

  getTeams() {
    const url = '/nextos/teams';
    return axios.get(url);
  },

  getTeam(id) {
    return axios.get(`/nextos/teams/${id}`);
  },

  saveTeam(payload) {
    let url = '/nextos/teams';
    let teamId = '';
    if (payload.teamId) {
      teamId = `/${payload.teamId}`;
      url += teamId;
      return axios.put(url, payload);
    }
    return axios.post(url, payload);
  },

  deleteTeam(id) {
    return axios.delete(`/nextos/teams/${id}`);
  },

  getTeamReports(id) {
    return axios.get(`/nextos/team/reports/${id}`);
  },

  getDashboards(params) {
    return axios.get('/nextos/dashboards', { params }, { timeout: 1000000 });
  },

  getDashboard(id) {
    return axios.get(`/nextos/dashboard/${id}`);
  },

  saveDashboard(payload) {
    const dashboardId = payload.dashboardId;

    if (dashboardId) {
      return axios.put(`/nextos/dashboard/${dashboardId}`, payload);
    }
    return axios.post('/nextos/dashboards', payload);
  },

  deleteDashboard(id) {
    const url = `/nextos/dashboard/${id}`;
    return axios.delete(url);
  },

  getCallGroups() {
    const url = '/nextos/hunt-groups';
    return axios.get(url);
  },

  getPhoneNumbers() {
    const url = '/nextos/phone-numbers?include_extensions';
    return axios.get(url);
  },

  getLocations() {
    const url = '/nextos/locations';
    return axios.get(url);
  },

  getCustomerAreaCodes() {
    const url = '/nextos/customer-area-codes';
    return axios.get(url);
  },

  getTrendPanelData(params) {
    const url = '/v2/nextos/reports/trendpanels';
    return axios.post(url, params);
  },

  getPeriodPanelData(params) {
    const url = '/v2/nextos/reports/periodpanels';
    return axios.post(url, params);
  },

  getComparisonPanelData(params) {
    const url = '/v2/nextos/reports/comparisonpanels';
    return axios.post(url, params);
  },

  getMonitoringSectionDashboard(params, reportType) {
    const url = `/v2/nextos/reports/monitoring/${reportType}`;
    return axios.post(url, params);
  },

  saveCustomReportQuery(payload) {
    let url = '/nextos/reports/custom';
    let reportId = '';
    const data = snakelizeKeys(payload, ['reportGroup', 'reportId']);

    if (payload.reportId) {
      reportId = `/${payload.reportId}`;
      url += reportId;
      return axios.put(url, data);
    }
    return axios.post(url, data);
  },
  getGamificationTopNByType(params) {
    const url = '/v2/nextos/reports/top-n-buckets-by-metric';
    return axios.post(url, params);
  },

  getTotalCount(params) {
    const url = '/v2/nextos/reports/total-count';
    return axios.post(url, params);
  },

  getCustomReport(id) {
    const url = `/nextos/reports/custom/${id}`;
    return axios.get(url);
  },

  deleteCustomReport(id) {
    const url = `/nextos/reports/custom/${id}`;
    return axios.delete(url);
  },

  getCustomReports() {
    const url = '/nextos/reports/custom';
    return axios.get(url);
  },

  getWallboardSectionData(params, reportType) {
    const url = `/v2/nextos/reports/monitoring/${reportType}`;
    return axios.post(url, params);
  },

  getWallboardSectionMetaData(reportType) {
    const url = `/v2/nextos/monitoring/${reportType}`;
    return axios.get(url);
  },

  getCustomReportData(payload) {
    if (type(payload) === 'string') {
      payload = { id: payload };
    }
    const url = '/v2/nextos/reports/custom/run';
    return axios.post(url, payload);
  },

  getScorecards(params) {
    const url = '/v2/nextos/reports/scorecards';
    return axios.post(url, params);
  },

  getScheduledReports(id) {
    const url = `/nextos/reports/scheduled${id ? `/${id}` : ''}`;
    return axios.get(url);
  },

  getScheduledReportHistory(id) {
    const url = `/nextos/reports/scheduled/history${id ? `/${id}` : ''}`;
    return axios.get(url);
  },

  savePopoutParams(payload) {
    const url = '/nextos/report-popout-params';
    return axios.post(url, payload);
  },
  getPopoutParams(id) {
    const url = `/nextos/report-popout-params/${id}`;
    return axios.get(url);
  },
  saveScheduledReport(payload) {
    let url = '/nextos/reports/scheduled';
    let id = '';
    if (payload.id) {
      id = `/${payload.id}`;
      url += id;
      return axios.put(url, payload);
    }
    return axios.post(url, payload);
  },
  deleteScheduledReport(id) {
    const url = `/nextos/reports/scheduled/${id}`;
    return axios.delete(url);
  },
  getTrendTableData(params) {
    const url = '/v2/nextos/reports/trend-table';
    return axios.post(url, params);
  },
  getComparisonTableData(params) {
    const url = '/v2/nextos/reports/comparison-table';
    return axios.post(url, params);
  },
  getPeriodOverPeriodTableData(params) {
    const url = '/v2/nextos/reports/period-over-period-table';
    return axios.post(url, params);
  },
  getCallLogTableData(params) {
    const url = '/v2/nextos/reports/call-log';
    return axios.post(url, params);
  },
  getProfileImage(id) {
    const url = `/nextos/profile_image/${id}`;
    return axios.get(url);
  },
  saveProfileImage(params) {
    const url = '/nextos/profile_image';
    return axios.post(url, params);
  },
  exportCallLog(params) {
    const url = '/v2/nextos/reports/call-log-csv';
    return axios.post(url, params);
  },
  getCallRecording(queryData) {
    const querystring = qs.stringify(queryData);
    const url = `/nextos/recording/meta?${querystring}`;
    return axios.get(url, { timeout: 30000 });
  },
  saveCallRecording(params) {
    let url = '/nextos/recording/meta';
    if (params.id) {
      const segment = `/${params.id}`;
      url += segment;
      return axios.put(url, params);
    }
    return axios.post(url, params);
  },
  sendCallRecording(params) {
    const url = '/nextos/recording/email';
    return axios.post(url, params);
  },
  getQuestionGroups() {
    const url = '/nextos/question_groups';
    return axios.get(url);
  },
  getQuestionGroup(id) {
    const url = `/nextos/question_groups/${id}`;
    return axios.get(url);
  },
  saveQuestionGroup(params) {
    let url = '/nextos/question_groups';
    if (params.id) {
      url += `/${params.id}`;
      return axios.put(url, params);
    }
    return axios.post(url, params);
  },
  deleteQuestionGroup(id) {
    const url = `/nextos/question_groups/${id}`;
    return axios.delete(url);
  },
  saveQuestionGroupAnswers(group_id, params) {
    let url = `/nextos/question_groups/${group_id}/responses`;
    if (params.id) {
      url += `/${params.id}`;
      return axios.put(url, params);
    }
    return axios.post(url, params);
  },
  getQuestionGroupResponses(group_id) {
    const url = `/nextos/question_groups/${group_id}/responses`;
    return axios.get(url);
  },
  getQuestionGroupResponsesByCall(external_tracking_id) {
    const url = `/nextos/responses?external_tracking_id=${external_tracking_id}`;
    return axios.get(url);
  },
  putNextosHomepageSettings(params) {
    const url = '/nextos/settings/homepage';
    return axios.put(url, params);
  },
  getQualityPanelData(params) {
    const url = '/nextos/reports/quality/summarypanels';
    return axios.post(url, params);
  },
  getProblemLocationData(params) {
    const url = '/nextos/reports/quality/call-log/problem_locations';
    return axios.post(url, params);
  },
  getConnectedCallData(params) {
    const summary_type = params.section === 'adoption-and-usage' ? 'usage' : 'controls';
    const url = `/nextos/reports/quality/${summary_type}/summary`;
    return axios.post(url, params);
  }
};

export default AppData;
