import React from 'react';
import { themr } from '@nextiva/analytics-lib';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Styles from './NoData.module.scss';
import { ReactComponent as AlertImg } from 'img/icons/nx-alert-circle.svg';


function NoData(props) {
  const {
    theme,
    isAbsolutePosition = false,
    description = 'No data with these options.',
  } = props;

  return (
    <div className={cx(theme.noData, {
      [theme.absolutePosition]: isAbsolutePosition,
    })}>
      <AlertImg className={theme.icon} />
      <p className={theme.description}>{description}</p>
    </div>
  )
}


NoData.propTypes = {
  theme: PropTypes.object,
  isAbsolutePosition: PropTypes.bool,
  description: PropTypes.string,
};


export default themr(NoData, Styles);
