import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { themr } from '@nextiva/analytics-lib';

import Styles from './VideoPopup.module.scss';


class VideoPopup extends Component {
  static propTypes = {
    /** The URL of the video */
    videoUrl: PropTypes.string.isRequired,
    /** Height of the window */
    height: PropTypes.number,
    /** Width of the window */
    width: PropTypes.number,
    /** Left position of the window */
    leftPosition: PropTypes.number,
    /** Top position of the window */
    topPosition: PropTypes.number,
    theme: PropTypes.object,
  };

  static defaultProps = {
    width: 1000,
    height: 650,
    leftPosition: 25,
    topPosition: 50,
  };

  handleClick = () => {
    window.open(this.props.videoUrl, 'Window2', `status=no,height=${this.props.height},width=${this.props.width},resizable=yes, \
                left=${this.props.leftPosition},top=${this.props.topPosition},screenX=${this.props.leftPosition}, \
                screenY=${this.props.topPosition},toolbar=no,menubar=no,scrollbars=no,location=no,directories=no`);
  };

  render() {
    const { theme } = this.props;
    return (
      <span data-testid="navigation-videoPopUp" onClick={this.handleClick} className={theme.link} />
    );
  }
}

export default themr(VideoPopup, Styles);
