import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { NoData } from 'components';
import Utils from 'utils/common_utils';
import Theme from 'theme';
import BaseChartMixin from 'components/charts/chart_mixins';


// charts/charts.scss

class PieChart extends Component {
  static propTypes = {
    /** The title of the chart */
    exportTitle: PropTypes.string,
    /** Optional: Will hardcode a chart height */
    height: PropTypes.string,
    /** Whether or not an update is forced */
    forceRender: PropTypes.bool,
    /** A unique identifier for the chart being displayed */
    chartKey: PropTypes.string,
    /** The data to be displayed on the chart */
    chartData: PropTypes.object,
    /** The kind of unit that the chart is using on the value axis (calls, minutes, etc..) */
    unit: PropTypes.string,
    /** The position of the legend */
    legendPosition: PropTypes.string,
    /** The time range that the chart will represent */
    timeRange: PropTypes.object,
    /** Whether or not download is disabled */
    disableDownload: PropTypes.bool,
    /** The classname for the kind of pie chart that will be used */
    className: PropTypes.string,
    /** Alignment of the legend (left, right, etc...) */
    alignLegend: PropTypes.string,
    /** Color of the pie chart label text */
    pieColor: PropTypes.string,
    /** The text in a pie chart label */
    pieLabelText: PropTypes.string,
    /** Whether or not it is a donut chart */
    donut: PropTypes.bool,
    /** The size of the inner radius */
    innerRadius: PropTypes.number,
    /** The distance between the slice and the label */
    pieLabelRadius: PropTypes.number,
    /** Function for determining legend position based on props */
    getLegendPosition: PropTypes.func,
    /** Configuration options for pieChart */
    pieConfig: PropTypes.shape({
      /** Whether or not the legend of the chart is enabled */
      legendEnabled: PropTypes.bool,
      /** Whether or not the chart is responsive */
      responsive: PropTypes.bool,
    }),
  };

  graphData = () => {
    this.chart.graphs = [];
    this.chart.dataProvider = [];
    const data = this.props.chartData;

    _.forEach(_.cloneDeep(data.data), (d) => {
      d.color = Theme.getColor(d[data.meta.categoryField]);
      this.chart.dataProvider.push(d);
      if (!d.value) d.value = d[d.category];
    });
  };

  buildChart = () => {
    this.chart = new window.AmCharts.AmPieChart();

    this.chart.sequencedAnimation = true;
    this.chart.fontFamily = 'lato';
    this.chart.fontSize = 14;
    this.chart.titleField = this.props.chartData.meta.categoryField;
    this.chart.valueField = this.props.chartData.meta.valueField || 'value';
    this.chart.colorField = 'color';
    this.chart.startDuration = 0;
    this.chart.labelRadius = this.props.pieLabelRadius || 5;
    this.chart.color = this.props.pieColor || '#333333';
    this.chart.addClassNames = true;
    this.enableExport(this.props.exportTitle);

    if (this.props.pieConfig.responsive) {
      this.chart.responsive = {
        enabled: true,
        rules: [
          { minHeight: 200, overrides: { legend: { enabled: this.props.pieConfig.legendEnabled } } },
          { maxWidth: 400, maxHeight: 400, overrides: { valueAxes: { labelsEnabled: false } } },
          { maxWidth: 400, overrides: { categoryAxis: { labelsEnabled: false } } },
        ],
      };
    }

    if (this.props.donut) {
      this.chart.innerRadius = this.props.innerRadius || 60;
    }

    if (this.props.pieLabelText) {
      this.chart.labelText = this.props.pieLabelText;
    }

    if (this.props.unit === 'minutes') {
      this.chart.balloonText = '[[percents]]';
      this.chart.balloonFunction = (graphItem, percents) => {
        const value = Utils.humanizeSeconds(graphItem.value);
        return `${graphItem.title}: ${percents}%<br/>(${value})`;
      };
    }

    if (this.props.pieConfig.legendEnabled) {
      this.chart.legend = {
        enabled: _.isUndefined(this.props.legendPosition) || this.props.legendPosition !== 'none',
        position: this.props.getLegendPosition(),
        align: this.props.alignLegend || 'left',
        autoMargins: false,
        borderAlpha: 0,
        equalWidths: false,
        horizontalGap: 10,
        markerSize: 10,
        valueAlign: 'left',
        valueWidth: 0,
        divId: `${this.props.chartKey}Legend`,
      };
    }

    this.graphData(this.props.chartData);
    this.chart.write(this.props.chartKey);
  };

  render() {
    const {
      height,
      chartKey,
      className,
      chartData,
    } = this.props;

    const { meta, data } = chartData;

    const isEmptyData = data.every(item => 
      item[item[meta.categoryField]] === 0
    );

    if (isEmptyData) {
      return (
        <NoData isAbsolutePosition />
      )
    }

    const style = {};
    if (height) {
      style.height = height;
    }

    return (
      <div
        className={`pie-chart ${className}` || ''}
        style={style}
        id={chartKey}
      />
    );
  }
}

export default BaseChartMixin(PieChart);
