import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import configureStore, { history } from 'store/configureStore';
import 'styles/app.scss';
import Router from './Router';


const initialState = window.REDUX_INITIAL_STATE || {};
// TODO: remove export, need to rewrite actions
export const store = configureStore(initialState);

function App() {
  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Router />
      </ConnectedRouter>
    </Provider>
  );
}


App.propTypes = {
  store: PropTypes.object,
};


export default App;
