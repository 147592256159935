import moment from 'moment';

import { INITIAL_STATE } from './constants';

export function getDistState(frequency) {
  switch (frequency) {
    case 'minutes':
      return {
        interval: 15,
        times: [],
      };
    case 'daily':
      return {
        interval: 1,
        times: INITIAL_STATE.times,
      };
    case 'hourly':
      return {
        interval: 5,
        times: [],
      };
    case 'weekly':
      return {
        interval: 1,
        times: INITIAL_STATE.times,
        distributeWeekdays: INITIAL_STATE.distributeWeekdays,
      };
    case 'monthly':
      return {
        monthly: {
          dist: 'day',
          num: 1,
          month: 1,
        },
      };
    case 'one_time':
      return {
        distributeStart: INITIAL_STATE.distributeStart,
      };
    default:
      return {
        interval: INITIAL_STATE.interval,
        times: INITIAL_STATE.times,
      };
  }
}

export function getMonthlyState(dist) {
  if (dist === 'day') {
    return {
      dist,
      month: 1,
      num: 1,
    };
  } else if (dist === 'weekday') {
    return {
      dist,
      month: 1,
      num: 1,
      weekday: 'mon',
    };
  }
  return {};
}

export function getRelativePeriodState(unit, start, end) {
  if (unit === 'weekdays') {
    return {
      start: {
        ...start,
        value: '1',
      },
      end: {
        ...end,
        value: '1',
      },
    };
  }
  return {
    start: {
      ...start,
      value: moment(),
    },
    end: {
      ...end,
      value: moment(),
    },
  };
}
