import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import _ from 'lodash';

import AppData from 'app_data';
import TileOptionsContainer from 'modules/monitoring/custom_dashboard/tile_options_container';
import MultiSelectContainer from 'components/multi_select_container';
import callTypeOptions from 'modules/monitoring/custom_dashboard/call_type_options';
import { LeaderboardTable } from 'modules/monitoring/gamification/leaderboard_table_original';
import { Table } from 'modules/monitoring/gamification/table';
import { Leaderboard, LeaderboardContainer } from 'modules/monitoring/gamification/leaderboard';
import SimpleDropdown from 'components/simple_dropdown';
import Checkbox from 'components/checkbox';
import Utils from 'utils/common_utils';
import { GamificationTileControls } from 'components/custom_dashboard';


const bucketTypeOptions = [
  { id: 'users', label: 'Users', bucketType: 'users' },
  { id: 'locations', label: 'Locations', bucketType: 'locations' },
  { id: 'call_groups', label: 'Call Groups', bucketType: 'call_groups' },
  { id: 'phone_numbers', label: 'Phone Numbers', bucketType: 'phone_numbers' },
  { id: 'teams', teamType: 'users', label: 'Custom Groups: Users', bucketType: 'teams' },
  { id: 'teams', teamType: 'locations', label: 'Custom Groups: Locations', bucketType: 'teams' },
  { id: 'teams', teamType: 'call_groups', label: 'Custom Groups: Call Groups', bucketType: 'teams' },
  { id: 'teams', teamType: 'phone_numbers', label: 'Custom Groups: Phone Numbers', bucketType: 'teams' },
];
const countOptions = [5, 10, 15, 20];


const TABLE_TYPE = {
  original: {
    id: 'original',
    label: 'Original',
  },
  retro: {
    id: 'retro',
    label: 'Retro',
  },
  leaderBoard: {
    id: 'leaderboard',
    label: 'Leaderboard',
  },
  city: {
    id: 'city',
    label: 'City',
    theme: 'theme_city',
  },
  sports: {
    id: 'sports',
    label: 'Sports',
    theme: 'theme_sports',
  },
  sports2: {
    id: 'sports_2',
    label: 'Sports 2',
    theme: 'theme_sports_2',
  },
  sports3: {
    id: 'sports_3',
    label: 'Sports 3',
    theme: 'theme_sports_3',
  },
  tech: {
    id: 'tech',
    label: 'Tech',
    theme: 'theme_tech',
  },
};


class GamificationTile extends Component {
  static propTypes = {
    tileInfo: PropTypes.shape({
      filter: PropTypes.object,
      filters: PropTypes.array,
      title: PropTypes.string,
      count: PropTypes.number,
      metric: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
      ]),
      table: PropTypes.string,
    }),
    onUpdateItem: PropTypes.func,
    onRemoveItem: PropTypes.func,
    onCancel: PropTypes.func,
  };

  constructor(props) {
    super(props);
    const { tileInfo } = props;

    this.state = {
      filter: tileInfo.filter || bucketTypeOptions[0],
      filters: tileInfo.filters || [],
      title: tileInfo.title || '',
      count: tileInfo.count || countOptions[0],
      grouping: tileInfo.grouping || false,
      metric: tileInfo.metric || '',
      table: tileInfo.table || 'original',
      errors: {},
    };
  }

  componentDidMount() {
    const filterType = this.state.filter;
    if (this.props.tileInfo.filters) {
      this.getFilterOptions(filterType, 'mount');
    } else {
      this.getFilterOptions(filterType);
    }
  }

  onFilterChange = (value) => {
    this.getFilterOptions(value);
  };

  onFiltersChange = (value) => {
    this.setState({
      filters: value,
    });
  };

  onTitleChange = (event) => {
    this.setState({
      title: event.target.value,
    });
  };

  onCountChange = (value) => {
    const { id: count } = value;
    this.setState({
      count: Number(count),
    });
  };

  onMetricChange = (value) => {
    const { key } = value;
    const metric = _.find(callTypeOptions, { key });
    this.setState({
      metric,
    });
  };

  onTableChange = (value) => {
    const { id: table } = value;
    this.setState({
      table,
    });
  };

  onGroupingChange = () => {
    this.setState({
      grouping: !this.state.grouping,
    });
  };

  getBucketTypeOptions = () => bucketTypeOptions;

  getCountOptions = () => countOptions.map((num) => ({ id: num.toString(), label: num.toString() }));

  getMetricOptions = () => {
    const validKeys = [
      'total_calls',
      'total_talk_time',
      'inbound_calls',
      'inbound_talk_time',
      'outbound_calls',
      'outbound_talk_time',
    ];
    const availableOptions = callTypeOptions.filter((option) => validKeys.includes(option.key));
    return availableOptions;
    // return availableOptions.map((option) => <option key={option.key} value={option.key}>{option.label}</option>);
  };

  getTableOptions = () => Object.keys(TABLE_TYPE)
    .map(key => ({ ...TABLE_TYPE[key] }));
    // return availableOptions.map((option) => <option key={option.id} value={option.id}>{option.label}</option>);

  getFilterOptions = (filterType, str) => {
    const type = filterType.id;
    AppData.getNextOsType(type).then((response) => {
      let data = response.data;

      if (type === 'teams') {
        data = _.filter(data, (item) => filterType.teamType === item.team_type);
      }

      // cast all ids to str
      const filterOptions = _.map(data, (item) => ({ id: String(item.id), label: item.label }));

      const selectedFilters = str === 'mount' ? this.state.filters : filterOptions;

      let grouping = this.state.grouping;
      if (grouping && type !== 'teams') {
        grouping = false;
      }

      this.setState({
        filter: filterType,
        filters: selectedFilters,
        filterOptions,
        grouping,
      });
    });
  };

  validate = (page) => {
    const validators = [this.validateGamificationOptions];
    return validators[page]();
  };

  save = (page) => {
    const state = Object.assign({}, this.state);
    this.props.onUpdateItem(state);
  };

  validateGamificationOptions = () => {
    const errors = {};
    if (!this.state.title) {
      errors.title = true;
    }
    if (!this.state.filter) {
      errors.filter = true;
    }
    if (this.state.filters.length === 0) {
      errors.filters = true;
    }
    if (!this.state.metric) {
      errors.metric = true;
    }
    if (Object.keys(errors).length) {
      this.setState({ errors });
      return false;
    }
    return true;
  };

  renderGamificationOptionsOld = () => {
    const countOptionElements = this.getCountOptions();
    const metricOptionElements = this.getMetricOptions();
    const tableOptionElements = this.getTableOptions();
    const filterIndex = _.findIndex(bucketTypeOptions, (opt) => (opt.label === this.state.filter.label));
    return (
      <div>
        <div className="custom_dashboard_tile_content_title">{'Gamification'}</div>
        <input className={cx({ custom_dashboard_tile_title: true, error: this.state.errors.title })} type="text" placeholder="Title" onChange={this.onTitleChange} value={this.state.title} />
        <div className="custom_dashboard_subtitle">Filter Type</div>
        <SimpleDropdown
          className={cx({ error: this.state.errors.filter })}
          initialIndex={filterIndex}
          options={bucketTypeOptions}
          onChange={this.onFilterChange}
        />
        {this.state.filter.id === 'teams' ?
          <Checkbox
            label={'Grouped'}
            onChange={this.onGroupingChange}
            checked={this.state.grouping}
          /> : null}
        <div className="custom_dashboard_subtitle">Filters</div>
        <MultiSelectContainer
          className={cx({ 'error': this.state.errors.filters })}
          label={'Select Filters'}
          dropdownOptions={this.state.filterOptions}
          initialSelected={_.map(this.state.filters, 'id')}
          onDropdownSave={this.onFiltersChange}
          searchBar
        />
        <div className="custom_dashboard_subtitle">Metric</div>
        <select onChange={this.onMetricChange} value={this.state.metric.key} className={cx({ error: this.state.errors.metric })}>
          <option value="">Select Metric</option>
          {metricOptionElements}
        </select>
        <div className="custom_dashboard_subtitle">Count</div>
        <select onChange={this.onCountChange} value={this.state.count}>
          {countOptionElements}
        </select>
        <div className="custom_dashboard_subtitle">Display</div>
        <select onChange={this.onTableChange} value={this.state.table}>
          {tableOptionElements}
        </select>
      </div>
    );
  };

  renderGamificationOptions = () => (
    <GamificationTileControls
      tileInfo={this.state}
      onTitleChange={this.onTitleChange}
      filterOptions={bucketTypeOptions}
      onFilterChange={this.onFilterChange}
      filtersOptions={this.state.filterOptions}
      onFiltersChange={this.onFiltersChange}
      metricOptions={this.getMetricOptions()}
      onMetricChange={this.onMetricChange}
      countOptions={this.getCountOptions()}
      onCountChange={this.onCountChange}
      displayOptions={this.getTableOptions()}
      onDisplayChange={this.onTableChange}
    />
  );

  render() {
    const { onRemoveItem, onCancel } = this.props;
    return (
      <TileOptionsContainer
        onRemoveItem={onRemoveItem}
        onCancel={onCancel}
        onUpdate={this.save}
        validate={this.validate}
        pages={[this.renderGamificationOptions]}
      />
    );
  }
}

function Gamification(props) {
  const {
    tileInfo,
    editButton,
    realTime,
    timeRange,
  } = props;

  let component;

  const componentProps = {
    realTime,
    timeRange,
    key: tileInfo.table,
    nBuckets: tileInfo.count,
    filters: tileInfo.filters,
    callType: tileInfo.metric,
    filterType: tileInfo.filter,
    grouping: tileInfo.grouping,
    displayType: tileInfo.metric.units,
  };

  switch (tileInfo.table) {
    case TABLE_TYPE.retro.id: {
      component = (
        <div className="row">
          <LeaderboardTable {...componentProps} />
        </div>
        );
      break;
    }

    case TABLE_TYPE.leaderBoard.id: {
      component = (
        <div className="row custom_dashboard_leaderboard">
          <Leaderboard {...componentProps} perPage={tileInfo.count} />
        </div>
        );
      break;
    }

    case TABLE_TYPE.original.id: {
      component = (
        <div className="row">
          <Table
            {...componentProps}
            metric={tileInfo.metric}
            unit={tileInfo.metric.units}
            unitLabel={Utils.labelByUnit(tileInfo.metric.units)}
          />
        </div>
        );
      break;
    }

    case TABLE_TYPE.city.id:
    case TABLE_TYPE.sports.id:
    case TABLE_TYPE.sports2.id:
    case TABLE_TYPE.sports3.id:
    case TABLE_TYPE.tech.id: {
      component = (
        <LeaderboardContainer
          {...componentProps}
          perPage={tileInfo.count}
          shouldShowControls={false}
          theme={TABLE_TYPE[tileInfo.table.replace('_', '')].theme}
        />
      );
      break;
    }

    default: {
      component = (
        <div>
          {'Error'}
        </div>
        );
      break;
    }
  }

  return (
    <div className="custom_dashboard_tile_content monitoring-gamification">
      <div className="custom_dashboard_actions">
        {editButton}
      </div>

      <div className="custom_dashboard_tile_content_title">{tileInfo.title}</div>

      <div className="custom_dashboard_tile_content_wrapper">
        {component}
      </div>
    </div>
  );
}


Gamification.propTypes = {
  realTime: PropTypes.bool,
  staticTile: PropTypes.bool,
  timeRange: PropTypes.object.isRequired,
  editButton: PropTypes.node.isRequired,
  tileInfo: PropTypes.shape({
    filter: PropTypes.object,
    filters: PropTypes.array,
    title: PropTypes.string,
    count: PropTypes.number,
    metric: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    table: PropTypes.string,
  }).isRequired,
};

export { Gamification };
export default GamificationTile;
