import React from 'react';
import PropTypes from 'prop-types';
import {
  Route,
  Switch,
  NavLink,
} from 'react-router-dom';
import { kebabCase } from 'lodash';

import { VideoPopup } from 'components';

import Styles from './PageNavigation.module.scss';


function PageNavigation(props) {
  const { routes, videoUrl } = props;

  return (
    <>
      <div className={Styles.navigation}>
        <ul data-testid="navigation-list" className={Styles.list}>
          {routes.map(route => (
            <li key={route.path}>
              <NavLink
                to={route.path}
                className={Styles.tab}
                activeClassName={Styles.active}
                data-testid={`page-navigation-${route.title.toLowerCase()}`}
              >
                {route.title}
              </NavLink>
            </li>
          ))}
        </ul>

        {videoUrl && (
          <VideoPopup
            videoUrl={videoUrl}
            theme={{
              link: Styles.link,
            }}
          />
        )}
      </div>

      <div className={Styles.container}>
        <Switch>
          {routes.map(route => (
            <Route
              key={route.path}
              component={route.component}
              path={route.path + (route.parameter || '')}
            />
          ))}
        </Switch>
      </div>
    </>
  );
}


PageNavigation.propTypes = {
  videoUrl: PropTypes.string,
  routes: PropTypes.array.isRequired,
};


export default PageNavigation;
