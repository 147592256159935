import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Leaderboard } from 'modules/monitoring/gamification/leaderboard';
import AppData from 'app_data';
import { Collapse } from 'components';
import { ControlsActions } from 'actions/controls_actions';
import GamificationControls from 'modules/monitoring/gamification/gamification_controls';

const mapStateToProps = (state) => ({
  controlsState: state.controls.gamification || {},
});

const mapDispatchToProps = (dispatch) => ({
  controlsActions: bindActionCreators(ControlsActions, dispatch),
});

class LeaderboardPopout extends Component {
  static propTypes = {
    controlsState: PropTypes.object,
    controlsActions: PropTypes.object,
    match: PropTypes.object,
  };

  state = {
    loading: true,
  };

  componentWillMount() {
    const { id } = this.props.match.params;

    AppData.getPopoutParams(id).then((response) => {
      const { data } = response;
      this.props.controlsActions.loadState('gamification', data.controlsState);
      this.setState(data.componentState);
    });
  }

  render() {
    if (!this.state.displayType) {
      // settings call hasn't returned
      return <div />;
    }
    const { controlsState } = this.props;
    let displayType = this.state.displayType;
    if (controlsState.callType.metric.includes('_duration')) {
      displayType = 'seconds';
    }
    return (
      <div
        className={cx({
          'content-box': true,
          'monitoring-gamification': true,
          'theme-reset': controlsState.boardTheme.id !== 'theme_default',
          [`${controlsState.boardTheme.id}`]: true,
        })}
      >
        <div className="content-box-header">
          <Collapse>
            <GamificationControls
              disableBoardControls
            />
          </Collapse>
          <ul className="labels">
            <li>{controlsState.callType.label}</li>
          </ul>
        </div>
        <div className="content-box-content">
          <Leaderboard
            realTime={controlsState.realTime}
            callType={controlsState.callType}
            filterType={controlsState.filterType}
            filters={controlsState.filters}
            timeRange={controlsState.timeRange}
            displayType={displayType}
            nBuckets={controlsState.totalUsers}
            avatarStyle={controlsState.boardType.avatarStyle}
            sort={controlsState.boardType.sort}
            grouping={controlsState.grouping}
            perPage={controlsState.userCount.perPage}
          />
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LeaderboardPopout);
