import _ from 'lodash';
import { flow, filter, map, pick } from 'lodash/fp';
import { batchActions } from 'redux-batched-actions';
import localStore from 'store2';

import Constants from 'constants.js';
import Filters from 'modules/reporting/custom_filters';
import AppData from 'app_data';
import Utils from 'utils/common_utils';


const CustomActions = {
  enableFilter(dropzone, key) {
    return (dispatch, getState) => {
      dispatch(batchActions([
        { type: Constants.CUSTOM_ENABLE_FILTER, op: 'enable', dropzone, key },
        { type: Constants.CUSTOM_UPDATE_CHART },
      ]));
    };
  },
  disableFilter(dropzone, key) {
    return (dispatch, getState) => {
      dispatch(batchActions([
        { type: Constants.CUSTOM_DISABLE_FILTER, op: 'disable', dropzone, key },
        { type: Constants.CUSTOM_UPDATE_CHART },
      ]));
    };
  },
  enableAll() {
    return (dispatch, getState) => {
      dispatch(batchActions([
        { type: Constants.CUSTOM_ENABLE_ALL },
        { type: Constants.CUSTOM_UPDATE_CHART },
      ]));
    };
  },
  disableAll() {
    return (dispatch, getState) => {
      dispatch(batchActions([
        { type: Constants.CUSTOM_DISABLE_ALL },
        { type: Constants.CUSTOM_UPDATE_CHART },
      ]));
    };
  },
  setTeamType(value) {
    return (dispatch, getState) => {
      dispatch(batchActions([
        { type: Constants.CUSTOM_SET_TEAM_TYPE, field: 'teamType', value },
        { type: Constants.CUSTOM_DISABLE_ALL },
        { type: Constants.CUSTOM_UPDATE_CHART },
      ]));
    };
  },
  setTimeRanges(value) {
    return (dispatch, getState) => {
      dispatch(batchActions([
        { type: Constants.CUSTOM_SET_TIME_RANGES, field: 'timeRanges', value },
        { type: Constants.CUSTOM_UPDATE_CHART },
      ]));
    };
  },
  setRealTime(value) {
    return (dispatch, getState) => {
      dispatch(batchActions([
        { type: Constants.CUSTOM_SET_REAL_TIME, field: 'realTime', value },
        { type: Constants.CUSTOM_UPDATE_CHART },
      ]));
    };
  },
  setBuckets(value) {
    return (dispatch, getState) => {
      dispatch(batchActions([
        { type: Constants.CUSTOM_SET_BUCKETS, field: 'minuteBuckets', value },
        { type: Constants.CUSTOM_UPDATE_CHART },
      ]));
    };
  },
  validate(options) {
    return (dispatch, getState) => {
      dispatch({ type: Constants.CUSTOM_VALIDATE, options });

      const errors = getState().custom.ui.errors;
      const messages = [];
      _.each(errors, (err) => {
        if (err.hasError) messages.push(err.errorMessage);
      });

      return messages.length && messages || null;
    };
  },
  updateTreePath(path) {
    return { type: Constants.CUSTOM_UPDATE_TREE_PATH, path };
  },
  parseTree(path) {
    return { type: Constants.CUSTOM_PARSE_TREE, path };
  },
  setStatus(value) {
    return { type: Constants.CUSTOM_SET_STATUS, field: 'status', value };
  },
  setTitle(value) {
    return { type: Constants.CUSTOM_SET_TITLE, field: 'title', value };
  },
  loadReport(id) {
    return (dispatch, getState) => {
      AppData.getCustomReport(id).then((response) => {
        const { data } = response;
        dispatch({ type: Constants.CUSTOM_LOAD_REPORT, data });
      });
    };
  },
  getFilters() {
    return (dispatch, getState) =>
      AppData.getAllFilters()
        .then((data) => {
          _.each(data.data, (v, key) => {
            Filters[key].items = _.map(data.data[key], (row) => {
              const obj = {
                key: row.id,
                label: row.label,
                parent: key,
                selected: false,
              };

              // Support additional keys
              _.forEach(row, (value, key) => {
                if (key !== 'id' && key !== 'label') {
                  obj[_.camelCase(key)] = value;
                }
              });

              return obj;
            });
          });

          dispatch(CustomActions.setStatus('READY'));
          return data;
        });
  },
  save(reportId) {
    return (dispatch, getState) => {
      const customState = getState().custom;
      const jsonQuery = generateQuery(customState);
      if (reportId) jsonQuery.reportId = reportId;

      setTimeout(() => {
        dispatch({ type: Constants.CUSTOM_CLEAR });
      }, 10);

      return AppData.saveCustomReportQuery(jsonQuery);
    };
  },
  clear() {
    return { type: Constants.CUSTOM_CLEAR };
  },
  run() {
    return (dispatch, getState) => {
      const customState = getState().custom;
      const query = generateQuery(customState);

      localStore.set('customReport', query);
    };
  },
};

function generateQuery(customState) {
  const uiState = customState.ui;
  const query = {
    select: [],
    filters: [],
    timeRanges: [],
    title: uiState.title,
    unit: uiState.chart.unit,
    teamType: uiState.teamType,
  };

  // build selects
  query.select = flow(
    filter((i) => i.selected),
    map((i) => pick(['metric', 'label', 'agg_func'], i))
  )(uiState.filters[uiState.dropzones[0]].items);
  // build filters
  const filters = uiState.filters[uiState.dropzones[1]].items.filter((i) => i.selected).map((i) => i.key);

  query.filters = {};
  query.filters[uiState.dropzones[1]] = filters;

  if (uiState.queryPath.report === 'calls_talk_time') {
    query.labels = Utils.createLabelMap(uiState.filters[uiState.dropzones[0]].items, 'key');
  }

  // extensions must send over userId too
  if (uiState.filters.extensions) {
    query.filters['users'] = uiState.filters[uiState.dropzones[1]].items.filter((i) => i.selected).map((i) => i.userId);
  }

  // build dates
  _.each(uiState.timeRanges, (timeRange) => {
    query.timeRanges.push(timeRange);
  });

  // assign minute buckets
  if (uiState.minuteBuckets) {
    query.minute_buckets = uiState.minuteBuckets;
  }

  return _.assign({},
    customState.query,
    customState.ui.queryPath,
    query
  );
}

export default CustomActions;
