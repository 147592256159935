import React, { memo } from 'react';
import cx from 'classnames';
import Draggable from 'react-draggable';
import { ServiceUnavailablePlaceholder } from '@nextiva/next-ui';

import { DefaultSpinner } from 'components';

import Volume from '../Volume';
import { ReactComponent as LoopIcon } from '../../assets/loop.svg';

import Styles from './Player.module.scss';


const Player = memo((props) => {
  const {
    canvasRef,
    progressRef,
    isClip,
    isPlay,
    bounds,
    duration,
    progress,
    isReplay,
    isLoading,
    finishTime,
    errorMessage,
    togglePlay,
    onChangeVolume,
    onProgressDrag,
    onClickProgressBar,
    onClickReplayButton,
  } = props;

  if (errorMessage) {
    return (
      <ServiceUnavailablePlaceholder 
        text={errorMessage}
        className={Styles.error}
      />
    )
  }

  return (
    <>
      {isLoading && (
        <DefaultSpinner />
      )}

      <div className={Styles.canvas} ref={canvasRef}>
        {!isLoading && isClip && (
          <>
            <div
              className={Styles.clipStart}
              style={{ transform: `translateX(${bounds.left}px)` }}
            />

            <div
              className={Styles.clipEnd}
              style={{ transform: `translateX(${bounds.right}px)` }}
            />
          </>
        )}
      </div>

      <div className={cx(Styles.controls, {
        [Styles.hide]: isLoading,
      })}>
        <div
          ref={progressRef}
          className={Styles.line}
          onClick={onClickProgressBar}
        >
          <Draggable
            axis="x"
            grid={[1, 0]}
            bounds={bounds}
            position={{ x: progress, y: 0 }}
            onDrag={onProgressDrag}
            onStop={onProgressDrag}
          >
            <div className={Styles.progress} />
          </Draggable>
        </div>

        <div className={Styles.content}>
          <span className={Styles.progressTime}>
            {duration}
          </span>

          <LoopIcon
            className={cx(Styles.replayIcon, {
              [Styles.active]: isReplay,
            })}
            onClick={onClickReplayButton}
          />
          
          <span
            className={cx(Styles.playButton, {
              [Styles.paused]: isPlay,
            })}
            onClick={togglePlay}
          />

          <Volume onChange={onChangeVolume} />

          <span className={Styles.finishTime}>
            {finishTime}
          </span>
        </div>
      </div>
    </>
  );
});


Player.displayName = 'Player';


export default Player;
