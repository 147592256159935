import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ReactTimeout from 'react-timeout';
import { DefaultSpinner } from 'components';

import AppData from 'app_data';
import DashboardControls from 'components/dashboard_controls';
import { Collapse } from 'components';
import Utils from 'utils/common_utils';
import InlineSvgFactory from 'components/inline_svg';
import { ControlsActions } from 'actions/controls_actions';
import Theme from 'theme';

const fetchData = (component, section, props, skipLoader) => {
  if (!skipLoader) component.setState({ loading: true });

  const selects = _.map(props.callTypes, (i) => _.omit(i, 'id', 'units', 'svg', 'bgColor', 'className'));
  const params = {
    time_range: props.timeRange,
    bucket_type: props.filterType.bucketType,
    filters: { [props.filterType.bucketType]: _.map(props.filters, 'id') },
    selects,
    grouping: props.grouping,
    sort_by: selects.length > 0 ? selects[0].label : null,
  };

  AppData.getMonitoringSectionDashboard(params, 'dashboard')
    .then((response) => {
      const { data } = response;
      component.setState({
        items: data.data,
        loading: false,
      });
    });
};

const mapStateToProps = (state) => ({
  controlsState: state.controls.dashboard || {},
});

const mapDispatchToProps = (dispatch) => ({
  controlsActions: bindActionCreators(ControlsActions, dispatch),
});

class DashboardContainerComponent extends React.Component {
  static propTypes = {
    controlsActions: PropTypes.object,
    controlsState: PropTypes.object,
  };

  componentWillMount() {
    this.props.controlsActions.getMonitoringMetadata('dashboard');
  }

  componentWillUnmount() {
    this.props.controlsActions.setInactive('dashboard');
  }

  handlePopout = () => {
    const params = {};
    const { controlsState } = this.props;
    params.controlsState = _.assign({}, controlsState);
    // Save params to server, redirect with returned hash on data.id
    AppData.savePopoutParams(params).then((response) => {
      const { data } = response;
      const route = `${process.env.REACT_APP_PUBLIC_PATH || ''}/popout/dashboard/${data.id}`;
      const open = window.open(route);

      // ipad blocks popouts, redirect page
      if (open == null || typeof open === 'undefined') {
        window.location.href = route;
      }
    });
  };

  render() {
    let content;
    const { controlsState } = this.props;

    if (controlsState.ready) {
      content = (
        <>
          <DashboardControls
            section={'dashboard'}
            onPopout={this.handlePopout}
            grouping={controlsState.grouping}
          />
          <div className="dashboard-content content-box">
            <Dashboard
              realTime={controlsState.realTime}
              boardType={controlsState.boardType}
              callTypes={controlsState.callTypes}
              filterType={controlsState.filterType}
              filters={controlsState.filters}
              timeRange={controlsState.timeRange}
              grouping={controlsState.grouping}
            />
          </div>
        </>
      );
    } else {
      content = <DefaultSpinner />;
    }

    return content;
  }
}
const DashboardContainer = connect(mapStateToProps, mapDispatchToProps)(DashboardContainerComponent);

class DashboardComponent extends React.Component {
  static propTypes = {
    realTime: PropTypes.bool,
    boardType: PropTypes.shape({
      id: PropTypes.string,
    }),
    filters: PropTypes.array,
    callTypes: PropTypes.array,
  };

  state = {
    items: null,
    loading: true,
    windowHeight: 'initial',
    intervalID: null,
  };

  dashboard = React.createRef();

  componentWillMount() {
    this.resizeListener = _.debounce(this.handleResize, 50);
    window.addEventListener('resize', this.resizeListener);
  }

  componentDidMount() {
    this.handleResize();
    if (this.props.realTime) {
      fetchData.bind(this, this, 'dashboard', this.props, true);

      const intervalID = this.props.setInterval(
          fetchData.bind(this, this, 'dashboard', this.props, true),
          AppData.user.refresh_rate * 1000
      );
      this.setState({ intervalID: intervalID });
    } else {
      fetchData(this, 'dashboard', this.props);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.realTime) {
      fetchData.bind(this, this, 'dashboard', nextProps, true);

      const intervalID = this.props.setInterval(
          fetchData.bind(this, this, 'dashboard', nextProps, true),
          AppData.user.refresh_rate * 1000
      );
      this.setState({ intervalID: intervalID });
    } else {
      clearInterval(this.state.intervalID);
      fetchData(this, 'dashboard', nextProps);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeListener);
  }

  handleResize = () => {
    const node = this.dashboard.current;
    const boundingRect = node.getBoundingClientRect();
    const heightOffset = boundingRect.top;
    this.setState({
      windowHeight: window.innerHeight - heightOffset,
    });
  };

  render() {
    const items = this.state.items;
    let component;

    if (this.state.loading) {
      component = (
        <DefaultSpinner />
      );
    } else if (!items.length) {
      component = (
        <div className="monitoring-no-data">
          No data with these options.
        </div>
      );
    } else if (this.props.boardType.id === 'original') {
      component = (
        <OriginalTable
          items={items}
          displayNames={this.props.filters}
          callTypes={this.props.callTypes}
          divHeight={this.state.windowHeight}
        />
      );
    } else {
      component = (
        <IconicTable
          items={items}
          callTypes={this.props.callTypes}
          displayNames={this.props.filters}
          divHeight={this.state.windowHeight}
        />
      );
    }

    return (
      <div ref={this.dashboard}>
        {component}
      </div>
    );
  }
}
const Dashboard = ReactTimeout(DashboardComponent);

class IconicTable extends Component {
  static propTypes = {
    callTypes: PropTypes.array,
    items: PropTypes.array,
    divHeight: PropTypes.number,
  };

  render() {
    const header = _.map(this.props.callTypes, (item, index) => {
      const Svg = InlineSvgFactory(`${item.svg}.svg`);
      const color = Theme.getColor(item.label);
      const style = {
        backgroundColor: color,
        color: Utils.getContrastYIQ(color),
      };
      return (
        <th key={index} className={'dashboard'} style={style}>
          <Svg className="icon" />
          <div>{item.label}</div>
        </th>
      );
    });

    const items = _.map(this.props.items, (item, trIndex) => {
      const itemName = (
        <td key={`${trIndex}td`} className="monitoring-dashboard-location">{item.bucket_key}</td>
      );

      return (
        <tr key={trIndex}>
          {itemName}
          {
            _.map(this.props.callTypes, (callType, tdIndex) => {
              const color = Theme.getColor(callType.label);
              const style = {
                color,
              };
              if (callType.units === 'min') {
                return (
                  <td key={tdIndex} className={callType['className']} style={style}>
                    {Utils.humanizeSeconds(item[callType.label] || 0)}
                  </td>
                );
              }
              return (
                <td key={tdIndex} className={callType['className']} style={style}>
                  {Utils.numberWithCommas(item[callType.label] || 0)}
                </td>
              );
            })
          }
        </tr>
      );
    });

    const sums = _.reduce(this.props.items, (a, b) => {
      const result = {};
      _.forEach(a, (value, key) => {
        result[key] = value;
      });

      _.forEach(b, (value, key) => {
        result[key] = (result[key] || 0) + value;
      });
      return result;
    }, {});

    // Returns number with columns if not total_duration
    const sumColumns = _.map(this.props.callTypes, (callType, tdIndex) => {
      let result;
      const color = Theme.getColor(callType.label);
      const style = {
        color,
      };

      if (callType.hideTotal) {
        result = 'N/A';
      } else if (callType.units === 'min') {
        result = Utils.humanizeSeconds(sums[callType.label] || 0);
      } else if (callType.units === 'calls') {
        result = Utils.numberWithCommas(sums[callType.label] || 0);
      } else {
        result = parseFloat(sums[callType.label]).toFixed(2) || 0;
      }

      return (
        <td key={tdIndex} className={callType['className']} style={style}>
          <div>{result}</div>
        </td>
      );
    });

    return (
      <table className="monitoring-dashboard-iconic">
        <thead>
          <tr>
            <th key={'th'} />
            {header}
          </tr>
        </thead>
        <tbody>
          {items}
        </tbody>
        <tfoot>
          <tr>
            <td key={'first'}>Totals</td>
            {sumColumns}
          </tr>
        </tfoot>
      </table>
    );
  }
}

class OriginalTable extends Component {
  static propTypes = {
    callTypes: PropTypes.array,
    items: PropTypes.array,
    divHeight: PropTypes.number,
    unit: PropTypes.string,
  };

  render() {
    const sums = _.reduce(this.props.items, (a, b) => {
      const result = {};
      _.forEach(a, (value, key) => {
        result[key] = value;
      });

      _.forEach(b, (value, key) => {
        result[key] = (result[key] || 0) + value;
      });

      return result;
    }, {});

    const classes = cx({
      'monitoring-dashboard-username': this.props.unit === 'user',
      'monitoring-dashboard-location': this.props.unit === 'location',
    });

    const items = _.map(this.props.items, (item, thIndex) => (
      <th key={thIndex} className={classes}>{item.bucket_key}</th>
    ));

    const rowData = _.map(this.props.callTypes, (callType, trIndex) => {
      let result;
      const color = Theme.getColor(callType.label);
      const style = {
        color,
        borderBottom: `0.25em solid ${color}`,
      };
      if (callType.hideTotal) {
        result = 'N/A';
      } else if (callType.units === 'min') {
        result = Utils.humanizeSeconds(sums[callType.label] || 0);
      } else if (callType.units === 'calls') {
        result = Utils.numberWithCommas(sums[callType.label] || 0);
      } else {
        result = parseFloat(sums[callType.label]).toFixed(2).replace(/[.,]00$/, '') || 0;
      }

      return (
        <tr key={trIndex} className={callType.className}>
          <th style={_.pick(style, 'borderBottom')}>{callType.label}</th>
          {
            _.map(this.props.items, (item, tdIndex) => {
              if (callType.units === 'min') {
                return (
                  <td key={tdIndex} className={callType['className']} style={style}>
                    {Utils.humanizeSeconds(item[callType.label] || 0)}
                  </td>
                );
              }
              return (
                <td key={tdIndex} className={callType['className']} style={style}>
                  {Utils.numberWithCommas(item[callType.label] || 0)}
                </td>
              );
            })
          }
          <td style={style}>
            <div>{result}</div>
          </td>
        </tr>
      );
    });

    const rows = (
      <tbody className="monitoring-dashboard-body">
        {rowData}
      </tbody>
    );

    return (
      <div className="monitoring-dashboard-table">
        <table className="monitoring-dashboard-original">
          <thead>
            <tr>
              <th key={'first'} />
              {items}
              <th key={'last'}>Totals</th>
            </tr>
          </thead>
          {rows}
        </table>
      </div>
    );
  }
}

class DashboardPopoutComponent extends React.Component {
  static propTypes = {
    controlsActions: PropTypes.object,
    controlsState: PropTypes.object,
    match: PropTypes.object,
  };

  state = {
    ready: false,
  };

  componentWillMount() {
    const { id } = this.props.match.params;

    AppData.getPopoutParams(id).then((response) => {
      const { data } = response;
      this.props.controlsActions.loadState('dashboard', data.controlsState);
      this.setState({ ready: true });
    });
  }

  render() {
    const { controlsState } = this.props;

    if (!this.state.ready) {
      // settings call hasn't returned
      return (
        <DefaultSpinner />
      );
    }

    return (
      <div className="monitoring-gamification dashboard-controls">
        <Collapse>
          <DashboardControls
            section={'dashboard'}
            hidePopout
          />
        </Collapse>
        <div className="content-box-content">
          <Dashboard
            realTime={controlsState.realTime}
            boardType={controlsState.boardType}
            callTypes={controlsState.callTypes}
            filterType={controlsState.filterType}
            filters={controlsState.filters}
            timeRange={controlsState.timeRange}
            grouping={controlsState.grouping}
          />
        </div>
      </div>
    );
  }
}
const DashboardPopout = connect(mapStateToProps, mapDispatchToProps)(DashboardPopoutComponent);

export { DashboardContainer, Dashboard, DashboardPopout };
