import React from 'react';
import PropTypes from 'prop-types';
import BEMHelper from 'react-bem-helper';

import Tooltip from 'components/tooltip';
import HoursMinutesInput from './hours_minutes_input';

const classes = new BEMHelper('scheduled-report-form');

const TimeRange = ({ startModel, endModel, tooltip }) => (
  <div {...classes('field')}>
    <div>
      <label {...classes('label')}>Time range</label>
      {tooltip && <Tooltip tipContent={tooltip} />}
    </div>
    <div {...classes('time-range')}>
      <span>from</span>
      <HoursMinutesInput
        hourModel={`${startModel}.hours`}
        minuteModel={`${startModel}.minutes`}
        meridiemModel={`${startModel}.meridiem`}
      />
      <span>to</span>
      <HoursMinutesInput
        hourModel={`${endModel}.hours`}
        minuteModel={`${endModel}.minutes`}
        meridiemModel={`${endModel}.meridiem`}
      />
    </div>
  </div>
);

TimeRange.propTypes = {
  startModel: PropTypes.string,
  endModel: PropTypes.string,
  tooltip: PropTypes.string,
};

export default TimeRange;
