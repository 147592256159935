import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Control, Field } from 'react-redux-form';

const YesNoOptions = ({ model }) => (
  <div className="questions-form__question-options">
    <div>
      <label className="questions-form__label">Yes Text</label>
      <Control.text
        model={`${model}.yes`}
        className="questions-form__input"
        type="text"
        placeholder="Yes text"
        updateOn="blur"
      />
    </div>
    <div>
      <label className="questions-form__label">No Text</label>
      <Control.text
        model={`${model}.no`}
        className="questions-form__input"
        type="text"
        placeholder="No text"
        updateOn="blur"
      />
    </div>
  </div>
);

YesNoOptions.propTypes = {
  model: PropTypes.string,
};

export default YesNoOptions;
