import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import AnalyticsTrendPanels from 'components/analytics_panels/analytics_trend_panels';
import AnalyticsChart from 'components/charts/analytics_chart';
import AnalyticsTablePanel from 'components/analytics_table_panel';
import Utils from 'utils/common_utils';
import { Collapse } from 'components';


function AnalyticsTrend(props) {
  let uniqueReportTypes;
  const { state, actions } = props;

  if (state.callType) {
    uniqueReportTypes = [state.callType.type];
  } else {
    uniqueReportTypes = _.map(_.uniqBy(state.callTypes, 'type'), 'type');
  }

  if (state.ready) {
    let charts = _.map(uniqueReportTypes, (type, index) => {
      const report = _.find(state.metadata.reports, { type });
      return (
        <div
          key={index}
          className={`col-${state.callType || uniqueReportTypes.length === 1 ? 'full' : report.size}`}
        >
          <AnalyticsChart
            charts={report.charts}
            url={report.url}
            units={report.units}
            title={type}
            realTime={state.realTime}
            timeRange={state.timeRange}
            filters={state.apiFilters}
            selects={_.map(_.filter(state.callTypes, { type }), (i) => _.omit(i, 'type', 'id'))}
            labels={Utils.createLabelMap(state.callTypes)}
            enabledLegendItems={state.callType ? [state.callType.label] : null}
            legendPosition={'bottom'}
            detailUrl={state.metadata.table_report.detail_url}
            detailPeriodOptions={state.metadata.table_report.detail_period_options}
            callLogUrl={state.metadata.table_report.call_log_url}
          />
        </div>
      );
    });

    if (!charts.length) {
      charts = <div className="no-data">No data with these options.</div>;
    }

    return (
      <>
        <div style={{ position: 'relative' }}>
          <Collapse arrowClass="panel-collapse-arrow" disableAnimation>
            <AnalyticsTrendPanels
              onPanelClick={(callType) => {
                  // If current panel is selected, unselect
                if (!state.callType || callType.id !== state.callType.id) {
                  actions.setCallType('trend', callType);
                } else {
                  actions.setCallType('trend', null);
                }
              }}
              selected={state.callType}
              panelItems={state.callTypes}
              showPercent
              timeRange={state.timeRange}
              filters={state.apiFilters}
              className={'analytics-panel trend'}
            />
          </Collapse>
        </div>

        <div className="row analytics-charts">
          {charts}
        </div>

        <AnalyticsTablePanel
          filters={state.apiFilters}
          selects={state.callTypes}
          callLogFields={state.callLogFields}
          timeRange={state.timeRange}
          detailUrl={state.metadata.table_report.detail_url}
          detailPeriodOptions={state.metadata.table_report.detail_period_options}
          callLogUrl={state.metadata.table_report.call_log_url}
          activeTab={state.filterTypeLabel}
        />
      </>
    );
  }
}


AnalyticsTrend.propTypes = {
  actions: PropTypes.object,
  state: PropTypes.object,
};


export default AnalyticsTrend;
