import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';

import Utils from 'utils/common_utils';
import Theme from 'theme';
import BaseChartMixin from 'components/charts/chart_mixins';

// charts/charts.scss
class BarChart extends Component {
  static propTypes = {
    /** The title of the chart */
    exportTitle: PropTypes.string,
    /** Optional: Will hardcode a chart height */
    height: PropTypes.string,
    /** Whether or not an update is forced */
    forceRender: PropTypes.bool,
    /** A unique identifier for the chart being displayed */
    chartKey: PropTypes.string,
    /** The data to be displayed on the chart */
    chartData: PropTypes.object,
    /** The kind of unit that the chart is using on the value axis (calls, minutes, etc..) */
    unit: PropTypes.string,
    /** The position of the legend, default is 'top' */
    legendPosition: PropTypes.string,
    /** An array of the items in the legend */
    enabledLegendItems: PropTypes.array,
    /** The time range that the chart will represent */
    timeRange: PropTypes.object,
    /** Whether or not download is disabled */
    disableDownload: PropTypes.bool,
    /** Helper function for formatting seconds for balloon values */
    formatSeconds: PropTypes.func,
    /** Function for determining legend position based on props */
    getLegendPosition: PropTypes.func,
    /** Configuration options for barchart */
    barConfig: PropTypes.shape({
      /** Whether or not the legend of the chart is enabled */
      legendEnabled: PropTypes.bool,
      /** Whether or not the chart is responsive */
      responsive: PropTypes.bool,
      /** Whether or not the chart is rotated */
      rotate: PropTypes.bool,
      /** Whether or not it is a stacked bar chart */
      stacked: PropTypes.bool,
    }),
    legends: PropTypes.array,
    columnWidthOverride: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
  };

  static defaultProps = {
    columnWidthOverride: null,
  };

  graphData = () => {
    const addLabel = this.props.exportTitle === 'End Points';
    // these charts need to get the label color from the values
    const mosChartTitles = ['MOS Performance', 'Daily MOS Trend'];
    const isMOSChart = mosChartTitles.includes(this.props.exportTitle);
    const generateEndPointsByOSData = this.props.exportTitle === 'Endpoints by OS';
    const generateTotalCountEndpointsData = this.props.exportTitle === 'Total Count Endpoints';
    const addThresholdLine = this.props.exportTitle === 'MOS Performance';
    const generateEndpointsbyDeviceandVersionData = this.props.exportTitle === 'Endpoints by Device and Version';
    const timeIsMinute = this.props.unit === 'minutes';

    this.chart.graphs = [];
    this.chart.dataProvider = [];
    const data = this.props.chartData;
    let stackType = 'regular';
    if (!this.props.barConfig.stacked && data.data[0] && Object.keys(data.data[0]).length > 2) {
      stackType = 'none';
    }

    const valueAxis = [{
      stackType,
      axisAlpha: .1,
      dashLength: 1,
      position: 'bottom'
    }];

    if (timeIsMinute) {
      valueAxis[0].labelFunction = (seconds) => {
        const minutes = Utils.numberWithCommas(Math.floor(seconds / 60));
        return minutes;
      };
    }

    if (addLabel) {
      valueAxis.push({
        type: 'numeric',
        axisAlpha: 0,
        dashLength: 1000,
        position: 'right',
        title: "Calls"
      });
      valueAxis.push({
        type: 'numeric',
        axisAlpha: 0,
        dashLength: 1,
        position: 'left',
        title: "MOS",
      });
    }
    this.chart.valueAxes = valueAxis;

    if (this.props.chartData.meta.date_format) {
      this.chart.dataDateFormat = this.props.chartData.meta.date_format;
      this.chart.categoryAxis = {
        parseDates: true,
        axisAlpha: 0,
        gridAlpha: 0,
        tickLength: 0,
        boldPeriodBeginning: false,
      };
    } else if (this.props.chartData.meta.parseDates) {
      this.chart.categoryAxis = { parseDates: true };
    } else {
      this.chart.dataDateFormat = undefined;
      this.chart.categoryAxis = {axisAlpha: .01,dashLength: 1,};
    }

    // if column and greater than or equal to 8 graph items, rotate label
    if (!this.props.barConfig.rotate && this.props.chartData.data.length >= 8) {
      this.chart.categoryAxis.labelRotation = 45;
    }

    const finalColumnWidth = this.props.columnWidthOverride || (addThresholdLine ? 50 : this.props.chartData.data.length < 5 ? 65:'auto');
    const formatLabel=(graphData, title)=>{
      const value = graphData.values.value;
      const _title = typeof title ==='object' ? `${title.title} (${value})` : title;
      const updatedTitle = _title.split(' ')[0];
      return generateTotalCountEndpointsData && timeIsMinute ? `${updatedTitle} (${(value / 60).toFixed(2)})` : _title
  }

    const enabledLegendItemLabels = _.map(this.props.enabledLegendItems, 'label');
    _.forEach(data.meta.items, (item) => {
      const __graphsData = (item === "Calls") ? {
        balloonText :  `[[category]]<br/><b><span style='font-size:14px;'>[[value]]<br /> [[title]] ${this.props.unit} </span></b>`,
        balloonFunction: (timeIsMinute && this.props.formatSeconds) || null,
        bulletBorderAlpha: 1,
        bulletColor: '#FFFFFF',
        bullet: data.meta.bullet_type ? data.meta.bullet_type : undefined,
        title: data.meta.titles ? data.meta.titles[item] : item,
        valueField: item,
        lineColor: Theme.getColor(item),
        type: 'bar',
        color:Theme.getColor(item),
        lineAlpha: 4,
        fillAlphas: 0,
        useLineColorForBulletBorder: true,
        valueAxis: valueAxis[1],
        hidden: this.props.enabledLegendItems ? !_.includes(this.props.enabledLegendItems, item.replace('P1: ', '').replace('P2: ', '')) : false, // FIX THIS Replace is horible
      } : (generateEndPointsByOSData) ?
        null
      : {
        balloonText: `[[title]]<br><span style='font-size:14px;'><b>[[value]] ${this.props.unit}</b></span>`,
        balloonFunction: (timeIsMinute && this.props.formatSeconds) || null,
        fillAlphas: 1,
        lineAlpha: 1,
        title: item,
        type: 'column',
        valueField: item,
        fillColors: isMOSChart ? this.getChartFillColor(item) : Theme.getColor(item),
        lineColor: isMOSChart ? this.getChartFillColor(item) : Theme.getColor(item),
        valueAxis: addLabel ? valueAxis[2] : valueAxis[0],
        fixedColumnWidth: finalColumnWidth,
        hidden: this.props.enabledLegendItems ? !_.includes(enabledLegendItemLabels, item) : false,
      }
      if(__graphsData) this.chart.graphs.push(__graphsData)
    });
    if ((generateEndPointsByOSData || generateTotalCountEndpointsData || generateEndpointsbyDeviceandVersionData) && data.data.length){
      const _graphs = [];
      let _keys = [];
      data.data.map(x => _keys = _keys.concat(Object.keys(x)));
      _keys = _keys.filter((value, index, categoryArray) => categoryArray.indexOf(value) === index).sort();
      _keys.map((item) => {
        if (item !== 'category') {
          _graphs.push({
            balloonText: "[[title]]<br><span style='font-size:14px;'><b>[[value]] </b></span>",
            balloonFunction: formatLabel,
            fillAlphas: 1,
            lineAlpha: 1,
            title: item,
            type: "column",
            valueField: item,
            labelText: `[[title]] ([[value]])`,
            color: "#fff",
            fillColors: Theme.getColor(item.toLowerCase()),
            lineColor: Theme.getColor(item.toLowerCase()),
            fixedColumnWidth: generateTotalCountEndpointsData ? 135 : 165,
            hidden: false,
            labelFunction:formatLabel,
            valueAxis: timeIsMinute ? valueAxis[0] : undefined,
          });
        }
      })
      this.chart.graphs = _graphs;
    }
    _.forEach(data.data, (d) => {
      this.chart.dataProvider.push(d);
    });

    if (addThresholdLine){
      const threshold = [
          {
            fillAlpha:0,
            value:3.5,
            lineColor:Theme.getColor('moderate'),
            lineAlpha:1,
            dashLength:5,
            inside:true,
            above:true,
            lineThickness:2,
          },
          {
            fillAlpha:0,
            value:3.8,
            lineColor:Theme.getColor('good'),
            lineAlpha:1,
            dashLength:5,
            inside:true,
            above:true,
            lineThickness:2,
          }
        ]

      this.chart.valueAxes[0].guides = threshold;
    }
  }

  getChartFillColor=(itemKey)=> {
    const { data } = this.props.chartData;
    let _color = Theme.getColor('poor');

    data.forEach(item => {
      if(item && item['category']){
        const _value = item[itemKey];
        if(_value >= 3.5 && _value <= 3.8){
          _color = Theme.getColor('moderate')
        } else if(_value > 3.8){
          _color = Theme.getColor('good')
        }
      }
    });

    return _color
  }

  buildChart = () => {
    this.chart = new window.AmCharts.AmSerialChart();
    this.chart.categoryField = this.props.chartData.meta.categoryField;
    this.chart.fontSize = 14;
    this.chart.fontFamily = 'lato';
    this.chart.chartCursor = { cursorColor: '#3D85D1' };
    this.enableExport(this.props.exportTitle);

    if (this.props.barConfig.responsive) {
      this.chart.responsive = {
        enabled: true,
        rules: [
          { minHeight: 200, overrides: { legend: { enabled: this.props.barConfig.legendEnabled } } },
          { maxWidth: 400, overrides: { valueAxes: { labelsEnabled: false } } },
          { maxWidth: 400, overrides: { categoryAxis: { labelsEnabled: false } } },
          { maxWidth: 600, overrides: { categoryAxis: { fontSize: 10 } } },
        ],
      };
    }

    if (this.props.barConfig.rotate) {
      this.chart.rotate = true;
    }
    if (this.props.barConfig.legendEnabled) {
      this.chart.legend = {
        enabled: _.isUndefined(this.props.legendPosition) || this.props.legendPosition !== 'none',
        position: this.props.getLegendPosition(),
        autoMargins: false,
        borderAlpha: 0,
        equalWidths: false,
        markerSize: 10,
        valueAlign: 'left',
        valueWidth: 0,
        width:'95%',
        divId: `${this.props.chartKey}Legend`
      };
      if(this.props.legends){
        const data = this.props.legends.map(legend =>({
          title: legend,
          color: Theme.getColor(legend.toLowerCase())
        }))
        this.chart.legend['data'] = data;
      }
    }

    this.chart.valueAxes = [
      {
        position: 'bottom',
        axisAlpha: 0,
        type: this.props.chartData.meta.x_type ? this.props.chartData.meta.x_type : 'numeric',
        dashLength: 1,
        minPeriod: 'DD',
        minimumDate: this.props.chartData.meta.date_format ? moment(this.props.timeRange.from).subtract(1, 'days').toDate() : undefined,
        maximumDate: this.props.chartData.meta.date_format ? moment(this.props.timeRange.to).add(1, 'days').toDate() : undefined,
      },
      {
        axisAlpha: 0,
        dashLength: 1,
        position: 'right',
        type: this.props.chartData.meta.y_type ? this.props.chartData.meta.y_type : 'numeric',
        dateFormats: [
          { period: 'fff', format: 'L:NN:SS A' },
          { period: 'ss', format: 'L:NN:SS A' },
          { period: 'mm', format: 'L:NN A' },
          { period: 'hh', format: 'L:NN A' },
          { period: 'DD', format: 'MMM DD' },
          { period: 'WW', format: 'MMM DD' },
          { period: 'MM', format: 'MMM' },
          { period: 'YYYY', format: 'YYYY' },
        ],
      },
    ];

    this.graphData();
    this.chart.write(this.props.chartKey);
  }

  render() {
    const style = {};
    if (this.props.height) {
      style.height = this.props.height;
    }

    return (
      <div
        style={style}
        className={`barchart ${_.snakeCase(this.props.exportTitle)}`}
        id={this.props.chartKey}
      />
    );
  }
}

export default BaseChartMixin(BarChart);

