import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { DefaultSpinner } from 'components';

import { ControlsActions } from 'actions/controls_actions';
import { Tables } from './table';
import { LeaderboardContainer } from './leaderboard';
import { LeaderboardTableContainer } from './leaderboard_table_original';
import GamificationControls from './gamification_controls';


const mapStateToProps = (state) => ({
  controlsState: state.controls.gamification || {},
});

const mapDispatchToProps = (dispatch) => ({
  controlsActions: bindActionCreators(ControlsActions, dispatch),
});

class GamificationContainer extends Component {
  static propTypes = {
    controlsState: PropTypes.object,
    controlsActions: PropTypes.object,
  };

  state = {};

  componentWillMount() {
    this.props.controlsActions.getMonitoringMetadata('gamification');
  }

  componentWillUnmount() {
    this.props.controlsActions.setInactive('gamification');
  }

  renderTables = (controlsState) => (
    <Tables
      key={'tables'}
      realTime={controlsState.realTime}
      callType={controlsState.callType}
      filterType={controlsState.filterType}
      filters={controlsState.filters || []}
      timeRange={controlsState.timeRange}
      nBuckets={controlsState.boardType.nBuckets}
      grouping={controlsState.grouping}
    />
  );

  renderLeaderboard = (controlsState) => (
    <LeaderboardContainer
      key={'leaderboard'}
      className="gamification"
      realTime={controlsState.realTime}
      callType={controlsState.callType}
      filterType={controlsState.filterType}
      filters={controlsState.filters || []}
      timeRange={controlsState.timeRange}
      nBuckets={controlsState.totalUsers}
      avatarStyle={controlsState.boardType.avatarStyle}
      sort={controlsState.boardType.sort}
      theme={controlsState.boardTheme.id}
      grouping={controlsState.grouping}
      perPage={controlsState.userCount.perPage}
    />
  );

  renderLeaderboardTable = (controlsState) => (
    <LeaderboardTableContainer
      key={'leaderboardTable'}
      realTime={controlsState.realTime}
      nBuckets={controlsState.boardType.nBuckets}
      callType={controlsState.callType}
      filterType={controlsState.filterType}
      filters={controlsState.filters || []}
      timeRange={controlsState.timeRange}
      grouping={controlsState.grouping}
    />
  );

  render() {
    const { controlsState } = this.props;
    let content;

    if (!controlsState.ready) {
      return <DefaultSpinner />;
    }

    if (controlsState.boardType.id.includes('retro')) {
      content = this.renderLeaderboardTable(controlsState);
    } else if (controlsState.boardType.id.includes('original')) {
      content = this.renderTables(controlsState);
    } else if (controlsState.boardType.id.includes('leaderboard')) {
      content = this.renderLeaderboard(controlsState);
    }

    return (
      <>
        {controlsState.metadata && (
          <GamificationControls />
        )}

        {content}
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GamificationContainer);
