
const days = {
  1: 'mon',
  2: 'tue',
  3: 'wed',
  4: 'thu',
  5: 'fri',
  6: 'sat',
  7: 'sun',
  mon: 1,
  tue: 2,
  wed: 3,
  thu: 4,
  fri: 5,
  sat: 6,
  sun: 7,
};

export const convertWeekday = (day) => days[String(day)];

export const hasOwnProperty = Object.hasOwnProperty;

export const getMinuteReportTypes = () => ['hourly', 'fifteen', 'thirty'];
