import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import HoursMinutesInput from 'components/hours_minutes_input';
import Utils from 'utils/common_utils';

class WeekdayCheckboxes extends Component {
  static propTypes = {
    days: PropTypes.object,
    onChange: PropTypes.func,
    hours: PropTypes.string,
    minutes: PropTypes.string,
    meridiem: PropTypes.string,
    showTime: PropTypes.bool,
  };

  static defaultProps = {
    showTime: true,
  };

  getDayProps = () => _.pick(this.props.days, this.getDayRefs());

  getDayRefs = () => ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];

  getTimeProps = () => _.pick(this.props, ['hours', 'minutes', 'meridiem']);

  handleTimeChange = (obj) => {
    const result = { time: obj };
    result.days = this.getDayProps();

    this.props.onChange(result);
  };

  handleWeekdayChange = (e) => {
    // clicking label will cause this handler to run twice
    if (e.target.type === 'checkbox') {
      const result = { days: {} };

      _.each(_.keys(this.getDayProps()), (ref) => {
        const isChecked = this.refs[ref].checked;
        result.days[ref] = isChecked;
      });

      result.time = this.getTimeProps();

      this.props.onChange(result);
    }
  };

  render() {
    const refs = this.getDayRefs();
    const weekdays = _.map(refs, (ref, index) => (
      <label key={index}>
        {Utils.capitalize(ref)}
        <input
          ref={ref}
          type="checkbox"
          checked={this.props.days[ref]}
          onChange={() => {}}
          disabled={!hasOwnProperty.call(this.props.days, ref)}
        />
      </label>
    ));

    return (
      <div className="weekday-checkboxes">
        <div onClick={this.handleWeekdayChange} className="weekday-inner">
          {weekdays}
        </div>
        {this.props.showTime ?
          <div className="weekday-time">
            <span className="at">at</span>
            <HoursMinutesInput
              ref="hoursMinutesInput"
              hours={this.props.hours}
              minutes={this.props.minutes}
              meridiem={this.props.meridiem}
              onChange={this.handleTimeChange}
            />
          </div>
          : null}
      </div>
    );
  }
}

export default WeekdayCheckboxes;
