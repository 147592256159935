import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import cx from 'classnames';
import { connect } from 'react-redux';
import type from 'type-of';

import AppData from 'app_data';
import { Leaderboard } from 'modules/monitoring/gamification/leaderboard';

import { ReactComponent as PopoutImg } from 'img/popout.svg';

const mapStateToProps = (state) => ({
  controlsState: state.controls.gamification || {},
});

class LeaderboardContainer extends Component {
  static propTypes = {
    realTime: PropTypes.bool,
    callType: PropTypes.object.isRequired,
    filterType: PropTypes.object.isRequired,
    filters: PropTypes.array.isRequired,
    timeRange: PropTypes.object.isRequired,
    displayType: PropTypes.string,
    nBuckets: PropTypes.number.isRequired,
    avatarStyle: PropTypes.string,
    sort: PropTypes.string,
    theme: PropTypes.string,
    grouping: PropTypes.bool.isRequired,
    perPage: PropTypes.number.isRequired,
    controlsState: PropTypes.object,
    shouldShowControls: PropTypes.bool,
    className: PropTypes.string,
  };

  static defaultProps = {
    shouldShowControls: true,
  };

  state = {
    displayType: this.props.displayType || 'calls',
  };

  handlePopout = () => {
    const path = '/popout/gamification-leaderboard';
    // save state
    const params = {};
    params.componentState = _.assign({}, this.props, this.state);
    params.componentState.loading = false;
    params.componentState.data = null;

    params.controlsState = _.assign({}, this.props.controlsState);

    // Save params to server, redirect with returned hash on data.id
    AppData.savePopoutParams(params).then((response) => {
      const { data } = response;
      const route = `${process.env.REACT_APP_PUBLIC_PATH || ''}${path}/${data.id}`;
      const open = window.open(route);

      // ipad blocks popouts, redirect page
      if (open == null || type(open) === 'undefined') {
        window.location.href = route;
      }
    });
  };

  render() {
    const {
      sort,
      theme,
      timeRange,
      callType,
      realTime,
      filters,
      nBuckets,
      grouping,
      avatarStyle,
      filterType,
      perPage,
      className,
      shouldShowControls,
    } = this.props;

    let { displayType } = this.state;

    if (callType.metric.includes('_duration')) {
      displayType = 'seconds';
    }

    return (
      <div
        className={cx('dashboard-content', className, {
          'content-box': true,
          'monitoring-gamification': true,
          'theme-reset': theme !== 'theme_default',
          [`${theme}`]: true,
          [`${sort}`]: true,
        })}
      >
        <div className="content-box-header">
          <ul className="labels">
            <li>{callType.label}</li>
          </ul>

          {shouldShowControls && (
            <ul className="controls">
              <li onClick={this.handlePopout}>
                <PopoutImg />
              </li>
            </ul>
          )}
        </div>

        <div className="content-box-content">
          <Leaderboard
            realTime={realTime}
            displayType={displayType}
            callType={callType}
            filterType={filterType}
            filters={filters}
            timeRange={timeRange}
            nBuckets={nBuckets}
            avatarStyle={avatarStyle}
            sort={sort}
            grouping={grouping}
            perPage={perPage}
          />
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(LeaderboardContainer);
