import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Checkbox from 'components/checkbox';

const MultiSelectOption = (props) => {
  const { selected, label, onClick } = props;
  const classNames = {
    'multi-select-item': true,
    selected,
  };
  return (
    <li
      className={cx(classNames)}
      onClick={onClick}
    >
      <Checkbox
        checked={selected}
      />
      <span className="multi-select-label" title={label}>
        {label}
      </span>
    </li>
  );
};

MultiSelectOption.propTypes = {
  /** Whether or not this option is selected */
  selected: PropTypes.bool,
  /** The label for the option */
  label: PropTypes.string,
  /** The function to call when the option is clicked */
  onClick: PropTypes.func,
};

export default MultiSelectOption;
