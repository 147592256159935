

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import onClickOutside from 'react-onclickoutside';

import InlineSvgFactory from 'components/inline_svg';
import { ReactComponent as ArrowSvg } from 'img/arrow_down.svg';

let idInc = 0;

const keyHandlers = {
  38: 'handleUpKey',
  40: 'handleDownKey',
  13: 'handleEnterKey',
  27: 'handleEscKey',
};

function interceptEvent(event) {
  if (event) {
    event.preventDefault();
    event.stopPropagation();
  }
}

class MultiSelect extends React.Component {
  static displayName = 'MultiSelect';

  static propTypes = {
    /** Alignment options for the multi select */
    alignOptions: PropTypes.string,
    /** Additional items to be displayed in the multiselect */
    children: PropTypes.node,
    /** The Class name of the multiselect */
    className: PropTypes.string,
    /** The text of the close button */
    closeText: PropTypes.string,
    /** Whether or not the multiselect changes when closed */
    changeOnClose: PropTypes.bool,
    /** The label for the filter */
    filterText: PropTypes.string,
    /** Handler for changing the filters */
    handleFilterChange: PropTypes.func,
    /** The icon for the multiselect column */
    icon: PropTypes.string,
    /** The label for the multiselect column */
    label: PropTypes.string,
    /** Handler for a change to the multiselect */
    onChange: PropTypes.func,
    /** Handler for save being clicked */
    onSave: PropTypes.func,
    /** Whether or not multiple options are selected */
    multiple: PropTypes.bool,
    /** Whether or not the search bar is displayed */
    searchBar: PropTypes.bool,
    /* The label of the selected option */
    selectLabel: PropTypes.string,
    /** Handler for when select all is clicked */
    toggleSelectAll: PropTypes.func,
    /** The selected values of the multi select  */
    value: PropTypes.array,
  };

  static defaultProps = {
    closeText: 'Close',
  };

  state = {
    id: `react-select-box-${++idInc}`,
    open: false,
    focusedIndex: -1,
    pendingValue: [],
  };

  componentWillMount() {
    this.updatePendingValue(this.props.value);
  }

  componentWillReceiveProps(next) {
    this.updatePendingValue(next.value);
  }

  blurTimeout = null;

  changeOnClose = () => (this.isMultiple() && String(this.props.changeOnClose) === 'true');

  clickingOption = false;

  handleChange = (val, cb) => (event) => {
    this.clickingOption = false;
    interceptEvent(event);
    if (this.isMultiple()) {
      let selected = [];
      if (val != null) {
        selected = this.value().slice(0);
        const index = selected.indexOf(val);
        if (index !== -1) {
          selected.splice(index, 1);
        } else {
          selected.push(val);
        }
      }
      this.updatePendingValue(selected, cb) || this.props.onChange(selected);
    } else {
      this.updatePendingValue(val, cb) || this.props.onChange(val);
      this.handleClose();
      this.refs.button.focus();
    }
  };

  handleClear = (event) => {
    interceptEvent(event);
    this.handleChange(null, function () {
      // only called when change="true"
      this.props.onChange(this.state.pendingValue);
    })(event);
  };

  handleClickOutside = () => {
    if (this.state.open) {
      this.handleClose();
    }
  };

  handleClose = (event) => {
    interceptEvent(event);
    if (!this.clickingOption) {
      this.setState({ open: false, focusedIndex: -1 });
    }
    if (this.changeOnClose()) {
      this.props.onChange(this.state.pendingValue);
    }
    if (!event) {
      this.props.onSave();
    }
  };

  handleDownKey = (event) => {
    interceptEvent(event);
    if (!this.state.open) {
      this.handleOpen(event);
    }
    this.moveFocus(1);
  };

  handleEnterKey = (event) => {
    if (this.state.focusedIndex !== -1) {
      this.handleChange(this.options()[this.state.focusedIndex].value)(event);
    }
  };

  handleEscKey = (event) => {
    if (this.state.open) {
      this.handleClose(event);
    } else {
      this.handleClear(event);
    }
  };

  handleFocus = () => {
    clearTimeout(this.blurTimeout);
  };

  handleKeyDown = (event) => {
    if (keyHandlers[event.which]) {
      this[keyHandlers[event.which]](event);
    }
  };

  handleMouseDown = () => {
    this.clickingOption = true;
  };

  handleNativeChange = (event) => {
    let val = event.target.value;
    if (this.isMultiple()) {
      const children = [].slice.call(event.target.childNodes, 0);
      val = children.reduce((memo, child) => {
        if (child.selected) {
          memo.push(child.value);
        }
        return memo;
      }, []);
    }
    this.props.onChange(val);
  };

  handleOpen = (event) => {
    interceptEvent(event);
    this.setState({ open: true }, function () {
      this.refs.menu.focus();
    });
  };

  handleSpaceKey = (event) => {
    interceptEvent(event);
    if (!this.state.open) {
      this.handleOpen(event);
    } else if (this.state.focusedIndex !== -1) {
      this.handleEnterKey();
    }
  };

  handleUpKey = (event) => {
    interceptEvent(event);
    this.moveFocus(-1);
  };

  hasValue = () => {
    if (this.isMultiple()) {
      return this.value().length > 0;
    }
    return this.value() != null;
  };

  isMultiple = () => String(this.props.multiple) === 'true';

  isSelected = (value) => {
    if (this.isMultiple()) {
      return this.value().indexOf(value) !== -1;
    }
    return this.value() === value;
  };

  // Lock the label
  label = () => this.props.label;

  moveFocus = (move) => {
    const len = React.Children.count(this.props.children);
    const idx = (this.state.focusedIndex + move + len) % len;
    this.setState({ focusedIndex: idx });
  };

  options = () => {
    const options = [];
    const self = this;

    React.Children.forEach(this.props.children, (option, index) => {
      if (self.props.searchBar) {
        if (option.props.children.toLowerCase().indexOf(self.props.filterText.trim().toLowerCase()) === -1) {
          return;
        }
      }
      options.push({
        key: index,
        value: option.props.value,
        label: option.props.children,
      });
    });

    return options;
  };

  toggleOpenClose = (event) => {
    interceptEvent(event);
    this.setState({ open: !this.state.open });
    if (this.state.open) {
      return this.setState({ open: false });
    }

    this.handleOpen();
  };

  updatePendingValue = (value, cb) => {
    if (this.changeOnClose()) {
      this.setState({ pendingValue: value }, cb);
      return true;
    }
    return false;
  };

  value = () => {
    const value = this.changeOnClose() ?
      this.state.pendingValue :
      this.props.value;

    if (!this.isMultiple() || Array.isArray(value)) {
      return value;
    } if (value != null) {
      return [value];
    }
    return [];
  };

  renderClearButton = () => {
    if (this.hasValue()) {
      return (
        <button
          className="react-select-box-clear"
          aria-hidden
          onClick={this.handleClear}
        />
      );
    }
  };

  renderCloseButton = () => {
    if (this.isMultiple() && this.props.closeText) {
      return (
        <button
          onClick={this.handleClose}
          className="react-select-box-close"
          onFocus={this.handleFocus}
        >
          {this.props.closeText}
        </button>
      );
    }
  };

  renderNativeSelect = () => {
    const id = `${this.state.id}-native-select`;
    const multiple = this.isMultiple();
    const empty = multiple ? null : <option key="" value="">No Selection</option>;
    const options = [empty].concat(this.props.children);

    return (
      <div key="native-select" className="react-select-box-native">
        <label key="label" htmlFor={id}>
          {this.props.label}
        </label>
        <select
          id={id}
          key="select"
          multiple={multiple}
          onKeyDown={(e) => { e.stopPropagation(); }}
          value={this.props.value || (multiple ? [] : '')}
          onChange={this.handleNativeChange}
        >
          {options}
        </select>
      </div>
    );
  };

  renderOption = (option, i) => {
    const className = {
      'react-select-box-option': true,
      'react-select-box-option-focused': i === this.state.focusedIndex,
      'react-select-box-option-selected': this.isSelected(option.value),
    };
    return (
      <a
        id={`${this.state.id}-${i}`}
        href="#"
        onClick={this.handleChange(option.value)}
        onMouseDown={this.handleMouseDown}
        className={cx(className)}
        tabIndex={-1}
        key={option.value}
        onFocus={this.handleFocus}
      >
        {option.label}
      </a>
    );
  };

  renderOptionMenu = () => {
    const className = {
      'react-select-box-options': true,
      'react-select-box-hidden': !this.state.open,
    };

    /*
    var active = null
    if (this.state.focusedIndex !== -1) {
      active = this.state.id + '-' + this.state.focusedIndex
    }
    */
    return (
      <div
        key="options-1"
        className={cx(className)}
        onFocus={this.handleFocus}
        aria-hidden
        ref="menu"
        tabIndex={0}
      >
        {
          // this.renderCloseButton()
          <div key="options-2" className="react-select-box-off-screen">
            {this.options().map(this.renderOption)}
          </div>
        }
      </div>
    );
  };

  renderSearchBar = () => {
    const { open } = this.state;
    const inputClass = {
      'react-select-box-search': true,
      'react-select-box-hidden': !open,
    };
    const buttonClass = {
      'react-select-box-select': true,
      'react-select-box-hidden': !open,
    };

    return (
      <div key="1">
        {null}
        <input
          key="i1"
          className={cx(inputClass)}
          type="search"
          placeholder="Search"
          onChange={this.props.handleFilterChange}
          value={this.props.filterText}
        />
        <button key="b1" className={cx(buttonClass)} onClick={this.props.toggleSelectAll}>
          {this.props.selectLabel}
        </button>
      </div>
    );
  };

  render() {
    // not rendering native select for performance
    const common = [
      this.renderOptionMenu(),
    ];

    let className = {
      'react-select-box-container': true,
      [this.props.className]: this.props.className,
      'react-select-box-multi': this.isMultiple(),
      'react-select-box-empty': !this.hasValue(),
    };
    if (!this.props.icon) {
      if (this.props.searchBar) {
        common.push(this.renderSearchBar());
        className['react-select-box-searchable'] = true;
      }
      return (
        <div key="d1" onKeyDown={this.handleKeyDown} className={cx(className)}>
          <button
            key="button"
            id={this.state.id}
            ref="button"
            className="react-select-box"
            onClick={this.toggleOpenClose}
            tabIndex="0"
            aria-hidden
          >
            <div key="label" className="react-select-box-label">
              {this.label()}
            </div>
          </button>
          {common}
        </div>
      );
    }

    className = {
      'multiselect-icon-dropdown': true,
      'react-select-box-multi': true,
      [`multiselect-align-${this.props.alignOptions}`]: this.props.alignOptions,
      [`${this.props.className}`]: this.props.className,
    };

    const Icon = InlineSvgFactory(this.props.icon);

    return (
      <div
        onKeyDown={this.handleKeyDown}
        className={cx(className)}
      >
        <a
          id={this.state.id}
          className="react-select-box"
          onClick={this.toggleOpenClose}
          tabIndex="0"
          aria-hidden
        >
          <Icon />
          <ArrowSvg className="triangle" />
        </a>
        {common}
      </div>
    );
  }
}

export default onClickOutside(MultiSelect);
