
import SettingsHandler, {
  Global,
  ColorThemes,
  UserManagement,
  Groups,
} from 'modules/settings';

import * as Questions from 'modules/questions/components';

const routes = [
  {
    path: '/settings/global',
    title: 'Global',
    component: Global,
  },
  {
    path: '/settings/color-themes',
    title: 'Color Themes',
    component: ColorThemes,
  },
  {
    path: '/settings/user-management',
    title: 'User Management',
    parameter: '/:idUser?',
    component: UserManagement,
  },
  {
    path: '/settings/groups',
    title: 'Custom Groups',
    parameter: '/:idGroup?',
    component: Groups,
  },
  {
    path: '/settings/call-rating',
    title: 'Call Rating',
    parameter: '/:questionId?/(responses)?',
    component: Questions.List,
  },
];


export default routes;
