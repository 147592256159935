import React from 'react';
import moment from 'moment';

import Styles from './Footer.module.scss';


const years = `2015-${moment().year()}`;


function Footer() {
  return (
    <footer className={Styles.footer}>
      Copyright © {years} Nextiva Communications
    </footer>
  );
}


export default Footer;
