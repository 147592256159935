import { normalize, arrayOf } from 'normalizr';

import AppData from 'app_data';
import * as t from './actionTypes';
import * as schema from './schema';

export function loadQuestionsRequest() {
  return {
    type: t.LOAD_QUESTIONS_REQUEST,
  };
}

export function loadQuestionsSuccess(response) {
  return {
    type: t.LOAD_QUESTIONS_SUCCESS,
    data: normalize(response, arrayOf(schema.questionGroup)),
  };
}

export function loadQuestionsFailure(error) {
  return {
    type: t.LOAD_QUESTIONS_FAILURE,
    error,
  };
}

export function loadQuestionsResponsesRequest() {
  return {
    type: t.LOAD_RESPONSES_REQUEST,
  };
}

export function loadQuestionsResponsesSuccess(response) {
  return {
    type: t.LOAD_RESPONSES_SUCCESS,
    data: normalize(response, arrayOf(schema.questionResponse)),
  };
}

export function loadQuestionsResponsesFailure(error) {
  return {
    type: t.LOAD_RESPONSES_FAILURE,
    error,
  };
}

export function loadQuestions() {
  return (dispatch, getState) => {
    dispatch(loadQuestionsRequest());
    return AppData.getQuestionGroups()
      .then((response) => {
        dispatch(loadQuestionsSuccess(response.data));
        return response;
      })
      .catch((error) => {
        dispatch(loadQuestionsFailure(error));
      });
  };
}

export function loadQuestionsResponses(external_tracking_id) {
  return (dispatch, getState) => {
    dispatch(loadQuestionsResponsesRequest());
    return AppData.getQuestionGroupResponsesByCall(external_tracking_id)
      .then((response) => {
        dispatch(loadQuestionsResponsesSuccess(response.data));
        return response;
      })
      .catch((error) => {
        dispatch(loadQuestionsResponsesFailure(error));
      });
  };
}
