import React from 'react';
import { Link } from 'react-router-dom';
import LandingTile from 'components/landing_tile';

import landing_ico_report from 'img/landing_ico_report.png';
import landing_ico_monitor from 'img/landing_ico_monitor.png';
import landing_ico_analyze from 'img/landing_ico_analyze.png';
import landing_ico_customize from 'img/landing_ico_customize.png';
import landing_ico_save from 'img/landing_ico_save.png';
import landing_ico_set from 'img/landing_ico_set.png';
import landing_ico_quality from 'img/landing_ico_quality.png';

import Styles from './Landing.module.scss';


const Landing = () => (
  <div data-testid="home-landingContainer" className={Styles.landing}>
    <div className={Styles.row}>
      <LandingTile
        tileId="landing-report"
        tileLink="reporting"
        tileImg={landing_ico_report}
        tileTitle="Report"
        data-testId="home-tilesItem-report"
      >
        <p>View your business activity using <Link to="/reporting">popular report templates</Link> or create a <Link to="/reporting/custom">custom report</Link>.</p>
      </LandingTile>
      <LandingTile
        tileId="landing-monitor"
        tileLink="monitoring"
        tileImg={landing_ico_monitor}
        tileTitle="Monitor"
      >
        <p>Keep your finger on the pulse with <Link to="/monitoring">standard</Link> or <Link to="/monitoring/custom">custom</Link> dashboards. Foster transparency with <Link to="/monitoring/wallboard">wallboards</Link>, and engage your team with <Link to="/monitoring/gamification">gamification</Link>.</p>
      </LandingTile>
      <LandingTile
        tileId="landing-analyze"
        tileLink="analytics"
        tileImg={landing_ico_analyze}
        tileTitle="Analyze"
      >
        <p>Gain insight by identifying data <Link to="/analytics">trends</Link>, and draw <Link to="/analytics/call-group/comparison">comparisons</Link> between the activity of two entities or <Link to="/analytics/summary/period">time periods</Link>.</p>
      </LandingTile>
    </div>

    <div className={Styles.row}>
      <LandingTile
        tileId="landing-customize"
        tileLink="reporting/custom"
        tileImg={landing_ico_customize}
        tileTitle="Customize"
      >
        <p>Create <Link to="/reporting/custom">custom reports</Link> tailored to your business needs, and see them side-by-side with​ <Link to="/monitoring/custom">custom dashboards</Link>.</p>
      </LandingTile>
      <LandingTile
        tileId="landing-save"
        tileLink="saved"
        tileImg={landing_ico_save}
        tileTitle="Save"
      >
        <p><Link to="/saved">Save</Link> custom reports and <Link to="/saved/scheduled">schedule</Link> them to run and distribute automatically.</p>
      </LandingTile>
      <LandingTile
        tileId="landing-set"
        tileLink="settings"
        tileImg={landing_ico_set}
        tileTitle="Set"
      >
        <p>Set <Link to="/settings/global">global date defaults</Link>, and group users or phone numbers into <Link to="/settings/groups">custom groups</Link>.</p>
      </LandingTile>
    </div>
    <div className={Styles.row}>
    <LandingTile
        tileId="landing-quality"
        tileLink="/quality/summary"
        tileImg={landing_ico_quality}
        tileTitle="Check Quality"
      >
        <p>"Gain insights into your call quality. <Link to="/quality/user-activity">View Mean Opinion</Link> Score (MoS) across all calls, sort by device type, or drill down into specific individual calls."</p>
      </LandingTile>
    </div>
  </div>
);


export default Landing;
