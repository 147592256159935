import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import type from 'type-of';
import cx from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { DateRangePicker } from '@nextiva/analytics-ui';


import { DateRangePeriod } from 'components';
import AppData from 'app_data';
import CustomActions from 'actions/custom_actions';
import Dropzone from 'components/dropzone';
import BucketsModal from 'components/buckets_modal';
import Chart from 'components/charts/chart';
import AccordionItems from 'components/accordion/accordion_items';
import ReportTable from 'components/report_table';
import CallLog from 'components/call_log';

const mapStateToProps = state => ({
  title: state.custom.ui.title,
  chart: state.custom.ui.chart,
  filters: state.custom.ui.filters,
  timeRanges: state.custom.ui.timeRanges,
  realTime: state.custom.ui.realTime,
  errors: state.custom.ui.errors,
  dropzoneLimits: state.custom.ui.dropzoneLimits,
  dropzones: state.custom.ui.dropzones,
  minuteBuckets: state.custom.ui.minuteBuckets,
});

const mapDispatchToProps = dispatch => ({
  customActions: bindActionCreators(CustomActions, dispatch),
});

class CustomChartContainer extends Component {
  static propTypes = {
    /** Handler for the save button */
    save: PropTypes.func,
    /** Handler for the run button */
    run: PropTypes.func,
    /** Custom Report redux actions */
    customActions: PropTypes.object,
    /** Chart configuration */
    chart: PropTypes.object,
    /** Limits on the filter dropzones */
    dropzoneLimits: PropTypes.object,
    /** Available dropzones for filters */
    dropzones: PropTypes.array,
    /** Errors for the custom report */
    errors: PropTypes.object,
    /** All available filters for the report */
    filters: PropTypes.object,
    /** Minute buckets for call duration report */
    minuteBuckets: PropTypes.array,
    /** Realtime mode? */
    realTime: PropTypes.bool,
    /** Current time ranges for report */
    timeRanges: PropTypes.array,
    /** Report title */
    title: PropTypes.string,
  };

  handleBucketsChange = buckets => {
    this.props.customActions.setBuckets(buckets);
  };

  handleDateUpdate = val => {
    if (type(val) === 'object') val = [val];
    this.props.customActions.setTimeRanges(val);
  };

  handleDisableAll = () => {
    this.props.customActions.disableAll();
  };

  handleEnableAll = () => {
    this.props.customActions.enableAll();
  };

  handleRealTimeChange = realTime => {
    this.props.customActions.setRealTime(realTime);
  };

  handleSelect = (key, index, parentIndex, item) => {
    if (!key) return;
    this.props.customActions.disableFilter(item.parent, key);
  };

  handleTitleChange = e => {
    this.props.customActions.setTitle(e.target.value);
  };

  render() {
    const {
      title,
      chart,
      filters,
      timeRanges,
      realTime,
      errors,
      dropzoneLimits,
      dropzones,
      minuteBuckets,
    } = this.props;
    let tables;

    if (!chart) {
      return <div />;
    }

    let legendPosition;

    if (minuteBuckets) {
      legendPosition = 'top';
    } else if (chart && chart.format === 'period') {
      legendPosition = 'bottom';
    } else {
      legendPosition = 'none';
    }

    const boxes = [[], []];
    let dateRange;

    _.each(dropzones, (dropzone, index) => {
      if (!filters[dropzone]) return;
      const selectedFilters = _.filter(filters[dropzone].items, {
        selected: true,
      });

      _.each(selectedFilters, item => {
        const color = `${item.key}-color`;
        item = _.assign({}, item, {
          closeable: true,
          className: `dragged-chart-item ${color}`,
        });

        boxes[index].push(item);
      });
    });

    if (timeRanges.length === 1) {
      dateRange = (
        <DateRangePicker
          className="date-range-popover"
          isRealTime={realTime}
          timezone={AppData.user.timezone}
          dateRange={timeRanges[0]}
          onChange={this.handleDateUpdate}
          onRealTimeChange={this.handleRealTimeChange}
          theme={{
            dateRangePicker: 'date-range',
          }}
        />
      );
    } else {
      dateRange = (
        <DateRangePeriod
          timeRanges={timeRanges}
          timezone={AppData.user.timezone}
          onChange={this.handleDateUpdate}
        />
      );
    }

    const dropzoneLimit = dropzoneLimits[dropzones[1]];

    if (chart.config && chart.display === 'table') {
      if (chart.format !== 'call_log') {
        if (type(chart.config) !== 'array') {
          tables = [chart.config];
        } else {
          tables = chart.config;
        }

        tables = _.map(tables.slice(0, 3), (config, index) => (
          <div key={index} className="table-container">
            <ReportTable
              className="standard-table custom-table"
              data={config}
              containerWidth={835}
              sample
            />
          </div>
        ));
      } else if (chart.format === 'call_log') {
        // https://sentry.io/nextiva/analytics-standalone/issues/633675204/
        const data = {
          ...chart.config,
          rows: _.map(chart.config.rows, 'data'),
        };
        tables = [
          <div key="call-log" className="table-container">
            <CallLog className="call-log-table" data={data} />
          </div>,
        ];
      }
    }

    return (
      <div data-testid="custom-chart-container" className="custom-chart-container">
        <div data-testid="custom-chart-header" className="custom-chart-header">
          <input
            data-testid="custom-chart-title-input"
            type="text"
            className={cx({
              error: errors.title.hasError,
            })}
            onBlur={this.handleTitleChange}
            placeholder="Report Title"
            defaultValue={title}
          />
          <div className="buttons pull-right">
            <div data-testid="custom-chart-save" className="btn btn-green" onClick={this.props.save}>
              Save
            </div>
            <div data-testid="custom-chart-run" className="btn btn-blue" onClick={this.props.run}>
              Run
            </div>
          </div>
        </div>
        <div className="date-control-container">
          {dateRange}
          {minuteBuckets ? (
            <BucketsModal
              onChange={this.handleBucketsChange}
              className="chart-buckets"
              value={minuteBuckets}
            />
          ) : null}
        </div>
        <div
          data-testid="custom-chart-filters-container"
          className={cx({
            'custom-chart-items-container': true,
            error: errors.select.hasError,
          })}
        >
          <AccordionItems
            color={!minuteBuckets}
            items={boxes[0]}
            onSelect={this.handleSelect}
          />
        </div>
        {chart.name !== 'table' ? (
          <Chart
            forceRender
            chart={{ name: chart.name }}
            unit={chart.unit}
            chartData={chart.config}
            timeRange={timeRanges[0]}
            legendPosition={legendPosition}
            lineChartType={chart.format}
            sample
            disableDownload
          />
        ) : (
          <div>{tables}</div>
        )}

        {dropzoneLimit !== 0 ? (
          <Dropzone
            items={boxes[1]}
            onSelect={this.handleSelect}
            perPage={16}
            enableAll={this.handleEnableAll}
            disableAll={this.handleDisableAll}
            hasError={errors.filter.hasError}
            limit={dropzoneLimit}
          />
        ) : null}
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CustomChartContainer);
