import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import { handleChartChange, handleUnitChange, handlePopout } from 'utils/chart_utils';
import ChartHeader from 'components/charts/chart_header';
import ChartAsync from 'components/charts/chart_async';

const mapStateToProps = (state) => {
  const currentSection = state.controls.currentSection;
  const controlsState = state.controls[currentSection];
  return {
    controlsState,
  };
};

class ReportPageChart extends Component {
  static propTypes = {
    /** The Url of the call log */
    callLogUrl: PropTypes.string,
    /** An array of chart objects to be displayed on the report page */
    charts: PropTypes.array,
    /** An array of objects, the options in the detail table dropdown of the
    popout. Each object has an id and a label */
    detailPeriodOptions: PropTypes.array,
    /** URL for the data to populate the popout table for a chart */
    detailUrl: PropTypes.string,
    /** The filtering options for which data fields returned */
    filters: PropTypes.object,
    /** Whether or not the chart is displaying a popout button */
    popoutButton: PropTypes.bool,
    /** Whether or not the chart is displaying data in real time */
    realTime: PropTypes.bool,
    /** The title of the chart */
    title: PropTypes.string,
    /** A timeout for when the charts load. This is cosmetic */
    timeout: PropTypes.number,
    /** The time range being presented by the chart */
    timeRange: PropTypes.object,
    /** An array of the potential units (calls, minutes) */
    units: PropTypes.array,
    /** Whether or not the Units button is displayed */
    hideUnits: PropTypes.bool,
    /** An object with a calls and minutes array. Each array has objects
    with a label (inbound, outbound, etc..) and a metric (inbound duration,
    outbound duration, etc..) */
    unitSelects: PropTypes.object,
    /** The URL of the chart data */
    url: PropTypes.string,
    defaultChart: PropTypes.object,
    legends:PropTypes.array,
    barConfig: PropTypes.shape({
      /** Whether or not the legend of the chart is enabled */
      legendEnabled: PropTypes.bool,
      /** Whether or not the chart is responsive */
      responsive: PropTypes.bool,
      /** Whether or not the chart is rotated */
      rotate: PropTypes.bool,
      /** Whether or not it is a stacked bar chart */
      stacked: PropTypes.bool,
    }),
    columnWidthOverride: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
  };

  static defaultProps = {
    overrideKey: null,
  };

  state = {
    unit: this.props.units.length ? this.props.units[0] : '',
    chart: this.props.defaultChart || this.props.charts[0],
  };

  handleUnitChange = handleUnitChange.bind(this)

  handleChartChange = handleChartChange.bind(this)

  handlePopout = handlePopout.bind(this)

  render() {
    // Instantiate the headere here in order to pass it to the charts so they
    // know to re-render when the header changes.
    // Otherwise memoization will prevent the async chart from rendering.
    const header =
      <ChartHeader
        title={this.props.title}
        unit={this.state.unit}
        hideUnits={this.props.hideUnits}
        units={this.props.units}
        charts={this.props.charts}
        onUnitChange={this.handleUnitChange}
        onChartChange={this.handleChartChange}
        onPopout={this.handlePopout}
        
      />;

    return (
      <div data-testid="reporting-chart-container" className="chart-container border">
        {header}
        <ChartAsync
          ref="chartWrapper"
          timeout={this.props.timeout}
          chart={this.state.chart}
          url={this.props.url}
          unit={this.state.unit}
          selects={this.props.unitSelects[this.state.unit]}
          title={this.props.title}
          timeRange={this.props.timeRange}
          realTime={this.props.realTime}
          filters={this.props.filters}
          header={header}
          legends={this.props.legends && this.props.legends}
          legendEnabled={this.props.legendEnabled}
          barConfig={this.props.barConfig}
          columnWidthOverride={this.props.columnWidthOverride}
        />
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  { push },
)(ReportPageChart);
