import React, { Component } from 'react';
import PropTypes from 'prop-types';

import TabbedControls from 'components/tabbed_controls';
import ListSelect from 'components/list_select';

class CustomReportTileControls extends Component {
  static propTypes = {
    /** State of custom report tile */
    tileInfo: PropTypes.shape({
      /** Title for the tile */
      title: PropTypes.string,
      /** Current report id */
      reportId: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      /** Data display type */
      displayType: PropTypes.string,
      /** Whether or not to show the legend */
      legendEnabled: PropTypes.string,
      /** Validation errors for tile info */
      errors: PropTypes.object,
    }).isRequired,
    /** The available reports to choose */
    reportOptions: PropTypes.array,
    /** Handler for changing the title */
    onTitleChange: PropTypes.func,
    /** Handler for changing the report */
    onReportChange: PropTypes.func,
    /** Handler for changing the display type */
    onDisplayTypeChange: PropTypes.func,
    /** Handler for changing the legend */
    onLegendChange: PropTypes.func,
  }

  getTitleTab() {
    const { tileInfo, onTitleChange } = this.props;
    const tab = (
      <div className="custom-dashboard-controls-title">
        <input
          type="text"
          placeholder="Title"
          onChange={onTitleChange}
          value={tileInfo.title}
        />
      </div>
    );
    return tab;
  }

  getReportTab() {
    const { tileInfo, onReportChange, reportOptions } = this.props;
    let selected;
    if (tileInfo.reportId) {
      selected = {
        report_id: parseInt(tileInfo.reportId, 10),
      };
    }
    const tab = (
      <ListSelect
        options={reportOptions}
        onSelect={onReportChange}
        selected={selected}
        compareField="report_id"
        labelField="report_name"
      />
    );
    return tab;
  }

  getDisplayTypeTab() {
    const { tileInfo, onDisplayTypeChange } = this.props;
    let selected;
    if (tileInfo.displayType) {
      selected = {
        key: tileInfo.displayType,
      };
    }
    const tab = (
      <ListSelect
        options={tileInfo.displayTypes}
        onSelect={onDisplayTypeChange}
        selected={selected}
        compareField="key"
      />
    );
    return tab;
  }

  getLegendTab() {
    const { tileInfo, onLegendChange } = this.props;
    const options = [
      { id: 'legendEnabled', label: 'Show Legend' },
      { id: 'legendDisabled', label: 'Hide Legend' },
    ];
    const selected = {
      id: tileInfo.displayLegend,
    };
    const tab = (
      <ListSelect
        options={options}
        onSelect={onLegendChange}
        selected={selected}
      />
    );
    return tab;
  }

  render() {
    const { errors } = this.props.tileInfo;
    const tabs = [
      { icon: 'icon-custom-gear', label: 'Title', content: this.getTitleTab(), className: (errors.title ? 'error' : null) },
      { icon: 'icon-custom-written-doc', label: 'Report', content: this.getReportTab(), className: (errors.report ? 'error' : null) },
      { icon: 'icon-custom-indented-list', label: 'Display', content: this.getDisplayTypeTab(), className: (errors.displayType ? 'error' : null) },
      { icon: 'icon-custom-palette', label: 'Legend', content: this.getLegendTab(), className: (errors.displayLegend ? 'error' : null) },
    ];
    return (
      <TabbedControls
        tabs={tabs}
      />
    );
  }
}

export default CustomReportTileControls;
