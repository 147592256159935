import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

class StyleButton extends Component {
  static propTypes = {
    /** Handler for when the button is toggled */
    onToggle: PropTypes.func,
    /** Classname for the style of the button */
    style: PropTypes.string,
    /** label of the button */
    label: PropTypes.string,
    /** Whether or not the button is active */
    active: PropTypes.bool,
    /** Classname of the icon to be displayed */
    icon: PropTypes.string,
  }

  onToggle = (e) => {
    const { onToggle, style } = this.props;
    e.preventDefault();
    onToggle(style);
  }

  render() {
    const { label, active, icon } = this.props;
    const className = [
      'RichEditor-styleButton',
      { 'RichEditor-activeButton': active },
    ];

    return (
      <span className={cx(className)} onMouseDown={this.onToggle}>
        <span className={icon} />
      </span>
    );
  }
}

export default StyleButton;
