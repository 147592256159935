import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
// import shallowCompare from 'react-addons-shallow-compare';

class ComparisonDropdowns extends Component {
  static propTypes = {
    /** The options for the comparison dropdown */
    dropdownOptions: PropTypes.array,
    /** Handler for when the dropdown is changed */
    onComparisonChange: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      comparisonSelected0: props.dropdownOptions[0] || null,
      comparisonSelected1: props.dropdownOptions[1] || null,
    };
  }

  componentWillReceiveProps(newProps) {
    const props = this.props;
    if (!props.dropdownOptions ||
      newProps.dropdownOptions.length !== props.dropdownOptions.length) {
      const option0 = newProps.dropdownOptions[0];
      const option1 = newProps.dropdownOptions[1];
      this.setState({
        comparisonSelected0: option0 || null,
        comparisonSelected1: option1 || null,
      });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    // TODO: need to replace it //shallowCompare(this, nextProps, nextState);
    return true;
  }

  handleDropdownChange = (index, e) => {
    const obj = {};
    const value = _.find(this.props.dropdownOptions, { id: e.target.value });

    obj[`comparisonSelected${index}`] = value;
    const otherIndex = 1 - index;

    if (obj[`comparisonSelected${index}`].id === this.state[`comparisonSelected${otherIndex}`].id) {
      const unusedIndex = obj[`comparisonSelected${index}`].id !== this.props.dropdownOptions[0].id ? 0 : 1;
      obj[`comparisonSelected${otherIndex}`] = this.props.dropdownOptions[unusedIndex];
    }

    this.setState(obj, () => {
      const selected = [this.state.comparisonSelected0, this.state.comparisonSelected1];
      this.props.onComparisonChange(selected);
    });
  }

  render() {
    let idx;
    const comparisonSelected0 = this.state.comparisonSelected0;
    const comparisonSelected1 = this.state.comparisonSelected1;

    const opts = _.map(this.props.dropdownOptions, (opt, index) =>
      <option key={index} value={opt.id}>{opt.label}</option>
    );

    if (comparisonSelected0 !== null) {
      idx = _.findIndex(this.props.dropdownOptions, { id: comparisonSelected0.id });
    } else {
      idx = 0;
    }

    const opts2 = _.clone(opts);
    opts2.splice(idx, 1);

    return (
      <div className="comparison-dropdowns">
        <select
          ref="d0"
          onChange={this.handleDropdownChange.bind(this, '0')}
          value={comparisonSelected0 && comparisonSelected0.id}
        >
          {opts}
        </select>
        <select
          ref="d1"
          onChange={this.handleDropdownChange.bind(this, '1')}
          value={comparisonSelected1 && comparisonSelected1.id}
        >
          {opts2}
        </select>
      </div>
    );
  }
}

export default ComparisonDropdowns;
