import * as React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import errorImg from 'img/404.png';
import Styles from './ErrorPlaceholder.module.scss';


function ErrorPlaceholder(props) {
  const { isAbsolutePosition, className } = props;

  return (
    <div 
      data-testid="errorPlaceholder" 
      className={cx(Styles.anErrorPlaceholder, className, { 
        [Styles.absolutePosition]: isAbsolutePosition,
      })}
    >
      <img src={errorImg} alt="error-img" />
    </div>
  );
};


ErrorPlaceholder.propTypes = {
  isAbsolutePosition: PropTypes.bool,
};


export default ErrorPlaceholder;
