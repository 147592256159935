import { camelCase, invert } from 'lodash';
import {
  camelizeKeys,
  snakelizeKeys,
  makePostRequestCreator,
} from 'common';

import {
  SortOrderMap,
  ListDisplayPreferences,
  CommonDisplayPreferences,
} from '../../../constants';

const axiosRepeat = makePostRequestCreator();
export const getQualityCallLog = async (params) => {
  try {
    const { url, ...others } = params;
    const { data } = await axiosRepeat(url, snakelizeKeys(others));
    return normalizeCustomReport(camelizeKeys(data));
  } catch (e) {
    return e;
  }
};


export const sendQualityCallLogToEmail = async (params) => {
  try {
    const { data } = await axiosRepeat(
      '/nextos/reports/quality/call-log/csv',
      snakelizeKeys(params),
    );
    return data;
  } catch (e) {
    return e;
  }
};


export function normalizeCustomReport(data) {
  const {
    limit,
    offset,
    totalCount,
    tables: [table],
  } = data;

  const widths = {
    'connected': 180,
    'from': 180,
  };

  return {
    pagination: {
      limit,
      offset,
      totalItems: totalCount,
    },
    sortData: {
      key: camelCase(table.sort.header),
      order: invert(SortOrderMap)[table.sort.order],
    },
    table: {
      rows: table.rows,
      headers: table.headers.map(column => {
        const name = camelCase(column.id);
        return {
          name,
          value: name,
          label: column.label,
          width: widths[column.id] || 150,
          duration: Boolean(column.duration),
          sortable: Boolean(column.sortable),
          ...CommonDisplayPreferences,
          ...ListDisplayPreferences[name],
        }
      }),
    },
  };
}
