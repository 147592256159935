

import _ from 'lodash';

const Filters = {
  calls: {
    items: [
      { key: 'Total', metric: 'total', label: 'Total', selected: false },
      { key: 'Inbound', metric: 'inbound', label: 'Inbound', selected: false },
      { key: 'Outbound', metric: 'outbound', label: 'Outbound', selected: false },
      { key: 'Toll free (I)', metric: 'tollfree_inbound', label: 'Toll free (I)', selected: false },
      { key: 'International (O)', metric: 'international_outbound', label: 'International (O)', selected: false },
      { key: 'Missed', metric: 'missed', label: 'Missed', selected: false },
      { key: 'Unique dials', metric: 'unique_calls', label: 'Unique dials', selected: false },
      { key: 'Answered', metric: 'answered', label: 'Answered', selected: false },
      { key: 'Internal Total', metric: 'internal_total', label: 'Internal Total', selected: false },
      { key: 'External Total', metric: 'external_total', label: 'External Total', selected: false },
      { key: 'Five9 Total', metric: 'five9_total', label: 'Five9 Total', selected: false },
    ],
  },
  calls_talk_time: {
    items: [
      { key: 'Total', metric: 'total', label: 'Total', selected: false },
      { key: 'Inbound', metric: 'inbound', label: 'Inbound', selected: false },
      { key: 'Outbound', metric: 'outbound', label: 'Outbound', selected: false },
      { key: 'Toll free (I)', metric: 'tollfree_inbound', label: 'Toll free (I)', selected: false },
      { key: 'International (O)', metric: 'international_outbound', label: 'International (O)', selected: false },
      { key: 'Total talk time', metric: 'total_duration', label: 'Total talk time', selected: false },
      { key: 'Inbound talk time', metric: 'inbound_duration', label: 'Inbound talk time', selected: false },
      { key: 'Outbound talk time', metric: 'outbound_duration', label: 'Outbound talk time', selected: false },
      { key: 'Toll free talk time (I)', metric: 'tollfree_duration', label: 'Toll free talk time (I)', selected: false },
      { key: 'International talk time (O)', metric: 'international_duration', label: 'International talk time (O)', selected: false },
      { key: 'Five9 Total', metric: 'five9_total', label: 'Five9 Total', selected: false },
      { key: 'Five9 Duration', metric: 'five9_duration', label: 'Five9 Duration', selected: false },
    ],
  },
  call_duration: {
    items: [
      { key: 'Total talk time', metric: 'total_duration', label: 'Total talk time', selected: false },
      { key: 'Inbound talk time', metric: 'inbound_duration', label: 'Inbound talk time', selected: false },
      { key: 'Outbound talk time', metric: 'outbound_duration', label: 'Outbound talk time', selected: false },
      { key: 'Toll free talk time (I)', metric: 'tollfree_duration', label: 'Toll free talk time (I)', selected: false },
      { key: 'International talk time (O)', metric: 'international_duration', label: 'International talk time (O)', selected: false },
      { key: 'Average inbound talk time', metric: 'inbound_duration', agg_func: 'avg', label: 'Average inbound talk time', selected: false },
      { key: 'Average outbound talk time', metric: 'outbound_duration', agg_func: 'avg', label: 'Average outbound talk time', selected: false },
      { key: 'Average talk time', metric: 'total_duration', 'agg_func': 'avg', label: 'Average talk time', selected: false },
      { key: 'Longest call', metric: 'total_duration', agg_func: 'max', label: 'Longest call', selected: false },
      { key: 'Longest inbound Call', metric: 'inbound_duration', agg_func: 'max', label: 'Longest inbound Call', selected: false },
      { key: 'Longest outbound Call', metric: 'outbound_duration', agg_func: 'max', label: 'Longest outbound Call', selected: false },
      { key: 'Internal duration', metric: 'internal_duration', label: 'Internal duration', selected: false },
      { key: 'External duration', metric: 'external_duration', label: 'External duration', selected: false },
      { key: 'Five9 Duration', metric: 'five9_duration', label: 'Five9 Duration', selected: false },
    ],
  },
  locations: {
    items: [
      { key: 'dallas', label: 'Dallas', selected: false },
      { key: 'new-york', label: 'New York', selected: false },
      { key: 'scottsdale', label: 'Scottsdale', selected: false },
      { key: 'la', label: 'Los Angeles', selected: false },
    ],
  },
  call_groups: {
    items: [
      { key: 'group-a', label: 'Group A', selected: false },
      { key: 'group-b', label: 'Group B', selected: false },
      { key: 'group-c', label: 'Group C', selected: false },
      { key: 'group-d', label: 'Group D', selected: false },
      { key: 'group-e', label: 'Group E', selected: false },
    ],
  },
  distribution_calls: {
    items: [
      { key: 'answered', metric: 'answered', label: 'Answered', selected: false },
      { key: 'missed', metric: 'missed', label: 'Missed', selected: false },
    ],
  },
  distribution_talk_time: {
    items: [
      { key: 'inbound_duration', metric: 'inbound_duration', label: 'Inbound Duration', selected: false },
    ],
  },
  phone_numbers: {
    items: [
      { key: 'phone-a', label: '234234234', selected: false },
      { key: 'phone-b', label: '123123447', selected: false },
      { key: 'phone-c', label: '123123445', selected: false },
      { key: 'phone-d', label: '123123445', selected: false },
      { key: 'phone-e', label: '123123444', selected: false },
    ],
  },
  extensions: {
    items: [
      { key: 'extension-a', label: '0000', selected: false },
      { key: 'extension-b', label: '0001', selected: false },
      { key: 'extension-c', label: '0002', selected: false },
      { key: 'extension-d', label: '0003', selected: false },
      { key: 'extension-e', label: '0004', selected: false },
    ],
  },
  users: {
    items: [
      { key: 'robert', label: 'Robert B. Weide', selected: false },
      { key: 'larry', label: 'Larry Charles', selected: false },
      { key: 'bryan', label: 'Bryan Gordon', selected: false },
      { key: 'david-steinberg', label: 'David Steinberg', selected: false },
      { key: 'alec', label: 'Alec Berg', selected: false },
      { key: 'david-mandel', label: 'David Mandel', selected: false },
      { key: 'jeff', label: 'Jeff Schaffer', selected: false },
    ],
  },
  call_log: {
    items: [
      { key: 'start_time', metric: 'start_time', label: 'Time of Call', selected: false },
      { key: 'talk_duration_seconds', metric: 'talk_duration_seconds', label: 'Duration', selected: false },
      { key: 'direction', metric: 'direction', label: 'Direction', selected: false },
      { key: 'connected', metric: 'connected', label: 'Answered', selected: false },
      { key: 'customer_area_code_state', metric: 'customer_area_code_state', label: 'State', selected: false },
      { key: 'calling_number', metric: 'calling_number', label: 'From', selected: false },
      { key: 'called_number', metric: 'called_number', label: 'To', selected: false },
    ],
  },
  customer_area_codes: {
    items: [
      { key: '602', label: '602', selected: false },
      { key: '480', label: '580', selected: false },
    ],
  },
  teams: {
    items: [],
  },
  smses: {
    items: [
      { key: 'sms_sent', metric: 'TOTAL_SENT', label: 'Number Sent', selected: true },
      { key: 'sms_received', metric: 'TOTAL_RECEIVED', label: 'Number Received', selected: true },
      { key: 'sms_failed', metric: 'TOTAL_FAILED', label: 'Number Failed', selected: true },
    ],
  },
  sms_log: {
    items: [
      { key: 'start_time', metric: 'start_time', label: 'Time', selected: true },
      { key: 'calling_number', metric: 'calling_number', label: 'Sender', selected: true },
      { key: 'called_number', metric: 'called_number', label: 'Recipients', selected: true },
    ],
  },
};

Filters.call_log_users = {
  items: [
    { key: 'remote_user_id', metric: 'remote_user_id', label: 'Transfer User', selected: false },
    { key: 'user_id', metric: 'user_id', label: 'Name', selected: false },
  ].concat(Filters.call_log.items),
};

Filters.call_log_locations = {
  items: [
    { key: 'location', metric: 'location', label: 'Location', selected: false },
    { key: 'user_id', metric: 'user_id', label: 'Name', selected: false },
  ].concat(Filters.call_log.items),
};

Filters.call_log_call_groups = {
  items: [
    { key: 'remote_user_id', metric: 'remote_user_id', label: 'Transfer User', selected: false },
    { key: 'call_group', metric: 'user_id', label: 'Call Group', selected: false },
  ].concat(Filters.call_log.items),
};

Filters.call_log_phone_numbers = {
  items: [
    { key: 'user_id', metric: 'user_id', label: 'Name', selected: false },
  ].concat(Filters.call_log.items),
};

Filters.call_log_customer_area_codes = {
  items: [
    { key: 'customer_area_code', metric: 'customer_area_code', label: 'Area code', selected: false },
    { key: 'user_id', metric: 'user_id', label: 'Name', selected: false },
  ].concat(Filters.call_log.items),
};

Filters.flatten = () => {
  const filters = {};
  _.each(['calls', 'call_duration', 'call_log'], (section) => {
    _.each(Filters[section].items, (row) => {
      filters[row.key] = row.label;
    });
  });

  filters.user_id = 'User';
  filters.location = 'Location';
  filters.call_group = 'Call Group';
  filters.phone_number = 'Phone number';
  filters.customer_area_code = 'Area code';

  return filters;
};

export default Filters;
