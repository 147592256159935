import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import vex from 'lib/vex';

import AppData from 'app_data';
import Theme from 'theme';
import BarChart from 'components/charts/bar_chart';
import data from 'components/charts/dummydata';
import Button from 'components/button';


class ColorThemes extends Component {

  state = {
    previousTheme: Theme.getTheme(),
    theme: Theme.getTheme(),
  };

  cancel = () => {
    this.setState({ theme: this.state.previousTheme });
    Theme.setTheme(this.state.previousTheme);
  };

  changeTheme = (theme) => {
    if (this.state.theme === theme) return;
    this.setState({ previousTheme: this.state.theme, theme });
    Theme.setTheme(theme);
    this.forceUpdate();
  };

  submit = () => {
    AppData.putNextosThemeSettings({ 'theme': this.state.theme })
      .then((result) => {
        vex.success('Saved');
      })
      .catch((error) => {
        vex.error([], null, 'Error saving color theme');
      });
  };

  render() {
    return (
      <div className="color-themes">
        <div className="color-themes__settings">
          <div className="preview">
            <BarChart
              chartKey="demo-chart"
              chartData={data.demoBar}
              unit="calls"
              disableDownload
              barConfig={{}}
            />
          </div>

          <div className="settings">
            <h1>Themes</h1>

            <div className="palette-container">
              <input
                id="clean-theme"
                type="checkbox"
                checked={this.state.theme === 'clean'}
                onChange={this.changeTheme.bind(this, 'clean')}
              />

              <label htmlFor="clean-theme">Clean & Bright</label>
              <Palette name="clean" />

              <input
                id="earthy-theme"
                type="checkbox"
                name="earthy"
                checked={this.state.theme === 'earthy'}
                onChange={this.changeTheme.bind(this, 'earthy')}
              />

              <label htmlFor="earthy-theme">Earthy & Muted</label>
              <Palette name="earthy" />
            </div>
          </div>
        </div>

        <div className="button-row">
          <div className="buttons">
            <Button
              className="btn"
              onClick={this.cancel}
              aria-label="Cancel"
            >
              Cancel
            </Button>

            <Button
              className="btn btn-green"
              onClick={this.submit}
              aria-label="Save"
            >
              Update
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

class Palette extends Component {
  static propTypes = {
    /** Palette name */
    name: PropTypes.string,
  };

  componentWillMount() {
    this.colors = Theme.getPalette(this.props.name);
  }

  render() {
    const elements = _.map(this.colors, (color, index) => <PaletteColor key={index} color={color} />);

    return (
      <div className="palette">{elements}</div>
    );
  }
}

const PaletteColor = ({ color }) => (
  <div className="color-block" style={{ 'backgroundColor': color }} />
);

PaletteColor.propTypes = {
  /** Hex color for the palette color */
  color: PropTypes.string,
};

export default ColorThemes;
