import React from 'react'
import { useTable, useExpanded, useSortBy } from 'react-table'
import { ReactComponent as Arrow } from 'img/arrow.svg';
import cx from 'classnames';

const  CustomTable = ({ columns: userColumns, data, component, className })=> {
    const Component = component;
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      page,
      prepareRow,
      state: { },
    } = useTable(
      {
        columns: userColumns,
        data,
      },
      useSortBy,
      useExpanded, // Use the useExpanded plugin hook
    )

    return (
      <>
        <table {...getTableProps()} className={className}>
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}
                    style= {{width: column.width}}
                >
                  {column.render('Header')}
                  {!column.disableSortBy && (
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ?  <span className="sort-arrow-container"><Arrow className={cx(`arrow-sort ${'arrow-down'}`)}/></span>
                          : <span className="sort-arrow-container"><Arrow className={cx(`arrow-sort ${'arrow-up'}`)}/></span>
                        : <span className="sort-arrow-container">
                            <Arrow className={cx(`arrow-sort ${'arrow-up'}`)}/>
                          </span>}
                    </span>
                  )}
                  
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            { rows.map((row, i) => {
              prepareRow(row)
              return (
                <>
                    <tr {...row.getRowProps()}>
                    {row.cells.map(cell => {
                        return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    })}
                    </tr>
                    {row.isExpanded &&(
                        <tr className="expanded-container">
                            <td colSpan={userColumns.length} style={{padding:0}}>
                            <Component row={row}/>
                            </td>
                        </tr>
                    )}
                </>
              )
            })}
          </tbody>
        </table>

      </>
    )
  }

  export default CustomTable;
