export default {
  custom: {
    ui: {
      errors: {
        title: {
          hasError: false,
          errorMessage: 'Missing a report title',
        },
        select: {
          hasError: false,
          errorMessage: 'Missing at least one call type to report on.',
        },
        filter: {
          hasError: false,
          errorMessage: 'Missing at least 1 filter',
        },
      },
      treePath: {
        report: null,
        reportGroup: null,
        format: null,
        breakdown: null,
        display: null,
      },
      title: '',
      chart: null,
      dropzones: [],
      filters: {},
      teamType: null,
      dropzoneLimits: {},
      timeRanges: [],
      realTime: false,
    },
  },
  controls: {
    quality: {
      ready: false,
      apiFilters: {},
      metadata: null,
      timeRange: {},
      realTime: false,
      filterType: null,
      filters: [],
      filterOptions: [],
      callFilterOptions: null,
      callFilter: null,
      section: 'summary',
      page: 'summary',
    },
    dashboard: {
      ready: false,
      apiFilters: {},
      metadata: null,
      callTypes: [],
      filters: [],
      filterOptions: [],
      timeRange: {},
      realTime: false,
      boardType: { id: 'iconic', label: 'Iconic' },
      filterType: { id: 'users', label: 'Users', bucketType: 'users' },
      callType: { id: 'outbound', label: 'Outbound' },
      filterTypeOptions: [
        { id: 'users', label: 'Users', bucketType: 'users' },
        { id: 'locations', label: 'Locations', bucketType: 'locations' },
        { id: 'call_groups', label: 'Call Groups', bucketType: 'call_groups' },
        { id: 'teams', teamType: 'users', label: 'Custom Groups: Users', bucketType: 'teams' },
        { id: 'teams', teamType: 'locations', label: 'Custom Groups: Locations', bucketType: 'teams' },
        { id: 'teams', teamType: 'call_groups', label: 'Custom Groups: Call Groups', bucketType: 'teams' },
        { id: 'teams', teamType: 'phone_numbers', label: 'Custom Groups: Phone Numbers', bucketType: 'teams' },
      ],
      callTypeOptions: [],
      page: 'dashboard',
    },
    wallboards: {
      ready: false,
      apiFilters: {},
      metadata: null,
      callTypes: [],
      filters: [],
      filterOptions: [],
      timeRange: {},
      realTime: false,
      boardType: { id: 'iconic', label: 'Iconic' },
      filterType: { id: 'users', label: 'Users', bucketType: 'users' },
      callType: { id: 'outbound', label: 'Outbound' },
      filterTypeOptions: [
        { id: 'users', label: 'Users', bucketType: 'users' },
        { id: 'locations', label: 'Locations', bucketType: 'locations' },
        { id: 'call_groups', label: 'Call Groups', bucketType: 'call_groups' },
        { id: 'teams', teamType: 'users', label: 'Custom Groups: Users', bucketType: 'teams' },
        { id: 'teams', teamType: 'locations', label: 'Custom Groups: Locations', bucketType: 'teams' },
        { id: 'teams', teamType: 'call_groups', label: 'Custom Groups: Call Groups', bucketType: 'teams' },
        { id: 'teams', teamType: 'phone_numbers', label: 'Custom Groups: Phone Numbers', bucketType: 'teams' },
      ],
      callTypeOptions: [],
      page: 'wallboards',
    },
    gamification: {
      ready: false,
      apiFilters: {},
      metadata: null,
      callTypes: [],
      filters: [],
      filterOptions: [],
      timeRange: {},
      realTime: false,
      boardType: { id: 'leaderboard_top', label: 'Leaderboard top', nBuckets: 10, sort: 'desc' },
      boardTheme: { id: 'theme_default', label: 'Theme: Default' },
      userCount: { id: 'users_10', label: '10 per page', perPage: 10 },
      totalUsers: 10,
      filterType: { id: 'users', label: 'Users' },
      callType: { id: 'total', label: 'Total' },
      filterTypeOptions: [
        { id: 'users', label: 'Users' },
        { id: 'locations', label: 'Locations' },
        { id: 'call_groups', label: 'Call Groups' },
        { id: 'teams', teamType: 'users', label: 'Custom Groups: Users', bucketType: 'teams' },
        { id: 'teams', teamType: 'locations', label: 'Custom Groups: Locations', bucketType: 'teams' },
        { id: 'teams', teamType: 'call_groups', label: 'Custom Groups: Call Groups', bucketType: 'teams' },
        { id: 'teams', teamType: 'phone_numbers', label: 'Custom Groups: Phone Numbers', bucketType: 'teams' },
      ],
      callTypeOptions: [
        { id: 'total', label: 'Total' },
        { id: 'outbound', label: 'Outbound' },
        { id: 'inbound', label: 'Inbound' },
      ],
      boardTypeOptions: [
        { id: 'leaderboard_top', label: 'Leaderboard top', nBuckets: 10, sort: 'desc' },
        { id: 'leaderboard_bottom', label: 'Leaderboard bottom', nBuckets: 10, sort: 'asc' },
        { id: 'leaderboard_left_top', label: 'Leaderboard left avatar top', nBuckets: 10, avatarStyle: 'avatar-left', sort: 'desc' },
        { id: 'leaderboard_left_bottom', label: 'Leaderboard left avatar bottom', nBuckets: 10, avatarStyle: 'avatar-left', sort: 'asc' },
        { id: 'leaderboard_right_top', label: 'Leaderboard right avatar top', nBuckets: 10, avatarStyle: 'avatar-right', sort: 'desc' },
        { id: 'leaderboard_right_bottom', label: 'Leaderboard right avatar bottom', nBuckets: 10, avatarStyle: 'avatar-right', sort: 'asc' },
      ],
      boardThemeOptions: [
        { id: 'theme_default', label: 'Theme: Default' },
        { id: 'theme_city', label: 'Theme: City' },
        { id: 'theme_retro', label: 'Theme: Retro' },
        { id: 'theme_sports', label: 'Theme: Sports' },
        { id: 'theme_sports_2', label: 'Theme: Sports 2' },
        { id: 'theme_sports_3', label: 'Theme: Sports 3' },
        { id: 'theme_tech', label: 'Theme: Tech' },
      ],
      perPageOptions: [
        { id: 'users_5', label: '5 per page', perPage: 5 },
        { id: 'users_10', label: '10 per page', perPage: 10 },
        { id: 'users_15', label: '15 per page', perPage: 15 },
        { id: 'users_20', label: '20 per page', perPage: 20 },
        { id: 'users_25', label: '25 per page', perPage: 25 },
      ],
      page: 'gamification',
    },
    scorecards: {
      ready: false,
      apiFilters: {},
      metadata: null,
      period: null,
      periodOptions: null,
      filters: [],
      filterOptions: [],
      timeRange: {},
      realTime: false,
      filterType: { id: 'users', label: 'Users' },
      filterTypeOptions: [
        { id: 'users', label: 'Users' },
        { id: 'locations', label: 'Locations' },
        { id: 'call_groups', label: 'Call Groups' },
        { id: 'teams', teamType: 'users', label: 'Custom Groups: Users', bucketType: 'teams' },
        { id: 'teams', teamType: 'locations', label: 'Custom Groups: Locations', bucketType: 'teams' },
        { id: 'teams', teamType: 'call_groups', label: 'Custom Groups: Call Groups', bucketType: 'teams' },
        { id: 'teams', teamType: 'phone_numbers', label: 'Custom Groups: Phone Numbers', bucketType: 'teams' },
      ],
      page: 'scorecards',
    },
    reporting: {
      ready: false,
      apiFilters: {},
      metadata: null,
      timeRange: {},
      realTime: false,
      filterType: null,
      filters: [],
      filterOptions: [],
      callFilterOptions: null,
      callFilter: null,
      section: null,
      page: 'reporting',
    },
    trend: {
      ready: false,
      apiFilters: {},
      metadata: null,
      timeRange: {},
      realTime: false,
      filterType: null,
      filterTypeLabel: null,
      filters: [],
      filterOptions: [],
      callType: null,
      callTypes: null,
      callTypeOptions: null,
      page: 'trend',
    },
    comparison: {
      ready: false,
      apiFilters: {},
      metadata: null,
      timeRange: {},
      realTime: false,
      filterType: null,
      filterTypeLabel: null,
      filters: [],
      filterOptions: [],
      callType: null,
      callTypes: null,
      callTypeOptions: null,
      page: 'comparison',
    },
    period: {
      ready: false,
      apiFilters: {},
      metadata: null,
      timeRanges: [],
      realTime: false,
      filterType: null,
      filterTypeLabel: null,
      filters: [],
      filterOptions: [],
      callType: null,
      callTypes: null,
      callTypeOptions: null,
      page: 'period',
    },
    callDuration: {
      ready: false,
      apiFilters: {},
      metadata: null,
      timeRange: null,
      realTime: false,
      filterType: null,
      filterTypeLabel: null,
      filters: [],
      filterOptions: [],
      callType: null,
      callTypes: null,
      callTypeOptions: null,
      minuteBuckets: null,
      page: 'callDuration',
    },
  },
  team: {
    ui: {
      errors: {
        title: {
          hasError: false,
          errorMessage: 'Missing a group title',
        },
        grouping: {
          hasError: false,
          errorMessage: 'Missing group grouping',
        },
        filter: {
          hasError: false,
          errorMessage: 'Missing at least 1 filter',
        },
      },
      title: null,
      description: null,
      group_type: '',
      dropzoneState: {},
      dropzones: [],
      filters: {},
      selectOptions: [
        { key: 'users', label: 'Users' },
        { key: 'phone_numbers', label: 'Phone Numbers' },
        { key: 'locations', label: 'Locations' },
        { key: 'call_groups', label: 'Call Groups' },
      ],
    },
  },
  custom_dashboard: {
    errors: {
      title: {
        hasError: false,
        errorMessage: 'Missing a dashboard title',
      },
      tile: {
        hasError: false,
        errorMessage: 'Missing at least one tile',
      },
      editing: {
        hasError: false,
        errorMessage: 'One or more tiles in an edit state',
      },
    },
    isLoading: true,
    title: null,
    description: null,
    items: [],
    layout: [],
    breakpoint: null,
    cols: 12,
    tileType: 'reports',
    isEditing: false,
    newCounter: 0,
    timeRange: null,
    realTime: false,
    tile_options_page: 0,
    tile: {},
    isNew: true,
  },
  user_management: {
    ui: {
      errors: {
        avatar: {
          hasError: false,
          errorMessage: 'Missing an image file',
        },
      },
      userId: null,
      displayName: null,
      avatar: null,
    },
  },
};
