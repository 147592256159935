import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import MultiSelect from 'components/multi_select_core';

// react_select.scss

class MultiSelectContainer extends React.Component {
  static propTypes = {
    /** The icon for the multiselect container */
    icon: PropTypes.string,
    /** Alignment options for the multiselect container */
    alignOptions: PropTypes.string,
    /** The class name of the multi select container */
    className: PropTypes.string,
    /** The label of the multiselect container */
    label: PropTypes.string,
    /** The initially selected options in the multi select dropdown */
    initialSelected: PropTypes.array,
    /** Array of the options which are presented in the drop down */
    dropdownOptions: PropTypes.arrayOf(PropTypes.object),
    /** Handler for when save is selected from the drop down */
    onDropdownSave: PropTypes.func,
    /** Whether or not the search bar is enabled */
    searchBar: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    const selected = props.initialSelected || [];
    const label = selected.length > 0 ? 'Remove all' : 'Select all';
    this.state = {
      selected,
      selectLabel: label,
      filterText: '',
    };
  }

  componentWillReceiveProps(newProps) {
    const props = this.props;
    if (props.label !== newProps.label || !props.dropdownOptions ||
      newProps.dropdownOptions.length !== props.dropdownOptions.length ||
      !_.isEqual(newProps.initialSelected, props.initialSelected)) {
      const selected = newProps.initialSelected || [];
      const label = selected.length > 0 ? 'Remove all' : 'Select all';
      this.setState({ selected, 'selectLabel': label });
    }
  }

  handleDropdownChange = (opts) => {
    const obj = { selected: opts };
    const allOpts = this.props.dropdownOptions;
    const label = opts.length < allOpts.length && 'Select all' || opts.length === allOpts.length && 'Remove All' || null;
    if (label) obj.selectLabel = label;
    this.setState(obj);
  };

  handleSave = () => {
    const selected = _.filter(this.props.dropdownOptions, (o) => this.state.selected.indexOf(o.id) !== -1);

    this.props.onDropdownSave(selected);
  };

  handleFilterChange = (e) => {
    this.setState({ filterText: e.target.value });
  };

  toggleSelectAll = () => {
    if (!this.props.dropdownOptions) {
      return;
    }
    if ((this.state.selected.length < this.props.dropdownOptions.length) && (this.state.selectLabel === 'Select all')) {
      this.setState({
        selected: _.map(this.props.dropdownOptions, (item) => item.id),
        selectLabel: 'Remove all',
      });
    } else {
      this.setState({
        selected: [],
        selectLabel: 'Select all',
      });
    }
  };

  render() {
    let options;
    if (this.props.dropdownOptions && this.props.dropdownOptions.length) {
      options = _.map(this.props.dropdownOptions, (opt, index) => (
        <option key={index} value={opt.id}>{opt.label}</option>
      ));
    }

    return (
      <MultiSelect
        handleFilterChange={this.handleFilterChange}
        filterText={this.state.filterText}
        searchBar={this.props.searchBar}
        toggleSelectAll={this.toggleSelectAll}
        className={this.props.className}
        alignOptions={this.props.alignOptions}
        icon={this.props.icon}
        label={_.startCase(this.props.label)}
        multiple
        onChange={this.handleDropdownChange}
        onSave={this.handleSave}
        value={this.state.selected}
        selectLabel={this.state.selectLabel}
        closeText={'Update'}
      >
        {options}
      </MultiSelect>
    );
  }
}

export default MultiSelectContainer;
