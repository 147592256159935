import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import cx from 'classnames';
import onClickOutside from 'react-onclickoutside';

import Utils from 'utils/common_utils';
import InlineSvgFactory from 'components/inline_svg';
import Button from 'components/button';

import { ReactComponent as ArrowSvg } from 'img/arrow_down.svg';


class IconDropdown extends Component {
  static propTypes = {
    /** The classname of the icon dropdown */
    className: PropTypes.string,
    /** The default values for the icon dropdown */
    defaultValue: PropTypes.object,
    /** The icon of the icon dropdown */
    fixedIcon: PropTypes.object,
    /** Handler for when the icon dropdown is edited */
    onEdit: PropTypes.func.isRequired,
    /** An array of the options in the icon dropdown */
    options: PropTypes.array,
    /** Whether or not to hide the arrow */
    hideArrow: PropTypes.bool,
    /** The label for the icon dropdown */
    ariaLabel: PropTypes.string,
  };

  state = {
    selected: this.props.defaultValue,
    dropdown: false,
  };

   shouldComponentUpdate(nextProps, nextState) {
     const { selected, dropdown } = this.state;
     return (
       nextState.selected !== selected ||
       nextState.dropdown !== dropdown
     );
   }

  setSelected = (chart) => {
    // If using fixedIcon mode, don't update top level icon
    if (!this.props.fixedIcon) {
      this.setState({ selected: chart, dropdown: false });
    } else {
      this.setState({ dropdown: false });
    }

    this.props.onEdit(chart);
  };

  handleClickOutside = () => {
    this.setState({ dropdown: false });
  };

  toggleDropdown = () => {
    const empty = this.props.options.length === 0;
    const fixedEmpty = !this.props.fixedIcon && this.props.options.length === 1;

    if (empty || fixedEmpty) return;

    this.setState({ dropdown: !this.state.dropdown });
  };

  render() {
    const { dropdown } = this.state;
    const { className } = this.props;

    const hideArrow = this.props.hideArrow || this.props.options.length === 1;
    let SelectedSvg;
    // Ability to use a fixed icon
    if (!this.props.fixedIcon) {
      if (this.state.selected.icon) {
        SelectedSvg = InlineSvgFactory(this.state.selected.icon);
      } else {
        // eslint-disable-next-line
        console.warn(`Icon not set for '${this.state.selected.name}' and not using fixed icon mode`);
        return <small>Invalid Icon</small>;
      }
    } else {
      SelectedSvg = typeof(this.props.fixedIcon.icon) === 'string'
        ? InlineSvgFactory(this.props.fixedIcon.icon)
        : this.props.fixedIcon.icon;
    }

    const options = _.map(this.props.options, (option, index) => {
      if (option.name === this.state.selected.name && !this.props.fixedIcon) return;
      let OptionSvg;

      if (option.icon) {
        OptionSvg = typeof(option.icon) === 'string'
          ? InlineSvgFactory(option.icon)
          : option.icon;
      }

      return (
        <div key={index} data-testid="dropdown-option" className="option" onClick={this.setSelected.bind(this, option)}>
          <div className="text">
            {Utils.capitalize(option.name)}
          </div>
          {OptionSvg ? <OptionSvg className={`svg-${_.kebabCase(option.name)}`} /> : null}
        </div>
      );
    });

    let chartToggler;
    if (this.props.options.length > 0) {
      chartToggler = (
        <Button
          data-testid="dropdownButton"
          className="btn-transparent selected"
          onClick={this.toggleDropdown}
          aria-label={this.props.ariaLabel}
        >
          <SelectedSvg className={`svg-${_.kebabCase(this.state.selected.name)}`} />
          {!hideArrow ? <ArrowSvg className="triangle" /> : null}
        </Button>
      );
    } else {
      chartToggler = (
        <div className="selected" style={{ cursor: 'default' }}>
          <SelectedSvg className={`svg-${_.kebabCase(this.state.selected.name)}`} />
        </div>
      );
    }

    const rootClassName = cx('chart-dropdown', className, {
      active: dropdown,
    });

    return (
      <div className={rootClassName}>
        {chartToggler}

        {dropdown && (
          <div data-testid="dropdown-options" className="options">
            {options}
          </div>
        )}
      </div>
    );
  }
}

export default onClickOutside(IconDropdown);
