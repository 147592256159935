import React, { Component } from 'react';
import PropTypes from 'prop-types';

import TabbedControls from 'components/tabbed_controls';
import ListSelect from 'components/list_select';
import MultiSelect from 'components/multi_select';

class CallTypeTileControls extends Component {
  static propTypes = {
    /** State of the call type tile */
    tileInfo: PropTypes.shape({
      /** Title for the tile */
      title: PropTypes.string,
      /** User type filter */
      filter: PropTypes.string,
      /** Filters for the current user type */
      filters: PropTypes.array,
      /** Call type metric */
      callType: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
      ]),
      /** Validation errors for the tile */
      errors: PropTypes.object,
    }).isRequired,
    /** Handler for title change */
    onTitleChange: PropTypes.func,
    /** User type filters */
    filterOptions: PropTypes.array,
    /** Handler for filter change */
    onFilterChange: PropTypes.func,
    /** Filter options based on filter choice */
    filtersOptions: PropTypes.array,
    /** Handler for filters change */
    onFiltersChange: PropTypes.func,
    /** Call type metric options */
    callTypeOptions: PropTypes.array,
    /** Handler for call type change */
    onCallTypeChange: PropTypes.func,
    /** Elements for the thresholds area */
    thresholdsSection: PropTypes.func,
  }

  getTitleTab() {
    const { tileInfo, onTitleChange } = this.props;
    const tab = (
      <div className="custom-dashboard-controls-title">
        <input
          type="text"
          placeholder="Title"
          onChange={onTitleChange}
          value={tileInfo.title}
        />
      </div>
    );
    return tab;
  }

  getFilterTab() {
    const { tileInfo, onFilterChange, filterOptions } = this.props;
    const selected = {
      key: tileInfo.filter,
    };
    const tab = (
      <ListSelect
        options={filterOptions}
        onSelect={onFilterChange}
        selected={selected}
        compareField="key"
      />
    );
    return tab;
  }

  getFiltersTab() {
    const { tileInfo, onFiltersChange, filtersOptions } = this.props;
    const selected = tileInfo.filters;
    const tab = (
      <MultiSelect
        searchBar
        options={filtersOptions}
        onChange={onFiltersChange}
        initialSelected={selected}
      />
    );
    return tab;
  }

  getCallTypeTab() {
    const { tileInfo, onCallTypeChange, callTypeOptions } = this.props;
    let selected;
    if (tileInfo.callType) {
      selected = tileInfo.callType;
    }
    const tab = (
      <ListSelect
        options={callTypeOptions}
        onSelect={onCallTypeChange}
        selected={selected}
        compareField="key"
      />
    );
    return tab;
  }

  getThresholdsTab() {
    const { thresholdsSection } = this.props;
    const tab = thresholdsSection();
    return tab;
  }

  render() {
    const { errors } = this.props.tileInfo;
    const tabs = [
      { icon: 'icon-custom-gear', label: 'Title', content: this.getTitleTab(), className: (errors.title ? 'error' : null) },
      { icon: 'icon-custom-people', label: 'Filter', content: this.getFilterTab(), className: (errors.filter ? 'error' : null) },
      { icon: 'icon-custom-filter', label: 'Filters', content: this.getFiltersTab(), className: (errors.filters ? 'error' : null) },
      { icon: 'icon-custom-sliders', label: 'Call Type', content: this.getCallTypeTab(), className: (errors.callType ? 'error' : null) },
      { icon: 'icon-custom-palette', label: 'Thresholds', content: this.getThresholdsTab(), className: (errors.thresholds ? 'error' : null) },
    ];
    return (
      <TabbedControls
        tabs={tabs}
      />
    );
  }
}

export default CallTypeTileControls;

