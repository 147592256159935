import _ from 'lodash';
import moment from 'moment-timezone';

import AppData from 'app_data';
import Utils from 'utils/common_utils';

const generateGraphObject = function (obj) {
  return _.merge({
    label: obj.label,
    name: obj.name,
    key: obj.key || obj.label.toLowerCase(),
    icon: obj.icon || `${obj.label.toLowerCase()}.svg`,
    iconClass: obj.iconClass || 'icon-right',
  }, obj);
};

const column = function (opts) {
  if (!opts) opts = {};
  opts.label = 'Column';
  opts.name = 'column';
  opts.key = 'column';
  return generateGraphObject(opts);
};

const bar = function (opts) {
  if (!opts) opts = {};
  opts.label = 'Bar';
  opts.name = 'bar';
  opts.key = 'bar';
  return generateGraphObject(opts);
};

const pie = function (opts) {
  if (!opts) opts = {};
  opts.label = 'Pie';
  opts.name = 'pie';
  opts.key = 'pie';
  return generateGraphObject(opts);
};

const table = function (opts) {
  if (!opts) opts = {};
  opts.label = 'Table';
  opts.name = 'table';
  opts.key = 'table';
  return generateGraphObject(opts);
};

const line = function (opts) {
  if (!opts) opts = {};
  opts.label = 'Line';
  opts.name = 'line';
  opts.iconClass = 'icon-right line';
  return generateGraphObject(opts);
};

const stackedColumn = function (opts) {
  if (!opts) opts = {};
  opts.label = 'Stacked column';
  opts.name = 'stacked-column';
  opts.key = 'stacked_column';
  opts.icon = 'stacked.svg';
  return generateGraphObject(opts);
};

const stackedBar = function (opts) {
  if (!opts) opts = {};
  opts.iconClass = 'icon-right stacked-bar';
  opts.label = 'Stacked bar';
  opts.name = 'stacked-bar';
  opts.key = 'stacked_bar';
  opts.icon = 'stacked.svg';
  return generateGraphObject(opts);
};

const firstN = function (n, sort) {
  return function (obj) {
    if (sort) obj = _.sortBy(obj, 'label');
    return _.take(obj, n);
  };
};

const select = function (fields) {
  return function (obj) {
    return _.filter(obj, (item) => _.indexOf(fields, item.key) >= 0);
  };
};

const prevWeekPeriod = () => [
  {
    type: 'absolute',
    start: moment().tz(AppData.user.timezone).subtract(14, 'days').startOf('day').toISOString(),
    end: moment().tz(AppData.user.timezone).subtract(8, 'days').endOf('day').toISOString(),
  },
  {
    type: 'absolute',
    start: moment().tz(AppData.user.timezone).subtract(7, 'days').startOf('day').toISOString(),
    end: moment().tz(AppData.user.timezone).subtract(1, 'days').endOf('day').toISOString(),
  },
];

const nDays = (n) => [{
  type: 'absolute',
  start: moment().tz(AppData.user.timezone).subtract(n, 'days').startOf('day').toISOString(),
  end: moment().tz(AppData.user.timezone).endOf('day').toISOString(),
}];

const getGlobalTimeRanges = (nDaysFallback = 5) => (
  AppData.user.time_settings && AppData.user.time_settings.reporting ?
    [AppData.user.time_settings.reporting] :
    nDays(nDaysFallback)
);

const callDurationDefaults = [
  'inbound_duration',
  'outbound_duration',
  'international_duration',
  'tollfree_duration',
];

const all = function (obj) {
  return _.sortBy(obj, 'label');
};

function baseTree(label, altLabel) {
  // assuming plural label
  const dropzone = _.snakeCase(label);
  const toolTipLabel = label.toLowerCase();
  if (altLabel) {
    label = altLabel;
  } else {
    label = Utils.capitalize(label).slice(0, -1);
  }

  return [
    {
      label: `${label} calls`,
      groupLabel: label,
      key: 'calls',
      dropzones: ['calls', dropzone],
      toolTip: 'View data on inbound and outbound calls. View trends and compare call types (inbound, \
              outbound, toll free, etc.) for your calls within a specified period of time.',
      items: [
        {
          label: 'Aggregate',
          key: 'aggregate',
          dateDefault: getGlobalTimeRanges(5),
          toolTip: `View total calls broken down by call type (inbound, outbound, toll free, etc.) \
                  over a period of time for selected ${toolTipLabel}.`,
          availableFilters: [{
            copyFrom: 'calls',
            key: 'calls',
            label: 'Calls',
          }],
          dropzoneDefault: [
            {
              dropzone,
              fn: all,
            },
            {
              dropzone: 'calls',
              fn: firstN(5),
            }],
          items: [
            {
              label: 'Period summary',
              key: 'period_summary',
              toolTip: 'View all data combined within a period of time.',
              items: [column(), bar(), table(), pie()],
            },
            {
              label: 'Daily summary',
              key: 'daily_summary',
              toolTip: 'View data broken down by day over a period of time.',
              items: [column(), stackedColumn(), table()],
            },
            {
              label: 'Daily average',
              key: 'daily_average',
              toolTip: 'View the daily average of data within a period of time.',
              items: [column(), bar(), table(), pie()],
            },
            {
              label: 'Hourly average',
              key: 'hourly_average',
              toolTip: 'View the hourly average of data within a period of time.',
              items: [line(), stackedColumn(), column()],
            },
          ],
        },
        {
          label: 'Compare',
          key: 'compare',
          toolTip: `Compare selected ${toolTipLabel} by their calls broken down by call type (inbound, outbound, \
                  toll free, etc.) over a period of time.`,
          availableFilters: [{
            copyFrom: 'calls',
            key: 'calls',
            label: 'Calls',
          }],
          dropzoneDefault: [
            {
              dropzone,
              fn: firstN(10, true),
            },
            {
              dropzone: 'calls',
              fn: firstN(7),
            },
          ],
          limitDropzone: [
            {
              field: dropzone,
              len: 30,
            },
          ],
          dateDefault: getGlobalTimeRanges(5),
          items: [
            {
              label: 'Period summary',
              key: 'period_summary',
              toolTip: 'View all data combined within a period of time.',
              items: [
                stackedBar({
                  limitDropzone: [
                    {
                      field: dropzone,
                      len: null,
                    },
                  ],
                  dropzoneDefault: [
                    {
                      dropzone,
                      fn: firstN(10, true),
                    },
                  ],
                }),
                stackedColumn({
                  limitDropzone: [
                    {
                      field: dropzone,
                      len: 30,
                    },
                  ],
                  dropzoneDefault: [
                    {
                      dropzone,
                      fn: firstN(10, true),
                    },
                  ],
                }),
                table({
                  limitDropzone: [
                    {
                      field: dropzone,
                      len: null,
                    },
                  ],
                  dropzoneDefault: [
                    {
                      dropzone,
                      fn: all,
                    },
                  ],
                }),
              ],
            },
            {
              label: 'Daily summary',
              key: 'daily_summary',
              toolTip: 'View data broken down by day over a period of time.',
              items: [
                table({
                  limitDropzone: [
                    {
                      field: dropzone,
                      len: null,
                    },
                  ],
                  dropzoneDefault: [
                    {
                      dropzone,
                      fn: all,
                    },
                  ],
                }),
              ],
            },
            {
              label: 'Daily average',
              key: 'daily_average',
              toolTip: 'View the daily average of data within a period of time.',
              items: [stackedBar(), stackedColumn(),
                table({
                  limitDropzone: [
                    {
                      field: dropzone,
                      len: null,
                    },
                  ],
                }),
              ],
            },
          ],
        },
        {
          label: 'Trend analysis',
          key: 'trend',
          toolTip: `View the direction in which calls are trending over a period of time for selected ${toolTipLabel}.`,
          availableFilters: [{
            copyFrom: 'calls',
            key: 'calls',
            label: 'Calls',
          }],
          limitDropzone: [
          { field: 'calls', len: 1 },
          { field: dropzone, len: 10 },
          ],
          dropzoneDefault: [
          { dropzone, fn: firstN(10) },
          { dropzone: 'calls', fn: firstN(1) },
          ],
          dateDefault: getGlobalTimeRanges(5),
          items: [
            {
              label: 'Daily summary',
              key: 'daily_summary',
              toolTip: 'View data broken down by day over a period of time.',
              items: [line()],
            },
          ],
        },
        {
          label: 'Period over period',
          key: 'period',
          toolTip: `Compare total calls broken down by call type (inbound, outbound, toll free, etc.) over two periods of \
                  time for selected ${toolTipLabel}.`,
          availableFilters: [{
            copyFrom: 'calls',
            key: 'calls',
            label: 'Calls',
          }],
          dateDefault: prevWeekPeriod(),
          dropzoneDefault: [
          { dropzone: 'calls', fn: firstN(4) },
          { dropzone, fn: firstN(10) },
          ],
          items: [
            {
              label: 'Daily summary',
              key: 'daily_summary',
              toolTip: 'View data broken down by day over a period of time.',
              items: [line(), table()],
            },
          ],
        },
        {
          label: 'Call log',
          key: 'call_log',
          toolTip: `View a list of calls for selected ${toolTipLabel}.`,
          dateDefault: getGlobalTimeRanges(5),
          availableFilters: [{
            copyFrom: `call_log_${dropzone}`,
            key: 'call_log',
            label: 'Call Log',
          }],
          dropzones: ['call_log', dropzone],
          dropzoneDefault: [
          { dropzone, fn: firstN(50) },
          { dropzone: 'call_log', fn: firstN(10) },
          ],
          items: [
            {
              label: 'Individual calls',
              key: 'individual_calls',
              toolTip: 'View the individual calls within a period of time.',
              items: [table()],
            },
          ],
        },
      ],
    },
    {
      label: `${label} talk time`,
      groupLabel: label,
      key: 'talk_time',
      toolTip: 'View data on the total amount of time spent on calls.  View trends and compare call \
              types (inbound, outbound, toll free, etc.) for your talk times within a specific period of time.',
      availableFilters: [{
        copyFrom: 'call_duration',
        key: 'call_duration',
        label: 'Talk time',
      }],
      dropzoneDefault: [
      { dropzone: 'call_duration', fn: select(callDurationDefaults) },
      ],
      dropzones: ['call_duration', dropzone],
      items: [
        {
          label: 'Aggregate',
          key: 'aggregate',
          toolTip: `View total talk time broken down by call type (inbound, outbound, toll free, etc.) \
                  over a period of time for selected ${toolTipLabel}.`,
          dateDefault: getGlobalTimeRanges(5),
          dropzoneDefault: [
          { dropzone, fn: all },
          ],
          items: [
            {
              label: 'Period summary',
              key: 'period_summary',
              toolTip: 'View all data combined within a period of time.',
              items: [column(), bar(), table(), pie()],
            },
            {
              label: 'Daily summary',
              key: 'daily_summary',
              toolTip: 'View data broken down by day over a period of time.',
              items: [column(), table()],
            },
            {
              label: 'Daily average',
              key: 'daily_average',
              toolTip: 'View the daily average of data within a period of time.',
              items: [column(), bar(), pie(), table()],
            },
            {
              label: 'Hourly average',
              key: 'hourly_average',
              toolTip: 'View the hourly average of data within a period of time.',
              dropzoneDefault: [{
                dropzone,
                fn: firstN(4, true),
              }],
              items: [line(), stackedColumn()],
            },
          ],
        },
        {
          label: 'Compare',
          key: 'compare',
          toolTip: `Compare selected ${toolTipLabel} by their talk time broken down by call type \
                  (inbound, outbound, toll free, etc.) over a period of time.`,
          dropzoneDefault: [{
            dropzone,
            fn: firstN(30, true),
          }],
          dateDefault: getGlobalTimeRanges(7),
          limitDropzone: [
            {
              field: dropzone,
              len: 30,
            },
          ],
          items: [
            {
              label: 'Period summary',
              key: 'period_summary',
              toolTip: 'View all data combined within a period of time.',
              items: [stackedBar(), stackedColumn(), table()],
            },
            {
              label: 'Daily summary',
              key: 'daily_summary',
              toolTip: 'View data broken down by day over a period of time.',
              items: [table()],
            },
            {
              label: 'Daily average',
              key: 'daily_average',
              toolTip: 'View the daily average of data within a period of time.',
              items: [stackedColumn(), stackedBar(), table()],
            },
          ],
        },
        {
          label: 'Trend analysis',
          key: 'trend',
          toolTip: `View the direction in which talk time is trending over a period of time for \
                  selected ${toolTipLabel}.`,
          dropzoneDefault: [
          { dropzone, fn: firstN(10) },
          { dropzone: 'call_duration', fn: firstN(1) },
          ],
          limitDropzone: [
          { field: 'call_duration', len: 1 },
          { field: dropzone, len: 10 },
          ],
          dateDefault: getGlobalTimeRanges(5),
          items: [
            {
              label: 'Daily summary',
              key: 'daily_summary',
              toolTip: 'View data broken down by day over a period of time.',
              items: [line()],
            },
          ],
        },
        {
          label: 'Period over period',
          key: 'period',
          toolTip: `Compare total talk time broken down by call type (inbound, outbound, toll \
                  free, etc.) over two periods of time for selected ${toolTipLabel}.`,
          dropzoneDefault: [{
            dropzone,
            fn: firstN(4, true),
          }],
          dateDefault: prevWeekPeriod(),
          items: [
            {
              label: 'Daily summary',
              key: 'daily_summary',
              toolTip: 'View data broken down by day over a period of time.',
              items: [line(), table()],
            },
          ],
        },
      ],
    },
    {
      label: `${label} call duration`,
      key: 'call_duration',
      groupLabel: label,
      toolTip: 'View data on the length of time calls are lasting.  View trends and compare call types \
              (inbound, outbound, toll free, etc.) for your call durations within a specific period of time.',
      availableFilters: [{
        copyFrom: 'calls',
        key: 'call_duration',
        label: 'Call duration',
      }],
      minuteBucketsDefault: [
      { from: 0, to: 5 },
      { from: 5, to: 10 },
      { from: 10, to: 15 },
      { from: 15, to: 20 },
      { from: 20, to: null },
      ],
      dropzones: ['call_duration', dropzone],
      items: [
        {
          label: 'Aggregate',
          key: 'aggregate',
          toolTip: `View total call duration broken down by call type (inbound, outbound, toll free, etc.) \
                  over a period of time for selected ${toolTipLabel}.`,
          dateDefault: getGlobalTimeRanges(5),
          dropzoneDefault: [
            {
              dropzone,
              fn: all,
            },
            {
              dropzone: 'call_duration',
              fn: firstN(1),
            },
          ],
          items: [
            {
              label: 'Period summary',
              key: 'period_summary',
              toolTip: 'View all data combined within a period of time.',
              dropzoneDefault: [{
                dropzone: 'call_duration',
                fn: firstN(3),
              }],
              items: [stackedBar(), stackedColumn(), column(), bar(),
                pie({
                  limitDropzone: [{
                    field: 'call_duration',
                    len: 1,
                  }],
                  dropzoneDefault: [{
                    dropzone: 'call_duration',
                    fn: firstN(1),
                  }],
                }),
                table(),
              ],
            },
            {
              label: 'Daily summary',
              key: 'daily_summary',
              toolTip: 'View data broken down by day over a period of time.',
              limitDropzone: [{
                field: 'call_duration',
                len: 1,
              }],
              items: [stackedColumn(), stackedBar(), table()],
            },
            {
              label: 'Daily average',
              key: 'daily_average',
              toolTip: 'View the daily average of data within a period of time.',
              dropzoneDefault: [{
                dropzone: 'call_duration',
                fn: firstN(3),
              }],
              items: [stackedBar(), stackedColumn(), column(), bar(),
                pie({
                  limitDropzone: [{
                    field: 'call_duration',
                    len: 1,
                  }],
                  dropzoneDefault: [{
                    dropzone: 'call_duration',
                    fn: firstN(1),
                  }],
                }),
                table(),
              ],
            },
            {
              label: 'Hourly average',
              key: 'hourly_average',
              toolTip: 'View the hourly average of data within a period of time.',
              limitDropzone: [{
                field: 'call_duration',
                len: 1,
              }],
              items: [line(), stackedColumn()],
            },
          ],
        },
        {
          label: 'Compare',
          key: 'compare',
          toolTip: `Compare selected ${toolTipLabel} by their call duration broken down by call type \
                  (inbound, outbound, toll free, etc.) over a period of time.`,
          dateDefault: getGlobalTimeRanges(5),
          dropzoneDefault: [{
            dropzone,
            fn: firstN(4, true),
          },
          {
            dropzone: 'call_duration',
            fn: firstN(1),
          }],
          items: [
            {
              label: 'Period summary',
              key: 'period_summary',
              toolTip: 'View all data combined within a period of time.',
              limitDropzone: [{
                field: 'call_duration',
                len: 1,
              }],
              items: [stackedBar(), stackedColumn(), bar(), column(), table()],
            },
            {
              label: 'Daily summary',
              key: 'daily_summary',
              toolTip: 'View data broken down by day over a period of time.',
              limitDropzone: [{
                field: 'call_duration',
                len: 1,
              }],
              items: [table()],
            },
            {
              label: 'Daily average',
              key: 'daily_average',
              toolTip: 'View the daily average of data within a period of time.',
              limitDropzone: [{
                field: 'call_duration',
                len: 1,
              }],
              items: [stackedBar(), stackedColumn(), column(), bar(),
                pie({
                  limitDropzone: [{
                    field: 'call_duration',
                    len: 1,
                  }],
                }),
              ],
            },
          ],
        },
        {
          label: 'Trend analysis',
          key: 'trend',
          toolTip: `View the direction in which call duration is trending over a period of time for \
                  selected ${toolTipLabel}.`,
          dropzoneDefault: [
          { dropzone, fn: firstN(10) },
          { dropzone: 'call_duration', fn: firstN(1) },
          ],
          dateDefault: getGlobalTimeRanges(5),
          limitDropzone: [
          { field: 'call_duration', len: 1 },
          { field: dropzone, len: 10 },
          ],
          items: [
            {
              label: 'Daily summary',
              key: 'daily_summary',
              toolTip: 'View data broken down by day over a period of time.',
              items: [line(), table()],
            },
          ],
        },
      ],
    },
    {
      label: `${label} calls + talk time`,
      groupLabel: label,
      key: 'calls_talk_time',
      toolTip: 'View data that combines both calls and the amount of time spent on the calls within one report.',
      availableFilters: [{
        copyFrom: 'calls_talk_time',
        key: 'calls_talk_time',
        label: 'Calls + Talk time',
      }],
      dropzones: ['calls_talk_time', dropzone],
      items: [
        {
          label: 'Aggregate',
          key: 'aggregate',
          toolTip: `View calls and talk time broken down by call type (inbound, outbound, toll free, etc.) \
                  over a period of time for selected ${toolTipLabel}.`,
          dateDefault: getGlobalTimeRanges(5),
          dropzoneDefault: [
          { dropzone, fn: all },
          { dropzone: 'calls_talk_time', fn: all },
          ],
          items: [
            {
              label: 'Period summary',
              key: 'period_summary',
              toolTip: 'View all data combined within a period of time.',
              items: [table()],
            },
            {
              label: 'Daily summary',
              key: 'daily_summary',
              toolTip: 'View data broken down by day over a period of time.',
              items: [table()],
            },
            {
              label: 'Daily average',
              key: 'daily_average',
              toolTip: 'View the daily average of data within a period of time.',
              items: [table()],
            },
            {
              label: 'Hourly average',
              key: 'hourly_average',
              toolTip: 'View the hourly average of data within a period of time.',
              items: [table()],
            },
          ],
        },
        {
          label: 'Compare',
          key: 'compare',
          toolTip: `Compare selected ${toolTipLabel} by their calls and talk time broken down by call type \
                  (inbound, outbound, toll free, etc.) over a period of time.`,
          dropzoneDefault: [
          { dropzone, fn: firstN(30, true) },
          { dropzone: 'calls_talk_time', fn: all },
          ],
          dateDefault: getGlobalTimeRanges(7),
          items: [
            {
              label: 'Period summary',
              key: 'period_summary',
              toolTip: 'View all data combined within a period of time.',
              items: [table()],
            },
            {
              label: 'Daily summary',
              key: 'daily_summary',
              toolTip: 'View data broken down by day over a period of time.',
              items: [table()],
            },
            {
              label: 'Daily average',
              key: 'daily_average',
              toolTip: 'View the daily average of data within a period of time.',
              items: [table()],
            },
          ],
        },
      ],
    },
  ];
}

const call_group_distribution = function (dropzone) {
  const label = dropzone === 'talk_time' ? 'Talk Time' : 'Calls';
  const key = dropzone === 'talk_time' ? 'distribution_talk_time' : 'distribution_calls';
  const limit = dropzone === 'talk_time' ? 1 : 2;
  const toolTip = dropzone === 'talk_time' ? 'View talk time from a selected call group by the user the call was distributed to.' : 'View calls from a selected call group by the user the call was distributed to and the call result (answered or missed).';

  return {
    label: 'Distribution',
    key: 'distribution',
    dropzones: [key, 'call_groups'],
    toolTip,
    availableFilters: [{
      copyFrom: key,
      key,
      label,
    }],
    limitDropzone: [
      { field: key, len: limit },
      { field: 'call_groups', len: 1 },
    ],
    items: [{
      label: 'Period summary',
      key: 'period_summary',
      toolTip: 'View all data combined within a period of time.',
      dropzoneDefault: [
        { dropzone: key, fn: firstN(limit) },
        { dropzone: 'call_groups', fn: firstN(1) },
      ],
      items: [stackedBar(), stackedColumn(), column(), bar(), table()],
    },
    {
      label: 'Daily average',
      key: 'daily_average',
      toolTip: 'View the daily average of data within a period of time.',
      dateDefault: getGlobalTimeRanges(1),
      dropzoneDefault: [
        { dropzone: key, fn: firstN(limit) },
        { dropzone: 'call_groups', fn: firstN(1) },
      ],
      items: [stackedBar(), stackedColumn(), table()],
    },
    {
      label: 'Hourly average',
      key: 'hourly_average',
      toolTip: 'View the hourly average of data within a period of time.',
      dropzoneDefault: [
        { dropzone: key, fn: firstN(limit) },
        { dropzone: 'call_groups', fn: firstN(1) },
      ],
      items: [table()],
    }],
  };
};


const surveys = function () {
  return {
    label: 'Rating Log',
    key: 'rating_log',
    dropzones: ['calls', 'users'],
    dropzoneDefault: [
      { dropzone: 'calls', fn: all },
      { dropzone: 'users', fn: all },
    ],
    availableFilters: [{
      copyFrom: 'users',
      key: 'users',
      label: 'Users',
    }],
    items: [{
      label: 'Individual Calls',
      key: 'individual_calls',
      items: [table()],
    }],
  };
};


const sms_totals_items = function () {
  return [{
    label: 'Aggregate',
    key: 'aggregate',
    dateDefault: getGlobalTimeRanges(5),
    toolTip: `View total SMS messages by sent, received and failed over a period of time.`,
    availableFilters: [{
      copyFrom: 'smses',
      key: 'dummyfilter',
      label: 'DummyFilter',
    }],
    dropzones: ['smses', 'dummyfilter'],
    dropzoneDefault: [
      {dropzone: 'smses', fn: all}
    ],
    limitDropzone: [
      { field: 'dummyfilter', len: 0 }
    ],
    items: [
      {
        label: 'Period summary',
        key: 'period_summary',
        toolTip: 'View all data combined within a period of time.',
        items: [column(), bar(), table(), pie()],
      },
      {
        label: 'Daily summary',
        key: 'daily_summary',
        toolTip: 'View data broken down by day over a period of time.',
        items: [column(), stackedColumn(), table()],
      },
      {
        label: 'Daily average',
        key: 'daily_average',
        toolTip: 'View the daily average of data within a period of time.',
        items: [column(), bar(), table(), pie()],
      },
      {
        label: 'Hourly average',
        key: 'hourly_average',
        toolTip: 'View the hourly average of data within a period of time.',
        items: [line(), stackedColumn(), column()],
      },
    ]
  },
  {
    label: 'Compare',
    key: 'compare',
    dateDefault: getGlobalTimeRanges(5),
    toolTip: `Compare sms's by sent, received and failed over a period of time.`,
    availableFilters: [{
      copyFrom: 'smses',
      key: 'dummyfilter',
      label: 'DummyFilter',
    }],
    dropzones: ['smses', 'dummyfilter'],
    dropzoneDefault: [
      {dropzone: 'smses', fn: all}
    ],
    limitDropzone: [
      { field: 'dummyfilter', len: 0 }
    ],
    items: [
      {
        label: 'Period summary',
        key: 'period_summary',
        toolTip: 'View all data combined within a period of time.',
        items: [stackedBar(), stackedColumn(), table()],
      },
      {
        label: 'Daily summary',
        key: 'daily_summary',
        toolTip: 'View data broken down by day over a period of time.',
        items: [table()],
      },
      {
        label: 'Daily average',
        key: 'daily_average',
        toolTip: 'View the daily average of data within a period of time.',
        items: [stackedBar(), stackedColumn(), table()],
      },
    ]
  },
  {
    label: 'SMS log',
    key: 'sms_log',
    toolTip: `View a list of SMS messages.`,
    dateDefault: getGlobalTimeRanges(5),
    availableFilters: [
      {label: 'SMS Log', key: 'sms_log', copyFrom: 'smses'},
      {label: 'DummyFilter', key: 'dummyfilter', copyFrom: 'smses'}
    ],
    dropzones: ['sms_log', 'dummyfilter'],
    dropzoneDefault: [
      { dropzone: 'sms_log', fn: all }
    ],
    limitDropzone: [
      { field: 'dummyfilter', len: 0 }
    ],
    items: [
      {
        label: 'Individual Messages',
        key: 'individual_calls',
        toolTip: 'View individual messages within a period of time.',
        items: [table()],
      },
    ],
  }
]};



const sms_items = function () {
  return [{
    label: 'Aggregate',
    key: 'aggregate',
    dateDefault: getGlobalTimeRanges(5),
    toolTip: `View total SMS messages by sent, received and failed over a period of time.`,
    availableFilters: [{
      copyFrom: 'users',
      key: 'users',
      label: 'Users',
    }],
    dropzones: ['smses', 'users'],
    dropzoneDefault: [{
      dropzone: 'users',
      fn: all,
    }],
    items: [
      {
        label: 'Period summary',
        key: 'period_summary',
        toolTip: 'View all data combined within a period of time.',
        items: [column(), bar(), table(), pie()],
      },
      {
        label: 'Daily summary',
        key: 'daily_summary',
        toolTip: 'View data broken down by day over a period of time.',
        items: [column(), stackedColumn(), table()],
      },
      {
        label: 'Daily average',
        key: 'daily_average',
        toolTip: 'View the daily average of data within a period of time.',
        items: [column(), bar(), table(), pie()],
      },
      {
        label: 'Hourly average',
        key: 'hourly_average',
        toolTip: 'View the hourly average of data within a period of time.',
        items: [line(), stackedColumn(), column()],
      },
    ]
  },
  {
    label: 'Compare',
    key: 'compare',
    dateDefault: getGlobalTimeRanges(5),
    toolTip: `Compare sms's by sent, received and failed per user over a period of time.`,
    availableFilters: [{
      copyFrom: 'users',
      key: 'users',
      label: 'Users',
    }],
    dropzones: ['smses', 'users'],
    dropzoneDefault: [{
      dropzone: 'users',
      fn: all,
    }],
    items: [
      {
        label: 'Period summary',
        key: 'period_summary',
        toolTip: 'View all data combined within a period of time.',
        items: [stackedBar(), stackedColumn(), table()],
      },
      {
        label: 'Daily summary',
        key: 'daily_summary',
        toolTip: 'View data broken down by day over a period of time.',
        items: [table()],
      },
      {
        label: 'Daily average',
        key: 'daily_average',
        toolTip: 'View the daily average of data within a period of time.',
        items: [stackedBar(), stackedColumn(), table()],
      },
    ]
  },
  {
    label: 'SMS log',
    key: 'sms_log',
    toolTip: `View a list of SMS messages for selected users.`,
    dateDefault: getGlobalTimeRanges(5),
    availableFilters: [{
      copyFrom: `users`,
      key: 'users',
      label: 'Users',
    }],
    dropzones: ['sms_log', 'users'],
    dropzoneDefault: [{
      dropzone: 'users',
      fn: all,
    }],
    items: [
      {
        label: 'Individual Messages',
        key: 'individual_calls',
        toolTip: 'View individual messages within a period of time.',
        items: [table()],
      },
    ],
  }
]};

const Tree = {};

Tree.get = () => {
  const tree = [
    {
      label: 'Users',
      key: 'users',
      availableFilters: [{
        copyFrom: 'users',
        key: 'users',
        label: 'Users',
      }],
      items: baseTree('Users').concat([

      ]),
    },
    {
      label: 'Locations',
      key: 'locations',
      availableFilters: [{
        copyFrom: 'locations',
        key: 'locations',
        label: 'Locations',
      }],
      items: baseTree('Locations').concat([

      ]),
    },
    {
      label: 'Call groups',
      key: 'call_groups',
      availableFilters: [{
        copyFrom: 'call_groups',
        key: 'call_groups',
        label: 'Call groups',
      }],
      items: () => {
        const base = baseTree('Call groups');

        base[0].items.push(call_group_distribution('calls'));
        base[1].items.push(call_group_distribution('talk_time'));

        return base;
      },
    },
    {
      label: 'Phone numbers',
      key: 'phone_numbers',
      availableFilters: [{
        copyFrom: 'phone_numbers',
        key: 'phone_numbers',
        label: 'Phone numbers',
      }],
      items: baseTree('Phone numbers').concat([

      ]),
    },
    {
      label: 'Extensions',
      key: 'extensions',
      availableFilters: [{
        copyFrom: 'extensions',
        key: 'extensions',
        label: 'Extensions',
      }],
      items: () => {
        const base = baseTree('Extensions');

        base[0].items = base[0].items.slice(0, 3);
        base[1].items = base[1].items.slice(0, 3);

        return base;
      },
    },
    {
      label: 'Area codes',
      key: 'customer_area_codes',
      availableFilters: [{
        copyFrom: 'customer_area_codes',
        key: 'customer_area_codes',
        label: 'Area codes',
      }],
      items: baseTree('Customer Area codes', 'Area codes').concat([

      ]),
    },
    {
      label: 'Groups',
      key: 'teams',
      availableFilters: [{
        copyFrom: 'teams',
        key: 'teams',
        label: 'Groups',
      }],
      items: baseTree('teams', 'Groups').concat([

      ]),
      postFn() {
        // temporarily remove call_log until unpack feature is implemented
        const calls = this.items[0].items;

        // find call log
        const callLogIndex = _.findIndex(calls, { key: 'call_log' });

        if (callLogIndex >= 0) {
          calls.splice(callLogIndex, 1);
        }
      },
    },
    {
      label: 'Surveys',
      key: 'surveys',
      availableFilters: [{
        copyFrom: 'calls',
        key: 'calls',
        label: 'Calls',
      }],
      items: [
        {
          key: 'call_rating',
          label: 'Call Rating',
          toolTip: 'View all call rating responses for call recordings.',
          items: [surveys()],
        },
      ],
    },
    {
      label: 'SMS',
      key: 'sms',
      availableFilters: [{
        copyFrom: 'smses',
        key: 'smses',
        label: 'SMS',
      },
      {
        copyFrom: 'sms_log',
        key: 'sms_log',
        label: 'SMS Log',
      }],
      items: [
        {
          key: 'sms_total_counts',
          label: 'SMS Total Counts',
          toolTip: 'View counts of all SMS messages sent and received.',
          items: sms_totals_items(),
        },
        {
          key: 'sms_user_counts',
          label: 'SMS User Counts',
          toolTip: 'View counts of SMS messages sent and received by user',
          items: sms_items(),
        },
      ],
    },
  ];

  // run postFn on each root node
  _.each(tree, (node) => {
    if (node.items && typeof node.items === 'function') node.items = node.items();
    if (node.postFn && typeof node.postFn === 'function') node.postFn();
  });

  return tree;
};

export default Tree;
