import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import cx from 'classnames';
import validator from 'validator';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import axios from 'axios';
import { actions } from 'react-redux-form';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { DefaultSpinner } from 'components';

import AppData from 'app_data';
import Player from 'components/call_recording/player';
import Button from 'components/button';
import Questions from 'components/call_recording/questions';
import vex from 'lib/vex';
import Utils from 'utils/common_utils';

class CallRecording extends PureComponent {
  static propTypes = {
    callRecordingInfo: PropTypes.object.isRequired,
    onClose: PropTypes.func,
    questionsFormActions: PropTypes.shape({
      /**
       * Used to set the form state as submitted
       * so question groups can be changed after submission
       */
      setSubmitted: PropTypes.func,
    }),
    /** State of the questions form */
    form: PropTypes.object,
  }

  constructor(props) {
    super(props);
    this.state = {
      showEmail: false,
      clipInfo: null,
      notes: '',
      apiError: null,
      emailTo: '',
      emailErrors: [],
      playerReady: false,
      sendingEmail: false,
      streamUrl: null,
      tab: 0,
      answers: null,
    };
  }

  componentDidMount() {
    // Add display overflow hidden
    this.toggleBodyOverflow();

    const params = _.pick(this.props.callRecordingInfo, [
      'bw_user_id',
      'external_tracking_id',
      'local_call_id',
      'start_time',
      'end_time',
      'group_id',
    ]);
    params['user_id'] = params['bw_user_id'];
    delete params['bw_user_id'];
    params['local_call_id'] = params['local_call_id'].replace(/[^0-9:]/g, '');
    AppData.getCallRecording(params).then((response) => {
      const { data } = response;
      if (data) {
        this.setState({
          id: data.id,
          ready: true,
          notes: data.note,
          clipInfo: data.segments || null,
          streamUrl: data.stream_url.substr(1),
        });
      } else {
        this.setState({
          ready: true,
        });
      }
    }).catch((err) => {
      const status = err.response ? err.response.status : null;
      this.setState({
        apiError: this.parseError(status),
      });
    });
  }

  componentWillUnmount() {
    this.toggleBodyOverflow();
  }

  onClipChange = (clipInfo) => {
    this.setState({
      clipInfo,
    });
  }

  onLoad = () => {
    this.setState({
      playerReady: true,
    });
  }

  onPlayerError = (error) => {
    const re = /.*:\s(\d)/;
    const status = error.match(re)[1];
    const errorMessage = this.parseError(parseInt(status, 10));
    this.setState({ playerError: errorMessage });
  }

  parseError = status => {
    switch (status) {
      case 4:
      case 404:
        return 'Call recording data not available for this record. It may have been deleted.';
      case 403:
        return 'You do not have access to call recordings. If you need access, please contact your NextOS administrator.';
      case 502:
        return 'The call recording service is currently inaccessible. Please try again later.';
      default:
        return 'Oops! Something is not adding up. Please try again later.';
    }
  }

  toggleBodyOverflow() {
    const body = document.body;
    if (body.style.overflow !== 'hidden') {
      body.style.overflow = 'hidden';
    } else {
      body.style.overflow = '';
    }
  }

  displayUnansweredDialog = (callback) => {
    const message = `Some questions do not have a response
    and will be considered as "not applicable" in reporting.`;
    vex.dialog.buttons.YES.text = 'OK';
    vex.dialog.buttons.NO.text = 'Cancel';
    vex.dialog.confirm({
      message,
      callback,
    });
  }

  handleNotesChange = (e) => {
    this.setState({ notes: e.target.value });
  }

  handleToggleEmail = () => {
    this.setState({ showEmail: !this.state.showEmail });
  }

  handleEmailToChange = (e) => {
    this.setState({ emailTo: e.target.value });
  }

  validateEmails() {
    const emailErrors = [];
    if (this.state.emailTo) {
      const emails = this.state.emailTo.split(',');

      _.each(emails, (email) => {
        if (!validator.isEmail(email.trim())) {
          emailErrors.push(email);
        }
      });
    }

    return emailErrors;
  }

  handleValidateEmails = () => {
    this.setState({ emailErrors: this.validateEmails() });
  }

  handleSendEmail = () => {
    if (!this.state.emailTo) {
      return vex.error(['Please enter at least one email address.']);
    }

    if (this.validateEmails().length) return;

    this.handleSave({ supressModal: true }).then((id) => {
      const params = {
        call_id: id,
        emails: this.state.emailTo,
        call_info: this.props.callRecordingInfo,
      };

      this.setState({ sendingEmail: true });
      AppData.sendCallRecording(params).then(() => {
        this.setState({
          emailTo: '',
          showEmail: false,
          sendingEmail: false,
        });
        vex.success('Your email has been sent successfully.');
      });
    });
  }

  handleCancel = () => {
    const { onClose } = this.props;
    onClose();
  }

  handleSaveClick = ({ supressModal = false }) => {
    const { form } = this.props;
    if (form.touched && !form.valid) {
      const callback = (value) => {
        if (value) {
          this.handleSave(supressModal);
        }
      };
      this.displayUnansweredDialog(callback);
    } else {
      this.handleSave(supressModal);
    }
  }

  handleSave = ({ supressModal = false }) => {
    const { questionsFormActions, callRecordingInfo, form } = this.props;
    const params = {
      id: this.state.id,
      bw_user_id: this.props.callRecordingInfo.bw_user_id,
      external_tracking_id: this.props.callRecordingInfo.external_tracking_id,
      segments: this.state.clipInfo || {},
      note: this.state.notes,
    };
    const responseCallInfo = _.pick(callRecordingInfo, [
      'bw_user_id',
      'user_id',
      'bw_remote_user_id',
      'remote_user_id',
      'start_time',
      'end_time',
      'source',
      'connected',
      'outbound',
      'internal',
    ]);

    return axios.all([AppData.saveCallRecording(params), this.handleAnswersSave(params, responseCallInfo)])
      .then(axios.spread((recording) => {
        if (!supressModal) {
          vex.success('Your changes have been saved.');
        }
        if (form.touched) {
          questionsFormActions.setSubmitted('questions_group_response');
        }
        this.setState({ id: recording.data.id });

        return recording.data.id;
      }));
  }

  handleTabChange = (index) => {
    this.setState({ tab: index });
  }

  handleAnswersChange = (questionGroup, answers) => {
    this.setState({ questionGroup, answers });
  }

  handleAnswersSave = (params, callInfo) => {
    const { questionGroup, answers: savedAnswers } = this.state;
    const { form } = this.props;
    const answers = Object.assign({}, savedAnswers);
    if (form.touched) {
      if (!answers.external_tracking_id) {
        const { external_tracking_id } = params;
        answers.external_tracking_id = external_tracking_id;
        answers.call_info = callInfo;
      }
      return AppData.saveQuestionGroupAnswers(questionGroup, answers);
    }
    return Promise.resolve();
  }

  renderTabbedSection = () => {
    const { notes } = this.state;
    return (
      <Tabs
        onSelect={this.handleTabChange}
        selectedIndex={this.state.tab}
        selectedTabClassName="call-recording__tab--selected"
      >
        <TabList
          className="call-recording__tabs"
        >
          <Tab
            className="call-recording__tab"
          >
            Notes
          </Tab>
          <Tab
            className="call-recording__tab"
          >
            Rate call
          </Tab>
        </TabList>
        <TabPanel>
          <Notes
            notes={notes}
            onChange={this.handleNotesChange}
          />
        </TabPanel>
        <TabPanel>
          <Questions
            onChange={this.handleAnswersChange}
            externalTrackingId={this.props.callRecordingInfo.external_tracking_id}
          />
        </TabPanel>
      </Tabs>
    );
  }

  render() {
    const {
      ready,
      apiError,
      playerError,
      clipInfo,
      email,
      sendingEmail,
      emailErrors,
    } = this.state;
    const { callRecordingInfo } = this.props;
    if (!ready) {
      if (apiError) {
        return <div className="call-recording-missing">{apiError}</div>;
      }
      return (
        <DefaultSpinner />
      );
    }
    if (playerError) {
      return <div className="call-recording-missing">{playerError}</div>;
    }
    const { other_party_name, customer_number, start_time, call_recording_result, call_leg_talk_duration_seconds } = callRecordingInfo;
    const name = other_party_name ? other_party_name.trim() : 'Name Unavailable';
    const phoneNumber = customer_number;
    const [date, ...startTime] = start_time.split(' ');
    const time = `${startTime[0]} ${startTime[1]}`;
    const duration = Utils.convertSecondsToMinutes(call_leg_talk_duration_seconds);

    if (!call_recording_result) {
      return (
        <div className="call-recording-container">
          <HeaderInfo
            callInfo={{
              name,
              phoneNumber,
              date,
              time,
              duration,
            }}
            onClose={this.props.onClose}
            emailActive={false}
            showEmail={false}
          />
        </div>
      );
    }

    return (
      <div className="call-recording-container">
        <HeaderInfo
          callInfo={{
            name,
            phoneNumber,
            date,
            time,
            duration,
          }}
          onClose={this.props.onClose}
          emailActive={this.state.showEmail}
          onToggleEmail={this.handleToggleEmail}
          showEmail
        />
        <Player
          className="call-recording-player"
          audioSrc={`/${this.state.streamUrl}`}
          width={500}
          initialClipPcts={clipInfo}
          onClipChange={this.onClipChange}
          onLoad={this.onLoad}
          onError={this.onPlayerError}
        />
        {this.state.showEmail && (
          <Email
            emailTo={email}
            emailErrors={emailErrors}
            onEmailToChange={this.handleEmailToChange}
            validateEmails={this.handleValidateEmails}
            onSend={this.handleSendEmail}
            sendingEmail={sendingEmail}
          />
        )}
        {this.renderTabbedSection()}
        <div className="buttons">
          <Button onClick={this.handleCancel} className="btn">
            Cancel
          </Button>
          <Button onClick={this.handleSaveClick} className="btn btn-green">
            Save
          </Button>
        </div>
        <div className="clearfix" />
      </div>
    );
  }
}

const HeaderInfo = (props) => (
  <div className="header-info-container">
    <div className="header">
      <span className="icon-custom-briefcase" />
      <h2>{props.callInfo.name}</h2>
      {props.callInfo.company ?
        <span>
          <span className="divider">|</span>
          <h2 className="light">{props.callInfo.company}</h2>
        </span>
      : null }
    </div>
    <div className="sub-header">
      <span className="phone">{props.callInfo.phoneNumber}</span>
      <span className="divider">|</span>
      <span className="date">{props.callInfo.date}</span>
      <span className="divider">|</span>
      <span className="time">{props.callInfo.time}</span>
      <span className="divider">|</span>
      <span className="duration">{props.callInfo.duration}</span>

      <div className="actions-container">
        {props.showEmail ?
          <span
            onClick={props.onToggleEmail}
            className={cx({ 'icon-custom-envelope-fill': true, 'active': props.emailActive })}
          />
          : null
        }
      </div>
      <div onClick={props.onClose} className="close icon-custom-exit" />
    </div>
  </div>
);

HeaderInfo.propTypes = {
  /** Info for the loaded call recording */
  callInfo: PropTypes.shape({
    name: PropTypes.string,
    company: PropTypes.string,
    phoneNumber: PropTypes.string,
    date: PropTypes.string,
    time: PropTypes.string,
    duration: PropTypes.string,
  }).isRequired,
  /** Toggle email input window */
  onToggleEmail: PropTypes.func,
  /** Whether or not the email window is active */
  emailActive: PropTypes.bool,
  /** Show email icon */
  showEmail: PropTypes.bool,
  onClose: PropTypes.func,
};

const Email = (props) => {
  if (props.sendingEmail) {
    return (
      <DefaultSpinner />
    );
  }

  return (
    <div className="email-container">
      <div className="field-container">
        <h2>Email</h2>
        <label htmlFor="emailTo">Email to:</label>
        <input
          type="text"
          id="emailTo"
          className={cx({ error: props.emailErrors.length })}
          onChange={props.onEmailToChange}
          placeholder="Email addresses"
          onBlur={props.validateEmails}
          onMouseOut={props.validateEmails}
          value={props.emailTo}
        />
        <div className="email-errors">
          {props.emailErrors.length && 'Invalid Emails:' || ''}
          {_.map(props.emailErrors, (err, index) => <div key={index}>{err}</div>)}
        </div>
        <div className="email-tip">
          For multiple addresses, use a comma to separate.
        </div>
      </div>
      <div className="clearfix" />
      <div className="buttons pull-right">
        <div onClick={props.onSend} className="btn btn-green">
          Send
        </div>
      </div>
      <div className="clearfix" />
      <div className="horizontal-divider" />
    </div>
  );
};

Email.propTypes = {
  /** Email is being sent with call recording */
  sendingEmail: PropTypes.bool,
  /** Action after emails are entered */
  onEmailToChange: PropTypes.func,
  /** Function for validating email input */
  validateEmails: PropTypes.func,
  /** The emails that have been entered */
  emailTo: PropTypes.string,
  /** Validation errors for emails */
  emailErrors: PropTypes.array,
  /** Action when clicking send */
  onSend: PropTypes.func,
};

const Notes = (props) => (
  <div className="notes-container">
    <textarea
      className="notes"
      onChange={props.onChange}
      value={props.notes || ''}
    />
  </div>
);

Notes.propTypes = {
  /** Action after entering notes */
  onChange: PropTypes.func,
  /** Notes for the call recording */
  notes: PropTypes.string,
};

const mapStateToProps = (state) => ({
  form: state.forms.questions_group_response.$form,
});

const mapDispatchToProps = (dispatch) => ({
  questionsFormActions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CallRecording);
