import Page from 'modules/quality/quality_page';


const routes = [
  {
    path: '/quality/summary',
    title: 'Summary',
    component: Page,
  },
  {
    path: '/quality/location',
    title: 'Location',
    component: Page,
  },
  {
    path: '/quality/user-activity',
    title: 'User Activity',
    component: Page,
  },
  {
    path: '/quality/calls',
    title: 'Calls',
    component: Page,
  },
  {
    path: '/quality/adoption-and-usage',
    title: 'Adoption & Usage',
    component: Page,
  },
];


export default routes;
