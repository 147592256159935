import * as t from './actionTypes';

const initialState = {
  questionsLoading: true,
  responsesLoading: true,
  questions: [],
  responses: [],
  entities: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case t.LOAD_QUESTIONS_REQUEST:
      return {
        ...state,
        questionsLoading: true,
      };
    case t.LOAD_RESPONSES_REQUEST:
      return {
        ...state,
        responsesLoading: true,
      };
    case t.LOAD_QUESTIONS_SUCCESS:
      return {
        ...state,
        questionsLoading: false,
        questions: action.data.result,
        entities: {
          ...state.entities,
          ...action.data.entities,
        },
      };
    case t.LOAD_RESPONSES_SUCCESS:
      return {
        ...state,
        responsesLoading: false,
        responses: action.data.result,
        entities: {
          ...state.entities,
          ...action.data.entities,
        },
      };
    default:
      return state;
  }
};
