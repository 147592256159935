

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import cx from 'classnames';

import MultiSelectOption from 'components/multi_select_option';

class MultiSelect extends Component {
  static defaultProps = {
    placeholder: 'Search',
    options: [],
    onChange: () => {},
    selectAllLabel: 'Select All',
    removeAllLabel: 'Remove All',
  };

  static propTypes = {
    /** The classname(s) for the component */
    className: PropTypes.string,
    /** Whether or not the search bar is enabled */
    searchBar: PropTypes.bool,
    /** The placeholder text for the search bar */
    placeholder: PropTypes.string,
    /** The options to display for selection */
    options: PropTypes.array.isRequired,
    /** The callback after the selections change */
    onChange: PropTypes.func,
    /** Whether or not the toggle buttons are enabled */
    hideToggleButtons: PropTypes.bool, 
    /** The label for the toggle button when select all is the option */
    selectAllLabel: PropTypes.string,
    /** The label for the toggle button when remove all is the option */
    removeAllLabel: PropTypes.string,
    /** An element to be displayed under the search bar */
    displayElement: PropTypes.element,
    /** Initially selected elements */
    initialSelected: PropTypes.array,
  }

  constructor(props) {
    super(props);

    this.state = {
      selections: [],
      filterText: '',
    };
  }

  componentWillMount() {
    const { initialSelected } = this.props;
    if (initialSelected) {
      this.setSelected(initialSelected, true, true);
    }
  }

  componentDidUpdate(prevProps) {
    const { initialSelected } = this.props;
    if(initialSelected && initialSelected.length !== prevProps.initialSelected.length) {
      this.setState({selections: initialSelected});
    }
  }

  setSelected = (options, selected, isInitial = false) => {
    if (!(options instanceof Array)) options = [options];

    const selections = this.state.selections;
    options.forEach((option, index) => {
      const selectionIndex = _.findIndex(selections, { id: option.id });
      if (!selected && selectionIndex !== -1) {
        selections.splice(selectionIndex, 1);
      } else if(selectionIndex === -1 && selected){
        selections.push(option);
      }
    });
    this.setState({ selections });
    if (!isInitial) {
      this.props.onChange([...selections]);
    }
  };

  handleFilterChange = (event) => {
    this.setState({ filterText: event.target.value });
  }

  handleOptionClick = (option) => {
    const { selections } = this.state;
    const index = _.findIndex(selections, { id: option.id });
    this.setSelected(option, !(index > -1));
  }

  removeAll = () => {
    this.toggleAll(false);
  }

  selectAll = () => {
    this.toggleAll(true);
  }

  toggleAll = (selected) => {
    const { options } = this.props;
    const { filterText } = this.state;
    const filteredOptions = options.filter((option) => option.label.toLowerCase().includes(filterText.toLowerCase()));
    this.setSelected(filteredOptions, selected);
  }

  renderContent = () => {
    const content = [
      this.renderOptions(),
      this.renderToggleButton(),
    ];
    const { searchBar } = this.props;
    if (this.props.displayElement) {
      content.unshift(this.renderDisplayElement(this.props.displayElement));
    }
    if (searchBar) {
      content.unshift(this.renderSearchBar());
    }
    return content;
  }

  renderDisplayElement = (element) => element;

  renderOptions = () => {
    const { options } = this.props;
    const { selections, filterText } = this.state;
    const optionElements = options.filter((option) => option.label.toLowerCase().includes(filterText.toLowerCase())).map((option) => (
      <MultiSelectOption
        key={option.id}
        label={option.label}
        onClick={this.handleOptionClick.bind(this, option)}
        selected={Boolean(_.find(selections, { id: option.id }))}
      />
    ));
    return (
      <ul
        key="options"
        className="multi-select-items"
      >
        {optionElements}
      </ul>
    );
  }

  renderSearchBar = () => {
    const { placeholder } = this.props;
    return (
      <input
        key="search"
        className="multi-select-search"
        // className="multiSelectSearchCustom"
        type="search"
        placeholder={placeholder}
        onChange={this.handleFilterChange}
        value={this.state.filterText}
      />
    );
  }

  renderToggleButton = () => {
    const { selectAllLabel, removeAllLabel, options,hideToggleButtons } = this.props;
    const { selections } = this.state;
    let label;
    let toggleFunc;
    if (selections.length === options.length) {
      label = removeAllLabel;
      toggleFunc = this.removeAll;
    } 
    if (selections.length < options.length) {
      label = selectAllLabel;
      toggleFunc = this.selectAll;
    }
    if(hideToggleButtons){
      return <></>
    }
    return (
      <button
        key="toggle"
        className="multi-select-toggle"
        onClick={toggleFunc}
      >
        {label}
      </button>
    );
  }

  render() {
    const {
      className,
    } = this.props;
    const classNames = [
      'multi-select',
      className,
    ];

    return (
      <div
        className={cx(classNames)}
      >
        {this.renderContent()}
      </div>
    );
  }
}

export default MultiSelect;
