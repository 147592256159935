import React, { Component, memo } from 'react';
import PropTypes from 'prop-types';
import ReactTimeout from 'react-timeout';
import { DefaultSpinner } from 'components';

import AppData from 'app_data';
import { fetchChartData, handleRetry } from 'utils/chart_utils';
import Chart from 'components/charts/chart';

import retry from 'img/retry.png';

class ChartAsync extends Component {
  static propTypes = {
    /** The time range that the chart will represent */
    timeRange: PropTypes.object,
    /** An array of multiple time ranges for charts with multiple periods */
    timeRanges: PropTypes.array,
    /** Whether or not the chart is in real time */
    realTime: PropTypes.bool.isRequired,
    /** An artificial timeout to allow for reports to load in sequential order */
    timeout: PropTypes.number,
    /** The call data fields that are expected to be returned: (start_time,
    user_id, calling_number, called_number, talk_duration_seconds, etc...) */
    selects: PropTypes.array,
    /** The kind of unit that the chart is using on the value axis
    (calls, minutes, etc..) */
    unit: PropTypes.string,
    /** Filters the calls by metric: "filters": {inbound: true, outbound: true}*/
    filters: PropTypes.object,
    /** The chart object */
    chart: PropTypes.object,
    /** The title of the chart */
    title: PropTypes.string,
    /** The position of the legend: 'top', 'bottom', 'none' */
    legendPosition: PropTypes.string,
    /** An array of the items in the legend */
    enabledLegendItems: PropTypes.array,
    /** Whether or not the chart is drilling-down */
    isDrillingDown: PropTypes.bool,
    /** Whether or not the chart is in the second level of drilling-down */
    isSecondLevel: PropTypes.bool,
    /** A method to update the chart during a drill-down */
    updateChartData: PropTypes.func,
    /** Additional params to update the default params */
    extraParams: PropTypes.object,
    /** The time zone being displayed */
    timeZone: PropTypes.string,
    /** A handler for when a drill-down node is selected to open the call recording modal */
    triggerCallRecordingModal: PropTypes.func,
    legends: PropTypes.array,
    columnWidthOverride: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
  };

  static defaultProps = {
    columnWidthOverride: null,
  };

  state = {
    loading: true,
    error: false,
    chartData: null,
    intervalID: null,
  };

  componentDidMount() {
    if (this.props.realTime) {
      const intervalID = this.props.setInterval(this.fetchChartData, AppData.user.refresh_rate * 1000);
      this.setState({ intervalID: intervalID });

      return intervalID;
    }
    setTimeout(() => {
      this.fetchChartData(this.props);
    }, this.props.timeout);
  }

  componentWillReceiveProps(newProps) {
    const stillSecondLevel = this.props.isSecondLevel === newProps.isSecondLevel;
    if (newProps.isSecondLevel && stillSecondLevel) {
      return;
    }

    // TODO dont show loader on live update
    this.setState({ loading: true });

    if (newProps.realTime && !this.props.realTime) {
      const intervalID = this.props.setInterval(this.fetchChartData, AppData.user.refresh_rate * 1000, newProps);
      this.setState({ intervalID: intervalID });
    } else if(!newProps.realTime && this.props.realTime) {
      clearInterval(this.state.intervalID);
    }

    this.fetchChartData(newProps);
  }

  /* TODO: redundant with ReactTimeout? */
  componentWillUnmount() {
    clearInterval(this.state.intervalID);
  }

  cancelFetch = () => {
    if (!this.request) {
      return;
    }
    this.request.cancel();
    this.request = null;
  }

  fetchChartData = (props) => {
    this.cancelFetch();
    const fetchData = fetchChartData.bind(this);
    this.request = fetchData(props);
  }

  handleRetry = handleRetry.bind(this)

  render() {
    let content;
    if (!this.state.loading) {
      content = (
        <Chart
          ref="chartWrapper"
          chartData={this.state.chartData}
          chart={this.props.chart}
          unit={this.props.unit}
          timeRange={this.props.timeRange}
          legendPosition={this.props.legendPosition}
          enabledLegendItems={this.props.enabledLegendItems}
          title={this.props.title}
          legendEnabled={this.props.legendEnabled}
          updateChartData={this.props.updateChartData}
          isDrillingDown={this.props.isDrillingDown}
          timeZone={this.props.timeZone}
          isSecondLevel={this.props.isSecondLevel}
          legends={this.props.legends && this.props.legends}
          triggerCallRecordingModal={this.props.triggerCallRecordingModal}
          columnWidthOverride={this.props.columnWidthOverride}
        />
      );
    } else if (this.state.error) {
      content = (
        <div className="chart-empty">
          <div className="loader-error" onClick={this.handleRetry}>
            <img src={retry} alt="Retry" />
            <div className="btn btn-green">Retry</div>
          </div>
        </div>
      );
    } else {
      content = (
        <div className="chart-empty">
          <DefaultSpinner />
        </div>
      );
    }

    return content;
  }
}

export default memo(ReactTimeout(ChartAsync));
