import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import cx from 'classnames';

import InlineSvgFactory from 'components/inline_svg';
import Theme from 'theme';

import ico_tooltip from 'img/ico_tooltip.png';

const Close = (props) => (
  <div onClick={props.onClick} className="accordion__close">+</div>
);

Close.propTypes = {
  /** Function to call on close */
  onClick: PropTypes.func,
};

const LiButton = (props) => (
  <li
    onClick={props.onClick}
    className={props.className}
    data-testid="accordion-section-item-select"
  >
    {props.children}
  </li>
);

LiButton.propTypes = {
  /** Action on click of li element */
  onClick: PropTypes.func,
  /** Classname for li element */
  className: PropTypes.string,
  /** Content of li element */
  children: PropTypes.node,
};

const checkSelected = (selectedKey, index, item) => {
  // If selectedKey is int, check for selected by index
  if (typeof selectedKey === 'number') {
    return selectedKey === index;
  }
  return selectedKey === item.key;
};


const AccordionItems = (props) => {
  const items = _.map(props.items, (item, index) => {
    let El;
    let Svg;
    let CloseIcon;
    let color;
    let toolTip;
    const className = cx(
      'accordion__item',
      {
        [item.className]: item.className,
        disabled: props.atLimit,
        selected: checkSelected(props.selectedKey, index, item),
      }
    );

    if (item.toolTip) {
      const tipContent = `<h4>XBert tip:</h4>${item.toolTip}`;
      toolTip = (
        <span data-tip={tipContent} data-for="custom-report-tooltip">
          <img className="ico_tooltip" src={ico_tooltip} role="presentation" />
        </span>
      );
    }

    if (item.icon) {
      Svg = InlineSvgFactory(item.icon);
      El = <Svg className={item.iconClass} />;
    }

    if (props.color) {
      color = Theme.getColor(item.label);
      color = (
        <span
          className="accordion__color"
          style={{ backgroundColor: color }}
        />
      );
    }

    if (item.closeable && !props.static) {
      CloseIcon = (
        <Close
          onClick={() => {
            props.onSelect(item.key, index, props.parentIndex, item);
          }}
        />
      );
    }

    return (
      <LiButton
        key={index}
        draggable={item.draggable}
        closeable={item.closeable}
        onClick={() => {
          if (!item.closeable && !props.atLimit && !item.static) {
            props.onSelect(
              item.key,
              index,
              props.parentIndex,
              item
            );
          }
        }
        }
        className={className}
      >
        {color} {item.label || item.key} {toolTip} {El} {CloseIcon}
      </LiButton>
    );
  });

  return (
    <ul data-testid="accordion-section-items-select" className={cx('accordion__items', props.className)}>
      {items}
    </ul>
  );
};

AccordionItems.propTypes = {
  /** Index of the parent accordion */
  parentIndex: PropTypes.number,
  /** Classname of the unordered list element */
  className: PropTypes.string,
  /** Items to display */
  items: PropTypes.array,
  /** Whether or not the items are static */
  static: PropTypes.bool,
};

export default AccordionItems;
