import React from 'react';
import _ from 'lodash';
import { DefaultSpinner } from 'components';

import AppData from 'app_data';
import vex from 'lib/vex.js';
import Checkbox from 'components/checkbox.js';
import Tooltip from 'components/tooltip.js';
import Button from 'components/button';
import Timezones from 'timezones.js';
import { DateRangePicker } from '@nextiva/analytics-ui';

class GlobalSettings extends React.Component {
  state = {
    reporting: null,
    monitoring: null,
    analytics: null,
    global: null,
    outboundUserRedirects: null,
    excludeWeekends: null,
    timezone: null,
    loading: true,
  };

  componentDidMount() {
    AppData.getNextosGlobalSettings().then((response) => {
      const { data } = response;
      this.setState({
        reporting: data.time_settings.reporting,
        monitoring: data.time_settings.monitoring,
        analytics: data.time_settings.analytics,
        quality: data.time_settings.quality,
        global: data.time_settings.global,
        userOutboundRedirects: data.reporting_settings.include_user_outbound_redirects,
        excludeWeekends: data.reporting_settings.exclude_weekends,
        includeInternalCalls: data.reporting_settings.include_internal_calls,
        includeExternalCalls: data.reporting_settings.include_external_calls,
        includeCallGroupCalls: data.reporting_settings.include_call_groups_in_user_calls,
        includeInactiveUsers: data.reporting_settings.include_inactive_users,
        timezone: data.timezone,
        loading: false,
      });
    });
  }

  handleDateChange = (field, value) => {
    this.setState({
      [field]: value,
    });
  };

  handleOutboundUserRedirectChange = (value) => {
    this.setState({ userOutboundRedirects: value });
  };

  handleExcludeWeekendsChange = (value) => {
    this.setState({ excludeWeekends: value });
  };

  handleInternalCallsChange = (value) => {
    this.setState({ includeInternalCalls: value });
  };

  handleExternalCallsChange = (value) => {
    this.setState({ includeExternalCalls: value });
  };

  handleIncludeCallGroupsCallsChange = (value) => {
    this.setState({ includeCallGroupCalls: value });
  };

  handleIncludeInactiveUsersChange = (value) => {
    this.setState({ includeInactiveUsers: value });
  };

  handleTimezoneChange = (event) => {
    this.setState({ timezone: event.target.value });
  };

  submit = () => {
    const data = {
      time_settings: {
        reporting: this.state.reporting,
        monitoring: this.state.monitoring,
        analytics: this.state.analytics,
        quality: this.state.quality,
        global: this.state.global,
      },
      reporting_settings: {
        include_user_outbound_redirects: this.state.userOutboundRedirects,
        exclude_weekends: this.state.excludeWeekends,
        include_internal_calls: this.state.includeInternalCalls,
        include_external_calls: this.state.includeExternalCalls,
        include_call_groups_in_user_calls: this.state.includeCallGroupCalls,
        include_inactive_users: this.state.includeInactiveUsers,
      },
      timezone: this.state.timezone,
    };

    AppData.putNextosGlobalSettings(data).then(() => {
      vex.success('Settings saved.');
      AppData.user = Object.assign(AppData.user, data);
    }).catch(() => {
      vex.error([], null, 'There was an error saving your settings.');
    });
  };

  render() {
    if (this.state.loading) {
      return (
        <DefaultSpinner />
      );
    }

    return (
      <div className="settings-reporting">
        <div className="row">
          <div className="col-full">
            <section>
              <h1>Global date defaults</h1>
              <div className="date-range-container">
                <label>Reporting:</label>
                <DateRangePicker
                  className="date-range-popover"
                  timezone={AppData.user.timezone}
                  dateRange={this.state.reporting}
                  onChange={this.handleDateChange.bind(this, 'reporting')}
                  theme={{
                    dateRangePicker: 'date-range',
                  }}
                />
              </div>
              <div className="date-range-container">
                <label>Monitoring:</label>
                <DateRangePicker
                  className="date-range-popover"
                  timezone={AppData.user.timezone}
                  dateRange={this.state.monitoring}
                  onChange={this.handleDateChange.bind(this, 'monitoring')}
                  theme={{
                    dateRangePicker: 'date-range',
                  }}
                />
              </div>
              <div className="date-range-container">
                <label>Analytics:</label>
                <DateRangePicker
                  className="date-range-popover"
                  timezone={AppData.user.timezone}
                  dateRange={this.state.analytics}
                  onChange={this.handleDateChange.bind(this, 'analytics')}
                  theme={{
                    dateRangePicker: 'date-range',
                  }}
                />
              </div>
              <div className="date-range-container">
                <label>Quality:</label>
                <DateRangePicker
                  className="date-range-popover"
                  timezone={AppData.user.timezone}
                  dateRange={this.state.quality}
                  onChange={this.handleDateChange.bind(this, 'quality')}
                  theme={{
                    dateRangePicker: 'date-range',
                  }}
                />
              </div>
            </section>
            <section>
              <h1>Time zone</h1>
              <div className="report-settings-container">
                <div className="field-container">
                  <select ref="time_zone" aria-label="Time zone" value={this.state.timezone} onChange={this.handleTimezoneChange}>
                    {_.map(Timezones, (timezone, index) => (
                      <option key={index} value={timezone.id}>{timezone.label}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="clearfix" />
            </section>
            <section>
              <h1>Global report settings</h1>
              <div className="report-settings-container">
                <label>Include outbound user redirects:</label>
                <Checkbox checked={this.state.userOutboundRedirects} onChange={this.handleOutboundUserRedirectChange} />
                <Tooltip
                  tipContent="User redirects include Nextiva forwarding features like hunt groups and call centers."
                  tipId="redirect-setting"
                />
              </div>
              <div className="report-settings-container">
                <label>Exclude weekends:</label>
                <Checkbox checked={this.state.excludeWeekends} onChange={this.handleExcludeWeekendsChange} />
                <Tooltip
                  tipContent="Exclude weekends from reporting data."
                  tipId="weekend-setting"
                />
              </div>
              <div className="report-settings-container">
                <label>Include internal calls:</label>
                <Checkbox checked={this.state.includeInternalCalls} onChange={this.handleInternalCallsChange} />
                <Tooltip
                  tipContent="Internal includes all calls originating and ending within the account's network, e.g. extension to extension, forwards, transfers."
                  tipId="internal-calls-setting"
                />
              </div>
              <div className="report-settings-container">
                <label>Include external calls:</label>
                <Checkbox checked={this.state.includeExternalCalls} onChange={this.handleExternalCallsChange} />
                <Tooltip
                  tipContent="External includes all calls originating from or ending at numbers outside of the account's network."
                  tipId="external-calls-setting"
                />
              </div>
              <div className="report-settings-container">
                <label>Include call group calls:</label>
                <Checkbox checked={this.state.includeCallGroupCalls} onChange={this.handleIncludeCallGroupsCallsChange} />
                <Tooltip
                  tipContent="Include calls to hunt groups and call centers that were not answered in user calls."
                  tipId="include-call-groups-setting"
                />
              </div>
              <div className="report-settings-container">
                <label>Include inactive users:</label>
                <Checkbox checked={this.state.includeInactiveUsers} onChange={this.handleIncludeInactiveUsersChange} />
                <Tooltip
                  tipContent="Allow inactive and deleted users to be used in reports."
                  tipId="include-inactive-users-setting"
                />
              </div>
            </section>
          </div>
        </div>

        <div className="buttons">
          <Button
            className="btn"
            onClick={this.cancel}
            aria-label="Cancel"
          >
            Cancel
          </Button>
          <Button
            className="btn btn-green"
            onClick={this.submit}
            aria-label="Update"
          >
            Update
          </Button>
        </div>
      </div>
    );
  }
}

export default GlobalSettings;
