import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import BaseChartMixin from 'components/charts/chart_mixins';
import Utils from 'utils/common_utils';

class HeatMap extends Component {
  static propTypes = {
    /** The title of the chart */
    exportTitle: PropTypes.string,
    /** Optional: Will hardcode a chart height */
    height: PropTypes.string,
    /** A unique identifier for the chart being displayed */
    chartKey: PropTypes.string,
    /** The data to be displayed on the chart */
    chartData: PropTypes.object,
    /** The kind of unit that the chart is using on the value axis (calls, minutes, etc..) */
    unit: PropTypes.string,
    /** The time range that the chart will represent */
    timeRange: PropTypes.object,
    /** Whether or not download is disabled */
    disableDownload: PropTypes.bool,
    /** what kind of map we are displaying - 'heat' is the old name for 'usa' */
    mapType: PropTypes.oneOf(['world', 'usa', 'heat']),
  };

  graphData = () => {
    const data = _.cloneDeep(this.props.chartData);
    const mapType = this.props.mapType === 'world' ? 'worldLow' : 'usaLow'

    this.chart.dataProvider = {
      map: mapType,
      areas: data.data,
    };
  };

  buildChart = () => {
    this.chart = new window.AmCharts.AmMap();

    this.chart.type = 'map';
    this.chart.theme = 'none';
    // Path to images for map
    this.chart.path = 'amcharts';
    this.chart.colorSteps = 10;
    this.enableExport(this.props.exportTitle);

    this.chart.areasSettings = {
      autoZoom: false,
      alpha: 0.8,
      color: '#67b7dc',
      colorSolid: '#003767',
      unlistedAreasAlpha: 0.4,
      unlistedAreasColor: '#000000',
      outlineColor: '#FFFFFF',
      outlineAlpha: 0.5,
      outlineThickness: 0.5,
      rollOverColor: '#3c5bdc',
      rollOverOutlineColor: '#FFFFFF',
      selectedOutlineColor: '#FFFFFF',
      selectedColor: '#f15135',
      unlistedAreasOutlineColor: '#FFFFFF',
      unlistedAreasOutlineAlpha: 0.5,
    };

    this.chart.valueLegend = {
      right: 10,
      minValue: '0',
      maxValue: '100',
    };


    this.chart.balloon = {
      adjustBorderColor: true,
      verticalPadding: 25,
      horizontalPadding: 15,
      maxWidth: 200,
      cornerRadius: 0,
      fillColor: '#FFFFFF',
    };

    this.chart.zoomControl = {
      zoomControlEnabled: true,
      buttonFillColor: '#67b7dc',
      buttonRollOverColor: '#3c5bdc',
    };

    this.chart.colorSteps = 50;
    this.chart.balloonLabelFunction = this.balloonLabelFunction;

    this.graphData();
    this.chart.write(this.props.chartKey);
  };

  balloonLabelFunction = (mapObject, ammap) => {
    const label = this.props.chartData.meta.y_label;
    let value = mapObject.value;
    if (this.props.unit === 'minutes') {
      value = Utils.humanizeSeconds(value);
    }

    return `${mapObject.enTitle} <br /> ${label} ${value}`;
  };

  render() {
    return <div className="linechart" id={this.props.chartKey} />;
  }
}

export default BaseChartMixin(HeatMap);
