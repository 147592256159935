import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import _ from 'lodash';

import AppData from 'app_data';
import Utils from 'utils/common_utils';
import AnalyticsTrendPanelItem from 'components/analytics_panels/analytics_trend_panel_item';

class AnalyticsTrendPanels extends React.Component {
  static propTypes = {
    /** Handler for clicking a panel */
    onPanelClick: PropTypes.func,
    /** The selected panel */
    selected: PropTypes.object,
    /** Array of the panel items */
    panelItems: PropTypes.array,
    /** Class name for the component div */
    className: PropTypes.string,
    /** The filtering options for which panels are shown */
    filters: PropTypes.object,
    /** The time period being represented by the panels */
    timeRange: PropTypes.object,
    /** Whether or not to show percents in the panels */
    showPercent: PropTypes.bool,
  };

  state = {
    panelItems: [],
  };

  componentDidMount() {
    this.updateData(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.updateData(nextProps);
  }

  updateSelected = (index) => {
    this.props.onPanelClick(this.state.panelItems[index]);
  };

  updateData = (props) => {
    const selects = _.map(props.panelItems, (item) => _.omit(item, 'id', 'type'));

    const params = {
      selects,
      time_range: props.timeRange,
      filters: props.filters,
    };

    AppData.getTrendPanelData(params)
      .then((response) => {
        const { data } = response;
        const panelItems = _.cloneDeep(props.panelItems);
        _.each(panelItems, (item) => {
          if (item.type === 'Min') {
            data[item.label].hideUnit = true;
            data[item.label].total = Utils.humanizeSeconds(data[item.label].total);
          }
          if (item.type === 'Calls') {
            data[item.label].total = Utils.numberWithCommas(data[item.label].total);
          }
          _.assign(item, data[item.label]);
        });

        this.setState({ panelItems });
      });
  };
  
  render() {
    
    let width = 16;

    if (this.state.panelItems && this.state.panelItems.length > 6) {
      width = (1 / Math.ceil(this.state.panelItems.length / 2)) * 100;
    }

    const panelItems = _.map(this.state.panelItems, (item, idx) => {
      const isSelected = this.props.selected && (this.props.selected.label === item.label);
      const percent = this.props.showPercent ? item.percent : null;
      let hideBorder = false;

      if (this.state.panelItems.length > 6 && idx === Math.ceil(this.state.panelItems.length / 2) - 1) {
        hideBorder = true;
      }

      

      return (
        
        <AnalyticsTrendPanelItem
          key={idx}
          hideBorder={hideBorder}
          width={width}
          hideUnit={item.hideUnit}
          label={item.label}
          total={item.total}
          percent={percent}
          type={item.type}
          itemKey={idx}
          updateSelected={this.updateSelected}
          selected={isSelected}
        />
      );
    });
    return (
      <div className={this.props.className}>
        <div className="row">
          <div className="col-full">
            <div className="panel-box">
              {panelItems}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AnalyticsTrendPanels;
