import axios from 'axios';
import axiosMockAdapter from 'axios-mock-adapter';
import vex from 'lib/vex';


const onError = (error) => {
  let errorResponse = error;

  if (axios.isCancel(error)) {
    errorResponse = {
      isCancel: true,
    }
  } else if (error.code === 'ECONNABORTED') {
    errorResponse = { statusCode: 'ECONNABORTED' };
  } else if (error.response) {
    errorResponse = {
      errors: error.response.data,
      statusCode: error.response.status,
    };
  } else if (error.message === 'Network Error') {
    errorResponse = {
      errors: error.message,
    }
    vex.error([], null, error.message);
  }

  return Promise.reject(errorResponse);
};

const { REACT_APP_DEV } = process.env;

const instance = axios.create({
  withCredentials: true,
  timeout: 30000,
  baseURL: REACT_APP_DEV === 'true' ? '/api' : '',
  headers: {
    common: {
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      Pragma: 'no-cache',
    },
  },
});

instance.all = axios.all;
instance.spread = axios.spread;
instance.CancelToken = axios.CancelToken;
instance.isCancel = axios.isCancel;

instance.onError = onError;

export function makePostRequestCreator() {
  let call;

  return (url, params) => {
      if (call) {
        call.cancel();
      }

      call = instance.CancelToken.source();

      return instance
        .post(url, params, { cancelToken: call.token })
        .catch(onError);
  }
}

let axiosMock;

if (process.env.REACT_APP_TEST === 'true') {
  axiosMock = new axiosMockAdapter(instance);
}

export { axiosMock };

export default instance;
