import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import cx from 'classnames';

import InlineSvgFactory from 'components/inline_svg';

const Arrow = InlineSvgFactory('trend_arrow.svg');

class AnalyticsComparisionPanelItem extends Component {
  static propTypes = {
    /** The label of the panel */
    label: PropTypes.string,
    /** An array of items */
    valueItems: PropTypes.array,
    /** The percentage to be displayed on the panel */
    percent: PropTypes.number,
    /** The type of information the panel represents */
    type: PropTypes.string,
    /** Whether or not to show units on the panel */
    hideUnit: PropTypes.bool,
    /** Whether or not to hide the border on the panel */
    hideBorder: PropTypes.bool,
    /** Handler for updating the data on the selected panel */
    updateSelected: PropTypes.func,
    /** The unique key for the panel item */
    itemKey: PropTypes.number,
    /** Whether or not the panel is selected */
    selected: PropTypes.bool,
  };

  static defaultProps = {
    label: '',
    items: [],
    percent: 0,
    type: '',
  };

  handleClick = () => {
    this.props.updateSelected(this.props.itemKey);
  }

  render() {
    const classes = cx({
      'panel-item': true,
      'panel-selected': this.props.selected,
      'hide-border': this.props.hideBorder,
    });

    const arrowClass = cx({ 'arrow': true, 'down': this.props.percent < 0 });

    const vItems = _.map(this.props.valueItems, (item, idx) =>
      <div key={idx} className="col-half">
        <div className="call-group">
          {item.label}
        </div>
        <div className="panel-number">
          {item.total}
          <sup className="number-type">
            {!this.props.hideUnit && this.props.type}
          </sup>
        </div>
      </div>
    );

    return (
      <div className={classes} onClick={this.handleClick}>
        <div className="panel-comparision-item">
          <div className="panel-title">{this.props.label}</div>
          <div className="row">
            {vItems}
          </div>
          <div className="row">
            <div className="col-full">
              <div className="panel-percent">
                <Arrow className={arrowClass} />
                {Math.abs(this.props.percent)}%
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AnalyticsComparisionPanelItem;
