import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { enableBatching } from 'redux-batched-actions';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';

import rootReducer from 'reducers';
import createLogger from './createLogger';

const middleware = [thunk];
const {
  REACT_APP_DEV,
  REACT_APP_TEST,
  REACT_APP_PUBLIC_PATH,
} = process.env;

if (REACT_APP_DEV && REACT_APP_TEST !== 'true') {
  middleware.push(createLogger());
}

export const history = createBrowserHistory({
  basename: REACT_APP_PUBLIC_PATH || '/',
});

const enhancer = compose(
  applyMiddleware(
    ...middleware,
    routerMiddleware(history),
  ),
);

export default (initialState) => {
  const reducer = enableBatching(rootReducer(history));
  const store = createStore(reducer, initialState, enhancer);

  if (REACT_APP_DEV && module.hot) {
    module.hot.accept('../reducers', () =>
      // eslint-disable-next-line global-require
      store.replaceReducer(require('../reducers').default(history))
    );
  }

  return store;
};
