import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import storage from 'store2';
import { withRouter } from 'react-router-dom';
import { push } from 'connected-react-router';
import { ServiceUnavailablePlaceholder } from '@nextiva/next-ui';

import { DefaultSpinner } from 'components';
import loadUser from 'actions/user_actions';
import AppData from 'app_data';

import Logo from './Logo';
import User from './User';
import Footer from './Footer';
import Navigation from './Navigation';

import Styles from './Wrapper.module.scss';


class Wrapper extends Component {
  static propTypes = {
    /** Authentication succeeded */
    isAuthenticated: PropTypes.bool,
    /** Load current user information */
    loadUser: PropTypes.func,
    /** Authentication failed */
    authenticationFailed: PropTypes.bool,
    /** Why authentication failed */
    authenticationError: PropTypes.object,
    /** The user's saved default home page */
    defaultHomepage: PropTypes.string,
    /** The current active page of the app */
    currentActivePage: PropTypes.string,
    /** The main app components determined by route */
    children: PropTypes.node,
    push: PropTypes.func,
  };

  componentWillMount() {
    const { isAuthenticated, loadUser } = this.props;

    if (!isAuthenticated) {
      loadUser();
      AppData.loadAvatars();
    }
  }

  componentWillReceiveProps({ defaultHomepage }) {
    const { session } = storage;

    if (defaultHomepage && !session.has('homePageLoaded')) {
      session.set('homePageLoaded', true);

      this.props.push(defaultHomepage);
    }
  }

  render() {
    const {
      children,
      isAuthenticated,
      authenticationFailed,
      authenticationError,
      currentActivePage,
    } = this.props;

    let component = children;

    if (!isAuthenticated) {
      if (authenticationFailed) {
        const { response } = authenticationError;

        if (response && response.status === 403) {
          component = (
            <div className={Styles.loader}>
              <p>Redirecting to login screen...</p>
            </div>
          );
        } else {
          component = <ServiceUnavailablePlaceholder className={Styles.service}/>;
        }
      } else {
        component = (
          <DefaultSpinner />
        );
      }
    }

    return (
      <>
        <header className={Styles.header}>
          <nav className={Styles.navbar}>
            <Logo />
            <Navigation />
            {isAuthenticated && (
              <User currentActivePage={currentActivePage} />
            )}
          </nav>
        </header>

        <div className={Styles.content}>
          {component}
        </div>

        <Footer />
      </>
    );
  }
}


const mapStateToProps = ({ user, router }) => ({
  isAuthenticated: user.isAuthenticated,
  authenticationFailed: user.authenticationFailed,
  authenticationError: user.error,
  defaultHomepage: user.user.homepage,
  currentActivePage: router.location.pathname,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  loadUser,
  push,
}, dispatch);


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Wrapper));
