import { actionTypes } from 'react-redux-form';

import { NAME, INITIAL_STATE } from './constants';
import { getDistState, getMonthlyState, getRelativePeriodState } from './utils';

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.CHANGE:
      if (action.model === `${NAME}.frequency`) {
        return { ...state, ...getDistState(action.value) };
      } else if (action.model === `${NAME}.monthly.dist`) {
        return {
          ...state,
          ...{ monthly: getMonthlyState(action.value) },
        };
      } else if (action.model === `${NAME}.unit`) {
        return {
          ...state,
          ...getRelativePeriodState(action.value, state.start, state.end),
        };
      }
      return state;
    default:
      return state;
  }
};
