import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { DateRangePicker } from '@nextiva/analytics-ui';

import { bindControlsActions } from 'actions/controls_actions';
import Checkbox from 'components/checkbox';
import ButtonPopover from 'components/button_popover';
import TabbedControls from 'components/tabbed_controls';
import ListSelect from 'components/list_select';
import MultiSelect from 'components/multi_select';
import * as selectors from 'modules/monitoring';


const mapStateToProps = (state) => {
  const gamificationState = selectors.gamificationSelector(state);
  return {
    filterType: gamificationState.filterType,
    filterTypeOptions: gamificationState.filterTypeOptions,
    filters: gamificationState.filters,
    filterOptions: gamificationState.filterOptions,
    callType: gamificationState.callType,
    callTypeOptions: gamificationState.callTypeOptions,
    realTime: gamificationState.realTime,
    timeRange: gamificationState.timeRange,
    timezone: gamificationState.timezone,
    boardType: gamificationState.boardType,
    boardTypeOptions: gamificationState.boardTypeOptions,
    boardTheme: gamificationState.boardTheme,
    boardThemeOptions: gamificationState.boardThemeOptions,
    grouping: gamificationState.grouping,
    perPageOptions: gamificationState.perPageOptions,
    userCount: gamificationState.userCount,
    totalUsers: gamificationState.totalUsers,
  };
};

class GamificationControls extends Component {
  static propTypes = {
    /** Disable changing the board type */
    disableBoardControls: PropTypes.bool,
    /** Current filter type */
    filterType: PropTypes.object,
    /** Available filter types */
    filterTypeOptions: PropTypes.array,
    /** Selected filters */
    filters: PropTypes.array,
    /** Availble filters */
    filterOptions: PropTypes.array,
    /** Current call type */
    callType: PropTypes.object,
    /** Available call types */
    callTypeOptions: PropTypes.array,
    /** Are we in real time mode? */
    realTime: PropTypes.bool,
    /** Time range for the section */
    timeRange: PropTypes.object,
    /** User timezone */
    timezone: PropTypes.string,
    /** Displayed board type */
    boardType: PropTypes.object,
    /** Available board types */
    boardTypeOptions: PropTypes.array,
    /** Displayed board theme */
    boardTheme: PropTypes.object,
    /** Available board themes */
    boardThemeOptions: PropTypes.array,
    /** Whether or not to group custom groups */
    grouping: PropTypes.bool,
    /** Users per page options */
    perPageOptions: PropTypes.array,
    /** Current user per page option */
    userCount: PropTypes.object,
    /** Total users to display */
    totalUsers: PropTypes.number,
    push: PropTypes.func,
  }

  componentWillMount() {
    this.actions = bindControlsActions('gamification');
  }

  linkToGroups = () => {
    this.props.push('/settings/groups');
  };

  render() {
    const {
      filterType,
      filterTypeOptions,
      filters,
      filterOptions,
      callType,
      callTypeOptions,
      realTime,
      timeRange,
      timezone,
      boardType,
      boardTypeOptions,
      boardTheme,
      boardThemeOptions,
      grouping,
      perPageOptions,
      userCount,
      totalUsers,
    } = this.props;
    const { disableBoardControls } = this.props;
    const boardTypeSelect = (
      <ListSelect
        options={boardTypeOptions}
        selected={boardType}
        onSelect={this.actions.setBoardType}
      />
    );
    const boardThemeSelect = (
      <ListSelect
        options={boardThemeOptions}
        selected={boardTheme}
        onSelect={this.actions.setBoardTheme}
      />
    );
    const metrics = (
      <ListSelect
        options={callTypeOptions}
        selected={callType}
        onSelect={this.actions.setCallType}
      />
    );
    const filterTypes = (
      <>
        <ListSelect
          options={filterTypeOptions}
          selected={filterType}
          onSelect={this.actions.changeFilterType}
          compareField={filterType.id !== 'teams' ? 'id' : 'teamType'}
        />
        <div className="grouped-checkbox">
          {filterType.id === 'teams' ?
            <Checkbox
              label={'Grouped'}
              onChange={this.actions.setGrouping}
              checked={grouping}
            /> : null}
        </div>
      </>
    );
    let element = null;
    if (filterType && (filterType.bucketType === 'teams')) {
      element = (
        <div className="group-link-large" key={'gamification-element'}>
          <div className="multi-select-item">
            <span className="icon-custom-plus" />
            <a onClick={this.linkToGroups}>Create A New Custom Group</a>
          </div>
          <hr />
        </div>
        );
    }
    const filtersSelect = (
      <MultiSelect
        searchBar
        options={filterOptions}
        onChange={this.actions.setFilters}
        initialSelected={filters}
        displayElement={element}
      />
    );
    const perPage = (
      <>
        <ListSelect
          options={perPageOptions}
          selected={userCount}
          onSelect={this.actions.setUserCount}
        />
        <div className="total-users-input">
          Total
          <input
            type="number"
            className="gamification-total-users"
            onBlur={this.actions.setTotalUsers}
            defaultValue={totalUsers}
          />
        </div>
      </>
    );
    const tabbedControls = (
      <TabbedControls
        tabs={[
          { icon: 'icon-custom-indented-list', label: 'Board Type', content: boardTypeSelect, disabled: disableBoardControls },
          { icon: 'icon-custom-palette', label: 'Board Theme', content: boardThemeSelect },
          { icon: 'icon-custom-sliders', label: 'Metric', content: metrics },
          { icon: 'icon-custom-people', label: 'Filter Type', content: filterTypes },
          { icon: 'icon-custom-filter', label: 'Filters', content: filtersSelect },
          { icon: 'icon-custom-written-doc', label: 'Per Page', content: perPage },
        ]}
      />
    );

    return (
      <div className="gamification-monitoring-controls monitoring-controls">
        <DateRangePicker
          withRealTimeIcon
          className="date-range-popover"
          isRealTime={realTime}
          timezone={timezone}
          dateRange={timeRange}
          onChange={this.actions.setTimeRange}
          onRealTimeChange={this.actions.setRealTime}
          theme={{
            dateRangePicker: 'date-range',
          }}
        />
        <ButtonPopover
          key="gamification"
          popoverClass="controls-popover gamification-controls-popover"
          iconClass="icon-custom-settings"
          popoverBody={tabbedControls}
        />
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  { push },
)(GamificationControls);
