import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Control, Field, Errors, actions, track } from 'react-redux-form';
import { connect } from 'react-redux';
import cx from 'classnames';
import _ from 'lodash';
import validator from 'validator';

import Button from 'components/button';
import ToolTip from 'components/tooltip';
import { showErrorAfterTouched } from '../utils';
import QuestionsGroup from './questions_group';

const MAX_TITLE_LENGTH = 30;
const required = (val) => val && val.length;

const QuestionsCreateForm = ({ onSubmit, onCancel, questions_group, dispatch }) => (
  <Form
    className="questions-form"
    onSubmit={onSubmit}
    model="questions_group"
    validators={{
      questions: { required },
    }}
    validateOn="submit"
  >
    <div className="title">
      <h1>
        Create/Edit Question Group
      </h1>
    </div>
    <div className="questions-form__row">
      <span className="questions-form__label--section">Group</span>
      <div className="questions-form__input-container questions-form__name">
        <Control.text
          className="questions-form__input"
          model=".title"
          placeholder="Group name"
          type="text"
          updateOn="blur"
          validateOn={['change', 'blur']}
          validators={{
            required,
            length: (val) => validator.isLength(val, { min: 0, max: MAX_TITLE_LENGTH }),
          }}
        />
        <Errors
          className="questions-form__error"
          model=".title"
          messages={{
            required: 'A title is required',
            length: `Group name must be ${MAX_TITLE_LENGTH} characters or less`,
          }}
          show={showErrorAfterTouched}
        />
      </div>
      <div className="questions-form__input-container questions-form__description">
        <Control.text
          className="questions-form__input"
          model=".description"
          placeholder="Group description"
          type="text"
          updateOn="blur"
          validateOn={['change', 'blur']}
          validators={{ required }}
        />
        <Errors
          className="questions-form__error"
          model=".description"
          messages={{
            required: 'A description is required',
          }}
          show={showErrorAfterTouched}
        />
      </div>
    </div>
    <div className="questions-form__divider" />
    <div className="questions-form__row questions-form__row--questions">
      <span className="questions-form__label--section questions-form__label--questions">Questions
        <ToolTip
          tipContent="The Call Rating Report includes a metric &quot;Rating.&quot; This metric is determined as follows: Yes = 1, No = 0. For Scoring or Rating questions it is equal to Response divided by Max Score."
          tipClass="questions-form__tooltip"
        />
      </span>
      <QuestionsGroup questions={questions_group.questions} dispatch={dispatch} />
    </div>
    <Errors
      className="questions-form__error"
      model=".questions[]"
      messages={{
        required: 'At least one question is required',
      }}
    />
    <div className="questions-form__buttons">
      <Button className="btn" onClick={onCancel}>Cancel</Button>
      <Button className="btn btn-green" type="submit">Submit</Button>
    </div>
  </Form>
);

QuestionsCreateForm.propTypes = {
  questions_group: PropTypes.object,
  dispatch: PropTypes.func,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
};

export default connect(({ questions_group }) => ({ questions_group }))(QuestionsCreateForm);
