import React from 'react';
import moment from 'moment';
import Utils from 'utils/common_utils';


const BaseChartMixin = (Component) => class extends Component {
  componentDidMount() {
    this.component.redrawFn = () => { this.component.chart.validateNow(); };
    window.addEventListener('redrawChart', this.component.redrawFn);
    this.component.enableExport = (chartName) => {
      chartName = typeof chartName !== 'undefined' ? chartName.replace(' ', '_') : 'Analytics_Chart';
      chartName += `_${moment().format('YYYYMMDDHHMMSS')}`;

      if (!this.component.props.disableDownload) {
        if (!this.component.props.timeRange) return;
        // TODO support period
        const { start, end } = Utils.getStartEndDates(this.component.props.timeRange);
        const fromDate = moment(start).format('MM/DD/YYYY hh:mm A');
        const toDate = moment(end).format('M/DD/YYYY hh:mm A');
        const pdfText = `${this.component.props.exportTitle} \r\n ${fromDate} - ${toDate}`;
        this.component.chart.export = {
          fileName: chartName,
          enabled: true,
          libs: { path: '/amcharts/plugins/export/libs/' },
          menu: [{
            class: 'export-main',
            label: 'Export',
            menu: [{
              'format': 'PDF',
              'content': [
                pdfText,
                  { 'image': 'reference', 'fit': [523.28, 769.89] },
              ],
            }],
          }],
          beforeCapture: () => {
            if (this.component.props.legendPosition === 'none') {
              this.component.chart.legend.enabled = true;
              this.component.chart.write(this.component.props.chartKey);
            }
          },
          afterCapture: () => {
            if (this.component.props.legendPosition === 'none') {
              this.component.chart.legend.enabled = false;
            }
          },
        };
        this.component.chart.pathToImages = '/amcharts/images/';
      }
    };
    this.component.buildChart();
    this.component.chart.validateData();
  }

  componentWillReceiveProps(nextProps) {
    if (this.component.props.height !== nextProps.height) {
      this.component.chart.invalidateSize();
    }
  }

  componentWillUnmount() {
    this.component.chart.clear();
    window.removeEventListener('redrawChart', this.component.redrawFn);
  }

  componentDidUpdate() {
    if (this.component.props.forceRender) {
      this.component.buildChart();
    } else {
      this.component.graphData(this.component.props.data);
      this.component.chart.validateData();
    }
  }

  formatSeconds = (graphDataItem, graph) => {
    const { category, values } = graphDataItem;
    let date = category;
    const value = Utils.humanizeSeconds(values.value);

    if (moment(category, 'YYYY-MM-DD', true).isValid()) {
      date = moment(new Date(category)).format('MMM D, YYYY');
    }

    return (`
      ${date}
      <br/>
      <b>
        ${value}
        <br />
        ${graph.title}
      </b>
    `);
  };

  getLegendPosition = () => {
    let position = 'top';
    if (this.component.props.legendPosition && this.component.props.legendPosition !== 'none') {
      position = this.component.props.legendPosition;
    }
    return position;
  };

  render() {
    return (
      <Component
        ref={(c) => { this.component = c; }}
        {...this.props}
        formatSeconds={this.formatSeconds}
        getLegendPosition={this.getLegendPosition}
      />
    );
  }
};

export default BaseChartMixin;
