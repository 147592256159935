import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Editor, EditorState, RichUtils } from 'draft-js';
import { convertFromHTML, convertToHTML } from 'draft-convert';
import cx from 'classnames';

import 'draft-js/dist/Draft.css';
import BlockStyleControls from './block_style_controls';
import InlineStyleControls from './inline_style_controls';

const styleMap = {
  CODE: {
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
    fontSize: 16,
    padding: 2,
  },
};

function getBlockStyle(block) {
  switch (block.getType()) {
    case 'blockquote': return 'RichEditor-blockquote';
    default: return null;
  }
}

class RichTextEditor extends Component {
  static propTypes = {
    /** Called when the editor state is updated */
    onChange: PropTypes.func,
    /** Called when the editor is focused */
    onFocus: PropTypes.func,
    /** Called when the editor is unfocused */
    onBlur: PropTypes.func,
    /** Content value */
    value: PropTypes.string,
    /** Placeholder for the text area */
    placeholder: PropTypes.string,
  }

  constructor(props) {
    super(props);
    this.state = {
      editorState: EditorState.createEmpty(),
      hasFocus: false,
    };
  }

  componentWillMount() {
    const { value } = this.props;
    if (value) {
      const editorState = EditorState.createWithContent(convertFromHTML(value));
      this.setState({ editorState });
    }
  }

  handleChange = (editorState) => {
    const { onChange } = this.props;
    this.setState({ editorState });
    if (onChange) {
      onChange(editorState.getCurrentContent());
    }
  }

  handleBlur = () => {
    const { onBlur } = this.props;
    const { editorState } = this.state;
    if (onBlur) {
      const html = convertToHTML(editorState.getCurrentContent());
      onBlur(html);
    }
  }

  handleKeyCommand = (command) => {
    const { editorState } = this.state;
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      this.handleChange(newState);
      return true;
    }
    return false;
  }

  handleTab = (e) => {
    const { editorState } = this.state;
    const maxDepth = 4;
    this.handleChange(
      RichUtils.onTab(e, editorState, maxDepth)
    );
  }

  toggleBlockType = (blockType) => {
    this.handleChange(
      RichUtils.toggleBlockType(
        this.state.editorState,
        blockType,
      )
    );
  }

  toggleInlineStyle = (inlineStyle) => {
    this.handleChange(
      RichUtils.toggleInlineStyle(
        this.state.editorState,
        inlineStyle
      )
    );
  }

  focus = () => {
    this.setState({ hasFocus: true },
      this.editor.focus()
    );
  }

  blur = () => {
    this.setState({ hasFocus: false },
      this.editor.blur()
    );
  }

  render() {
    const { editorState, hasFocus } = this.state;
    const { onFocus, placeholder } = this.props;
    const rootClassName = [
      'RichEditor-root',
    ];
    const editorClassName = [
      'RichEditor-editor',
    ];
    return (
      <div className={cx(rootClassName)}>
        <div className="RichEditor-toolbar">
          <InlineStyleControls
            editorState={editorState}
            onToggle={this.toggleInlineStyle}
          />
          <BlockStyleControls
            editorState={editorState}
            onToggle={this.toggleBlockType}
          />
        </div>
        <div className={cx(editorClassName)} onClick={this.focus} onBlur={this.blur}>
          <Editor
            blockStyleFn={getBlockStyle}
            customStyleMap={styleMap}
            editorState={editorState}
            handleKeyCommand={this.handleKeyCommand}
            onChange={this.handleChange}
            onFocus={onFocus}
            onBlur={this.handleBlur}
            onTab={this.handleTab}
            placeholder={placeholder}
            ref={(node) => { this.editor = node; }}
            spellCheck
          />
        </div>
      </div>
    );
  }
}

export default RichTextEditor;
