import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { DefaultSpinner } from 'components';

import AppData from 'app_data';
import DataTable from 'components/data_table';
import Button from 'components/button';
import Edit from './Edit';
import { mapStateToProps, mapDispatchToProps } from './connector';


const USERS_PER_PAGE = 15;


class UserManagementView extends Component {
  static propTypes = {
    /** User management redux actions */
    userManagementActions: PropTypes.object,
    push: PropTypes.func,
    match: PropTypes.object,
  };

  state = {
    users: [],
    filterText: '',
    selectedUser: null,
  };

  componentWillMount() {
    // TODO: need to refactor it
    _.debounce(this.fetchAllUsers, 500)();
  }

  componentWillReceiveProps({ match }) {
    const { idUser } = match.params;
    const selectedUser = this.state.users.find((user) => user.id === idUser);

    this.setState({
      selectedUser,
    });
  }

  fetchAllUsers = () => {
    const {
      push,
      match,
      userManagementActions,
    } = this.props;
    const users = _.values(AppData.avatars);

    const { idUser } = match.params;
    const selectedUser = users.find((user) => user.id === idUser);

    if (selectedUser) {
      userManagementActions.storeUser(selectedUser);
    } else {
      push('/settings/user-management');
    }

    this.setState({
      users,
      selectedUser,
    });
  };

  handleAction = (obj) => {
    const selectedUser = this.state.users.find((user) => user.id === obj.id);

    this.setState({
      selectedUser,
    });

    this.props.userManagementActions.storeUser(selectedUser);
    this.props.push(`/settings/user-management/${obj.id}`);
  };

  handleSearch = (event) => {
    this.setState({ filterText: event.target.value });
  };

  labelCallGroup = (label) => (label === 'Hunt Group' ? 'Call Group' : label);

  renderPopOver = (link, index, obj) => (
    <Button
      className="btn-transparent"
      onClick={this.handleAction.bind(this, obj)}
    >
      <img className="avatar" src={link} alt={`Avatar for ${obj.label}`} />
    </Button>
  );

  render() {
    const {
      users,
      selectedUser,
    } = this.state;

    if (selectedUser) {
      return <Edit />;
    }

    if (users.length === 0) {
      return (
        <DefaultSpinner />
      );
    }

    const headers = [
      { label: 'Avatar', key: 'avatar', labelFunc: this.renderPopOver },
      { label: 'Name', key: 'label' },
      { label: 'Type', key: 'type', labelFunc: this.labelCallGroup },
      { label: 'Location', key: 'locationName' },
    ];
    const actions = [
      { name: 'Edit' },
    ];

    const content = (
      <DataTable
        headers={headers}
        rows={users}
        actions={actions}
        onAction={this.handleAction}
        perPage={USERS_PER_PAGE}
        filterText={this.state.filterText}
      />
    );

    return (
      <div className="user-management-container">
        <div className="user-management-search-container">
          <label htmlFor="user-management-search">Filter:</label>
          <input id="user-management-search" type="search" onChange={this.handleSearch} />
        </div>

        {content}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserManagementView);
