import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { bindControlsActions } from 'actions/controls_actions';
import { DateRangePicker } from '@nextiva/analytics-ui';

import { DateRangePeriod } from 'components';

import MultiSelect from 'components/multi_select';
import BucketsModal from 'components/buckets_modal';
import ButtonPopover from 'components/button_popover';
import TabbedControls from 'components/tabbed_controls';
import PageDropdown from 'components/analytics_controls/page_dropdown';
import ComparisonDropdowns from 'components/analytics_controls/comparison_dropdowns';
import { selectors } from 'modules/analytics';

const mapStateToProps = ({ controls }) => {
  const { currentSection } = controls || {};
  const trend = (controls && controls[controls.currentSection]) || {};
  return {
    currentSection,
    ready: trend.ready,
    filterType: trend.filterType,
    filters: trend.filters,
    filterOptions: trend.filterOptions,
    callTypes: trend.callTypes,
    callTypeOptions: trend.callTypeOptions,
    realTime: trend.realTime,
    timeRanges: trend.timeRanges || [trend.timeRange],
    timezone: trend.timezone,
    minuteBuckets: trend.minuteBuckets,
    actions: bindControlsActions(currentSection)
  };
};

class TrendControls extends Component {
  static propTypes = {
    /** Class name for the component div */
    className: PropTypes.string,
    /** The current page */
    page: PropTypes.string,
    /** The current section of the current page */
    section: PropTypes.string,
    /** Current filter type */
    filterType: PropTypes.object,
    /** Selected filters */
    filters: PropTypes.array,
    /** Availble filters */
    filterOptions: PropTypes.array,
    /** Selected call types */
    callTypes: PropTypes.array,
    /** Available call types */
    callTypeOptions: PropTypes.array,
    /** Are we in real time mode? */
    realTime: PropTypes.bool,
    /** Time range for the section */
    timeRanges: PropTypes.array,
    /** User timezone */
    timezone: PropTypes.string,
    push: PropTypes.func,
    ready: PropTypes.bool,
    currentSection: PropTypes.string,
    minuteBuckets: PropTypes.array,
  };

  linkToGroups = () => {
    this.props.push('/settings/groups');
  };

  render() {
    const {
      ready,
      actions,
      filterType,
      filters,
      filterOptions,
      callTypes,
      callTypeOptions,
      realTime,
      timeRanges,
      timezone,
      currentSection,
      minuteBuckets,
    } = this.props;

    if (!ready) {
      return null;
    }

    const metrics = (
      <MultiSelect
        searchBar
        options={callTypeOptions}
        onChange={actions.setCallTypes}
        initialSelected={callTypes}
      />
    );
    const tabs = [
      { icon: 'icon-custom-sliders', label: 'Metrics', content: metrics },
    ];

    let element = null;
    if (filterType && (filterType.id === 'teams')) {
      element = (
        <div className="group-link-small" key={'trend-element'}>
          <div className="multi-select-item">
            <span className="icon-custom-plus" />
            <a onClick={this.linkToGroups}>Create A New Custom Group</a>
          </div>
          <hr />
        </div>
        );
    }

    if (filterOptions.length) {
      const filtersSelect = (
        <MultiSelect
          searchBar
          options={filterOptions}
          onChange={actions.setFilters}
          initialSelected={filters}
          displayElement={element}
        />
      );
      tabs.push(
        { icon: 'icon-custom-filter', label: 'Filters', content: filtersSelect },
      );
    }

    const tabbedControls = (
      <TabbedControls
        tabs={tabs}
      />
    );
    const buttonPopover = (
      <ButtonPopover
        popoverClass="controls-popover analytics-controls-popover"
        iconClass="icon-custom-settings"
        popoverBody={tabbedControls}
      />
    );

    const hideComparison = filterOptions.length < 2;

    return (
      <div className={`analytics-controls analytics-${currentSection}`}>
        <PageDropdown
          page={this.props.page}
          section={this.props.section}
          hideComparison={hideComparison}
        />

        {currentSection === 'comparison' && (
          <ComparisonDropdowns
            dropdownOptions={filterOptions}
            onComparisonChange={actions.setFilters}
          />
        )}

        <div className="additional-controls">
          {timeRanges.length === 1
            ? (
              <DateRangePicker
                withRealTimeIcon
                className="date-range-popover"
                isRealTime={realTime}
                timezone={timezone}
                dateRange={timeRanges[0]}
                onChange={actions.setTimeRange}
                onRealTimeChange={actions.setRealTime}
                theme={{
                  dateRangePicker: 'date-range',
                }}
              />
            ) : (
              <DateRangePeriod
                timezone={timezone}
                timeRanges={timeRanges}
                onChange={actions.setTimeRanges}
              />
            )
          }

          {buttonPopover}

          {minuteBuckets && (
            <BucketsModal
              onChange={actions.setMinuteBuckets}
              className="chart-buckets"
              value={minuteBuckets}
            />
          )}
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  {
    push,
  }
)(TrendControls);
