import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import Utils from 'utils/common_utils';
import FlipCounterDigit from 'components/flip_counter_digit';

const FlipCounter = ({ value, displayType }) => {
  const values = {};
  let labels;

  if (displayType === 'seconds') {
    values.minutes = parseInt(value / 60, 10);
    labels = ['minutes'];
  } else if (displayType === 'full') {
    labels = ['hours', 'minutes', 'seconds'];
    values.hours = Utils.addDigit(parseInt(value / 3600, 10) % 24);
    values.minutes = Utils.addDigit(parseInt(value / 60, 10) % 60);
    values.seconds = Utils.addDigit(value % 60);
  } else if (displayType === 'calls') {
    labels = ['calls'];
    values.calls = value;
  }

  const items = _.each(labels).map((item, eachIndex) => {
    const digits = values[item].toString().split('');
    const counters = _.map(digits, (digit, index) => (
      <FlipCounterDigit key={index} digit={digit} />
    ));
    return (
      <div key={eachIndex} className="counter-section">
        <span>{item}</span>
        <div>{counters}</div>
      </div>
    );
  });

  return <div>{items}</div>;
};

FlipCounter.propTypes = {
  /** Value of the flip counter */
  value: PropTypes.number,
  /** The unit type of the flip counter */
  displayType: PropTypes.string,
};

export default FlipCounter;
