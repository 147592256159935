import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Control, actions } from 'react-redux-form';
import BEMHelper from 'react-bem-helper';

import Timezones from 'timezones';
import HoursMinutesInput from 'components/hours_minutes_input';
import WeekdayCheckboxes from './weekday_checkboxes';
import TimeRange from './time_range';
import DateTime from './date_time';
import { required, checkDateValue } from '../validation';

const classes = new BEMHelper('scheduled-report-form');

function getQuantity(unit) {
  switch (unit) {
    case 'minutes':
      return [...Array(59).keys()].map((x) => ++x);
    case 'hours':
      return [...Array(24).keys()].map((x) => ++x);
    case 'days':
      return [...Array(366).keys()].map((x) => ++x);
    case 'weeks':
      return [...Array(52).keys()].map((x) => ++x);
    case 'months':
      return [...Array(12).keys()].map((x) => ++x);
    default:
      return [];
  }
}

function getQuantityOptions(unit) {
  const quantities = getQuantity(unit);
  return quantities.map((quantity) => (
    <option key={quantity} value={quantity}>{quantity}</option>
  ));
}

function getWeekdayOptions() {
  const weekdays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  return weekdays.map((weekday, index) => (
    <option key={weekday} value={index + 1}>{weekday}</option>
  ));
}

function getQtyOrWeekdays(reportingProps) {
  const unit = reportingProps.relativeUnit;
  if (unit === 'weekdays') {
    return [
      <div key="weekday-start" {...classes('field')}>
        <label {...classes('label')}>Start</label>
        <Control.select model=".start.value">
          {getWeekdayOptions()}
        </Control.select>
      </div>,
      <div key="weekday-end" {...classes('field')}>
        <label {...classes('label')}>End</label>
        <Control.select model=".end.value">
          {getWeekdayOptions()}
        </Control.select>
      </div>,
    ];
  }
  return (
    <div {...classes('field')}>
      <label {...classes('label')}>Qty</label>
      <Control.select model=".quantity">
        {getQuantityOptions(unit)}
      </Control.select>
    </div>
  );
}

const FixedPeriod = (props) => (
  <div {...classes('fixed-dates')}>
    <div>
      <div {...classes('field')}>
        <label {...classes('label')}>Start date</label>
        <DateTime
          model=".start"
          value={props.start.value}
        />
      </div>
    </div>
    <div {...classes('field')}>
      <label {...classes('label')}>End date</label>
      <DateTime
        model=".end"
        value={props.end.value}
      />
    </div>
  </div>
);

FixedPeriod.propTypes = {
  start: PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
  }),
  end: PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
  }),
};

export const showExcludeTodayOptions = [
  'days',
  'weeks',
  'months',
];

const RelativePeriod = (props) => (
  <div {...classes('relative')}>
    <div {...classes('relative-dates')}>
      <div {...classes('relative-units')}>
        <div {...classes('field')}>
          <label {...classes('label')}>Units</label>
          <Control.select model=".unit">
            <option value="minutes">Minutes</option>
            <option value="hours">Hours</option>
            <option value="days">Days</option>
            <option value="weeks">Weeks</option>
            <option value="months">Months</option>
            <option value="weekdays">Days of the Week</option>
          </Control.select>
        </div>
        {getQtyOrWeekdays(props)}
        {props.relativeUnit !== 'weekdays' ?
          <span {...classes('tip', 'period')}>
            {`${props.relativeUnit} before the report will be generated`}
          </span>
          : null}
      </div>

      {showExcludeTodayOptions.includes(props.relativeUnit) && (
        <span className="standard-checkbox">
          <Control.checkbox model=".excludeToday" id="reporting-period-exclude-today" />
          <label htmlFor="reporting-period-exclude-today">
            exclude the day this report was viewed
          </label>
        </span>
      )}

      <TimeRange
        startModel=".start"
        endModel=".end"
        tooltip="The time range defines the start time on the first day and the end time on the last day of the report."
      />
    </div>
  </div>
);

RelativePeriod.propTypes = {
  excludeToday: PropTypes.bool,
  relativeUnit: PropTypes.string,
};

const ReportingPeriod = (props) => {
  let periodSection;

  const timezoneOptions = Timezones.map((row, index) => (
    <option key={index} value={row.id}>{row.label}</option>
  ));

  if (props.period === 'fixed') {
    periodSection = (
      <FixedPeriod start={props.start} end={props.end} />
    );
  } else if (props.period === 'relative') {
    periodSection = (
      <RelativePeriod {...props} />
    );
  }

  return (
    <div {...classes('reporting')}>
      <div {...classes('field', 'period')}>
        <label {...classes('label')}>Time Frame</label>

        <Control.select model=".period">
          <option value="fixed">Fixed</option>
          <option value="relative">Relative</option>
        </Control.select>
      </div>

      <div {...classes('reporting-sections')}>
        <div {...classes('reporting-section')}>
          {periodSection}
        </div>
      </div>

      <div {...classes('field', 'timezone')}>
        <label {...classes('label')}>Timezone</label>
        <Control.select
          model=".timezone"
          validators={{ required }}
        >
          <option value="">Select timezone</option>
          {timezoneOptions}
        </Control.select>
      </div>
    </div>
  );
};

ReportingPeriod.propTypes = {
  relativeUnit: PropTypes.string,
  start: PropTypes.object,
  end: PropTypes.object,
  period: PropTypes.oneOf(['fixed', 'relative']),
};

export default ReportingPeriod;
