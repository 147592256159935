import React from 'react';
import PropTypes from 'prop-types';
import { Control } from 'react-redux-form';
import BEMHelper from 'react-bem-helper';

import Tooltip from 'components/tooltip';
import { required, scheduleNameLength } from '../validation';

const classes = new BEMHelper('scheduled-report-form');

const Schedule = ({ availableReports, reportNames }) => (
  <div {...classes('scheduled')}>
    <div {...classes('field', 'schedule-name')}>
      <label {...classes('label')}>Schedule name</label>

      <Control.text
        model=".scheduleName"
        updateOn="blur"
        validateOn={['blur', 'change']}
        validators={{
          required,
          isLength: scheduleNameLength,
          unique: (val) => !reportNames.includes(val),
        }}
      />
    </div>

    <div {...classes('field', 'report-name')}>
      <div>
        <label {...classes('label')}>Report name</label>
      </div>

      <Control.select
        model=".reportId"
        validators={{ required }}
      >
        <option value="report name">Report name</option>

        {availableReports.map((report) => (
          <option key={report.id} value={report.id}>
            {report.label}
          </option>
        ))}
      </Control.select>
    </div>
  </div>
);

Schedule.propTypes = {
  availableReports: PropTypes.array,
  reportNames: PropTypes.array,
};

export default Schedule;
