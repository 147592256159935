import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import AppData from 'app_data';
import Utils from 'utils/common_utils';


class QualityPanelItem extends Component {
  static propTypes = {
    /** The label of the panel */
    label: PropTypes.string,
    /** The value to be displayed on the panel */
    value: PropTypes.number,
    /** Optional label for the bottom part of the panel. */
    percentLabel: PropTypes.string,
    /** Optional percentage for the bottom part of the panel/ */
    percentValue: PropTypes.number,
  };

  static defaultProps = {
    label: '',
    value: 0,
    percentLabel: '',
    percentValue: 0.0,
  };

  render() {
    return (
      <div className="panel-item">
        <div className="panel-title">{this.props.label}</div>
        <div className="panel-percent">
          {Utils.numberWithCommas(this.props.value)}

          {this.props.percentLabel !== null ?
          <div className="panel-number">
            {this.props.percentLabel} <span>{this.props.percentValue}%</span>
          </div>
        : null}
        </div>
      </div>
    );
  }
}


class QualityTrendPanels extends React.Component {
  static propTypes = {
    /** The time period being represented by the panels */
    timeRange: PropTypes.object
  };

  state = {
    panelData: [],
  };

  componentDidMount() {
    this.updateData(this.props);
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.timeRange, this.props.timeRange)) {
      this.updateData(this.props);
    }
  }

  updateData = (props) => {
    const params = {
      time_range: props.timeRange
    };

    AppData.getQualityPanelData(params)
      .then((response) => {
        this.setState({ panelData: response.data });
      });
  };

  render() {
    const panelItems = this.state.panelData.map((item, idx) => {
      return (
        <QualityPanelItem
          key={idx}
          label={item.label}
          value={item.value}
          percentLabel={item.percentLabel}
          percentValue={item.percentValue}
          style={{border:'1px',solid:'black'}}
        />
      );
    });
 return (
      <div className="analytics-panel comparison">
        <div className="row">
          <div className="col-full">
            <div className="panel-box">
              {panelItems}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default QualityTrendPanels;
