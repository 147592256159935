import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

class Button extends Component {
  static propTypes = {
    /** Whether or not the button is disabled */
    disabled: PropTypes.bool,
    /** A handler for when the button is clicked */
    onClick: PropTypes.func,
    /** The class of the component the button is placed in */
    componentClass: PropTypes.string,
    /** The type of button: either button, reset, submit */
    type: PropTypes.oneOf(['button', 'reset', 'submit']),
    /** The className for the style of the button */
    className: PropTypes.string,
  }

  static defaultProps = {
    disabled: false,
  }

  renderButton(className) {
    const { componentClass, ...otherProps } = this.props;
    const Component = componentClass || 'button';

    return (
      <Component
        {...otherProps}
        type={otherProps.type || 'button'}
        className={className}
      />
    );
  }

  render() {
    const { disabled, className } = this.props;
    const fullClassName = cx(className, {
      disabled,
    });

    return this.renderButton(fullClassName);
  }
}

export default Button;
