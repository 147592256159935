import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import InlineSvgFactory from 'components/inline_svg';

const Arrow = InlineSvgFactory('trend_arrow.svg');

class AnalyticsTrendPanelItem extends Component {
  static propTypes = {
    /** Whether or not to hide the border on the panel */
    hideBorder: PropTypes.bool,
    /** Unique key for the item on the panel */
    itemKey: PropTypes.number,
    /** Handler to update the selected panel */
    updateSelected: PropTypes.func,
    /** Whether or not the panel is selected */
    selected: PropTypes.bool,
    /** The label of the panel */
    label: PropTypes.string,
    /** The total to be displayed on the panel */
    total: PropTypes.string,
    /** The percentage to be displayed on the panel */
    percent: PropTypes.number,
    /** The type of information the panel represents */
    type: PropTypes.string,
    /** Whether or not to show the units on the panel */
    hideUnit: PropTypes.bool,
    /** The width of the panel */
    width: PropTypes.number,
  };

  static defaultProps = {
    label: '',
    total: 0,
    percent: 0,
    type: '',
  };

  handleClick = () => {
    this.props.updateSelected(this.props.itemKey);
  };

  

  render() {
    
    const classes = cx(
      {
        'panel-item': true,
        'panel-selected': this.props.selected,
        'hideAfter': this.props.hideBorder,
      }
    );

    const arrowClass = cx({ 'arrow': true, 'down': this.props.percent < 0 });
    const percent = Math.abs(this.props.percent);
    const percentDisplay = percent <= 1000 ? percent : '1000+';

   

    return (
      <div
        onClick={this.handleClick}
        className={classes}
      >
        <div className="panel-title">{this.props.label}</div>
        <div className="panel-number">
          {this.props.total}
          <sup className="number-type">
            {!this.props.hideUnit && this.props.type}
          </sup>
        </div>
        {this.props.percent !== null ?
          <div className="panel-percent">
            <Arrow className={arrowClass} />{percentDisplay}%
          </div>
        : null}
      </div>
    );
  }
}

export default AnalyticsTrendPanelItem;
