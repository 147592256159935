

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Draggable from 'react-draggable';
import cx from 'classnames';
import _ from 'lodash';
import { DefaultSpinner } from 'components';

import Utils from 'utils/common_utils.js';
import Wave from 'components/call_recording/wave.js';
import Volume from 'components/call_recording/volume';

class Player extends Component {
  static propTypes = {
    /** The audio source */
    audioSrc: PropTypes.string.isRequired,
    /** Class name for the player */
    className: PropTypes.string,
    /** Handler for when the clip changes */
    onClipChange: PropTypes.func,
    /** Handler for when there is an error */
    onError: PropTypes.func,
    /** Handler for when the clip is loaded */
    onLoad: PropTypes.func,
    /** Options for the call recording player */
    options: PropTypes.object,
    /** Start and end percentages of the file */
    initialClipPcts: PropTypes.object,
    /** The maximum volume possible shown in the UI */
    volumeHeight: PropTypes.number,
    /** The width of the player */
    width: PropTypes.number,
    /** The height of the player canvas */
    canvasHeight: PropTypes.number,
  };

  static defaultProps = {
    width: 500,
    volumeHeight: 60,
    canvasHeight: 120,
  };

  constructor(props) {
    super(props);

    this.state = {
      playing: false,
      progressPct: 0,
      volumeVisible: false,
      volume: 1,
      seconds: 0,
      progressWidth: 400,
      leftClipPx: 0,
      rightClipPx: this.props.width,
      ready: false,
    };
  }

  componentDidMount() {
    // get width of slider;
    const progressRect = this.refs.progress.getBoundingClientRect();

    this.setState({
      progressWidth: progressRect.width - 15,
    });

    const localOpts = {
      onUpdate: this.onSoundUpdate.bind(this),
      onClipChange: this.handleClipChange.bind(this),
      onEnd: this.onSoundEnd.bind(this),
      canvasWidth: this.props.width,
      initialClipPcts: this.props.initialClipPcts,
    };

    if (this.props.onLoad) {
      localOpts.onLoad = () => {
        // eslint-disable-next-line
        this.props.onLoad;
        this.setState({ ready: true });
      };
    }

    if (this.props.onError) {
      localOpts.onError = this.props.onError;
    }

    const waveOptions = _.assign({}, localOpts, this.props.options);

    this.wave = new Wave(this.props.audioSrc, this.refs.canvas, waveOptions);
  }

  componentWillUnmount() {
    if (this.wave) {
      this.wave.pause();
      this.wave.unload();
    }
  }

  onSoundEnd() {
    this.setState({
      playing: false,
    });
  }

  onSoundUpdate(progress, duration) {
    this.setState({
      progressPct: progress / 100,
      seconds: progress / 100 * duration,
    });
  }

  getClipBounds() {
    const bounds = {};
    bounds.lower = this.state.leftClipPx ? this.state.leftClipPx / this.props.width : 0;
    bounds.upper = this.state.rightClipPx ? this.state.rightClipPx / this.props.width : 1;

    return bounds;
  }

  togglePlay = () => {
    if (!this.wave.ready()) {
      return;
    }

    if (this.state.playing) {
      this.wave.pause();
    } else {
      this.wave.play();
    }

    this.setState({
      playing: !this.state.playing,
    });
  }

  handleDrag = (e, DraggableData) => {
    if (!this.wave.ready()) return;

    const bounds = this.getClipBounds();
    const progressPct = _.clamp(DraggableData.x / this.state.progressWidth, bounds.lower, bounds.upper);
    if (this.state.playing) {
      this.togglePlay();
    }

    this.wave.seek(progressPct);
    this.setState({ progress: progressPct });
  }

  handleDragEnd = (e) => {
    if (!this.state.playing && e.target.className === 'handle') {
      this.togglePlay();
    }
  }

  handleClipChange = (start, end) => {
    this.setState({
      leftClipPx: start,
      rightClipPx: end,
    });

    if (this.props.onClipChange) {
      this.props.onClipChange({
        start: (start / this.props.width * this.wave.duration()).toFixed(2),
        end: (end / this.props.width * this.wave.duration()).toFixed(2),
      });
    }
  }

  toggleVolume = () => {
    this.setState({ volumeVisible: !this.state.volumeVisible });
  }

  handleVolumeChange = (e, DraggableData) => {
    const volume = _.clamp((Number(this.props.volumeHeight) - DraggableData.y) / Number(this.props.volumeHeight), 0, 1);
    this.wave.volume(volume);
    this.setState({ volume });
  }

  handleVolumeBarClick = (e) => {
    // ignore .handle clicks
    if (e.target.className === 'handle') return;

    const progressRect = this.refs.volumeComponent.refs.volumeProgress.getBoundingClientRect();
    const volume = 1 - ((e.clientY - progressRect.top - 5) / this.props.volumeHeight);

    this.wave.volume(volume);
    this.setState({ volume });
  }

  handleProgressBarClick = (e) => {
    if (!this.wave.ready()) return;

    // ignore .handle clicks
    if (e.target.className === 'handle') return;

    const bounds = this.getClipBounds();
    const progressRect = this.refs.progress.getBoundingClientRect();
    const clickProgress = (e.clientX - progressRect.left - 7) / this.state.progressWidth;
    const progressPct = _.clamp(clickProgress, bounds.lower, bounds.upper);

    this.wave.seek(progressPct);
    this.setState({ progress: progressPct });
  }

  render() {
    let volumeComponent;
    let controlsStyle;
    let className = 'call-recording-player';

    if (this.props.className) {
      className = `${className} ${this.props.className}`;
    }

    if (this.state.volumeVisible) {
      volumeComponent = (
        <Volume
          ref="volumeComponent"
          volumeHeight={this.props.volumeHeight}
          onChange={this.handleVolumeChange}
          onVolumeBarClick={this.handleVolumeBarClick}
          volume={this.state.volume}
        />
      );
    }

    const bounds = {
      left: 0,
      right: this.state.progressWidth,
    };

    if (this.state.leftClipPx) {
      bounds.left = (this.state.leftClipPx / this.props.width) * this.state.progressWidth + 1;
    }

    if (this.state.rightClipPx < this.props.width) {
      bounds.right = (this.state.rightClipPx / this.props.width) * this.state.progressWidth - 1;
    }

    return (
      <div className={className}>
        {!this.state.ready ?
          <div className="overlay">
            <DefaultSpinner />
          </div>
        : null }
        <div ref="canvas" className="canvas-container" />
        <div
          className="controls"
          style={{ width: `${this.props.width}px` }}
        >
          <TimeDisplay progressPct={this.state.progressPct} seconds={this.state.seconds} />
          <span
            className={cx({
              'icon-custom-circle-pause': this.state.playing,
              'icon-custom-circle-play': !this.state.playing,
            })}
            onClick={this.togglePlay}
          />
          <div ref="progress" className="progress-container" onClick={this.handleProgressBarClick}>
            <div
              style={{
                width: `${(this.state.progressPct * this.state.progressWidth) + 10}px`,
              }}
              className="progress"
            />
            <Draggable
              axis="x"
              defaultPosition={{ x: 0, y: 0 }}
              bounds={bounds}
              position={{ x: this.state.progressPct * this.state.progressWidth, y: 0 }}
              onDrag={this.handleDrag}
              onStop={this.handleDragEnd}
              grid={[1, 0]}
              zIndex={100}
            >
              <div>
                <div className="handle" />
              </div>
            </Draggable>
          </div>
          <div
            className={cx({
              'volume-toggle': true,
              'icon-custom-volume-high': true,
            })}
            onClick={this.toggleVolume}
          />
          {volumeComponent}
        </div>
      </div>
    );
  }
}

const TimeDisplay = (props) => {
  let time;
  if (props.seconds > 0) {
    const seconds = Utils.addDigit(Math.floor(props.seconds % 60));
    const minutes = Utils.addDigit(Math.floor(props.seconds / 60));
    time = `${minutes}:${seconds}`;
  }

  const progress = `${_.clamp(props.progressPct * 100, 0, 92)}%`;
  return (
    <div className="current-time" style={{ 'left': progress }}>
      {time}
    </div>
  );
};

TimeDisplay.propTypes = {
  /** Seconds elapsed */
  seconds: PropTypes.number,
  /** Progress percentage of file */
  progressPct: PropTypes.number,
};

export default Player;
