import { camelCase, snakeCase } from 'lodash';


export function camelizeKeys(object, passParams = []) {
  if (!object || object instanceof Date || typeof object !== 'object') {
    return object;
  }

  if (Array.isArray(object)) {
    return object.map(element => camelizeKeys(element));
  }

  return Object.keys(object).reduce((result, fieldName) => {
    
    const camelKey = camelCase(fieldName);
    if (camelKey && camelKey in result) {
      throw new Error(
        `Camelcased key ${camelKey} would overwrite existing key of the given object`,
      );
    }

    if (passParams.includes(fieldName) || passParams.includes(camelKey)) {
      return {
        ...result,
        [fieldName]: object[fieldName],
      }
    } 

    return {
      ...result,
      [camelKey]: camelizeKeys(object[fieldName]),
    };
  }, {});
}

export function snakelizeKeys(object, passParams = []) {
  if (
    !object ||
    object instanceof Date ||
    object instanceof FormData ||
    typeof object !== 'object'
  ) {
    return object;
  }

  if (Array.isArray(object)) {
    return object.map(element => snakelizeKeys(element));
  }

  return Object.keys(object).reduce((result, fieldName) => {
    const snakeKey = snakeCase(fieldName);
    if (snakeKey && snakeKey in result) {
      throw new Error(
        `Snakecased key ${snakeKey} would overwrite existing key of the given object`,
      );
    }

    if (passParams.includes(fieldName) || passParams.includes(snakeKey)) {
      return {
        ...result,
        [fieldName]: object[fieldName],
      }
    } 

    return {
      ...result,
      [snakeKey]: snakelizeKeys(object[fieldName]),
    };
  }, {});
}