import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import cx from 'classnames';

import InlinveSvgFactory from 'components/inline_svg';
const Arrow = InlinveSvgFactory('arrow.svg');

class SearchablePagination extends React.Component {
  static propTypes = {
    /** The maximum number of results per page */
    perPage: PropTypes.number,
    /** Parameters of the search */
    searchOn: PropTypes.array,
    /** Additional content that renders with the search bar */
    children: PropTypes.node,
  };

  constructor(props) {
    super(props);
    this.state = {
      items: this.props.children,
      filterText: '',
      page: 0,
      perPage: this.props.perPage || 10,
      totalPages: this.getTotalPages(),
    };
  }

  componentWillMount() {
    this.pageLeft = this.paginate.bind(this, -1);
    this.pageRight = this.paginate.bind(this, 1);
  }

  componentWillReceiveProps(newProps) {
    const items = this.filterItems(newProps.searchOn, this.state.filterText);
    this.setState(
      {
        page: 0,
        items,
        totalPages: this.getTotalPages(items),
      }
    );
  }

  getTotalPages = (items) => {
    if (!items) items = this.props.children;
    if (items.length) {
      return Math.ceil(items.length / (this.props.perPage || 10));
    }
    return 1;
  };

  setFilterText = (e) => {
    const items = this.filterItems(this.props.searchOn, e.target.value);

    this.setState({
      page: 0,
      filterText: e.target.value,
      items,
      totalPages: this.getTotalPages(items),
    });
  };

  filterItems = (items, filterText) => {
    let filteredItems = [];

    if (filterText === undefined) {
      filterText = this.state.filterText;
    }

    if (filterText !== '') {
      _.each(items, (item, index) => {
        if (item.toLowerCase().indexOf(filterText.toLowerCase()) >= 0) {
          filteredItems.push(this.props.children[index]);
        }
      });
    } else {
      filteredItems = this.props.children;
    }

    return filteredItems;
  };

  paginate = (direction) => {
    const newPage = this.state.page + direction;

    if (newPage >= 0 && newPage < this.getTotalPages()) {
      this.setState({ page: this.state.page + direction });
    }
  };


  render() {
    const start = this.state.page * this.state.perPage;
    const children = this.state.items.slice(start, start + this.state.perPage);

    return (
      <div className="dropzone-container">
        <div>
          <div className="page-controls">
            <input
              type="search"
              placeholder="Search"
              onChange={this.setFilterText}
              value={this.state.filterText}
            />
            <div className="pull-right">
              <Arrow
                className={cx({ 'arrow-left': true, hidden: this.state.page === 0 })}
                onClick={this.pageLeft}
              />
              {this.state.page + 1} / {this.state.totalPages}
              <Arrow
                className={cx({ 'arrow-right': true, hidden: this.state.page + 1 === this.state.totalPages })}
                onClick={this.pageRight}
              />
            </div>
          </div>
          {children}
        </div>
      </div>
    );
  }
}

export default SearchablePagination;
