import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';
import FlipCard from 'react-flipcard-2';

class LandingTile extends Component {
  static propTypes = {
    /** The unique ID of the tile */
    tileId: PropTypes.string.isRequired,
    /** The link that clicking the tile leads to */
    tileLink: PropTypes.string.isRequired,
    /** The image displayed on the tile */
    tileImg: PropTypes.string.isRequired,
    /** The title of the tile */
    tileTitle: PropTypes.string.isRequired,
    /** The content of the landing tile */
    children: PropTypes.element.isRequired,
    push: PropTypes.func,
  };

  onClick = (e) => {
    if (e.target.nodeName !== 'A') {
      const { push, tileLink } = this.props;
      push(tileLink);
    }
  };

  render() {
    return (
      <span id={this.props.tileId} onClick={this.onClick}>
        <FlipCard>
          <div>
            <img src={this.props.tileImg} alt={this.props.tileTitle} />
            <h2>{this.props.tileTitle}</h2>
          </div>
          <div>
            <h2>{this.props.tileTitle}</h2>
            <div className="landing-content">
              {this.props.children}
            </div>
          </div>
        </FlipCard>
      </span>
    );
  }
}

export default connect(
  null,
  { push }
)(LandingTile);
