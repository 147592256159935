import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import cx from 'classnames';

import Button from 'components/button';

class Pagination extends Component {
  static propTypes = {
    /** The initial page that is being displayed */
    initialPage: PropTypes.number,
    /** Class name for the iterator arrows */
    className: PropTypes.string,
    /** Handler for updating a page, takes the new page number as an argument */
    handlePageUpdate: PropTypes.func,
    /** The maximum number of pages for the question set */
    maxPages: PropTypes.number,
  };

  constructor(props) {
    super(props);
    this.state = {
      currentPage: this.props.initialPage,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      currentPage: nextProps.initialPage,
    });
  }

  handlePageUpdate = () => {
    this.props.handlePageUpdate(this.state.currentPage);
  }

  incrementPage = () => {
    if (this.state.currentPage < this.props.maxPages - 1) {
      this.setState({
        currentPage: this.state.currentPage + 1,
      }, this.handlePageUpdate);
    }
  }

  decrementPage = () => {
    if (this.state.currentPage > 0) {
      this.setState({
        currentPage: this.state.currentPage - 1,
      }, this.handlePageUpdate);
    }
  }

  render() {
    const { currentPage } = this.state;
    const { className, initialPage, maxPages } = this.props;

    const leftArrowClass = cx({
      'btn-transparent': true,
      'icon-custom-chevron-left': currentPage !== 0,
      'icon-custom-chevron-left-hidden': currentPage === 0,
    });

    const rightArrowClass = cx({
      'btn-transparent': true,
      'icon-custom-chevron-right': currentPage !== maxPages - 1,
      'icon-custom-chevron-right-hidden': currentPage === maxPages - 1,
    });

    return (
      <div>
        <div className={className}>
          <Button onClick={this.decrementPage} className={leftArrowClass} />
          <span> {initialPage + 1} / {maxPages} </span>
          <Button onClick={this.incrementPage} className={rightArrowClass} />
        </div>
      </div>
    );
  }
}

export default Pagination;
