import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import * as customDashboardActionCreators from 'actions/custom_dashboard_actions';

export function mapStateToProps(state) {
  return {
    custom_dashboard: state.custom_dashboard,
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    push: bindActionCreators(push, dispatch),
    customDashboardActions: bindActionCreators(customDashboardActionCreators, dispatch),
  };
}

