import React, { useState, useEffect, useCallback } from "react";
import ToolTip from "components/tooltip";
import CustomTable from "components/custom_table/";
import { getUserActivityTable } from "../dataProvider/data-provider";

const navControl = ["Recent", "Problematic"];

const urls = {
  recent: `/nextos/reports/quality/call-log/recent`,
  problematic: `/nextos/reports/quality/call-log/problematic`
};

const QualityUserTable = ({ apiFilters, timeRange }) => {
  const [state, setState] = useState({
    activeNavPills: "recent",
    isLoadingTableData: false,
    table: {}
  });

  const handleNavPills = menu => {
    setState(prevState => ({
      ...prevState,
      activeNavPills: menu.toLocaleLowerCase(),
      isLoadingTableData: true
    }));
    getTableData(menu.toLocaleLowerCase());
  };

  const getTableData = useCallback(
    async activeTab => {
      const tableData = await getUserActivityTable({
        url: urls[activeTab],
        filters: apiFilters,
        timeRange
      });
      setState(prevState => ({
        ...prevState,
        activeNavPills: activeTab,
        table: tableData.table,
        isLoadingTableData: false
      }));
    },
    [timeRange, apiFilters]
  );

  useEffect(() => {
    getTableData(state.activeNavPills);
  }, [apiFilters, timeRange]);
  useEffect(() => {
    getTableData(state.activeNavPills);
  }, []);

  return (
    <div className="connected-calls-container">
      <div className="connected-calls-header">
        <div className="title-content">
          <h4>Connected Calls</h4>
        </div>
      </div>
      <div className="log-list">
        <div className="nav-control">
          <ul>
            {navControl.map((menu, index) => (
              <li
                key={index}
                className={
                  state.activeNavPills === menu.toLocaleLowerCase()
                    ? "active"
                    : ""
                }
                onClick={() => handleNavPills(menu)}
              >
                {menu}{" "}
                {menu === "Recent" && (
                  <ToolTip tipContent="Shows the last hour of calls with quality ratings." />
                )}
              </li>
            ))}
          </ul>
        </div>
        <CustomTable 
          table={state.table}
          isLoading={state.isLoadingTableData}
        />
      </div>
    </div>
  );
};

export default QualityUserTable;
