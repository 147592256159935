import React from 'react';
import PropTypes from 'prop-types';

import BasicChartControls from 'components/charts/basic_chart_controls';

const ChartHeader = (props) => (
  <div data-testid="chart-header" className="chart-header">
    {props.title}
    <BasicChartControls {...props} />
  </div>
);

ChartHeader.propTypes = {
  /** The title of the chart in the header */
  title: PropTypes.string,
  /** The kind of unit that the chart is using */
  unit: PropTypes.string,
  /** The potential units that the chart could use */
  units: PropTypes.array,
  /** Whether or not the Units button is displayed */
  hideUnits: PropTypes.bool,
  /** The charts that could potentially be displayed */
  charts: PropTypes.array,
  /** Whether or not the data is displayed in real time*/
  realTime: PropTypes.bool,
  /** A handler for a change in the unit */
  onUnitChange: PropTypes.func,
  /** A handler for an edit to the chart */
  onChartChange: PropTypes.func,
  /** A handler for when the chart popout button is clicked */
  onPopout: PropTypes.func,
  /** Whether or not the popout button is displayed */
  hidePopout: PropTypes.bool,
};

export default ChartHeader;
