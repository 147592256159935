import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Popover from 'react-popover';
import cx from 'classnames';
import type from 'type-of';

import Button from 'components/button';

/**
 * A button component that opens a popover when clicked.
 */
class ButtonPopover extends Component {
  static propTypes = {
    /** The content of the popover */
    popoverBody: PropTypes.node.isRequired,
    /** The function that is called when clicking outside of the popover */
    onOuterAction: PropTypes.func,
    /** The text label for the button */
    label: PropTypes.string,
    /** The class of the icon */
    iconClass: PropTypes.string,
    /** The class of the label */
    labelClass: PropTypes.string,
    /** The class of the popover */
    popoverClass: PropTypes.string,
    /** The class of the button */
    buttonClass: PropTypes.string,
    /** Allow isOpen to be overridden */
    isOpen: PropTypes.bool,
    /** Click handler for the button */
    onClick: PropTypes.func,
    /** ARIA label for the underlying button */
    ariaLabel: PropTypes.string,
  }

  static defaultProps = {
    popoverBody: <p>{'No popover body defined.'}</p>,
    ariaLabel: 'Button popover',
    buttonClass: 'btn-transparent',
  }

  constructor(props) {
    super(props);

    this.state = {
      isOpen: props.isOpen || false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if ((type(nextProps.isOpen) !== 'undefined') && nextProps.isOpen !== this.state.isOpen) {
      this.setState({
        isOpen: nextProps.isOpen,
      });
    }
  }

  togglePopover = () => {
    if (this.props.onClick) {
      this.props.onClick();
    } else {
      this.setState({ isOpen: !this.state.isOpen });
    }
  }

  closePopover = () => {
    this.setState({ isOpen: false });
  }

  render() {
    const {
      popoverBody,
      onOuterAction,
      label,
      iconClass,
      labelClass,
      popoverClass,
      buttonClass,
      ariaLabel,
    } = this.props;
    const { isOpen } = this.state;
    const iconClasses = cx({
      'button-popover-icon': true,
      [iconClass]: iconClass,
    });
    let popoverClasses = 'button-popover';
    if (popoverClass) {
      popoverClasses += ` ${popoverClass}`;
    }
    const buttonClasses = cx({
      [buttonClass]: buttonClass,
    });
    const outerAction = onOuterAction || this.closePopover;

    return (
      <div className="button-popover-container">
        <Popover
          className={popoverClasses}
          body={popoverBody}
          preferPlace="below"
          isOpen={isOpen}
          onOuterAction={outerAction}
        >
          <Button
            className={buttonClasses}
            value={label}
            onClick={this.togglePopover}
            aria-label={ariaLabel}
          >
            {label && (
              <span className={labelClass}>{label}</span>
            )}
            <span className={iconClasses} />
          </Button>
        </Popover>
      </div>
    );
  }
}

export default ButtonPopover;
