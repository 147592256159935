import React, { memo } from 'react';
import {
  Button,
  ModalDialog,
  ModalSeparator,
} from '@nextiva/next-ui';

import { DefaultSpinner, DefaultSpinnerTypes } from 'components';

import Info from '../Info';
import Header from '../Header';
import Additional from '../Additional';
import PlayerContainer from '../../containers/PlayerContainer';

import Styles from './CallRecording.module.scss';


const CallRecording = memo((props) => {
  const {
    date,
    note,
    email,
    title,
    clipInfo,
    duration,
    streamUrl,
    isLoading,
    emailErrors,
    phoneNumber,
    errorMessage,
    directionIcon,
    textButtonSave,
    externalTrackingId,
    isLoadingSaveButton,
    onChangeTab,
    onSaveModal,
    onBlurEmail,
    onCloseModal,
    onClipChange,
    onChangeEmail,
    onChangeTextArea,
    changeAnswerQuestions,
  } = props;

  return (
    <ModalDialog
      shouldCloseOnOverlayClick
      className={Styles.modal}
      overlayClassName={Styles.overlayModal}
      onClose={onCloseModal}
    >
      <Header
        title={title}
        direction={directionIcon}
        onCloseModal={onCloseModal}
      />

      <ModalSeparator />
      <Info date={date} phoneNumber={phoneNumber} />

      <div className={Styles.player}>
        {isLoading
          ? (
            <DefaultSpinner />
          ) : (
            <PlayerContainer
              clipInfo={clipInfo}
              finishTime={duration}
              streamUrl={streamUrl}
              errorMessage={errorMessage}
              onClipChange={onClipChange}
            />
          )}
      </div>

      <form onSubmit={onSaveModal}>
        <Additional
          note={note}
          email={email}
          emailErrors={emailErrors}
          externalTrackingId={externalTrackingId}
          onChangeTab={onChangeTab}
          onBlurEmail={onBlurEmail}
          onChangeEmail={onChangeEmail}
          onChangeTextArea={onChangeTextArea}
          changeAnswerQuestions={changeAnswerQuestions}
        />

        <div className={Styles.footer}>
          <Button onClick={onCloseModal} className={Styles.cancel}>
            Cancel
          </Button>
          
          <Button
            type="submit"
            kind="success"
            className={Styles.save}
            disabled={isLoadingSaveButton}
          >
            {isLoadingSaveButton && (
              <DefaultSpinner kind={DefaultSpinnerTypes.SMALL} />
            )}
            {textButtonSave}
          </Button>
        </div>
      </form>
    </ModalDialog>
  );
});


CallRecording.displayName = 'CallRecording';


export default CallRecording;