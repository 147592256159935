import update from 'immutability-helper';
import _ from 'lodash';
import Constants from 'constants.js';
import appDefaults from './app_defaults';

function controls(state = {}, action) {
  if (action.type && action.type.indexOf('CONTROLS') === 0) {
    return update(state, {
      $merge: { [action.section]: controlsPage(state[action.section], action) },
      currentSection: { $set: action.section },
    });
  }
  return state;
}

function controlsPage(state, action) {
  if (typeof state === 'undefined') {
    state = appDefaults.controls[action.section] || {};
  }

  switch (action.type) {
    case Constants.CONTROLS_SET_TIME_RANGE:
    case Constants.CONTROLS_SET_TIME_RANGES:
    case Constants.CONTROLS_SET_CALL_FILTER:
    case Constants.CONTROLS_SET_REAL_TIME:
    case Constants.CONTROLS_SET_BOARD_TYPE:
    case Constants.CONTROLS_SET_BOARD_THEME:
    case Constants.CONTROLS_SET_USER_COUNT:
    case Constants.CONTROLS_SET_TOTAL_USERS:
    case Constants.CONTROLS_SET_PERIOD:
    case Constants.CONTROLS_SET_CALL_TYPE:
    case Constants.CONTROLS_SET_MINUTE_BUCKETS:
    case Constants.CONTROLS_SET_INACTIVE:
    case Constants.CONTROLS_SET_FILTER_OPTIONS:
    case Constants.CONTROLS_SET_GROUPING:
      return update(state, {
        [action.field]: { $set: action.value },
      });

    case Constants.CONTROLS_SET_FILTER_TYPE: {
      let newState;
      let currentIndex;
      if (action.section === 'gamification') {
        currentIndex = _.findIndex(state.callTypeOptions, { id: state.callType.id });
        if (currentIndex === -1) {
          currentIndex = 0;
        }
      }
      if (action.filterType.id === 'call_groups' || action.filterType.teamType === 'call_groups') {
        newState = update(state, {
          callTypeOptions: { $set: state.callGroupCallTypeOptions },
        });
      } else {
        newState = update(state, {
          callTypeOptions: { $set: state.originalCallTypeOptions },
        });
      }

      if (action.section === 'wallboards') {
        newState = update(newState, {
          callTypes: { $set: newState.callTypeOptions.slice(0, 8) },
        });
      } else if (action.section === 'dashboard') {
        newState = update(newState, {
          callTypes: { $set: newState.callTypeOptions.slice(0, 5) },
        });
      } else if (action.section === 'gamification') {
        newState = update(newState, {
          callType: { $set: newState.callTypeOptions[currentIndex] },
        });
      }

      return update(newState, {
        filters: { $set: action.filterOptions },
        filterOptions: { $set: action.filterOptions },
        filterType: { $set: action.filterType },
        apiFilters: {
          [action.filterType.id]: {
            $set: _.map(action.filterOptions, 'id'),
          },
        },
      });
    }
    case Constants.CONTROLS_SET_CALL_TYPES:
      return update(state, {
        callType: { $set: null },
        callTypes: { $set: action.value },
      });

    case Constants.CONTROLS_LOAD_STATE:
      return update(state, { $merge: action.state });

    case Constants.CONTROLS_SET_FILTERS: {
      const filterType = state.filterType;

      return update(state, {
        filters: { $set: action.filters },
        apiFilters: {
          [filterType.id]: { $set: _.map(action.filters, 'id') },
        },
      });
    }

    default:
      return state;
  }
}

export default controls;
