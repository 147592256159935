import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { snakeCase, omit } from 'lodash';
import vex from 'lib/vex';
import { IntervalRunner } from '@nextiva/analytics-ui';
import { camelizeKeys } from 'common';

import { ReportCallLog } from '../../components';
import { OptionsSource, SortOrderMap } from '../../constants';
import { getReportCallLog, normalizeCustomReport, sendReportCallLogToEmail } from '../../dataProvider';


class ReportCallLogContainer extends PureComponent {
  static propTypes = {
    /** external reports can not call the call-log endpoint and must pass data
        from the public report endpoint instead.  if this is populated from that
        set externalReportData to the data returned from that endpoint and do not
        call the passed url.
    */
    externalReportData: PropTypes.object,
    /** The URL for the table data, in case it's not passed */
    url: PropTypes.string,
    /** The selected filter options for the detail table */
    filters: PropTypes.object,
    /** The data fields that are expected to be returned: (start_time,
    user_id, calling_number, called_number, talk_duration_seconds, etc...) */
    selects: PropTypes.array,
    /** The time range being represented by the table */
    timeRange: PropTypes.object,
    /** Whether or not the data is in real time */
    realTime: PropTypes.bool,
    /** is this an sms_log or a call_log */
    isSMS: PropTypes.bool,
  };


  static defaultProps = {
    filterOptions: OptionsSource,
  };


  state = {
    sortData: {},
    pagination: {
      limit: 25,
      offset: 0,
    },
    searchQuery: '',
    isLoading: false,
    selectedSourceType: OptionsSource[0],
  }


  componentDidMount() {
    this.getTable();
  }


  componentDidUpdate(prevProps) {
    const {
      realTime: prevRealTime,
      timeRange: prevTimeRange,
    } = prevProps;
    const { realTime, timeRange } = this.props;

    if (
      prevRealTime !== realTime ||
      prevTimeRange !== timeRange
    ) {
      this.getTable();
    }
  }


  getTable = async () => {
    const {
      externalReportData,
      url,
      labels,
      filters,
      selects,
      realTime,
      timeRange,
    } = this.props;

    const {
      sortData,
      pagination,
      searchQuery,
      selectedSourceType,
    } = this.state;

    this.setState({
      isError: false,
      isLoading: true,
    });

    if(externalReportData) {
      this.setState({
        ...normalizeCustomReport(camelizeKeys(externalReportData)),
        isLoading: false,
      });

      return;
    }

    const data = await getReportCallLog({
      ...pagination,
      url,
      labels,
      selects,
      searchQuery,
      timeRange: realTime
        ? omit(timeRange, 'end')
        : timeRange,
      sort: {
        order: SortOrderMap[sortData.order],
        header: snakeCase(sortData.key),
      },
      filters: {
        ...filters,
        [selectedSourceType.value]: true,
      },
    });


    if (data.isCancel) {
      return;
    }

    if (data.errors) {
      this.setState({
        isError: true,
        isLoading: false,
      });
      return;
    }

    this.setState({
      ...data,
      isLoading: false,
    });
  };


  onSortChange = (sortData) => {
    const { pagination } = this.state;
    this.setState({
      sortData,
      pagination: {
        offset: 0,
        limit: pagination.limit,
      },
    }, this.getTable);
  };


  onClickCallRecording = ({ target }) => {
    const { rows } = this.state.table;
    const { index } = target.dataset;

    this.setState({
      callRecordingInfo: rows[index],
    });
  };


  onCloseModal = () => {
    this.setState({
      callRecordingInfo: null,
    });
  };


  onPageChange = ({ selected }) => {
    const { pagination } = this.state;

    this.setState({
      pagination: {
        limit: pagination.limit,
        offset: selected * pagination.limit,
      },
    }, this.getTable);
  };


  onSearchChange = (searchQuery) => {
    const { pagination } = this.state;

    this.setState({
      searchQuery,
      pagination: {
        offset: 0,
        limit: pagination.limit,
      },
    }, this.getTable);
  };


  onLimitChange = (limit) => {
    this.setState({
      pagination: {
        limit,
        offset: 0,
      },
    }, this.getTable);
  };


  onChangeSourceType = (selectedSourceType) => {
    const { pagination } = this.state;
    this.setState({
      pagination: {
        offset: 0,
        limit: pagination.limit,
      },
      selectedSourceType,
    }, this.getTable);
  };


  onClickExport = async (toEmail) => {
    const {
      filters,
      selects,
      timeRange,
    } = this.props;
    const {
      sortData,
      searchQuery,
      selectedSourceType,
    } = this.state;

    const data = await sendReportCallLogToEmail({
      selects,
      timeRange,
      searchQuery,
      sort: {
        order: SortOrderMap[sortData.order],
        header: snakeCase(sortData.key),
      },
      filters: {
        ...filters,
        [selectedSourceType.value]: true,
      },
      toEmail,
    });

    if (data.errors) {
      vex.error([], null, 'Error sending email');
    } else {
      vex.success('Your email has been sent successfully.');
    }
  };


  render() {
    const {
      table,
      sortData,
      isLoading,
      pagination,
      searchQuery,
      callRecordingInfo,
      selectedSourceType,
    } = this.state;

    const {
      realTime,
      filterOptions,
      errorClassName,
      externalReportData,
      isSMS,
    } = this.props;

    return (
      <IntervalRunner isActive={realTime} action={this.getTable}>
        <ReportCallLog
          table={table}
          sortData={sortData}
          search={searchQuery}
          isLoading={isLoading}
          filterOptions={filterOptions}
          sourceType={selectedSourceType}
          pagination={pagination}
          errorClassName={errorClassName}
          callRecordingInfo={callRecordingInfo}
          onPageChange={this.onPageChange}
          onLimitChange={this.onLimitChange}
          onSearchChange={this.onSearchChange}
          onSortChange={this.onSortChange}
          onClickExport={this.onClickExport}
          onChangeSourceType={this.onChangeSourceType}
          onCloseModal={this.onCloseModal}
          onClickCallRecording={this.onClickCallRecording}
          withoutPagination={externalReportData ? true : false}
          withoutSearch={externalReportData || isSMS ? true : false}
          withoutAddButton={externalReportData || isSMS ? true : false}
          withoutPerPage={externalReportData ? true : false}
        />
      </IntervalRunner>
    );
  }
}


export default ReportCallLogContainer;
