import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { handleChartChange, handlePopout } from 'utils/chart_utils';
import ChartAsync from 'components/charts/chart_async';
import ChartDropdown from 'components/icon_dropdown';
import InlineSvgFactory from 'components/inline_svg';

const Popout = InlineSvgFactory('popout.svg');

const mapStateToProps = (state) => {
  const currentSection = state.controls.currentSection;
  const controlsState = state.controls[currentSection];
  return {
    controlsState,
  };
};

class AnalyticsChart extends Component {
  static propTypes = {
    /** The Url of the call log */
    callLogUrl: PropTypes.string,
    /** An array of chart objects to be displayed on the report page */
    charts: PropTypes.array,
    /** An array of objects, the options in the detail table dropdown of the
    popout. Each object has an id and a label */
    detailPeriodOptions: PropTypes.array,
    /** URL for the data to populate the popout table for a chart */
    detailUrl: PropTypes.string,
    /** An array of the items in the legend */
    enabledLegendItems: PropTypes.array,
    /** An object with labels for corresponding metrics. Has multiple
    fields which are strings ( average duration, outbound, outbound_duration,
    inbound, inbound_duration, total ) */
    labels: PropTypes.object,
    /** The position of the legend, default is 'top' */
    legendPosition: PropTypes.string,
    /** The api filters */
    filters: PropTypes.object,
    /** Whether or not the chart is in real time */
    realTime: PropTypes.bool.isRequired,
    /** An array of objects. Each object has a label (Total, Inbound, Outbound)
    and a metric (total, inbound, outbound) */
    selects: PropTypes.array,
    /** The title of the chart */
    title: PropTypes.string,
    /** The time range that the chart will represent */
    timeRange: PropTypes.object,
    /** An array of multiple time ranges for charts with multiple periods */
    timeRanges: PropTypes.array,
    /** The URL for the chart data */
    url: PropTypes.string,
    /** The kind of unit that the chart is using */
    unit: PropTypes.string,
    /** The potential units that the chart could use */
    units: PropTypes.array,
  };

  state = {
    chart: this.props.charts[0],
  };

  handleChartChange = handleChartChange.bind(this)

  handlePopout = handlePopout.bind(this)

  render() {
    return (
      <div className="chart-container">
        <div className="chart-title-container">
          <div className="chart-title">
            <div className="chart-title-bar" />
            <div className="chart-title-contents">
              {this.props.title}
              <ChartDropdown
                defaultValue={this.state.chart}
                onEdit={this.handleChartChange}
                options={this.props.charts}
                ariaLabel="Change chart type"
              />
              <Popout className="chart-title-expand" onClick={this.handlePopout} />
            </div>
            <div className="chart-title-bar" />
          </div>
        </div>
        <ChartAsync
          ref="chartWrapper"
          realTime={this.props.realTime}
          chart={this.state.chart}
          url={this.props.url}
          unit={this.props.units[0]}
          units={this.props.units}
          title={this.props.title}
          filters={this.props.filters}
          timeRange={this.props.timeRange}
          timeRanges={this.props.timeRanges}
          selects={this.props.selects}
          labels={this.props.labels}
          enabledLegendItems={this.props.enabledLegendItems}
          legendPosition={this.props.legendPosition}
        />
      </div>
    );
  }
}

export default connect(mapStateToProps)(AnalyticsChart);
