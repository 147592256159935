import React, { Component } from 'react';
import ErrorPlaceholder from 'components/ErrorPlaceholder';


class ErrorBoundary extends Component {
  static getDerivedStateFromProps(props, prevState) {
    if (props.pathname !== prevState.url) {
      return {
        url: props.pathname,
        hasError: false,
      };
    }
    return null;
  }

  state = {
    hasError: false,
    url: this.props.pathname,
  };

  componentDidCatch() {
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      return <ErrorPlaceholder />;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
