import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import _ from 'lodash';
import type from 'type-of';
import { connect } from 'react-redux';

import AppData from 'app_data';
import { LeaderboardTable } from 'modules/monitoring/gamification/leaderboard_table_original';

import { ReactComponent as PopoutImg } from 'img/popout.svg';

const mapStateToProps = (state) => ({
  controlsState: state.controls.gamification || {},
});

class LeaderboardTableContainer extends Component {
  static propTypes = {
    displayType: PropTypes.string,
    callType: PropTypes.shape({
      label: PropTypes.string,
    }),
    realTime: PropTypes.bool,
    filterType: PropTypes.string,
    filters: PropTypes.array,
    timeRange: PropTypes.object,
    nBuckets: PropTypes.number,
    grouping: PropTypes.bool,
    controlsState: PropTypes.object,
  };

  state = {
    displayType: this.props.displayType || 'calls',
  };

  handleModeChange = (displayType) => {
    this.setState({
      displayType,
    });
  };

  handlePopout = () => {
    const path = '/popout/gamification-leaderboard-table';
    // save state
    const params = {};
    params.componentState = _.assign({}, this.props, this.state);
    params.componentState.loading = false;
    params.componentState.data = null;

    params.controlsState = _.assign({}, this.props.controlsState);

    // Save params to server, redirect with returned hash on data.id
    AppData.savePopoutParams(params).then((response) => {
      const { data } = response;
      const route = `${process.env.REACT_APP_PUBLIC_PATH || ''}${path}/${data.id}`;
      const open = window.open(route);

      // ipad blocks popouts, redirect page
      if (open == null || type(open) === 'undefined') {
        window.location.href = route;
      }
    });
  };

  render() {
    return (
      <div className={'content-box monitoring-gamification'}>
        <div className="content-box-header">
          <ul className="labels">
            <li>{this.props.callType.label}</li>
          </ul>
          <ul className="controls">
            <li onClick={this.handlePopout}>
              <PopoutImg/>
            </li>
            <li
              className={cx({ 'bold': this.state.displayType === 'calls' })}
              onClick={this.handleModeChange.bind(this, 'calls')}
            >
              Calls
            </li>
            <li
              className={cx({ 'bold': this.state.displayType === 'seconds' })}
              onClick={this.handleModeChange.bind(this, 'seconds')}
            >
              Talk time
            </li>
          </ul>
        </div>
        <div className="content-box-content">
          <LeaderboardTable
            realTime={this.props.realTime}
            displayType={this.state.displayType}
            callType={this.props.callType}
            filterType={this.props.filterType}
            filters={this.props.filters}
            timeRange={this.props.timeRange}
            nBuckets={this.props.nBuckets}
            grouping={this.props.grouping}
          />
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(LeaderboardTableContainer);
