import React, { memo, useCallback } from 'react';
import cx from 'classnames';
import { List } from '@nextiva/analytics-ui';
import moment from 'moment';
import _ from 'lodash';
import fattable from 'lib/fattable';
import FileSaver from 'file-saver';
import Utils from 'utils/common_utils';

import {
  NoData,
  DefaultSpinner,
  ErrorPlaceholder,
} from 'components';
import CustomTable from './../../../../components/DataTable/DataTable'
import AdditionalButtons from './AdditionalButtons';
import { CallRecordingContainer } from 'modules/CallRecording';
// import { ColumnTypes } from '../../constants';

import './QualityCallsTable.scss';
import { ReactComponent as IconPhone } from 'img/phone.svg';
import { ReactComponent as Arrow } from 'img/arrow.svg';

const QualityCallsTable = memo((props) => {
  const {
    table,
    search,
    isLoading,
    sourceType,
    pagination,
    filterOptions,
    errorClassName,
    callRecordingInfo,
    onPageChange,
    onLimitChange,
    onSearchChange,
    onClickExport,
    onChangeSourceType,
    onCloseModal,
    withoutPagination = false,
    withoutSearch = false,
    withoutAddButton = false,
    withoutPerPage = false,
  } = props;

  if (!table) {
    return isLoading ? (
      <DefaultSpinner />
    ) : (
      <ErrorPlaceholder
        isAbsolutePosition
        className={errorClassName}
      />
    )
  } else if (Object.keys(table).length === 0) {
    return (
      <NoData isAbsolutePosition />
    );
  }
  const [columns, setColumns] = React.useState([])

  React.useEffect(()=>{
        if(table.headers){
            const _updatedColumns = table.headers.map(header =>{
                if(header.label === 'Internal'){
                    return {
                        id: 'expander',
                        width: header.width,
                        disableSortBy:!header.sortable,
                        Header: ({ getToggleAllRowsExpandedProps }) => (
                            <>
                             {header.label}
                            </>
                          ),
                        accessor:header.name,
                        Cell: ({ row }) =>
                            <span
                            {...row.getToggleRowExpandedProps({
                                style: {
                                    paddingLeft: `${row.depth * 2}rem`,
                                    position:'relative'
                                },
                            })}
                            >
                          {renderCell(header.name)(row.original) }
                          <Arrow className={cx(`arrow ${row.isExpanded ? 'arrow-down': 'arrow-up'}`)}/>
                        </span>
                      }
                }

                return{
                    width: header.width,
                    Header: header.label,
                    accessor: header.name,
                    disableSortBy:!header.sortable,
                    Cell:({row}) =>renderCell(header.name)(row.original)
                }
            })
            setColumns(_updatedColumns)
        }


  }, [table.headers])


  const formatConnected = connected => {
    switch (connected) {
      case 'Yes':
        return 'Connected';
      case 'Yes - Forwarded':
        return 'Forwarded';
      default:
        return 'Missed'
    }
  }

  const rowToJSON = (row) => {
    const last = row.length - 1;
    return row.map((inner, index) => {
      if (index !== last) {
        return `"${inner}"`;
      }
      return `"${inner}"\n`;
    });
  };

  const rowHeight = 40;
  const createTable = (outputToCSV = false, nextProps = null) => {
    const columnWidths = [];
    let dataSource;
    let headers;
    let rows;
    dataSource = table;
    rows = [];
    headers = [];
    headers = headers.concat(
      _.map(dataSource.headers, (row, index) => row.label)
    );

    _.forEach(dataSource.headers, (header, index) => {
      const tds = [];
      _.forEach(dataSource.rows, (row) => {
        const td = row[header.name];
        tds.push(td);
      });
      rows.push(tds);
    });

    if (outputToCSV) {
      const filename = `analytics-table-${moment().format('YMD')}.csv`;
      const updatedRows = rows.map(row => row.filter(i => typeof i !== 'object'));
      let rowsRearranged = [];
      updatedRows.forEach((a, i) => {
        a.forEach((c, ci) => {
          rowsRearranged[ci] = rowsRearranged[ci] ? [...rowsRearranged[ci], c] : [c]
        })
      })
      const rawData = [headers].concat(rowsRearranged);
      const csvData = _.map(rawData, (row) => rowToJSON(row));

      if (navigator.msSaveBlob) {
        let finalVal = '';
        for (let i = 0; i < csvData.length; i++) {
          const value = csvData[i];

          for (let j = 0; j < value.length; j++) {
            const innerValue = value[j] === null ? '' : value[j].toString();
            const result = innerValue.replace(/"/g, '"');
            if (j > 0) {
              finalVal += ',';
            }
            finalVal += result;
          }
        }

        return navigator.msSaveBlob(new Blob([finalVal], { type: 'text/csv;charset=utf-8;' }), filename);
      }

      const csvBlob = new Blob(csvData, { type: 'text/csv;charset=utf-8;' });
      FileSaver.saveAs(csvBlob, filename);

      return;
    }

    // computeHeaderWidth(headers);
    for (let i = 0; i < headers.length; i++) {
      columnWidths.push(200);
      // columnWidths.push(this.headerWidth);
    }

    const painter = new fattable.Painter();
    painter.fillCell = (cellDiv, data) => {
      cellDiv.textContent = data.content;
      cellDiv.title = data.content;
      if (data.columnId === 0) {
        cellDiv.className = 'first-column';
      } else {
        cellDiv.className = '';
      }
    };

    painter.fillHeader = (headerDiv, data) => {
      headerDiv.textContent = data.content;
      if (data.columnId === 0) {
        headerDiv.className = 'header-icon';
      } else {
        headerDiv.className = '';
      }
    };

    const tableData = new fattable.SyncTableModel();

    tableData.getCellSync = (i, j) => {
      if (rows[i]) {
        return {
          content: rows[i][j],
          rowId: i,
          columnId: j,
        };
      }
      return {
        content: null,
        rowId: i,
        columnId: j,
      };
    };

    tableData.getHeaderSync = (i) => ({
      content: headers[i],
      columnId: i,
    });

    fattable({
      container: `quality-calls-table`,
      model: tableData,
      nbRows: rows.length,
      rowHeight: rowHeight,
      headerHeight: rowHeight,
      painter,
      columnWidths,
    });
  };

  const renderCell = useCallback((columnLabel) => (cell) => {
    switch (columnLabel) {
      case 'connected':
        return <><IconPhone />{cell[columnLabel]}</>
      case 'talkDurationSeconds':
        return Utils.humanizeSeconds(cell[columnLabel]);
      case 'callingNumber':
        return (
          <span className="user-from">
            <span className='user-name'>{cell.userId}</span>
            <span>{cell[columnLabel]}</span>
          </span>
        )
      case 'calledNumber':
        return (
          <span>{cell[columnLabel]}</span>
        )
      case 'callScore':
        return (
          <span className={`call-score ${cell[columnLabel].toLowerCase()}`}>{cell[columnLabel]}</span>
        )
      default:
        return cell[columnLabel];
    }
  });

  return (
    <div
      data-testid="reportCallLog"
      className={cx('reportTable', {
        'data-empty': table.rows.length === 0
      })}
    >
      <span className="download-csv" onClick={() => createTable(true)} />
      <List
        search={search}
        isLoading={isLoading}
        className={'list'}
        pagination={pagination}
        onPageChange={onPageChange}
        onLimitChange={onLimitChange}
        onSearchChange={onSearchChange}
        withoutPagination={withoutPagination}
        withoutSearch={withoutSearch}
        withoutAddButton={withoutAddButton}
        withoutPerPage={withoutPerPage}
        buttonComponent={() => (
          <AdditionalButtons
            options={filterOptions}
            sourceType={sourceType}
            onClickExport={onClickExport}
            onChangeSourceType={onChangeSourceType}
            filterBtnFlag={false}
          />
        )}
      >
        <CustomTable className="quality-calls-table" data={table.rows} columns={columns} component={RenderRowSubComponent} />
      </List>

      {Boolean(callRecordingInfo) && (
        <CallRecordingContainer
          info={callRecordingInfo}
          onCloseModal={onCloseModal}
        />
      )}
    </div>
  );
});

const RenderRowSubComponent = (props) => {
  const tabGroups = ['Summary', 'Details'];
  const { original } = props.row;
  const [expandedView, setExpandedView] = React.useState('Summary');
  const toggleTab = (_tab) => setExpandedView(_tab)

  return (
    <div className="expanded-row-container">
      <div className="headers">
        <div className="tab-container">
          <div className="tab active">
            <label>{original.location}</label>
            <label>
              <span>
                {original.internal === 'Yes' ? 'Internal/' : 'External/'}
                <span className={original.callScore.toLowerCase()}>{original.callScore}</span>
              </span>
            </label>
          </div>
          <div className="tab">
            <label>{original.callingNumber}</label>
            <label>
              <span>
                <span className={original.callScore.toLowerCase()}>{original.callScore}</span>
              </span>
            </label>
          </div>
        </div>
        <div className="button-group">
          {tabGroups.map((tab, index) => (
            <div className={`button ${expandedView === tab ? 'active' : ''}`} onClick={() => toggleTab(tab)}>
              {tab}
            </div>
          ))}

        </div>
      </div>
      <div className={`expand-contents ${expandedView.toLowerCase()}`}>
        {expandedView === 'Summary' ? (<>
          <div className="content">
            <div className="graph">
              <label>Client</label>
              <div className={`circle ${original.callScore.toLowerCase()}`}>
                {original.avgMos}
              </div>
              <label>Server</label>
            </div>
            <div className="table">
              <ul>
                <li>PACKET LOSS AVG : {original.avgPacketLoss ? original.avgPacketLoss + '%' : '0'}</li>
                <li>PACKET LOSS MAX : {original.maxPacketLoss}</li>
                <li>JITTER AVG : {original.avgJitter}</li>
                <li>JITTER MAX : {original.maxJitter}</li>
              </ul>
            </div>
          </div>
          <div className="content"></div></>) : (
          <>
            <div className="details-content-container">
              <div className="details-content">
                <label>Source IP</label>
                <span>{original.srcIp}</span>
              </div>
              <div className="details-content">
                <label>Source MAC</label>
                <span>{original.srcMac}</span>
              </div>
              <div className="details-content">
                <label>Source Codecs</label>
                <span>{original.srcCodecs}</span>
              </div>
              <div className="details-content">
                <label>Destination IP</label>
                <span>{original.dstIp}</span>
              </div>
              <div className="details-content">
                <label>Destination MAC</label>
                <span>{original.dstMac}</span>
              </div>
              <div className="details-content">
                <label>Destination Codecs</label>
                <span>{original.dstCodecs}</span>
              </div>
              <div className="details-content">
                <label>Setup Time</label>
                <span>{original.setupTime}</span>
              </div>
              <div className="details-content">
                <label>Setup Delay</label>
                <span>{original.setupDelay}</span>
              </div>
              <div className="details-content">
                <label>User Agent</label>
                <span>{original.userAgent}</span>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

QualityCallsTable.displayName = 'QualityCallsTable';
export default QualityCallsTable;
