import _ from 'lodash';
function getRandomInt(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

const dummyData = {
  custom: {
    'select': [
      'total',
      'inbound',
      'outbound',
      'tollfree_inbound',
      'international_outbound',
    ],
    'filters': [
      {
        'type': 'users',
        'users': [
          'joseph.hanley@nextiva.com',
          'joseph.jacobi@nextiva.com',
          'jthoreck1@nextiva.com',
          'josh.carpenter@nextiva.com',
          'jlesavoyATA@nextiva.com',
          'jlesavoy@nextiva.com',
          'josh.noggle@nextiva.com',
          'josh.silba@nextiva.com',
          'joshs_ip7000@nextiva.com',
          'jkay11@nextiva.com',
          'julian.campos@nextiva.com',
          'jgallegos@nextiva.com',
          'jreynolds@nextiva.com',
          'justin.wilson@nextiva.com',
          'kflaherty@nextiva.com',
          'karan.shaw@nextiva.com',
          'kdewey@nextiva.com',
          'karl.simon@nextiva.com',
          'karol.dworakowski@nextiva.com',
          'kathleen.klein@nextiva.com',
          'keegan.kennedy@nextiva.com',
          'keith.netzley@nextiva.com',
          'keith.tomlin@nextiva.com',
          'kelly.boyle@nextiva.com',
          'twilliams@nextiva.com',
          'btbc01@nextiva.com',
          'pn480Tbd@nextiva.com',
          'cisco504_nexTest@nextiva.com',
          'tgornyLAoffice@nextiva.com',
          'thomas.sparks@nextiva.com',
          'tcady1@nextiva.com',
          'tiffany.turner@nextiva.com',
          'tim.oboyle@nextiva.com',
          'tim.roberts@nextiva.com',
          'timothy.valentine@nextiva.com',
          'tollringdemo@nextiva.com',
          'tom.michaels@nextiva.com',
          'tom.stgermain@nextiva.com',
          'tgorny@nextiva.com',
          't.nunn@nextiva.com',
          'tony.calvis@nextiva.com',
          'tony.cunningham@nextiva.com',
          'tconrad@nextiva.com',
          'TTraining@nextiva.com',
          'trainingemp@nextiva.com',
          'travis.hill@nextiva.com',
          'tyler.falen@nextiva.com',
          'tyler.simon@nextiva.com',
          'voiptrainee@nextiva.com',
          'vvx600_nexcctest@nextiva.com',
          'vijaya.maddukuri@nextiva.com',
          'VirtualVXML@nextiva.com',
          'viviana.arias@nextiva.com',
          'VoIPSpare@nextiva.com',
          'webcollabnextiva@nextiva.com',
          'MaterIVRWakeUPTest@nextiva.com',
          'wtuanaki@nextiva.com',
          'ymasjedi@nextiva.com',
          'ymasjedi_5126@nextiva.com',
          'ymasjedi_5030@nextiva.com',
          'ylustgarten@nextiva.com',
          'yonglee@nextiva.com',
          'zachary.kalko@nextiva.com',
          'zack.knibbe@nextiva.com',
          'zachary.taylor@nextiva.com',
          'zakmall@nextiva.com',
          'dingding@nextiva.com',
          'hlamTestUser3@nextiva.com',
          'jstewartmobile2@nextiva.com',
          'iceserver@nextiva.com',
          'justin.atchley@nextiva.com',
          'queueman2@nextiva.com',
          'melalt01@nextiva.com',
          'melalt02@nextiva.com',
          'melalt03@nextiva.com',
          'melalt04@nextiva.com',
          'melalt05@nextiva.com',
          'robert.lewis@nextiva.com',
          'salesfwrd2@nextiva.com',
          'temp12345@nextiva.com',
          'training_1@nextiva.com',
          'training2@nextiva.com',
          'stewartvvx410@nextiva.com',
        ],
      },
    ],
    'reportGroup': 'users',
    'report': 'calls',
    'format': 'aggregate',
    'breakdown': 'period_summary',
    'display': 'column',
    'dates': [
      {
        'from': '2015-08-01T07:00:00.000Z',
        'to': '2015-08-07T06:59:59.999Z',
      },
    ],
    'data': {
      'meta': {
        'date_format': null,
        'categoryField': 'category',
        'items': [
          'Total',
          'Inbound',
          'Outbound',
          'Toll free (I)',
          'International (O)',
        ],
      },
      'data': [
        {
          'category': 'Total',
          'Total': 8049,
        },
        {
          'category': 'Inbound',
          'Inbound': 7324,
        },
        {
          'category': 'Outbound',
          'Outbound': 9154,
        },
        {
          'category': 'Toll free (I)',
          'Toll free (I)': 9632,
        },
        {
          'category': 'International (O)',
          'International (O)': 4790,
        },
      ],
    },
    'title': 'Aggregate calls in August by call type',
  },
  scorecards: {
    abc: {
      table_class: 'monitoring-scorecards-table overview',
      headings: ['ABC INC.', 'Scottsdale', 'Los Angeles', 'New York', 'Total'],
      heading_icon: null,
      rows: [
        {
          label: 'Inbound Calls',
          data: [700, 760, 600, 500],
        },
        {
          label: 'Calls per day',
          data: [400, 401, 402, 403],
        },
        {
          label: 'Average talk time',
          data: [300, 249, 198, 147],
          suffix: '<sup>mins</sup>',
        },
        {
          label: 'Calls per user',
          data: ['10,000', '10,000', '10,000', '10,000'],
        },
        {
          label: 'Calls to voicemail',
          data: [14.2857143, 15.3846154, 16.6666667, 20],
        },
        {
          label: 'Total talk time',
          data: [665, 617.5, 570, 522.5],
          suffix: '<sup>mins</sup>',
        },
      ],
    },
  },
  comparisonPanelMetaItems: [
    { title: 'Inbound calls', type: 'Calls' },
    { title: 'Calls per day', type: 'Calls' },
    { title: 'Average talk time', type: 'Mins' },
    { title: 'Calls per user', type: 'Calls' },
    { title: 'Calls to voicemail', type: 'Calls' },
    { title: 'Total talk time', type: 'Mins' },
  ],
  periodOverPeriodPanelMetaItems: [
    { title: 'Inbound calls', type: 'Calls' },
    { title: 'Outbound calls', type: 'Calls' },
    { title: 'International calls', type: 'Calls' },
    { title: 'Toll free calls', type: 'Calls' },
    { title: 'Inbound talk time', type: 'Min' },
    { title: 'Outbound talk time', type: 'Min' },
    { title: 'International talk time', type: 'Min' },
    { title: 'Total talk time', type: 'Min' },
  ],
  trendPanelMetaItems: [
    { title: 'Inbound', type: 'Calls', id: 'inbound', label: 'Inbound' },
    { title: 'Total', type: 'Calls', id: 'total', label: 'Total' },
    { title: 'Average talk time', type: 'Min', id: 'average_duration', label: 'Avg. talk time' },
    { title: 'Average calls per user', type: 'Calls', id: 'avg_call_per_user', label: 'Avg. calls per user' },
    { title: 'Missed', type: 'Calls', id: 'missed', label: 'Missed' },
    { title: 'Total talk time', type: 'Min', id: 'total_duration', label: 'Total talk time' },
  ],
  trendMetaItems: [
    { title: 'Calls', call_prop: 'analysis_call' },
    { title: 'Talk Time', call_prop: 'analysis_talk_time' },
  ],
  comparisonMetaItems: [
    { title: 'Calls', call_prop: 'analysis_call' },
    { title: 'Talk Time', call_prop: 'analysis_talk_time' },
  ],
  periodOverPeriodMetaItems: [
    { title: 'Calls', call_prop: 'analysis_period_call' },
    { title: 'Talk Time', call_prop: 'analysis_period_talk_time' },
  ],
  customItemsFormat: [
    { key: 'aggregate', label: 'Aggregate' },
    { key: 'compare', label: 'Compare' },
    { key: 'trend', label: 'Trend' },
    { key: 'period', label: 'Period over period' },
  ],
  customItemsBreakdown: [
    { key: 'summary', label: 'Summary' },
    { key: 'dailysummary', label: 'Daily summary' },
    { key: 'dailyaverage', label: 'Daily average' },
    { key: 'hourlyaverage', label: 'Hourly average' },
  ],
  customItemsDisplay: [
    { key: 'Column', label: 'Column', icon: 'column.svg', iconClass: 'icon-right' },
    { key: 'Bar', label: 'Bar', icon: 'bar.svg', iconClass: 'icon-right' },
    { key: 'Line', label: 'Line', icon: 'line.svg', iconClass: 'icon-right line' },
    { key: 'Scatter', label: 'Scatter', icon: 'scatter.svg', iconClass: 'icon-right' },
    { key: 'Pie', label: 'Pie', icon: 'pie.svg', iconClass: 'icon-right' },
    { key: 'Table  ', label: 'Table', icon: 'table.svg', iconClass: 'icon-right' },
  ],
  customBuilderTypes: [
    {
      label: 'Users',
      items: [
        { key: 'user-calls', label: 'User calls' },
        { key: 'user-talk-time', label: 'User talk time' },
        { key: 'user-calls-talk-time', label: 'User calls + talk time' },
      ],
    },
    {
      label: 'Call groups',
      items: [
        { key: 'call-group-calls', label: 'Call group calls' },
        { key: 'call-group-talk-time', label: 'Call group calls talk time' },
        { key: 'call-group-calls-talk-time', label: 'Call group calls + talk time' },
        { key: 'call-group-calls-with-user', label: 'Call group calls with user' },
        { key: 'call-group-calls-talk-time-with-users', label: 'Call group calls + talk time with users' },
      ],
    },
    {
      label: 'Locations',
      items: [
        { key: 'location-calls', label: 'Location calls' },
        { key: 'location-talk-time', label: 'Location talk time' },
        { key: 'location-calls-talk-time', label: 'Location calls + talk time' },
        { key: 'location-calls-with-user', label: 'Location calls with user' },
        { key: 'location-calls-talk-time-with-users', label: 'Location calls + talk time with users' },
      ],
    },
    {
      label: 'Auto attendant',
      items: [
        { key: 'auto-attendent-route', label: 'Auto attendant route' },
        { key: 'auto-attendent-route-with-users', label: 'Auto attendant route with users' },
        { key: 'auto-attendent-calls', label: 'Auto attendant calls' },
        { key: 'auto-attendent-talk-time', label: 'Auto attendant talk time' },
        { key: 'auto-attendent-calls-talk-time', label: 'Auto attendant calls + talk time' },
        { key: 'auto-attendent-calls-with-users', label: 'Auto attendant calls with users' },
        { key: 'auto-attendent-talk-time-with-users', label: 'Auto attendant talk time with users' },
        { key: 'auto-attendent-calls-talk-time-with-users', label: 'Auto attendant calls + talk time with users' },
      ],
    },
    {
      label: 'Phone numbers',
      items: [
        { key: 'phone-number-calls', label: 'Phone number calls' },
        { key: 'phone-number-talk-time', label: 'Phone number talk time' },
        { key: 'phone-number-calls-talk-time', label: 'Phone number calls + talk time' },
        { key: 'phone-number-calls-with-users', label: 'Phone number calls with users' },
        { key: 'phone-number-talk-time-with-users', label: 'Phone number talk time with users' },
        { key: 'phone-number-calls-talk-time-with-users', label: 'Phone number calls + talk time with user' },
      ],
    },
    {
      label: 'Area',
      items: [
        { key: 'calls-by-area', label: 'Calls by area' },
        { key: 'talk-time-by-area', label: 'Talk time by area' },
        { key: 'calls-talk-time-by-area', label: 'Calls + talk time by area' },
      ],
    },
  ],
  customFilterTypes: [
    {
      label: 'Calls',
      items: [
        // {key: 'all-calls', label: 'User calls', icon: 'drag.svg', iconClass: 'icon-left'},
        { key: 'inbound', label: 'Inbound', icon: 'drag.svg', iconClass: 'icon-left' },
        { key: 'outbound', label: 'Outbound', icon: 'drag.svg', iconClass: 'icon-left' },
        { key: 'toll-free', label: 'Toll free', icon: 'drag.svg', iconClass: 'icon-left' },
        // {key: 'toll-free', label: 'Toll free (O)', icon: 'drag.svg', iconClass: 'icon-left'},
        { key: 'international', label: 'International', icon: 'drag.svg', iconClass: 'icon-left' },
        // {key: 'international', label: 'International (O)', icon: 'drag.svg', iconClass: 'icon-left'},
        { key: 'missed-calls', label: 'Missed calls', icon: 'drag.svg', iconClass: 'icon-left' },
        { key: 'unique-dials', label: 'Unique dials', icon: 'drag.svg', iconClass: 'icon-left' },
        { key: 'connected-calls', label: 'Connected calls', icon: 'drag.svg', iconClass: 'icon-left' },
      ],
    },
    {
      label: 'Talk times',
      items: [
        // {key: 'all', label: 'All', icon: 'drag.svg', iconClass: 'icon-left'},
        // {key: 'all-inbound', label: 'All inbound', icon: 'drag.svg', iconClass: 'icon-left'},
        // {key: 'all-outbound', label: 'All outbound', icon: 'drag.svg', iconClass: 'icon-left'},
        { key: 'inbound', label: 'Inbound', icon: 'drag.svg', iconClass: 'icon-left' },
        { key: 'outbound', label: 'Outbound', icon: 'drag.svg', iconClass: 'icon-left' },
        { key: 'toll-free', label: 'Toll free', icon: 'drag.svg', iconClass: 'icon-left' },
        { key: 'international', label: 'International', icon: 'drag.svg', iconClass: 'icon-left' },
        // {key: 'toll-free-o', label: 'Toll free(O)', icon: 'drag.svg', iconClass: 'icon-left'},
      ],
    },
    {
      label: 'Locations',
      items: [
        { key: 'dallas', label: 'Dallas', icon: 'drag.svg', iconClass: 'icon-left' },
        { key: 'new-york', label: 'New York', icon: 'drag.svg', iconClass: 'icon-left' },
        { key: 'scottsdale', label: 'Scottsdale', icon: 'drag.svg', iconClass: 'icon-left' },
      ],
    },
    {
      label: 'Users',
      items: [
      { key: 'robert', label: 'Robert B. Weide', icon: 'drag.svg', iconClass: 'icon-left' },
      { key: 'larry', label: 'Larry Charles', icon: 'drag.svg', iconClass: 'icon-left' },
      { key: 'bryan', label: 'Bryan Gordon', icon: 'drag.svg', iconClass: 'icon-left' },
      { key: 'david-steinberg', label: 'David Steinberg', icon: 'drag.svg', iconClass: 'icon-left' },
      { key: 'alec', label: 'Alec Berg', icon: 'drag.svg', iconClass: 'icon-left' },
      { key: 'david-mandel', label: 'David Mandel', icon: 'drag.svg', iconClass: 'icon-left' },
      { key: 'jeff', label: 'Jeff Schaffer', icon: 'drag.svg', iconClass: 'icon-left' },
      ],
    },
  ],
  sectionFilters: {
    location: ['Phoenix', 'Mesa', 'Scottsdale', 'Chandler'],
    call_group: ['Call Group 1', 'Call Group 2'],
    user_activity: [
      'Robert B. Weide',
      'Larry Charles',
      'Bryan Gordon',
      'David Steinberg',
      'Alec Berg',
      'David Mandel',
      'Jeff Schaffer',
      'Andy Ackerman',
      'Jeff Garlin',
      'Dean Parisot',
      'Keith Truesdell',
      'Tom Kramer',
    ],
    phone_number: ['480-555-1234', '480-555-1245', '480-555-9871'],
  },
  demoBar: {
    'meta': {
      'y_label': 'Number of minutes',
      'items': [
        'Inbound',
        'Outbound',
        'Toll free',
        "Int'l",
        'Answered',
        'Missed',
        'Voicemail',
      ],
      'categoryField': 'category',
    },
    'data': [
      {
        'category': 'Inbound',
        'Inbound': 973,
      },
      {
        'category': 'Outbound',
        'Outbound': 1500,
      },
      {
        'category': 'Toll free',
        'Toll free': 463,
      },
      {
        'category': "Int'l",
        "Int'l": 805,
      },
      {
        'category': 'Answered',
        'Answered': 1105,
      },
      {
        'category': 'Missed',
        'Missed': 1200,
      },
      {
        'category': 'Voicemail',
        'Voicemail': 605,
      },
    ],
  },
  reportSettings() {
    return {
      summary: {
        starts_time: '00:00',
        starts_on: '0',
        ends_time: '23:59',
        ends_on: '0',
        real_time: false,
      },
      location: {
        starts_time: '00:00',
        starts_on: '0',
        ends_time: '23:59',
        ends_on: '0',
        real_time: false,
      },
      call_group: {
        starts_time: '00:00',
        starts_on: '0',
        ends_time: '23:59',
        ends_on: '0',
        real_time: false,
      },
      user_activity: {
        starts_time: '00:00',
        starts_on: '0',
        ends_time: '23:59',
        ends_on: '0',
        real_time: false,
      },
      phone_number: {
        starts_time: '00:00',
        starts_on: '0',
        ends_time: '23:59',
        ends_on: '0',
        real_time: false,
      },
      time_zone: 'Arizona',
      refresh_rate_seconds: '15',
      default_sampling_seconds: '300',
    };
  },
  monitoringSettings() {
    return {
      phone_number: {
        starts_time: '00:00',
        starts_on: '0',
        ends_time: '23:59',
        ends_on: '0',
        real_time: false,
      },
      wallboards: {
        starts_time: '00:00',
        starts_on: '0',
        ends_time: '23:59',
        ends_on: '0',
        real_time: false,
      },
      scorecards: {
        starts_time: '00:00',
        starts_on: '0',
        ends_time: '23:59',
        ends_on: '0',
        real_time: false,
      },
      gamification: {
        starts_time: '00:00',
        starts_on: '0',
        ends_time: '23:59',
        ends_on: '0',
        real_time: false,
      },
      time_zone: 'Arizona',
      refresh_rate_seconds: '15',
      default_sampling_seconds: '300',
    };
  },
  metadata() {
    return {
      reports: {
        report_subtypes: ['Calls', 'Minutes'],
        'Total Calls': {
          graph_types: ['bar', 'pie'],
          size: 'half',
        },
        'Inbound Calls': {
          graph_types: ['scatter', 'stacked'],
          size: 'half',
        },
        'Hourly Activity': {
          size: 'full',
        },
      },
      time_range: {
        real_time: false,
        from: '2015-01-01 07:00:00.583111+00:00',
        to: '2015-01-04 06:59:00.583111+00:00',
      },
      time_ranges: [
        {
          real_time: false,
          from: '2015-01-01 07:00:00.583111+00:00',
          to: '2015-01-04 06:59:00.583111+00:00',
        },
        {
          real_time: false,
          from: '2015-01-05 07:00:00.583111+00:00',
          to: '2015-01-09 06:59:00.583111+00:00',
        },
      ],
      filters: [
        {
          name: 'total_calls',
          label: 'Total Calls',
          route: 'total-calls',
        },
        {
          name: 'inbound',
          label: 'Inbound',
          route: 'inbound',
        },
        {
          name: 'outbound',
          label: 'Outbound',
          route: 'outbound',
        },
        {
          name: 'answered',
          label: 'Answered',
          route: 'answered',
        },
        {
          name: 'missed',
          label: 'Missed',
          route: 'missed',
        },
        {
          name: 'voicemail',
          label: 'Voicemail',
          route: 'voicemail',
        },
      ],
    };
  },
  filters() {
    return {
      total_calls: getRandomInt(80, 100),
      inbound: getRandomInt(30, 70),
      outbound: getRandomInt(30, 70),
      answered: getRandomInt(20, 40),
      missed: getRandomInt(0, 10),
      voicemail: getRandomInt(0, 10),
    };
  },
  callFilters: {
    total: 100,
    inbound: 50,
    outbound: 50,
    answered: 50,
    missed: 50,
    voicemail: 50,
  },

  barData: {
    'total_calls': {
      'calls': {
        'serial': {
          'meta': { 'categoryField': 'category', 'y_label': 'Number of calls', 'items': ['Toll Free', 'International', 'Inbound', 'Outbound'] },
          'data': [
            { 'category': 'Toll Free', 'Toll Free': 12 },
            { 'category': 'International', 'International': 99 },
            { 'category': 'Inbound', 'Inbound': 85 },
            { 'category': 'Outbound', 'Outbound': 73 },
          ] },
      },
      'minutes': {
        'serial': {
          'meta': { 'categoryField': 'category', 'y_label': 'Number of calls', 'items': ['Toll Free', 'International', 'Inbound', 'Outbound'] },
          'data': [
            { 'category': 'Toll Free', 'Toll Free': 12 },
            { 'category': 'International', 'International': 99 },
            { 'category': 'Inbound', 'Inbound': 85 },
            { 'category': 'Outbound', 'Outbound': 73 },
          ] },
      },
    },
  },

  pieData: {
    'total_calls': {
      'calls': {
        'pie': {
          'meta': { 'categoryField': 'category', 'valueField': 'value' },
          'data': [
              { 'category': 'Toll Free', 'value': 12 },
              { 'category': 'International', 'value': 99 },
              { 'category': 'Inbound', 'value': 85 },
              { 'category': 'Outbound', 'value': 73 },
          ] },
      },

      'minutes': {
        'pie': {
          'meta': { 'categoryField': 'category', 'valueField': 'value' },
          'data': [
              { 'category': 'Toll Free', 'value': 12 },
              { 'category': 'International', 'value': 99 },
              { 'category': 'Inbound', 'value': 85 },
              { 'category': 'Outbound', 'value': 73 },
          ] },
      },
    },
  },

  scatterData: {
    'total_calls': {
      'calls': {
        'serial': {
          'meta': { 'categoryField': 'date', 'y_label': 'Number of calls', 'items': ['Toll Free', 'International', 'Inbound', 'Outbound'] },
          'data': [
            { 'date': '2015-10-01', 'Toll Free': 12, 'International': 11, 'Inbound': 55, 'Outbound': 11 },
            { 'date': '2015-10-02', 'Toll Free': 23, 'International': 99, 'Inbound': 43, 'Outbound': 17 },
            { 'date': '2015-10-03', 'Toll Free': 44, 'International': 57, 'Inbound': 85, 'Outbound': 60 },
            { 'date': '2015-10-04', 'Toll Free': 33, 'International': 46, 'Inbound': 93, 'Outbound': 73 },
            { 'date': '2015-10-05', 'Toll Free': 65, 'International': 21, 'Inbound': 73, 'Outbound': 51 },
            { 'date': '2015-10-06', 'Toll Free': 43, 'International': 98, 'Inbound': 54, 'Outbound': 83 },
            { 'date': '2015-10-07', 'Toll Free': 28, 'International': 76, 'Inbound': 90, 'Outbound': 92 },
          ],
        },
      },

      'minutes': {
        'meta': { 'categoryField': 'date', 'y_label': 'Number of minutes', 'items': ['Toll Free', 'International', 'Inbound', 'Outbound'] },
        'data': [
          { 'date': '2015-10-01', 'Toll Free': 12, 'International': 11, 'Inbound': 55, 'Outbound': 11 },
          { 'date': '2015-10-02', 'Toll Free': 23, 'International': 99, 'Inbound': 43, 'Outbound': 17 },
          { 'date': '2015-10-03', 'Toll Free': 44, 'International': 57, 'Inbound': 85, 'Outbound': 60 },
          { 'date': '2015-10-04', 'Toll Free': 33, 'International': 46, 'Inbound': 93, 'Outbound': 73 },
          { 'date': '2015-10-05', 'Toll Free': 65, 'International': 21, 'Inbound': 73, 'Outbound': 51 },
          { 'date': '2015-10-06', 'Toll Free': 43, 'International': 98, 'Inbound': 54, 'Outbound': 83 },
          { 'date': '2015-10-07', 'Toll Free': 28, 'International': 76, 'Inbound': 90, 'Outbound': 92 },
        ] },
    },
  },

  lineData: {
    'metadata': { 'categoryField': 'hour', 'items': ['Toll Free', 'International', 'Inbound', 'Outbound'] },
    'total_calls': {
      'calls': {
        'serial': {
          'meta': { 'categoryField': 'hour', 'y_label': 'Number of calls', 'items': ['Toll Free', 'International', 'Inbound', 'Outbound'] },
          'data': [
            { 'hour': '12AM', 'Toll Free': 12, 'International': 11, 'Inbound': 55, 'Outbound': 11 },
            { 'hour': '1', 'Toll Free': 23, 'International': 99, 'Inbound': 43, 'Outbound': 17 },
            { 'hour': '2', 'Toll Free': 44, 'International': 57, 'Inbound': 85, 'Outbound': 60 },
            { 'hour': '3', 'Toll Free': 33, 'International': 46, 'Inbound': 93, 'Outbound': 73 },
            { 'hour': '4', 'Toll Free': 65, 'International': 21, 'Inbound': 73, 'Outbound': 51 },
            { 'hour': '5', 'Toll Free': 43, 'International': 98, 'Inbound': 54, 'Outbound': 83 },
            { 'hour': '6', 'Toll Free': 28, 'International': 76, 'Inbound': 90, 'Outbound': 92 },
            { 'hour': '7', 'Toll Free': 23, 'International': 99, 'Inbound': 43, 'Outbound': 17 },
            { 'hour': '8', 'Toll Free': 44, 'International': 57, 'Inbound': 85, 'Outbound': 60 },
            { 'hour': '9', 'Toll Free': 33, 'International': 46, 'Inbound': 93, 'Outbound': 73 },
            { 'hour': '10', 'Toll Free': 65, 'International': 21, 'Inbound': 73, 'Outbound': 51 },
            { 'hour': '11', 'Toll Free': 43, 'International': 98, 'Inbound': 54, 'Outbound': 83 },
            { 'hour': '12PM', 'Toll Free': 28, 'International': 76, 'Inbound': 90, 'Outbound': 92 },
          ] } },

      'minutes': {
        'meta': { 'categoryField': 'hour', 'y_label': 'Number of minutes', 'items': ['Toll Free', 'International', 'Inbound', 'Outbound'] },
        'data': [
          { 'hour': '12AM', 'Toll Free': 12, 'International': 11, 'Inbound': 55, 'Outbound': 11 },
          { 'hour': '1', 'Toll Free': 23, 'International': 99, 'Inbound': 43, 'Outbound': 17 },
          { 'hour': '2', 'Toll Free': 44, 'International': 57, 'Inbound': 85, 'Outbound': 60 },
          { 'hour': '3', 'Toll Free': 33, 'International': 46, 'Inbound': 93, 'Outbound': 73 },
          { 'hour': '4', 'Toll Free': 65, 'International': 21, 'Inbound': 73, 'Outbound': 51 },
          { 'hour': '5', 'Toll Free': 43, 'International': 98, 'Inbound': 54, 'Outbound': 83 },
          { 'hour': '6', 'Toll Free': 28, 'International': 76, 'Inbound': 90, 'Outbound': 92 },
          { 'hour': '7', 'Toll Free': 23, 'International': 99, 'Inbound': 43, 'Outbound': 17 },
          { 'hour': '8', 'Toll Free': 44, 'International': 57, 'Inbound': 85, 'Outbound': 60 },
          { 'hour': '9', 'Toll Free': 33, 'International': 46, 'Inbound': 93, 'Outbound': 73 },
          { 'hour': '10', 'Toll Free': 65, 'International': 21, 'Inbound': 73, 'Outbound': 51 },
          { 'hour': '11', 'Toll Free': 43, 'International': 98, 'Inbound': 54, 'Outbound': 83 },
          { 'hour': '12PM', 'Toll Free': 28, 'International': 76, 'Inbound': 90, 'Outbound': 92 },
        ] },
    },
  },

  customChartData(items) {
    const customChart = {
      'meta': {
        'x_type': 'numeric',
        'lineThickness': 5,
        'y_type': 'numeric',
        'y_label': 'Number of calls',
        'bullet_type': 'none',
        'categoryField': 'category',
        'items': [
          'Inbound',
          'Outbound',
          'Toll free',
          'International',
        ],
      },
      'data': [
        {
          'Outbound': 88,
          'Inbound': 152,
          'International': 110,
          'Toll free': 184,
          'category': 'Robert B. Weide',
        },
        {
          'Outbound': 189,
          'Inbound': 35,
          'International': 114,
          'Toll free': 19,
          'category': 'Larry Charles',
        },
        {
          'Outbound': 158,
          'Inbound': 52,
          'International': 174,
          'Toll free': 37,
          'category': 'Bryan Gordon',
        },
        {
          'Outbound': 123,
          'Inbound': 79,
          'International': 10,
          'Toll free': 13,
          'category': 'David Steinberg',
        },
        {
          'Outbound': 72,
          'Inbound': 10,
          'International': 5,
          'Toll free': 12,
          'category': 'Alec Berg',
        },
        {
          'Outbound': 201,
          'Inbound': 24,
          'International': 111,
          'Toll free': 19,
          'category': 'David Mandel',
        },
        {
          'Outbound': 43,
          'Inbound': 123,
          'International': 22,
          'Toll free': 12,
          'category': 'Jeff Schaffer',
        },
      ],
    };

    const copyChartData = _.cloneDeep(customChart);

    return copyChartData;
  },
  generateRandomPanelData(itemTitles, grouping, minVal, maxVal) {
    const items = [];
    let num = 0;
    for (let i = 0; i < itemTitles.length; i++) {
      if (grouping > 1) {
        const subItem = [];
        for (let j = 0; j < grouping; j++) {
          num = itemTitles[i].type === 'Calls' ? getRandomInt(minVal, maxVal) : getRandomInt(Math.floor(minVal / 2), Math.floor(maxVal / 2));
          subItem.push(num);
        }
        items.push({ title: itemTitles[i].title, value: subItem, type: itemTitles[i].type });
      } else {
        items.push({ title: itemTitles[i].title, value: itemTitles[i].type === 'Calls' ? getRandomInt(minVal, maxVal) : getRandomInt(minVal / 2, maxVal / 2), type: itemTitles[i].type });
      }
    }
    return items;
  },
};

export default dummyData;
