import React, { Component } from 'react';
import _ from 'lodash';
import validator from 'validator';

import Utils from 'utils/common_utils';

validator.required = (val) => Boolean(val.length);

const Form = {
  errors: [],
  errorMap: {},
};

const checkRules = (rule, ref, segment) => {
  if (!segment) {
    segment = '';
  }

  let message = Form.errorMap[`${segment + ref}:${rule}`] ||
  Form.errorMap[segment + ref] || Form.errorMap[segment + rule] || null;
  if (segment.length) {
    message = message || Form.errorMap[segment] || null;
  }

  return message;
};

const formatArgs = (rule, args) => {
  const formats = {
    isInt: {
      min: parseInt(args[1], 10),
      max: parseInt(args[2], 10),
      allow_leading_zeroes: true,
    },
  };
  if (formats[rule]) {
    return [args[0], formats[rule]];
  }
  return args;
};

const FormMixin = (Component) => class extends Component {
  constructor(props) {
    super(props);
    this.Form = Form;
    this.formErrors = Form.errors;
    this.state = {};
  }

  buildKeys() {
    // refs haven't changed
    if (_.isEqual(_.keys(this.validations), _.keys(this.component.refs))) return;

    this.validations = {};
    _.each(_.keys(this.component.refs), (key) => {
      if (this.component.refs[key].dataset && this.component.refs[key].dataset.validations) {
        this.validations[key] = this.component.refs[key].dataset.validations.split(',').map((i) => i.trim());
      }
    });
  }

  componentDidMount() {
    this.buildKeys();
    window.addEventListener('globalValidate', this.validateRefs);
    window.addEventListener('globalValidateClear', this.clearErrors);
  }

  componentWillUpdate() {
    this.buildKeys();
  }

  componentWillUnmount() {
    window.removeEventListener('globalValidate', this.validateRefs);
    window.removeEventListener('globalValidateClear', this.clearErrors);
  }

  validateAll = () => {
    this.clearAllErrors();
    this.formErrors.length = 0;
    Utils.dispatchEvent('globalValidate');
    if (this.formErrors.length) {
      return this.formErrors;
    }
    return null;
  }

  addErrorMessages = (opts) => {
    this.Form.errorMap = _.merge(this.Form.errorMap, opts);
  }

  clearAllErrors = () => {
    Utils.dispatchEvent('globalValidateClear');
  }

  clearErrors = () => {
    const obj = this.state;
    _.each(_.keys(this.validations), (ref) => {
      delete (obj[`${ref}Error`]);
    });
    this.setState(obj);
  }

  validateRefs = () => {
    _.each(_.keys(this.validations), (ref) => {
      this.validate(ref);
    });
  }

  validate = (ref) => {
    if (!this.validations[ref]) return;
    const node = this.component.refs[ref];
    const obj = {};
    const error = {};
    if (_.includes(this.validations[ref], 'required') || node.value.length) {
      // lodash each (faster) with return false emulating Array.every
      _.each(this.validations[ref], (rule) => {
        let args = rule.split(':');
        rule = args[0];
        args = [node.value].concat(args.slice(1));
        args = formatArgs(rule, args);
        if (!validator[rule].apply(this, args)) {
          const pathArray = this.path.split(':');

          while (pathArray.length) {
            const p = pathArray.join(':');
            error.message = checkRules(rule, ref, p);
            if (error.message) break;
            pathArray.pop();
          }

          if (!error.message) {
            error.message = checkRules(rule, ref) || 'error';
          }

          error.rule = rule;
          return false;
        }
      });
    }

    if (error.message) {
      let path = this.path || '';
      if (path.length) path += ':';

      this.formErrors.push({
        path: path + ref,
        rule: error.rule,
        error: error.message,
      });
      obj[`${ref}Error`] = error.message;
    } else {
      obj[`${ref}Error`] = null;
    }
    this.setState(obj);
  }

  render() {
    return (
      <Component
        {...this.props}
        ref={(c) => { this.component = c; }}
        validate={this.validate}
        validateAll={this.validateAll}
        addErrorMessages={this.addErrorMessages}
        form={this.Form}
        errors={this.state}
      />
    );
  }
};

export default FormMixin;
