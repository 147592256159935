import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import type from 'type-of';
import { connect } from 'react-redux';

import AppData from 'app_data';
import { Table } from 'modules/monitoring/gamification/table';

import { ReactComponent as PopoutImg } from 'img/popout.svg';

const mapStateToProps = (state) => ({
  controlsState: state.controls.gamification || {},
});

class TableContainer extends Component {
  static propTypes = {
    tableLabels: PropTypes.array,
    timeRange: PropTypes.object,
    realTime: PropTypes.bool,
    metric: PropTypes.object,
    nBuckets: PropTypes.number,
    unit: PropTypes.string,
    unitLabel: PropTypes.string,
    selectedType: PropTypes.string,
    filters: PropTypes.array,
    grouping: PropTypes.bool,
    controlsState: PropTypes.object,
  };

  handlePopout = () => {
    const path = 'gamification-table-popout';
    // save state
    const params = {};
    params.componentState = _.assign({}, this.props, this.state);
    params.componentState.loading = false;
    params.componentState.data = null;

    params.controlsState = _.assign({}, this.props.controlsState);

    // Save params to server, redirect with returned hash on data.id
    AppData.savePopoutParams(params).then((response) => {
      const { data } = response;
      const route = `${process.env.REACT_APP_PUBLIC_PATH || ''}${path}/${data.id}`;
      const open = window.open(route);

      // ipad blocks popouts, redirect page
      if (open == null || type(open) === 'undefined') {
        window.location.href = route;
      }
    });
  };

  render() {
    return (
      <div className="content-box monitoring-gamification">
        <div className="content-box-header">
          <ul className="labels">
            <li>{this.props.tableLabels[0]}</li>
            <li>{this.props.tableLabels[1]}</li>
          </ul>
          <ul className="controls">
            <li onClick={this.handlePopout}>
              <PopoutImg />
            </li>
          </ul>
        </div>
        <div className="content-box-content">
          <Table
            ref="table"
            realTime={this.props.realTime}
            metric={this.props.metric}
            nBuckets={this.props.nBuckets}
            unit={this.props.unit}
            unitLabel={this.props.unitLabel}
            timeRange={this.props.timeRange}
            filterType={this.props.selectedType}
            filters={this.props.filters}
            tableLabels={this.props.tableLabels}
            grouping={this.props.grouping}
          />
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(TableContainer);
