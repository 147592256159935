import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import cx from 'classnames';

import Button from 'components/button';
import InlineSvgFactory from 'components/inline_svg';

const Arrow = InlineSvgFactory('arrow.svg');

class AccordionSection extends Component {
  static propTypes = {
    /** Handler for toggling the accordion */
    onToggleAccordion: PropTypes.func,
    /** Handler for when an accordion item is selected */
    onSelect: PropTypes.func,
    /** The currently selected item */
    selectedKey: PropTypes.number,
    /** The index of the corresponding item in the data array */
    parentIndex: PropTypes.number,
    /** Whether or not the accordion is currently at its limit */
    atLimit: PropTypes.bool,
    /** Whether or not the accordion section is open */
    open: PropTypes.bool,
    /** The max length of the label */
    maxStringLength: PropTypes.number,
    /** The length of the list in the accordion section */
    listLength: PropTypes.number,
    /** The header title of the accordion section */
    title: PropTypes.string,
    /** The accordion section items */
    children: PropTypes.node,
  }

  static defaultProps = {
    atLimit: false,
  };

  handleClick = () => {
    this.props.onToggleAccordion();
  }

  render() {
    const style = { height: 0 };
    const children = React.Children.map(this.props.children, (child) => {
      const clone = React.cloneElement(
        child,
        {
          onSelect: this.props.onSelect,
          selectedKey: this.props.selectedKey,
          parentIndex: this.props.parentIndex,
          atLimit: this.props.atLimit,
        }
      );
      return clone;
    });

    // TODO: copy a clone outside viewport and use getBoundingClientRect to calculate height

    if (this.props.open) {
      _.each(this.props.children.props.items, (item) => {
        if (item.label.length > this.props.maxStringLength) style.height += 22;
      });

      style.height += 15 + (50 * this.props.listLength);
      style.height += 'px';
    }

    return (
      <div data-testid="accordion-section-item" className={cx({ 'accordion__section': true, open: this.props.open, hidden: this.props.title === 'DummyFilter' })}>
        <div data-testid="accordion-section-item-title" className="accordion__title" onClick={this.handleClick}>
          <span>{this.props.title}</span>
          <Button className="btn-transparent accordion__button">
            <Arrow className="arrow" />
          </Button>
        </div>
        <div className="accordion__wrap" style={style}>
          <div className="accordion__content">
            {children}
          </div>
        </div>
      </div>
    );
  }
}

export default AccordionSection;
