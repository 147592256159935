import Constants from 'constants.js';

function questions(state = [], action) {
  switch (action.type) {
    case Constants.QUESTIONS_ADD:
      return [
        ...state,
        action.questionGroup,
      ];

    default:
      return state;
  }
}

export default questions;
