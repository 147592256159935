import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { themr } from '@nextiva/analytics-lib';
import { DateRangePicker } from '@nextiva/analytics-ui';

import Styles from './DateRangePeriod.module.scss';


class DateRangePeriod extends Component {
  static propTypes = {
    /** The timezone the date range is displayed in */
    timezone: PropTypes.string.isRequired,
    /** An array of the time ranges */
    timeRanges: PropTypes.array,
    /** Handler for changing the time range */
    onChange: PropTypes.func,
    /** Whether or not the date range period is static */
    isStatic: PropTypes.bool,
    theme: PropTypes.object,
  };

  handleDateUpdate = (index, dateData) => {
    const dates = this.props.timeRanges;
    dates[index] = dateData;
    this.props.onChange([...dates]);
  };

  render() {
    const {
      theme,
      isStatic,
      timezone,
      timeRanges,
    } = this.props;

    return (
      <div className={theme.date}>
        <DateRangePicker
          timezone={timezone}
          dateRange={timeRanges[0]}
          className="date-range-popover"
          onChange={this.handleDateUpdate.bind(this, 0)}
          theme={{
            dateRangePicker: 'date-range',
          }}
          disabled={isStatic}
          isPeriod
        />
        <span className={theme.over}>over</span>
        <DateRangePicker
          timezone={timezone}
          dateRange={timeRanges[1]}
          className="date-range-popover"
          onChange={this.handleDateUpdate.bind(this, 1)}
          theme={{
            dateRangePicker: 'date-range',
          }}
          disabled={isStatic}
          isPeriod
        />
      </div>
    );
  }
}

export default themr(DateRangePeriod, Styles);
