import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { SvgIcon } from '@nextiva/next-ui';

/*
* Example: var MySvg = require('components/inlinesvg')('mysvg.svg');
* <MySvg className="mysvg" />
*
* CSS to change color (Must use important) :
*
* .mysvg svg path {
*  fill: #CCC!important
* }
*/
const Svg = (filename) => {
  /* eslint-disable global-require */
  const link = require(`img/${filename}`);
  /* eslint-enable global-require */
  return class extends Component {
    static propTypes = {
      /** Class name for the inline SVG */
      className: PropTypes.string,
      /** Function for when the SVG is clicked */
      onClick: PropTypes.func,
    };

    render() {
      return (
        <div className={`inline-svg ${this.props.className || ''}`}>
          <div ref="svg" onClick={this.props.onClick} className="svg-overlay" />
          <SvgIcon src={link} />
        </div>
      );
    }
  };
};

export default Svg;
