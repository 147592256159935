
import _ from 'lodash';

const RatingLog = function (ui) {
  this.ui = ui;
  this.params = { ui };
  const params = this.params;

  this.individualCalls = () => {
    params.rand = this.dailyRand;
    params.selects = this.getSelects(0);
    params.items = [
      'Call Type',
      'Time',
      'Call User',
      'Question Group',
      'Question Title',
      'Question Type',
      'Min Score',
      'Max Score',
      'Score',
      'Rating',
    ];
    params.dates = this.ui.timeRanges[0];
    params.tableSwap = true;
    params.tableLabels = ['Rating User', 'Rating User'];

    this.createData = () => this.createRatingLogData(params);
  };
};

export default RatingLog;
