import React, { memo } from 'react';

import { serialToPies } from 'utils/chart_utils';

import { NoData } from 'components';
import { ReportCallLogContainer } from 'modules';
import ReportTable from 'components/report_table';
import ResizableChart from 'components/charts/resizable_chart';

import Styles from './Chart.module.scss';


const Chart = memo((props) => {
  const {
    data,
    unit,
    title,
    report,
    format,
    select,
    display,
    filters,
    realTime,
    timeRanges,
    legendPosition,
  } = props;


  /* call-log can be populated from data or via url, anything else requires
   * data be set.
   */
  if (format === 'call_log' || format == 'sms_log') {
    const isSMS = format == 'sms_log';

    return (
      <ReportCallLogContainer
        pagination
        selects={select}
        filters={filters}
        realTime={realTime}
        timeRange={timeRanges[0]}
        // call log always has this undefined somehow and it breaks otherwise
        // to make sure sms data is also undefined so the sms-log endpoint is used
        externalReportData={isSMS ? undefined : data}
        url={isSMS ? '/v2/nextos/reports/sms-log' : '/v2/nextos/reports/call-log'}
        isSMS={isSMS}
      />
    );
  }

  if (data.length === 0) {
    return (
      <div className={Styles.noData}>
        <NoData isAbsolutePosition />
      </div>
    );
  }

  if (display === 'table') {
    return data.map((tableData, index) => {
      return (
        <ReportTable
          autoSize
          key={index}
          data={tableData}
          className="standard-table custom-table"
        />
      );
    });
  }

  const [ chartData ] = display === 'pie' && report === 'call_duration'
    ? serialToPies(data[0])
    : data;

  // TODO: after refactoring need to remove it
  chartData.meta.date_format = chartData.meta.dateFormat;

  return (
    <ResizableChart
      forceRender
      title={title}
      unit={unit}
      chartData={chartData}
      chart={{ name: display }}
      timeRange={timeRanges[0]}
      legendPosition={legendPosition}
    />
  );
});


Chart.displayName = 'Chart';


export default Chart;
