
import {
  Saved,
  Scheduled,
  SavedDashboards,
} from 'modules/saved';


const routes = [
  {
    path: '/saved/dashboard',
    title: 'Saved Reports',
    component: Saved,
  },
  {
    path: '/saved/scheduled',
    title: 'Scheduled Reports',
    component: Scheduled,
  },
  {
    path: '/saved/dashboards',
    title: 'Saved Dashboards',
    component: SavedDashboards,
  },
];


export default routes;
