import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import _ from 'lodash';
import update from 'immutability-helper';
import ReactTimeout from 'react-timeout';
import { DefaultSpinner } from 'components';

import AppData from 'app_data';
import TileOptionsContainer from 'modules/monitoring/custom_dashboard/tile_options_container';
import MultiSelectContainer from 'components/multi_select_container';
import Utils from 'utils/common_utils';
import callTypeOptions from 'modules/monitoring/custom_dashboard/call_type_options';
import ColorPicker from 'components/color_picker';
import vex from 'lib/vex';
import { CallTypeTileControls } from 'components/custom_dashboard';

import { ReactComponent as Plus } from 'img/plus.svg';
import { ReactComponent as Minus } from 'img/plus.svg';

const MAX_THRESHOLDS = 5;
const MIN_FONT_SIZE = 24;
const MAX_FONT_SIZE = 64;

const bucketTypeOptions = [
  { key: 'users', label: 'Users', bucketType: 'users' },
  { key: 'locations', label: 'Locations', bucketType: 'locations' },
  { key: 'call_groups', label: 'Call Groups', bucketType: 'call_groups' },
  { key: 'phone_numbers', label: 'Phone Numbers', bucketType: 'phone_numbers' },
  { key: 'teams:users', teamType: 'users', label: 'Custom Groups: Users', bucketType: 'teams' },
  { key: 'teams:locations', teamType: 'locations', label: 'Custom Groups: Locations', bucketType: 'teams' },
  { key: 'teams:call_groups', teamType: 'call_groups', label: 'Custom Groups: Call Groups', bucketType: 'teams' },
  { key: 'teams:phone_numbers', teamType: 'phone_numbers', label: 'Custom Groups: Phone Numbers', bucketType: 'teams' },
];

function isOverlapping(x1, x2, y1, y2) {
  return Math.max(x1, y1) < Math.min(x2, y2);
}

function isPositiveIntegerOrInfinity(n) {
  return (n >>> 0 === parseFloat(n)) || (n === Infinity);
}

function isInfinitySymbol(str) {
  const maybeStr = Utils.encodeHtmlEntity(str);
  return str === '&#8734;' || maybeStr === '&#8734;';
}

class CallTypeTileOptions extends React.Component {
  static propTypes = {
    tileInfo: PropTypes.shape({
      filter: PropTypes.string,
      filters: PropTypes.array,
      title: PropTypes.string,
      callType: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
      ]),
      thresholds: PropTypes.array,
      thresholdCount: PropTypes.number,
    }),
    onUpdateItem: PropTypes.func,
    onRemoveItem: PropTypes.func,
    onCancel: PropTypes.func,
  };

  constructor(props) {
    super(props);
    const { tileInfo } = props;

    this.state = {
      filter: tileInfo.filter || '',
      filters: tileInfo.filters || [],
      title: tileInfo.title || '',
      callType: tileInfo.callType || '',
      thresholds: tileInfo.thresholds || _.fill(Array(MAX_THRESHOLDS), { from: '', to: '&#8734;', color: '#3d85d1' }),
      thresholdCount: tileInfo.thresholds ? tileInfo.thresholds.length : 1,
      errors: {},
    };
  }

  componentDidMount() {
    if (this.props.tileInfo.filter) {
      const filterType = _.find(bucketTypeOptions, { key: this.props.tileInfo.filter });
      this.getFilterOptions(filterType, 'mount');
    }
  }

  onFilterChange = (value) => {
    const filterType = _.find(bucketTypeOptions, { key: value.key });
    this.getFilterOptions(filterType);
  };

  onFiltersChange = (value) => {
    this.setState({
      filters: value,
    });
  };

  onCallTypeChange = (value) => {
    this.setState({
      callType: value,
    });
  };

  onTitleChange = (event) => {
    this.setState({
      title: event.target.value,
    });
  };

  getFilterOptions = (filterType, str) => {
    const type = filterType.key.split(':')[0];
    AppData.getNextOsType(type).then((response) => {
      let data = response.data;

      if (type === 'teams') {
        data = _.filter(data, (item) => filterType.teamType === item.team_type);
      }

      // cast all ids to str
      const filterOptions = _.map(data, (item) => ({ id: String(item.id), label: item.label }));

      const selectedFilters = str === 'mount' ? this.state.filters : filterOptions;

      this.setState({
        filter: filterType.key,
        filters: selectedFilters,
        filterOptions,
      });
    });
  };

  getBucketTypeOptions = () => bucketTypeOptions.map((type) => <option key={type.key} value={type.key}>{type.label}</option>);

  getCallTypeOptions = () => callTypeOptions.map((type) => <option key={type.key} value={type.key}>{type.label}</option>);

  addThreshold = () => {
    const { thresholdCount, thresholds } = this.state;
    if (thresholds.length === 5) {
      this.setState({ thresholdCount: thresholdCount + 1 });
    } else {
      const newThresholds = thresholds.slice(0);
      const newThreshold = { from: '', to: '&#8734;', color: '#3d85d1' };
      newThresholds.push(newThreshold);
      this.setState({ thresholdCount: thresholdCount + 1, thresholds: newThresholds });
    }
  };

  removeThreshold = () => {
    const { thresholdCount, thresholds, errors } = this.state;
    const newThresholds = thresholds.slice(0);
    const newErrors = _.assign({}, errors);
    newThresholds[thresholdCount - 1] = { from: '', to: '&#8734;', color: '#3d85d1' };
    if (newErrors.thresholds && newErrors.thresholds[thresholdCount - 1].hasError) {
      newErrors.thresholds[thresholdCount - 1] = { hasError: false };
    }
    this.setState({ thresholdCount: thresholdCount - 1, thresholds: newThresholds, errors: newErrors });
  };

  updateThreshold = (index, event) => {
    let key;
    let value;

    if (event.target) {
      key = event.target.name;
      value = event.target.value;
    } else {
      key = 'color';
      value = `${event.hex}`;
    }
    const newState = update(this.state, {
      thresholds: {
        [index]: {
          [key]: {
            $set: value,
          },
        },
      },
    });
    this.setState(newState);
  };

  validateCallTypeInfo = () => {
    const errors = {};
    if (!this.state.title) {
      errors.title = true;
    }
    if (!this.state.filter) {
      errors.filter = true;
    }
    if (!this.state.callType) {
      errors.callType = true;
    }
    const thresholdErrors = this.validateThresholdInfo();
    if (thresholdErrors.length) {
      errors.thresholds = thresholdErrors;
    }
    if (Object.keys(errors).length) {
      this.setState({ errors });
      return false;
    }
    return true;
  };

  filterThresholds = (thresholds) => {
    const validThresholds = thresholds.filter((threshold) => {
      const from = threshold.from;
      const to = threshold.to;
      if (from !== '' && to !== '') {
        return threshold;
      }
      return false;
    });
    return validThresholds;
  };

  validateThresholdInfo = () => {
    const errors = {
      hasError: false,
      errorMessage: 'Please enter a threshold.',
      thresholds: [],
    };

    for (let i = 0; i < MAX_THRESHOLDS; i++) {
      errors.thresholds.push({ hasError: false });
    }

    const { thresholds } = this.state;
    const validThresholds = this.filterThresholds(thresholds.slice(0));

    if (!validThresholds.length) {
      errors.hasError = true;
    } else if (validThresholds.length === 1) {
      const singleThreshold = validThresholds[0];
      const from = parseInt(singleThreshold.from, 10);
      const to = isInfinitySymbol(singleThreshold.to) ? Number.POSITIVE_INFINITY : parseInt(singleThreshold.to, 10);
      if ((from > to) || !isPositiveIntegerOrInfinity(from) || !isPositiveIntegerOrInfinity(to)) {
        errors.thresholds[0].hasError = true;
        errors.thresholds[0].errorMessage = 'Invalid input for bucket 1.';
      }
    }

    for (let i = 0; i < validThresholds.length; i++) {
      for (let j = i + 1; j < validThresholds.length; j++) {
        const x = validThresholds[i];
        const xFrom = parseInt(x.from, 10);
        const xTo = isInfinitySymbol(x.to) ? Number.POSITIVE_INFINITY : parseInt(x.to, 10);
        const y = validThresholds[j];
        const yFrom = parseInt(y.from, 10);
        const yTo = isInfinitySymbol(y.to) ? Number.POSITIVE_INFINITY : parseInt(y.to, 10);
        if ((xFrom > xTo) || !isPositiveIntegerOrInfinity(xFrom) || !isPositiveIntegerOrInfinity(xTo)) {
          errors.thresholds[i].hasError = true;
          errors.thresholds[i].errorMessage = `Invalid input for bucket ${i + 1}.`;
        } else if ((yFrom > yTo) || !isPositiveIntegerOrInfinity(yFrom) || !isPositiveIntegerOrInfinity(yTo)) {
          errors.thresholds[j].hasError = true;
          errors.thresholds[j].errorMessage = `Invalid input for bucket ${j + 1}.`;
        } else if (isOverlapping(xFrom, xTo, yFrom, yTo)) {
          errors.thresholds[i].hasError = true;
          errors.thresholds[i].errorMessage = `Bucket ${i + 1} overlaps with bucket ${j + 1}.`;
          errors.thresholds[j].hasError = true;
          errors.thresholds[j].errorMessage = `Bucket ${j + 1} overlaps with bucket ${i + 1}.`;
        }
      }
    }

    // this.setState({ errors });
    const errorMessages = [];
    const thresholdErrors = _.filter(errors.thresholds, { hasError: true });
    if (errors.hasError) {
      errorMessages.push(errors.errorMessage);
    }
    if (thresholdErrors.length > 0) {
      errorMessages.push(...thresholdErrors.map((error) => error.errorMessage));
    }
    if (errorMessages.length > 0) {
      vex.error(errorMessages);
      return thresholdErrors;
    }
    return [];
  };

  validate = (page) => {
    const validators = [
      this.validateCallTypeInfo,
      this.validateThresholdInfo,
    ];
    return validators[page]();
  };

  save = () => {
    const state = _.assign({}, this.state);
    state.thresholds = this.filterThresholds(state.thresholds);
    this.props.onUpdateItem(state);
  };

  renderCallTypeInfo = () => {
    const bucketTypeOptionsElements = this.getBucketTypeOptions();
    const callTypeOptionsElements = this.getCallTypeOptions();
    return (
      <div>
        <div className="custom_dashboard_tile_content_title">{'Single Call Type'}</div>
        <input className={cx({ custom_dashboard_tile_title: true, error: this.state.errors.title })} type="text" placeholder="Title" onChange={this.onTitleChange} value={this.state.title} />
        <div className="custom_dashboard_subtitle">Filter Type</div>
        <select className={cx({ error: this.state.errors.filter })} onChange={this.onFilterChange} value={this.state.filter}>
          <option value="">Select Filter Type</option>
          {bucketTypeOptionsElements}
        </select>
        <div className="custom_dashboard_subtitle">Filter Type</div>
        <MultiSelectContainer
          label={'Select Filters'}
          dropdownOptions={this.state.filterOptions}
          initialSelected={_.map(this.state.filters, 'id')}
          onDropdownSave={this.onFiltersChange}
          searchBar
        />
        <div className="custom_dashboard_subtitle">Select a call type</div>
        <select className={cx({ error: this.state.errors.callType })} onChange={this.onCallTypeChange} value={this.state.callType.key}>
          <option value="">Select Call Type</option>
          {callTypeOptionsElements}
        </select>
      </div>
    );
  };

  renderThresholdInfo = () => {
    let addButton;
    let removeButton;
    let title = 'Buckets';
    const { thresholdCount, thresholds, errors, callType } = this.state;
    const currentThresholds = thresholds.slice(0, thresholdCount);
    const thresholdElements = currentThresholds.map((threshold, index) => {
      let hasError = false;
      if (errors.thresholds && errors.thresholds[index].hasError) {
        hasError = true;
      }
      return <ThresholdRow key={index} threshold={threshold} hasError={hasError} onValueChange={this.updateThreshold.bind(this, index)} />;
    });

    if (thresholdCount < MAX_THRESHOLDS) {
      addButton = (
        <div className="btn btn_add" onClick={this.addThreshold}>
          <Plus className="threshold_add" />
        </div>
      );
    }

    if (thresholdCount > 1) {
      removeButton = (
        <div className="btn btn_remove" onClick={this.removeThreshold}>
          <Minus className="threshold_remove" />
        </div>
      );
    }

    if (callType.units === 'seconds') {
      title = 'Buckets (in minutes)';
    }

    return (
      <div className="call_type_threshold_container">
        <div className="content_title">{title}</div>
        {thresholdElements}
        <div className="threshold_buttons">
          {removeButton}
          {addButton}
        </div>
      </div>
    );
  };

  renderCallTypeOptions = () => (
    <CallTypeTileControls
      tileInfo={this.state}
      onTitleChange={this.onTitleChange}
      filterOptions={bucketTypeOptions}
      onFilterChange={this.onFilterChange}
      filtersOptions={this.state.filterOptions}
      onFiltersChange={this.onFiltersChange}
      callTypeOptions={callTypeOptions}
      onCallTypeChange={this.onCallTypeChange}
      thresholdsSection={this.renderThresholdInfo}
    />
  );

  render() {
    const { onRemoveItem, onCancel } = this.props;
    return (
      <TileOptionsContainer
        onRemoveItem={onRemoveItem}
        onCancel={onCancel}
        onUpdate={this.save}
        validate={this.validate}
        pages={[this.renderCallTypeOptions]}
      />
    );
  }
}

class ThresholdRow extends PureComponent {
  static propTypes = {
    threshold: PropTypes.object,
    hasError: PropTypes.bool,
    onValueChange: PropTypes.func,
  };

  constructor(props) {
    super(props);
    const { threshold } = props;

    this.state = {
      hasInfinity: threshold.to === '&#8734;',
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.hasInfinity && !this.state.hasInfinity) {
      this.to.focus();
    }
  }

  onBlur = (e) => {
    if (e.target.value === '') {
      this.setState({ hasInfinity: true });
    } else {
      this.props.onValueChange(e);
    }
  };

  onClick = () => {
    this.setState({ hasInfinity: false });
  };

  render() {
    const { threshold, hasError, onValueChange } = this.props;
    const { hasInfinity } = this.state;
    const defaultToValue = threshold.to === '&#8734;' ? '' : threshold.to;

    let toDisplay = (
      <input ref={(c) => { this.to = c; }} type="text" name="to" onBlur={this.onBlur} defaultValue={defaultToValue} className={cx({ 'threshold-to': true, error: hasError })} />
    );

    if (hasInfinity) {
      toDisplay = (
        <div className="infinity" onClick={this.onClick}>
          <span>&#8734;</span>
        </div>
      );
    }

    return (
      <div className="call_type_threshold">
        <label>From</label>
        <input type="text" name="from" onBlur={onValueChange} defaultValue={threshold.from} className={cx({ 'threshold-from': true, error: hasError })} />
        <label>To</label>
        {toDisplay}
        <ColorPicker
          onColorChange={onValueChange}
          initialColor={threshold.color}
        />
      </div>
    );
  }
}

class CallTypeTile extends PureComponent {
  static propTypes = {
    tileInfo: PropTypes.shape({
      filter: PropTypes.string,
      filters: PropTypes.array,
      title: PropTypes.string,
      callType: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
      ]),
      thresholds: PropTypes.array,
      thresholdCount: PropTypes.number,
    }).isRequired,
    timeRange: PropTypes.object,
    realTime: PropTypes.bool,
    editButton: PropTypes.node.isRequired,
    staticTile: PropTypes.bool,
  };

  state = {};

  componentDidMount() {
    this.getCount();
    this.resizeText();
    if (this.props.realTime) {
      const intervalID = setInterval(
        this.getCount, AppData.user.refresh_rate * 1000
      );
      this.setState({ intervalID: intervalID });
      return;
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.realTime) {
      this.getCount(nextProps);
      const intervalID = setInterval(() => { this.getCount(nextProps); }, AppData.user.refresh_rate * 1000);
      this.setState({ intervalID: intervalID });
    } else {
      clearInterval(this.state.intervalID);
    }
    if ((this.props.timeRange !== nextProps.timeRange) ||
        (this.props.realTime !== nextProps.realTime) ||
        (!_.isEqual(this.props.tileInfo, nextProps.tileInfo))) {
      this.setState({}, this.getCount);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const propsChanged = !_.isEqual(nextProps, this.props);
    const stateChanged = !_.isEqual(nextState, this.state);
    return propsChanged || stateChanged;
  }

  componentDidUpdate() {
    this.resizeText();
  }

  getCount = (props = this.props) => {
    let timeRange;
    const { tileInfo } = props;

    if (props.realTime) {
      timeRange = _.assign({}, props.timeRange);
      delete timeRange.end;
    } else {
      timeRange = props.timeRange;
    }

    const callTypeOption = tileInfo.callType;

    const params = {};
    params.time_range = timeRange;
    params.selects = [];
    const select = { metric: callTypeOption.metric, label: callTypeOption.label };
    if (callTypeOption.agg_func) {
      select.agg_func = callTypeOption.agg_func;
    }
    params.selects.push(select);
    params.filters = {};

    //send only bucket without specifier
    const bucketType = (tileInfo.bucketType || tileInfo.filter).split(':')[0];

    params.filters[bucketType] = tileInfo.filters.map((filter) => filter.id);
    callTypeOption.filters.forEach((filter) => {
      params.filters[filter] = true;
    });
    AppData.getTotalCount(params).then((response) => {
      const { data } = response;
      const key = Object.keys(data.totals)[0];
      this.setState({
        value: data.totals[key],
      });
    });
  }

  resizeText = () => {
    const element = this.results;
    if (element) {
      const width = element.offsetWidth;
      const fontSize = Math.max(
                      Math.min(width / 25, MAX_FONT_SIZE),
                      MIN_FONT_SIZE);
      if (fontSize !== this.state.fontSize) {
        this.setState({ fontSize });
      }
    }
  }

  render() {
    if (typeof this.state.value === 'number') {
      const { editButton, staticTile, tileInfo } = this.props;
      const { thresholds, callType, title } = tileInfo;
      let checkedValue;
      let displayedValue;

      const fontSize = this.state.fontSize || 'initial';
      const resultsStyle = { fontSize };

      if (callType.units === 'seconds') {
        checkedValue = this.state.value / 60;
        displayedValue = Utils.humanizeSeconds(this.state.value);
      } else {
        checkedValue = this.state.value;
        displayedValue = `${this.state.value} ${callType.units}`;
      }

      const currentThreshold = thresholds.find((threshold) => {
        const from = parseInt(threshold.from, 10);
        const to = isInfinitySymbol(threshold.to) ? Number.POSITIVE_INFINITY : parseInt(threshold.to, 10);
        return _.inRange(checkedValue, from, to + 1);
      });
      const backgroundColor = currentThreshold ? currentThreshold.color : '#3d85d1';
      const foregroundColor = Utils.getContrastYIQ(backgroundColor);
      const tileStyle = {
        backgroundColor,
        color: foregroundColor,
      };

      return (
        <div className="custom_dashboard_tile_content" style={tileStyle}>
          <div className="custom_dashboard_actions">
            {editButton}
          </div>
          <div ref={(c) => { this.results = c; }} className="call_type_results" style={resultsStyle}>
            <p>
              {title}
            </p>
            <p>
              {callType.label}
            </p>
            <p>
              {displayedValue}
            </p>
          </div>
        </div>
      );
    }
    return (
      <DefaultSpinner />
    );
  }
}

const CallType = ReactTimeout(CallTypeTile);

export { CallType };
export default CallTypeTileOptions;

