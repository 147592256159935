import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Control } from 'react-redux-form';

const ShortAnswerOptions = ({ model }) => (
  <div className="questions-form__question-options">
    <div>
      <label className="questions-form__label">Max Characters</label>
      <Control
        model={`${model}.max`}
        className="questions-form__input"
        type="number"
        updateOn="blur"
      />
    </div>
  </div>
);

ShortAnswerOptions.propTypes = {
  model: PropTypes.string,
};

export default ShortAnswerOptions;
