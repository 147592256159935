import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import BarChart from 'components/charts/bar_chart';
import HeatMap from 'components/charts/heat_map';
import LineChart from 'components/charts/line_chart';
import MultiAxisChart from 'components/charts/multi_axis_chart';
import PieChart from 'components/charts/pie_chart';
import ScatterChart from 'components/charts/scatter_chart';
import { NoData } from 'components';

class Chart extends Component {
  static propTypes = {
    /** Whether or not the chart is responsive */
    responsive: PropTypes.bool,
    /** Whether or not the chart legend is enabled */
    legendEnabled: PropTypes.bool,
    /** The time zone the chart will display */
    timeZone: PropTypes.string,
    /** The chart config object */
    chart: PropTypes.shape({
      /** Chart display type */
      name: PropTypes.string,
    }),
    /** The title of the chart */
    title: PropTypes.string,
    /** Optional: Will hardcode a chart height */
    height: PropTypes.string,
    /** Whether or not an update is forced */
    forceRender: PropTypes.bool,
    /** A unique identifier for the chart being displayed */
    chartKey: PropTypes.string,
    /** The data to be displayed on the chart */
    chartData: PropTypes.object,
    /** The kind of unit that the chart is using on the value axis (calls, minutes, etc..) */
    unit: PropTypes.string,
    /** The position of the legend */
    legendPosition: PropTypes.string,
    /** The time range that the chart will represent */
    timeRange: PropTypes.object,
    /** Whether or not download is disabled */
    disableDownload: PropTypes.bool,
    /** The classname for the kind of pie chart that will be used */
    className: PropTypes.string,
    /** An array of the items in the legend */
    enabledLegendItems: PropTypes.array,
    /** The type of line chart being rendered */
    lineChartType: PropTypes.string,
    /** Whether or not the line is smooth (rounded) */
    lineSmooth: PropTypes.bool,
    /** Whether or not the chart is a stacked line chart */
    stacked: PropTypes.bool,
    /** Alignment of the legend (left, right, etc...) */
    alignLegend: PropTypes.string,
    /** Color of the pie chart label text */
    pieColor: PropTypes.string,
    /** The text in a pie chart label */
    pieLabelText: PropTypes.string,
    /** Whether or not it is a sample chart */
    sample: PropTypes.bool,
    /** A handler for updating the chart data on drill-down */
    updateChartData: PropTypes.func,
    /** Whether or not the chart is drilling down */
    isDrillingDown: PropTypes.bool,
    /** Whether or not the chart is in the second level of drilling-down */
    isSecondLevel: PropTypes.bool,
    /** A handler for when a drill-down node is selected to open the call recording modal */
    triggerCallRecordingModal: PropTypes.func,
    legends: PropTypes.array,
    columnWidthOverride: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
  };

  // Refer to individual chart for PropTypes
  static defaultProps = {
    responsive: false,
    legendEnabled: true,
    columnWidthOverride: null,
  };

  componentWillReceiveProps(newProps) {
    this.forceUpdate();
  }

  render() {
    let chart;
    const chartKey = `chart${Math.random().toString().slice(2)}`;
    const name = _.kebabCase(this.props.chart.name);

    if (!this.props.chartData || this.props.chartData.length === 0) {
      return (
        <div className="chart-no-data">
          <NoData isAbsolutePosition />
        </div>
      );
    }

    if (name === 'line') {
      chart = (
        <LineChart
          exportTitle={this.props.title}
          height={this.props.height}
          forceRender={this.props.forceRender}
          chartKey={chartKey}
          legendPosition={this.props.legendPosition}
          chartData={this.props.chartData}
          unit={this.props.unit}
          enabledLegendItems={this.props.enabledLegendItems}
          disableDownload={this.props.disableDownload}
          timeRange={this.props.timeRange}
          lineChartType={this.props.lineChartType}
          lineSmooth={false}
          lineConfig={{ responsive: this.props.responsive, legendEnabled: this.props.legendEnabled }}
        />
      );
    } else if (name === 'smooth-line') {
      chart = (
        <LineChart
          exportTitle={this.props.title}
          height={this.props.height}
          forceRender={this.props.forceRender}
          chartKey={chartKey}
          chartData={this.props.chartData}
          unit={this.props.unit}
          legendPosition={this.props.legendPosition}
          enabledLegendItems={this.props.enabledLegendItems}
          disableDownload={this.props.disableDownload}
          timeRange={this.props.timeRange}
          lineChartType={this.props.lineChartType}
          lineSmooth
          lineConfig={{ responsive: this.props.responsive, legendEnabled: this.props.legendEnabled }}
        />
      );
    } else if (name === 'stacked-area') {
      chart = (
        <LineChart
          exportTitle={this.props.title}
          height={this.props.height}
          forceRender={this.props.forceRender}
          chartKey={chartKey}
          chartData={this.props.chartData}
          unit={this.props.unit}
          legendPosition={this.props.legendPosition}
          enabledLegendItems={this.props.enabledLegendItems}
          disableDownload={this.props.disableDownload}
          timeRange={this.props.timeRange}
          lineChartType={this.props.lineChartType}
          lineSmooth={false}
          stacked
          lineConfig={{ responsive: this.props.responsive, legendEnabled: this.props.legendEnabled }}
        />
      );
    } else if (name === 'bar') {
      chart = (
        <BarChart
          exportTitle={this.props.title}
          height={this.props.height}
          forceRender={this.props.forceRender}
          chartKey={chartKey}
          chartData={this.props.chartData}
          unit={this.props.unit}
          legendPosition={this.props.legendPosition}
          enabledLegendItems={this.props.enabledLegendItems}
          timeRange={this.props.timeRange}
          disableDownload={this.props.disableDownload}
          barConfig={{ rotate: true, responsive: this.props.responsive, legendEnabled: this.props.legendEnabled }}
          legends={this.props.legends && this.props.legends}
        />
      );
    } else if (name === 'stacked-column') {
      chart = (
        <BarChart
          exportTitle={this.props.title}
          height={this.props.height}
          forceRender={this.props.forceRender}
          chartKey={chartKey}
          chartData={this.props.chartData}
          unit={this.props.unit}
          legendPosition={this.props.legendPosition}
          enabledLegendItems={this.props.enabledLegendItems}
          timeRange={this.props.timeRange}
          disableDownload={this.props.disableDownload}
          barConfig={{ stacked: true, responsive: this.props.responsive, legendEnabled: this.props.legendEnabled }}
        />
      );
    } else if (name === 'stacked-bar') {
      chart = (
        <BarChart
          exportTitle={this.props.title}
          height={this.props.height}
          forceRender={this.props.forceRender}
          chartKey={chartKey}
          chartData={this.props.chartData}
          unit={this.props.unit}
          legendPosition={this.props.legendPosition}
          enabledLegendItems={this.props.enabledLegendItems}
          timeRange={this.props.timeRange}
          disableDownload={this.props.disableDownload}
          barConfig={{ stacked: true, rotate: true, responsive: this.props.responsive, legendEnabled: this.props.legendEnabled }}
        />
      );
    } else if (name === 'column') {
      chart = (
        <BarChart
          exportTitle={this.props.title}
          height={this.props.height}
          forceRender={this.props.forceRender}
          chartKey={chartKey}
          chartData={this.props.chartData}
          unit={this.props.unit}
          legendPosition={this.props.legendPosition}
          enabledLegendItems={this.props.enabledLegendItems}
          timeRange={this.props.timeRange}
          disableDownload={this.props.disableDownload}
          barConfig={{ responsive: this.props.responsive, legendEnabled: this.props.legendEnabled }}
          columnWidthOverride={this.props.columnWidthOverride}
        />
      );
    } else if (name === 'pie') {
      chart = (
        <PieChart
          exportTitle={this.props.title}
          height={this.props.height}
          forceRender={this.props.forceRender}
          chartKey={chartKey}
          chartData={this.props.chartData}
          unit={this.props.unit}
          timeRange={this.props.timeRange}
          legendPosition={this.props.legendPosition}
          className={this.props.className}
          disableDownload={this.props.disableDownload}
          alignLegend={this.props.alignLegend}
          pieColor={this.props.pieColor}
          pieLabelText={this.props.pieLabelText}
          pieConfig={{ responsive: this.props.responsive, legendEnabled: this.props.legendEnabled }}

        />
      );
    } else if (name === 'scatter') {
      chart = (
        <ScatterChart
          exportTitle={this.props.title}
          height={this.props.height}
          chartKey={chartKey}
          chartData={this.props.chartData}
          unit={this.props.unit}
          timeRange={this.props.timeRange}
          disableDownload={this.props.disableDownload}
          updateChartData={this.props.updateChartData}
          isDrillingDown={this.props.isDrillingDown}
          timeZone={this.props.timeZone}
          isSecondLevel={this.props.isSecondLevel}
          triggerCallRecordingModal={this.props.triggerCallRecordingModal}
        />
      );
    } else if (name === 'heat' || name === 'usa' || name === 'world') {
      chart = (
        <HeatMap
          exportTitle={this.props.title}
          height={this.props.height}
          chartKey={chartKey}
          chartData={this.props.chartData}
          unit={this.props.unit}
          timeRange={this.props.timeRange}
          disableDownload={this.props.disableDownload}
          mapType={name}
        />
      );
    } else if (name === 'donut') {
      chart = (
        <PieChart
          exportTitle={this.props.title}
          height={this.props.height}
          forceRender={this.props.forceRender}
          chartKey={chartKey}
          chartData={this.props.chartData}
          unit={this.props.unit}
          timeRange={this.props.timeRange}
          legendPosition={this.props.legendPosition}
          className={this.props.className}
          disableDownload={this.props.disableDownload}
          alignLegend={this.props.alignLegend}
          pieColor={this.props.pieColor}
          pieLabelText={this.props.pieLabelText}
          donut
          pieConfig={{ responsive: this.props.responsive }}
        />
      );
    } else if (name === 'multiaxis') {
      chart = (
        <MultiAxisChart
          exportTitle={this.props.title}
          height={this.props.height}
          forceRender={this.props.forceRender}
          chartKey={chartKey}
          chartData={this.props.chartData}
          timeRange={this.props.timeRange}
          legendPosition={this.props.legendPosition}
          enabledLegendItems={this.props.enabledLegendItems}
          className={this.props.className}
          disableDownload={this.props.disableDownload}
        />
      );
    }
    return (
      <div className="chart">
        {this.props.sample ?
          <div className="sample-overlay">SAMPLE</div>
        : null}
        {chart}
      </div>
    );
  }
}

export default Chart;
