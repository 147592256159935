
export const SortOrderMap = {
    ORDER_ASCENDING: 'asc',
    ORDER_DESCENDING: 'desc',
  };


  export const OptionsSource = [
    {
      value: 'totalCalls',
      label: 'All Calls',
    },
    {
      value: 'inbound',
      label: 'Inbound',
    },
    {
      value: 'outbound',
      label: 'Outbound',
    },
    {
      value: 'answered',
      label: 'Answered',
    },
    {
      value: 'missed',
      label: 'Missed',
    },
    {
      value: 'internal',
      label: 'Internal',
    },
  ];


  export const CommonDisplayPreferences = {
    type: 'text',
    display: true,
    resizable: false,
    filterable: false,
  };



  export const ColumnTypes = {
    DIRECTION_ICON: 'directionIcon',
    RECORDING_ICON: 'recordingIcon',
    SOURCE: 'source',
    TRANSFER_USER: 'transferUser',
    USER_ID: 'userId',
    START_TIME: 'startTime',
    CALL_LEG_TALK_DURATION_SECONDS: 'callLegTalkDurationSeconds',
    DIRECTION: 'direction',
    CONNECTED: 'connected',
    CUSTOMER_AREA_CODE_STATE: 'customerAreaCodeState',
    CUSTOMER_NUMBER: 'customerNumber',
    USER_NUMBER: 'userNumber',
    TALK_DURATION_SECONDS: 'talkDurationSeconds',
  };



  export const ListDisplayPreferences = {
    [ColumnTypes.DIRECTION_ICON]: {
      type: 'icon',
      width: 40,
    },
    [ColumnTypes.RECORDING_ICON]: {
      type: 'icon',
      width: 40,
    },
    [ColumnTypes.SOURCE]: {
      width: 120,
    },
    [ColumnTypes.TRANSFER_USER]: {
      width: 150,
    },
    [ColumnTypes.USER_ID]: {
      width: 140,
    },
    [ColumnTypes.START_TIME]: {
      width: 150,
    },
    [ColumnTypes.CALL_LEG_TALK_DURATION_SECONDS]: {
      width: 120,
    },
    [ColumnTypes.DIRECTION]: {
      width: 120,
    },
    [ColumnTypes.CONNECTED]: {
      width: 130,
    },
    [ColumnTypes.CUSTOMER_AREA_CODE_STATE]: {
      width: 100,
    },
    [ColumnTypes.CUSTOMER_NUMBER]: {
      width: 120,
    },
    [ColumnTypes.USER_NUMBER]: {
      width: 120,
    },
    [ColumnTypes.TALK_DURATION_SECONDS]: {
      width: 120,
    },
  };