

import _ from 'lodash';

const Theme = (() => {
  let theme = 'clean';
  const themes = {
    clean: {
      distributed: '#2862D4',
      total: '#B3EE3A',
      total_calls: '#B3EE3A',
      total_talk_time: '#5D7538',
      average_total_talk_time: '#99C25C',
      longest_total_talk_time: '#28311A',
      shortest_total_talk_time: '#DFF5BD',
      inbound: '#FF9912',
      inbound_calls: '#FF9912',
      inbound_talk_time: '#7F5B1E',
      average_inbound_talk_time: '#CC9230',
      longest_inbound_talk_time: '#7F6A44',
      shortest_inbound_talk_time: '#FFD388',
      outbound: '#2856BB',
      outbound_calls: '#2856BB',
      outbound_talk_time: '#0D1B3B',
      average_outbound_talk_time: '#1D3F88',
      longest_outbound_talk_time: '#1F283B',
      shortest_outbound_talk_time: '#607DBB',
      toll_free_i: '#FF7881',
      toll_free: '#FF7881',
      toll_free_calls: '#FF7881',
      toll_free_talk_time: '#7F3C40',
      toll_free_talk_time_i: '#7F3C40',
      average_toll_free_talk_time: '#CC6067',
      longest_toll_free_talk_time: '#7F6264',
      shortest_toll_free_talk_time: '#FFC4C8',
      int_l: '#D22881',
      international_o: '#D22881',
      international: '#D22881',
      international_calls: '#D22881',
      international_talk_time: '#564263',
      international_talk_time_o: '#564263',
      average_international_talk_time: '#9874B0',
      longest_international_talk_time: '#1B161F',
      shortest_international_talk_time: '#DFDAE3',
      answered: '#00D2C1',
      answered_calls: '#00D2C1',
      missed: '#E94B30',
      missed_calls: '#E94B30',
      voicemail: '#FEF3CD',
      calls_per_day: '#FF7881',
      calls_per_user: '#952BD5',
      average_talk_time: '#99C25C',
      avg_talk_time: '#99C25C',
      unique_dials: '#7CCCE5',
      random: ['#F19D3C', '#D7563C', "#CFAD17", '#70D2C1', '#9874B0', '#397ED6', '#3D7915', '#FE7881', '#6994B0', '#99C25C', '#FA9E00', '#C97BA3', '#565BE1', '#3BB0B0', '#E3A49B', '#EEC900', '#056D76', '#369574', '#943CDD', '#98B876', '#D14B3B', '#B89367', '#64C99B', '#62C0DF', '#AB5E44', '#D6AF24', '#C1B764', '#C04821', '#3E8C69', '#117366', '#3358B5', '#256963', '#5C5045', '#32668E', '#FA7809', '#AD3D52', '#10947E', '#328799', '#FB4349', '#247B8F', '#634832', '#85C194', '#9B2557', '#796F15', '#079490'],
      border: null,
      good: '#5FCFC1',
      moderate: '#F19D3C',
      poor: '#D7563C',
      upstream: '#3358B5',
      downstream: '#4B7FD0',
      calls: '#F19D3C',
      active: '#4B7FD0',
      inactive: '#F19D3C',
      ios13: '#4B7FD0',
      ios9: '#94B5EB',
      andriod11: '#6D97DA',
      android: '#F19D3C',
      ios: '#397ED6',
      windows: '#FE7881',
      windows7: '#6D97DA',
      mac: '#2856BB',
      other: '#94B5EB',
      hardphone: '#FE7881',
      connect: '#F19D3C',
      mobile_app: '#397ED6',
      desktop_app: '#2856BB',
      others: '#6AB3D5'
    },
    earthy: {
      total: '#668D3C',
      total_calls: '#668D3C',
      total_talk_time: '#668D3C',
      inbound: '#5169AF',
      inbound_calls: '#5169AF',
      inbound_talk_time: '#5169AF',
      outbound: '#EEC900',
      outbound_calls: '#EEC900',
      outbound_talk_time: '#EEC900',
      toll_free: '#C74A4F',
      toll_free_i: '#C74A4F',
      toll_free_calls: '#C74A4F',
      toll_free_talk_time: '#C74A4F',
      toll_free_talk_time_i: '#C74A4F',
      int_l: '#C97BA3',
      international: '#C97BA3',
      international_o: '#C97BA3',
      international_calls: '#C97BA3',
      international_talk_time: '#C97BA3',
      international_talk_time_o: '#C97BA3',
      answered: '#A74043',
      answered_calls: '#A74043',
      missed: '#334074',
      missed_calls: '#334074',
      voicemail: '#6AB3D6',
      avg_inbound_talk_time: '',
      random: [
        '#5C95B3',
        '#6D954F',
        '#84B363',
        '#A86C8D',
      ],
      border: null,
    },
    dark: {
      total: '#666',
      outbound: '#2863D7',
      inbound: '#FF7429',
      answered: '#2BD632',
      missed: '#FF2927',
      voicemail: '#FDD531',
      toll_free: '#2A98D3',
      international: '#D22881',
      random: [
        '#FFA72C',
        '#F7FF30',
        '#B6E12F',
        '#3E2BD4',
        '#962CD6',
      ],
      border: '#666',
    },
  };
  const options = Object.keys(themes).join(', ');
  const randomColorMap = {
    clean: {},
    earthy: {},
    dark: {},
  };
  let randomIndex = 0;

  const randomColor = (key) => {
    if (randomIndex === themes[theme].random.length) {
      randomIndex = 0;
    }
    key = _.snakeCase(key);
    const color = themes[theme].random[randomIndex++];
    randomColorMap[theme][key] = color;

    return color;
  };

  return {
    getColor(key) {
      key = _.snakeCase(key);
      return themes[theme][key] || randomColorMap[theme][key] || randomColor(key);
    },
    getPalette(key) {
      const colors = [];
      const showOnly = ['inbound', 'outbound', 'toll_free', 'international', 'answered', 'missed', 'voicemail'];
      _.each(themes[key], (v, k) => {
        if (!_.isArray(v) && !_.isNull(v) && showOnly.includes(k)) {
          colors.push(v);
        }
      });

      return colors;
    },
    setTheme(t) {
      if (themes[t.trim().toLowerCase()]) {
        randomIndex = 0;
        theme = t;
        return;
      }
      throw new Error(`Invalid theme: available themes: ${options}`);
    },
    getTheme() {
      return theme;
    },
  };
})();

export default Theme;
