import React from 'react';
import { NavLink } from 'react-router-dom';

import { ReactComponent as Star } from 'img/icons/logo.svg';
import Styles from './Logo.module.scss';

function Logo() {
  return (
    <NavLink
      exact to="/"
      data-testid="header-logo"
      className={Styles.link}
      activeClassName={Styles.active}
    >
      <Star className={Styles.img} />
    </NavLink>
  );
}


export default Logo;
