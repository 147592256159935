import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import cx from 'classnames';
import type from 'type-of';
import ReactTooltip from 'react-tooltip';

import IconDropdown from 'components/icon_dropdown';
import InlineSvgFactory from 'components/inline_svg';
import spinnerWhileLoading from 'enhancers/spinnerWhileLoading';

const Arrow = InlineSvgFactory('arrow.svg');

class DataTable extends React.Component {
  static propTypes = {
    /** Optionally filter row text */
    filterText: PropTypes.string,
    /** Array of row objects for a data table */
    rows: PropTypes.array.isRequired,
    /** The headers for the row of the data table */
    headers: PropTypes.array.isRequired,
    /** Array of the actions that can be performed on the row (available in the
    gear dropdown) */
    actions: PropTypes.array,
    /** Handler for selecting an action on a row*/
    onAction: PropTypes.func.isRequired,
    /** Handler for clicking on the row of the data table */
    onRowClick: PropTypes.func,
    /** Max number of data table rows per page */
    perPage: PropTypes.number,
    /** ClassName of the data table */
    className: PropTypes.string,
    onPageChange: PropTypes.func,
    pagination: PropTypes.object,
    sorting: PropTypes.object,
    useApiPagination: PropTypes.bool,
    onSortingChange: PropTypes.func,
  };

  static defaultProps = {
    perPage: 10,
  };

  constructor(props) {
    super(props);
    this.state = {
      sortBy: null,
      sortDir: 'asc',
      page: props.useApiPagination ? props.pagination.current_page : 0,
      items: this.props.rows,
      totalPages: props.useApiPagination ? props.pagination.total_pages : this.getTotalPages(),
    };
  }

  componentWillReceiveProps(nextProps) {
    const filterChange = nextProps.filterText !== this.props.filterText;
    const items = nextProps.useApiPagination ? nextProps.rows : this.filterItems(nextProps);
    this.setState({
      items,
    });
  }

  getTotalPages = (items) => {
    if (!items) items = this.props.rows;
    if (items.length) {
      return Math.ceil(items.length / (this.props.perPage || 5));
    }
    return 1;
  };

  changePage = (page) => {
    if (this.props.pagination) {
      this.props.onPageChange(page);
    } else {
      this.setState({ page });
    }
  };

  filterItems = (props) => {
    let data = [];

    if (props.filterText) {
      _.each(this.props.rows, (row, index) => {
        const hasSearchText = _.some(this.props.headers, (header) => {
          let value;
          if (header.labelFunc) {
            value = header.labelFunc(row[header.key], index, row);
          } else {
            value = row[header.key];
          }
          if (typeof (value) !== 'string') {
            return false;
          }
          return value.toLowerCase().indexOf(props.filterText.toLowerCase()) !== -1;
        });
        hasSearchText && data.push(row);
      });
    } else {
      data = props.rows;
    }

    return data;
  };

  handleRowClick = (row) => {
    const index = _.findIndex(this.props.rows, { id: row.id });
    this.props.onRowClick(index);
  };

  handleSort = (index) => {
    const key = this.props.headers[index].key;
    let sortDir = (this.props.sorting && this.props.sorting.sortDir) || this.state.sortDir;
    const sortingKey = (this.props.sorting && this.props.sorting.sortBy) || this.state.sortBy;

    if (key === sortingKey) {
      sortDir = sortDir === 'asc' ? 'desc' : 'asc';
    } else {
      sortDir = 'asc';
    }

    const sorting = {
      sortBy: key,
      sortDir,
    };

    if (this.props.useApiPagination) {
      this.props.onSortingChange && this.props.onSortingChange(sorting);
    } else {
      this.setState(sorting);
    }
  };

  getPagination = () => {
    if (this.props.pagination) return this.props.pagination;
    const { page, totalPages } = this.state;

    const next_page = page + 1 === totalPages ? null : page + 1;
    const previous_page = page === 0 ? null : page - 1;

    return {
      current_page: page + 1,
      total_pages: totalPages,
      page_size: this.props.perPage,
      previous_page,
      next_page,
    };
  }

  render() {
    const { previous_page, next_page, page_size: perPage, current_page, total_pages } = this.getPagination();
    let data = this.state.items;
    let rows = [];
    let className = 'data-table paginate-controls';
    const headers = [];
    const allowedKeys = [];
    const showPreviousPageArrow = (this.props.pagination && previous_page) || (!this.props.pagination && previous_page !== null);
    const sortBy = (this.props.sorting && this.props.sorting.sortBy) || this.state.sortBy;
    const sortDir = (this.props.sorting && this.props.sorting.sortDir) || this.state.sortDir;

    const paginationControls = (
      <div className="data-table-pagination" onClick={e => e.stopPropagation()}>
        <Arrow
          className={cx({ 'arrow-left': true, hidden: !showPreviousPageArrow })}
          onClick={() => this.changePage(previous_page)}
        />
        <span>{current_page} / {total_pages}</span>
        <Arrow
          className={cx({ 'arrow-right': true, hidden: !next_page })}
          onClick={() => this.changePage(next_page)}
        />
      </div>
    );
    _.each(this.props.headers, (header, index) => {
      let additionalContent;
      const { sortBy, sortDir } = this.props.sorting ? this.props.sorting : this.state;

      const isSortArrowUp = header.key === sortBy && sortDir === 'asc';

      if (index === this.props.headers.length - 1) {
        additionalContent = paginationControls;
      }

      const th = (
        <th key={index} onClick={this.handleSort.bind(null, index)}>
          <span>{header.label}</span>
          <Arrow
            className={cx(
              {
                arrow: true,
                'arrow-up': isSortArrowUp,
              }
            )}
          />
          {additionalContent}
        </th>
      );

      headers.push(th);
      allowedKeys.push(header.key);
    });

    if (data.length) {
      if (this.state.sortBy && !this.props.useApiPagination) {
        data = _.sortBy(data, (obj) => {
          if (type(obj[this.state.sortBy]) === 'string') {
            return obj[this.state.sortBy].toLowerCase();
          }
          return obj[this.state.sortBy];
        });

        if (this.state.sortDir === 'desc') {
          data = data.reverse();
        }
      }

      if (!this.props.pagination) {
        data = data.slice(this.state.page * perPage, (this.state.page + 1) * perPage);
      }

      rows = _.map(data, (row, trIndex) => {
        const tds = [];
        const keyLength = allowedKeys.length;
        _.each(allowedKeys, (key, index) => {
          let value = '';
          let td = '';
          const labelFunc = this.props.headers[index].labelFunc;
          const tooltip = this.props.headers[index].tooltip;
          if (row[key]) {
            if (labelFunc) {
              value = labelFunc(row[key], trIndex, row);
            } else {
              value = row[key];
            }
            td = value;
            // Checks if Row click and allows click on report name
            if (!this.props.onRowClick) {
              if (index === 0 && (this.props.headers[0].key === 'report_name' || this.props.headers[0].key === 'title')) {
                td = <a className="underline" onClick={this.props.onAction.bind(null, row, { name: 'View' })}>{td}</a>;
              } else if (index === 0 && this.props.headers[0].key === 'name') {
                td = <a className="underline" onClick={this.props.onAction.bind(null, row, { name: 'Edit' })}>{td}</a>;
              }
            }
            if (tooltip) {
              td = <span data-tip={row[key]} data-for="data-table-tooltip">{td}</span>;
            }
          }
          if (index < keyLength - 1) {
            tds.push(<td key={index}>{td}</td>);
          } else {
            tds.push(
              <td key={index}>
                {td}

                {this.props.actions ?
                  <IconDropdown
                    className="pull-right"
                    defaultValue={{ name: 'None', icon: 'gear.svg' }}
                    fixedIcon={{ name: 'Icon', icon: 'gear.svg' }}
                    onEdit={this.props.onAction.bind(null, row)}
                    options={this.props.actions}
                    ariaLabel="Row actions"
                  />
                : null }
              </td>
            );
          }
        });

        const additionalProps = {};
        if (this.props.onRowClick) {
          additionalProps.onClick = () => {
            this.handleRowClick(row);
          };
        }

        return (
          <tr
            data-testid="table-row"
            className={cx({ pointer: !!this.props.onRowClick })}
            key={trIndex}
            {...additionalProps}
          >
            {tds}
          </tr>
        );
      });
    } else {
      const tr = (
        <tr key="not-found">
          <td
            style={{ textAlign: 'center' }}
            colSpan={this.props.headers.length}
          >
            No results
          </td>
        </tr>
      );
      rows.push(tr);
    }

    if (this.props.className) {
      className += ` ${this.props.className}`;
    }

    return (
      <div>
        <table data-testid="table-container" className={className}>
          <thead>
            <tr>
              {headers}
            </tr>
          </thead>
          <tbody>
            {rows}
          </tbody>
        </table>
        <ReactTooltip
          class="data-table-tooltip-container"
          delayShow={0}
          html
          id="data-table-tooltip"
          type="light"
          effect="solid"
        />
      </div>
    );
  }
}

const enhance = spinnerWhileLoading(
  props => props.rows
);
export default enhance(DataTable);
