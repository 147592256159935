import { combineReducers } from 'redux';
import { createForms } from 'react-redux-form';
import { connectRouter } from 'connected-react-router';

import questions from 'modules/questions';
import scheduled_reports from 'modules/scheduled_reports';
import controls from './controls_reducer';
import custom from './custom_reducer';
import team from './team_reducer';
import custom_dashboard from './custom_dashboard_reducer';
import user_management from './user_management_reducer';
import user from './user_reducer';
import questions_groups from './question_groups';

const initialQuestionGroup = {
  title: '',
  description: '',
  questions: [],
};

const initialQuestion = {
  type: 'scale',
  text: '',
};

const initialQuestionGroupResponse = {
  answers: [],
};

export default (history) => combineReducers({
  controls,
  custom,
  team,
  custom_dashboard,
  user,
  user_management,
  questions_groups,
  ...createForms({
    questions_group: initialQuestionGroup,
    questions_group_response: initialQuestionGroupResponse,
    [scheduled_reports.constants.NAME]: scheduled_reports.reducer,
  }),
  [questions.constants.NAME]: questions.reducer,
  router: connectRouter(history),
});
