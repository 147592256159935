import { Schema, arrayOf } from 'normalizr';

export const questionGroup = new Schema('questionGroups');
export const questions = new Schema('questions');

questionGroup.define({
  questions: arrayOf(questions),
});

export const questionResponse = new Schema('questionResponses');
