import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Collapse } from 'components';
import QualityTrendPanels from './quality_trend_panels';
import ReportPageChart from 'components/charts/report_page_chart';
import AttentionTable from './attention_table.js';

class QualitySummary extends React.Component {
  static propTypes = {
    actions: PropTypes.object,
    state: PropTypes.object,
  };

  shouldComponentUpdate(nextProps, nextState) {
    const propsChanged = !_.isEqual(nextProps, this.props);
    const stateChanged = !_.isEqual(nextState, this.state);

    return propsChanged || stateChanged;
  }

  render() {
    const { state } = this.props;
    let chartData = [
      {
        charts: [
          {icon: "bar.svg", name: "bar", type: "serial"}
        ],
        detail_period_options: [{id: "summary", label: "summary"}],
        label: "MOS Performance",
        size: "half",
        units: ["mos"],
        legends:["Good", "Moderate", "Poor"],
        unitSelects: {"mos": [
          {label: "Core MOS", metric: "core"},
          {label: "Up MOS", metric: "up"},
          {label: "Down MOS", metric: "down"},
        ]},
        timeout: 0,
        url: "/nextos/reports/quality/mos/group-by-period"
      },
      {
        charts: [
          {icon: "line.svg", name: "line", type: "serial"},
        ],
        detail_period_options: [{id: "day", label: "Daily"}],
        label: "Daily MOS Trend",
        size: "half",
        popoutButton: true,
        units: ["mos"],
        unitSelects: {"mos": [
          {label: "Total", metric: "total"}
        ]},
        timeout: 5,
        url: "/nextos/reports/quality/mos/avg"
      },
      {
        charts: [
          {icon: "bar.svg", name: "column", type: "serial"},
        ],
        detail_period_options: [{id: "summary", label: "summary"}],
        label: "End Points",
        size: "full",
        units: ["mos"],
        legends:["Calls", "Upstream", "DownStream"],
        unitSelects: {"mos": [
          {label: "App Mobile", metric:"mos"},
          {label: "App Desktop", metric:"mos"},
          {label: "Phone Mobile", metric: "mos"}
        ]},
        timeout: 10,
        url: "/nextos/reports/quality/mos/endpoints"
      }
    ];

    let charts = null;
    // when the controls switch there is a multiple-rendering as the state there
    // updates the filters from {location: [...]} back to empty - wait for that
    // to render.
    if((_.isEmpty(state.apiFilters) === true) && (state.section === 'summary')) {
      charts = chartData.map(function(chart, index) {
        return (
          <div key={index} className={`col-${chart.size}`}>
            <ReportPageChart
              timeout={chart.timeout}
              charts={chart.charts}
              url={chart.url}
              units={chart.units}
              hideUnits={true}
              unitSelects={chart.unitSelects}
              title={chart.label}
              timeRange={state.timeRange}
              realTime={state.realTime}
              detailPeriodOptions={chart.detail_period_options}
              legends={chart.legends && chart.legends}
            />
          </div>
        );
      });
    }

    return (
      <div className="analytics">
        <Collapse arrowClass={'panel-collapse-arrow'} disableAnimation>
          <QualityTrendPanels timeRange={state.timeRange} />
        </Collapse>
        <div className="reports">
          {charts}
        </div>
        <AttentionTable state={this.props.state}/>
      </div>
    );
  }
}

export default QualitySummary;