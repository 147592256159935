import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Control, actions } from 'react-redux-form';
import moment from 'moment';
import BEMHelper from 'react-bem-helper';

import Button from 'components/button';
import WeekdayCheckboxes from './weekday_checkboxes';
import HoursMinutesInput from './hours_minutes_input';
import TimeRange from './time_range';
import DateTime from './date_time';
import { checkDateValue, isHour, isMinute } from '../validation';

const classes = new BEMHelper('scheduled-report-form');
const DIST_TIME_LIMIT = 5;

function getIntervals(frequency) {
  switch (frequency) {
    case 'minutes':
      return [15, 30];
    case 'hourly':
      return [...Array(12).keys()].map((x) => x * 5);
    case 'daily':
      return [...Array(366).keys()].map((x) => ++x);
    case 'weekly':
      return [...Array(52).keys()].map((x) => ++x);
    case 'monthly':
      return [...Array(31).keys()].map((x) => ++x);
    case 'monthly-count':
      return [...Array(12).keys()].map((x) => ++x);
    default:
      return [];
  }
}

function getIntervalOptions(frequency) {
  const intervals = getIntervals(frequency);
  return intervals.map((interval) => {
    let label = interval;
    if (frequency === 'hourly') {
      label = `${interval}th`;
    }
    return <option key={interval} value={interval}>{label}</option>;
  });
}

function getIntervalText(frequency) {
  switch (frequency) {
    case 'minutes':
      return 'minutes';
    case 'hourly':
      return 'minute';
    case 'daily':
      return 'day(s)';
    case 'weekly':
      return 'week(s)';
    default:
      return '';
  }
}

function handleWeekdayChange(model, value) {
  const { days } = value;
  return (dispatch) => {
    dispatch(actions.change(model, days));
  };
}

const Frequency = (props) => (
  <div {...classes('field')}>
    <label {...classes('label')}>Frequency</label>
    <Control.select
      model=".frequency"
      required
    >
      <option value="minutes">Minutes</option>
      <option value="hourly">Hourly</option>
      <option value="daily">Daily</option>
      <option value="weekly">Weekly</option>
      <option value="monthly">Monthly</option>
      <option value="one_time">One Time</option>Save Changes
    </Control.select>
  </div>
);

const Intervals = ({ frequency }) => (
  <div {...classes('field', 'intervals')}>
    <label {...classes('label')}>Intervals</label>
    <div {...classes('interval')}>
      <span>every</span>
      <Control.select
        model=".interval"
        required
      >
        {getIntervalOptions(frequency)}
      </Control.select>
      <span>{getIntervalText(frequency)}</span>
    </div>
  </div>
);

Intervals.propTypes = {
  frequency: PropTypes.string,
};

const RunUntil = ({ run }) => (
  <div {...classes('field')}>
    <label {...classes('label')}>Run until</label>
    <DateTime
      model=".run"
      value={run.value}
      options={[
        { key: 'forever', label: 'Forever' },
      ]}
      minDate={moment()}
    />
  </div>
);

RunUntil.propTypes = {
  run: PropTypes.object,
};

const Weekdays = ({ weekdays }) => (
  <div {...classes('field', 'weekdays')}>
    <label {...classes('label')} />
    <div {...classes('weekdays')}>
      <span>on</span>
      <Control
        model=".distributeWeekdays"
        component={WeekdayCheckboxes}
        changeAction={handleWeekdayChange}
        controlProps={{
          days: weekdays,
          showTime: false,
        }}
        validators={{
          required: (val) => Object.values(val).filter((x) => x).length > 0,
        }}
      />
    </div>
  </div>
);

Weekdays.propTypes = {
  weekdays: PropTypes.object,
};

const HourMinute = ({ model, modifier }) => (
  <div {...classes('hour-minute', modifier)}>
    <span>at</span>
    <HoursMinutesInput
      hourModel={`${model}.hours`}
      minuteModel={`${model}.minutes`}
      meridiemModel={`${model}.meridiem`}
    />
  </div>
);

HourMinute.propTypes = {
  model: PropTypes.string,
  modifier: PropTypes.string,
};

const DistributionTimes = ({ times, onDelete, onAdd }) => (
  <div {...classes('distribution-times')}>
    {times.map((time, index) => (
      <div key={index} {...classes('distribution-time')}>
        <HourMinute model={`.times[${index}]`} />
        <Button {...classes('button', '', 'btn')} onClick={() => onDelete(index)}>
          <span className="icon-custom-trash-can" />
        </Button>
      </div>
    ))}
    {times.length < DIST_TIME_LIMIT ? <Button {...classes('button', 'add-time', 'btn btn-green')} onClick={onAdd}>+</Button> : null }
  </div>
);

DistributionTimes.propTypes = {
  times: PropTypes.array,
  onDelete: PropTypes.func,
  onAdd: PropTypes.func,
};

const OneTime = ({ start }) => (
  <div {...classes('field')}>
    <label {...classes('label')}>
      Distribution Time
    </label>
    <DateTime
      model=".distributeStart"
      value={start.value}
      minDate={moment()}
    />
  </div>
);

OneTime.propTypes = {
  start: PropTypes.object,
};

const Monthly = (props) => (
  <div {...classes('monthly')}>
    <div {...classes('monthly-option')}>
      <div {...classes('radio')}>
        <Control.radio model=".monthly.dist" value="day" />
        <label>the</label>
      </div>
      <Control.select model=".monthly.num">
        {getIntervalOptions('monthly')}
        <option value="last">Last</option>
      </Control.select>
      <span>day of every</span>
      <Control.select model=".monthly.month">
        {getIntervalOptions('monthly-count')}
      </Control.select>
      <span>month(s)</span>
    </div>
    <div {...classes('monthly-option')}>
      <div {...classes('radio')}>
        <Control.radio model=".monthly.dist" value="weekday" />
        <label>the</label>
      </div>
      <Control.select model=".monthly.num">
        {getIntervalOptions('monthly')}
        <option value="last">Last</option>
      </Control.select>
      <Control.select model=".monthly.weekday">
        <option value="mon">Monday</option>
        <option value="tue">Tuesday</option>
        <option value="wed">Wednesday</option>
        <option value="thu">Thursday</option>
        <option value="fri">Friday</option>
        <option value="sat">Saturday</option>
        <option value="sun">Sunday</option>
      </Control.select>
      <span>of every</span>
      <Control.select model=".monthly.month">
        {getIntervalOptions('monthly-count')}
      </Control.select>
      <span>month(s)</span>
    </div>
  </div>
);

class Distribution extends Component {
  renderDistributionOptions() {
    const {
      frequency,
      run,
      distributeWeekdays,
      distributeStart,
      distributeEnd,
      times,
      handleAddDistributionTime,
      handleRemoveDistributionTime,
    } = this.props;
    switch (frequency) {
      case 'minutes':
        return [
          <Frequency key="frequency" frequency={frequency} />,
          <Intervals key="intervals" frequency={frequency} />,
          <TimeRange key="time-range" startModel=".distributeStart" endModel=".distributeEnd" />,
          <Weekdays key="weekdays" weekdays={distributeWeekdays} />,
          <RunUntil key="run" run={run} />,
        ];
      case 'daily':
        return [
          <Frequency key="frequency" frequency={frequency} />,
          <Intervals key="intervals" frequency={frequency} />,
          <DistributionTimes key="distribution" times={times} onAdd={handleAddDistributionTime} onDelete={handleRemoveDistributionTime} />,
          <RunUntil key="run" run={run} />,
        ];
      case 'hourly':
        return [
          <Frequency key="frequency" frequency={frequency} />,
          <Intervals key="intervals" frequency={frequency} />,
          <TimeRange key="time-range" startModel=".distributeStart" endModel=".distributeEnd" />,
          <Weekdays key="weekdays" weekdays={distributeWeekdays} />,
          <RunUntil key="run" run={run} />,
        ];
      case 'weekly':
        return [
          <Frequency key="frequency" frequency={frequency} />,
          <Intervals key="intervals" frequency={frequency} />,
          <DistributionTimes key="distribution" times={times} onAdd={handleAddDistributionTime} onDelete={handleRemoveDistributionTime} />,
          <Weekdays key="weekdays" weekdays={distributeWeekdays} />,
          <RunUntil key="run" run={run} />,
        ];
      case 'monthly':
        return [
          <Frequency key="frequency" frequency={frequency} />,
          <Monthly key="monthly" />,
          <HourMinute key="hour-minute" model=".distributeStart" modifier="distribute-time" />,
          <RunUntil key="run" run={run} />,
        ];
      case 'one_time':
        return [
          <Frequency key="frequency" frequency={frequency} />,
          <OneTime key="one-time" start={distributeStart} />,
        ];
      default:
        return <Frequency frequency={frequency} />;
    }
  }

  render() {
    const { frequency, run } = this.props;
    return (
      <div {...classes('distribution')}>
        {this.renderDistributionOptions()}
      </div>
    );
  }
}

Distribution.propTypes = {
  frequency: PropTypes.string,
  run: PropTypes.object,
  distributeWeekdays: PropTypes.object,
  distributeStart: PropTypes.object,
  distributeEnd: PropTypes.object,
  times: PropTypes.array,
  handleAddDistributionTime: PropTypes.func,
  handleRemoveDistributionTime: PropTypes.func,
};

export default Distribution;
