const Aggregate = function (ui) {
  this.ui = ui;
  this.params = { ui };
  const params = this.params;

  this.periodSummary = () => {
    params.rand = this.aggregateRand;

    if (this.buckets) {
      params.tableLabels = ['Call Type', 'Buckets'];
      params.selects = this.getBuckets();
      params.xGrouping = this.getSelects(0);
    } else {
      params.tableLabels = ['Date Range', 'Call Type'];
      params.selects = this.getSelects(0);
    }
  };

  this.dailySummary = () => {
    params.rand = this.dailyRand;
    params.dateFormat = 'YYYY-MM-DD';

    if (this.buckets) {
      params.tableLabels = ['Date', 'Buckets'];
      params.xGrouping = this.generateDates();
      params.selects = this.getBuckets();
    } else {
      params.tableLabels = ['Date', 'Call Type'];
      params.xGrouping = this.generateDates();
      params.dateFormat = 'YYYY-MM-DD';
      params.selects = this.getSelects(0);
    }
  };

  this.dailyAverage = () => {
    params.rand = this.averageRand;
    if (this.buckets) {
      params.tableLabels = ['Call Type', 'Buckets'];
      params.selects = this.getBuckets();
      params.xGrouping = this.getSelects(0);
    } else {
      params.tableLabels = ['Date Range', 'Call Type'];
      params.selects = this.getSelects(0);
    }
  };

  this.hourlyAverage = () => {
    params.rand = this.averageRand;
    params.xGrouping = this.generateHours();
    if (this.buckets) {
      params.selects = this.getBuckets();
    } else {
      params.selects = this.getSelects(0);
    }
  };
};

export default Aggregate;
