import ReactGA from 'react-ga';

import AppData from 'app_data';
import Constants from 'constants.js';

const {
  REACT_APP_DEV,
  REACT_APP_GA_ID,
} = process.env;

function loadUser() {
  return (dispatch, getState) => {
    AppData.loadUserData()
      .then((response) => {
        const { data } = response;
        dispatch({ type: Constants.USER_LOGIN_SUCCESS, data });
        initializeGA(data);
      }, (error) => {
        dispatch({ type: Constants.USER_LOGIN_FAILURE, error });
      });
  };
}

const gaIds = JSON.parse(REACT_APP_GA_ID);

function initializeGA(userData) {
  ReactGA.initialize(gaIds[window.NextivaEnv], {
    debug: REACT_APP_DEV === 'true',
    gaOptions: {
      userId: userData.user_id,
    },
  });
}

export default loadUser;
