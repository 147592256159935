import React, { Component } from 'react';
import PropTypes from 'prop-types';
import validator from 'validator';

class CallLogExportModal extends Component {
  static propTypes = {
    /** Handler for when exporting */
    onExport: PropTypes.func,
    /** Handler for canceling the export */
    onCancel: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      emailError: false,
    };
  }

  handleSend = () => {
    const email = this.refs.email.value;

    if (!validator.isEmail(email.trim())) {
      return this.setState({
        emailError: true,
      });
    }

    this.props.onExport(email);
  }

  render() {
    let emailError;
    if (this.state.emailError) {
      emailError = (
        <div className="error-text">Invalid Email</div>
      );
    }

    return (
      <div className="call-log-popover-content">
        <h2>Send exported data to:</h2>
        <div className="error-container">
          {emailError}
        </div>
        <input ref="email" type="text" className="input" placeholder="email" />
        <div className="buttons pull-right">
          <button
            onClick={this.props.onCancel}
            className="btn cancel"
          >
            Cancel
          </button>
          <button
            onClick={this.handleSend}
            className="btn btn-green send"
          >
            Send
          </button>
        </div>
      </div>
    );
  }
}

export default CallLogExportModal;
