import React from 'react';
import { Control } from 'react-redux-form';
import BEMHelper from 'react-bem-helper';

import { required, validateEmail } from '../validation';

const classes = new BEMHelper('scheduled-report-form');

const Delivery = (props) => (
  <div {...classes('delivery')}>
    <div {...classes('field')}>
      <label {...classes('label')}>Email addresses</label>
      <Control.text
        model=".email"
        placeholder="Enter email"
        updateOn={['blur']}
        validateOn={['blur', 'change']}
        validators={{
          required,
          validEmail: validateEmail,
        }}
      />
      <span {...classes('tip')}>
        For multiple addresses, use a comma to separate.
      </span>
    </div>
  </div>
);

export default Delivery;
