import React from 'react';
import { branch, renderComponent } from 'recompose';
import { DefaultSpinner } from 'components';

const Spinner = () => (
  <DefaultSpinner />
);

const spinnerWhileLoading = hasLoaded =>
  branch(
    props => !hasLoaded(props),
    renderComponent(Spinner),
    component => component
  );

export default spinnerWhileLoading;
