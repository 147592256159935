import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import moment from 'moment';
import vex from 'lib/vex';
import _ from 'lodash';

import DataTable from 'components/data_table';
import AppData from 'app_data';
import InlineSvgFactory from 'components/inline_svg';

const Plus = InlineSvgFactory('plus.svg');

class Saved extends React.Component {

  static propTypes = {
    push: PropTypes.func,
  };

  state = {
    filterText: '',
    rows: null,
  };

  componentWillMount() {
    this.fetchReports();
  }

  setFilterText = (e) => {
    this.setState({
      filterText: e.target.value,
    });
  };

  fetchReports = () => {
    AppData.getCustomReports().then((response) => {
      const { data } = response;
      this.setState({ rows: data });
    });
  };

  handleAction = (reportObj, action) => {
    const { push } = this.props;

    if (action.name === 'Edit') {
      push(`/reporting/custom/${reportObj.report_id}`);
    } else if (action.name === 'View') {
      push(`/custom/${reportObj.report_id}`);
    } else if (action.name === 'Delete') {
      vex.warning(`Are you sure you want to delete ${reportObj.report_name}`,
        (confirmed) => {
          if (confirmed) {
            AppData.deleteCustomReport(reportObj.report_id).then(() => {
              window.location.reload();
            });
          }
        }
      );
    }
  };

  handleNewReport = () => {
    this.props.push('/reporting/custom');
  };

  render() {
    const content = (
      <DataTable
        headers={[
          { key: 'report_name', label: 'Report Name', labelFunc: (value) => _.truncate(value, { length: 40 }), tooltip: true },
          { key: 'creator', label: 'Creator', labelFunc: (value) => _.truncate(value, { length: 40 }), tooltip: true },
          { key: 'created', label: 'Created', labelFunc: (value) => moment(value).format('L').toString() },
        ]}
        rows={this.state.rows}
        filterText={this.state.filterText}
        onAction={this.handleAction}
        actions={[{ name: 'Edit' }, { name: 'View' }, { name: 'Delete' }]}
        perPage={10}
      />
    );

    return (
      <div className="save-schedule">
        <div className="schedule-header">
          <h1>Saved reports</h1>

          <div className="schedule-controls">
            <input
              data-testid="saved-search"
              className="search"
              type="search"
              placeholder="Search"
              onChange={this.setFilterText}
              value={this.state.filterText}
            />
            <div
              onClick={this.handleNewReport}
              className="btn btn-green pull-right new-report"
            >
              Custom Report <Plus className="new-report-plus" />
            </div>
          </div>
        </div>

        {content}
      </div>
    );
  }
}

export default connect(
  null,
  { push },
)(Saved);
