import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { mapStateToProps, mapDispatchToProps } from 'modules/monitoring/custom_dashboard/connector';

import trash from 'img/trash.png';

class TileOptionsContainer extends PureComponent {
  static propTypes = {
    onRemoveItem: PropTypes.func,
    onCancel: PropTypes.func,
    validate: PropTypes.func,
    pages: PropTypes.array,
    tileInfo: PropTypes.object,
    onUpdate: PropTypes.func,
    customDashboardActions: PropTypes.object,
    custom_dashboard: PropTypes.object,
  };

  onCancelClick = (currentPage) => {
    const { onCancel } = this.props;
    if (currentPage !== 0) {
      this.previousPage(currentPage);
    } else {
      onCancel();
    }
  };

  getAdvanceButton = (currentPage) => {
    const { pages } = this.props;
    if (currentPage === (pages.length - 1)) {
      return (
        <input className="btn btn-green" type="button" value="Update" onClick={this.validate.bind(this, currentPage)} />
      );
    }
    return (
      <input className="btn btn-blue" type="button" value="Next" onClick={this.nextPage.bind(this, currentPage)} />
    );
  };

  validate = (currentPage) => {
    const { validate, onUpdate } = this.props;
    const isValid = validate(currentPage);
    if (isValid) {
      onUpdate();
    }
  };

  nextPage = (page) => {
    const { validate } = this.props;
    const isValid = validate(page);
    if (isValid) {
      this.props.customDashboardActions.setOptionsPage(page + 1);
    }
  };

  previousPage = (page) => {
    this.props.customDashboardActions.setOptionsPage(page - 1);
  };

  render() {
    const {
      pages,
      onRemoveItem,
      custom_dashboard: uiState,
    } = this.props;

    const currentPage = uiState.tile_options_page;
    const currentPageElement = pages[currentPage]();
    const advanceButton = this.getAdvanceButton(currentPage);

    return (
      <div className="custom_dashboard_popover_content">
        {currentPageElement}

        <div className="custom_dashboard_tile_buttons">
          <button className="btn trash_btn" onClick={onRemoveItem}>
            <img alt="Remove" src={trash} />
          </button>

          <input className="btn" type="button" value="Cancel" onClick={this.onCancelClick.bind(this, currentPage)} />
          {advanceButton}
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TileOptionsContainer);
