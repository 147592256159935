import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import type from 'type-of';
import Resizable from 're-resizable';
import cx from 'classnames';

import Chart from 'components/charts/chart';

class ResizableChart extends Component {
  static propTypes = {
    /** An object to handle the style of the chart */
    handleStyle: PropTypes.object,
    /** An object with the class name of the chart */
    handleClass: PropTypes.object,
    /** The initial height */
    initialHeight: PropTypes.number,
    /** The minimum height */
    minHeight: PropTypes.number,
    /** The maximum height */
    maxHeight: (props, propName) => {
      const { minHeight, [propName]: maxHeight } = props;
      if (!maxHeight) {
        return;
      } else if (type(maxHeight) !== 'number') {
        return new Error(`${propName} should be a number.`);
      } else if (maxHeight < minHeight) {
        return new Error(`${propName} should be greater than or equal to minHeight.`);
      }
    },
    /** Handler for when a chart is resized  */
    onChartResize: PropTypes.func,
    /** Enabled resizing on different sides */
    resizable: PropTypes.object,
    style: PropTypes.object,
  }

  static defaultProps = {
    minHeight: 200,
    resizable: {
      top: false,
      right: false,
      bottom: true,
      left: false,
      topRight: false,
      bottomRight: false,
      bottomLeft: false,
      topLeft: false,
    },
    handleClass: {
      bottom: cx({ 'resize-icon': true, 'icon-custom-vertical-resize': true }),
    },
    handleStyle: {
      bottom: {
        'height': '15px',
      },
    },
    style: {
      overflow: 'hidden',
      paddingBottom: '20px',
    },
  }

  constructor(props) {
    super(props);

    this.state = {
      height: props.initialHeight || 600,
    };
    this.debouncedHandleResize = _.debounce((...args) => {
      this.handleResize(...args);
    }, 50);
  }

  handleResize = (direction, styleSize, clientSize) => {
    const { offsetHeight } = clientSize;
    this.setState({
      height: offsetHeight,
    });
  };

  handleResizeStop = (direction, styleSize, clientSize) => {
    const { onChartResize } = this.props;
    const { offsetHeight } = clientSize;

    this.setState({
      height: offsetHeight,
    });

    if (onChartResize) {
      onChartResize(offsetHeight);
    }
  };

  render() {
    const {
      style,
      minHeight,
      maxHeight,
      resizable,
      handleClass,
      handleStyle,
      ...chartProps
    } = this.props;
    return (
      <Resizable
        minHeight={minHeight}
        maxHeight={maxHeight}
        onResize={this.debouncedHandleResize}
        onResizeStop={this.handleResizeStop}
        enable={resizable}
        handleClasses={handleClass}
        handleStyles={handleStyle}
        style={style}
      >
        <Chart
          {...chartProps}
          height={`${(this.state.height - 15)}px`}
        />
      </Resizable>
    );
  }
}

export default ResizableChart;
