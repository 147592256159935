import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import _ from 'lodash';

import ValidationMixin from 'components/validation';

class HoursMinutesInput extends Component {
  static propTypes = {
    /** Hour of the day in two digits (12, 01, 02, etc..) */
    hours: PropTypes.string,
    /** Minutes of the hour in two digits (09, 10, 11) */
    minutes: PropTypes.string,
    /** am or pm */
    meridiem: PropTypes.string,
    /** Handler for when the hour and minute input is changed */
    onChange: PropTypes.func,
    /** Set the validation error messages */
    addErrorMessages: PropTypes.func,
    /** Validate specific field */
    validate: PropTypes.func,
    /** Validate all input fields with validations */
    validateAll: PropTypes.func,
    /** Form that keeps validation errors */
    form: PropTypes.shape({
      /** Contains validation erros for fields */
      errorMap: PropTypes.object,
    }),
    /** Validation messages to display */
    errors: PropTypes.object,
  };

  state = {};

  handleTimeChange = () => {
    const result = {};
    _.each(_.keys(this.refs), (ref) => {
      result[ref] = this.refs[ref].value;
    });
    this.props.onChange(result);
  };

  render() {
    const {
      validate,
      errors,
      hours,
      minutes,
      meridiem,
    } = this.props;

    return (
      <span className="hours-minutes">
        <input
          className={cx({ hours: true, error: errors.hoursError })}
          ref="hours"
          type="text"
          data-validations="required,isInt:1:12"
          maxLength="2"
          onChange={this.handleTimeChange}
          onBlur={validate.bind(this, 'hours')}
          value={hours}
        />
        <span className="colon">:</span>
        <input
          className={cx({ minutes: true, error: errors.minutesError })}
          ref="minutes"
          type="text"
          data-validations="required,isInt:0:59"
          maxLength="2"
          onChange={this.handleTimeChange}
          onBlur={validate.bind(this, 'minutes')}
          value={minutes}
        />
        <select
          className="meridiem"
          ref="meridiem"
          onChange={this.handleTimeChange}
          value={meridiem}
        >
          <option value="am">AM</option>
          <option value="pm">PM</option>
        </select>
      </span>
    );
  }
}

export default ValidationMixin(HoursMinutesInput);
