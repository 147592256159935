import React, { Component } from 'react';
import PropTypes from 'prop-types';

import AppData from 'app_data';
import { Collapse } from 'components';

class QuestionResponses extends Component {
  static propTypes = {
    questionId: PropTypes.string,
  };

  state = {
    rows: null,
  };

  componentDidMount() {
    this.fetchQuestionGroupResponses();
  }

  fetchQuestionGroupResponses = () => {
    const { questionId } = this.props;
    AppData.getQuestionGroupResponses(questionId).then((response) => {
      const { data } = response;
      this.setState({ rows: data });
    });
  };

  renderResponses = () => {
    const { rows } = this.state;
    if (!rows) {
      return;
    }

    if (rows.length === 0) {
      return (
        <div>No data</div>
      );
    }

    return rows.map((row, index) => (
      <Collapse
        key={row.id}
        initialCollapsed
        header={`Response ${index + 1}`}
      >
        <div>
          <pre>
            {JSON.stringify(row, null, 2)}
          </pre>
        </div>
      </Collapse>
    ));
  };

  render() {
    return (
      <div className="question-responses-list">
        <div className="question-responses-list__header title">
          <h1>Question group responses</h1>
        </div>
        <div className="question-responses-list__content">
          {this.renderResponses()}
        </div>
      </div>
    );
  }
}

export default QuestionResponses;
