import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { DateRangePicker } from '@nextiva/analytics-ui';

import { bindControlsActions } from 'actions/controls_actions';
import Checkbox from 'components/checkbox';
import MultiSelect from 'components/multi_select';
import ListSelect from 'components/list_select';
import TabbedControls from 'components/tabbed_controls';
import ButtonPopover from 'components/button_popover';
import * as selectors from 'modules/monitoring';

const mapStateToProps = (state) => {
  const scorecardsState = selectors.scorecardsSelector(state);
  return {
    filterType: scorecardsState.filterType,
    filterTypeOptions: scorecardsState.filterTypeOptions,
    filters: scorecardsState.filters,
    filterOptions: scorecardsState.filterOptions,
    callTypes: scorecardsState.callTypes,
    callTypeOptions: scorecardsState.callTypeOptions,
    realTime: scorecardsState.realTime,
    timeRange: scorecardsState.timeRange,
    timezone: scorecardsState.timezone,
    grouping: scorecardsState.grouping,
    periodOptions: scorecardsState.periodOptions,
    period: scorecardsState.period,
  };
};

class ScorecardControls extends Component {
  static propTypes = {
    /** Current filter type */
    filterType: PropTypes.object,
    /** Available filter type options */
    filterTypeOptions: PropTypes.array,
    /** Selected filters */
    filters: PropTypes.array,
    /** Availble filters */
    filterOptions: PropTypes.array,
    /** Selected call types */
    callTypes: PropTypes.array,
    /** Available call types */
    callTypeOptions: PropTypes.array,
    /** Are we in real time mode? */
    realTime: PropTypes.bool,
    /** Time range for the section */
    timeRange: PropTypes.object,
    /** User timezone */
    timezone: PropTypes.string,
    /** Whether or not to group custom groups */
    grouping: PropTypes.bool,
    /** Current period to display */
    period: PropTypes.object,
    /** Period options */
    periodOptions: PropTypes.array,
    push: PropTypes.func,
  };

  componentWillMount() {
    this.actions = bindControlsActions('scorecards');
  }

  linkToGroups = () => {
    this.props.push('/settings/groups');
  };

  render() {
    const {
      filterType,
      filterTypeOptions,
      filters,
      filterOptions,
      callTypes,
      callTypeOptions,
      realTime,
      timeRange,
      timezone,
      grouping,
      periodOptions,
      period,
    } = this.props;

    const filterTypes = (
      <div>
        <ListSelect
          options={filterTypeOptions}
          onSelect={this.actions.changeFilterType}
          selected={filterType}
          compareField={filterType.id !== 'teams' ? 'id' : 'teamType'}
        />
        <div className="grouped-checkbox">
          {filterType.id === 'teams' ?
            <Checkbox
              label={'Grouped'}
              onChange={this.actions.setGrouping}
              checked={grouping}
            />
            : null }
        </div>
      </div>
    );
    let element = null;
    if (filterType && (filterType.bucketType === 'teams')) {
      element = (
        <div className="group-link-small" key={'scorecards-element'}>
          <div className="multi-select-item">
            <span className="icon-custom-plus" />
            <a onClick={this.linkToGroups}>Create A New Custom Group</a>
          </div>
          <hr />
        </div>
        );
    }
    const filtersSelect = (
      <MultiSelect
        searchBar
        options={filterOptions}
        onChange={this.actions.setFilters}
        initialSelected={filters}
        displayElement={element}
      />
    );
    const periodsSelect = (
      <ListSelect
        options={periodOptions}
        onSelect={this.actions.setPeriod}
        selected={period}
      />
    );
    const tabbedControls = (
      <TabbedControls
        tabs={[
          { icon: 'icon-custom-people', content: filterTypes },
          { icon: 'icon-custom-filter', content: filtersSelect },
          { icon: 'icon-custom-indented-list', content: periodsSelect },
        ]}
      />
    );
    const buttonPopover = (
      <ButtonPopover
        popoverClass="controls-popover scorecard-controls-popover"
        iconClass="icon-custom-settings"
        popoverBody={tabbedControls}
      />
    );

    return (
      <div
        className={'scorecards-monitoring-controls monitoring-controls'}
      >
        <DateRangePicker
          className="date-range-popover"
          isRealTime={realTime}
          timezone={timezone}
          dateRange={timeRange}
          onChange={this.actions.setTimeRange}
          onRealTimeChange={this.actions.setRealTime}
          theme={{
            dateRangePicker: 'date-range',
          }}
        />
        {buttonPopover}
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  { push },
)(ScorecardControls);
