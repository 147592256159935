import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { NavLink } from 'react-router-dom';
import ReactTimeout from 'react-timeout'; // TODO: lodash => debounce

import axios from 'axios_client';
import AppData from 'app_data';
import Theme from 'theme';
import Utils from 'utils/common_utils';


class CallFilters extends Component {
  static propTypes = {
    /** The time range being represented by the table */
    timeRange: PropTypes.object.isRequired,
    /** Whether or not the data is in real time */
    realTime: PropTypes.bool.isRequired,
    /** The filter option */
    filter: PropTypes.string.isRequired,
    /** An array of all filtering options */
    filterOptions: PropTypes.array.isRequired,
    /** The section being displayed */
    section: PropTypes.string.isRequired,
    /** The URL for the data with filters */
    filterTotalsUrl: PropTypes.string.isRequired,
    /** The filters for the call log */
    filters: PropTypes.object,
  };

  state = {
    values: null,
    intervalID: null,
  };

  componentDidMount() {
    this.updateFilterValues();
  }

  componentWillUnmount() {
    if(this.state.intervalID) {
      clearInterval(this.state.intervalID);
    }
  }

  /// TODO: componentDidUpdate is preferred now, this is deprecated.
  componentWillReceiveProps(newProps) {
    // update on any new props except a filter change
    const filterChange = newProps.filter !== this.props.filter;
    const sectionChange = newProps.section !== this.props.section;

    if (!filterChange || !sectionChange) {
      this.updateFilterValues(newProps);
    }
  }

  fetchValues = (props, filterQuery) => {
    axios.post(props.filterTotalsUrl, filterQuery).then((response) => {
      const { data } = response;
      this.setState({ values: data.totals });
    });
  };

  updateFilterValues = (props) => {
    props = props || this.props;
    const filterQuery = {
      selects: _.map(props.filterOptions, (filter) => (
        { metric: filter.name, label: filter.label }
      )),
      unit: 'calls',
      time_range: props.timeRange,
      filters: props.filters,
    };

    this.setState({ values: null });

    if (props.realTime) {
      this.fetchValues(props, filterQuery);

      if(!this.state.intervalID) {
        const intervalID = window.setInterval(() => {
          this.fetchValues(props, filterQuery);
        }, AppData.user.refresh_rate * 1000);

        this.setState({ intervalID: intervalID });
      }	      
    } else {
      if(this.state.intervalID) {
        clearInterval(this.state.intervalID);
        this.setState({ intervalID: null });
      }
      this.fetchValues(props, filterQuery);
    }
  };

  render() {
    const { pathname } = this.props;
    const path = pathname.split('/');

    const callFilters = _.map(this.props.filterOptions, (_item, index) => {
      const item = _.assign({}, _item);
      const values = this.state.values;
      let value = values && values[item.label];
      if (value) {
        value = Utils.numberWithCommas(value);
      }

      if (item.name === 'total') item.route = '';

      return (
        <li key={index}>
          <NavLink exact to={`/reporting/${this.props.section}/${item.route}`} activeClassName="active">
            <div className={!path[3] && !item.route ? 'bold' : null}>{item.label}</div>
            <div style={{ color: Theme.getColor(item.name) }}>
              {value || '-'}
            </div>
          </NavLink>
        </li>
      );
    });
    if (this.props.filterOptions[0].name === 'total') {
      const divider = <li key="divider" className="divider"><div /></li>;
      callFilters.splice(1, 0, divider);
    }

    return (
      <ul className="call-filters">
        {callFilters}
      </ul>
    );
  }
}

export default connect(({ router }) => ({
  pathname: router.location.pathname,
}))(ReactTimeout(CallFilters));
