import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import AnalyticsComparisionPanels from 'components/analytics_panels/analytics_comparison_panels';
import AnalyticsTablePanel from 'components/analytics_table_panel';
import { Collapse } from 'components';
import Utils from 'utils/common_utils';

import AnalyticsChart from 'components/charts/analytics_chart';


function AnalyticsPeriod(props) {
  const { state, actions } = props;

  let uniqueReportTypes;

  if (state.callType) {
    uniqueReportTypes = [state.callType.type];
  } else {
    uniqueReportTypes = _.map(_.uniqBy(state.callTypes, 'type'), 'type');
  }

  if (uniqueReportTypes.length === 0) {
    return (
      <div className="no-data">No data with these options.</div>
    );
  }


  const charts = _.map(uniqueReportTypes, (type, index) => {
    const report = _.find(state.metadata.reports, { type });
    return (
      <div
        key={index}
        className={`col-${state.callType || uniqueReportTypes.length === 1 ? 'full' : report.size}`}
      >
        <AnalyticsChart
          charts={report.charts}
          url={report.url}
          units={report.units}
          title={type}
          realTime={state.realTime}
          timeRange={state.timeRanges[0]}
          timeRanges={state.timeRanges}
          filters={state.apiFilters}
          selects={_.map(_.filter(state.callTypes, { type }), (i) => _.omit(i, 'type', 'id'))}
          labels={Utils.createLabelMap(state.callTypes)}
          enabledLegendItems={state.callType ? [state.callType.label] : null}
          legendPosition={'bottom'}
          detailUrl={state.metadata.table_report.detail_url}
          detailPeriodOptions={state.metadata.table_report.detail_period_options}
          callLogUrl={state.metadata.table_report.call_log_url}
        />
      </div>
    );
  });

  return (
    <div>
      <div style={{ position: 'relative' }}>
        <Collapse arrowClass="panel-collapse-arrow" disableAnimation>
          <AnalyticsComparisionPanels
            onPanelClick={(callType) => {
              // If current panel is selected, unselect
              if (!state.callType || callType.id !== state.callType.id) {
                actions.setCallType('period', callType);
              } else {
                actions.setCallType('period', null);
              }
            }}
            selected={state.callType}
            panelItems={state.callTypes}
            timeRanges={state.timeRanges}
            parseDates
            filters={state.apiFilters}
            className={'analytics-panel period'}
          />
        </Collapse>
      </div>

      <div className="row analytics-charts">
        {charts}
      </div>

      <AnalyticsTablePanel
        filters={state.apiFilters}
        selects={state.callTypes}
        callLogFields={state.callLogFields}
        timeRanges={state.timeRanges}
        detailUrl={state.metadata.table_report.detail_url}
        detailPeriodOptions={state.metadata.table_report.detail_period_options}
        callLogUrl={state.metadata.table_report.call_log_url}
      />
    </div>
  );
}


AnalyticsPeriod.propTypes = {
  state: PropTypes.object,
  actions: PropTypes.object,
};


export default AnalyticsPeriod;
