import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { DateRangePicker } from '@nextiva/analytics-ui';

import { bindControlsActions } from 'actions/controls_actions';
import { ReactComponent as PopoutImg } from 'img/popout.svg';
import Checkbox from 'components/checkbox';
import ListSelect from 'components/list_select';
import MultiSelect from 'components/multi_select';
import TabbedControls from 'components/tabbed_controls';
import ButtonPopover from 'components/button_popover';
import * as selectors from 'modules/monitoring';

const mapStateToProps = (state, ownProps) => {
  let dashboardState = selectors.dashboardSelector(state);
  if (ownProps.section === 'wallboards') {
    dashboardState = selectors.wallboardsSelector(state);
  }
  return {
    filterType: dashboardState.filterType,
    filterTypeOptions: dashboardState.filterTypeOptions,
    filters: dashboardState.filters,
    filterOptions: dashboardState.filterOptions,
    callTypes: dashboardState.callTypes,
    callTypeOptions: dashboardState.callTypeOptions,
    realTime: dashboardState.realTime,
    timeRange: dashboardState.timeRange,
    timezone: dashboardState.timezone,
    grouping: dashboardState.grouping,
    boardType: dashboardState.boardType,
  };
};

class DashboardControls extends Component {
  static propTypes = {
    /** The section of monitoring (in this case 'dashboard') */
    section: PropTypes.string.isRequired,
    /** Whether or not the dashboard controls are disabled */
    disableBoardControls: PropTypes.bool,
    /** Whether or not the popout is hidden */
    hidePopout: PropTypes.bool,
    /** Handler for when the popout button is selected */
    onPopout: PropTypes.func,
    /** Current filter type */
    filterType: PropTypes.object,
    /** Filter Type options */
    filterTypeOptions: PropTypes.array,
    /** Selected filters */
    filters: PropTypes.array,
    /** Availble filters */
    filterOptions: PropTypes.array,
    /** Selected call types */
    callTypes: PropTypes.array,
    /** Available call types */
    callTypeOptions: PropTypes.array,
    /** Are we in real time mode? */
    realTime: PropTypes.bool,
    /** Time range for the section */
    timeRange: PropTypes.object,
    /** User timezone */
    timezone: PropTypes.string,
    /** Whether or not to group custom groups */
    grouping: PropTypes.bool,
    /** Displayed board type */
    boardType: PropTypes.object,
    push: PropTypes.func,
  };

  componentWillMount() {
    this.actions = bindControlsActions(this.props.section);
  }

  linkToGroups = () => {
    this.props.push('/settings/groups');
  };

  render() {
    const {
      filterType,
      filterTypeOptions,
      filters,
      filterOptions,
      callTypes,
      callTypeOptions,
      realTime,
      timeRange,
      timezone,
      grouping,
      boardType,
    } = this.props;
    const { disableBoardControls } = this.props;

    const boardTypeSelect = (
      <ListSelect
        options={[
          { id: 'iconic', label: 'Iconic' },
          { id: 'original', label: 'Original' },
        ]}
        onSelect={this.actions.setBoardType}
        selected={boardType}
      />
    );
    const callTypesSelect = (
      <MultiSelect
        searchBar
        options={callTypeOptions}
        onChange={this.actions.setCallTypes}
        initialSelected={callTypes}
      />
    );
    const filterTypes = (
      <div>
        <ListSelect
          options={filterTypeOptions}
          onSelect={this.actions.changeFilterType}
          selected={filterType}
          compareField={filterType.id !== 'teams' ? 'id' : 'teamType'}
        />
        {this.props.section === 'dashboard' && filterType.id === 'teams' ?
          <Checkbox
            label={'Grouped'}
            onChange={this.actions.setGrouping}
            checked={grouping}
          />
          : null }
      </div>
    );
    let element = null;
    if (filterType && (filterType.bucketType === 'teams')) {
      element = (
        <div className="group-link-medium" key={'dashboard-element'}>
          <div className="multi-select-item">
            <span className="icon-custom-plus" />
            <a onClick={this.linkToGroups}>Create A New Custom Group</a>
          </div>
          <hr />
        </div>
        );
    }
    const filtersSelect = (
      <MultiSelect
        searchBar
        options={filterOptions}
        onChange={this.actions.setFilters}
        initialSelected={filters}
        displayElement={element}
      />
    );
    const tabbedControls = (
      <TabbedControls
        tabs={[
          { icon: 'icon-custom-indented-list', content: boardTypeSelect, disabled: disableBoardControls },
          { icon: 'icon-custom-sliders', content: callTypesSelect },
          { icon: 'icon-custom-people', content: filterTypes },
          { icon: 'icon-custom-filter', content: filtersSelect },
        ]}
      />
    );
    const buttonPopover = (
      <ButtonPopover
        popoverClass="controls-popover dashboard-controls-popover"
        iconClass="icon-custom-settings"
        popoverBody={tabbedControls}
      />
    );

    return (
      <div
        className={cx({
          'gamification-monitoring-controls monitoring-controls': true,
        })}
      >
        <DateRangePicker
          withRealTimeIcon
          className="date-range-popover"
          isRealTime={realTime}
          timezone={timezone}
          dateRange={timeRange}
          onChange={this.actions.setTimeRange}
          onRealTimeChange={this.actions.setRealTime}
          theme={{
            dateRangePicker: 'date-range',
          }}
        />
        {buttonPopover}
        {!this.props.hidePopout && (
          <PopoutImg className="dashboard-popout" onClick={this.props.onPopout} />
        )}
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  { push },
)(DashboardControls);
