import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Utils from 'utils/common_utils';

import { ReactComponent as Arrow } from 'img/plus.svg';


const LeaderboardTableRow = (props) => {
  let value = props.value;
  const suffix = Utils.getSuffix(props.index + 1);
  const className = props.index !== 0 ? 'rank' : 'rank-first';
  let arrowElement;

  if (props.unit === 'seconds') {
    value = Utils.humanizeSeconds(props.value);
  }
  if (props.arrow !== null) {
    const arrowClass = cx(
      {
        'leaderboard-trend-arrow': true,
        'down': props.arrow,
      });
    arrowElement = <Arrow className={arrowClass} />;
  }

  return (
    <tr className={className}>
      <td>
        <div className="suffix">
          <span>{props.index + 1}<sup>{suffix}</sup></span>
        </div>
      </td>
      <td>
        <span>{props.label}</span>
      </td>
      <td>
        <div className="table-text">{value}</div>
      </td>
      <td>
        {arrowElement ?
          <div className="arrow-container">
            {arrowElement}
          </div>
        : <span>&mdash;</span>}
      </td>
    </tr>
  );
};

LeaderboardTableRow.propTypes = {
  value: PropTypes.number,
  index: PropTypes.number,
  unit: PropTypes.string,
  arrow: PropTypes.bool,
  label: PropTypes.string,
};

export default LeaderboardTableRow;
