
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
// TODO
// Tabs.setUseDefaultStyles(false);
/**
 * Component for tabbed controls inside popover.
 */
class TabbedControls extends Component {
  static propTypes = {
    /** The data for the tabs */
    tabs: PropTypes.array,
    /** Whether to auto update after input */
    autoUpdate: PropTypes.bool,
  }
  static defaultProps = {
    autoUpdate: true,
  }
  constructor(props) {
    super(props);
    this.state = {
      selectedIndex: 0,
    };
  }
  onTabClick = (index) => {
    this.setState({ selectedIndex: index });
  }
  render() {
    const { tabs } = this.props;
    const { selectedIndex } = this.state;
    const tabHeaders = tabs.map((tab, i) => {
      if (tab.disabled) {
        return null;
      }
      let content = tab.label;
      const tabClassNames = ['tabbed-controls-tab', tab.className];
      if (tab.icon) {
        const classNames = ['tabbed-controls-tab-icon', tab.icon];
        content = (
          <span className={cx(classNames)} />
        );
      }
      return (
        <Tab
          key={i}
          className={cx(tabClassNames)}
          title={tab.label}
        >
          {content}
        </Tab>
      );
    });
    const tabContent = tabs.map((tab, i) => {
      if (tab.disabled) {
        return null;
      }
      return (
        <TabPanel
          key={i}
          className="tabbed-controls-content"
        >
          {tab.content}
        </TabPanel>
      );
    });
    return (
      <div className="tabbed-controls">
        <Tabs
          selectedIndex={this.state.selectedIndex}
          onSelect={this.onTabClick}
          focus={false}
          selectedTabClassName="selected"
        >
          <TabList className="tabbed-controls-tabs">
            {tabHeaders}
          </TabList>
          {tabContent}
        </Tabs>
      </div>
    );
  }
}
export default TabbedControls;
