import React, { memo } from 'react';
import { LabeledTextField } from '@nextiva/next-ui';

import Styles from './Email.module.scss';


const Email = memo((props) => {
  const { 
    value,
    errors,
    onBlur,
    onChange,
  } = props;

  const isError = errors.length !== 0;

  return (
    <>
      <LabeledTextField
        value={value}
        label="Email to"
        isError={isError}
        onBlur={onBlur}
        onChange={onChange}
      />

      <p className={Styles.description}>for multiple addresses, use a comma to separate</p>

      {isError && (
        <p className={Styles.invalid}>Invalid Emails</p>
      )}

      <ul className={Styles.invalidList}>
        {errors.map((error, index) => 
          <li 
            key={index} 
            className={Styles.item}
            data-testid="emailError"
          >
            {error}
          </li>
        )}
      </ul>
    </>
  )
});


Email.displayName = 'Email';


export default Email;
