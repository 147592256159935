import type from 'type-of';
import _ from 'lodash';
import moment from 'moment-timezone';
import AppData from 'app_data';

const Utils = {
  capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  },
  capitalizeWords(str) {
    return str.replace(/\w\S*/g, (txt) =>
      txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    );
  },
  addDigit(num) {
    return num.toString().length === 1 ? `0${num}` : String(num);
  },
  toggleTime(time) {
    time = _.cloneDeep(time);
    if (type(time) === 'string') {
      const result = {};
      const [hours, minutes] = time.split(':');

      if (Number(hours) >= 12) {
        result.hours = Utils.addDigit(hours - 12);
        result.meridiem = 'pm';
      } else {
        result.hours = Utils.addDigit(hours);
        result.meridiem = 'am';
      }

      if (result.hours === '00') result.hours = '12';

      result.minutes = minutes;

      return result;
    } else if (type(time) === 'object') {
      if (time.meridiem === 'pm') {
        time.hours = Number(time.hours) + 12;
      }

      if (time.hours % 12 === 0) {
        time.hours = Number(time.hours) - 12;
      }

      const timeString = `${this.addDigit(time.hours)}:${this.addDigit(time.minutes)}`;

      return timeString;
    }
  },
  numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  },
  humanizeSeconds(seconds) {
    let result = seconds;

    if (seconds > -1) {
      // seconds
      result = `${Math.round(seconds % 60)}s`;
    } else return result;

    if (seconds >= 60) {
      // minutes
      result = `${Math.floor(seconds / 60) % 60}m ${result}`;
    } else return result;

    if (seconds >= 3600) {
      // hours
      result = `${Math.floor(seconds / 3600) % 24}h ${result}`;
    } else return result;

    if (seconds >= 86400) {
      // days (hide days when years)
      const value = Math.floor(seconds / 86400) % 365;
      if (value > 0) {
        result = `${value}d ${result}`;
      }
    } else return result;

    if (seconds >= 86400) {
      // years
      const value = Math.floor(seconds / (86400 * 365));
      if (value === 1) {
        result = `${value}yr ${result}`;
      } else if (value > 1) {
        result = `${value}yrs ${result}`;
      }
    }

    return result;
  },

  formatFromSeconds(value, format) {
    /// take value in seconds and format to anything moment understands.
    /// frankly: terrible -- blame stackoverflow.
    return moment().startOf('day').seconds(value).format(format);
  },

  convertSecondsToMinutes(value) {
    value = +value;
    const seconds = Utils.addDigit(Math.floor(value % 60));
    const minutes = Utils.addDigit(Math.floor(value / 60));
    return `${minutes}m${seconds}s`;
  },
  convertFromUTC(dateTime, timezone, format = 'YYYY-MM-DD HH:mm') {
    return moment.utc(dateTime).tz(timezone).format(format);
  },

  openRoute(pathname, query = {}) {
    const open = window.open((process.env.REACT_APP_PUBLIC_PATH || '') + pathname);

    // ipad blocks popouts, redirect page
    if (open == null || typeof open === 'undefined') {
      window.location.href = pathname;
    }
  },
  getSuffix(index) {
    index = Math.floor(index);
    const hundredRem = index % 100;
    const tenRem = index % 10;
    if (hundredRem - tenRem === 10) {
      return 'th';
    }
    switch (tenRem) {
      case 1:
        return 'st';
      case 2:
        return 'nd';
      case 3:
        return 'rd';
      default:
        return 'th';
    }
  },
  labelByUnit(unit) {
    if (unit === 'seconds') {
      return 'Duration';
    }
    if (unit === 'calls') {
      return 'Calls';
    }
  },

  createDelayedCallback(cb, delay) {
    const currentTime = Date.now();
    const readyTime = currentTime + delay;

    return (data) => {
      const now = Date.now();

      if (now > readyTime) {
        cb(data);
      } else {
        // if response comes back too quickly, delay callback
        setTimeout(() => {
          cb(data);
        }, readyTime - now);
      }
    };
  },
  createLabelMap(labelArr, key = 'id') {
    return _.reduce(labelArr, (prev, cur) => {
      prev[cur[key]] = cur.label;
      return prev;
    }, {});
  },
  relativeToLabel(timeRange) {
    const value = parseInt(timeRange.value, 10);

    let unitCountLabel;
    let suffix = '';
    if (value === 0) {
      if (timeRange.unit === 'day') {
        unitCountLabel = 'Today';
      } else {
        unitCountLabel = `This ${timeRange.unit}`;
      }
    } else if (value === 1) {
      if (timeRange.unit === 'day') {
        unitCountLabel = 'Yesterday';
      } else {
        unitCountLabel = `${value} ${timeRange.unit}`;
        suffix = 'ago';
      }
    } else if (value > 1) {
      unitCountLabel = `${value} ${timeRange.unit}s`;
      suffix = 'ago';
    }
    if (timeRange.round) {
      suffix = `${suffix} (rounded)`;
    }
    if (suffix) {
      return `${unitCountLabel} ${suffix}`;
    }
    return `${unitCountLabel}`;
  },
  relativeToAbsolute(timeRange, timezone = AppData.user.timezone) {
    let absoluteDate = moment().tz(timezone).subtract(timeRange.value, `${timeRange.unit}s`);

    if (timeRange.round) {
      if (timeRange.unit === 'week') {
        absoluteDate = absoluteDate.startOf('isoweek');
      } else {
        absoluteDate = absoluteDate.startOf(timeRange.unit);
      }
    }
    return absoluteDate;
  },
  getStartEndDates(timeRange, timezone = AppData.user.timezone) {
    let start;
    let end;
    if (timeRange.type === 'absolute') {
      start = moment.utc(timeRange.start).tz(timezone);
      end = moment.utc(timeRange.end).tz(timezone);
    } else if (timeRange.type === 'relative') {
      start = Utils.relativeToAbsolute(timeRange, timezone);
      end = moment.utc().tz(timezone);
    }

    return { type: 'absolute', start, end };
  },
  dispatchEvent(eventName) {
    const event = document.createEvent('Event');
    event.initEvent(eventName, false, true);

    window.dispatchEvent(event);
  },
  decodeHtmlEntity(str) {
    return str.replace(/&#(\d+);/g, (match, dec) =>
      String.fromCharCode(dec)
    );
  },
  encodeHtmlEntity(str) {
    const buf = [];
    for (let i = str.length - 1; i >= 0; i--) {
      buf.unshift(['&#', str[i].charCodeAt(), ';'].join(''));
    }
    return buf.join('');
  },
  debounceArgs(func, wait) {
    let args = [];
    let timeoutId;
    return function (...params) {
      // User formal parameters to make sure we add a slot even if a param
      // is not passed in
      if (func.length) {
        for (let i = 0; i < func.length; i++) {
          if (!args[i]) {
            args[i] = [];
          }
          args[i].push(params[i]);
        }
      } else { // No formal parameters, just track the whole argument list
        args.push(_.toArray(params));
      }
      clearTimeout(timeoutId);
      timeoutId = setTimeout(function () {
        func.apply(this, args);
        args = [];
      }, wait);
    };
  },
  getContrastYIQ(hexcolor) {
    if (hexcolor.charAt(0) === '#') {
      hexcolor = hexcolor.slice(1);
    }
    const r = parseInt(hexcolor.substr(0, 2), 16);
    const g = parseInt(hexcolor.substr(2, 2), 16);
    const b = parseInt(hexcolor.substr(4, 2), 16);
    const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
    return (yiq >= 128) ? 'black' : 'white';
  },
};

export default Utils;
