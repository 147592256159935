import _ from 'lodash';
import type from 'type-of';
import moment from 'moment-timezone';
import update from 'immutability-helper';

import AppData from 'app_data';
import Constants from 'constants.js';
import Utils from 'utils/common_utils';
import Tree from 'modules/reporting/report_tree';
import Filters from 'modules/reporting/custom_filters';
import SampleData from 'utils/sample_data/sample_data';
import appDefaults from './app_defaults';


function custom(state = {
  ui: appDefaults.custom.ui,
}, action) {
  switch (action.type) {
    case Constants.CUSTOM_SET_TIME_RANGES:
    case Constants.CUSTOM_SET_REAL_TIME:
    case Constants.CUSTOM_SET_BUCKETS:
    case Constants.CUSTOM_SET_TITLE:
    case Constants.CUSTOM_SET_STATUS:
    case Constants.CUSTOM_SET_TEAM_TYPE:
      return update(state, {
        ui: {
          [action.field]: { $set: action.value },
          errors: { $set: errorsReducer(state.ui, action) },
        },
      });

    case Constants.CUSTOM_UPDATE_CHART:
      return update(state, {
        ui: {
          chart: { $set: GenerateChart(state.ui) },
        },
      });

    case Constants.CUSTOM_UPDATE_TREE_PATH:
      return update(state, {
        ui: {
          treePath: { $merge: action.path },
        },
      });

    case Constants.CUSTOM_DISABLE_ALL:
    case Constants.CUSTOM_ENABLE_ALL:
    case Constants.CUSTOM_ENABLE_FILTER:
    case Constants.CUSTOM_DISABLE_FILTER:
      return update(state, {
        ui: {
          filters: { $set: filtersReducer(
            state.ui.filters,
            action,
            state.ui.dropzones[1],
            state.ui.dropzoneLimits[action.dropzone || state.ui.dropzones[1]],
            state.ui.teamType
          ) },
          errors: { $set: errorsReducer(state.ui, action) },
        },
      });

    case Constants.CUSTOM_PARSE_TREE: {
      // retain report title if this is an edit
      const title = state.ui.title;

      const uiState = Parser.parse(action.path);
      uiState.chart = GenerateChart(uiState);
      uiState.status = 'READY';
      uiState.treePath = state.ui.treePath;
      uiState.title = title;


      return _.merge({}, appDefaults.custom, {
        ui: uiState,
      });
    }

    case Constants.CUSTOM_CLEAR:
      return appDefaults.custom;

    case Constants.CUSTOM_LOAD_REPORT: {
      const data = action.data;
      const treeLabels = [
        data.reportGroup,
        data.report,
        data.format,
        data.breakdown,
        data.display,
      ];

      let node = Tree.get();
      const treeIndices = [];

      _.each(treeLabels, (key, index) => {
        const ix = _.findIndex(node, { key: key.toLowerCase() });
        treeIndices[index] = ix;
        node = node[ix].items;
      });

      const ui = Parser.parse(treeIndices);

      ui.treePath = {
        reportGroup: treeIndices[0],
        report: treeIndices[1],
        format: treeIndices[2],
        breakdown: treeIndices[3],
        display: treeIndices[4],
      };

      // enable all filters from data payload
      const selectDropzone = ui.dropzones[0];
      const filterDropzone = ui.dropzones[1];

      const selectSet = new Set(data.select.map((select) => select.metric));
      const filterSet = new Set(data.filters[ui.dropzones[1]]);

      // modify selects
      ui.filters[selectDropzone].items = _.map(ui.filters[selectDropzone].items, (select) => {
        if (selectSet.has(select.metric)) {
          select.selected = true;
        }

        return select;
      });

      // modify filters
      ui.filters[filterDropzone].items = _.map(ui.filters[filterDropzone].items, (filter) => {
        filter.selected = filterSet.has(filter.key);

        return filter;
      });

      ui.title = data.title;

      ui.timeRanges = data.time_ranges || data.timeRanges;

      ui.chart = GenerateChart(ui);

      ui.status = 'READY';

      return _.merge({}, appDefaults.custom, {
        ui,
      });
    }

    case Constants.CUSTOM_VALIDATE: {
      const errors = _.cloneDeep(appDefaults.custom.ui.errors);
      const dropzones = state.ui.dropzones;

      if (!action.options.excludeTitle) {
        // title is required
        if (!(state.ui.title && state.ui.title.length)) {
          errors.title.hasError = true;
        }
      }

      // at least 1 select
      if (!_.filter(state.ui.filters[dropzones[0]].items, { selected: true }).length) {
        errors.select.hasError = true;
      }

      // at least 1 filter
      if (!_.filter(state.ui.filters[dropzones[1]].items, { selected: true }).length) {
        errors.filter.hasError = true;
      }

      return update(state, {
        ui: {
          errors: { $set: errors },
        },
      });
    }

    default:
      return state;
  }
}

function GenerateChart(ui) {
  return ui.dropzones.length ? _.assign({}, ui.chart, { config: SampleData(ui) }) : null;
}

function errorsReducer(uiState, action) {
  switch (action.type) {
    case Constants.CUSTOM_SET_TITLE: {
      if (action.value.length && uiState.errors.title.hasError) {
        const title = _.assign({}, uiState.errors.title, {
          hasError: false,
        });

        return _.assign({}, uiState.errors, {
          title,
        });
      }
      return uiState.errors;
    }

    case Constants.CUSTOM_ENABLE_FILTER:
    case Constants.CUSTOM_ENABLE_ALL: {
      // ENABLE_FILTER uses action.dropzone, otherwise the 2nd dropzone (filter) is used;
      const dropzone = action.dropzone || uiState.dropzones[1];
      const errorKey = uiState.dropzones.indexOf(dropzone) === 0 ? 'select' : 'filter';

      const errorObj = _.assign({}, uiState.errors[errorKey], {
        hasError: false,
      });

      return _.assign({}, uiState.errors, {
        [errorKey]: errorObj,
      });
    }

    default:
      return uiState.errors;
  }
}

function filtersReducer(state, action, filterDropzone, dropzoneLimit, teamType = null) {
  const { op, dropzone, key } = action;
  const newFilter = _.assign({}, state[dropzone || filterDropzone]);

  switch (action.type) {
    case Constants.CUSTOM_ENABLE_FILTER:
    case Constants.CUSTOM_DISABLE_FILTER: {
      if (dropzoneLimit && op === 'enable') {
        let selectedLength = 0;
        let lastSelected;
        newFilter.items = _.map(state[dropzone].items, (item, index) => {
          if (item.selected) {
            selectedLength++;
            lastSelected = index;
          }

          if (item.key === key) {
            return _.assign({}, item, {
              selected: true,
            });
          }
          return item;
        });

        if (selectedLength === dropzoneLimit) {
          newFilter.items = update(newFilter.items, {
            [lastSelected]: { $merge: { selected: false } },
          });
        }
      } else {
        const index = _.findIndex(state[dropzone].items, { key });

        newFilter.items = [
          ...state[dropzone].items.slice(0, index),
          ...state[dropzone].items.slice(index + 1),
          _.assign({}, state[dropzone].items[index], {
            selected: op === 'enable',
          }),
        ];
      }

      return update(state, {
        [dropzone]: { $set: newFilter },
      });
    }

    case Constants.CUSTOM_DISABLE_ALL:
    case Constants.CUSTOM_ENABLE_ALL: {
      let idx = 0;

      newFilter.items = _.map(state[filterDropzone].items, (item, index) => {
        const newItem = _.assign({}, item);
        if (dropzoneLimit && idx > dropzoneLimit - 1) {
          newItem.selected = false;
        } else {
          newItem.selected = action.type === Constants.CUSTOM_ENABLE_ALL;

          // override selected when teamTypes dont match in teams reports
          if (teamType && item.teamType !== teamType) {
            newItem.selected = false;
          } else {
            idx++;
          }
        }

        return newItem;
      });

      return update(state, {
        [filterDropzone]: { $set: newFilter },
      });
    }

    default:
      return state;
  }
}

const Parser = {
  validKeys: [
    'availableFilters',
    'dropzones',
    'limitDropzone',
    'dropzoneDefault',
    'dateDefault',
    'minuteBucketsDefault',
    'groupLabel',
  ],
  ui: {},
  defaults: {
    ui: {
      dropzones: [],
      filters: {},
      dropzoneLimits: {},
      timeRanges: [],
      chart: {},
    },
  },
  parse(path) {
    path = _.cloneDeep(path);
    this.ui = _.cloneDeep(this.defaults.ui);

    // add default dates
    const timeRanges = [
      {
        type: 'absolute',
        start: moment().tz(AppData.user.timezone).startOf('day').toISOString(),
        end: moment().tz(AppData.user.timezone).endOf('day').toISOString(),
      },
    ];

    this.ui.timeRanges = timeRanges;

    // set node to first object in tree
    let node = Tree.get()[path[0]];

    path.shift();
    this.checkKeys(node);

    let queryPath = [node.key];

    // traverse all items
    _.each(path, (index) => {
      node = node.items[index];
      this.checkKeys(node);
      queryPath.push(node.key);
    });

    // all keys but chart processed, call manually
    this.parseChart(node);

    // pull out config keys
    const config = ['reportGroup', 'report', 'format', 'breakdown', 'display'];
    queryPath = _.zipObject(config, queryPath);

    this.addQueryPath(queryPath);

    return this.ui;
  },
  addQueryPath(val) {
    this.ui.chart = _.assign({}, this.ui.chart, val);
    this.ui.queryPath = val;
  },
  checkKeys(node) {
    _.each(this.validKeys, (key) => {
      if (node[key]) {
        this[`parse${Utils.capitalize(key)}`](node[key]);
      }
    });
  },
  parseGroupLabel(val) {
    this.ui.chart.groupLabel = val;
  },
  parseMinuteBucketsDefault(val) {
    this.ui.minuteBuckets = val;
  },
  parseDropzoneDefault(arr) {
    _.each(arr, (obj) => {
      let filters = this.ui.filters[obj.dropzone].items;

      // set all filters to false before incase of overwriting
      _.each(filters, (filter) => {
        filter.selected = false;
      });

      // if teams report, limit to team first team type
      if (obj.dropzone === 'teams' && filters[0]) {
        this.ui.teamType = this.ui.filters.teams.items[0].teamType;
        filters = _.filter(filters, { teamType: filters[0].teamType });
      }

      filters = obj.fn(filters);

      _.each(filters, (filter) => {
        filter.selected = true;
      });
    });
  },
  parseDateDefault(arr) {
    this.ui.timeRanges = arr;
  },
  parseDropzones(val) {
    this.ui.dropzones = val;
  },
  parseLimitDropzone(arr) {
    _.each(arr, (dropzone) => {
      this.ui.dropzoneLimits[_.snakeCase(dropzone.field)] = dropzone.len;
    });
  },
  parseAvailableFilters(arr) {
    let filterSet;

    _.each(arr, (obj) => {
      let filters;
      const key = obj.key;

      filterSet = {
        key,
        label: obj.label,
        items: [],
      };

      if (type(obj.copyFrom) === 'string') {
        filters = _.cloneDeep(Filters[obj.copyFrom].items);
      } else {
        filters = _.flatten(
          _.map(obj.copyFrom, (k) => _.cloneDeep(Filters[k].items))
        );
      }

      const parentLabel = _.snakeCase(key);

      _.each(filters, (filter) => {
        filter.parent = parentLabel;
        filterSet.items.push(filter);
      });

      const dropzone = filterSet.key;

      this.ui.filters[dropzone] = filterSet;
    });
  },
  parseChart(obj) {
    this.ui.chart.name = obj.name;
  },
};

export default custom;
