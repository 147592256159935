import React, { memo, useCallback } from 'react';
import cx from 'classnames';
import { List, DataTable } from '@nextiva/analytics-ui';

import {
  NoData,
  DefaultSpinner,
  ErrorPlaceholder,
} from 'components';
import Utils from 'utils/common_utils';

import AdditionalButtons from '../AdditionalButtons';
import { CallRecordingContainer } from 'modules/CallRecording';
import { ColumnTypes } from '../../constants';

import Styles from './ReportCallLog.module.scss';
import { ReactComponent as IconMissed } from 'img/missed_calls.svg';
import { ReactComponent as IconInbound } from 'img/inbound.svg';
import { ReactComponent as IconOutbound } from 'img/outbound.svg';


const DirectionIcons = {
  missed: <IconMissed className={Styles.directionIcon} />,
  inbound: <IconInbound className={Styles.directionIcon} />,
  outbound: <IconOutbound className={Styles.directionIcon} />,
};


const ReportCallLog = memo((props) => {
  const {
    table,
    search,
    sortData,
    isLoading,
    sourceType,
    pagination,
    filterOptions,
    errorClassName,
    callRecordingInfo,
    onPageChange,
    onLimitChange,
    onSearchChange,
    onSortChange,
    onClickExport,
    onChangeSourceType,
    onCloseModal,
    onClickCallRecording,
    withoutPagination = false,
    withoutSearch = false,
    withoutAddButton = false,
    withoutPerPage = false,
  } = props;

  if (!table) {
    return isLoading ? (
      <DefaultSpinner />
    ) : (
      <ErrorPlaceholder 
        isAbsolutePosition
        className={errorClassName}
      />
    )
  } else if (Object.keys(table).length === 0) {
    return (
      <NoData isAbsolutePosition />
    );
  }
  
  const {
    rows,
    headers,
  } = table;

  const renderCell = useCallback((columnLabel) => (cell) => {
    switch (columnLabel) {
      case ColumnTypes.DIRECTION_ICON:
        return DirectionIcons[cell.cellData];

      case ColumnTypes.RECORDING_ICON:
        return cell.rowData.callRecordingResult === 'successful'
          ? (
            <span
              data-index={cell.idx}
              onClick={onClickCallRecording}
              className={cx(Styles.volumeIcon, 'icon-custom-volume-high')}
            />
          ) : null;

      default:
        return headers.find(h => h.name === columnLabel).duration
          ? Utils.humanizeSeconds(cell.cellData) 
          : cell.cellData;
    }
  });

  return (
    <div 
      data-testid="reportCallLog"
      className={cx(Styles.reportTable, { 
        'data-empty': rows.length === 0 
      })}
    >
      <List
        search={search}
        isLoading={isLoading}
        className={Styles.list}
        pagination={pagination}
        onPageChange={onPageChange}
        onLimitChange={onLimitChange}
        onSearchChange={onSearchChange}
        withoutPagination={withoutPagination}
        withoutSearch={withoutSearch}
        withoutAddButton={withoutAddButton}
        withoutPerPage={withoutPerPage}
        buttonComponent={() => (
          <AdditionalButtons
            options={filterOptions}
            sourceType={sourceType}
            onClickExport={onClickExport}
            onChangeSourceType={onChangeSourceType}
          />
        )}
      >
        <DataTable
          data={rows}
          sortData={sortData}
          isLoading={isLoading}
          className={Styles.table}
          getRenderer={renderCell}
          displayPreferences={headers}
          onSortChange={onSortChange}
        />
      </List>

      {Boolean(callRecordingInfo) && (
        <CallRecordingContainer
          info={callRecordingInfo}
          onCloseModal={onCloseModal}
        />
      )}
    </div>
  );
});


ReportCallLog.displayName = 'ReportCallLog';


export default ReportCallLog;
