import update from 'immutability-helper';

import Constants from 'constants.js';

function user(state = {
  isAuthenticated: false,
  authenticationFailed: false,
  user: {},
}, action) {
  switch (action.type) {
    case Constants.USER_LOGIN_SUCCESS:
      return update(state, {
        $merge: {
          isAuthenticated: true,
          user: action.data,
        },
      });

    case Constants.USER_LOGIN_FAILURE:
      return update(state, {
        $merge: {
          isAuthenticated: false,
          authenticationFailed: true,
          error: action.error,
        },
      });

    default:
      return state;
  }
}

export default user;
