import qs from 'qs';
import { 
  axios,
  camelizeKeys,
  snakelizeKeys,
} from 'common';

const API_SAVE_NOTE = '/nextos/recording/meta';

export const saveNote = async (params) => {
  try {
    if (params.id) {
      return axios.put(
        `${API_SAVE_NOTE}/${params.id}`, 
        snakelizeKeys(params)
      );
    }

    return await axios.post(API_SAVE_NOTE, snakelizeKeys(params));
  } catch(e) {
    return { errorMessage: 'Your changes not saved' };
  }
};


export const sendEmails = async (params) => {
  try {
    const { data } = await axios.post('/nextos/recording/email', snakelizeKeys(params));
    return data;
  } catch(e) {
    return { errorMessage: 'Your email has not been sent' };
  }
};


export const getCallRecording = async (params) => {
  try {
    const queryString = qs.stringify(snakelizeKeys(params));
    const response = await axios.get(
      `/nextos/recording/meta?${queryString}`, 
      { timeout: 30000 },
    );

    const data = camelizeKeys(response.data);

    return {
      ...data,
      note: data.note || '',
      clipInfo: data.segments,
    };
  } catch (e) {
    let errorMessage ;
    switch (e.response && e.response.status) {
      case 4:
      case 404:
        errorMessage = 'Call recording data not available for this record. It may have been deleted.';
        break;

      case 403:
        errorMessage = 'You do not have access to call recordings. If you need access, please contact your NextOS administrator.';
        break;
        
      case 502:
        errorMessage =  'The call recording service is currently inaccessible. Please try again later.';
        break;

      default:
        errorMessage = 'Oops! Something is not adding up. Please try again later.';
        break;
    }

    return { errorMessage };
  }
};


export const saveQuestionGroupAnswers = async (groupId, params) => {
  try {
    const url = `/nextos/question_groups/${groupId}/responses`;

    console.log(groupId, params)
    if (params.id) {
      return await axios.put(
        `${url}/${params.id}`, 
        snakelizeKeys(params)
      );
    }

    return await axios.post(url, snakelizeKeys(params));
  } catch(e) {
    return { errorMessage: 'Cannot save question groups' };
  }
}

