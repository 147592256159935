import React, { memo } from 'react';
import { 
  Tab,
  Tabs,
  TabList, 
  TabPanel,
  TextArea,
} from '@nextiva/next-ui';

import Email from '../Email';
import Questions from 'components/call_recording/questions';

import { ReactComponent as StarIcon } from '../../assets/star.svg';
import { ReactComponent as EmailIcon } from '../../assets/email.svg';
import { ReactComponent as PencilIcon } from '../../assets/pencil.svg';

import Styles from './Additional.module.scss';


const Additional = memo((props) => {
  const {
    note,
    email,
    emailErrors,
    externalTrackingId,
    onChangeTab,
    onBlurEmail,
    onChangeEmail,
    onChangeTextArea,
    changeAnswerQuestions,
  } = props;

  return (
    <Tabs className={Styles.additional} onSelect={onChangeTab}>
      <TabList>
        <Tab>
          Note
          <PencilIcon />
        </Tab>

        <Tab>
          Rate Call
          <StarIcon />
        </Tab>
        
        <Tab>
          <EmailIcon />
          Email
        </Tab>
      </TabList>
      
      <TabPanel>
        <TextArea
          value={note}
          autosize={false}
          className={Styles.note}
          onChange={onChangeTextArea}
        />
      </TabPanel>
      
      <TabPanel>
        <div className={Styles.questions}>
          <Questions
            externalTrackingId={externalTrackingId}
            onChange={changeAnswerQuestions}
          />
        </div>
      </TabPanel>
      
      <TabPanel>
        <Email 
          value={email}
          errors={emailErrors}
          onBlur={onBlurEmail}
          onChange={onChangeEmail}
        />
      </TabPanel>
    </Tabs>
  );
});


Additional.displayName = 'Additional';


export default Additional;