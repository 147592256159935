import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import moment from 'moment';
import vex from 'lib/vex';
import _ from 'lodash';
import { DefaultSpinner } from 'components';

import DataTable from 'components/data_table';
import AppData from 'app_data';
import InlineSvgFactory from 'components/inline_svg';
import Utils from 'utils/common_utils';

const Plus = InlineSvgFactory('plus.svg');

const actionsTypes = {
  EDIT: 'Edit',
  VIEW: 'View',
  FULL_SCREEN: 'View in Full Screen',
  DELETE: 'Delete',
};


const actionsDashboard = [
  actionsTypes.EDIT,
  actionsTypes.VIEW,
  actionsTypes.FULL_SCREEN,
  actionsTypes.DELETE,
].map(name => ({ name }));


class Saved extends React.Component {

  static propTypes = {
    push: PropTypes.func,
  };

  state = {
    filterText: '',
    rows: null,
    pagination: null,
    sorting: null,
  };

  fetchFilteredData = _.debounce(() => {
    this.setState({ rows: null });
    this.fetchDashboards({ page: 1 });
  }, 500);

  componentWillMount() {
    this.fetchDashboards();
  }

  onPageChange = page => {
    this.setState({
      rows: null,
      pagination: {
        ...this.state.pagination,
        current_page: page,
      },
    });

    this.fetchDashboards({ page });
  }

  onSortingChange = sorting => {
    this.setState({
      sorting,
      rows: null,
    });
    this.fetchDashboards({
      page: this.state.pagination.current_page,
    }, sorting);
  }

  setFilterText = (e) => {
    this.setState({
      filterText: e.target.value,
    });
    this.fetchFilteredData();
  };

  fetchDashboards = (params = { page: 1 }, optionalSorting) => {
    const { filterText } = this.state;
    const sorting = optionalSorting || this.state.sorting;

    const fetchParams = {
      ...params,
    };

    if (filterText) {
      fetchParams.search = filterText;
    }

    if (sorting) {
      fetchParams.order_by = `${sorting.sortDir === 'desc' ? '-' : ''}${sorting.sortBy}`;
    }

    AppData.getDashboards(fetchParams).then((response) => {
      const { data } = response;
      this.setState({
        rows: data.data,
        pagination: data.pagination,
      });
    });
  };

  handleAction = (dashboardObj, action) => {
    const { current_page } = this.state.pagination;
    const fetchParams = { page: current_page };
    const {
      title,
      dashboard_id: dashboardId,
    } = dashboardObj;

    switch (action.name) {
      case actionsTypes.EDIT: {
        this.props.push(`/monitoring/custom/${dashboardId}`);
        break;
      }

      case actionsTypes.VIEW: {
        Utils.openRoute(`/custom-dashboard/${dashboardId}`);
        break;
      }

      case actionsTypes.FULL_SCREEN: {
        Utils.openRoute(`/popout/custom-dashboard/${dashboardId}`);
        break;
      }

      case actionsTypes.DELETE: {
        vex.warning(`Are you sure you want to delete ${title}?`,
          (confirmed) => {
            if (confirmed) {
              AppData.deleteDashboard(dashboardId).then(() => {
                setTimeout(() => {
                  this.fetchDashboards(fetchParams);
                }, 2000);
              });
            }
          }
        );
        break;
      }
    }
  };

  handleNewDashboard = () => {
    this.props.push('/monitoring/custom');
  };

  render() {
    const { rows, filterText, pagination, sorting } = this.state;
    let content;
    if (rows) {
      content = (
        <DataTable
          headers={[
            { key: 'title', label: 'Dashboard Name', labelFunc: (value) => _.truncate(value, { length: 40 }), tooltip: true },
            { key: 'description', label: 'Description', labelFunc: (value) => _.truncate(value, { length: 40 }), tooltip: true },
            { key: 'creator', label: 'Creator', labelFunc: (value) => _.truncate(value, { length: 40 }), tooltip: true },
            { key: 'created', label: 'Created', labelFunc: (value) => moment(value).format('L').toString() },
          ]}
          rows={rows}
          filterText={filterText}
          onAction={this.handleAction}
          actions={actionsDashboard}
          pagination={pagination}
          onPageChange={this.onPageChange}
          onSortingChange={this.onSortingChange}
          sorting={sorting}
          perPage={10}
          useApiPagination
        />
      );
    } else {
      content = (
        <DefaultSpinner />
      );
    }

    return (
      <div className="save-schedule">
        <div className="schedule-header">
          <h1>Saved dashboards</h1>

          <div className="schedule-controls">
            <input
              className="search"
              type="search"
              placeholder="Search"
              onChange={this.setFilterText}
              value={this.state.filterText}
            />
            <div
              onClick={this.handleNewDashboard}
              className="btn btn-green pull-right new-dashboard"
            >
              Custom Dashboard <Plus className="new-dashboard-plus" />
            </div>
          </div>
        </div>

        {content}
      </div>
    );
  }
}

export default connect(
  null,
  { push },
)(Saved);
