import React, { memo, useRef, useState, useCallback } from 'react';
import { clamp } from 'lodash';
import Draggable from 'react-draggable';


import Styles from './Volume.module.scss';
import { ReactComponent as IconVolume } from '../../assets/volume.svg';
import { ReactComponent as IconEmptyVolume } from '../../assets/volume-zero.svg';


const bounds = {
  left: 0,
  right: 100,
};


const Volume = memo((props) => {
  const { onChange } = props;

  const volumeLine = useRef(null);
  const [volume, setVolume] = useState(bounds.right);
  const [isOffVolume, setIsOffVolume] = useState(false);

  const onClick = useCallback(
    (e) => {
      const rect = volumeLine.current.getBoundingClientRect();
      const positionClick = e.clientX - rect.x;
      const progress = clamp(positionClick, bounds.left, bounds.right);

      setVolume(progress);
      onChange(progress / bounds.right);
    },
    [volumeLine, setVolume, onChange],
  );

  const onChangeVolume = useCallback(
    (_, data) => {
      const volume = clamp(data.x, bounds.left, bounds.right);
      
      setVolume(volume);
      onChange(volume / bounds.right);
    }, 
    [onChange, setVolume],
  );

  const onClickIconVolume = useCallback(
    () => {
      setIsOffVolume(!isOffVolume);
      onChange(!isOffVolume ? 0 : volume / bounds.right);
    },
    [onChange, isOffVolume, setIsOffVolume],
  );

  return (
    <div className={Styles.volume}>
      <span 
        className={Styles.icon} 
        onClick={onClickIconVolume}
        data-testid="volumeIcon"
      >
        {volume === 0 || isOffVolume
          ? <IconEmptyVolume className={Styles.zero} /> 
          : <IconVolume />
        }
      </span>

      <div className={Styles.wrapper}>
        <div
          ref={volumeLine}
          className={Styles.line}
          onClick={onClick}
        >
          <div
            className={Styles.progress}
            style={{ width: `${isOffVolume ? 0 : volume}px` }}
            data-testid="volumeProgressBar"
          />

          <Draggable
            axis="x"
            bounds={bounds}
            position={{ 
              y: 0,
              x: isOffVolume ? 0 : volume, 
            }}
            onDrag={onChangeVolume}
            onStop={onChangeVolume}
          >
            <div className={Styles.value} />
          </Draggable>
        </div>
      </div>
    </div>
  );
});


Volume.displayName = 'Volume';


export default Volume;
