import _ from 'lodash';
import update from 'immutability-helper';

import Constants from 'constants.js';
import appDefaults from './app_defaults';

function user_management(state = {
  ui: appDefaults.user_management.ui,
}, action) {
  switch (action.type) {
    case Constants.USER_SET_USER_ID:
    case Constants.USER_SET_USER_DISPLAY_NAME:
    case Constants.USER_SET_USER_AVATAR:
      return update(state, {
        ui: {
          [action.field]: { $set: errorsReducer(state.ui, action) },
        },
      });

    case Constants.USER_LOAD_USER: {
      const ui = {};
      const data = action.data;

      ui.userId = data.userId;
      ui.displayName = data.displayName;
      ui.avatar = data.avatar;
      ui.status = 'READY';

      return _.merge({}, appDefaults.user_management, { ui });
    }

    case Constants.USER_STORE: {
      const ui = {};
      const user = action.user;

      ui.userId = user.id;
      ui.displayName = user.label;
      ui.avatar = user.avatar;
      ui.status = 'READY';

      return _.merge({}, appDefaults.user_management, { ui });
    }

    default:
      return state;
  }
}

function errorsReducer(uiState, action) {
  switch (action.type) {
    case Constants.USER_SET_AVATAR:
      if (action.value.length) {
        const avatar = _.assign({}, uiState.errors.avatar, {
          hasError: false,
        });

        return _.assign({}, uiState.errors, {
          avatar,
        });
      }
      return uiState.errors;

    default:
      return uiState.errors;
  }
}

export default user_management;
