
import reportRoutes from './reportRoutes';
import monitoringRoutes from './monitoringRoutes';
import analyticsRouters from './analyticsRouters';
import qualityRoutes from './qualityRoutes';
import savedRoutes from './savedRoutes';
import settingsRouters from './settingsRouters';


const routes = [
  {
    path: '/reporting',
    redirect: '/reporting/summary',
    title: 'Reporting',
    props: {
      routes: reportRoutes,
      videoUrl: 'https://nextiva-1.wistia.com/medias/b3sup68olz',
    },
  },
  {
    path: '/monitoring',
    redirect: '/monitoring/dashboard',
    title: 'Monitoring',
    props: {
      routes: monitoringRoutes,
      videoUrl: 'https://nextiva-1.wistia.com/medias/f6mku71awn',
    },
  },
  {
    path: '/analytics',
    redirect: '/analytics/summary/trend',
    title: 'Analytics',
    props: {
      routes: analyticsRouters,
      videoUrl: 'https://nextiva-1.wistia.com/medias/znynfqq574',
    },
  },
  {
    path: '/quality',
    redirect: '/quality/summary',
    title: 'Quality',
    props: {
      routes: qualityRoutes,
    },
  },
  {
    path: '/saved',
    redirect: '/saved/dashboard',
    title: 'Saved',
    props: {
      routes: savedRoutes,
    },
  },
  {
    path: '/settings',
    redirect: '/settings/global',
    title: 'Settings',
    props: {
      routes: settingsRouters,
    },
  },
];


export default routes;
