const Trend = function (ui) {
  this.ui = ui;
  this.params = { ui };
  const params = this.params;

  this.dailySummary = () => {
    params.rand = this.dailyRand;
    params.tableLabels = ['Date'];
    params.xGrouping = this.generateDates();
    params.dateFormat = 'YYYY-MM-DD';
    if (this.buckets) {
      params.tableLabels = ['Date', 'Buckets'];
      params.selects = this.getBuckets();
    } else {
      params.selects = this.getSelects(1);
    }
  };
};

export default Trend;
