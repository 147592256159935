import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Control } from 'react-redux-form';
import cx from 'classnames';
import _ from 'lodash';

import { isHour, isMinute } from '../validation';

// eslint-disable-next-line
class HoursMinutesInput extends Component {
  static propTypes = {
    /** Hour of the day in two digits (12, 01, 02, etc..) */
    hourModel: PropTypes.string,
    /** Minutes of the hour in two digits (09, 10, 11) */
    minuteModel: PropTypes.string,
    /** am or pm */
    meridiemModel: PropTypes.string,
  };

  render() {
    const { hourModel, minuteModel, meridiemModel } = this.props;
    return (
      <span className="hours-minutes">
        <Control.text
          model={hourModel}
          maxLength="2"
          mapProps={{
            className: ({ fieldValue }) =>
              cx('hours', {
                error: !fieldValue.valid,
              }),
          }}
          validateOn="blur"
          validators={{
            isHour,
          }}
        />
        <span className="colon">:</span>
        <Control.text
          model={minuteModel}
          maxLength="2"
          mapProps={{
            className: ({ fieldValue }) =>
              cx('minutes', {
                error: !fieldValue.valid,
              }),
          }}
          validateOn="blur"
          validators={{
            isMinute,
          }}
        />
        <Control.select className="meridiem" model={meridiemModel}>
          <option value="am">AM</option>
          <option value="pm">PM</option>
        </Control.select>
      </span>
    );
  }
}

export default HoursMinutesInput;
