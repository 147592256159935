import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { flow, sortBy, reverse } from 'lodash/fp';
import cx from 'classnames';
import ReactTimeout from 'react-timeout';
import { DefaultSpinner } from 'components';

import AppData from 'app_data';
import { calculateTrend } from 'modules/monitoring/gamification/gamification_mixins';
import Utils from 'utils/common_utils';

import arrow from 'img/arrow_up.png';

class Table extends Component {
  static propTypes = {
    unit: PropTypes.string.isRequired,
    unitLabel: PropTypes.string.isRequired,
    realTime: PropTypes.bool.isRequired,
  };

  state = {
    trendLabels: null,
    data: null,
    loading: true,
    intervalID: null,
  };

  componentDidMount() {
    this.fetchData(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.realTime) clearInterval(this.state.intervalID);
    this.setState({
      loading: true,
    });
    this.fetchData(nextProps);
  }

  fetchData = (newProps) => {
    const props = newProps || this.props;

    const params = {
      time_range: props.timeRange,
      metric: props.metric,
      n_buckets: props.nBuckets,
      bucket_type: _.snakeCase(props.filterType.id),
      filters: { [_.snakeCase(props.filterType.id)]: _.map(props.filters, 'id') },
      grouping: props.grouping,
    };

    const onSuccess = (response, tick) => {
      const { data } = response;

      const newData = flow(
        sortBy((x) => x.label),
        sortBy((x) => x.value),
        reverse
      )(data.top_n_buckets);

      this.setState({
        loading: false,
        data: newData,
        trendLabels: tick > 0 ? calculateTrend(this.state.data, newData, this.state.trendLabels) : null,
      });
    };

    let tick = 0;
    if (props.realTime) {
      AppData.getGamificationTopNByType(params).then((response) => {
        onSuccess(response, tick);
      });
      const intervalID = this.props.setInterval(() => {
        tick++;
        delete params.end;
        AppData.getGamificationTopNByType(params).then((response) => {
          onSuccess(response, tick);
        });
      }, AppData.user.refresh_rate * 1000);
      this.setState({ intervalID: intervalID });
    } else {
      AppData.getGamificationTopNByType(params).then(onSuccess);
    }
  };

  generate = () => {
    const unit = this.props.unit;
    const rowData = _.cloneDeep(this.state.data);

    const items = _.map(rowData, (row, index) => {
      const suffix = Utils.getSuffix(index + 1);
      if (unit === 'seconds') {
        row.value = Math.floor(row.value / 60);
      }

      return (
        <tr key={index} className={row.changed ? 'changed' : null}>
          <td>{index + 1}<sup>{suffix}</sup> {row.label}</td>
          <td>{row.value}<sup>{unit === 'seconds' ? 'minutes' : 'calls'}</sup></td>
          <td
            className={cx({
              trend: true,
              center: this.state.trendLabels && this.state.trendLabels[index] === null,
            })}
          >
            {this.state.trendLabels && this.state.trendLabels[index] !== null ?
              <div
                className={cx(
                  {
                    'trend-arrow': this.state.trendLabels[index] !== null,
                    down: !this.state.trendLabels[index],
                  }
                )}
              >
                <img alt="Trend change" src={arrow} />
              </div>
            : <span className="dash">&mdash;</span>}
          </td>
        </tr>
      );
    });

    return items;
  };

  render() {
    if (this.state.loading) {
      return (
        <DefaultSpinner />
      );
    }

    const tableData = this.generate();

    return (
      <table className="table">
        <thead>
          <tr>
            <th>Agent</th>
            <th>{this.props.unitLabel}</th>
            <th>Trend</th>
          </tr>
        </thead>
        <tbody>
          {tableData}
        </tbody>
      </table>
    );
  }
}

export default ReactTimeout(Table);
