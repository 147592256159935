import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import SimpleDropdown from 'components/simple_dropdown';


class DropdownDrilldown extends Component {
  static propTypes = {
    /** The initial value of the simple dropdown */
    initialValue: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    /** The initial index of the simple dropdown */
    initialIndex: PropTypes.number,
    /** Array of the options for the simple dropdown */
    options: PropTypes.array,
    /** Handler for when the simple dropdown is changed */
    onChange: PropTypes.func,
    /** The classname of the simple dropdown */
    className: PropTypes.string,
    /** The label of the simple dropdown */
    ariaLabel: PropTypes.string,
    /** Whether or not the chart is drilling down */
    isDrillingDown: PropTypes.bool,
    /** Whether or not the chart is at the second level of drill down */
    isSecondLevel: PropTypes.bool,
  };

  componentDidUpdate(prevProps) {
    if (this.props.isDrillingDown !== prevProps.isDrillingDown) {
      if (this.props.isDrillingDown && !this.props.isSecondLevel) {
        this.handleDrilldownChange(1);
      } else {
        this.handleDrilldownChange(0);
      }
    } else if (this.props.isSecondLevel !== prevProps.isSecondLevel) {
      if (this.props.isSecondLevel) {
        this.handleDrilldownChange(0);
      } else if (!this.props.isSecondLevel) {
        this.handleDrilldownChange(1);
      }
    }
  }

  handleDrilldownChange = (index) => {
    this.props.onChange(this.props.options[index]);
  }

  render() {
    return (
      <SimpleDropdown
        initialValue={this.props.initialValue}
        options={this.props.options}
        onChange={this.props.onChange}
        ariaLabel="Select detail period"
      />
    );
  }
}

export default DropdownDrilldown;
