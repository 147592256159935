import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import { DefaultSpinner } from 'components';

import AppData from 'app_data';
import { LeaderboardPopout as GamificationLeaderboard } from 'modules/monitoring/gamification/leaderboard';
import { LeaderboardTablePopout as GamificationLeaderboardTable } from 'modules/monitoring/gamification/leaderboard_table_original';
import { WallboardPopout as Wallboard } from 'modules/monitoring/wallboard.js';
import { TablePopout as GamificationTable } from 'modules/monitoring/gamification/table';
import { DashboardPopout as Dashboard } from 'modules/monitoring/dashboard.js';
import ChartPopout from 'components/charts/chart_popout';
import CustomDashboard from './Dashboard';
import loadUser from 'actions/user_actions';

import errorSplash from 'img/404.png';

const routes = [
  {
    path: '/chart/:id',
    component: ChartPopout,
  },
  {
    path: '/gamification-leaderboard/:id',
    component: GamificationLeaderboard,
  },
  {
    path: '/gamification-leaderboard-table/:id',
    component: GamificationLeaderboardTable,
  },
  {
    path: '/wallboard/:id',
    component: Wallboard,
  },
  {
    path: '/dashboard/:id',
    component: Dashboard,
  },
  {
    path: '/gamification-table/:id',
    component: GamificationTable,
  },
  {
    path: '/custom-dashboard/:dashboardId',
    component: CustomDashboard,
  },
];

// popout.scss
class PopoutContainer extends Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    loadUser: PropTypes.func,
    authenticationFailed: PropTypes.bool,
    children: PropTypes.node,
  };

  componentWillMount() {
    const { isAuthenticated, loadUser } = this.props;
    if (!isAuthenticated) {
      loadUser();
      AppData.loadAvatars();
    }
  }

  render() {
    const { isAuthenticated, authenticationFailed } = this.props;
    if (!isAuthenticated && authenticationFailed) {
      return (
        <div className="loadError">
          <img alt="Load error" src={errorSplash} />
        </div>
      );
    } else if (!isAuthenticated) {
      return (
        <DefaultSpinner />
      );
    }
    return (
      <div className="popout">
        {routes.map(route => (
          <Route
            key={route.path}
            path={`/popout${route.path}`}
            component={route.component}
          />
        ))}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.user.isAuthenticated,
  authenticationFailed: state.user.authenticationFailed,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  loadUser,
}, dispatch);

const Container = connect(mapStateToProps, mapDispatchToProps)(PopoutContainer);

export default {
  Container,
  Wallboard,
  GamificationTable,
  GamificationLeaderboard,
  GamificationLeaderboardTable,
  Dashboard,
  ChartPopout,
};
