import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { DefaultSpinner } from 'components';

import Utils from 'utils/common_utils';
import CustomChartContainer from 'components/charts/custom_chart_container';
import CustomActions from 'actions/custom_actions';
import CustomReportsFilters from 'components/filters';
import Tree from 'modules/reporting/report_tree';
import CustomReportsHeaderControls from 'modules/reporting/custom_reports_header_controls';
import vex from 'lib/vex';
import rotate from 'img/rotate.png';


class CustomReportsContainer extends Component {
  static propTypes = {
    /** Route params */
    match: PropTypes.object,
    /** Store status */
    status: PropTypes.string,
    /** Custom report actions */
    customActions: PropTypes.object,
    push: PropTypes.func,
  };

  state = {};

  componentWillMount() {
    const { customActions } = this.props;
    customActions.getFilters()
      .then(() => {
        this.loadReport();
        // this.forceUpdate();
      });
  }

  componentWillReceiveProps(nextProps) {
    const { reportId } = this.props.match.params;
    const { reportId: newReportId } = nextProps.match.params;
    if (newReportId && newReportId !== reportId) {
      this.loadReport();
    }
  }

  componentWillUnmount() {
    const { customActions } = this.props;
    customActions.clear();
  }

  loadReport = () => {
    const { customActions } = this.props;
    const { reportId } = this.props.match.params;
    if (reportId) {
      customActions.loadReport(reportId);
    }
  };

  validate = (cb, options = {}) => {
    const { customActions } = this.props;
    const errors = customActions.validate(options);

    if (!errors) {
      cb();
    } else {
      vex.error(errors);
    }
  };

  save = () => {
    const { push, customActions } = this.props;
    this.validate(() => {
      const { reportId } = this.props.match.params;
      customActions.save(reportId)
        .then((response) => {
          push(`/custom/${response.data.reportId}`);
        })
        .catch((err) => {
          vex.dialog.open({
            message: `<div class="vex-modal-message">${err.error_description}</div>`,
            contentClassName: 'error',
            buttons: [],
          });
        });
    });
  };

  run = () => {
    const { customActions } = this.props;
    this.validate(() => {
      customActions.run();
      Utils.openRoute('/custom');
    }, { excludeTitle: true });
  };

  handleEnableFilter = (...args) => {
    const { customActions } = this.props;
    customActions.enableFilter(...args);
  };

  handleSetTeamType = (teamType) => {
    const { customActions } = this.props;
    customActions.setTeamType(teamType);
  };

  render() {
    const { status } = this.props;
    if (status === 'READY') {
      return (
        <div className="reporting-custom">
          <div className="rotate-overlay">
            <img alt="Rotate screen" src={rotate} />
          </div>
          <div className="title">
            <h1>Custom report</h1>
          </div>
          <CustomReportsHeaderControls tree={Tree.get()} />
          <CustomReportsFilters
            className="reporting-custom-controls"
            setTeamType={this.handleSetTeamType}
            enableFilter={this.handleEnableFilter}
            reducerPath="custom.ui"
          />
          <section className="reporting-custom-chart">
            <div>
              <CustomChartContainer
                save={this.save}
                run={this.run}
              />
            </div>
          </section>
        </div>
      );
    }
    return (
      <DefaultSpinner />
    );
  }
}


export default connect(({ custom }) => ({
  status: custom.ui.status,
}), (dispatch) => ({
  push: bindActionCreators(push, dispatch),
  customActions: bindActionCreators(CustomActions, dispatch),
}))(CustomReportsContainer);
