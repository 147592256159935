import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import BaseChartMixin from 'components/charts/chart_mixins';
import Theme from 'theme';
import Utils from 'utils/common_utils';


class MultiAxisChart extends Component {
  static propTypes = {
    /** The title of the chart */
    exportTitle: PropTypes.string,
    /** Optional: Will hardcode a chart height */
    height: PropTypes.string,
    /** Whether or not an update is forced */
    forceRender: PropTypes.bool,
    /** A unique identifier for the chart being displayed */
    chartKey: PropTypes.string,
    /** The data to be displayed on the chart */
    chartData: PropTypes.object,
    /** The position of the legend */
    legendPosition: PropTypes.string,
    /** An array of the items in the legend */
    enabledLegendItems: PropTypes.array,
    /** The time range that the chart will represent */
    timeRange: PropTypes.object,
    /** The classname for the kind of chart that will be used */
    className: PropTypes.string,
    /** Whether or not download is disabled */
    disableDownload: PropTypes.bool,
    /** Helper function for formatting seconds for balloon values */
    formatSeconds: PropTypes.func,
    /** Function for determining legend position based on props */
    getLegendPosition: PropTypes.func,
  };

  graphData = () => {
    const data = this.props.chartData;

    this.chart.graphs = [];
    this.chart.dataProvider = [];

    if (this.props.chartData.meta.date_format) {
      this.chart.dataDateFormat = data.meta.date_format;
    }

    this.chart.categoryAxis = {
      parseDates: data.meta.date_format,
      axisAlpha: 0,
      gridAlpha: 0,
      tickLength: 0,
      boldPeriodBeginning: false,
      equalSpacing: true,
    };

    // greater than or equal to 8 graph items, rotate label
    if (this.props.chartData.data.length >= 8) {
      this.chart.categoryAxis.labelRotation = 45;
    }

    const enabledLegendItemLabels = _.map(this.props.enabledLegendItems, 'label');

    _.forEach(data.meta.items, (item) => {
      const unit = item.unit === 'seconds' ? 'minutes' : 'calls';
      this.chart.graphs.push({
        balloonText: `[[title]]<br><span style="font-size:14px;"><b>[[value]] ${unit}</b></span>`,
        balloonFunction: unit === 'minutes' && this.props.formatSeconds || null,
        fillAlphas: unit === 'minutes' ? 0 : 1,
        lineAlpha: 1,
        valueAxis: unit,
        title: item.label,
        type: unit === 'minutes' ? 'line' : 'column',
        valueField: item.label,
        lineColor: Theme.getColor(item.label),
        hidden: this.props.enabledLegendItems ? !_.includes(enabledLegendItemLabels, item) : false,
      });
    });

    _.forEach(data.data, (d) => {
      this.chart.dataProvider.push(d);
    });
  };

  buildChart = () => {
    const meta = this.props.chartData.meta;

    this.chart = new window.AmCharts.AmSerialChart();
    this.chart.categoryField = meta.categoryField;
    this.chart.fontFamily = 'lato';
    this.chart.addClassNames = true;
    this.chart.fontSize = 14;
    this.chart.chartCursor = { cursorColor: '#3D85D1' };
    this.chart.synchronizeGrid = true;
    this.chart.autoMargins = true;
    this.enableExport(this.props.exportTitle);

    this.chart.legend = {
      enabled: _.isUndefined(this.props.legendPosition) || this.props.legendPosition !== 'none',
      position: this.props.getLegendPosition(),
      autoMargins: false,
      borderAlpha: 0,
      equalWidths: !_.isUndefined(meta.legendEqualWidths) ? meta.legendEqualWidths : false,
      markerSize: 10,
      valueAlign: 'left',
      valueWidth: 0,
      maxColumns: meta.legendEqualWidths ? Math.floor(meta.items.length / 2) : 6,
      useGraphSettings: true,
      divId: `${this.props.chartKey}Legend`,
    };

    const formatSeconds = (seconds) => {
      const minutes = Utils.numberWithCommas(Math.floor(seconds / 60));
      return minutes;
    };

    this.chart.valueAxes = [
      {
        id: meta.axes[0].id,
        position: meta.axes[0].position,
        axisAlpha: 0,
        type: meta.axes[0].type ? meta.axes[0].type : 'numeric',
        labelFunction: meta.axes[0].unit === 'minutes' ? formatSeconds : null,
        title: meta.axes[0].title || null,
      },
      {
        id: meta.axes[1].id,
        position: meta.axes[1].position,
        axisAlpha: 0,
        type: meta.axes[1].type ? meta.axes[1].type : 'numeric',
        labelFunction: meta.axes[1].unit === 'minutes' ? formatSeconds : null,
        title: meta.axes[1].title || null,
      },
    ];

    this.graphData();
    this.chart.write(this.props.chartKey);
  };

  render() {
    const style = {};
    if (this.props.height) {
      style.height = this.props.height;
    }

    return (
      <div
        className="multiaxischart"
        id={this.props.chartKey}
        style={style}
      />
    );
  }
}

export default BaseChartMixin(MultiAxisChart);
