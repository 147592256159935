import React, { memo } from 'react';
import { ModalTitle, ModalExitButton } from '@nextiva/next-ui';


import { ReactComponent as IconMissed } from 'img/missed_calls.svg';
import { ReactComponent as IconInbound } from 'img/inbound.svg';
import { ReactComponent as IconOutbound } from 'img/outbound.svg';
import Styles from './Header.module.scss';


const DirectionIcons = {
  missed: <IconMissed className={Styles.icon} />,
  inbound: <IconInbound className={Styles.icon} />,
  outbound: <IconOutbound className={Styles.icon} />,
};


const Header = memo((props) => {
  const {
    title,
    direction,
    onCloseModal,
  } = props;
  
  return (
    <>
      <ModalTitle className={Styles.title}>
        {DirectionIcons[direction]}
        {title}
      </ModalTitle>

      <ModalExitButton onClick={onCloseModal} />
    </>
  )
});


Header.displayName = 'Header';


export default Header;