import validator from 'validator';
import moment from 'moment';
import _ from 'lodash';

import Utils from 'utils/common_utils';

const MAX_NAME_LENGTH = 50;

export const required = (val) => val && val.length;
export const isHour = (val) => validator.isInt(val, { min: 0, max: 12, allow_leading_zeroes: true });
export const isMinute = (val) => validator.isInt(val, { min: 0, max: 59, allow_leading_zeroes: true });
export const scheduleNameLength = (val) => validator.isLength(val, { min: 0, max: MAX_NAME_LENGTH });
export function checkDateValue(props) {
  const { value } = props.viewValue;
  if (!value) {
    return moment();
  }
  return value;
}
export function validateEmail(value) {
  const emails = value.split(',');
  const errors = [];
  emails.forEach((email, index) => {
    if (!validator.isEmail(email.trim())) {
      errors.push({
        error: `Incorrect email: ${email}`,
      });
    }
  });
  return errors.length === 0;
}
export function validateStartEnd({ start, end }) {
  const startTime = Utils.toggleTime(_.cloneDeep(start));
  const endTime = Utils.toggleTime(_.cloneDeep(end));
  return startTime < endTime;
}
export function validateDistWeekdays({ frequency, distributeWeekdays }) {
  const validFrequencies = ['minutes', 'hourly', 'weekly'];
  if (!validFrequencies.includes(frequency)) {
    return true;
  }
  const hasSelected = Object.values(distributeWeekdays).some((val) => val);
  return hasSelected;
}
export function validateDaysOfWeek({ unit, start, end }) {
  if (unit !== 'weekdays') {
    return true;
  }
  const startInteger = parseInt(start.value, 10);
  const endInteger = parseInt(end.value, 10);

  if (startInteger > endInteger) {
    return false;
  } else if (startInteger === endInteger) {
    return false;
  }
  return true;
}
export const errorMessages = {
  'timezone:required': 'A timezone is required',
  'reportId:required': 'A custom report is required',
  'scheduleName:required': 'Schedule name is required',
  'scheduleName:unique': 'Report name already exists',
  'scheduleName:isLength': 'Report name exceeds 50 characters',
  'email:required': 'Email addresses field is required',
  'email:validEmail': 'An email is invalid',
  'run:afterToday': 'Run until field must be after today',
  'dates:distributeLessThanStart': 'Distribute date is before start date',
  '$form:startBeforeEnd': 'Start time must be before end time',
  '$form:distributeWeekdays': 'At least one distribution weekday is required',
  '$form:daysOfWeek': 'Start day must be before end day',
};
