import _ from 'lodash';
import type from 'type-of';

import axios, { CancelToken, isCancel } from 'axios_client';
import AppData from 'app_data';
import Utils from 'utils/common_utils';
import { getDate } from 'utils/sample_data/sample_base';

export function serialToPies(data, order) {
  const pieCharts = [];

  _.each(data.data, (row) => {
    const chart = {
      meta: {
        categoryField: 'category',
        valueField: 'value',
        items: [],
      },
      data: [],
    };

    _.each(data.meta.items, (key) => {
      chart.meta.items.push(key);
      chart.data.push({ category: key, value: row[key] });
    });

    chart.meta.title = row.category;
    pieCharts.push(chart);
  });

  return pieCharts;
}

export function convertToPie(obj, rand) {
  obj.meta.valueField = 'value';

  obj.data = _.map(obj.meta.items, (label) => {
    const item = {};
    item.category = label;
    item[label] = label;
    item.value = rand();
    return item;
  });

  return obj;
}

export function convertToTable(config, dates) {
  const table = { rows: [] };
  const categoryField = config.meta && config.meta.categoryField || 'category';
  const isCSV = config.outputToCSV || false;
  table.swap = config.meta && config.meta.swap || false;

  table.headers = config.meta.items;

  const labels = config.meta.tableLabels || ['', ''];

  if (labels[1]) table.altHeader = labels[1];

  if (!config.data.length) {
    table.headers = [`${table.altHeader} - No data with selected options`];

    table.rows = [];
  } else if (config.data[0][config.data[0][categoryField]]) {
    table.rows = [
      {
        data: [getDate(dates)].concat(_.map(config.data, (o) => {
          const header = _.find(table.headers, { label: o[categoryField] });
          if (_.isUndefined(header)) {
            return o[o[categoryField]];
          }
          if (header.unit === 'seconds' && !isCSV) {
              return Utils.humanizeSeconds(o[o[categoryField]]);
          } else if (header.unit === 'seconds' && isCSV) {
              return Utils.formatFromSeconds(o[header.label], 'HH:mm:ss');
          } else if (header.unit === 'calls') {
            return Utils.numberWithCommas(o[o[categoryField]]);
          }
          return o[o[categoryField]];
        })),
      },
    ];

    if (type(table.headers[0]) === 'object') {
      table.headers = [labels[0]].concat(_.map(table.headers, (h) => h.label));
    } else {
      // single row data
      table.headers = ['Date Range - SAMPLE DATA'].concat(table.headers);
    }
  } else {
    // multiple rows

    // if headers are objects, can check for other props
    if (type(table.headers[0]) === 'object') {
      _.each(config.data, (o) => {
        const row = { data: [o[categoryField]], suffix: null };
        _.each(table.headers, (header) => {
          if (!_.isUndefined(o[header.label])) {
            if (header.unit === 'seconds' && !isCSV) {
              row.data.push(Utils.humanizeSeconds(o[header.label]));
            } else if (header.unit === 'seconds' && isCSV) {
              row.data.push(Utils.formatFromSeconds(o[header.label], 'HH:mm:ss'));
            } else if (header.unit === 'calls') {
              const callsLabel = o[header.label];
              const isFloat = !_.isInteger(callsLabel);
              const callsValue = isFloat ? callsLabel.toFixed(1) : callsLabel;

              row.data.push(callsValue);
            } else {
              row.data.push(o[header.label]);
            }
          }
        });
        table.rows.push(row);
      });
      table.headers = [labels[0]].concat(_.map(table.headers, (h) => h.label));
    } else {
      _.each(config.data, (o) => {
        const row = { data: [o[categoryField]] };
        _.each(table.headers, (header) => {
          if (!_.isUndefined(o[header])) row.data.push(o[header]);
        });
        table.rows.push(row);
      });
      table.headers = [labels[0]].concat(table.headers);
    }
  }

  return table;
}

export const chartMap = {
  column: 'serial',
  scatter: 'xy',
  line: 'serial',
  heat: 'usaLow',
  bar: 'serial',
  pie: 'pie',
  'Smooth Line': 'serial',
  'Stacked Bar': 'serial',
  'Stacked Column': 'serial',
  'USA': 'usaLow',
  'World': 'worldLow',
};

export function handlePopout() {
  // save state and props
  const params = {};
  const { controlsState, ...props } = this.props;
  params.componentState = _.assign({}, props, this.state);
  params.componentState.ready = false;
  params.componentState.data = null;

  params.controlsState = _.assign({}, controlsState);

  // Save params to server, redirect with returned hash on data.id
  AppData.savePopoutParams(params).then((response) => {
    const { data } = response;
    const route = `${process.env.REACT_APP_PUBLIC_PATH || ''}/popout/chart/${data.id}`;
    const popoutWindow = window.open(route);

    // ipad / safari blocks popouts, redirect page
    if (!popoutWindow) {
      window.location.href = route;
    }
  });
}

export function fetchChartData(props) {
  props = props || this.props;
  let timeRange = props.timeRange;
  const source = CancelToken.source();
  let resolved = false;
  let canceled = false;

  const onError = Utils.createDelayedCallback((response) => {
    if (isCancel(response)) {
      return;
    }
    this.setState({
      error: true,
      loading: true,
    });
  }, 400);

  const onSuccess = Utils.createDelayedCallback((response) => {
    const { data } = response;
    resolved = true;
    this.setState({
      chartData: data,
      error: false,
      loading: false,
    });
  }, 400);

  if (props.realTime) {
    timeRange = _.assign({}, props.timeRange);
    delete timeRange.end;
  }

  let params = {
    amchart_type: chartMap[props.chart.name],
    unit: props.unit,
    selects: props.selects,
    labels: props.labels,
    time_range: timeRange,
    time_ranges: props.timeRanges,
    filters: props.filters,
    minute_buckets: props.minuteBuckets,
  };

  if (props.extraParams) {
    params = Object.assign(params, props.extraParams);
  }

  const promise = axios.post(props.url, params, { cancelToken: source.token })
    .then(onSuccess)
    .catch(onError);
  promise.cancel = () => {
    if (!canceled && !resolved) {
      source.cancel();
      canceled = true;
    }
  };
  return promise;
}

export function handleRetry() {
  this.setState({
    error: false,
    loading: true,
  }, this.fetchChartData);
}

export function handleUnitChange(unit) {
  this.setState({
    unit,
  });
}

export function handleChartChange(chart) {
  this.setState({
    chart,
  });
}
