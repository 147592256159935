import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import _ from 'lodash';
import onClickOutside from 'react-onclickoutside';

import vex from 'lib/vex';
import arrow from 'img/modal_arrow.png';
import InlineSvgFactory from 'components/inline_svg';
import Row from 'components/buckets_modal_row';

const BucketsIcon = InlineSvgFactory('buckets.svg');

class BucketsModal extends Component {
  static propTypes = {
    /** Array of values in the buckets modal */
    value: PropTypes.array,
    /** Handler for changing the bucket modal */
    onChange: PropTypes.func,
    /** The className of the buckets modal */
    className: PropTypes.string,
  };

  state = {
    rows: this.props.value || [{ from: 0 }],
  };

  componentWillReceiveProps(nextProps) {
    this.setState({ rows: nextProps.value });
  }

  handleClickOutside = (e) => {
    if (!e.target.className.indexOf ||
      (e.target.className.indexOf('react-datepicker') !== 0
        && e.target.className.indexOf('vex') !== 0)) {
      if (this.state.modal) {
        this.save();
      }
    }
  };

  toggle = () => {
    this.setState({ modal: !this.state.modal });
  };

  save = () => {
    // error handling
    const hasError = _.some(this.state.rows, (o) => o.toError);

    if (hasError) {
      return vex.error(['Please fix buckets before proceeding.']);
    }

    this.props.onChange([...this.state.rows]);
    this.toggle();
  };

  handleAddRow = (index) => {
    if (this.state.rows.length === 5) return;
    const rows = this.state.rows;
    const previous = this.state.rows[index];
    rows.splice(index + 1, 0, { from: previous.to });
    this.setState({ rows });
  };

  handleRemoveRow = (index) => {
    const rows = this.state.rows;
    rows.splice(index, 1);
    this.setState({ rows });
  };

  handleRowChange = (index, obj) => {
    const rows = this.state.rows;
    rows[index] = obj;
    this.setState({ rows });
  };

  render() {
    let modal;
    if (this.state.modal) {
      const rowsLength = this.state.rows.length;
      const rows = _.map(this.state.rows, (row, index) => (
        <Row
          key={index}
          disableMinus={index === 0 && rowsLength === 1}
          addRow={this.handleAddRow.bind(this, index)}
          removeRow={this.handleRemoveRow.bind(this, index)}
          onChange={this.handleRowChange.bind(this, index)}
          toError={row.toError}
          from={row.from}
          to={row.to}
        />
      ));
      modal = (
        <div className="modal-buckets">
          <img className="arrow" src={arrow} role="presentation" />
          <div className="modal-buckets-title">Minute buckets:</div>
          {rows}
          <button onClick={this.save} className="btn btn-green pull-right update">
            Update
          </button>
        </div>
      );
    }
    return (
      <div className={`date-modal ${this.props.className}`}>
        <span>
          <span className="buckets-label">
            {this.state.rows.length} bucket{this.state.rows.length > 1 ? 's' : null}
          </span>
          <BucketsIcon className="buckets-icon" onClick={this.toggle} />
        </span>
        {modal}
      </div>
    );
  }
}

export default onClickOutside(BucketsModal);
