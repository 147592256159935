import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Form, actions } from 'react-redux-form';
import BEMHelper from 'react-bem-helper';

import vex from 'lib/vex';
import AppData from 'app_data';
import Button from 'components/button';
import Utils from 'utils/common_utils';
import { convertWeekday } from 'modules/saved/Scheduled/utils';

import {
  NAME,
  SECTIONS,
  INITIAL_STATE,
} from '../constants';

import {
  errorMessages as ERROR_MESSAGES,
  validateStartEnd,
  validateDistWeekdays,
  validateDaysOfWeek,
} from '../validation';

import ReportingPeriod, { showExcludeTodayOptions } from './reporting_period';
import Section from './section';
import Schedule from './schedule';
import Distribution from './distribution';
import Delivery from './delivery';


const classes = new BEMHelper('scheduled-report-form');


export class ScheduledReportForm extends Component {
  static propTypes = {
    /** Scheduled report form state */
    scheduledReport: PropTypes.object,
    /** Actions for modifying form directly */
    formActions: PropTypes.object,
    /** Saved scheduled report configuration */
    reportConfig: PropTypes.object,
    /** Available custom reports for scheduling */
    availableReports: PropTypes.array,
    /** List of current report names */
    reportNames: PropTypes.array,
    /** Function for cancel button */
    onCancel: PropTypes.func,
    /** Function to pass the ref */
    reportRef: PropTypes.func,
  };

  componentDidMount() {
    const { reportConfig, formActions } = this.props;

    if (reportConfig) {
      formActions.load(NAME, this.loadConfig(reportConfig));
    } else {
      formActions.load(NAME, {
        ...INITIAL_STATE,
        timezone: AppData.user.timezone,
      });
    }
  }

  getWeekdayStrings = () => ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];

  loadConfig = (reportConfig) => {
    // TODO: need to refactor this part of code
    // TODO: dataProvider
    const newState = _.cloneDeep(INITIAL_STATE);

    ['start', 'end'].forEach((field) => {
      let newField = {};

      if (reportConfig[`${field}_time`]) {
        const { hours, minutes, meridiem } = Utils.toggleTime(reportConfig[`${field}_time`]);

        newField = {
          hours,
          minutes,
          meridiem,
          value: reportConfig[`${field}_on`],
        };
      }

      newState[field] = newField;
    });

    const distributeStart = reportConfig['distribute_time'] || reportConfig['distribute_start_time'];

    if (distributeStart) {
      const { hours, minutes, meridiem } = Utils.toggleTime(distributeStart);

      newState.distributeStart = {
        hours,
        minutes,
        meridiem,
        value: reportConfig['distribute_on'],
      };
    }

    newState.times = reportConfig.distribute_times || [];

    if (Array.isArray(newState.times)) {
      newState.times = newState.times.map((time) => {
        const { hours, minutes, meridiem } = Utils.toggleTime(time);
        return { hours, minutes, meridiem };
      });
    }

    if (reportConfig.distribute_end_time) {
      newState.distributeEnd = Utils.toggleTime(reportConfig.distribute_end_time);
    }

    switch (reportConfig.report_type) {
      case 'daily': {
        newState.interval = parseInt(reportConfig['distribute_on'], 10);
        break;
      }

      case 'minutes':
      case 'hourly':
      case 'weekly': {
        const [, interval, weekdays] = reportConfig.distribute_on.match(/([^,]*),(.*)/);
        const days = JSON.parse(weekdays);

        // build weekdays array
        const weekdayStrings = this.getWeekdayStrings();
        newState.distributeWeekdays = {};

        _.each(weekdayStrings, (day) => {
          newState.distributeWeekdays[day] = false;
        });

        _.each(days, (day) => {
          newState.distributeWeekdays[convertWeekday(day)] = true;
        });

        newState.interval = interval;
        break;
      }

      case 'monthly': {
        const distributeOn = reportConfig.distribute_on.split(',');
        const [dist, num, month, ...rest] = distributeOn;
        let weekday = 'mon';

        if (dist === 'weekday') {
          weekday = convertWeekday(rest[0]);
        }

        newState.monthly = {
          dist,
          num: num === 'last' ? num : parseInt(num, 10),
          month: parseInt(month, 10),
          weekday,
        };
        break;
      }
    }

    if (reportConfig.distribute_date_end) {
      const date = moment(new Date(reportConfig.distribute_date_end));

      newState.run = {
        value: date.format('YYYY-MM-DD'),
        hours: date.format('hh'),
        minutes: date.format('mm'),
        meridiem: date.format('a'),
      };
    } else {
      newState.run = {
        value: 'forever',
      };
    }

    newState.id = reportConfig.id;
    newState.period = reportConfig.report_period || 'fixed';
    newState.unit = reportConfig.relative_unit || 'days';
    newState.quantity = reportConfig.relative_quantity || 1;
    newState.scheduleName = reportConfig.name;
    newState.frequency = reportConfig.report_type;
    newState.reportId = String(reportConfig.custom_report_id);
    newState.reportHeader = reportConfig.name;
    newState.timezone = reportConfig.timezone;
    newState.email = reportConfig.email;
    newState.excludeToday = reportConfig.exclude_today || false;

    return newState;
  };


  handleAddDistributionTime = () => {
    this.props.formActions.push(
      `${NAME}.times`,
      {
        hours: '11',
        minutes: '59',
        meridiem: 'pm',
      },
    );
  };


  handleRemoveDistributionTime = (index) => {
    this.props.formActions.remove(`${NAME}.times`, index);
  };


  handleSubmitFailed = (scheduledReportForm) => {
    const errors = [];

    Object.keys(scheduledReportForm).forEach((formKey) => {
      const formValue = scheduledReportForm[formKey];

      if (formValue.errors) {
        Object.keys(formValue.errors).forEach((errorKey) => {
          const errorValue = formValue.errors[errorKey];
          const errorField = `${formKey}:${errorKey}`;
          const errorMessage = ERROR_MESSAGES[errorField];

          if (errorValue && errorMessage) {
            errors.push(errorMessage);
          }
        });
      }
    });

    vex.error(errors);
  };


  handleSubmit = (scheduledReport) => {
    // TODO: dataProvider
    const {
      id,
      run,
      unit,
      times,
      email,
      period,
      monthly,
      interval,
      reportId,
      quantity,
      timezone,
      scheduleName,
      excludeToday,
      distributeEnd,
      distributeStart,
      distributeWeekdays,
      frequency: reportType,
    } = scheduledReport;

    const payload = {
      id,
      timezone,
      start_on: null,
      start_time: null,
      end_on: null,
      end_time: null,
      distribute_date_end: null,
      distribute_on: null,
      distribute_time: null,
      distribute_times: null,
      distribute_start: null,
      distribute_end: null,
      relative_unit: null,
      relative_quantity: null,
      report_type: reportType,
      report_period: period,
      name: scheduleName.trim(),
      custom_report_id: reportId,
      email: _.uniq(_.map(email.split(','), (e) => e.trim())).join(', '),
    };

    if (period === 'relative') {
      payload.relative_unit = unit;
      payload.relative_quantity = String(quantity);
      payload.exclude_today = showExcludeTodayOptions.includes(unit) && excludeToday;
    }

    ['start', 'end'].forEach((key) => {
      const obj = scheduledReport[key];
      const { value } = obj;
      const snakeKey = _.snakeCase(key);

      payload[`${snakeKey}_on`] = moment.isMoment(value)
        ? value.format('YYYY-MM-DD')
        : value;

      payload[`${key}_time`] = Utils.toggleTime(obj);
    });

    switch (reportType) {
      case 'daily': {
        payload.distribute_on = String(interval);
        break;
      }

      case 'minutes':
      case 'hourly':
        payload.distribute_start_time = Utils.toggleTime(distributeStart);
        payload.distribute_end_time = Utils.toggleTime(distributeEnd);

      case 'weekly': {
        const weekdays = Object.keys(distributeWeekdays)
          .filter(weekday => Boolean(distributeWeekdays[weekday]))
          .map(weekday => convertWeekday(weekday));

        payload.distribute_on = `${interval},${JSON.stringify(weekdays)}`;
        break;
      }

      case 'monthly': {
        const { dist, month, num, weekday } = monthly;

        if (dist === 'day') {
          payload.distribute_on = `${dist},${num},${month}`;
        } else if (dist === 'weekday') {
          payload.distribute_on = `${dist},${num},${month},${convertWeekday(weekday)}`;
        }

        payload['distribute_time'] = Utils.toggleTime(distributeStart);
        break;
      }

      case 'one_time': {
        const { value } = distributeStart;

        payload.run_on = '';
        payload['distribute_on'] = moment.isMoment(value)
          ? value.format('YYYY-MM-DD')
          : value;

        payload['distribute_time'] = Utils.toggleTime(distributeStart);
        break;
      }
    }

    payload.distribute_times = times.map((time) => Utils.toggleTime(time));

    // run ref is consistent for all types
    if (run.value === 'forever' || reportType === 'one_time') {
      payload.distribute_date_end = null;
    } else {
      const hoursMinutes = Utils.toggleTime(run);
      const date = run.value;
      payload.distribute_date_end = moment.tz(`${date} ${hoursMinutes}:00`, timezone).utc().format('YYYY-MM-DD HH:mm:ss');
    }

    this.saveToServer(payload);
  };


  saveToServer = (payload) => {
    AppData.saveScheduledReport(payload)
      .then(() => {
        window.location.reload();
      })
      .catch(({ data }) => {
        vex.error([data.error_description]);
      });
  };


  renderSection(section) {
    const {
      scheduledReport,
      availableReports,
      reportNames,
    } = this.props;

    switch (section.key) {
      case 'schedule':
        return (
          <Schedule
            availableReports={availableReports}
            reportNames={reportNames}
          />
        );

      case 'reporting':
        return (
          <ReportingPeriod
            start={scheduledReport.start}
            end={scheduledReport.end}
            relativeUnit={scheduledReport.unit}
            weekdays={scheduledReport.relativeWeekdays}
            period={scheduledReport.period}
            excludeToday={scheduledReport.excludeToday}
          />
        );

      case 'distribution':
        return (
          <Distribution
            frequency={scheduledReport.frequency}
            distributeStart={scheduledReport.distributeStart}
            distributeEnd={scheduledReport.distributeEnd}
            run={scheduledReport.run}
            distributeWeekdays={scheduledReport.distributeWeekdays}
            times={scheduledReport.times}
            handleAddDistributionTime={this.handleAddDistributionTime}
            handleRemoveDistributionTime={this.handleRemoveDistributionTime}
          />
        );

      case 'delivery':
        return (
          <Delivery />
        );

      default:
        return 'Something ain\'t right here...';
    }
  }

  render() {
    const { onCancel, reportRef } = this.props;

    return (
      <div ref={reportRef}>
        <Form
          className="scheduled-report-form"
          model={NAME}
          onSubmit={this.handleSubmit}
          onSubmitFailed={this.handleSubmitFailed}
          validators={{
            '': {
              startBeforeEnd: validateStartEnd,
              distributeWeekdays: validateDistWeekdays,
              daysOfWeek: validateDaysOfWeek,
            },
          }}
        >
          {SECTIONS.map((section, index) => (
            <Section
              key={section.title}
              number={index + 1}
              section={section}
            >
              {this.renderSection(section)}
            </Section>
          ))}

          <div {...classes('buttons', null, 'buttons pull-right')}>
            <Button onClick={onCancel} className="btn">
              Cancel
            </Button>

            <Button
              onClick={this.handleSave}
              className="btn btn-green"
              type="submit"
            >
              Save
            </Button>
          </div>
        </Form>
      </div>
    );
  }
}


const mapStateToProps = (state) => ({
  scheduledReport: state.scheduled_report,
});


const mapDispatchToProps = (dispatch) => ({
  formActions: bindActionCreators(actions, dispatch),
});


export default connect(mapStateToProps, mapDispatchToProps)(ScheduledReportForm);
