import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import Utils from 'utils/common_utils';
import { TableContainer } from 'modules/monitoring/gamification/table';

class Tables extends Component {
  static propTypes = {
    realTime: PropTypes.bool.isRequired,
    callType: PropTypes.object.isRequired,
    filterType: PropTypes.object.isRequired,
    filters: PropTypes.array.isRequired,
    timeRange: PropTypes.object.isRequired,
    nBuckets: PropTypes.number.isRequired,
    grouping: PropTypes.bool.isRequired,
  };

  state = {};

  getMetric = (displayType, callType) => {
    if (displayType === 'calls') {
      return callType;
    }
    return { label: callType.label, metric: `${callType.metric}_duration` };
  };

  metricsByCallType = (callType) => [this.getMetric('calls', callType), this.getMetric('duration', callType)];

  render() {
    const tables = [];
    _.each(this.metricsByCallType(this.props.callType), (metric, index) => {
      const unit = index === 0 ? 'calls' : 'seconds';
      tables.push(
        <div key={index} className="col-half">
          <TableContainer
            realTime={this.props.realTime}
            metric={metric}
            unitLabel={Utils.labelByUnit(unit)}
            nBuckets={this.props.nBuckets}
            unit={unit}
            timeRange={this.props.timeRange}
            filters={this.props.filters}
            selectedType={this.props.filterType}
            tableLabels={[this.props.callType.label, Utils.labelByUnit(unit)]}
            grouping={this.props.grouping}
          />
        </div>
      );
    });

    return (
      <div className="row">
        {tables}
      </div>
    );
  }
}

export default Tables;
