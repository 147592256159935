import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import moment from 'moment-timezone';

import AppData from 'app_data';
import { ReportCallLogContainer, OptionsSource } from 'modules';
import AnalyticsDetailTables from 'components/analytics_detail_tables';


const excludeInternalOptionSource = ['summary', 'location', 'phone-number'];


class AnalyticsTablePanel extends Component {
  static propTypes = {
    /** Filters the calls by metric: "filters": {inbound: true, outbound: true} */
    filters: PropTypes.object,
    /** The kind of unit being displaedy (calls, minutes, etc...) */
    unit: PropTypes.string,
    /** The data fields that are expected to be returned: (start_time,
    user_id, calling_number, called_number, talk_duration_seconds, etc...) */
    selects: PropTypes.array,
    /** The time range being represented by the table */
    timeRange: PropTypes.object,
    /** An array of the time ranges for a chart with multiple time ranges */
    timeRanges: PropTypes.array,
    /** Whether or not the table is displaying real time data */
    realTime: PropTypes.bool,
    /** The dropdown period options for a detail table */
    detailPeriodOptions: PropTypes.array,
    /** The url for the detailed table data (in this case trend-table-detail) */
    detailUrl: PropTypes.string,
    /** The url for the call log */
    callLogUrl: PropTypes.string,
    /** The selected filtering option for the call log table */
    callFilter: PropTypes.string,
    /** The column metrics in the call log table */
    callLogFields: PropTypes.array,
    /** The selected detail Period Option */
    activeTab: PropTypes.string,
    /** Whether or not the chart is drilling down */
    isDrillingDown: PropTypes.bool,
    /** Whether or not the chart is at the second level of dril down */
    isSecondLevel: PropTypes.bool,
    /** The metric being drilled down on */
    drillDownMetric: PropTypes.string,
    /** The initial value of the simple dropdown */
    initialValue: PropTypes.object,
    /** What chart is this a table for. */
    chart: PropTypes.object,
  };

  state = {
    tableType: 'detail',
    selectedCallFilter: this.props.callFilter || 'total_calls',
  };


  getTimeRange = () => {
    const { timeRange, timeRanges } = this.props;

    if (timeRange) {
      return timeRange;
    }

    const starts = timeRanges.map((timeRange) => 
      moment.utc(timeRange.start)
    );

    const ends = timeRanges.map((timeRange) => 
      moment.utc(timeRange.end)
    );
    
    return {
      type: 'absolute',
      start: moment.min(starts).tz(AppData.user.timezone).toJSON(),
      end: moment.max(ends).tz(AppData.user.timezone).toJSON(),
    };
  };


  getOptionSource = () => {
    const { activeTab } = this.props;

    if (excludeInternalOptionSource.includes(activeTab)) {
      return OptionsSource.filter((option) => option.value !== 'internal');
    }

    return OptionsSource;
  };


  getTableComponent = () => {
    const {
      unit,
      selects,
      filters,
      realTime,
      detailUrl,
      callLogUrl,
      timeRange,
      timeRanges,
      initialValue,
      callLogFields,
      isSecondLevel,
      isDrillingDown,
      detailPeriodOptions,
      chart,
    } = this.props;

    if (this.state.tableType === 'detail') {
      return (
        <AnalyticsDetailTables
          url={detailUrl}
          unit={unit}
          periodOptions={detailPeriodOptions}
          filters={filters}
          selects={selects}
          timeRange={timeRange}
          timeRanges={timeRanges}
          realTime={realTime}
          initialValue={initialValue}
          isDrillingDown={isDrillingDown}
          isSecondLevel={isSecondLevel}
          chart={chart}
        />
      );
    } else if (this.state.tableType === 'call_log') {
      return (
        <div className="report-call-log">
          <ReportCallLogContainer
            pagination
            url={callLogUrl}
            filters={filters}
            realTime={realTime}
            selects={callLogFields}
            timeRange={this.getTimeRange()}
            errorClassName="error-placeholder"
            filterOptions={this.getOptionSource()}
          />
        </div>
      );
    }
  };

  changeTableType = (tableType) => {
    this.setState({ tableType });
  };

  render() {
    return (
      <div className="table-panel">
        <div className="buttons">
          <button className={cx({ 'table-panel-btn': true, 'active': this.state.tableType === 'detail' })} onClick={this.changeTableType.bind(this, 'detail')}>
            Detail
          </button>
          <button className={cx({ 'table-panel-btn': true, 'active': this.state.tableType === 'call_log' })} onClick={this.changeTableType.bind(this, 'call_log')}>
            Call log
          </button>
        </div>
        {this.getTableComponent()}
      </div>
    );
  }
}

export default AnalyticsTablePanel;
