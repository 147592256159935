import Page from 'modules/reporting/report_page';
import Custom from 'modules/reporting/custom';


const routes = [
  {
    path: '/reporting/summary',
    title: 'Summary',
    parameter: '/:filter?',
    component: Page,
  },
  {
    path: '/reporting/location',
    title: 'Location',
    parameter: '/:filter?',
    component: Page,
  },
  {
    path: '/reporting/call-group',
    title: 'Call group',
    parameter: '/:filter?',
    component: Page,
  },
  {
    path: '/reporting/user-activity',
    title: 'User activity',
    parameter: '/:filter?',
    component: Page,
  },
  {
    path: '/reporting/phone-number',
    title: 'Phone number',
    parameter: '/:filter?',
    component: Page,
  },
  {
    path: '/reporting/custom',
    title: 'Custom',
    parameter: '/:reportId?',
    component: Custom,
  },
];


export default routes;
