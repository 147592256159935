import _ from 'lodash';
import { batchActions } from 'redux-batched-actions';

import * as CUSTOM_DASHBOARD from 'constants/custom_dashboard';
import AppData from 'app_data';

function loadDashboardRequest() {
  return { type: CUSTOM_DASHBOARD.LOAD_DASHBOARD_REQUEST };
}

function loadDashboardSuccess(response) {
  const { data } = response;
  return { type: CUSTOM_DASHBOARD.LOAD_DASHBOARD_SUCCESS, data };
}

function loadDashboardFailure(error) {
  return { type: CUSTOM_DASHBOARD.LOAD_DASHBOARD_FAILURE, error };
}

export function loadDashboard(id, viewing) {
  return (dispatch, getState) => {
    dispatch(loadDashboardRequest());
    return AppData.getDashboard(id)
      .then((response) => {
        if (!viewing) {
          dispatch(batchActions([
            loadDashboardSuccess(response),
            toggleEditDashboard(),
          ]));
          return response;
        }
        dispatch(loadDashboardSuccess(response));
      })
      .catch((error) => {
        dispatch(loadDashboardFailure(error));
      });
  };
}

function saveDashboardRequest() {
  return { type: CUSTOM_DASHBOARD.SAVE_DASHBOARD_REQUEST };
}

function saveDashboardSuccess(response) {
  const { data } = response;
  return { type: CUSTOM_DASHBOARD.SAVE_DASHBOARD_SUCCESS, data };
}

function saveDashboardFailure(error) {
  return { type: CUSTOM_DASHBOARD.SAVE_DASHBOARD_FAILURE, error };
}

export function saveDashboard(dashboardId) {
  return (dispatch, getState) => {
    dispatch(saveDashboardRequest);
    const dashboardState = getState().custom_dashboard;
    const savedDashboard = getDashboardInfo(dashboardState);
    if (dashboardId) {
      savedDashboard.dashboardId = dashboardId;
    }

    return AppData.saveDashboard(savedDashboard)
      .then((response) => {
        dispatch(saveDashboardSuccess(response));
      }, (error) => {
        dispatch(saveDashboardFailure(error));
      });
  };
}

export function startNewDashboard() {
  return (dispatch, getState) => {
    AppData.getNextosGlobalSettings().then((response) => {
      const { data } = response;
      dispatch(batchActions([
        { type: CUSTOM_DASHBOARD.SET_TIME_RANGE, field: 'timeRange', value: data.time_settings.monitoring },
        { type: CUSTOM_DASHBOARD.START_NEW_DASHBOARD },
      ]));
    });
  };
}

export function toggleEditDashboard() {
  return { type: CUSTOM_DASHBOARD.TOGGLE_EDIT_DASHBOARD };
}

export function addTile() {
  return { type: CUSTOM_DASHBOARD.ADD_TILE };
}

export function setTile(tileType, elementIndex) {
  return { type: CUSTOM_DASHBOARD.SET_TILE, tileType, index: elementIndex };
}

export function updateTile(tileState, elementIndex) {
  return { type: CUSTOM_DASHBOARD.UPDATE_TILE, tileState, index: elementIndex };
}

export function removeTile(elementIndex) {
  return { type: CUSTOM_DASHBOARD.REMOVE_TILE, index: elementIndex };
}

export function editTile(elementIndex) {
  return { type: CUSTOM_DASHBOARD.EDIT_TILE, index: elementIndex };
}

export function cancel(elementIndex) {
  return { type: CUSTOM_DASHBOARD.CANCEL, index: elementIndex };
}

export function cancelNew(elementIndex) {
  return { type: CUSTOM_DASHBOARD.CANCEL_NEW, index: elementIndex, tileType: 'new' };
}

export function layoutChange(layout) {
  return { type: CUSTOM_DASHBOARD.LAYOUT_CHANGE, layout };
}

export function breakpointChange(breakpoint, cols) {
  return { type: CUSTOM_DASHBOARD.BREAKPOINT_CHANGE, breakpoint, cols };
}

export function validate(options) {
  return (dispatch, getState) => {
    dispatch({ type: CUSTOM_DASHBOARD.VALIDATE, options });

    const errors = getState().custom_dashboard.errors;
    const messages = [];
    _.each(errors, (err) => {
      if (err.hasError) messages.push(err.errorMessage);
    });

    return messages.length && messages || null;
  };
}

export function setStatus(value) {
  return { type: CUSTOM_DASHBOARD.SET_STATUS, field: 'status', value };
}

export function setTitle(value) {
  return { type: CUSTOM_DASHBOARD.SET_TITLE, field: 'title', value };
}

export function setDescription(value) {
  return { type: CUSTOM_DASHBOARD.SET_DESCRIPTION, field: 'description', value };
}

export function setTimeRange(value) {
  return { type: CUSTOM_DASHBOARD.SET_TIME_RANGE, field: 'timeRange', value };
}

export function setRealTime(value) {
  return { type: CUSTOM_DASHBOARD.SET_REAL_TIME, field: 'realTime', value };
}

export function setOptionsPage(value) {
  return { type: CUSTOM_DASHBOARD.SET_OPTIONS_PAGE, field: 'tile_options_page', value };
}

export function clear() {
  return { type: CUSTOM_DASHBOARD.CLEAR };
}

function getDashboardInfo(dashboardState) {
  const dashboard = {};
  const uiState = dashboardState;

  dashboard.title = uiState.title;
  dashboard.time_range = uiState.timeRange;
  dashboard.description = uiState.description;
  dashboard.items = uiState.items;
  dashboard.items = dashboard.items.map((item, index) => {
    item.layout = uiState.layout[index];
    return item;
  });

  return dashboard;
}
