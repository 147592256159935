

const callTypeOptions = [
  { key: 'total_calls', label: 'Total Calls', metric: 'total', filters: ['total'], units: 'calls' },
  { key: 'total_talk_time', label: 'Total Talk Time', metric: 'total_duration', filters: ['total'], units: 'seconds' },
  { key: 'total_average_talk_time', label: 'Average Talk Time', metric: 'total_duration', agg_func: 'avg', filters: ['total'], units: 'seconds' },
  { key: 'inbound_calls', label: 'Inbound Calls', metric: 'inbound', filters: ['inbound'], units: 'calls' },
  { key: 'inbound_talk_time', label: 'Inbound Talk Time', metric: 'inbound_duration', filters: ['inbound'], units: 'seconds' },
  { key: 'inbound_average_talk_time', label: 'Average Inbound Talk Time', metric: 'inbound_duration', agg_func: 'avg', filters: ['inbound'], units: 'seconds' },
  { key: 'outbound_calls', label: 'Outbound Calls', metric: 'outbound', filters: ['outbound'], units: 'calls' },
  { key: 'outbound_talk_time', label: 'Outbound Talk Time', metric: 'outbound_duration', filters: ['outbound'], units: 'seconds' },
  { key: 'outbound_average_talk_time', label: 'Average Outbound Talk Time', metric: 'outbound_duration', agg_func: 'avg', filters: ['outbound'], units: 'seconds' },
  { key: 'tollfree_calls', label: 'Toll Free Calls', metric: 'tollfree_inbound', filters: [], units: 'calls' },
  { key: 'tollfree_talk_time', label: 'Toll Free Talk Time', metric: 'tollfree_duration', filters: [], units: 'seconds' },
  { key: 'average_tollfree_talk_time', label: 'Avg. Toll Free Talk Time', metric: 'tollfree_duration', agg_func: 'avg', filters: [], units: 'seconds' },
  { key: 'international_calls', label: 'International Calls', metric: 'international_outbound', filters: [], units: 'calls' },
  { key: 'international_talk_time', label: 'International Talk Time', metric: 'international_duration', filters: [], units: 'seconds' },
  { key: 'average_international_talk_time', label: 'Avg. International Talk Time', metric: 'international_duration', agg_func: 'avg', filters: [], units: 'seconds' },
  { key: 'missed_calls', label: 'Missed Calls', metric: 'missed', filters: ['missed'], units: 'calls' },
  { key: 'answered_calls', 'label': 'Answered', metric: 'answered', filters: ['answered'], units: 'calls' },
  { key: 'unique_calls', label: 'Unique Calls', metric: 'unique_calls', filters: [], units: 'calls' },
  { key: 'unique_call_ratio', label: 'Unique Call Ratio', metric: 'unique_call_ratio', filters: [], units: '' },
  { key: 'longest_total_duration', 'label': 'Longest Total Duration', metric: 'total_duration', agg_func: 'max', filters: ['total'], units: 'seconds' },
  { key: 'longest_inbound_duration', 'label': 'Longest Inbound Duration', metric: 'inbound_duration', agg_func: 'max', filters: ['inbound'], units: 'seconds' },
  { key: 'longest_outbound_duration', 'label': 'Longest Outbound Duration', metric: 'outbound_duration', agg_func: 'max', filters: ['outbound'], units: 'seconds' },
];

export default callTypeOptions;
