import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment-timezone';
import cx from 'classnames';
import Popover from 'react-popover';
import ReactTimeout from 'react-timeout';
import { DefaultSpinner } from 'components';

import axios, { CancelToken, isCancel } from 'axios_client';
import vex from 'lib/vex';
import AppData from 'app_data';
import ReportTable from 'components/report_table';
import CallLog from 'components/call_log';
import Utils from 'utils/common_utils';
import InlineSvgFactory from 'components/inline_svg';
import SimpleDropdown from 'components/simple_dropdown';
import CallRecordingModal from 'components/call_recording/call_recording_modal';
import CallLogExportModal from 'components/call_log_export_modal';
import Button from 'components/button';

const Arrow = InlineSvgFactory('arrow.svg');

const limitOptions = [
  { id: 10, label: '10' },
  { id: 20, label: '20' },
  { id: 50, label: '50' },
  { id: 100, label: '100' },
];

class ReportTableContainer extends Component {
  static propTypes = {
    /** The URL for the table data */
    url: PropTypes.string,
    /** The kind of unit being displayed (calls, minutes, etc...) */
    unit: PropTypes.string, // Not important if API uses selects
    /** The selected filter options for the detail table */
    filters: PropTypes.object,
    /** The data fields that are expected to be returned: (start_time,
    user_id, calling_number, called_number, talk_duration_seconds, etc...) */
    selects: PropTypes.array,
    /** The period object, if it is a period over period table. Object has a
    unique id field */
    period: PropTypes.object,
    /** The time range being represented by the table */
    timeRange: PropTypes.object,
    /** An array of the time ranges for a chart with multiple time ranges */
    timeRanges: PropTypes.array,
    /** Whether or not the data is in real time */
    realTime: PropTypes.bool,
    /** Whether or not the table is paginated */
    pagination: PropTypes.bool,
    /** Whether or not the table is displaying the call log */
    callLog: PropTypes.bool,
    /** Name of the tag that is currently active */
    activeTab: PropTypes.string,
    /** Whether or not the chart is drilling down */
    isDrillingDown: PropTypes.bool,
    /** The metric being drilled down on */
    drillDownMetric: PropTypes.string,
    /** The information of the call recording */
    callRecordingInfo: PropTypes.object,
    /** The chart this report table is for, if any. */
    chart: PropTypes.object,
  };

  static defaultProps = {
    pagination: false,
  };

  state = {
    loading: true,
    tables: [],
    totalCount: 0,
    currentPage: 0,
    totalPages: 0,
    limit: limitOptions[1],
    sortBy: null,
    sortDir: 'desc',
    selectedCallFilter: 'total_calls',
    exportModal: false,
    searchQuery: '',
    callRecordingInfo: null,
    intervalID: null,
  };

  componentDidMount() {
    this.pageLeft = this.paginate.bind(this, -1);
    this.pageRight = this.paginate.bind(this, 1);
    this.debouncedFetchData = _.debounce(() => { this.fetchTableData(); }, 500);

    if (this.props.timeRange && this.props.realTime) {
      const intervalID = setInterval(this.fetchTableData, AppData.user.refresh_rate * 1000);
      this.setState({ intervalID: intervalID });
      return;
    }
    setTimeout(() => {
      this.fetchTableData();
    }, 300);
  }

  componentWillReceiveProps(newProps) {
    if (newProps.timeRange && !this.props.realTime && newProps.realTime) {
      const intervalID = setInterval(this.fetchTableData, AppData.user.refresh_rate * 1000);
      this.setState({ intervalID: intervalID });
      this.fetchTableData();
    } else {
      clearInterval(this.state.intervalID);
      setTimeout(() => {
        this.fetchTableData(newProps);
      }, 300);
    }
  }

  getExportModal = () => (
    <CallLogExportModal
      onCancel={this.toggleExportModal}
      onExport={this.handleExport}
    />
  );

  changeCallFilter = (callFilter) => {
    this.setState({
      selectedCallFilter: callFilter.id,
      currentPage: 0,
    }, this.fetchTableData);
  };

  handleClickCallRecording = (event, rowIndex) => {
    const callRecordingInfo = this.state.tables[0].rows[rowIndex];
    this.setState({
      callRecordingInfo,
    });
  }

  handleCloseModal = () => {
    this.setState({
      callRecordingInfo: null,
    });
  }

  cancelFetch = () => {
    if (!this.request) {
      return;
    }
    this.request.cancel();
    this.request = null;
  }

  fetchTableData = (props, isSearch = false) => {
    this.cancelFetch();

    props = props || this.props;
    const source = CancelToken.source();
    let resolved = false;
    let canceled = false;

    let params = {
      unit: props.unit,
      selects: props.selects,
      period: props.period ? props.period.id : null,
      labels: Utils.createLabelMap(props.selects),
      filters: _.assign({}, this.props.filters, { [this.state.selectedCallFilter]: true }),
      search_query: this.state.searchQuery,
      chart: props.chart,
    };

    if (this.state.sortBy) {
      params.sort = {
        header: this.state.sortBy,
        order: this.state.sortDir,
      };
    }

    if (props.pagination) {
      params = _.assign(params, {
        offset: this.state.currentPage * this.state.limit.id,
        limit: this.state.limit.id,
      });
    }

    const onError = Utils.createDelayedCallback((response) => {
      // TODO: need to refactor it, reject promise
      if (!this && isCancel(response)) {
        return;
      }
      this.setState({
        error: true,
        loading: false,
      });
    }, 400);

    const onSuccess = Utils.createDelayedCallback((response) => {
      // TODO: need to refactor it, reject promise
      if (!this) {
        return;
      }

      const { data } = response;
      resolved = true;
      if (!data) {
        return this.setState({
          tables: null,
          loading: false,
        });
      }

      const newState = {
        tables: data.tables,
        loading: false,
        totalCount: data.total_count || 0,
        totalPages: Math.ceil(data.total_count / this.state.limit.id),
      };

      if (this.props.callLog) {
        newState.sortBy = data.tables[0].sort.header;
        newState.sortDir = data.tables[0].sort.order;
      }

      if (isSearch) {
        newState.currentPage = 0;
      }

      this.setState(newState);
    }, 400);

    if (props.timeRanges) {
      // Period over period Time ranges

      if (props.callLog) {
        params.time_range = this.periodDatesMinMix(props);
      } else {
        params.time_ranges = [
          Utils.getStartEndDates(props.timeRanges[0]),
          Utils.getStartEndDates(props.timeRanges[1]),
        ];
      }
    } else {
      // Single Time range
      params.time_range = props.timeRange;
    }

    // Execute query
    this.setState({ loading: true }, () => {
      this.request = axios.post(props.url, params, { cancelToken: source.token })
        .then(onSuccess)
        .catch(onError);
      this.request.cancel = () => {
        if (!canceled && !resolved) {
          source.cancel();
          canceled = true;
        }
      };
    });
  };

  handleExport = (toEmail) => {
    const props = this.props;
    const params = {
      filters: _.assign({}, props.filters, { [this.state.selectedCallFilter]: true }),
      selects: props.selects,
      to_email: toEmail,
      search_query: this.state.searchQuery,
    };

    if (props.timeRanges) {
      params.time_range = this.periodDatesMinMix(props);
    } else {
      params.time_range = props.timeRange;
    }

    if (this.state.sortBy) {
      params.sort = {
        header: this.state.sortBy,
        order: this.state.sortDir,
      };
    }

    AppData.exportCallLog(params).then(() => {
      vex.success('Your email will arrive shortly.');
    });
  };

  handleLimitChange = (obj) => {
    this.setState({
      limit: obj,
      currentPage: 0,
    }, this.fetchTableData);
  };

  handleSearchChange = (e) => {
    const searchQuery = e.target.value;
    this.setState({ searchQuery });
  };

  handleSearchKeypress = (e) => {
    const key = e.keyCode || e.which;
    if (key === 13) {
      const isSearch = true;
      this.fetchTableData(null, isSearch);
    }
  };

  handleSort = (header) => {
    if (this.state.sortBy !== header) {
      this.setState({
        sortBy: header,
        sortDir: 'desc',
      }, this.fetchTableData);
    } else {
      this.setState({
        sortDir: this.state.sortDir === 'asc' ? 'desc' : 'asc',
      }, this.fetchTableData);
    }
  };

  paginate = (direction) => {
    const newPage = this.state.currentPage + direction;

    if (newPage >= 0 && newPage <= this.state.totalPages) {
      this.setState({
        currentPage: this.state.currentPage + direction,
        loading: true,
      }, this.debouncedFetchData);
    }
  };

  periodDatesMinMix = (props) => {
    const start1 = moment.utc(props.timeRanges[0].start);
    const start2 = moment.utc(props.timeRanges[1].start);
    const end1 = moment.utc(props.timeRanges[0].end).tz(AppData.user.timezone);
    const end2 = moment.utc(props.timeRanges[1].end).tz(AppData.user.timezone);

    return {
      type: 'absolute',
      start: moment.min(start1, start2).tz(AppData.user.timezone),
      end: moment.max(end1, end2).tz(AppData.user.timezone),
    };
  };

  toggleExportModal = () => {
    this.setState({
      exportModal: !this.state.exportModal,
    });
  };

  formatDate = (date) => {
    const { timezone } = AppData.user;
    return moment.utc(date).tz(timezone).format('MM/DD/YY');
  };

  render() {
    let paginationControls;
    let controlsTop;
    let controlsBottom;
    let startDate;
    let endDate;

    if (this.props.pagination) {
      paginationControls = (
        <div className="dropzone-container">
          <div className="page-controls">
            <Arrow
              className={cx({ 'arrow-left': true, hidden: this.state.currentPage === 0 })}
              onClick={this.pageLeft}
            />
            {this.state.currentPage + 1} / {this.state.totalPages || 1}
            <Arrow
              className={cx({ 'arrow-right': true, hidden: this.state.currentPage + 1 === (this.state.totalPages || 1) })}
              onClick={this.pageRight}
            />
          </div>
        </div>
      );

      if (this.props.timeRanges) {
        const { start, end } = this.periodDatesMinMix(this.props);
        startDate = this.formatDate(start);
        endDate = this.formatDate(end);
      } else {
        const { timeRange } = this.props;

        if (timeRange.type === 'absolute') {
          startDate = this.formatDate(timeRange.start);
          endDate = this.formatDate(timeRange.end);
        } else if (timeRange.type === 'relative') {
          startDate = Utils.relativeToLabel(timeRange).toLowerCase();
          endDate = 'now';
        }
      }

      const excludeInternal = ['summary', 'location', 'phone-number'];

      let callFilterOptions;

      if (this.props.isDrillingDown) {
        callFilterOptions = [
          { id: this.props.drillDownMetric.toLowerCase(), label: this.props.drillDownMetric },
        ];
      } else {
        callFilterOptions = [
          { id: 'total_calls', 'label': 'All Calls' },
          { id: 'inbound', label: 'Inbound' },
          { id: 'outbound', label: 'Outbound' },
          { id: 'answered', label: 'Answered' },
          { id: 'missed', label: 'Missed' },
        ];
      }

      if (!excludeInternal.includes(this.props.activeTab)) {
        callFilterOptions.push({ id: 'internal', label: 'Internal' });
      }

      controlsTop = (
        <>
          <SimpleDropdown
            initialValue={this.state.selectedCallFilter}
            options={callFilterOptions}
            onChange={this.changeCallFilter}
            className={'call-prop-filter'}
            ariaLabel="Select call log filter"
          />
          <input
            type="text"
            className="call-log-search"
            onChange={this.handleSearchChange}
            onKeyPress={this.handleSearchKeypress}
            placeholder="Search call log"
            value={this.state.searchQuery}
            aria-label="Search call log"
          />
          <Popover
            className="call-log-popover"
            isOpen={this.state.exportModal}
            body={this.getExportModal()}
            place="left"
            onOuterAction={this.toggleExportModal}
          >
            <Button
              className="btn-transparent call-log-export"
              onClick={this.toggleExportModal}
              aria-label="Export call log data"
            >
              Export data
            </Button>
          </Popover>
          <div className="call-log-count">
            <div className="call-log-count-text">
              <strong>
                {Utils.numberWithCommas(this.state.totalCount)} calls
              </strong> from {startDate}
              {` - ${endDate}`}
            </div>
            {paginationControls}
          </div>

        </>
      );

      const start = this.state.currentPage * this.state.limit.id + 1;
      let end = start + this.state.limit.id - 1;

      if (end > this.state.totalCount) {
        end = this.state.totalCount;
      }

      controlsBottom = (
        <div className="call-log-controls">
          <div>
            Results per page
            <SimpleDropdown
              initialValue={this.state.limit}
              options={limitOptions}
              onChange={this.handleLimitChange}
              className="limit-select"
              ariaLabel="Select call log limit"
            />
            <span className="call-log-page-number">
              Showing {start} to {end} of {Utils.numberWithCommas(this.state.totalCount)} results
            </span>
          </div>

          {paginationControls}
        </div>
      );
    }

    if (this.state.loading) {
      return (
        <div className="call-log-wrapper">
          {controlsTop}
          <div className="call-log-container">
            <DefaultSpinner />
          </div>
        </div>
      );
    }

    const tables = _.map(this.state.tables, (data, index) => {
      if (this.props.callLog) {
        return (
          <div key={index}>
            <CallRecordingModal
              isOpen={!!this.state.callRecordingInfo}
              onRequestClose={this.handleCloseModal}
              handleCloseModal={this.handleCloseModal}
              callRecordingInfo={this.state.callRecordingInfo}
            />
            <CallLog
              className="call-log-table"
              data={data}
              sortBy={this.state.sortBy}
              sortDir={this.state.sortDir}
              onHandleSort={this.handleSort}
              handleClickCallRecording={this.handleClickCallRecording}
            />
          </div>
        );
      }
      return (
        <ReportTable
          key={index}
          data={data}
          autoSize
        />
      );
    });

    return (
      <div className="call-log-wrapper">
        {controlsTop}
        <div className="call-log-container">
          {tables}
        </div>
        {controlsBottom}
      </div>
    );
  }
}

export default ReactTimeout(ReportTableContainer);
