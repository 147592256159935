import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Accordion from 'components/accordion/accordion';
import AccordionItems from 'components/accordion/accordion_items';
import AccordionSection from 'components/accordion/accordion_section';
import CustomActions from 'actions/custom_actions';

const mapStateToProps = (state) => ({
  customState: state.custom.ui,
});

const mapDispatchToProps = (dispatch) => ({
  customActions: bindActionCreators(CustomActions, dispatch),
});

class CustomReportsHeaderControls extends Component {
  static propTypes = {
    tree: PropTypes.array,
    customState: PropTypes.object,
    customActions: PropTypes.object,
  };

  handleReportChange = (key, index, parentIndex) => {
    // user changes the main report type, reset all accordion state
    const newState = {
      report: null,
      format: null,
      breakdown: null,
      display: null,
    };
    const { treePath } = this.props.customState;

    if (index !== undefined && treePath.report !== index) {
      newState.reportGroup = parentIndex;
      newState.report = index;
      this.props.customActions.updateTreePath(newState);
    } else {
      newState.report = null;
      this.props.customActions.updateTreePath(newState);
    }
  };

  handleUpdateAccordion = (section, itemKey, index) => {
    const obj = {};
    const { treePath } = this.props.customState;
    if (section === 'format') {
      obj.breakdown = null;
      obj.display = null;
    }

    if (section === 'breakdown') {
      obj.display = null;
    }

    if (section === 'display') {
      const s = treePath;
      const path = [
        s.reportGroup,
        s.report,
        s.format,
        s.breakdown,
        index,
      ];

      obj[section] = index;

      this.props.customActions.updateTreePath(obj);

      setTimeout(() => {
        this.props.customActions.parseTree(path);
      }, 0);

      return;
    }

    obj[section] = index;

    this.props.customActions.updateTreePath(obj);
  };

  render() {
    const { treePath } = this.props.customState;
    let formatObj;
    let breakdownObj;
    let displayItemsObj;

    if (treePath.report !== null) {
      formatObj = this.props.tree[treePath.reportGroup].items[treePath.report];
    }

    if (formatObj !== null && formatObj !== undefined) {
      breakdownObj = formatObj.items[treePath.format];
    }

    if (breakdownObj !== null && breakdownObj !== undefined) {
      displayItemsObj = breakdownObj.items[treePath.breakdown];
    }

    return (
      <section data-testid="report-configurator-container" className="reporting-custom-filters">
        <section data-testid="report-type-section">
          <h2>Report type</h2>

          <Accordion
            onSelect={this.handleReportChange}
            selectedKey={treePath.report}
            section={treePath.reportGroup}
          >
            {this.props.tree.map((branch, index) => (
              <AccordionSection
                key={index}
                title={branch.label}
                listLength={branch.items.length}
                maxStringLength={25}
              >
                <AccordionItems
                  className="custom-select-items"
                  itemClassName="custom-select-item"
                  items={branch.items}
                />
              </AccordionSection>
            ))}
          </Accordion>
        </section>
        <section data-testid="format-section" className={treePath.report !== null ? null : 'hidden'}>
          <h2>Format</h2>
          <AccordionItems
            className="custom-select-items"
            itemClassName="custom-select-item"
            items={formatObj && formatObj.items}
            selectedKey={treePath.format}
            onSelect={this.handleUpdateAccordion.bind(this, 'format')}
          />
        </section>
        <section data-testid="breakdown-section" className={treePath.format !== null ? null : 'hidden'}>
          <h2>Breakdown</h2>
          <AccordionItems
            className="custom-select-items"
            itemClassName="custom-select-item"
            items={breakdownObj && breakdownObj.items}
            selectedKey={treePath.breakdown}
            onSelect={this.handleUpdateAccordion.bind(this, 'breakdown')}
          />
        </section>
        <section data-testid="display-section" className={treePath.breakdown !== null ? null : 'hidden'}>
          <h2>Display</h2>
          <AccordionItems
            className="custom-select-items"
            itemClassName="custom-select-item"
            items={displayItemsObj && displayItemsObj.items}
            selectedKey={treePath.display}
            onSelect={this.handleUpdateAccordion.bind(this, 'display')}
          />
        </section>
        <ReactTooltip
          class="tool-tip-container"
          delayShow={700}
          html
          id="custom-report-tooltip"
          type="light"
        />
      </section>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomReportsHeaderControls);
