import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AppData from 'app_data';
import vex from 'lib/vex';

import IconDropdown from 'components/icon_dropdown';
import { ReactComponent as gearImg } from 'img/gear.svg';

import getDisplayName from './getDisplayName';
import './User.scss';

const userDropdownOptions = [
  { action: 'setHomepage', name: 'Set Homepage' },
  { action: 'logout', name: 'Log Out' },
];


class User extends Component {
  state = {
    displayName: getDisplayName(AppData.user || {}),
  };


  setHomepage = () => {
    AppData
      .putNextosHomepageSettings({
        'homepage': this.props.currentActivePage,
      })
      .then(() => {
        vex.success('Saved');
      })
      .catch(() => {
        vex.error([], null, 'Error saving homepage');
      });
  };


  doLogout = () => {
    AppData.logout().then((response) => {
      const { data } = response;
      window.location = data.login_uri;
    });
  };


  handleDropdownChange = (obj) => {
    if (obj.action === 'logout') {
      this.doLogout();
    } else if (obj.action === 'setHomepage') {
      this.setHomepage();
    }
  };


  render() {
    const { displayName } = this.state;

    return (
      <div className="user">
        <IconDropdown
          hideArrow
          className="user__dropdown"
          defaultValue={{ name: 'None', icon: gearImg }}
          fixedIcon={{ name: 'Icon', icon: gearImg }}
          onEdit={this.handleDropdownChange}
          options={userDropdownOptions}
          ariaLabel="User settings"
        />

        <span data-testid="header-userName">
          Hi {displayName}
        </span>
      </div>
    );
  }
}


User.propTypes = {
  /** The current active page of the app */
  currentActivePage: PropTypes.string,
};


export default User;
