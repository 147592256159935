import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import cx from 'classnames';

import Accordion from 'components/accordion/accordion';
import AccordionItems from 'components/accordion/accordion_items';
import AccordionSection from 'components/accordion/accordion_section';

class Filters extends Component {
  static propTypes = {
    /** Function to enabled the filters */
    enableFilter: PropTypes.func,
    /** Function to set the team type */
    setTeamType: PropTypes.func,
    /** The classname for the Filter component */
    className: PropTypes.string,
    /** Store path to connect filters to */
    reducerPath: PropTypes.string,
    /** Available filters */
    filters: PropTypes.object,
    /** Current team type */
    teamType: PropTypes.string,
    /** Available dropzones */
    dropzones: PropTypes.array,
    /** Filter limits per dropzone */
    dropzoneLimits: PropTypes.object,
  };

  static defaultProps = {
    reducerPath: 'custom.ui',
  };

  constructor(props) {
    super(props);

    this.state = {
      selectedKey: null,
      filterText: '',
      teamType: null,
      teamDropdownOptions: null,
    };
  }

  setFilterText = (e) => {
    this.setState({
      filterText: e.target.value,
    });
  }

  handleSelect = (key, index, parentIndex, item) => {
    if (!key) {
      return;
    }

    this.props.enableFilter(item.parent, key);
  }

  handleDropdownChange = (e) => {
    this.props.setTeamType(e.target.value);
  }

  filterItems = (section, data, teamType) => {
    const filterText = this.state.filterText.toLowerCase().trim();
    const items = [];

    _.each(section.items, (item) => {
      if (item.label.toLowerCase().indexOf(filterText) >= 0 && item.selected !== true) {
        // special case for teams
        if (item.teamType) {
          if (teamType === item.teamType) {
            items.push(item);
          }
        } else {
          items.push(item);
        }
      }
    });

    if (items.length) {
      const newSection = _.clone(section);
      newSection.items = items;
      data.push(newSection);
    }

    return data;
  }

  render() {
    const { filters, teamType, dropzones, dropzoneLimits, className } = this.props;
    let teamsDropdown;

    let data = [];
    const filterText = this.state.filterText.toLowerCase().trim();

    if (filters.teams) {
      // find available options
      const teamTypes = new Set(_.map(filters.teams.items, 'teamType'));
      const options = _.map(Array.from(teamTypes), (team, index) => (
        <option key={index} value={team.toLowerCase()}>{_.startCase(team)}</option>
      ));

      teamsDropdown = (
        <select
          onChange={this.handleDropdownChange}
          value={teamType}
          className="filters-container__select"
        >
          {options}
        </select>
      );
    }

    _.each(dropzones, (dropzone) => {
      const section = filters[dropzone];

      if (dropzone === 'teams' || filterText.length) {
        data = this.filterItems(section, data, teamType);
      } else {
        data.push(section);
      }
    });

    return (
      <section data-testid="filters-section" className={cx('filters-container', className)}>
        <h2 className="filters-container__title">Filters</h2>
        <input
          type="search"
          data-testid="filters-section-search"
          className="filters-container__search"
          placeholder="Search filters"
          onChange={this.setFilterText}
          value={this.state.filterText}
        />
        {teamsDropdown}
        <Accordion
          maintainState={!this.state.filterText.length}
          open={!!this.state.filterText.length}
          multiple
          onSelect={this.handleSelect}
        >
          {data.map((branch, index) => {
            const [items, selectedItems] = _.partition(branch.items, { selected: false });
            let atLimit = false;
            if (branch.key !== 'calls' && dropzoneLimits) {
              atLimit = selectedItems.length === dropzoneLimits[branch.key];
            }
            return (
              <AccordionSection
                key={index}
                title={branch.label}
                listLength={items.length}
                maxStringLength={25}
                atLimit={atLimit}
              >
                <AccordionItems
                  onSelect={this.handleSelect}
                  items={items}
                />
              </AccordionSection>
            );
          })}
        </Accordion>
      </section>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { reducerPath } = ownProps;
  const path = _.get(state, reducerPath);
  return {
    filters: path.filters,
    dropzones: path.dropzones,
    teamType: path.teamType,
    dropzoneLimits: path.dropzoneLimits,
  };
};

export default connect(mapStateToProps)(Filters);
