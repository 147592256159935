import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import MultiSelect from 'components/multi_select';
import { ReactComponent as Arrow } from 'img/arrow.svg';
// TODO
// Tabs.setUseDefaultStyles(false);

/**
 * Component for tabbed controls inside popover.
 */
class TabbedControls extends Component {
  static propTypes = {
    /** The data for the tabs */
    tabs: PropTypes.array,
    /** Whether to auto update after input */
    autoUpdate: PropTypes.bool,
  }

  static defaultProps = {
    autoUpdate: true,
  }

  constructor(props) {
    super(props);

    this.state = {
      selectedIndex: 0,
      filters: [],
      unselectAll: true,
      selectedItems: this.props.filters,
      applyBtnFlag: false,
      unselectedAllSelected:(this.props.filters=== undefined || this.props.filters.length=== 0 ? true: false )
    };
  }

  onTabClick = (index) => {
    this.setState({ selectedIndex: index });
  }

  updateFilters = (e) =>{
    this.setState({
      applyBtnFlag: true,
      selectedItems: e,
      unselectedAllSelected: false
    })
  }

  unselectAllFilters = ()=>{
    this.setState({ applyBtnFlag: true, selectedItems:[], unselectedAllSelected: true})
  }

  selectAllFilters = ()=>{
    this.setState({ selectedIndex: 1, applyBtnFlag: true, selectedItems: this.props.filterOptions, unselectedAllSelected: false })
    setTimeout(()=>{
      this.setState({ selectedIndex: 0 })
    },100)

  }

  applyFilters =(e)=>{
    this.props.applyFilters(this.state.selectedItems)
    this.setState({ applyBtnFlag: false })
  }

  render() {
    const { tabs } = this.props;
    const { applyBtnFlag } = this.state;
    const tabHeaders = tabs.map((tab, i) => {
      if (tab.disabled) {
        return null;
      }
      let content = tab.label;
      const tabClassNames = ['tabbed-controls-tab', tab.className];
      if (tab.icon) {
        const classNames = ['tabbed-controls-tab-icon', tab.icon];
        content = (
          <div>
            <span className={cx(classNames)} />
            <span className='popup-filter-header' style={{color:'#666666',width: '37px',padding:'8px',fontWeight:400}}> Filter</span>
            <span style={{float: "right"}} className="sort-arrow-container"><Arrow className='arrow-sort arrow-down'/></span>
          </div>
        );
      }
      return (
        <Tab
          key={i}
          className={cx(tabClassNames)}
          title={tab.label}
          style={{width:'100%', margin:'0px', textAlign:'left', borderBottom:'1px solid #E3E3E3'}}
        >
          {content}
        </Tab>
      );
    });
    const tabContent = tabs.map((tab, i) => {
      if (tab.disabled) {
        return null;
      }
      return (
        <TabPanel
          key={i}
          className="tabbed-controls-content"
        >
          {/* {tab.content} */}
          <MultiSelect
              searchBar
              key={i}
              options={this.props.filterOptions}
              onChange={e => this.updateFilters(e)}
              hideToggleButtons = {true}
              initialSelected ={this.state.unselectedAllSelected ? [] : this.state.selectedItems}
          />
          <div className="filterFooterControls">
            {this.state.unselectedAllSelected !== true ?
            <button className='multi-select-toggle' onClick={this.unselectAllFilters} style={{color: "#005FEC",cursor:'pointer'}} >Unselect All</button>
            :
            <button className='multi-select-toggle' onClick={this.selectAllFilters} style={{color: "#005FEC",cursor:'pointer'}} >Select All</button>}
            <button className='multi-select-toggle' onClick={this.applyFilters} disabled={!applyBtnFlag} style={{color: !applyBtnFlag ? "grey" : "#005FEC",cursor:'pointer'}} >Apply</button>
          </div>
        </TabPanel>
      );
    });
    return (
      <div className="tabbed-controls">
        <Tabs
          selectedIndex={this.state.selectedIndex}
          onSelect={this.onTabClick}
          focus={false}
          selectedTabClassName="selected"
        >
          <TabList className="tabbed-controls-tabs">
            {tabHeaders}
          </TabList>
          {tabContent}
        </Tabs>
      </div>
    );
  }
}

export default TabbedControls;
