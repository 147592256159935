import React, { memo } from 'react';

import Styles from './Info.module.scss';
import { ReactComponent as PhoneIcon } from '../../assets/phone.svg';
import { ReactComponent as CalendarIcon } from '../../assets/date-time.svg';


const Info = memo((props) => {
  const { date, phoneNumber } = props;

  return (
    <ul className={Styles.info}>
      <li className={Styles.item}>
        <CalendarIcon className={Styles.icon} />
        {date}
      </li>

      <li className={Styles.item}>
        <PhoneIcon className={Styles.icon} />
        {phoneNumber}
      </li>
    </ul>
  )
});


Info.displayName = 'Info';


export default Info;
