import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import routes from '../../Router/routes';
import Styles from './Navigation.module.scss';


function Navigation({ list }) {
  return (
    <div className={Styles.navigation}>
      <ul data-testid="header-navigationList" className={Styles.list}>

        {list.map(item => (
          <li className={Styles.item} key={item.path}>
            <NavLink
              to={item.path}
              className={Styles.link}
              activeClassName={Styles.active}
              data-test={`navigation-${item.title.toLowerCase()}`}
            >
              {item.title}
            </NavLink>
          </li>
        ))}

      </ul>
    </div>
  );
}


Navigation.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape({
    path: PropTypes.string,
    title: PropTypes.string,
  })),
};


Navigation.defaultProps = {
  list: routes,
};


export default Navigation;
