import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import type from 'type-of';

/**
 * Displays options in a list for selection.
 */
class ListSelect extends Component {
  static propTypes = {
    /** The options to display for selection */
    options: PropTypes.array.isRequired,
    /** The callback for when a selection is made */
    onSelect: PropTypes.func.isRequired,
    /** The currently selected option */
    selected: PropTypes.shape({
      /** The id of the selection */
      id: PropTypes.string,
    }),
    /** Field to use to compare for selected status */
    compareField: PropTypes.string,
    /** Field to use for label */
    labelField: PropTypes.string,
  }

  static defaultProps = {
    compareField: 'id',
    labelField: 'label',
  }

  onClick = (index) => {
    const { options, onSelect } = this.props;
    onSelect(options[index]);
  }

  renderOptions() {
    const { options, onSelect, selected, compareField, labelField } = this.props;
    return options.map((option, i) => {
      const classNames = [
        'list-select-option',
        { 'selected': selected && (selected[compareField] === option[compareField]) },
      ];
      return (
        <li
          key={i}
          className={cx(classNames)}
          onClick={this.onClick.bind(this, i)}
          title={option[labelField]}
        >
          {option[labelField]}
        </li>
      );
    });
  }

  render() {
    const options = this.renderOptions();
    return (
      <ul className="list-select">
        {options}
      </ul>
    );
  }
}

export default ListSelect;
