import React from 'react';
import PropTypes from 'prop-types';
import BEMHelper from 'react-bem-helper';

import Tooltip from 'components/tooltip';

const classes = new BEMHelper('scheduled-report-form');

const SectionHeading = ({ title, tooltip }) => (
  <div {...classes('section-heading')}>
    <span {...classes('section-title')}>
      {title}
      {tooltip && <Tooltip tipContent={tooltip} />}
    </span>
  </div>
);

SectionHeading.propTypes = {
  title: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
};

export default SectionHeading;
