import React from 'react';
import PropTypes from 'prop-types';

const Radio = ({ children, className, ...props }, context) => {
  const { name, selectedValue, onChange } = context.radioGroup;
  const optional = {};
  if (selectedValue !== undefined) {
    optional.checked = (props.value === selectedValue);
  }
  if (typeof onChange === 'function') {
    optional.onChange = onChange.bind(null, props.value);
  }

  return (
    <label className={className}>
      <input
        {...props}
        type="radio"
        name={name}
        {...optional}
      />
      { children }
    </label>
  );
};

Radio.propTypes = {
  className: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  children: PropTypes.any,
};

Radio.contextTypes = {
  radioGroup: PropTypes.object,
};

export default Radio;
