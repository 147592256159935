import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { DefaultSpinner } from 'components';

import AppData from 'app_data';
import Utils from 'utils/common_utils';
import { handleChartChange, handleUnitChange } from 'utils/chart_utils';
import { ControlsActions } from 'actions/controls_actions';
import AnalyticsTablePanel from 'components/analytics_table_panel';
import BasicPopoutHeader from 'components/charts/basic_popout_header';
import ComparisonPopoutHeader from 'components/charts/comparison_popout_header';
import PeriodPopoutHeader from './PeriodPopoutHeader';
import ChartAsync from 'components/charts/chart_async';
import CallRecordingModal from 'components/call_recording/call_recording_modal';

const mapStateToProps = (state) => (
  {
    controlsState: state.controls.popout,
    currentSection: state.controls.currentSection,
  }
);

const mapDispatchToProps = (dispatch) => (
  {
    controlsActions: bindActionCreators(ControlsActions, dispatch),
  }
);

class ChartPopout extends Component {
  static propTypes = {
    /** Route params */
    /** Controls redux state */
    controlsState: PropTypes.object,
    match: PropTypes.object,
    /** Controls redux actions */
    controlsActions: PropTypes.object,
    /** Current section in controls state */
    currentSection: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = {
      isDrillingDown: false,
      isSecondLevel: false,
      savedDate: {},
      isOpenRecordingModal: false,
      callRecordingInfo: null,
    };
  }

  componentWillMount() {
    window.onresize = _.debounce(() => {
      Utils.dispatchEvent('redrawChart');
    }, 50);

    const { id } = this.props.match.params;

    AppData.getPopoutParams(id).then((response) => {
      const { data } = response;
      this.props.controlsActions.loadState('popout', data.controlsState);
      this.setState(data.componentState);
    });
  }

  componentWillUnmount() {
    window.onresize = null;
  }

  getSelects = () => {
    if (this.state.unitSelects) {
      return this.state.unitSelects[this.state.unit];
    } else if (this.state.selects) {
      return this.state.selects;
    }
  }

  getDrilldownSelects = (level) => {
    let selects;
    if (level) {
      selects = [
        { 'metric': 'start_time', 'label': 'Time' },
        { 'metric': 'talk_duration_seconds', 'label': 'Duration' },
      ];
    } else {
      selects = this.getTableSelects();
    }
    return selects;
  }

  getTableSelects = () => {
    let selects;
    switch (this.state.drillDownMetric) {
      case 'Inbound':
        selects = [{ 'metric': 'inbound', 'label': 'Inbound' }];
        break;
      case 'Outbound':
        selects = [{ 'metric': 'outbound', 'label': 'Outbound' }];
        break;
      case 'Toll free':
        selects = [{ 'metric': 'tollfree_inbound', 'label': 'Toll free' }];
        break;
      case 'International':
        selects = [{ 'metric': 'international_outbound', 'label': 'International' }];
        break;
      default:
        selects = [{ }];
        break;
    }
    return selects;
  }

  getDrilldownFilters = () => {
    let filters;
    switch (this.state.drillDownMetric) {
      case 'Inbound':
        filters = { 'inbound': true };
        break;
      case 'Outbound':
        filters = { 'outbound': true };
        break;
      case 'Toll free':
        filters = { 'toll_free': true };
        break;
      case 'International':
        filters = { 'international': true };
        break;
      default:
        filters = { 'total_calls': true };
        break;
    }
    return filters;
  }

  getDrilldownDate = (period, timeZone) => {
    const { controlsState } = this.props;
    let drillDownDate = controlsState.timeRange;
    let timeRange = controlsState.timeRange;
    if (this.state.isDrillingDown) {
      const date = moment.tz(this.state.drillDownDate, timeZone);
      const start_date = date.startOf(period).toISOString();
      const end_date = date.endOf(period).toISOString();
      drillDownDate = {
        type: 'absolute',
        start: start_date,
        end: end_date,
      };
      timeRange = {
        type: 'absolute',
        start: start_date,
        end: end_date,
      };
    }
    return { drillDownDate, timeRange };
  }

  getDetailPeriodOptions = () => {
    const options = [{ id: 'minute', label: 'Minute' }, { id: 'hour', label: 'Hourly' }, { id: 'day', label: 'Daily' }];
    return options;
  }

  getDrilldownOptions = (timeZone) => {
    const { controlsState } = this.props;
    let extraParams = null;
    let url = this.state.url;
    let filters = controlsState.apiFilters;
    let timeRange = controlsState.timeRange;
    let drillDownDate = controlsState.timeRange;
    let tableSelects = this.getSelects();
    let selects = this.getSelects();
    let detailPeriodOptions = this.state.detailPeriodOptions;
    let initialIndex = 0;
    let initialValue = detailPeriodOptions[0];

    if (this.state.isDrillingDown && !this.state.isSecondLevel) {
      url = this.state.url;
      filters = Object.assign({}, filters, this.getDrilldownFilters());
      extraParams = this.state.extraParams;
      const { drillDownDate: date, timeRange: time } = this.getDrilldownDate('day', timeZone);
      drillDownDate = date;
      timeRange = time;
      tableSelects = this.getTableSelects();
      selects = this.getDrilldownSelects(this.state.isSecondLevel);
      detailPeriodOptions = this.getDetailPeriodOptions();
      initialIndex = 2;
      initialValue = { id: 'hour', label: 'Hourly' };
    } else if (this.state.isDrillingDown && this.state.isSecondLevel) {
      url = this.state.callLogUrl;
      filters = Object.assign({}, filters, this.getDrilldownFilters());
      extraParams = { 'format': 'serial', 'limit': 5000 };
      const { drillDownDate: date, timeRange: time } = this.getDrilldownDate('hour', timeZone);
      drillDownDate = date;
      timeRange = time;
      tableSelects = this.getTableSelects();
      selects = this.getDrilldownSelects(this.state.isSecondLevel);
      detailPeriodOptions = this.getDetailPeriodOptions();
      initialIndex = 2;
      initialValue = { id: 'minute', label: 'Minute' };
    }
    return { url, filters, extraParams, timeRange, drillDownDate, tableSelects, selects, detailPeriodOptions, initialIndex, initialValue };
  }

  handleCloseModal = () => {
    this.setState({
      callRecordingInfo: null,
      isOpenRecordingModal: false,
    });
  }

  triggerCallRecordingModal = (callRecordingInfo) => {
    const newStartDate = moment(callRecordingInfo.start_time, 'MM/DD/YYYY hh:mm');
    const newEndDate = moment(callRecordingInfo.end_time, 'MM/DD/YYYY hh:mm');
    callRecordingInfo.start_time = newStartDate.format('MM/DD/YY hh:mm A');
    callRecordingInfo.end_time = newEndDate.format('MM/DD/YY hh:mm A');
    this.setState({
      callRecordingInfo,
      isOpenRecordingModal: true,
    });
  }

  updateChartData = (callType, date, level) => {
    this.setState({
      isDrillingDown: true,
      drillDownMetric: callType,
      drillDownDate: date,
      isSecondLevel: ((level !== undefined) ? level : false),
      unit: level ? 'minutes' : 'calls',
      savedDate: !this.state.isSecondLevel ? { metric: callType, time: date } : null,
    });
  }

  backFromDrillDown = () => {
    this.setState({
      isDrillingDown: false,
      isSecondLevel: false,
      drillDownMetric: null,
      unit: 'calls',
    });
  }

  backFromSecondDrillDown = () => {
    const { metric, time } = this.state.savedDate;
    this.updateChartData(metric, time, false);
  }

  handleChartChange = handleChartChange.bind(this)

  handleUnitChange = handleUnitChange.bind(this)

  render() {
    if (!this.state.url) {
      return (
        <DefaultSpinner />
      );
    }
    const { controlsState, currentSection } = this.props;
    const timeZone = controlsState.timezone;
    let tablePanel;

    const { url, filters, extraParams, timeRange, drillDownDate, tableSelects, selects, detailPeriodOptions, initialValue } = this.getDrilldownOptions(timeZone);
    if (this.state.detailUrl) {
      const activeTab = controlsState.filterTypeLabel ? controlsState.filterTypeLabel : controlsState.section;
      tablePanel = (
        <AnalyticsTablePanel
          unit={this.state.unit || this.state.units[0]}
          filters={filters}
          selects={tableSelects}
          callLogFields={controlsState.callLogFields}
          timeRange={timeRange}
          timeRanges={controlsState.timeRanges}
          realTime={controlsState.realTime}
          detailUrl={this.state.detailUrl}
          detailPeriodOptions={detailPeriodOptions}
          callLogUrl={this.state.callLogUrl}
          activeTab={activeTab}
          isDrillingDown={this.state.isDrillingDown}
          isSecondLevel={this.state.isSecondLevel}
          drillDownMetric={this.state.drillDownMetric}
          initialValue={initialValue}
          chart={this.state.chart}
        />
      );
    }

    let Header;
    if (controlsState.page === 'period') {
      Header = PeriodPopoutHeader;
    } else if (controlsState.page === 'comparison') {
      Header = ComparisonPopoutHeader;
    } else {
      Header = BasicPopoutHeader;
    }

    return (
      <>
        <div className="chart-container">
          <Header
            controlsSection={currentSection}
            title={this.state.title}
            unit={this.state.unit || this.state.units[0]}
            units={this.state.units}
            charts={this.state.charts}
            onUnitChange={this.handleUnitChange}
            onChartChange={this.handleChartChange}
            isDrillingDown={this.state.isDrillingDown}
            isSecondLevel={this.state.isSecondLevel}
            drillDownDate={drillDownDate}
            backFromDrillDown={this.backFromDrillDown}
            backFromSecondDrillDown={this.backFromSecondDrillDown}
          />
          <CallRecordingModal
            isOpen={!!this.state.isOpenRecordingModal}
            onRequestClose={this.handleCloseModal}
            handleCloseModal={this.handleCloseModal}
            callRecordingInfo={this.state.callRecordingInfo}
          />
          <ChartAsync
            ref="chartWrapper"
            chart={this.state.chart}
            url={url}
            unit={this.state.unit || this.state.units[0]}
            selects={selects}
            title={this.state.title}
            timeRange={timeRange}
            timeRanges={controlsState.timeRanges}
            enabledLegendItems={controlsState.callType ? [controlsState.callType.label] : null}
            legendPosition={controlsState.page === 'period' ? 'bottom' : null}
            realTime={controlsState.realTime}
            labels={this.state.labels}
            filters={filters}
            isDrillingDown={this.state.isDrillingDown}
            isSecondLevel={this.state.isSecondLevel}
            updateChartData={this.updateChartData}
            extraParams={extraParams}
            timeZone={timeZone}
            triggerCallRecordingModal={this.triggerCallRecordingModal}
          />
        </div>
        {tablePanel}
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChartPopout);
