import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Control, Field } from 'react-redux-form';

const TOTAL_OPTIONS = [
  { value: '5', label: 'Five' },
  { value: '6', label: 'Six' },
  { value: '7', label: 'Seven' },
  { value: '8', label: 'Eight' },
  { value: '9', label: 'Nine' },
  { value: '10', label: 'Ten' },
];

class RatingOptions extends Component {
  static propTypes = {
    model: PropTypes.string,
  };

  mapOptions = (options) => (
    options.map((option) => (
      <option key={option.value} value={option.value}>
        {option.label}
      </option>
    ))
  )

  render() {
    const { model } = this.props;
    return (
      <div className="questions-form__question-options">
        <div>
          <label className="questions-form__label">
            Max Rating
          </label>
          <Control.select
            model={`${model}.total`}
            className="questions-form__select"
          >
            {this.mapOptions(TOTAL_OPTIONS)}
          </Control.select>
        </div>
      </div>
    );
  }
}

export default RatingOptions;
