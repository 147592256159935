import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { enableBatching } from 'redux-batched-actions';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';

import rootReducer from 'reducers/index';
import createLogger from './createLogger';

const middleware = [thunk];
const { REACT_APP_DEV, REACT_APP_TEST } = process.env;

if (REACT_APP_DEV && REACT_APP_TEST !== 'true') {
  middleware.push(createLogger());
}

export const history = createBrowserHistory();

const enhancer = compose(
  applyMiddleware(
    ...middleware,
    routerMiddleware(history),
  ),
);

export default function configureStore(initialState) {
  const reducer = enableBatching(rootReducer(history));
  const store = createStore(reducer, initialState, enhancer);

  if (module.hot) {
    module.hot.accept('../reducers', () =>
      // eslint-disable-next-line global-require
      store.replaceReducer(require('../reducers').default(history))
    );
  }

  return store;
}
