import React from 'react';
import PropTypes from 'prop-types';
import { SpinnerSvg } from '@nextiva/next-ui';

export const Types = {
  BIG: {
    size: 125,
    thickness: 10,
  },
  NORMAL: {
    size: 75,
    thickness: 5,
  },
  SMALL: {
    size: 18,
    thickness: 3,
  },
};


function DefaultSpinner({ kind }) {
  return (
    <SpinnerSvg {...kind} data-testid="defaultSpinner"/>
  );
}


DefaultSpinner.defaultProps = {
  kind: Types.BIG,
};

DefaultSpinner.propTypes = {
  kind: PropTypes.shape({
    size: PropTypes.number,
    thickness: PropTypes.number,
  }),
};


export default DefaultSpinner;
