import _ from 'lodash';
import moment from 'moment-timezone';
import Utils from 'utils/common_utils';
import AppData from 'app_data';

import { convertToPie, convertToTable } from 'utils/chart_utils';
import Filters from 'modules/reporting/custom_filters';

const SampleBaseProto = {
  get buckets() {
    return !!this.ui.minuteBuckets;
  },
  get averageRand() {
    return generateRandFunc(this.ui, 'average', this.getSeed());
  },
  get dailyRand() {
    return generateRandFunc(this.ui, 'daily', this.getSeed());
  },
  get aggregateRand() {
    return generateRandFunc(this.ui, 'aggregate', this.getSeed());
  },
  get selectedFilters() {
    return _.filter(this.ui.filters[this.ui.dropzones[1]].items, { selected: true });
  },
  getSeed() {
    let seed = 10;
    if (this.ui.chart.report.indexOf('talk_time') === -1) {
      this.ui.chart.unit = 'calls';
    } else {
      this.ui.chart.unit = 'minutes';
      seed *= 4;
    }

    if (this.ui.chart.report.indexOf('users') === -1) {
      seed *= 10;
    }

    return seed;
  },
  generateDates() {
    return generateDates(this.ui.timeRanges[0]);
  },
  generateHours() {
    return generateHours(this.ui.timeRanges[0]);
  },
  getBuckets() {
    return getBuckets(this.ui.minuteBuckets);
  },
  getSelects(dropzone) {
    return getSelects(this.ui, dropzone);
  },
  getFullSelects(dropzone) {
    const filters = this.ui.filters;
    return _.filter(filters[this.ui.dropzones[dropzone]].items, { selected: true });
  },
  getUsers(n) {
    return _.map(Filters.users.items.slice(0, n), 'label');
  },
  createData() {
    return createData(this.params);
  },
  createCallLogData() {
    return createCallLogData(this.params);
  },
  createRatingLogData() {
    return createRatingLogData(this.params);
  },
  createSMSLogData() {
    return createSMSLogData(this.params);
  }
};

function getBuckets(buckets) {
  return _.map(buckets, (o) => {
    if (o.to) {
      return `${o.from} - ${o.to}min`;
    }
    return `${o.from}+ min`;
  });
}

function getSelects(ui, dropzone) {
  const filters = ui.filters;
  return _.map(_.filter(filters[ui.dropzones[dropzone]].items, { selected: true }), (o) => o.label);
}

function getDate(dates, timezone = AppData.user.timezone) {
  let startDate;
  let dateRange;
  const timeRange = dates[0];
  if (timeRange.type === 'relative') {
    startDate = Utils.relativeToAbsolute(timeRange);
  } else {
    startDate = moment(timeRange.start).tz(timezone);
  }
  startDate = startDate.format('YYYY-MM-DD');
  let endDate;
  if (timeRange.end) {
    endDate = moment(timeRange.end).tz(timezone).format('YYYY-MM-DD');
  }
  if (endDate && endDate === startDate) {
    dateRange = startDate;
  } else if (!endDate) {
    dateRange = `${startDate} - Now`;
  } else {
    dateRange = `${startDate} - ${endDate}`;
  }

  return dateRange;
}

function generateDates(timeRange) {
  const dates = [];
  const { start, end } = Utils.getStartEndDates(timeRange);

  const diff = Math.abs(start.diff(end, 'days'));
  dates.push(start.format('YYYY-MM-DD'));
  _.times(diff, () => {
    dates.push(start.add(1, 'days').format('YYYY-MM-DD'));
  });
  return dates;
}

function generateHours(timeRange) {
  const { start, end } = Utils.getStartEndDates(timeRange);

  const hours = [];
  const startHour = start.hour();
  let endHour;

  if (Math.abs(start.diff(end, 'days')) < 1) {
    endHour = end.hour();
  } else {
    endHour = 23;
  }

  for (let i = startHour; i <= endHour; i++) {
    const meridiem = i < 12 ? 'AM' : 'PM';
    let hour = i;
    if (hour === 0) {
      hour = 12;
    }
    hours.push((hour > 12 ? hour - 12 : hour) + meridiem);
  }
  return hours;
}

function generateRandFunc(ui, randType, seed) {
  let rand;
  const { start, end } = Utils.getStartEndDates(ui.timeRanges[0]);
  const dateDiff = Math.abs(start.diff(end, 'days')) + 1;
  const filtersCount = _.filter(ui.filters[ui.dropzones[1]].items, { selected: true }).length;
  if (!seed) seed = 50;

  if (ui.minuteBuckets) {
    seed = seed / ui.minuteBuckets.length;
  }

  if (randType === 'aggregate') {
    if (ui.chart.format !== 'compare') {
      seed *= filtersCount;
    }
    rand = getRandomInt.bind(null, seed * dateDiff, (seed * 2.5) * dateDiff);
  } else if (randType === 'average') {
    seed *= filtersCount;
    if (ui.chart.breakdown === 'hourly_average') {
      seed = Math.ceil(seed / 12);
    }
    rand = getRandomInt.bind(null, seed, (seed * 2.5));
  } else if (randType === 'daily') {
    if (ui.chart.format !== 'compare') {
      seed *= filtersCount;
    }
    rand = getRandomInt.bind(null, seed, (seed * 2.5));
  }

  return rand;
}

function createData(params) {
  const { ui, rand, xGrouping, selects, dateFormat, tableLabels, tableSwap, alphaGraphs } = params;
  const data = [];
  if (xGrouping) {
    _.each(xGrouping, (x) => {
      const obj = { category: x };
      _.each(selects, (select) => {
        obj[select] = rand();
      });
      data.push(obj);
    });
  } else {
    _.each(selects, (label) => {
      data.push({
        category: label,
        [`${label}`]: rand(),
      });
    });
  }

  let result = {
    meta: {
      date_format: dateFormat,
      tableLabels,
      swap: tableSwap || false,
      categoryField: 'category',
      items: _.map(selects, (l) => l),
    },
    data,
  };


  if (alphaGraphs) {
    const newItems = [];
    const newData = [];
    const alphaItems = [];

    // replace metadata
    _.each(result.meta.items, (item, index) => {
      newItems.splice(index, 0, `P1: ${item}`);
      newItems.push(`P2: ${item}`);
      alphaItems.push(`P2: ${item}`);
    });

    result.meta.items = newItems;
    result.meta.alphaGraphs = alphaItems;

    // replace category field and add p2 variations
    _.each(result.data, (row, index) => {
      const newRow = { day_in_date_range: index };
      _.each(_.keys(row), (key) => {
        if (key !== 'category') {
          newRow[`P1: ${key}`] = row[key];
          newRow[`P2: ${key}`] = vary(row[key]);
        }
      });
      newData.push(newRow);
    });

    result.data = newData;
    result.meta.categoryField = 'day_in_date_range';
    result.meta.lineThickness = 5;
    result.meta.showChartCursor = false;
    result.meta.legendEqualWidths = true;
  }

  if (ui.chart.name === 'pie') {
    result = convertToPie(result, rand);
  } else if (ui.chart.name === 'table') {
    result = convertToTable(result, ui.timeRanges);
  }

  return result;
}

function createCallLogData(params) {
  const { selects, items, dates, groupLabel } = params;

  const result = {
    altHeader: 'Call Fields',
    headers: selects.map((select) => ({ ...select, id: select.key })),
    rows: [],
    swap: true,
  };

  const startDate = dates;
  _.each(items.slice(0, 10), (item) => {
    const row = {
      data: {},
      suffix: null,
    };

    _.each(selects, (select) => {
      if (sampleCallLogFields[select.label]) {
        let param;
        if (select.label === 'Time of Call') {
          param = startDate;
        }
        row.data[select.key] = sampleCallLogFields[select.label](param);
      } else {
        // All other call log dropdowns include users so fetch from user filters
        if (select.label === 'User' && groupLabel !== 'User') {
          const index = getRandomInt(0, Filters.users.items.length - 1);
          row.data[select.key] = Filters.users.items[index].label;
        } else {
          row.data[select.key] = item;
        }
      }
    });

    result.rows.push(row);
  });

  const sampleRow = { data: selects.reduce((memo, select) => {
    memo[select.key] = '...';
    return memo;
  }, {}) };
  _.times(3, () => result.rows.push(sampleRow));

  return result;
}

function createRatingLogData(params) {
  const { ui, rand, selects, tableLabels, tableSwap, items } = params;
  const data = [{
    'Call Type': 'Inbound call',
    'Time': '01/01/2017 12:00 AM',
    'Call User': 'A User',
    'Question Group': 'A Question Group Title',
    'Question Title': 'A Question Title',
    'Question Type': 'scale',
    'Min Score': 0,
    'Max Score': 2,
    'Score': 0,
    'Rating': 0,
    'category': 'Another User',
  }];
  let result = {
    meta: {
      tableLabels,
      swap: tableSwap || false,
      categoryField: 'category',
      items,
    },
    data,
  };

  result = convertToTable(result, ui.timeRanges);

  return result;
}

function createSMSLogData(params) {
  const { ui, rand, selects, tableLabels, tableSwap, items } = params;
  const data = [{
    'Time': '07/11/2023 4:19 AM',
    'Sender': 'A User',
    'Recipients': '15551234567, Another User',
    'category': 'SMS'
  }];
  let result = {
    meta: {
      tableLabels,
      swap: tableSwap || false,
      categoryField: 'category',
      items,
    },
    data,
  };

  result = convertToTable(result, ui.timeRanges);

  return result;
}

function vary(num, range) {
  if (!range) range = 30;
  const pct = getRandomInt(-range, range) / 100;
  return Math.floor(num + (num * pct));
}

function getRandomInt(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

const sampleCallLogFields = {
  currentDate: null,
  'Answered': function () {
    const items = ['Yes', 'No', 'Yes-PostRedirect'];
    return items[Math.floor(Math.random() * items.length)];
  },
  'Direction': function () {
    const items = ['Inbound', 'Outbound'];
    return items[Math.floor(Math.random() * items.length)];
  },
  'Duration': function () {
    return getRandomInt(61, 3000);
  },
  dateTime(date) {
    const start = date.type === 'absolute' ? moment(date.start) : Utils.relativeToAbsolute(date);

    if (!this.currentDate) {
      this.currentDate = start;
      return start.format('MM/DD/YY hh:mm:ss');
    }

    this.currentDate = this.currentDate.add(getRandomInt(3, 50), 'minutes');
    return this.currentDate.format('MM/DD/YY hh:mm:ss');
  },
  phoneNumber() {
    return `(555) 555-${Math.random().toString().slice(2, 6)}`;
  },
  'From': function () {
    return this.phoneNumber();
  },
  'To': function () {
    return this.phoneNumber();
  },
  'Time of Call': function (...args) {
    return this.dateTime(...args);
  },
  'State': function () {
    return 'AZ';
  },
};

export { getDate };
export default SampleBaseProto;
