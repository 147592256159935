import _ from 'lodash';

const CallLog = function (ui) {
  this.ui = ui;
  this.params = {};
  const params = this.params;

  this.individualCalls = () => {
    params.selects = this.getFullSelects(0);
    params.items = _.map(this.selectedFilters, (i) => i.label);
    params.dates = this.ui.timeRanges[0];
    params.groupLabel = this.ui.chart.groupLabel;

    this.createData = () => this.createCallLogData(params);
  };
};

export default CallLog;
