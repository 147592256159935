
import Scorecards from 'modules/monitoring/scorecards';
import Gamification from 'modules/monitoring/gamification/gamification';
import CustomDashboard from 'modules/monitoring/custom_dashboard';
import { DashboardContainer as Dashboard } from 'modules/monitoring/dashboard';
import { WallboardContainer as Wallboard } from 'modules/monitoring/wallboard';


const routes = [
  {
    path: '/monitoring/dashboard',
    title: 'Dashboard',
    component: Dashboard,
  },
  {
    path: '/monitoring/wallboard',
    title: 'Wallboards',
    component: Wallboard,
  },
  {
    path: '/monitoring/scorecards',
    title: 'Scorecards',
    component: Scorecards,
  },
  {
    path: '/monitoring/gamification',
    title: 'Gamification',
    component: Gamification,
  },
  {
    path: '/monitoring/custom',
    title: 'Custom',
    parameter: '/:dashboardId?',
    component: CustomDashboard,
  },
];


export default routes;
