import React from 'react';
import Rating from 'react-rating';
import { actions } from 'react-redux-form';

import { Radio, RadioGroup } from 'components/radio_group';
import InputCharacterCount from 'components/input_character_count';

export function clearOptionsOnTypeChange(model, value) {
  return (dispatch) => {
    const optionsModel = model.replace('question_type', 'options');
    dispatch(actions.change(optionsModel, getInitialStateForType(value)));
    dispatch(actions.change(model, value));
  };
}

export function getInitialStateForType(type) {
  switch (type) {
    case 'scale':
      return {
        'start': '0',
        'total': '3',
      };
    case 'yes-no':
      return {
        'yes': 'Yes',
        'no': 'No',
      };
    case 'rating':
      return {
        'total': '5',
      };
    case 'short-answer':
      return {
        'max': '200',
      };
    default:
      return {};
  }
}

export function showErrorAfterTouched(field) {
  return (field.touched || field.submitted) && !field.focus;
}

export function getInitialQuestion() {
  return {
    question_type: 'scale',
    title: '',
    text: '',
    options: getInitialStateForType('scale'),
  };
}

export function getPreviewComponent(question, onChange = () => {}, value) {
  let questionComponent = <div />;
  if (question.question_type === 'scale') {
    const options = Array.from({ length: question.options.total }, (v, k) => k).map((option, index) => {
      const value = question.options.start === '0' ? index : index + 1;
      return (
        <Radio
          key={value}
          className="questions-form__radio"
          value={parseInt(value, 10)}
        >
          {value}
        </Radio>
      );
    });
    questionComponent = (
      <RadioGroup
        className="questions-form__radio-group"
        name={`question_${question.id}`}
        onChange={onChange}
        selectedValue={parseInt(value, 10)}
      >
        {options}
      </RadioGroup>
    );
  } else if (question.question_type === 'yes-no') {
    questionComponent = (
      <RadioGroup
        className="questions-form__radio-group"
        name={`question_${question.id}`}
        onChange={onChange}
        selectedValue={value}
      >
        <Radio
          className="questions-form__radio"
          value={question.options.yes}
        >
          {question.options.yes}
        </Radio>
        <Radio
          className="questions-form__radio"
          value={question.options.no}
        >
          {question.options.no}
        </Radio>
      </RadioGroup>
    );
  } else if (question.question_type === 'rating') {
    questionComponent = (
      <Rating
        className="questions-form__rating"
        start={0}
        stop={parseInt(question.options.total, 10)}
        step={1}
        fractions={2}
        initialRating={value}
        onChange={onChange}
      />
    );
  } else if (question.question_type === 'short-answer') {
    questionComponent = (
      <InputCharacterCount
        maxCharacters={parseInt(question.options.max, 10)}
        onChange={onChange}
        value={value}
      />
    );
  }
  return questionComponent;
}

export function getPreviewComponentForResponding({ question, onChange, value }) {
  return getPreviewComponent(question, onChange, value);
}

export function getPreviewComponentForEditing(question) {
  return getPreviewComponent(question);
}
