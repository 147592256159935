import React from 'react';
import PropTypes from 'prop-types';

const QuestionsContainer = ({ children }) => (
  <div className="settings-questions">
    {children}
  </div>
);

QuestionsContainer.propTypes = {
  children: PropTypes.node,
};

export default QuestionsContainer;
