import React, { Component } from 'react';
import PropTypes from 'prop-types';

import TabbedControls from 'components/tabbed_controls';
import ListSelect from 'components/list_select';
import MultiSelect from 'components/multi_select';

class GamificationTileControls extends Component {
  static propTypes = {
    /** State for the gamfication tile */
    tileInfo: PropTypes.shape({
      /** Title for the tile */
      title: PropTypes.string,
      /** Current user type filter */
      filter: PropTypes.object,
      /** Filers for the current user type */
      filters: PropTypes.array,
      /** The call type metric */
      metric: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
      ]),
      /** The number of users to display */
      count: PropTypes.number,
      /** The leaderboard display type */
      table: PropTypes.string,
      /** Validation errors for the tile */
      errors: PropTypes.object,
    }).isRequired,
    /** Handler for title change */
    onTitleChange: PropTypes.func,
    /** User type filters */
    filterOptions: PropTypes.array,
    /** Handler for filter change */
    onFilterChange: PropTypes.func,
    /** Filter options based on filter choice */
    filtersOptions: PropTypes.array,
    /** Handler for filters change */
    onFiltersChange: PropTypes.func,
    /** Call metric options */
    metricOptions: PropTypes.array,
    /** Handler for metric change */
    onMetricChange: PropTypes.func,
    /** Options for number of users */
    countOptions: PropTypes.array,
    /** Handler for count change */
    onCountChange: PropTypes.func,
    /** Leaderboard display types */
    displayOptions: PropTypes.array,
    /** Handler for display change */
    onDisplayChange: PropTypes.func,
  }

  getTitleTab() {
    const { tileInfo, onTitleChange } = this.props;
    const tab = (
      <div className="custom-dashboard-controls-title">
        <input
          type="text"
          placeholder="Title"
          onChange={onTitleChange}
          value={tileInfo.title}
        />
      </div>
    );
    return tab;
  }

  getFilterTab() {
    const { tileInfo, onFilterChange, filterOptions } = this.props;
    const selected = tileInfo.filter;
    const tab = (
      <ListSelect
        options={filterOptions}
        onSelect={onFilterChange}
        selected={selected}
      />
    );
    return tab;
  }

  getFiltersTab() {
    const { tileInfo, onFiltersChange, filtersOptions } = this.props;
    const selected = tileInfo.filters;
    const tab = (
      <MultiSelect
        searchBar
        options={filtersOptions}
        onChange={onFiltersChange}
        initialSelected={selected}
      />
    );
    return tab;
  }

  getMetricTab() {
    const { tileInfo, onMetricChange, metricOptions } = this.props;
    let selected;
    if (tileInfo.metric) {
      selected = tileInfo.metric;
    }
    const tab = (
      <ListSelect
        options={metricOptions}
        onSelect={onMetricChange}
        selected={selected}
        compareField="key"
      />
    );
    return tab;
  }

  getCountTab() {
    const { tileInfo, onCountChange, countOptions } = this.props;
    const selected = {
      id: tileInfo.count.toString(),
    };
    const tab = (
      <ListSelect
        options={countOptions}
        onSelect={onCountChange}
        selected={selected}
      />
    );
    return tab;
  }

  getDisplayTab() {
    const { tileInfo, onDisplayChange, displayOptions } = this.props;
    const selected = {
      id: tileInfo.table,
    };
    const tab = (
      <ListSelect
        options={displayOptions}
        onSelect={onDisplayChange}
        selected={selected}
      />
    );
    return tab;
  }

  render() {
    const { errors } = this.props.tileInfo;
    const tabs = [
      { icon: 'icon-custom-gear', label: 'Title', content: this.getTitleTab(), className: (errors.title ? 'error' : null) },
      { icon: 'icon-custom-people', label: 'Filter', content: this.getFilterTab(), className: (errors.filter ? 'error' : null) },
      { icon: 'icon-custom-filter', label: 'Filters', content: this.getFiltersTab(), className: (errors.filters ? 'error' : null) },
      { icon: 'icon-custom-sliders', label: 'Metric', content: this.getMetricTab(), className: (errors.metric ? 'error' : null) },
      { icon: 'icon-custom-written-doc', label: 'Count', content: this.getCountTab(), className: (errors.count ? 'error' : null) },
      { icon: 'icon-custom-indented-list', label: 'Display', content: this.getDisplayTab(), className: (errors.table ? 'error' : null) },
    ];
    return (
      <TabbedControls
        tabs={tabs}
      />
    );
  }
}

export default GamificationTileControls;
