import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import cx from 'classnames';
import InlineSvgFactory from 'components/inline_svg';

const Plus = InlineSvgFactory('plus.svg');
const Minus = InlineSvgFactory('minus.svg');

class BucketModalRow extends Component {
  static propTypes = {
    /** Starting number of the bucket row */
    from: PropTypes.number,
    /** Ending number of the bucket row */
    to: PropTypes.number,
    /** Handler for changing the bucket row range */
    onChange: PropTypes.func,
    /** Whether or not to disable negative numbers */
    disableMinus: PropTypes.bool,
    /** Handler for removing a bucket row */
    removeRow: PropTypes.func,
    /** Handler for adding a bucket row */
    addRow: PropTypes.func,
    /** Whether or not there is an error with the selected end bucket range */
    toError: PropTypes.bool,
  };

  state = {
    placeholder: !this.props.to,
  };

  shouldComponentUpdate(np, ns) {
    const p = this.props;
    const s = this.state;
    const propDiff = p.from !== np.from || p.to !== np.to
      || p.toError !== np.toError || p.disableMinus !== np.disableMinus;
    const stateDiff = s.placeholder !== ns.placeholder;
    return propDiff || stateDiff;
  }

  togglePlaceholder = (e, id, forcePlaceholder) => {
    if (e && e.target.value) return;

    this.setState({
      placeholder: e && e.target.className === 'infinity' || !forcePlaceholder,
    }, () => {
      if (!this.state.placeholder) {
        setTimeout(() => {
          this.to.focus();
        }, 20);
      }
    });
  };

  handleChange = (e) => {
    const result = {};
    const val = e.target.value;


    if (!val && e.target.name === 'to' && e.type === 'blur') {
      this.togglePlaceholder(null);
    }

    _.each(['from', 'to'], (k) => {
      result[k] = e.target.name === k ? parseInt(val, 10) : parseInt(this.props[k], 10);
      if (isNaN(result[k])) result[k] = null;
    });

    // only allow positive integers
    if (!/[^0-9]/.test(val)) {
      if (e.type === 'blur') {
        if ((result.to && result.to <= result.from) || result.to === 0) {
          result['toError'] = true;
        } else {
          delete result['toError'];
        }
        if (result.from === null) {
          result.from = 0;
        }
      }
    }

    this.props.onChange(result);
  };

  handleKeyDown = (e) => {
    // TAB
    if (e.which === 9) {
      if (this.state.placeholder) {
        this.togglePlaceholder(null, null, true);
      }
    }
  };

  render() {
    const fromValue = this.props.from >= 0 && this.props.from !== null ? this.props.from : '';
    const toValue = this.props.to >= 1 && this.props.to !== null ? this.props.to : '';
    let minus;

    if (!this.props.disableMinus) {
      minus = <Minus onClick={this.props.removeRow} className="minus" />;
    }

    return (
      <div>
        <input
          className={cx({ 'minutes-bucket': true })}
          ref="from"
          type="text"
          name="from"
          maxLength="3"
          onKeyDown={this.handleKeyDown}
          onChange={this.handleChange}
          onBlur={this.handleChange}
          value={fromValue}
        />
        <span className="colon">:</span>
        {!this.state.placeholder ?
          <span>
            <input
              className={cx({ 'minutes-bucket': true, error: this.props.toError })}
              ref={(node) => { this.to = node; }}
              name="to"
              type="text"
              maxLength="3"
              onChange={this.handleChange}
              onBlur={this.handleChange}
              value={toValue}
            />
            <label className="mins">mins</label>
            {this.props.to ?
              <Plus onClick={this.props.addRow} className="plus" />
            : null }
            {minus}
          </span>
        :
          <span className="infinity-wrapper">
            <span
              ref="inf"
              onClick={this.togglePlaceholder}
              className="infinity"
            >
              ∞
            </span>
            {minus}
          </span>
        }
      </div>
    );
  }
}

export default BucketModalRow;
