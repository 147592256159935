import 'babel-polyfill';
import closest from  'element-closest';
import 'react-app-polyfill/ie11';
import React from 'react';
import FastClick from 'fastclick';
import ReactDOM from 'react-dom';
import 'url-search-params-polyfill';

import 'components/AmCharts';

import { printVersion } from 'utils/app_utils';
import App from './App';

// add polyfill Element.closest for IE11 => https://developer.mozilla.org/en-US/docs/Web/API/Element/closest
closest(window);
printVersion();

ReactDOM.render(<App />, document.getElementById('root'));

setTimeout(() => {
  FastClick.attach(document.body);
}, 1000);
