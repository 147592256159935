import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

// TODO update app with new checkbox for crossbrowser styles
class Checkbox extends Component {
  static propTypes = {
    /** The label for the checkbox */
    label: PropTypes.string,
    /** The position of the label */
    labelPosition: PropTypes.oneOf(['left', 'right']),
    /** The default value of the checkbox */
    defaultValue: PropTypes.string,
    /** Handler for when the checkbox is checked or unchecked */
    onChange: PropTypes.func,
    /** Whether or not the checkbox is checked */
    checked: PropTypes.bool,
  };

  static checkboxId = null;

  static defaultProps = {
    labelPosition: 'right',
    onChange: () => {},
  };

  constructor(props) {
    super(props);

    this.state = {
      checked: !!this.props.checked || false,
    };
  }

  componentWillMount = () => {
    this.checkboxId = _.uniqueId('checkbox');
  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.checked !== this.props.checked) {
      this.setState({
        checked: nextProps.checked,
      });
    }
  }

  onClickChange = () => {
    this.setState({ checked: !this.state.checked }, () => {
      this.props.onChange(this.state.checked);
    });
  }

  render() {
    let labelElement;
    const toDisplay = [];
    const { label, labelPosition } = this.props;

    toDisplay.push(
      <input
        key={`checkbox-${this.checkboxId}`}
        type="checkbox"
        id={this.checkboxId}
        name={this.checkboxId}
        label={this.props.label}
        checked={this.state.checked}
        onChange={this.onClickChange}
      />
    );

    if (label) {
      labelElement = (
        <label
          key={`label-${this.checkboxId}`}
          htmlFor={this.checkboxId}
        >
          {label}
        </label>
      );
      if (labelPosition === 'left') {
        toDisplay.unshift(labelElement);
      } else {
        toDisplay.push(labelElement);
      }
    }

    return (
      <span className="standard-checkbox">
        {toDisplay}
      </span>
    );
  }
}

export default Checkbox;
