import _ from 'lodash';

import base from './sample_base';
import Aggregate from './aggregate_sample';
import Compare from './compare_sample';
import Period from './period_sample';
import Trend from './trend_sample';
import CallLog from './call_log_sample';
import Distribution from './distribution_sample';
import RatingLog from './rating_log_sample';
import SMSLog from './sms_log_sample';

function SampleData(ui) {
  const mapping = {
    'aggregate': Aggregate,
    'compare': Compare,
    'period': Period,
    'trend': Trend,
    'call_log': CallLog,
    'distribution': Distribution,
    'rating_log': RatingLog,
    'sms_log': SMSLog,
  };

  const Report = mapping[ui.chart.format];
  Report.prototype = Object.create(base);
  Report.prototype.constructor = Report;

  const report = new Report(ui);
  report[_.camelCase(ui.chart.breakdown)]();

  return report.createData();
}

export default SampleData;
