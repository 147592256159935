import React, { useState, memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import isEmail from 'validator/lib/isEmail';
import cx from 'classnames';
import { Button, LabeledTextField } from '@nextiva/next-ui';


import { DefaultSpinner } from 'components';
import Styles from './ExportDataModal.module.scss';


const ExportDataModal = memo((props) => {
  const {
    onCancel,
    onExport,
  } = props;

  const [email, setEmail] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [isWrongEmail, setWrongEmail] = useState(false);

  const onClickSendButton = useCallback((e) => {
    e.preventDefault();

    if (!isEmail(email.trim())) {
      setWrongEmail(true);
    } else {
      setLoading(true);
      setWrongEmail(false);
      onExport(email)
    }
  });

  return (
    <form 
      onSubmit={onClickSendButton}
      className={cx('nx-popover__body', Styles.exportData)} 
    >
      <h3 className={Styles.title}>Send exported data</h3>

      <LabeledTextField
        label="Email"
        value={email}
        disabled={isLoading}
        isError={isWrongEmail}
        containerClassName={Styles.email}
        onChange={(_, value) => setEmail(value)}
      />

      <div className={Styles.buttons}>
        {isLoading && (
          <DefaultSpinner thickness={3} size={32} />
        )}

        <Button onClick={onCancel} className={Styles.cancel}>
          Cancel
        </Button>

        <Button 
          kind="success"
          disabled={isLoading}
          onClick={onClickSendButton} 
        >
          Send
        </Button>
      </div>
    </form>
  );
});


ExportDataModal.propTypes = {
  /** Handler for when exporting */
  onExport: PropTypes.func,
  /** Handler for canceling the export */
  onCancel: PropTypes.func,
};


ExportDataModal.displayName = 'ExportDataModal';


export default ExportDataModal;
