import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import AnalyticsTrendPanels from 'components/analytics_panels/analytics_trend_panels';
import MultiPieContainer from 'components/charts/multi_pie_container';
import { Collapse } from 'components';


function AnalyticsCallDuration(props) {
  const { state, actions } = props;

  const selects = _.cloneDeep(state.callTypes);

  if (state.callType) {
    const selectedValue = state.callType;
    const selectedIdx = _.findIndex(selects, { label: selectedValue.label });
    selects.splice(selectedIdx, 1); // removes selectedIdx
    selects.unshift(selectedValue); // Adds to front
    // This is used to order the selects array which will also order the charts
  }

  let charts = <div className="no-data">No data with these options.</div>;

  if (selects.length) {
    charts = _.map(state.metadata.reports, (report, index) =>
      <div key={index} className={`col-${report.size}`}>
        <MultiPieContainer
          chart={report.charts[0]}
          url={report.url}
          timeRange={state.timeRange}
          filters={state.apiFilters}
          selects={selects}
          minuteBuckets={state.minuteBuckets}
          legendPosition={'bottom'}
          hideSmallPies={!!state.callType}
        />
      </div>
    );
  }

  return (
    <div>
      <div style={{ position: 'relative' }}>
        <Collapse arrowClass={'panel-collapse-arrow'} disableAnimation>
          <AnalyticsTrendPanels
            className={'analytics-panel trend'}
            onPanelClick={(callType) => {
              // If current panel is selected, unselect
              if (!state.callType || callType.id !== state.callType.id) {
                actions.setCallType('callDuration', callType);
              } else {
                actions.setCallType('callDuration', null);
              }
            }}
            selected={state.callType}
            panelItems={state.callTypes}
            timeRange={state.timeRange}
            filters={state.apiFilters}
            showPercent={false}
          />
        </Collapse>
      </div>

      <div className="row analytics-charts">
        {charts}
      </div>
    </div>
  );
}


AnalyticsCallDuration.propTypes = {
  state: PropTypes.object,
  actions: PropTypes.object,
};

export default AnalyticsCallDuration;
