import _ from 'lodash';

import * as CUSTOM_DASHBOARD from 'constants/custom_dashboard';
import appDefaults from './app_defaults';

const tileTypeConstraints = {
  new: {
    minWidth: 3,
    width: 6,
  },
  reports: {
    minWidth: 3,
    width: 6,
  },
  call_type: {
    minWidth: 3,
    width: 6,
  },
  gamification: {
    minWidth: 6,
    width: 6,
  },
};
const bucketTypeOptions = [
  { key: 'users', id: 'users', label: 'Users', bucketType: 'users' },
  {
    key: 'locations',
    id: 'locations',
    label: 'Locations',
    bucketType: 'locations',
  },
  {
    key: 'call_groups',
    id: 'call_groups',
    label: 'Call Groups',
    bucketType: 'call_groups',
  },
  {
    key: 'phone_numbers',
    id: 'phone_numbers',
    label: 'Phone Numbers',
    bucketType: 'phone_numbers',
  },
  {
    key: 'teams:users',
    id: 'teams',
    teamType: 'users',
    label: 'Custom Groups: Users',
    bucketType: 'teams',
  },
  {
    key: 'teams:locations',
    id: 'teams',
    teamType: 'locations',
    label: 'Custom Groups: Locations',
    bucketType: 'teams',
  },
  {
    key: 'teams:call_groups',
    id: 'teams',
    teamType: 'call_groups',
    label: 'Custom Groups: Call Groups',
    bucketType: 'teams',
  },
  {
    key: 'teams:phone_numbers',
    id: 'teams',
    teamType: 'phone_numbers',
    label: 'Custom Groups: Phone Numbers',
    bucketType: 'teams',
  },
];
const countOptions = [5, 10, 15, 20];
const MAX_THRESHOLDS = 5;

function custom_dashboard(state = appDefaults.custom_dashboard, action) {
  switch (action.type) {
    case CUSTOM_DASHBOARD.SET_STATUS:
    case CUSTOM_DASHBOARD.SET_TITLE:
    case CUSTOM_DASHBOARD.SET_DESCRIPTION:
    case CUSTOM_DASHBOARD.SET_TIME_RANGE:
    case CUSTOM_DASHBOARD.SET_REAL_TIME:
    case CUSTOM_DASHBOARD.SET_OPTIONS_PAGE:
      return {
        ...state,
        [action.field]: action.value,
        errors: errorsReducer(state, action),
      };

    case CUSTOM_DASHBOARD.LOAD_DASHBOARD_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case CUSTOM_DASHBOARD.START_NEW_DASHBOARD:
      return {
        ...state,
        isLoading: false,
        status: 'READY',
      };

    case CUSTOM_DASHBOARD.TOGGLE_EDIT_DASHBOARD:
      return {
        ...state,
        isEditing: !state.isEditing,
      };

    case CUSTOM_DASHBOARD.LOAD_DASHBOARD_SUCCESS: {
      const dashboard = {};
      const data = action.data;

      dashboard.title = data.name;
      dashboard.description = data.description;
      dashboard.timeRange = data.time_range;
      dashboard.items = [];
      dashboard.layout = [];
      data.items.forEach((item, index) => {
        const layout = item.layout;
        const i = `n${index}`;
        layout.i = i;
        item.i = i;
        dashboard.items.push(item);
        dashboard.layout.push(layout);
      });
      dashboard.newCounter = data.items.length;

      return {
        ...state,
        ...dashboard,
        isNew: false,
        status: 'READY',
        isLoading: false,
      };
    }

    case CUSTOM_DASHBOARD.ADD_TILE: {
      const newCounter = state.newCounter + 1;
      return {
        ...state,
        items: tilesReducer(state.items, action, state.cols, newCounter),
        newCounter,
        isEditingTile: true,
        layout: layoutsReducer(state.layout, action, state.cols, newCounter),
      };
    }

    case CUSTOM_DASHBOARD.SET_TILE: {
      return {
        ...state,
        items: tilesReducer(state.items, action, state.cols, state.newCounter),
        tileType: action.tileType,
        layout: layoutsReducer(
          state.layout,
          action,
          state.cols,
          state.newCounter
        ),
      };
    }

    case CUSTOM_DASHBOARD.UPDATE_TILE:
      return {
        ...state,
        items: tilesReducer(state.items, action, state.cols, state.newCounter),
        isEditingTile: false,
        tile_options_page: 0,
      };

    case CUSTOM_DASHBOARD.REMOVE_TILE:
      return {
        ...state,
        items: tilesReducer(state.items, action, state.cols, state.newCounter),
        layout: layoutsReducer(
          state.layout,
          action,
          state.cols,
          state.newCounter
        ),
        isEditingTile: false,
        tile_options_page: 0,
      };

    case CUSTOM_DASHBOARD.EDIT_TILE:
      return {
        ...state,
        items: tilesReducer(state.items, action, state.cols, state.newCounter),
        isEditingTile: true,
      };

    case CUSTOM_DASHBOARD.CANCEL:
      return {
        ...state,
        isEditingTile: false,
        items: tilesReducer(state.items, action, state.cols, state.newCounter),
      };

    case CUSTOM_DASHBOARD.CANCEL_NEW:
      return {
        ...state,
        items: tilesReducer(state.items, action, state.cols, state.newCounter),
        layout: layoutsReducer(
          state.layout,
          action,
          state.cols,
          state.newCounter
        ),
      };

    case CUSTOM_DASHBOARD.LAYOUT_CHANGE: {
      return {
        ...state,
        layout: action.layout,
      };
    }

    case CUSTOM_DASHBOARD.BREAKPOINT_CHANGE:
      return {
        ...state,
        breakpoint: action.breakpoint,
        cols: action.cols,
      };

    case CUSTOM_DASHBOARD.VALIDATE: {
      const errors = _.cloneDeep(appDefaults.custom_dashboard.errors);
      const tiles = state.items;

      const unfinishedTiles = tiles.filter(tile => tile.isEditing);

      if (!action.options.excludeTitle) {
        // title is required
        if (!(state.title && state.title.length)) {
          errors.title.hasError = true;
        }
      }

      // at least 1 tile
      if (tiles.length === 0) {
        errors.tile.hasError = true;
      }

      if (unfinishedTiles.length > 0) {
        errors.editing.hasError = true;
      }

      return {
        ...state,
        errors,
      };
    }

    case CUSTOM_DASHBOARD.CLEAR:
      return appDefaults.custom_dashboard;

    default:
      return state;
  }
}

function errorsReducer(uiState, action) {
  switch (action.type) {
    case CUSTOM_DASHBOARD.SET_TITLE: {
      if (action.value.length && uiState.errors.title.hasError) {
        const title = Object.assign({}, uiState.errors.title, {
          hasError: false,
        });

        return Object.assign({}, uiState.errors, {
          title,
        });
      }
      return uiState.errors;
    }

    default:
      return uiState.errors;
  }
}

function layoutsReducer(state = [], action, cols, counter) {
  switch (action.type) {
    case CUSTOM_DASHBOARD.ADD_TILE:
      return [...state, layoutReducer(undefined, action, cols, counter)];
    case CUSTOM_DASHBOARD.REMOVE_TILE:
      return [
        ...state.slice(0, action.index),
        ...state.slice(action.index + 1),
      ];
    case CUSTOM_DASHBOARD.CANCEL_NEW:
    case CUSTOM_DASHBOARD.SET_TILE:
      return [
        ...state.slice(0, action.index),
        layoutReducer(state[action.index], action, cols, counter),
        ...state.slice(action.index + 1),
      ];
    default:
      return state;
  }
}

function layoutReducer(state = {}, action, cols = 12, counter) {
  switch (action.type) {
    case CUSTOM_DASHBOARD.ADD_TILE: {
      const halfWidth = cols / 2;
      const height = 4;
      const minHeight = Math.ceil(cols / 6);
      const minWidth = Math.ceil(cols / 4);
      return {
        i: `n${counter}`,
        x: 0,
        y: 0,
        w: halfWidth,
        h: height,
        minW: minWidth,
        minH: minHeight,
      };
    }
    case CUSTOM_DASHBOARD.SET_TILE:
    case CUSTOM_DASHBOARD.CANCEL_NEW:
      return {
        ...state,
        minW: tileTypeConstraints[action.tileType].minWidth,
        w: tileTypeConstraints[action.tileType].width,
      };
    default:
      return state;
  }
}

function tilesReducer(state = [], action, cols, counter) {
  switch (action.type) {
    case CUSTOM_DASHBOARD.ADD_TILE:
      return [...state, tileReducer(undefined, action, cols, counter)];
    case CUSTOM_DASHBOARD.REMOVE_TILE:
      return [
        ...state.slice(0, action.index),
        ...state.slice(action.index + 1),
      ];
    case CUSTOM_DASHBOARD.UPDATE_TILE:
    case CUSTOM_DASHBOARD.EDIT_TILE:
    case CUSTOM_DASHBOARD.SET_TILE:
    case CUSTOM_DASHBOARD.CANCEL:
    case CUSTOM_DASHBOARD.CANCEL_NEW:
      return [
        ...state.slice(0, action.index),
        tileReducer(state[action.index], action),
        ...state.slice(action.index + 1),
      ];
    default:
      return state;
  }
}

function tileReducer(state, action, cols = 12, counter) {
  switch (action.type) {
    case CUSTOM_DASHBOARD.ADD_TILE:
      return {
        i: `n${counter}`,
        tileType: 'new',
        isNew: true,
        content: 'new tile',
        isEditing: true,
        layout: layoutReducer(undefined, action, cols, counter),
        tileInfo: tileTypeReducer(undefined, action),
      };
    case CUSTOM_DASHBOARD.EDIT_TILE:
      return {
        ...state,
        isEditing: true,
      };
    case CUSTOM_DASHBOARD.CANCEL:
      return {
        ...state,
        isEditing: false,
      };
    case CUSTOM_DASHBOARD.CANCEL_NEW:
      return {
        ...state,
        content: 'new tile',
        tileType: 'new',
        tileInfo: tileTypeReducer(undefined, action),
      };
    case CUSTOM_DASHBOARD.UPDATE_TILE:
      return {
        ...state,
        isNew: false,
        tileInfo: _.omit(action.tileState, 'errors'),
        isEditing: false,
      };
    case CUSTOM_DASHBOARD.SET_TILE: {
      const tileType = action.tileType;
      const tileTypeLabel = tileType.replace('_', ' ');
      return {
        ...state,
        content: `${tileTypeLabel} tile`,
        tileType,
        tileInfo: tileTypeReducer(undefined, action),
        layout: layoutReducer(state.layout, action, cols, counter),
      };
    }
    default:
      return state;
  }
}

function tileTypeReducer(state, action) {
  switch (action.tileType) {
    case 'reports':
      return customReportTile(state, action);
    case 'call_type':
      return callTypeTile(state, action);
    case 'gamification':
      return gamificationTile(state, action);
    default:
      return {};
  }
}

function customReportTile(
  state = {
    reportId: null,
    title: null,
    displayType: null,
    displayTypes: [],
    displayLegend: 'legendEnabled',
    errors: {},
  },
  action
) {
  switch (action.type) {
    case CUSTOM_DASHBOARD.UPDATE_TILE:
      return {
        ...state,
        ...action.state,
      };
    case CUSTOM_DASHBOARD.SET_TILE:
    default:
      return state;
  }
}

function callTypeTile(
  state = {
    filter: bucketTypeOptions[0].key,
    filters: [],
    title: null,
    callType: null,
    threshold: null,
    thresholds: null,
    thresholdCount: 1,
    errors: {},
  },
  action
) {
  switch (action.type) {
    case CUSTOM_DASHBOARD.UPDATE_TILE:
      return {
        ...state,
        ...action.state,
      };
    case CUSTOM_DASHBOARD.SET_TILE:
    default:
      return state;
  }
}

function gamificationTile(
  state = {
    filter: bucketTypeOptions[0],
    filters: [],
    title: null,
    count: countOptions[0],
    grouping: false,
    metric: null,
    table: 'original',
    errors: {},
  },
  action
) {
  switch (action.type) {
    case CUSTOM_DASHBOARD.UPDATE_TILE:
      return {
        ...state,
        ...action.state,
      };
    case CUSTOM_DASHBOARD.SET_TILE:
    default:
      return state;
  }
}

export default custom_dashboard;
