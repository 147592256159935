import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { DefaultSpinner } from 'components';

import ReportControls from 'components/report_controls';
import ReportPageChart from 'components/charts/report_page_chart';
import { ControlsActions } from 'actions/controls_actions';

const mapStateToProps = (state) => ({
  controlsState: state.controls.reporting || {},
});

const mapDispatchToProps = (dispatch) => ({
  controlsActions: bindActionCreators(ControlsActions, dispatch),
});

class ReportPage extends Component {
  static propTypes = {
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }).isRequired,
    controlsState: PropTypes.object,
    controlsActions: PropTypes.object,
  };

  componentWillMount() {
    window.addEventListener('refresh', this.storeDidChange);
    const path = this.props.location.pathname.split('/');
    const section = path[2];

    this.props.controlsActions.getReportingMetadata(section);
  }

  componentWillReceiveProps(nextProps) {
    const { controlsState } = this.props;
    const oldPath = this.props.location.pathname.split('/');
    const newPath = nextProps.location.pathname.split('/');
    if (oldPath[2] === newPath[2]) {
      if (oldPath[3] === newPath[3]) {
        return;
      }
    }
    const section = newPath[2];

    if (section !== controlsState.section) {
      this.props.controlsActions.setInactive('reporting');
      this.props.controlsActions.getReportingMetadata(section);
    } else {
      const callFilter = newPath[3] || 'total';
      this.props.controlsActions.setCallFilter('reporting', callFilter);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('refresh', this.storeDidChange);
    this.props.controlsActions.setInactive('reporting');
  }

  render() {
    const { controlsState } = this.props;

    if (controlsState.ready) {
      const callFilterMeta = _.find(controlsState.callFilterOptions, { name: controlsState.callFilter });
      const reports = _.map(controlsState.metadata.reports, (report, index) => (
        <div key={index} className={`col-${report.size}`}>
          <ReportPageChart
            timeout={index * 300}
            charts={report.charts}
            url={report.url}
            units={report.units}
            unitSelects={callFilterMeta.unit_selects}
            title={report.label}
            timeRange={controlsState.timeRange}
            realTime={controlsState.realTime}
            filters={_.assign(callFilterMeta.filters, controlsState.apiFilters)}
            detailUrl={report.detail_url}
            detailPeriodOptions={report.detail_period_options}
            callLogUrl={report.call_log_url}
            extraParams={report.extra_params}
          />
        </div>
      ));
      return (
        <>
          <ReportControls />
          <div className="reports">
            {reports}
          </div>
        </>
      );
    }

    // Metadata didnt load, show a loading spinner or blank
    return (
      <DefaultSpinner />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportPage);
