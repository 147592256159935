import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import AppData from 'app_data';
import DataTable from 'components/data_table';
import Utils from 'utils/common_utils';
import { VideoPopup } from 'components';
import Button from 'components/button';
import vex from 'lib/vex';
import Group from './Group';

import { ReactComponent as Plus } from 'img/plus.svg';


class Groups extends React.Component {

  static propTypes = {
    push: PropTypes.func,
    match: PropTypes.object,
    location: PropTypes.object,
  };

  state = {
    rows: null,
  };

  componentWillMount() {
    this.fetchTeams();
  }

  componentWillReceiveProps(nextProps) {
    const { match } = nextProps;
    const { idGroup } = match.params;

    if (!idGroup) {
      this.fetchTeams();
    }
  }

  fetchTeams = () => {
    AppData.getTeams().then((response) => {
      const { data } = response;
      this.setState({ rows: data });
    });
  };

  handleAction = (obj, action) => {
    if (action.name === 'Edit') {
      this.props.push(`/settings/groups/${obj.id}`);
    } else if (action.name === 'Delete') {
      vex.warning(`Are you sure you want to delete ${obj.label}?`,
        (confirmed) => {
          if (confirmed) {
            AppData.getTeamReports(obj.id).then((response) => {
              if (response.data.reports) {
                const vexMessage = (`Group is referenced in custom reports.
                Confirming Delete will remove the group from reports.
                If a report only contains this group, it will be deleted.`);
                vex.dialog.confirm({
                  message: vexMessage,
                  callback: (value) => {
                    if (value) {
                      AppData.deleteTeam(obj.id).then(() => {
                        window.location.reload();
                      });
                    }
                  },
                });
              } else {
                AppData.deleteTeam(obj.id).then(() => {
                  window.location.reload();
                });
              }
            });
          }
        }
      );
    }
  };

  handleNewTeam = () => {
    this.props.push('/settings/groups/new');
  };


  render() {
    const { match } = this.props;
    const { idGroup } = match.params;
    const isNewGroup = idGroup === 'new';

    if (isNewGroup || idGroup) {
      return (
        <Group idGroup={isNewGroup ? null : idGroup} />
      );
    }

    const headers = [
      { label: 'Group name', key: 'label', labelFunc: (value) => _.truncate(value, { length: 40 }), tooltip: true },
      { label: 'Description', key: 'description', labelFunc: (value) => _.truncate(value, { length: 40 }), tooltip: true },
      { label: 'Type', key: 'team_type', labelFunc: (value) => Utils.capitalizeWords(value.replace('_', ' ')) },
      { label: 'Members', key: 'members', labelFunc: (value) => `${value.length} members` },
    ];
    const actions = [
      { name: 'Edit' },
      { name: 'Delete' },
    ];

    const content = (
      <DataTable
        headers={headers}
        rows={this.state.rows}
        actions={actions}
        onAction={this.handleAction}
      />
    );

    return (
      <div className="teams-container">
        <div className="settings-teams">
          <div className="teams-container__controls">
            <h1>
              Manage groups
              <VideoPopup
                videoUrl="https://nextiva-1.wistia.com/medias/vm6t636nvn"
              />
            </h1>

            <Button
              onClick={this.handleNewTeam}
              className="btn btn-green pull-right new-team"
            >
              New Group <Plus className="new-team-plus" />
            </Button>
          </div>

          <div>
            {content}
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  null,
  { push },
)(Groups);
