import React from 'react';
import PropTypes from 'prop-types';
import QualityCallsTable from './QualityCallsTable'
import { snakeCase, omit } from 'lodash';
import vex from 'lib/vex';
import { OptionsSource, SortOrderMap } from '../../constants';
import { getQualityCallLog, normalizeCustomReport, sendQualityCallLogToEmail } from './data-provider';
import { camelizeKeys } from 'common';
import filters from 'components/filters';

class QualityCallsTableContainer extends React.Component {
  static propTypes = {
    actions: PropTypes.object,
    state: PropTypes.object,
  };
  state = {
    sortData: {},
    pagination: {
      limit: 25,
      offset: 0,
    },
    searchQuery: '',
    isLoading: false,
    selectedSourceType: OptionsSource[0],
  }


  componentDidMount() {
    this.getTable();
  }

  componentDidUpdate(prevProps) {
    const {
      realTime: prevRealTime,
      timeRange: prevTimeRange,
      filters: prevFilters
    } = prevProps;
    const { realTime, timeRange,filters } = this.props;

    if (
      prevRealTime !== realTime ||
      prevTimeRange !== timeRange ||
      prevFilters !==filters
    ) {
      this.getTable();
    }
  }


  getTable = async () => {
    const {
      externalReportData,
      url,
      labels,
      filters,
      selects,
      realTime,
      timeRange,
    } = this.props;

    const {
      sortData,
      pagination,
      searchQuery,
      selectedSourceType,
    } = this.state;

    this.setState({
      isError: false,
      isLoading: true,
    });

    if(externalReportData) {
      this.setState({
        ...normalizeCustomReport(camelizeKeys(externalReportData)),
        isLoading: false,
      });

      return;
    }
  
    let payloadFilters = {};
      filters.forEach(option => {
        if(option.id === "directions") {
          option.label.forEach(obj => {
            payloadFilters[obj.id] = true;
          })
        }else if(option.id ==="answered"){
          option.label.forEach(obj=>{
            if(obj.id === 'yes' && option.label.length === 1){
              payloadFilters['answered'] = true;
            }else if(obj.id === 'no' && option.label.length === 1){
              payloadFilters['missed'] = true;
            }
          })
        }else if(option.id ==="internal"){
          option.label.forEach(obj=>{
            if(obj.id === 'yes' && option.label.length === 1){
              payloadFilters['internal'] = true;
            }
          })
        }else if(option.id === "quality") {
            option.label.forEach(obj => {
              payloadFilters[obj.id] = true;
          });
        }else if(["users", "locations"].includes(option.id)) {
          if(option.label.length > 0) {
            payloadFilters[option.id] = [];
            option.label.forEach(obj => {
              payloadFilters[option.id].push(obj.id);
            })
          }
        }
      });

    const data = await getQualityCallLog({
      ...pagination,
      url,
      labels,
      selects,
      searchQuery,
      timeRange: realTime
        ? omit(timeRange, 'end')
        : timeRange,
      sort: {
        order: SortOrderMap[sortData.order],
        header: snakeCase(sortData.key),
      },
      filters: {
        ...payloadFilters,
        [selectedSourceType.value]: true,
      },
      format: 'quality',
    });

    if (data.isCancel) {
      return;
    }

    if (data.errors) {
      this.setState({
        isError: true,
        isLoading: false,
      });
      return;
    }

    let filteredData = [];
    let payloadFiltersObjectNames = [];
    Object.keys(payloadFilters).forEach(function(key){
      payloadFilters[key] === true &&
      payloadFiltersObjectNames.push(key.charAt(0).toUpperCase() + key.slice(1))
    })
    data.table.rows.map((item)=>{
      if(payloadFiltersObjectNames.includes(item.callScore)){
        filteredData.push(item);
      }
    })

    if(payloadFilters.moderate === true || payloadFilters.good === true || payloadFilters.poor === true ){
      data.table.rows = filteredData;
    }

    this.setState({
      ...data,
      isLoading: false,
    });
  };


  onSortChange = (sortData) => {
    const { pagination } = this.state;
    this.setState({
      sortData,
      pagination: {
        offset: 0,
        limit: pagination.limit,
      },
    }, this.getTable);
  };


  onClickCallRecording = ({ target }) => {
    const { rows } = this.state.table;
    const { index } = target.dataset;

    this.setState({
      callRecordingInfo: rows[index],
    });
  };


  onCloseModal = () => {
    this.setState({
      callRecordingInfo: null,
    });
  };


  onPageChange = ({ selected }) => {
    const { pagination } = this.state;

    this.setState({
      pagination: {
        limit: pagination.limit,
        offset: selected * pagination.limit,
      },
    }, this.getTable);
  };


  onSearchChange = (searchQuery) => {
    const { pagination } = this.state;

    this.setState({
      searchQuery,
      pagination: {
        offset: 0,
        limit: pagination.limit,
      },
    }, this.getTable);
  };


  onLimitChange = (limit) => {
    this.setState({
      pagination: {
        limit,
        offset: 0,
      },
    }, this.getTable);
  };


  onChangeSourceType = (selectedSourceType) => {
    const { pagination } = this.state;
    this.setState({
      pagination: {
        offset: 0,
        limit: pagination.limit,
      },
      selectedSourceType,
    }, this.getTable);
  };


  onClickExport = async (toEmail) => {
    const {
      filters,
      selects,
      timeRange,
    } = this.props;
    const {
      sortData,
      searchQuery,
      selectedSourceType,
    } = this.state;

    const data = await sendQualityCallLogToEmail({
      selects,
      timeRange,
      searchQuery,
      sort: {
        order: SortOrderMap[sortData.order],
        header: snakeCase(sortData.key),
      },
      filters: {
        ...filters,
        [selectedSourceType.value]: true,
      },
      format: 'quality',
      toEmail,
    });

    if (data.errors) {
      vex.error([], null, 'Error sending email');
    } else {
      vex.success('Your email has been sent successfully.');
    }
  };

  render() {
    const { state, actions } = this.props;
    const {
      table,
      sortData,
      isLoading,
      pagination,
      searchQuery,
      callRecordingInfo,
      selectedSourceType,
    } = this.state;
    const {
      realTime,
      filterOptions,
      errorClassName,
      externalReportData,
    } = this.props;

    return (
      <div className="analytics-quality-calls">
        <QualityCallsTable
          table={table}
          sortData={sortData}
          search={searchQuery}
          isLoading={isLoading}
          filterOptions={filterOptions}
          sourceType={selectedSourceType}
          pagination={pagination}
          errorClassName={errorClassName}
          callRecordingInfo={callRecordingInfo}
          onPageChange={this.onPageChange}
          onLimitChange={this.onLimitChange}
          onSearchChange={this.onSearchChange}
          onSortChange={this.onSortChange}
          onClickExport={this.onClickExport}
          onChangeSourceType={this.onChangeSourceType}
          onCloseModal={this.onCloseModal}
          onClickCallRecording={this.onClickCallRecording}
          withoutPagination={externalReportData ? true : false}
          withoutSearch={externalReportData ? true : false}
          withoutAddButton={externalReportData ? true : false}
          withoutPerPage={externalReportData ? true : false}
        />
      </div>
    );
  }
}

export default QualityCallsTableContainer;
