import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { DateRangePicker } from '@nextiva/analytics-ui';
import _ from 'lodash';
import AppData from 'app_data';
import MultiSelect from 'components/multi_select';
import ButtonPopover from 'components/button_popover';
import TabbedControls from 'components/tabbed_controls';
import TabbedQualityControls from 'components/tabbed_quality_controls'
import { Radio, RadioGroup } from 'components/radio_group';
import { bindControlsActions } from 'actions/controls_actions';
import Theme from 'theme';
import { ReactComponent as CallDirectionIcon } from 'img/call_direction_icon.svg';
import { ReactComponent as PhoneIcon } from 'img/phone.svg';
import { ReactComponent as UsersIcon } from 'img/users_icon.svg';
import { ReactComponent as LocationIcon } from 'img/location_icon.svg';
import { ReactComponent as Arrow } from 'img/arrow.svg';
import { ReactComponent as AnsweredIcon } from 'img/answered_icon.svg';
import { ReactComponent as InternalIcon } from 'img/internal_icon.svg';

const mapStateToProps = (_state) => {
  const state = _state.controls['quality'] || {};
  return {
    callParams:
    {
      filters: state.apiFilters,
      time_range: state.timeRange,
      section: state.section,
    },
    callFilter: state.callFilter,
    callFilterOptions: state.callFilterOptions,
    dataFetched: state.dataFetched || false,
    filters: state.filters,
    filterInitialized: state.filterInitialized || false,
    filterType: state.filterType,
    filterOptions: state.filterOptions,
    metadata: state.metadata,
    ready: state.ready,
    realTime: state.realTime,
    section: state.section,
    timeRange: state.timeRange,
    timezone: state.timezone,
    actions: bindControlsActions(state.section),
    selectionButtonToggle: true
  }
};

class QualityControls extends Component {

  static propTypes = {
    /** callParams */
    callParams: PropTypes.object,
    /** Current call filter */
    callFilter: PropTypes.string,
    /** Available call filters */
    callFilterOptions: PropTypes.array,
    /** Data has been fetched at least once */
    dataFetched: PropTypes.bool,
    /** Selected filters */
    filters: PropTypes.array,
    /** True if we have set the filter at least once */
    filterInitialized: PropTypes.bool,
    /** Current filter type */
    filterType: PropTypes.object,
    /** Availble filters */
    filterOptions: PropTypes.array,
    /** Metadata for the reporting section */
    metadata: PropTypes.object,
    /** Are we in real time mode? */
    realTime: PropTypes.bool,
    /** The current section of the current page */
    section: PropTypes.string,
    /** Time range for the section */
    timeRange: PropTypes.object,
    /** User timezone */
    timezone: PropTypes.string,
  }

  fetchData = (callParams) => {
    const hasSummary = ['user-activity', 'location', 'adoption-and-usage'];

    if (callParams.filters && hasSummary.includes(this.props.section)) {
      this.setState({ summaryData: {}, dataFetched: true });

      AppData.getConnectedCallData(callParams)
        .then((response) => {
          this.setState({ summaryData: response.data.totals });
        });
    }
  }

  componentWillMount() {
    this.actions = bindControlsActions('quality');
  }

  componentDidUpdate(prevProps) {
    const callParamsChanged = !_.isEqual(prevProps.callParams, this.props.callParams);
    const hasFilter = ['summary', 'adoption-and-usage'].includes(this.props.section) ? false : true;

    // When section is changed, reset everything to default
    if (!_.isEqual(prevProps.section, this.props.section)) {
      // call sections without filters initialized.
      const filterInitialized = hasFilter ? false : true;
      this.setState({ dataFetched: false, filterInitialized: filterInitialized, summaryData: {} });
      return;
    }

    if (this.state && this.state.filterInitialized) {
      if (!this.state.dataFetched || callParamsChanged) {
        this.fetchData(this.props.callParams);
      }
    }

    if (!this.state || !this.state.filterInitialized) {
      if (prevProps && callParamsChanged && _.isObject(this.props.callParams.filters)) {
        this.setState({ filterInitialized: true });
      }
    }

  }

  makeControlFilter() {
    let controlFilter;
    switch (this.props.section) {
      case 'user-activity':
      case 'location':
      case 'adoption-and-usage':
        controlFilter = this.createControlFilterFromState();
        break;
      default:
        controlFilter = ''
    }
    return controlFilter;
  }

  createControlFilterFromState() {
    if (!this.state || (this.state && (!this.state.dataFetched || !this.state.summaryData))) {
      const empty = [undefined, undefined, undefined];
      return this.createControlFilter(empty, empty);
    }

    /// data for users/locations
    let keys = ['# of Connected Calls', 'Avg. Score', 'Min. Score'];
    let labels = keys;

    if(this.props.section === 'adoption-and-usage') {
        keys = ['user_count', 'avg_calls', 'avg_mins'];
        labels = ['Enabled Phone Users', 'Avg. # of Calls Per User', 'Avg. Min Per User'];
    }

    const values = keys.map(k => _.get(this.state.summaryData, k, ''));

    return this.createControlFilter(labels, values);
  }

  createControlFilter(labels, values) {
    let classes = "connected-call-filters";
    if(this.props.section === 'adoption-and-usage') {
        classes = "adoption-and-usage-filters " + classes;
    }

    return <div className={classes}>
      <li key="1">
        <div>
          {values[0]}
        </div>
        <div className="bold">{labels[0]}</div>
      </li>
      <li key="divider1" className="divider"></li>
      <li key="2">
        <div style={{ color: Theme.getColor('good') }}>
          {values[1]}
        </div>
        <div className="bold">{labels[1]}</div>
      </li>
      <li key="divider2" className="divider"></li>
      <li key="3">
        <div>
          {values[2]}
        </div>
        <div className="bold">{labels[2]}</div>
      </li>
    </div>
  }

  makeButtonPopover() {
    const {
      filterOptions,
      filterType,
      filters,
      section
    } = this.props;
    let buttonPopover;

    if (filterOptions && filterOptions.length) {
      const filtersSelect = (
        <MultiSelect
          searchBar
          options={filterOptions}
          onChange={this.actions.setFilters}
          initialSelected={filters}
        />
      );

    const tabbedControls = () => {
      if(this.props.section !== "calls") {
        return <TabbedQualityControls
          tabs={[{ key:"filtersOption", icon:"icon-custom-filter", label: filterType.id, content: filtersSelect },]}
          applyFilters = {this.actions.setFilters}
          filters={filters}
          filterOptions={filterOptions}
                />
      } else {
        return <CallFilter
        tabs={filterOptions}
        section={section}
        filters={filters} />
        }
    };

      buttonPopover = (
        <ButtonPopover
          popoverClass="controls-popover quality-controls-popover"
          iconClass="icon-custom-settings"
          popoverBody={tabbedControls()}
        />
      );
    }

    return buttonPopover;
  }

  render() {
    const {
      realTime,
      timeRange,
      timezone,

    } = this.props;

    return (
      <div className="report-controls" style={{ display: "flex" }}>
        <div className="label-and-date-picker">
          <h4>Quality of Service</h4>
          <div className="report-controls__left">
            <DateRangePicker
              withRealTimeIcon
              timezone={timezone}
              dateRange={timeRange}
              isRealTime={realTime}
              className="date-range-popover"
              onChange={this.actions.setTimeRange}
              onRealTimeChange={this.actions.setRealTime}
              theme={{
                dateRangePicker: 'date-range',
              }}
            />
            {this.makeButtonPopover()}
          </div>
        </div>
        {this.makeControlFilter()}
      </div>
    );
  }
}

class CallFilter extends Component {
  static propTypes = {
      tabs: PropTypes.array,
      filters: PropTypes.array,
      section: PropTypes.string,
    }
    constructor(props) {
    super(props);
      this.state = {
        selectedId: null,
        filterUpdated: true,
        unSelectedButtonFlag: false,
        unSelectAllSelected:false,
        filters: this.props.filters.map(tab => {return {id:tab.id, label:tab.label}}),
        radioButtonsId: ['directions', 'answered', 'internal']
      };
      this.tabNameMap = {
        'users': 'Users',
        'locations': 'Locations',
        'directions': 'Call Directions',
        'quality': 'Call Quality',
        'answered': 'Answered',
        'internal': 'Internal'
      };
      this.tabIconMap = {
        'users': <UsersIcon />,
        'locations': <LocationIcon />,
        'directions': <CallDirectionIcon className='callIcons' />,
        'quality': <PhoneIcon className='callIcons' style={{width:'16px', height:'24px'}} />,
        'answered': <AnsweredIcon className='answered' />,
        'internal': <InternalIcon className='internal' />
      };
      this.actions = bindControlsActions(this.props.section);
      this.updateFilters = this.updateFilters.bind(this);
      this.applyFilters = this.applyFilters.bind(this);
      this.selectTab = this.selectTab.bind(this);
      this.unselectFilters = this.unselectFilters.bind(this);
      this.selectAllFilters = this.selectAllFilters.bind(this);
      this.buildFilters = this.buildFilters.bind(this);

      setTimeout(() =>{
        this.selectTab('locations', 'selected', 'direct');
      }, 200);

    }
    componentWillUpdate() {
      this.actions = bindControlsActions(this.props.section);
    }
    componentDidUpdate() {
      this.actions = bindControlsActions('quality');
    }
    buildFilters(){
      let filters = {};
      this.props.filters.forEach(tab=>{filters[tab.id]=tab.label});
      return filters;
    }
    unselectFilters() {
      let {filters, selectedId} = this.state;
      filters.forEach(element => {
        if(element.id === selectedId){
          element.label = [];
        }
      });

      let selectToggleBtnStatus = true;
      if(this.state.radioButtonsId.includes(this.state.selectedId)){
        selectToggleBtnStatus = false
      }

      this.setState({
        selectionButtonToggle: selectToggleBtnStatus,
        filters: filters,
        filterUpdated: false,
        unSelectedButtonFlag: this.state.radioButtonsId.includes(selectedId) ? true : false,
        unSelectAllSelected:false,
      });
    }

    selectAllFilters() {
      let {selectedId, filters} = this.state;

      let filterSelectedItems = this.props.tabs.filter(item => {
        if(item.id === selectedId){
          return item.label;
        }
      })

      filters.map(item =>{
        if(item.id === selectedId){
          delete item.label;
          item['label'] = filterSelectedItems[0].label;
        }
      });

      let selectToggleBtnStatus = false;
      if(this.state.radioButtonsId.includes(this.state.selectedId)){
        selectToggleBtnStatus = true
      }

      this.setState({
        selectionButtonToggle: selectToggleBtnStatus,
        filters: filters,
        filterUpdated: false,
        unSelectAllSelected:false,
        selectedId: null
      });

      setTimeout(() =>{
        this.setState({ selectedId: selectedId})
      }, 100);
    }

    updateFilters(options,tabId) {
      if(tabId === 'directions'){
        options = JSON.parse(options);
      }else if(tabId === 'answered'){
        options = JSON.parse(options);
      }else if(tabId === 'internal'){
        options = JSON.parse(options);
      }
      let { filters, selectedId } = this.state;
      let index = filters.findIndex(x=>x.id === selectedId);

      if(index !== -1) {
        filters[index].label = options
        this.setState({
          filters,
          filterUpdated: false,
          unSelectAllSelected:false,
          unSelectedButtonFlag : false
        });
      }
    }

    applyFilters () {
      this.setState({
        filterUpdated: true,
      });
      this.actions.setFilters([...this.state.filters]);
    }
    selectTab(tabId, isExpanded, source)
    {
      const siblingsSelected = document.querySelectorAll('.unselected');
      if(source === 'direct'){
        Array.from(siblingsSelected).filter((el, idx) => {
          if (el.classList.contains('unselected') && isExpanded === 'unselected') {
            el.classList.remove('hideTabs');
          } else if (
            el.classList.contains('unselected') &&
            isExpanded === 'selected'
          ) {
            el.classList.add('hideTabs');
          }
        });
      }else{
        Array.from(siblingsSelected).filter((el, idx) => {
          if (el.classList.contains('unselected') && isExpanded === 'unselected') {
            el.classList.add('hideTabs');
          } else if (
            el.classList.contains('unselected') &&
            isExpanded === 'selected'
          ) {
            el.classList.remove('hideTabs');
          }
        });
      }

      let filterSelectedItems = this.state.filters.filter(item => {
        if(item.id === tabId){
          return item;
        }
      });

      if(filterSelectedItems[0].label && filterSelectedItems[0].label.length !== 0) {
        this.setState({selectionButtonToggle : false})

        this.setState({unSelectedButtonFlag : filterSelectedItems[0].label.length === 2 ? true : false})
      }else{
        this.setState({selectionButtonToggle : this.state.radioButtonsId.includes(tabId) ? false : true})
        this.setState({unSelectedButtonFlag : this.state.radioButtonsId.includes(tabId) ? true : false})
      }

      if(this.state.selectedId !== tabId){
        this.setState({selectedId: tabId})
      } else {
        this.setState({selectedId: null})
      }
    }

    render() {
      const { tabs } = this.props;
      return <div className='popover-body-calls'>
      {tabs.map((tab, index)=> {
          return <>
            <div key={index}
            className={this.state.selectedId===tab.id ? "horizontal-tab selected" : "horizontal-tab unselected"}
            onClick={(e) => {this.selectTab(tab.id, this.state.selectedId === tab.id ? 'selected' : 'unselected')}}>
              <span className='tabIcons'>{this.tabIconMap[tab.id]}</span>
              <span className='horizontalCallsTab'>{this.tabNameMap[tab.id]}</span>
              {this.state.selectedId !== tab.id && tab.id === 'locations' && <span style={{float: "right"}} className="sort-arrow-container"><Arrow className='arrow-sort arrow-up' style={{transform: 'rotate(180deg)'}} /></span>}
              {this.state.selectedId === tab.id && tab.id === 'locations' && <span style={{float: "right"}} className="sort-arrow-container"><Arrow className='arrow-sort arrow-down'/></span>}
            </div>
            {
              this.state.selectedId === tab.id &&
                tab.id !== 'directions' &&
                tab.id !== 'answered' &&
                tab.id !== 'internal' ?
              <MultiSelect
                searchBar
                key={tab.id}
                options={tab.label}
                onChange={e => this.updateFilters(e,tab.id)}
                hideToggleButtons = {true}
                initialSelected ={this.state.unSelectAllSelected ? []: this.state.filters[index].label}
              />
              : (this.state.selectedId === tab.id &&
                tab.id === 'directions') ||
              (this.state.selectedId === tab.id && tab.id === 'answered') ||
              (this.state.selectedId === tab.id && tab.id === 'internal') ?
                <RadioGroup
                  className="questions-form__radio-group"
                  name={`direction_${tab.label}`}
                  onChange={e => this.updateFilters(e,tab.id)}
                  selectedValue={this.state.unSelectAllSelected ? JSON.stringify([]):JSON.stringify(this.state.filters[index].label)}
                  >
                    {tab.label.map(item =>
                      <Radio
                        key={item.id}
                        className="questions-form__radio"
                        value={JSON.stringify([item])}
                      >
                        {item.label}
                      </Radio>)
                    }
                      </RadioGroup>: ''
                    }
                    </>
      })}
      {/* <hr className='divider'></hr> */}
      <div className="filterFooterControls">
        {this.state.selectionButtonToggle !== true ?
          <button className='multi-select-toggle'  disabled={this.state.selectedId !== null && !this.state.unSelectedButtonFlag ? false : true} style={{color: this.state.selectedId !== null && !this.state.unSelectedButtonFlag ? "#005FEC" : "grey"}} onClick={this.unselectFilters}>{this.state.radioButtonsId.includes(this.state.selectedId) ? 'Unselect' : 'Unselect All'}</button>
          :
          <button className='multi-select-toggle'  disabled={this.state.selectedId !== null && !this.state.radioButtonsId.includes(this.state.selectedId) ? false : true} style={{color: this.state.selectedId !== null && !this.state.radioButtonsId.includes(this.state.selectedId) ? "#005FEC" : "grey"}} onClick={this.selectAllFilters}>Select All</button>
        }
        <button className='multi-select-toggle' disabled={this.state.filterUpdated} style={{color: this.state.filterUpdated ? "grey" : "#005FEC"}} onClick={this.applyFilters}>Apply</button>
      </div>
      </div>
  }
}

export default connect(mapStateToProps)(QualityControls);
