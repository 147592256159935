import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { DefaultSpinner } from 'components';

import _ from 'lodash';
import moment from 'moment-timezone';
import vex from 'lib/vex';

import AppData from 'app_data';
import DataTable from 'components/data_table';
import ValidationMixin from 'components/validation';
import InlineSvgFactory from 'components/inline_svg';
import { VideoPopup } from 'components';
import ScheduledReports from 'modules/scheduled_reports/components';

import Styles from './Scheduled.module.scss';

const Plus = InlineSvgFactory('plus.svg');


const headers = [
  {
    key: 'name',
    label: 'Schedule name',
    labelFunc: (value) => _.truncate(value, { length: 25 }),
    tooltip: true,
  },
  {
    key: 'report_name',
    label: 'Report name',
    labelFunc: (value) => _.truncate(value, { length: 25 }),
    tooltip: true,
  },
  { key: 'distribution', label: 'Next Distribution' },
  {
    key: 'email',
    label: 'Email addresses',
    labelFunc: (value) => _.truncate(value, { length: 25 }),
    tooltip: true,
  },
];

const historyHeaders = [
  {
    key: 'title',
    label: 'Link',
    labelFunc: (value) => _.truncate(value, { length: 25 }),
  },
  {
    key: 'sent_at',
    label: 'Sent At',
    tooltip: true,
  },
  {
    key: 'sent_success',
    label: 'Sent?',
    tooltip: true,
  },
  {
    key: 'sent_to',
    label: 'To',
    labelFunc: (value) => _.truncate(value, { length: 25 }),
    tooltip: true,
  },
  {
    key: 'sent_message_id',
    label: 'Message ID',
    tooltip: true,
  },
];


// TODO: add confirmation for form when route is changing
class Scheduled extends Component {
  static propTypes = {
    push: PropTypes.func,
  };

  state = {
    initialized: false,
    loading: false,
    filterText: '',
    rows: null,
    historyRows: [],
    availableReports: [],
    customReportsMap: null,
  };

  componentDidMount() {
    this.getCustomReports();
  }

  getCustomReports = () => {
    AppData.getCustomReports().then((response) => {
      const { data } = response;
      const availableReports = [];
      if (!data.length) {
        // User must create a custom report prior to scheduling
        this.displayCustomReportsDialog();
      }

      const customReportsMap = {};

      data.forEach((report) => {
        customReportsMap[String(report.report_id)] = report.report_name;

        availableReports.push({
          id: report.report_id,
          label: report.report_name,
        });
      });

      this.setState({
        availableReports,
        customReportsMap,
        initialized: true,
      }, this.getScheduledReports);
    });
  };

  getScheduledReports = () => {
    AppData.getScheduledReports().then((response) => {
      const { data } = response;

      const rows = data.map((report) => {
        const next_run_date = moment.utc(report.next_run_date).tz(AppData.user.timezone);
        const now = moment();

        if (now < next_run_date) {
          report.distribution = next_run_date.calendar();
        } else {
          report.distribution = 'N/A';
        }

        report.report_name = this.state.customReportsMap[report.custom_report_id];

        return report;
      });

      this.setState({
        rows,
      });
    });
  };

  setFilterText = ({ target }) => {
    this.setState({
      filterText: target.value,
    });
  };

  checkForChanges = (callback) => {
    const { form } = this.refs;
    if (form && form.state.hasChange) {
      return this.displayUnsavedChangesDialog(callback);
    }
    callback && callback();
  };

  clear = () => {
    this.setState({
      loading: false,
      editing: false,
      history: false,
      reportConfig: null,
    });
  };

  displayCustomReportsDialog = () => {
    vex.dialog.confirm({
      message: 'A custom report must be created prior to scheduling. Create custom report?',
      callback: (value) => {
        if (value) {
          this.props.push('/reporting/custom');
        }
      },
    });
  };

  displayUnsavedChangesDialog = (callback) => {
    vex.dialog.confirm({
      message: 'You currently have unsaved changes. Continue?',
      callback,
    });
  };

  handleCancel = () => {
    this.checkForChanges(this.clear);
  };

  handleDelete = (scheduleObj) => {
    // deletes passed schedule or current state obj
    vex.warning(
      `Are you sure you want to delete ${scheduleObj.name}`,
      (confirmed) => {
        if (confirmed) {
          AppData.deleteScheduledReport(scheduleObj.id).then(() => {
            window.location.reload();
          });
        }
      }
    );
  };

  handleLoadHistory = (scheduleObj) => {
    AppData.getScheduledReportHistory(scheduleObj.id).then((response) => {
      const rows = response.data.logs.map((link) => {
        const updated = moment.utc(link.updated).tz(AppData.user.timezone).format("YYYY-MM-DD HH:mm:ss");
        const sent_success = link.sent_email === true ? 'Yes' : 'No';

        return {
          sent_at: updated,
          sent_to: link.email,
          sent_success: sent_success,
          sent_message_id: link.sg_message_id,
          uri: link.uri,
          /// N.B. data_table special cases report_name and title for View and name for Edit links
          /// so if this key name changes linking to the report will break.
          title: 'View Report',
        };
      });

      this.setState({
        history: true,
        historyRows: rows,
      });
    });
  };

  handleScheduleAction = (scheduleObj, action) => {
    const { rows, reportConfig } = this.state;

    if (action.name === 'Edit') {
      const index = _.findIndex(rows, { id: scheduleObj.id });

      if (reportConfig && rows[index].id === reportConfig.id) {
        return;
      }

      this.checkForChanges(_.partial(this.loadReport, index));
    } else if (action.name === 'Delete') {
      this.handleDelete(scheduleObj);
    } else if (action.name == 'History') {
      this.handleLoadHistory(scheduleObj);
    }
  };

  handleHistoryAction = (obj, _action) => {
    const reportURL = `${window.location.origin}/external-reports.html#${obj.uri}`;
    window.open(reportURL, '_blank');
  }

  loadReport = (dataIndex) => {
    this.setState({
      loading: true,
      editing: false,
      history: false,
    });

    setTimeout(() => {
      this.setState({
        loading: false,
        editing: true,
        reportConfig: _.cloneDeep(this.state.rows[dataIndex]),
      }, this.scrollToReportForm);
    }, 200);
  };

  newReport = () => {
    this.checkForChanges(() => {
      if (this.state.availableReports.length) {
        this.setState({
          loading: true,
          editing: false,
          history: false,
        });

        setTimeout(() => {
          this.setState({
            loading: false,
            editing: true,
            reportConfig: null,
          }, this.scrollToReportForm);
        }, 200);
      } else {
        this.displayCustomReportsDialog();
      }
    });
  };

  scrollToReportForm = () => {
    if (this.reportForm) {
      this.reportForm.scrollIntoView();
    }
  };

  render() {
    const {
      rows,
      editing,
      history,
      loading,
      filterText,
      reportConfig,
      availableReports,
      historyRows,
    } = this.state;

    let reportNames = _.map(rows, 'name');
    if (reportConfig && reportConfig.id) {
      reportNames = _.filter(reportNames, (name) => name !== reportConfig.name);
    }

    return (
      <div className="scheduled">
        <div className="schedule-header">
          <h1 className={Styles.title}>
            Scheduled reports
            <VideoPopup
              videoUrl="https://nextiva-1.wistia.com/medias/x6pue5i9m3"
            />
          </h1>

          <div className="schedule-controls">
            <input
              className="search"
              type="search"
              placeholder="Search"
              onChange={this.setFilterText}
              value={filterText}
            />

            <div
              onClick={this.newReport}
              className="btn btn-green pull-right new-report"
            >
              Scheduled report <Plus className="new-report-plus" />
            </div>
          </div>
        </div>

        <DataTable
          headers={headers}
          rows={rows}
          filterText={filterText}
          onAction={this.handleScheduleAction}
          actions={[{ name: 'Edit' }, { name: 'Delete' }, {name: 'History'}]}
          perPage={10}
        />

        {editing && (
          <ScheduledReports.Report
            reportRef={(node) => { this.reportForm = node; }}
            onCancel={this.handleCancel}
            reportNames={reportNames}
            reportConfig={reportConfig}
            availableReports={availableReports}
            timezone={AppData.user.timezone}
          />
        )}

        {history && (
          <DataTable
            headers={historyHeaders}
            rows={historyRows}
            perPage={100}
            onAction={this.handleHistoryAction}
          />
        )}

        {loading && (
          <DefaultSpinner />
        )}
      </div>
    );
  }
}


export default connect(
  null,
  { push },
)(ValidationMixin(Scheduled));
