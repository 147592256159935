import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

class PageDropdown extends Component {
  static propTypes = {
    /** Whether or not to hide the comparison */
    hideComparison: PropTypes.bool,
    path: PropTypes.string,
    push: PropTypes.func,
  };

  getPathArray = () => this.props.path.split('/');

  handlePageChange = (e) => {
    const path = this.getPathArray();
    this.props.push(`/analytics/${path[2]}/${e.target.value}`);
  };

  render() {
    const path = this.getPathArray();
    const filter = path[2];
    const section = path[3];

    return (
      <select onChange={this.handlePageChange} value={section} aria-label="Analytics section">
        <option value="trend">Trend Analysis</option>

        {filter !== 'summary' && !this.props.hideComparison && (
          <option value="comparison">Comparison</option>
        )}

        <option value="period">Period over period</option>
        <option value="call-duration">Call Duration</option>
      </select>
    );
  }
}

export default connect(({ router }) => ({
  path: router.location.pathname,
}), {
  push,
})(PageDropdown);
