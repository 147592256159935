import React, { Component } from 'react';
import PropTypes from 'prop-types';
import GridLayout, { WidthProvider } from 'react-grid-layout';
import _ from 'lodash';
import cx from 'classnames';
import vex from 'lib/vex';
import { connect } from 'react-redux';
import { DefaultSpinner } from 'components';

import AppData from 'app_data';
import CustomReportTile, { CustomReport } from 'modules/monitoring/custom_dashboard/custom_report_tile';
import CallTypeTile, { CallType } from 'modules/monitoring/custom_dashboard/call_type_tile';
import GamificationTile, { Gamification } from 'modules/monitoring/custom_dashboard/gamification_tile';
import { mapStateToProps, mapDispatchToProps } from 'modules/monitoring/custom_dashboard/connector';
import { CustomDashboardControls, CustomDashboardTile } from 'components/custom_dashboard';
import ButtonPopover from 'components/button_popover';
import Utils from 'utils/common_utils';

const ReactGridLayout = WidthProvider(GridLayout);

class CustomDashboard extends Component {
  static propTypes = {
    className: PropTypes.string,
    cols: PropTypes.number,
    rowHeight: PropTypes.number,
    useCSSTransforms: PropTypes.bool,
    customDashboardActions: PropTypes.object.isRequired,
    custom_dashboard: PropTypes.object.isRequired,
    match: PropTypes.object,
    push: PropTypes.func,
    viewing: PropTypes.bool,
    isShowIconPopout: PropTypes.bool,
    isShowEditButton: PropTypes.bool,
    location: PropTypes.object,
  };

  static defaultProps = {
    className: 'dashboard_layout',
    cols: 12,
    rowHeight: 100,
    useCSSTransforms: true,
    verticalCompact: true,
    isShowIconPopout: false,
    isShowEditButton: true,
  };

  state = {};

  componentDidMount() {
    const { location, customDashboardActions } = this.props;
    const notAllowed = ['call_log', 'period'];

    AppData.getCustomReports().then((response) => {
      const { data } = response;
      const reports = data.filter((report) => !notAllowed.includes(report.format));
      this.setState({ reports });
      this.loadDashboard();

      const params = new URLSearchParams(location.search);
      customDashboardActions.setRealTime(params.get('realTime') === 'true');
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    const propsChanged = !_.isEqual(nextProps, this.props);
    const stateChanged = !_.isEqual(nextState, this.state);
    return propsChanged || stateChanged;
  }

  componentWillUnmount() {
    this.props.customDashboardActions.clear();
  }

  // We're using the cols coming back from this to calculate where to add new items.
  onBreakpointChange = (breakpoint, cols) => {
    this.props.customDashboardActions.breakpointChange(breakpoint, cols);
  };

  onLayoutChange = (layout) => {
    const { custom_dashboard: uiState } = this.props;
    if (!_.isEqual(uiState.layout, layout)) {
      this.props.customDashboardActions.layoutChange(layout);
      if (this.props.viewing && !uiState.isEditingTile) {
        this.handleSave();
      }
    }
  };

  onRemoveItem = (elementIndex) => {
    if (this.props.viewing) {
      if (this.props.custom_dashboard.items.length > 1) {
        this.props.customDashboardActions.removeTile(elementIndex);
        this.handleSave();
        return;
      }
      vex.error(['One tile must be left in a dashboard.']);
    } else {
      this.props.customDashboardActions.removeTile(elementIndex);
    }
  };

  onUpdateItem = (state, elementIndex) => {
    this.props.customDashboardActions.updateTile(state, elementIndex);
    if (this.props.viewing) {
      this.handleSave();
    }
  };

  onEditItem = (elementIndex) => {
    if (this.props.custom_dashboard.isEditingTile) {
      return;
    }
    this.props.customDashboardActions.editTile(elementIndex);
  };

  onCancel = (elementIndex) => {
    this.props.customDashboardActions.cancel(elementIndex);
  };

  onCancelNew = (elementIndex) => {
    this.props.customDashboardActions.cancelNew(elementIndex);
  };

  onMouseDown = (e) => {
    if (!this.state.isEditing) {
      e.stopPropagation();
    }
  };

  getPopoverContent = (el, elementIndex) => {
    let content;
    let options;
    const self = this;
    const tileType = el.tileType;
    const tileInfo = el.tileInfo || {};
    const removeHandler = this.onRemoveItem.bind(this, elementIndex);
    const updateHandler = function (state) { self.onUpdateItem(state, elementIndex); };
    const cancelHandler = el.isNew ?
      this.onCancelNew.bind(this, elementIndex) :
      this.onCancel.bind(this, elementIndex);

    switch (tileType) {
      case 'reports':
        options = this.state.reports.map((report) =>
          <option key={report.report_id} value={report.report_id}>{report.report_name}</option>
        );
        content = (
          <CustomReportTile
            options={this.state.reports}
            onRemoveItem={removeHandler}
            onUpdateItem={updateHandler}
            tileInfo={tileInfo}
            onCancel={cancelHandler}
          />
        );
        break;
      case 'call_type':
        content = (
          <CallTypeTile
            onRemoveItem={removeHandler}
            onUpdateItem={updateHandler}
            tileInfo={tileInfo}
            onCancel={cancelHandler}
          />
        );
        break;
      case 'gamification':
        content = (
          <GamificationTile
            onRemoveItem={removeHandler}
            onUpdateItem={updateHandler}
            tileInfo={tileInfo}
            onCancel={cancelHandler}
          />
        );
        break;
      default:
        content = (
          <div>
            <div className="custom_dashboard_popover_title">Add element</div>
            <div className="custom_dashboard_popover_content">
              <p>Reports</p>
              <input type="button" className="btn" onClick={this.setTile.bind(this, 'reports', elementIndex)} value="Saved Reports" />
              <p>Monitoring</p>
              <input type="button" className="btn" onClick={this.setTile.bind(this, 'call_type', elementIndex)} value="Call Type" />
              <input type="button" className="btn" onClick={this.setTile.bind(this, 'gamification', elementIndex)} value="Gamification" />
            </div>
          </div>
        );
    }
    return content;
  };

  getTileContent = (el, index) => {
    let content;
    const { custom_dashboard: uiState } = this.props;
    const tileType = el.isNew ? 'new' : el.tileType;
    const editHandler = this.onEditItem.bind(this, index);
    const updateHandler = _.bind(this.onUpdateItem, this, _, index);

    const popoverContent = this.getPopoverContent(el, index);

    let outerAction = () => {};

    if (!el.isNew) {
      outerAction = this.onCancel.bind(this, index);
    }

    const editButton = (
      <ButtonPopover
        popoverClass="custom_dashboard_popover"
        iconClass="icon-custom-gear"
        buttonClass="btn-transparent custom-dashboard__tile__action edit"
        popoverBody={popoverContent}
        isOpen={el.isEditing}
        onOuterAction={outerAction}
        onClick={editHandler}
      />
    );

    switch (tileType) {
      case 'reports':
        content = (
          <CustomReport
            tileInfo={el.tileInfo}
            timeRange={uiState.timeRange}
            realTime={uiState.realTime}
            onUpdateItem={updateHandler}
            editButton={editButton}
          />
        );
        break;
      case 'call_type':
        content = (
          <CallType
            tileInfo={el.tileInfo}
            timeRange={uiState.timeRange}
            realTime={uiState.realTime}
            editButton={editButton}
          />
        );
        break;
      case 'gamification':
        content = (
          <Gamification
            tileInfo={el.tileInfo}
            timeRange={uiState.timeRange}
            realTime={uiState.realTime}
            editButton={editButton}
          />
        );
        break;
      default:
        content = (
          <div className="custom_dashboard_tile_content">
            <div className="custom_dashboard_actions">
              {editButton}
            </div>
            <span className="grid-item-text">{el.content}</span>
          </div>
        );
        break;
    }

    return content;
  };

  onOpenPopout = () => {
    const {
      match,
      custom_dashboard: uiState,
    } = this.props;
    const { dashboardId } = match.params;

    const params = uiState.realTime ? '?realTime=true' : '';
    Utils.openRoute(`/popout/custom-dashboard/${dashboardId}${params}`);
  };

  setTile = (value, elementIndex) => {
    this.props.customDashboardActions.setTile(value, elementIndex);
  };

  loadDashboard = () => {
    const { dashboardId } = this.props.match.params;
    const { viewing } = this.props;

    if (dashboardId) {
      this.props.customDashboardActions.loadDashboard(dashboardId, viewing);
    } else {
      this.props.customDashboardActions.startNewDashboard();
    }
  };

  handleTitleChange = (e) => {
    this.props.customDashboardActions.setTitle(e.target.value);
  };

  handleDescriptionChange = (e) => {
    this.props.customDashboardActions.setDescription(e.target.value);
  };

  handleTimeRangeChange = (val) => {
    this.props.customDashboardActions.setTimeRange(val);
  };

  handleRealTimeChange = (val) => {
    this.props.customDashboardActions.setRealTime(val);
  };

  toggleEditDashboard = () => {
    this.props.customDashboardActions.toggleEditDashboard();
  };

  validate = (cb, options = {}) => {
    const errors = this.props.customDashboardActions.validate(options);

    if (!errors) {
      cb();
    } else {
      vex.error(errors);
    }
  };

  handleSave = () => {
    this.validate(() => {
      const { dashboardId } = this.props.match.params;
      const {
        push,
        viewing,
        custom_dashboard,
        customDashboardActions,
      } = this.props;

      customDashboardActions.saveDashboard(dashboardId)
        .then(() => {
          if (!viewing) {
            push('/saved/dashboards');
          } else if (viewing && custom_dashboard.isEditing) {
            this.toggleEditDashboard();
          }
        })
        .catch((err) => {
          vex.dialog.open({
            message: `<div class="vex-modal-message">${err.error_description}</div>`,
            contentClassName: 'error',
            buttons: [],
          });
        });
    });
  };

  createElement = (el, index) => {
    const className = cx({
      'custom_dashboard_tile': true,
      'new': el.isNew,
    });

    const innerElement = this.getTileContent(el, index);

    const element = (
      <CustomDashboardTile
        key={el.i}
        className={className}
        onMouseDown={this.onMouseDown}
      >
        {innerElement}
      </CustomDashboardTile>
    );

    return element;
  };

  handleAddItem = () => {
    const { custom_dashboard: uiState } = this.props;
    const { isEditingTile } = uiState;
    if (isEditingTile) {
      return;
    }
    this.props.customDashboardActions.addTile(this.props);
  };

  render() {
    const {
      viewing,
      isShowIconPopout,
      isShowEditButton,
      custom_dashboard: uiState,
    } = this.props;
    const elements = _.map(uiState.items.slice(0), this.createElement);
    const viewOnly = !uiState.isEditing && viewing;

    if (!uiState.isLoading) {
      return (
        <>
          <CustomDashboardControls
            timeRange={uiState.timeRange}
            realTime={uiState.realTime}
            timezone={AppData.user.timezone}
            onTimeRangeChange={this.handleTimeRangeChange}
            onRealTimeChange={this.handleRealTimeChange}
            onTitleChange={this.handleTitleChange}
            onDescriptionChange={this.handleDescriptionChange}
            title={uiState.title}
            description={uiState.description}
            onAddItem={this.handleAddItem}
            onSave={this.handleSave}
            errors={uiState.errors}
            viewOnly={viewOnly}
            isShowEditButton={isShowEditButton}
            isShowIconPopout={isShowIconPopout}
            onOpenPopout={this.onOpenPopout}
            editDashboard={this.toggleEditDashboard}
          />
          <ReactGridLayout
            {...this.props}
            layout={uiState.layout}
            draggableCancel=".Popover"
            onLayoutChange={this.onLayoutChange}
          >
            {elements}
          </ReactGridLayout>
        </>
      );
    }
    return <DefaultSpinner />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomDashboard);
