import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { DateRangePicker } from '@nextiva/analytics-ui';

import { bindControlsActions } from 'actions/controls_actions';
import MultiSelect from 'components/multi_select';
import BasicChartControls from 'components/charts/basic_chart_controls';
import ButtonPopover from 'components/button_popover';
import TabbedControls from 'components/tabbed_controls';
import Button from 'components/button';

const mapStateToProps = (state) => {
  const currentSection = state.controls.currentSection;
  const controlsState = state.controls[currentSection];
  return {
    filters: controlsState.filters,
    filterOptions: controlsState.filterOptions,
    realTime: controlsState.realTime,
    timeRange: controlsState.timeRange,
    timezone: controlsState.timezone,
  };
};

class BasicPopoutHeader extends Component {
  static propTypes = {
    /** The title of the chart in the header */
    title: PropTypes.string,
    /** The kind of unit that the chart is using */
    unit: PropTypes.string,
    /** The potential units that the chart could use */
    units: PropTypes.array,
    /** The charts that could potentially be displayed */
    charts: PropTypes.array,
    /** A handler for a change in the unit */
    onUnitChange: PropTypes.func,
    /** A handler for an edit to the chart */
    onChartChange: PropTypes.func,
    /** Whether or not the chart is drilling-down */
    isDrillingDown: PropTypes.bool,
    /** Whether or not the chart is in the second drill-down level */
    isSecondLevel: PropTypes.bool,
    /** The date that a chart is drilling down on */
    drillDownDate: PropTypes.object,
    /** Current controls section */
    controlsSection: PropTypes.string,
    /** A function to return a drill-down chart to its original state */
    backFromDrillDown: PropTypes.func,
    /** A function to return a drill-down chart from the third to the second level */
    backFromSecondDrillDown: PropTypes.func,
    /** Selected filters */
    filters: PropTypes.array,
    /** Availble filters */
    filterOptions: PropTypes.array,
    /** Are we in real time mode? */
    realTime: PropTypes.bool,
    /** Time range for the section */
    timeRange: PropTypes.object,
    /** User timezone */
    timezone: PropTypes.string,
  };

  componentWillMount() {
    this.actions = bindControlsActions(this.props.controlsSection);
  }

  render() {
    const {
      filters,
      filterOptions,
      realTime,
      timezone,
    } = this.props;
    let { timeRange } = this.props;
    let isStatic = false;
    let backButton;
    let backButton2;
    let buttonPopover;
    let basicChartControls = (
      <BasicChartControls {...this.props} disablePopoutButton />
    );

    if (this.props.isDrillingDown) {
      isStatic = true;
      timeRange = this.props.drillDownDate;
      basicChartControls = null;
      backButton = (
        <Button className="btn" onClick={this.props.backFromDrillDown}>Back to Initial</Button>
      );
      if (this.props.isSecondLevel) {
        backButton2 = (
          <Button className="btn" onClick={this.props.backFromSecondDrillDown}>Back to Day</Button>
        );
      }
    }

    if (filterOptions.length && !this.props.isDrillingDown) {
      const filtersSelect = (
        <MultiSelect
          searchBar
          options={filterOptions}
          onChange={this.actions.setFilters}
          initialSelected={filters}
        />
      );
      const tabbedControls = (
        <TabbedControls
          tabs={[
            { icon: 'icon-custom-filter', label: 'Filters', content: filtersSelect },
          ]}
        />
      );
      buttonPopover = (
        <ButtonPopover
          popoverClass="controls-popover popout-controls-popover"
          iconClass="icon-custom-settings"
          popoverBody={tabbedControls}
        />
      );
    }

    return (
      <div className="chart-header">
        <div className="left-side">
          {this.props.title}
          <DateRangePicker
            withRealTimeIcon
            isRealTime={realTime}
            timezone={timezone}
            dateRange={timeRange}
            className="date-range-popover"
            onChange={this.actions.setTimeRange}
            onRealTimeChange={this.actions.setRealTime}
            theme={{
              dateRangePicker: 'date-range',
            }}
            disabled={isStatic}
          />
          <span>
            {backButton}
          </span>
          {backButton2}
          {buttonPopover}
        </div>

        {basicChartControls}
      </div>
    );
  }
}

export default connect(mapStateToProps)(BasicPopoutHeader);
