import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';


import { DateRangePeriod } from 'components';
import { bindControlsActions } from 'actions/controls_actions';
import MultiSelect from 'components/multi_select';
import BasicChartControls from 'components/charts/basic_chart_controls';
import ButtonPopover from 'components/button_popover';
import TabbedControls from 'components/tabbed_controls';

import Styles from './PeriodPopoutHeader.module.scss';

const mapStateToProps = (state) => {
  const currentSection = state.controls.currentSection;
  const controlsState = state.controls[currentSection];
  return {
    filters: controlsState.filters,
    filterOptions: controlsState.filterOptions,
    realTime: controlsState.realTime,
    timeRanges: controlsState.timeRanges,
    timezone: controlsState.timezone,
  };
};

class PeriodPopoutHeader extends Component {
  static propTypes = {
    /** The title of the chart in the header */
    title: PropTypes.string,
    /** The kind of unit that the chart is using */
    unit: PropTypes.string,
    /** The potential units that the chart could use */
    units: PropTypes.array,
    /** The charts that could potentially be displayed on the popout. */
    charts: PropTypes.array,
    /** A handler for a change in the unit */
    onUnitChange: PropTypes.func,
    /** A handler for an edit to the chart */
    onChartChange: PropTypes.func,
    /** Which controls section */
    controlsSection: PropTypes.string,
    /** Selected filters */
    filters: PropTypes.array,
    /** Availble filters */
    filterOptions: PropTypes.array,
    /** Are we in real time mode? */
    realTime: PropTypes.bool,
    /** Time range for the section */
    timeRanges: PropTypes.array,
    /** User timezone */
    timezone: PropTypes.string,
  };

  componentWillMount() {
    this.actions = bindControlsActions(this.props.controlsSection);
  }

  render() {
    const {
      filters,
      filterOptions,
      timeRanges,
      timezone,
    } = this.props;
    let buttonPopover;

    if (filterOptions.length) {
      const filtersSelect = (
        <MultiSelect
          searchBar
          options={filterOptions}
          onChange={this.actions.setFilters}
          initialSelected={filters}
        />
      );
      const tabbedControls = (
        <TabbedControls
          tabs={[
            { icon: 'icon-custom-filter', label: 'Filters', content: filtersSelect },
          ]}
        />
      );
      buttonPopover = (
        <ButtonPopover
          popoverClass="controls-popover popout-controls-popover"
          iconClass="icon-custom-settings"
          popoverBody={tabbedControls}
        />
      );
    }

    return (
      <div className="chart-header">
        <span>
          {this.props.title}
        </span>

        <DateRangePeriod
          timezone={timezone}
          timeRanges={timeRanges}
          onChange={this.actions.setTimeRanges}
          theme={{
            date: Styles.date,
          }}
        />

        {buttonPopover}
        <BasicChartControls {...this.props} disablePopoutButton />
      </div>
    );
  }
}

export default connect(mapStateToProps)(PeriodPopoutHeader);
