import React from 'react';
import PropTypes from 'prop-types';
import { Control, Field, Errors, actions } from 'react-redux-form';
import cx from 'classnames';
import _ from 'lodash';

import Button from 'components/button';
import { Collapse } from 'components';
import RichTextEditor from 'components/editor';
import ScaleOptions from './scale_options';
import YesNoOptions from './yes_no_options';
import RatingOptions from './rating_options';
import ShortAnswerOptions from './short_answer_options';
import {
  getInitialQuestion,
  showErrorAfterTouched,
  clearOptionsOnTypeChange,
  getPreviewComponentForEditing,
} from '../utils';

const required = (val) => val && val.length;

const renderQuestionOptions = (type, model) => {
  let Component;
  switch (type) {
    case 'scale':
      Component = ScaleOptions;
      break;
    case 'yes-no':
      Component = YesNoOptions;
      break;
    case 'rating':
      Component = RatingOptions;
      break;
    case 'short-answer':
      Component = ShortAnswerOptions;
      break;
    default:
      return <div>{type}</div>;
  }
  return <Component model={model} />;
};

const QuestionsGroup = ({ questions, dispatch }) => (
  <ul className="questions-form__questions">
    {questions.map((question, index) => {
      const trigger = (
        <li
          className={cx('questions-form__question', {
            'questions-form__question--first': index === 0,
          })}
        >
          <Field
            model={`.questions[${index}].question_type`}
            className="questions-form__question-type"
            changeAction={clearOptionsOnTypeChange}
          >
            <select>
              <option value="scale">Scale Question</option>
              <option value="yes-no">Yes/No Question</option>
              <option value="rating">Rating Question</option>
            </select>
          </Field>
          <div className="questions-form__question-number">
            <span>{`Q ${_.padStart(index + 1, 2, '0')}.`}</span>
          </div>
          <div className="questions-form__input-container questions-form__question-text">
            <Control.text
              className="questions-form__input"
              model={`.questions[${index}].title`}
              placeholder="Question title"
              type="text"
              validateOn={['change', 'blur']}
              updateOn="blur"
              validators={{ required }}
            />
            <Errors
              className="questions-form__error"
              model={`.questions[${index}].title`}
              messages={{
                required: 'A question title is required',
              }}
              show={showErrorAfterTouched}
            />
          </div>
          <div className="questions-form__buttons questions-form__buttons--question">
            <div className="questions-form__delete">
              <Button
                type="button"
                className="btn-transparent"
                onClick={() => dispatch(actions.remove('questions_group.questions', index))}
              >
                <span className="icon-custom-trash-can" />
              </Button>
            </div>
          </div>
        </li>
      );
      const questionComponent = getPreviewComponentForEditing(question);
      return (
        <Collapse
          key={index}
          initialCollapsed={question.text.length !== 0}
          header={trigger}
          arrowClass={cx('questions-form__collapse-arrow', {
            'questions-form__collapse-arrow--first': index === 0,
          })}
        >
          <Control
            model={`.questions[${index}].text`}
            component={RichTextEditor}
            updateOn="blur"
            validators={{ required }}
            controlProps={{
              placeholder: 'Enter question text...',
            }}
          />
          <Errors
            className="questions-form__error"
            model={`.questions[${index}].text`}
            messages={{
              required: 'Must have question text',
            }}
            show={showErrorAfterTouched}
          />
          {renderQuestionOptions(question.question_type, `.questions[${index}].options`)}
          <div>
            <span className="questions-form__label">Preview</span>
            {questionComponent}
          </div>
        </Collapse>
      );
    })}
    <li className="questions-form__add">
      <Button className="btn-transparent" type="button" onClick={() => dispatch(actions.push('questions_group.questions', getInitialQuestion()))}>+ Add Question</Button>
    </li>
  </ul>
);

QuestionsGroup.propTypes = {
  questions: PropTypes.array,
  dispatch: PropTypes.func,
};

export default QuestionsGroup;
