import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { Scrollbars } from 'react-custom-scrollbars';

import CallRecording from 'components/call_recording/call_recording';


const CallRecordingModal = (props) => (
  <Modal
    className="call-recording-modal"
    overlayClassName="call-recording-modal-overlay"
    isOpen={props.isOpen}
    onRequestClose={props.handleCloseModal}
    contentLabel="Call recording modal"
    ariaHideApp={false}
  >
    <Scrollbars
      autoHide
      autoHeight
      autoHeightMax="80vh"
    >
      {props.callRecordingInfo ?
        <CallRecording
          onClose={props.handleCloseModal}
          callRecordingInfo={props.callRecordingInfo}
        />
      : null}
    </Scrollbars>
  </Modal>
);

CallRecordingModal.propTypes = {
  /** The initial index of the simple dropdown */
  isOpen: PropTypes.bool,
  onRequestClose: PropTypes.func,
  handleCloseModal: PropTypes.func,
  callRecordingInfo: PropTypes.object,
};

export default CallRecordingModal;
