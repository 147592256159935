import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { Control, actions } from 'react-redux-form';
import cx from 'classnames';
import _ from 'lodash';
import moment from 'moment';
import onClickOutside from 'react-onclickoutside';
import { connect } from 'react-redux';

import DatePicker from 'react-datepicker';
import AppData from 'app_data';
import HoursMinutesInput from './hours_minutes_input';
import { NAME, INITIAL_STATE } from '../constants';

const MINIMUM_DATE = new Date('10/11/2015');

class DateTime extends React.Component {
  static propTypes = {
    /** Minimum Date object */
    minDate: PropTypes.object,
    /** Maximum Date object */
    maxDate: PropTypes.object,
    /** Array of the date time options */
    options: PropTypes.array,
    /** The date, moment object */
    value: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.string,
    ]),
    /** Whether or not to disable a custom date option */
    disableCustomDate: PropTypes.bool,
    /** The label of the date time selector */
    label: PropTypes.string,
    /** Whether or not the date is absolute */
    absoluteDate: PropTypes.bool,
    /** Form model */
    model: PropTypes.string,
    /** Dispatch to store */
    dispatch: PropTypes.func,
  };

  static defaultProps = {
    value: moment(),
    options: [],
    minDate: MINIMUM_DATE,
  };

  state = {
    open: false,
  };

  handleClickOutside = () => {
    this.setState({ open: false });
  };

  handleDateChange = (model, value) => {
    if (this.props.value === 'forever') {
      // If previous value was 'forever' and then set date
      // react-redux-form doenst init state
      // for new controls before executing validator
      this.initHourAndMinuteState();
    }
    this.setState({ open: false });
    return dispatch => {
      const date = value.format('YYYY-MM-DD');
      dispatch(actions.change(model, date));
    };
  };

  initHourAndMinuteState = () => {
    const { model, dispatch } = this.props;
    const { hours, minutes, meridiem } = INITIAL_STATE.run;
    dispatch(actions.load(`${NAME}${model}.hours`, hours));
    dispatch(actions.load(`${NAME}${model}.minutes`, minutes));
    dispatch(actions.load(`${NAME}${model}.meridiem`, meridiem));
  };

  handleSelectOption = (index) => {
    const { model, dispatch } = this.props;
    this.setState({
      open: false,
    });
    const key = this.props.options[index].key;
    dispatch(actions.change(`${NAME}${model}.value`, key));
  };

  openDateModal = () => {
    const el = this.datePicker;
    this.setState({ open: false });
    el.setOpen(true);
  };

  toggleModal = () => {
    this.setState({ open: !this.state.open });
  };

  render() {
    const { model } = this.props;
    const options = [];
    let dateLabel;

    if (!this.props.disableCustomDate) {
      options.push(
        <div
          className="option border"
          key={'customDate'}
          onClick={this.openDateModal}
        >
          Custom date
        </div>
      );
    }

    _.each(this.props.options, (obj, index) => {
      options.push(
        <div
          className="option"
          key={index}
          onClick={this.handleSelectOption.bind(this, index)}
        >
          {obj.label}
        </div>
      );
    });

    // calculate date label, if not in options it is a date
    const option = _.find(this.props.options, { key: this.props.value });

    if (option !== undefined) {
      dateLabel = option.label;
    } else {
      dateLabel = moment(this.props.value).format('MM-DD-YYYY');
    }

    const buttonClick = options.length <= 1 ? this.openDateModal : this.toggleModal;

    return (
      <div className="datetime">
        <div>
          {this.props.label ?
            <div className="label">
              {this.props.label}
            </div>
            : null
          }
          <div className="btn date-button" onClick={buttonClick}>
            {dateLabel}
            <span className="css-arrow-down" />
          </div>
          {this.state.open ?
            <div className="datetime-dropdown">
              <div className="options">
                {options}
              </div>
            </div>
          : null}
          <div className="datepicker-input">
            <Control
              model={`${model}.value`}
              component={DatePicker}
              getRef={(node) => { this.datePicker = node; }}
              changeAction={this.handleDateChange}
              controlProps={{
                dateFormat: 'MM/DD/YY',
                minDate: moment(this.props.minDate, 'MM/DD/YYYY').tz(AppData.user.timezone),
                selected: this.props.absoluteDate,
              }}
            />
          </div>
          {dateLabel !== 'Forever' ?
            <span>
              <span className="at">at</span>
              <HoursMinutesInput
                hourModel={`${model}.hours`}
                minuteModel={`${model}.minutes`}
                meridiemModel={`${model}.meridiem`}
              />
            </span>
          : null }
        </div>
      </div>
    );
  }
}

export default connect(null)(onClickOutside(DateTime));
