import _ from 'lodash';
import Constants from 'constants.js';
import AppData from 'app_data';
import appDefaults from 'reducers/app_defaults';
import { store } from 'App';
import { batchActions } from 'redux-batched-actions';

const urlMap = {
  'summary': null,
  'location': 'locations',
  'call-group': 'call_groups',
  'user-activity': 'users',
  'phone-number': 'phone_numbers',
  'teams': 'teams',
  'calls':'calls',
  'custom-group': 'teams',
};

const ControlsActions = {
  setTimeRange(section, value) {
    return { type: Constants.CONTROLS_SET_TIME_RANGE, field: 'timeRange', section, value };
  },

  setTimeRanges(section, value) {
    return { type: Constants.CONTROLS_SET_TIME_RANGES, field: 'timeRanges', section, value };
  },

  setCallFilter(section, value) {
    return { type: Constants.CONTROLS_SET_CALL_FILTER, field: 'callFilter', section, value };
  },

  setRealTime(section, value) {
    return { type: Constants.CONTROLS_SET_REAL_TIME, field: 'realTime', section, value };
  },

  setBoardType(section, value) {
    return { type: Constants.CONTROLS_SET_BOARD_TYPE, field: 'boardType', section, value };
  },

  setBoardTheme(section, value) {
    return { type: Constants.CONTROLS_SET_BOARD_THEME, field: 'boardTheme', section, value };
  },

  setUserCount(section, value) {
    return { type: Constants.CONTROLS_SET_USER_COUNT, field: 'userCount', section, value };
  },

  setTotalUsers(section, event) {
    return { type: Constants.CONTROLS_SET_TOTAL_USERS, field: 'totalUsers', section, value: parseFloat(event.target.value) };
  },

  setPeriod(section, value) {
    return { type: Constants.CONTROLS_SET_PERIOD, field: 'period', section, value };
  },

  setCallType(section, value) {
    return { type: Constants.CONTROLS_SET_CALL_TYPE, field: 'callType', section, value };
  },

  setCallTypes(section, value) {
    return { type: Constants.CONTROLS_SET_CALL_TYPES, field: 'callTypes', section, value };
  },

  setMinuteBuckets(section, value) {
    return { type: Constants.CONTROLS_SET_MINUTE_BUCKETS, field: 'minuteBuckets', section, value };
  },

  setInactive(section) {
    return { type: Constants.CONTROLS_SET_INACTIVE, field: 'ready', section, value: false };
  },

  loadState(section, state) {
    return { type: Constants.CONTROLS_LOAD_STATE, section, state };
  },

  setGrouping(section, value) {
    return { type: Constants.CONTROLS_SET_GROUPING, field: 'grouping', section, value };
  },

  setFilters(section, filters) {
    return {
      type: Constants.CONTROLS_SET_FILTERS,
      section,
      filters,
    };
  },

  setFilterType(section, filterType, filterOptions) {
    return {
      type: Constants.CONTROLS_SET_FILTER_TYPE,
      section,
      filterType,
      filterOptions,
    };
  },

  changeFilterType(section, filterType, readyState, comparison) {
    return (dispatch) =>
      AppData.getNextOsType(filterType.id)
        .then((response) => {
          let data = response.data;

          if (filterType.id === 'teams' && filterType.teamType) {
            data = _.filter(data, (item) => filterType.teamType === item.team_type);
          }

          // cast all ids to str
          let filterOptions = _.map(data, (item) => ({
            id: String(item.id), label: item.label,
          }));


          const actions = [ControlsActions.setFilterType(section, filterType, filterOptions)];
          if (readyState) {
            actions.push(ControlsActions.loadState(section, { ready: true }));
          }

          if (comparison) {
            actions.push(ControlsActions.setFilters(section, filterOptions.slice(0, 2)));
          }

          dispatch(batchActions(actions));
        })
        .catch((failure) => {
          console.warn(failure); // eslint-disable-line
        });
  },

  getMonitoringMetadata(section) {
    return (dispatch, getState) =>
      AppData.getMonitoringSectionMetadata(section)
        .then((response) => {
          const metadata = response.data;
          const currentState = getState().controls[section] || appDefaults.controls[section];
          const newState = {};

          newState.metadata = metadata;
          newState.timeRange = metadata.time_range;
          newState.grouping = false;
          newState.timezone = AppData.user.timezone;

          if (metadata.header && metadata.header.length) {
            let headers = metadata.header;
            if (section === 'wallboards') {
              headers = headers.slice(0, 8);
            } else if (section === 'dashboard') {
              headers = headers.slice(0, 5);
            }

            let header_callgroup = metadata.header_callgroup;
            if (section === 'wallboards') {
              header_callgroup = header_callgroup.slice(0, 8);
            } else if (section === 'dashboard') {
              header_callgroup = header_callgroup.slice(0, 5);
            }
            // This is used just to hold the original options - when swapping filter type to call_group
            newState.originalCallTypeOptions = _.clone(metadata.header);

            newState.callGroupCallTypeOptions = metadata.header_callgroup;
            newState.callTypeOptions = currentState.filterType === 'call_groups' ? metadata.header_callgroups : metadata.header;

            if (!currentState.callTypes.length) {
              newState.callType = newState.callTypeOptions[0];
              newState.callTypes = currentState.filterType === 'call_groups' ? header_callgroup : headers;
            }
          }

          if (metadata.period_options) {
            newState.periodOptions = metadata.period_options;
            if (!currentState.period) {
              newState.period = metadata.period_options[0];
            }
          }
          dispatch(ControlsActions.loadState(section, newState));
          dispatch(ControlsActions.changeFilterType(section, currentState.filterType, true));
        });
  },
  getReportingMetadata(section) {
    return (dispatch, getState) =>
      AppData.getReportingSectionMetadata(section)
        .then((response) => {
          // TODO dont overwrite everything
          // const currentState = getState().controls["reporting"] || appDefaults.controls["reporting"];
          const metadata = response.data;
          const newState = {};
          // console.log(section)
          // if (section === "call-group"){
          //   _.find(metadata.filters, {name: 'outbound'}).label = 'Distributed'
          // }
          newState.ready = false;
          newState.metadata = metadata;
          newState.timeRange = metadata.time_range;
          newState.timezone = AppData.user.timezone;
          newState.callLogFields = metadata.call_log_fields;
          newState.callFilterOptions = metadata.filters;
          newState.section = section;
          newState.callFilter = 'total';
          newState.filterOptions = [];
          newState.filters = [];
          newState.apiFilters = {};

          if (section !== 'summary') {
            dispatch(ControlsActions.changeFilterType('reporting', { id: urlMap[section] }, true));
          } else {
            newState.ready = true;
          }

          dispatch(ControlsActions.loadState('reporting', newState));
        });
  },

  getAnalyticsMetadata(filterType, section, pageAlias = section) {
    let filterTypeLabel;
    return (dispatch, getState) => {

      if (filterType === 'custom-group') {
        filterType = 'teams';
        filterTypeLabel = 'Custom Groups';
      } else {
        filterTypeLabel = filterType;
      }

      return AppData.getAnalyticsSectionMetadata(filterType, section)
        .then((response) => {
          // TODO dont overwrite everything
          // const currentState = getState().controls[pageAlias] || appDefaults.controls[pageAlias];
          const metadata = response.data;
          const newState = {};
          newState.metadata = metadata;

          if (metadata.time_range) {
            newState.timeRange = metadata.time_range;
          } else if (metadata.time_ranges) {
            newState.timeRanges = metadata.time_ranges;
          }
          newState.timezone = AppData.user.timezone;
          newState.callGroupCallTypeOptions = _.clone(metadata.panel_items);
          newState.originalCallTypeOptions = _.clone(metadata.panel_items);
          newState.callTypeOptions = metadata.panel_items;
          newState.callTypes = metadata.panel_items.slice(0, 6);
          newState.callLogFields = metadata.call_log_fields;
          newState.callType = null;
          newState.callFilter = 'total';
          newState.filterTypeLabel = filterTypeLabel;
          newState.filterOptions = [];
          newState.filters = [];
          newState.minuteBuckets = metadata.minute_buckets;
          newState.apiFilters = {};

          if (filterType !== 'summary') {
            if (section !== 'comparison') {
              dispatch(ControlsActions.changeFilterType(pageAlias, { id: urlMap[filterType] }, true));
            } else {
              dispatch(ControlsActions.changeFilterType(pageAlias, { id: urlMap[filterType] }, true, true));
            }
          } else {
            newState.ready = true;
          }

          dispatch(ControlsActions.loadState(pageAlias, newState));
        });
    };
  },

  getQualityMetadata(section) {
    return (dispatch, getState) =>
      AppData.getQualitySectionMetadata(section)
        .then((response) => {
          const metadata = response.data;
          const newState = {};

          newState.ready = true;
          newState.metadata = metadata;
          newState.timeRange = metadata.time_range;
          newState.timezone = AppData.user.timezone;
          newState.callLogFields = metadata.call_log_fields;
          newState.callFilterOptions = metadata.filters;
          newState.section = section;
          newState.callFilter = 'total';
          newState.filterOptions = [];
          newState.filters = [];
          newState.apiFilters = {};
          if (!['adoption-and-usage', 'summary'].includes(section)) {
            dispatch(ControlsActions.changeFilterType('quality', { id: urlMap[section] }, true));
          } else {
            newState.ready = true;
          }

          dispatch(ControlsActions.loadState('quality', newState));

        });
  },
};


export function bindControlsActions(section) {
  const actions = {};
  _.forEach(ControlsActions, (value, fn) => {
    actions[fn] = (...args) => {
      store.dispatch(ControlsActions[fn](section, ...args));
    };
  });

  return actions;
}

export { ControlsActions };
