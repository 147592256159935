import React ,{useCallback} from 'react';
import { DataTable } from '@nextiva/analytics-ui';
import moment from 'moment';
import './CustomTable.scss';

const CustomTable =({table, isLoading})=>{

    const renderCell = useCallback((columnLabel) => (cell) => {
        switch (columnLabel) {
            case 'userId':
                return(
                    <span style={{display:'flex', flexDirection:'column', textTransform:'capitalize'}}>
                        <span>{cell.cellData}</span>
                        <span>{cell.rowData.callingNumber}</span>
                    </span>
                )
            case 'startTime':
                return moment(cell.cellData).format('h:mm A')
            case 'talkDurationSeconds':
                return moment.utc(cell.cellData*1000).format('H:mm');
            case 'mos':
                const _quality = cell.cellData >= 3.8 ? 'Good' : cell.cellData >=3.5 ? 'Moderate': 'Poor'
                return <span className={`quality ${_quality.toLowerCase()}`}>{_quality}</span>
            default:
                return  cell.cellData;
        }
      });

    return(
       <div className="custom-table-Container">
           <div className="table-content">
               {table && (
                   <DataTable
                       data={table.rows || []}
                       isLoading={isLoading}
                       getRenderer={renderCell}
                       displayPreferences={table.headers || []}
                    />
             )}
           </div>
       </div>
    )
}

export default CustomTable;