import axios from 'axios';
import vex from 'lib/vex';


const { REACT_APP_DEV, REACT_APP_TEST } = process.env;

const instance = axios.create({
  withCredentials: true,
  timeout: 30000,
  baseURL: REACT_APP_DEV && REACT_APP_TEST !== 'true' ? '/api' : '',
  headers: {
    common: {
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      'Pragma': 'no-cache',
    },
  },
});

instance.interceptors.response.use((response) => response, (error) => {
  if (!error.response && error.message === 'Network Error') {
    vex.error([], null, error.message);

    // this happens when the server can't be reached at all, which is
    // generally temporary, but the modal persists until dismissed
    // forcing intervention in wallboards and other real time monitoring
    // components, so close automatically.
    setTimeout(() => {vex.closeAll()}, 15000);
  }

  if (error.response && error.response.status === 403 && error.response.data.permission === 'authenticated') {
    window.location = error.response.data.login_uri;
    return Promise.reject(error);
  }

  return Promise.reject(error);
});

export default instance;
export const { CancelToken, isCancel } = axios;
