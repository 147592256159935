import React from 'react';
import PropTypes from 'prop-types';
import BEMHelper from 'react-bem-helper';

import SectionHeading from './section_heading';

const classes = new BEMHelper('scheduled-report-form');

const Section = ({ number, section, children }) => (
  <div {...classes('section', { first: number === 1 })}>
    <SectionHeading
      title={section.title}
      tooltip={section.tooltip}
    />
    <div {...classes('section-content')}>
      {children}
    </div>
  </div>
);

Section.propTypes = {
  className: PropTypes.string,
  number: PropTypes.number,
  section: PropTypes.shape({
    title: PropTypes.string,
  }),
  children: PropTypes.node,
};

export default Section;
