import React from 'react';
import PropTypes from 'prop-types';

import StyleButton from './style_button';

const INLINE_STYLES = [
  { label: 'Bold', style: 'BOLD', icon: 'icon-custom-bold' },
  { label: 'Italic', style: 'ITALIC', icon: 'icon-custom-italic' },
  { label: 'Underline', style: 'UNDERLINE', icon: 'icon-custom-underline' },
];

const InlineStyleControls = ({ editorState, onToggle }) => {
  const currentStyle = editorState.getCurrentInlineStyle();
  return (
    <div className="RichEditor-controls">
      {INLINE_STYLES.map(type =>
        <StyleButton
          key={type.label}
          active={currentStyle.has(type.style)}
          label={type.label}
          icon={type.icon}
          onToggle={onToggle}
          style={type.style}
        />
      )}
    </div>
  );
};

InlineStyleControls.propTypes = {
  editorState: PropTypes.object.isRequired,
  onToggle: PropTypes.func.isRequired,
};

export default InlineStyleControls;
