import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ReactTimeout from 'react-timeout';
import { DefaultSpinner } from 'components';

import AppData from 'app_data';
import InlineSvgFactory from 'components/inline_svg';
import DashboardControls from 'components/dashboard_controls';
import { Collapse } from 'components';
import Utils from 'utils/common_utils';
import { ControlsActions } from 'actions/controls_actions';

const fetchData = (component, section, props, skipLoader) => {
  if (!skipLoader) component.setState({ loading: true });

  const params = {
    time_range: props.timeRange,
    selects: _.map(props.callTypes, (i) => _.omit(i, 'id', 'units', 'svg', 'bgColor', 'className')),
    filters: { [props.filterType.bucketType]: _.map(props.filters, 'id') },
  };

  AppData.getMonitoringSectionDashboard(params, 'wallboards')
    .then((response) => {
      const { data } = response;
      component.setState({
        data: data.data,
        loading: false,
      });
    });
};

const mapStateToProps = (state) => ({
  controlsState: state.controls.wallboards || {},
});

const mapDispatchToProps = (dispatch) => ({
  controlsActions: bindActionCreators(ControlsActions, dispatch),
});

class WallboardContainerComponent extends Component {
  static propTypes = {
    controlsActions: PropTypes.object,
    controlsState: PropTypes.object,
  };

  componentWillMount() {
    window.addEventListener('refresh', this.storeDidChange);
    this.props.controlsActions.getMonitoringMetadata('wallboards');
  }

  componentWillUnmount() {
    window.removeEventListener('refresh', this.storeDidChange);
    this.props.controlsActions.setInactive('wallboards');
  }

  handlePopout = () => {
    const params = {};
    params.controlsState = _.assign({}, this.props.controlsState);

    // Save params to server, redirect with returned hash on data.id
    AppData.savePopoutParams(params).then((response) => {
      const { data } = response;
      const route = `${process.env.REACT_APP_PUBLIC_PATH || ''}/popout/wallboard/${data.id}`;
      const open = window.open(route);

      // ipad blocks popouts, redirect page
      if (open == null || typeof open === 'undefined') {
        window.location.href = route;
      }
    });
  };

  render() {
    const { controlsState } = this.props;
    let content;

    if (controlsState.ready) {
      content = (
        <>
          <DashboardControls
            section={'wallboards'}
            onPopout={this.handlePopout}
          />
          <div className="dashboard-content content-box">
            <Wallboard
              realTime={controlsState.realTime}
              metadata={controlsState.metadata}
              boardType={controlsState.boardType}
              callTypes={controlsState.callTypes}
              filterType={controlsState.filterType}
              filters={controlsState.filters}
              timeRange={controlsState.timeRange}
            />
          </div>
        </>
      );
    } else {
      content = <DefaultSpinner />;
    }

    return content;
  }
}

const WallboardContainer = connect(mapStateToProps, mapDispatchToProps)(WallboardContainerComponent);

class WallboardComponent extends Component {
  static propTypes = {
    realTime: PropTypes.bool,
    callTypes: PropTypes.array,
    metadata: PropTypes.shape({
      header: PropTypes.array,
    }),
    boardType: PropTypes.shape({
      id: PropTypes.string,
    }),
  };

  state = {
    items: null,
    loading: true,
    intervalID: null,
  };

  componentDidMount() {
    if (this.props.realTime) {
      fetchData.bind(this, this, 'wallboards', this.props, true);
      const intervalID = this.props.setInterval(
          fetchData.bind(this, this, 'wallboards', this.props, true),
          AppData.user.refresh_rate * 1000);
      this.setState({ intervalID: intervalID });
    } else {
      fetchData(this, 'wallboards', this.props);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.realTime) {
      fetchData.bind(this, this, 'wallboards', nextProps, true);
      const intervalID = this.props.setInterval(
          fetchData.bind(this, this, 'wallboards', nextProps, true),
          AppData.user.refresh_rate * 1000);
      this.setState({ intervalID: intervalID });
    } else {
      clearInterval(this.state.intervalID);
      fetchData(this, 'wallboards', nextProps);
    }
  }

  render() {
    if (this.state.loading) {
      return (
        <DefaultSpinner />
      );
    }
    // header value used for Call Type dropdown.
    // Metadata is diff than dashboard and htis is a short term fix
    const header = [];
    let hasData = false;
    const boards = _.map(this.state.data, (boardData, index) => {
      if (boardData.value > 0) {
        hasData = true;
      }
      const boardMeta = _.find(this.props.callTypes, (obj) => obj.label === boardData.name);

      header.push(this.props.metadata.header[boardData.name]);
      let boardValue = boardData.value;
      if (boardData.unit === 'seconds') {
        boardValue = Math.ceil(boardValue / 60);
      }
      if (boardMeta) {
        return (
          <Board
            key={index}
            title={boardMeta.label}
            value={boardValue}
            svg={boardMeta.svg}
            unit={boardMeta.units}
            bgColor={boardMeta.bgColor}
            boardType={this.props.boardType.id}
          />
        );
      }
    });

    // If all values 0 then return No Data Message.
    if (!hasData) {
      return (<div className="monitoring-no-data">No data with these options.</div>);
    }

    return (
      <div className="wallboard-container">
        {boards}
      </div>
    );
  }
}

const Wallboard = ReactTimeout(WallboardComponent);

class Board extends Component {
  static propTypes = {
    svg: PropTypes.string,
    bgColor: PropTypes.string,
    title: PropTypes.string,
    value: PropTypes.number,
    unit: PropTypes.string,
    boardType: PropTypes.string,
  };

  render() {
    const Svg = InlineSvgFactory(`${this.props.svg}.svg`);

    let board = (
      <div className="wallboard-iconic" style={{ backgroundColor: this.props.bgColor }}>
        <div className="wallboard-iconic--title-wrapper">
          <Svg className="wallboard-iconic__icon" />
          <span className="wallboard-iconic__title">{this.props.title}</span>
        </div>
        <div >
          <div className="wallboard-iconic__value">{Utils.numberWithCommas(this.props.value | 0)}</div>
          <div className="wallboard-iconic__unit">{this.props.unit}</div>
        </div>
      </div>
    );

    if (this.props.boardType === 'original') {
      board = (
        <div className="wallboard-original" >
          <span className="wallboard-original__title ">{this.props.title}</span>
          <div>
            <div className="wallboard-original__value">{Utils.numberWithCommas(this.props.value | 0)}</div>
            <sup className="wallboard-original__unit">{this.props.unit}</sup>
          </div>
        </div>
      );
    }

    return board;
  }
}

class WallboardPopoutComponent extends Component {
  static propTypes = {
    controlsState: PropTypes.object,
    controlsActions: PropTypes.object,
    match: PropTypes.object,
  };

  state = {
    ready: false,
  };

  componentWillMount() {
    const { id } = this.props.match.params;

    AppData.getPopoutParams(id).then((response) => {
      const { data } = response;
      this.props.controlsActions.loadState('wallboards', data.controlsState);
      this.setState({ ready: true });
    });
  }

  render() {
    if (!this.state.ready) {
      // settings call hasn't returned
      return <div />;
    }

    const { controlsState } = this.props;

    return (
      <div className="monitoring-gamification dashboard-controls">
        <Collapse disableAnimation>
          <DashboardControls
            section={'wallboards'}
            hidePopout
          />
        </Collapse>
        <div className="wallboard">
          <Wallboard
            realTime={controlsState.realTime}
            metadata={controlsState.metadata}
            boardType={controlsState.boardType}
            callTypes={controlsState.callTypes}
            filterType={controlsState.filterType}
            filters={controlsState.filters}
            timeRange={controlsState.timeRange}
          />
        </div>
      </div>
    );
  }
}

const WallboardPopout = connect(mapStateToProps, mapDispatchToProps)(WallboardPopoutComponent);


export {
  WallboardContainer,
  Wallboard,
  WallboardPopout,
};
