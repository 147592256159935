import React from 'react';

import CustomDashboard from 'modules/monitoring/custom_dashboard';

import Styles from './Dashboard.module.scss';


function Dashboard(props) {
  return (
    <div className={Styles.dashboard}>
      <CustomDashboard
        {...props}
        viewing
        isShowEditButton={false}
      />
    </div>
  );
}

export default Dashboard;
