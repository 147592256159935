

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import cx from 'classnames';

import AccordionItems from 'components/accordion/accordion_items';
import InlinveSvgFactory from 'components/inline_svg';

const Arrow = InlinveSvgFactory('arrow.svg');

class Dropzone extends Component {
  static propTypes = {
    /** Handler for selecting an item from the dropzone */
    onSelect: PropTypes.func.isRequired,
    /** Function for adding all items to the drop down (clicking Select All) */
    enableAll: PropTypes.func,
    /** Function for clearing the drop down (clicking Remove All) */
    disableAll: PropTypes.func,
    /** An array of the item objects that could be placed into the dropzone */
    items: PropTypes.array,
    /** Whether or not the dropdown is static */
    static: PropTypes.bool,
    /** The max number of items that can be show on the dropzone per page */
    perPage: PropTypes.number.isRequired,
    /** The max number of items that can be selected into the dropzone  */
    limit: PropTypes.number,
    /** Whether or not the dropdown has an error */
    hasError: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = {
      items: this.props.items || [],
      filterText: '',
      page: 0,
      totalPages: this.getTotalPages(),
      toggleLabel: 'remove all',
    };
  }

  componentWillMount() {
    this.pageLeft = this.paginate.bind(this, -1);
    this.pageRight = this.paginate.bind(this, 1);
  }

  componentWillReceiveProps(newProps) {
    const items = this.filterItems(newProps.items, this.state.filterText);
    this.setState(
      {
        page: 0,
        items,
        totalPages: this.getTotalPages(items),
      }
    );
  }

  shouldComponentUpdate(nextProps, nextState) {
    return true;
  }

  getTotalPages(items) {
    if (!items) items = this.props.items;
    if (items && items.length) {
      return Math.ceil(items.length / this.props.perPage);
    }
    return 1;
  }

  setFilterText = (e) => {
    const items = this.filterItems(this.props.items, e.target.value);

    this.setState({
      page: 0,
      filterText: e.target.value,
      items,
      totalPages: this.getTotalPages(items),
    });
  }

  filterItems = (items, filterText) => {
    let filteredItems = [];

    if (filterText === undefined) filterText = this.state.filterText;
    if (filterText !== '') {
      _.each(items, (item) => {
        if (item.label.toLowerCase().indexOf(filterText.toLowerCase()) >= 0) {
          filteredItems.push(item);
        }
      });
    } else {
      filteredItems = items;
    }

    return filteredItems;
  }

  handleDropzoneToggle = () => {
    let newLabel = 'remove all';

    if (this.state.toggleLabel === 'remove all') {
      newLabel = 'select all';
      this.props.disableAll();
    } else {
      this.props.enableAll();
    }

    this.setState({
      toggleLabel: newLabel,
    });
  }

  paginate(direction) {
    const newPage = this.state.page + direction;

    if (newPage >= 0 && newPage < this.getTotalPages()) {
      this.setState({ page: this.state.page + direction });
    }
  }

  render() {
    const start = this.state.page * this.props.perPage;
    const visibleItems = this.state.items.slice(start, start + this.props.perPage);
    let atLimit = false;
    let limitInfo = null;
    if (this.props.limit) {
      limitInfo = `Limit: ${this.state.items.length} out of ${this.props.limit}`;
      atLimit = true;
    }

    return (
      <div data-testid="custom-chart-dropzone" className="dropzone-container">
        <div
          className={cx({ 'custom-chart-items-container dropzone': true, error: this.props.hasError })}
        >
          <div className="page-controls">
            <input
              type="search"
              placeholder="Search"
              onChange={this.setFilterText}
              value={this.state.filterText}
            />
            <span className={cx({ 'dropzone-limit-info': true, 'dropzone-limit-full': atLimit })}>{limitInfo}</span>
            <div className="pull-right">
              <Arrow
                className={cx({ 'arrow-left': true, hidden: this.state.page === 0 })}
                onClick={this.pageLeft}
              />
              {this.state.page + 1} / {this.state.totalPages}
              <Arrow
                className={cx({ 'arrow-right': true, hidden: this.state.page + 1 === this.state.totalPages })}
                onClick={this.pageRight}
              />
            </div>
          </div>
          <AccordionItems
            items={visibleItems}
            onSelect={this.props.onSelect}
          />
        </div>
        {!this.props.static ?
          <span
            onClick={this.handleDropzoneToggle}
            className="toggle-label"
          >
            {this.state.toggleLabel}
          </span>
        : null }
      </div>
    );
  }
}

export default Dropzone;
