import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Form, Control, actions, track } from 'react-redux-form';
import { DefaultSpinner, DefaultSpinnerTypes } from 'components';

import vex from 'lib/vex';
import ShowMore from 'components/show_more';
import Pagination from 'components/call_recording/pagination';
import questions from 'modules/questions';


function getNormalizedResponse(question, selectedValue) {
  const questionType = question.question_type;
  switch (questionType) {
    case 'scale': {
      const total = question.options.start === 0 ? question.options.total : question.options.total - 1;
      return selectedValue / total;
    }
    case 'yes-no':
      return question.options.yes === selectedValue ? 1 : 0;
    case 'rating':
      return selectedValue / question.options.total;
    default:
      throw new Error('Invalid question type provided');
  }
}

class QuestionsContainer extends Component {
  static propTypes = {
    /** Handle passing the answers */
    onChange: PropTypes.func,
    /** Unique id for the call */
    externalTrackingId: PropTypes.string.isRequired,
    /** Form state */
    form: PropTypes.object,
    /** Actions for interacting with questions */
    questionsActions: PropTypes.shape({
      /** Load available question groups */
      loadQuestions: PropTypes.func,
      /** Load question group responses for a call */
      loadResponses: PropTypes.func,
    }),
    /** Actions for the question group response form */
    questionsFormActions: PropTypes.object,
    /** List of question groups */
    questionGroups: PropTypes.array,
    /** List of questions by id */
    questions: PropTypes.object,
    /** Question group responses for the current call */
    questionResponses: PropTypes.array,
    /** Whether or not questions are loading */
    loading: PropTypes.bool,
  }

  state = {
    questionGroup: 0,
    questions: null,
    questionPage: 0,
    answers: null,
  }

  componentDidMount() {
    const { questionsActions, questionsFormActions, externalTrackingId } = this.props;
    const loadQuestions = questionsActions.loadQuestions();
    const loadResponses = questionsActions.loadQuestionsResponses(externalTrackingId);
    Promise.all([loadQuestions, loadResponses]).then((values) => {
      // pull data keys out of both responses
      const [{ data: questionGroups }, { data: questionResponses = [] }] = values;
      // this will be the first question group displayed in the modal
      const firstQuestionGroup = questionGroups[0];
      // is there already a saved response for the first question group in the list?
      const firstQuestionGroupResponse = questionResponses.find((element) => element.question_group_id === firstQuestionGroup.id);
      // if there is a response, set it as the model for the question group response form
      if (firstQuestionGroupResponse) {
        questionsFormActions.change('questions_group_response', firstQuestionGroupResponse);
      } else {
        questionsFormActions.change(
          'questions_group_response',
          firstQuestionGroup
            ? this.getBlankResponse(firstQuestionGroup)
            : [],
        );
      }
    });
  }

  componentWillUnmount() {
    const { questionsFormActions } = this.props;
    // reset the form when the modal closes to clear out responses
    questionsFormActions.reset('questions_group_response');
  }

  getQuestions = () => {
    const { questionGroup } = this.state;
    const { questionGroups, questions } = this.props;
    if (questionGroups.length === 0) {
      return [];
    }
    const questionsList = _.cloneDeep(questionGroups[questionGroup].questions);
    return questionsList.map((id, index) => {
      const question = _.cloneDeep(questions[id]);
      question.number = index + 1;
      return question;
    });
  }

  getCurrentQuestions = () => {
    const { questionPage } = this.state;
    let questionsList = this.getQuestions();
    const questionValue = questionPage * 2;
    questionsList = questionsList.slice(questionValue, questionValue + 2);
    return questionsList;
  }

  getBlankResponse = (questionGroup) => {
    const { questions } = questionGroup;
    const blankResponse = {
      question_group_id: questionGroup.id,
      answers: [],
    };
    questions.forEach((question) => {
      blankResponse.answers.push({
        question_id: question.id ? question.id : question,
        answer: {
          normalized_value: null,
          value: null,
        },
      });
    });
    return blankResponse;
  }

  displayUnsavedChangesDialog = (callback) => {
    const message = `You have unsaved changes.
    Your responses will not be saved if you change the question group.
    Are you sure you want to proceed?`;
    vex.dialog.buttons.YES.text = 'Yes';
    vex.dialog.buttons.NO.text = 'No';
    vex.dialog.confirm({
      message,
      callback,
    });
  }

  changeQuestionGroup = (index) => {
    const { questionsActions, externalTrackingId, questionsFormActions, questionGroups, questionResponses } = this.props;
    const newIndex = parseInt(index, 10);
    questionsActions.loadQuestionsResponses(externalTrackingId).then(() => {
      this.setState({
        questionGroup: newIndex,
        questionPage: 0,
        answers: null,
      }, () => {
        const questionGroup = questionGroups[newIndex];
        // is there already a saved response for the first question group in the list?
        const questionGroupResponse = questionResponses.find((element) => element.question_group_id === questionGroup.id);
        if (questionGroupResponse) {
          questionsFormActions.change('questions_group_response', questionGroupResponse);
        } else {
          questionsFormActions.change('questions_group_response', this.getBlankResponse(questionGroup));
        }
      });
    });
  }

  handlePageUpdate = (newPage) => {
    this.setState({ questionPage: newPage });
  }

  handleChangeQuestionGroup = (e) => {
    const { form } = this.props;
    const index = e.target.value;
    if (form.touched && !form.submitted) {
      const callback = (value) => {
        if (value) {
          this.changeQuestionGroup(index);
        }
      };
      this.displayUnsavedChangesDialog(callback);
    } else {
      this.changeQuestionGroup(index);
    }
  }

  handleAnswersChange = (values) => {
    const { onChange, questionGroups } = this.props;
    const { questionGroup } = this.state;
    if (questionGroups.length === 0) {
      return;
    }
    onChange(questionGroups[questionGroup].id, values);
  }

  handleAnswerChange = (questionId, model, value) => {
    const { questionsFormActions, questions } = this.props;
    const answer = {
      value,
      normalized_value: getNormalizedResponse(questions[questionId], value),
    };
    questionsFormActions.change(model, answer);
    questionsFormActions.setTouched(model);
  }

  renderQuestionGroups = () => {
    const { questionGroups } = this.props;
    return questionGroups.map((group, index) => <option key={index} value={index}>{group.title}</option>);
  }

  render() {
    const { loading, questionGroups } = this.props;
    const { questionGroup, questionPage } = this.state;

    if (!loading) {
      if (questionGroups.length === 0) {
        return (
          <div className="recording-no-questions-groups">
            No question groups for this call
          </div>
        );
      }

      return (
        <div className="recording-questions">
          <div className="recording-questions__header">
            <p className="recording-questions__title">
              Question Group:
            </p>
            <select
              className="recording-questions__questions-select"
              value={questionGroup}
              onChange={this.handleChangeQuestionGroup}
            >
              {this.renderQuestionGroups()}
            </select>
          </div>
          <Form
            model="questions_group_response"
            onChange={this.handleAnswersChange}
            validators={{
              'answers': (value) => (
                !Boolean(_.find(value, (answer) => (
                  answer.answer.value === null
                )))
              ),
            }}
          >
            <Questions
              questions={this.getCurrentQuestions()}
              onChange={this.handleAnswerChange}
            />
          </Form>
          <Pagination
            handlePageUpdate={this.handlePageUpdate}
            initialPage={questionPage}
            maxPages={Math.ceil(this.getQuestions().length / 2)}
            className="call-rating-iteration-arrows"
          />
        </div>
      );
    }
    return (
      <DefaultSpinner kind={DefaultSpinnerTypes.NORMAL}/>
    );
  }
}

const Questions = ({ questions, onChange }) => (
  <ul className="recording-questions__questions">
    {
      questions.map((question) => (
        <Question
          key={question.id}
          number={question.number}
          question={question}
          onChange={onChange}
        />
      ))
    }
  </ul>
);

Questions.propTypes = {
  questions: PropTypes.array,
  onChange: PropTypes.func,
};

Questions.defaultProps = {
  questions: [],
};

const Question = ({ number, question, onChange }) => (
  <li className="recording-questions__question recording-question">
    <div className="recording-question__title">
      {`Question ${number}: ${question.title}`}
    </div>
    <ShowMore
      classNameLess="recording-question__text"
      classNameMore="recording-question__text-more"
      textBody={question.text}
    />
    <Control
      model={track('.answers[].answer', { question_id: question.id })}
      className="recording-question__input"
      component={questions.utils.getPreviewComponentForResponding}
      mapProps={{
        onChange: (formProps) => formProps.onChange,
        value: (formProps) => formProps.modelValue && formProps.modelValue.value,
      }}
      controlProps={{
        question,
      }}
      changeAction={onChange.bind(null, question.id)}
    />
  </li>
);

Question.propTypes = {
  number: PropTypes.number,
  question: PropTypes.shape({
    text: PropTypes.string,
    type: PropTypes.string,
    title: PropTypes.string,
  }),
  onChange: PropTypes.func,
};

const mapStateToProps = (state) => ({
  form: state.forms.questions_group_response.$form,
  loading: questions.selectors.questionsLoading(state) && questions.selectors.responsesLoading(state),
  questionGroups: questions.selectors.getAll(state),
  questions: questions.selectors.getQuestions(state),
  questionResponses: questions.selectors.getQuestionResponses(state),
});

const mapDispatchToProps = (dispatch) => ({
  questionsActions: bindActionCreators(questions.actions, dispatch),
  questionsFormActions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(QuestionsContainer);
