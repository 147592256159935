import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import cx from 'classnames';
import ico_tooltip from '../img/ico_tooltip.png';

const ToolTip = ({ tipItem, tipContent, tipId, tipClass }) => {
  const newTipContent = `<h4>XBert tip:</h4>${tipContent}`;

  if (!tipItem) {
    tipItem = <img className="ico_tooltip" src={ico_tooltip} alt="Tooltip" />;
  }
  return (
    <span className={cx('xbert-tooltip', tipClass)}>
      <span data-tip={newTipContent} data-for={tipId}>
        {tipItem}
      </span>
      <ReactTooltip
        class="tool-tip-container"
        delayShow={700}
        html
        id={tipId}
        type="light"
      />
    </span>
  );
};

ToolTip.propTypes = {
  /** The tool tip item object */
  tipItem: PropTypes.object,
  /** The content of the tool tip */
  tipContent: PropTypes.string,
  /** The ID of the tool tip */
  tipId: PropTypes.string,
  /** The class of the tool tip */
  tipClass: PropTypes.string,
};

export default ToolTip;
