import React, { Component } from 'react';
import PropTypes from 'prop-types';

class FlipCounterDigit extends Component {
  static propTypes = {
    /** The digit in the flip counter */
    digit: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = {
      digit: 0,
      nextDigit: this.props.digit,
      currentClass: '',
    };
  }

  componentWillMount() {
    this.timeouts = [];
  }

  componentDidMount() {
    this.setTimeout(() => {
      this.updateDigit();
    }, 100);
  }

  componentWillReceiveProps(newProps) {
    if (this.state.digit !== newProps.digit) {
      if (!this.isAnimating) {
        this.setState({
          nextDigit: newProps.digit,
        }, this.updateDigit());
      }
    }
  }

  componentWillUnmount() {
    this.clearTimeouts();
  }

  setTimeout = (callback, ...args) => {
    this.timeouts.push(setTimeout((...params) => { callback.call(this, ...params); }, ...args));
  }

  clearTimeouts = () => {
    this.timeouts.forEach(clearTimeout);
  }

  updateDigit = () => {
    this.isAnimating = true;
    this.setState({ currentClass: '' }, () => {
      this.setTimeout(() => {
        this.setState({ currentClass: 'changing' });
      }, 50);
    });

    this.setTimeout(() => {
      this.isAnimating = false;
      this.setState({
        currentClass: 'changed',
        digit: this.state.nextDigit,
      });
    }, 500);
  }

  render() {
    return (
      <div className={`count up ${this.state.currentClass}`}>
        <span className="current top">{this.state.digit}</span>
        <span className="next top">{this.state.nextDigit}</span>
        <span className="current bottom">{this.state.digit}</span>
        <span className="next bottom">{this.state.nextDigit}</span>
      </div>
    );
  }
}

export default FlipCounterDigit;
