import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import userManagementActionCreators from 'actions/user_management_actions';

export function mapStateToProps(state) {
  return {
    user_management: state.user_management.ui,
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    push: bindActionCreators(push, dispatch),
    userManagementActions: bindActionCreators(userManagementActionCreators, dispatch),
  };
}
