import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import { DefaultSpinner } from 'components';

import { ControlsActions } from 'actions/controls_actions';
import AnalyticsControls from 'components/analytics_controls/trend_controls';

import Trend from '../analytics_trend';
import Comparison from '../analytics_comparison';
import Period from '../Period';
import CallDuration from '../analytics_call_duration';


const MAP_SECTIONS = {
  trend: 'trend',
  period: 'period',
  comparison: 'comparison',
  'call-duration': 'callDuration',
};


const mapStateToProps = ({ controls, router }) => {
  const [filterType, section] = replacePath(router.location.pathname);

  return ({
    section,
    filterType,
    controlsState: controls && controls[MAP_SECTIONS[section]] || {},
  });
};

const mapDispatchToProps = (dispatch) => ({
  push: bindActionCreators(push, dispatch),
  controlsActions: bindActionCreators(ControlsActions, dispatch),
});


const replacePath = (path) => path.replace('/analytics/', '').split('/');

const sections = [
  {
    key: 'trend',
    component: Trend,
  },
  {
    key: 'comparison',
    component: Comparison,
  },
  {
    key: 'period',
    component: Period,
  },
  {
    key: 'call-duration',
    component: CallDuration,
  },
];


class Wrapper extends Component {
  static propTypes = {
    section: PropTypes.string,
    filterType: PropTypes.string,
    controlsState: PropTypes.object,
    controlsActions: PropTypes.object,
    match: PropTypes.object,
    push: PropTypes.func,
  };

  componentWillMount() {
    const {
      push,
      section,
      filterType,
    } = this.props;

    if (!section || !sections.some(s => s.key === section)) {
      push(`/analytics/${filterType}/trend`);
      return;
    }

    this.getAnalyticsMetadata(filterType, section);
  }

  componentWillReceiveProps(nextProps) {
    const {
      push,
      section,
      filterType,
      controlsState,
      controlsActions,
    } = nextProps;
    const oldSection = MAP_SECTIONS[this.props.section];

    if (!section) {
      push(`/analytics/${filterType}/trend`);
      return;
    }

    if (controlsState.page !== oldSection) {
      if (oldSection) {
        controlsActions.setInactive(oldSection);
      }

      this.getAnalyticsMetadata(filterType, section);
    }
  }

  componentWillUnmount() {
    const { section, controlsActions } = this.props;
    controlsActions.setInactive(section);
  }

  getAnalyticsMetadata = (filterType, section) => {
    const { controlsActions } = this.props;

    let params = [filterType, section];

    if (section === 'call-duration') {
      params = [filterType, 'call-duration', 'callDuration'];
    } else if (section === 'period') {
      params = [filterType, 'period-over-period', 'period'];
    }

    controlsActions.getAnalyticsMetadata(...params);
  };

  render() {
    const {
      section,
      controlsState,
      controlsActions,
    } = this.props;

    if (!section) {
      return (
        <div>Redirecting...</div>
      );
    }

    const { component: Component } = sections
      .find(s => s.key === section) || {};

    if (!Component) {
      return (
        <div>Not found. 404</div>
      );
    }

    return (
      <div className="analytics analytics-trend">
        <AnalyticsControls />

        {controlsState.ready
          ? (
            <Component state={controlsState} actions={controlsActions} />
          ) : (
            <DefaultSpinner />
          )
        }

      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Wrapper);
