import React from 'react';
import { NotFoundPlaceholder } from '@nextiva/next-ui';

import { CustomReport, getExternalReport } from 'modules/reporting';

import Styles from './ExternalReports.module.scss';


function ExternalReports(props) {
  const { id } = props.match.params;

  if (!id) {
    return (
      <NotFoundPlaceholder className={Styles.notFound} />
    );
  }

  return (
    <CustomReport
      {...props}
      isExternal
      isDisabledData
      getReport={getExternalReport}
    />
  );
}


export default ExternalReports;
