import _ from 'lodash';

import Constants from 'constants.js';
import AppData from 'app_data';
import Filters from 'modules/reporting/custom_filters';

const TeamActions = {
  enableFilter(dropzone, key) {
    return { type: Constants.TEAM_ENABLE_FILTER, op: 'enable', dropzone, key };
  },
  disableFilter(dropzone, key) {
    return { type: Constants.TEAM_DISABLE_FILTER, op: 'disable', dropzone, key };
  },
  enableAll() {
    return { type: Constants.TEAM_ENABLE_ALL };
  },
  disableAll() {
    return { type: Constants.TEAM_DISABLE_ALL };
  },
  loadTeam(id) {
    return (dispatch, getState) => {
      AppData.getTeam(id).then((response) => {
        const { data } = response;
        dispatch({ type: Constants.TEAM_LOAD_TEAM, data });
      });
    };
  },
  getFilters() {
    return (dispatch, getState) =>
      AppData.getAllFilters()
        .then((data) => {
          _.each(data.data, (v, key) => {
            Filters[key].items = _.map(data.data[key], (row) => ({
              key: row.id,
              label: row.label,
              parent: key,
              selected: false,
            }));
          });

          return data;
        });
  },
  validate(options) {
    return (dispatch, getState) => {
      dispatch({ type: Constants.TEAM_VALIDATE, options });

      const errors = getState().team.ui.errors;
      const messages = [];
      _.each(errors, (err) => {
        if (err.hasError) messages.push(err.errorMessage);
      });

      return messages.length && messages || null;
    };
  },
  save(teamId) {
    return (dispatch, getState) => {
      const teamState = getState().team;
      const savedTeam = getTeamInfo(teamState);
      if (teamId) savedTeam.teamId = teamId;

      setTimeout(() => {
        dispatch({ type: Constants.CUSTOM_CLEAR });
      }, 10);

      return AppData.saveTeam(savedTeam);
    };
  },
  chooseGroupType(team_type) {
    return { type: Constants.TEAM_CHOOSE_TYPE, team_type };
  },
  setStatus(value) {
    return { type: Constants.TEAM_SET_STATUS, field: 'status', value };
  },
  setTitle(value) {
    return { type: Constants.TEAM_SET_TITLE, field: 'title', value };
  },
  setDescription(value) {
    return { type: Constants.TEAM_SET_DESCRIPTION, field: 'description', value };
  },
  clear() {
    return { type: Constants.TEAM_CLEAR };
  },
};

function getTeamInfo(teamState) {
  const team = {};
  const uiState = teamState.ui;

  team.label = uiState.title;
  team.description = uiState.description;
  team.team_type = uiState.dropzones[0];
  team.members = uiState.filters[team.team_type].items.filter((x) => x.selected).map((x) => x.key);

  return team;
}

export default TeamActions;
