import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { DateRangePicker } from '@nextiva/analytics-ui';

import { bindControlsActions } from 'actions/controls_actions';
import BasicChartControls from 'components/charts/basic_chart_controls';
import ComparisonDropdowns from 'components/analytics_controls/comparison_dropdowns';

const mapStateToProps = (state) => {
  const currentSection = state.controls.currentSection;
  const controlsState = state.controls[currentSection];
  return {
    filterOptions: controlsState.filterOptions || [],
    realTime: controlsState.realTime,
    timeRange: controlsState.timeRange,
    timezone: controlsState.timezone,
  };
};

class ComparisonPopoutHeader extends Component {
  static propTypes = {
    /** The title of the chart in the header */
    title: PropTypes.string,
    /** The kind of unit that the chart is using */
    unit: PropTypes.string,
    /** The potential units that the chart could use */
    units: PropTypes.array,
    /** The charts that could potentially be displayed */
    charts: PropTypes.array,
    /** A handler for a change in the unit */
    onUnitChange: PropTypes.func,
    /** A handler for an edit to the chart */
    onChartChange: PropTypes.func,
    /** Which controls section */
    controlsSection: PropTypes.string,
    /** Selected filters */
    filters: PropTypes.array,
    /** Availble filters */
    filterOptions: PropTypes.array,
    /** Are we in real time mode? */
    realTime: PropTypes.bool,
    /** Time range for the section */
    timeRange: PropTypes.object,
    /** User timezone */
    timezone: PropTypes.string,
  };

  componentWillMount() {
    this.actions = bindControlsActions(this.props.controlsSection);
  }

  render() {
    const {
      filterOptions,
      realTime,
      timeRange,
      timezone,
    } = this.props;
    return (
      <div className="chart-header">
        <span>
          {this.props.title}
        </span>
        <ComparisonDropdowns
          dropdownOptions={filterOptions}
          onComparisonChange={this.actions.setFilters}
        />
        <DateRangePicker
          withRealTimeIcon
          className="date-range-popover"
          isRealTime={realTime}
          timezone={timezone}
          dateRange={timeRange}
          onChange={this.actions.setTimeRange}
          onRealTimeChange={this.actions.setRealTime}
          theme={{
            dateRangePicker: 'date-range',
          }}
        />
        <BasicChartControls {...this.props} disablePopoutButton />
      </div>
    );
  }
}

export default connect(mapStateToProps)(ComparisonPopoutHeader);
