import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

class Accordion extends Component {
  static propTypes = {
    /** Whether or not there are multiple accordion sections */
    multiple: PropTypes.bool,
    /** Handler for when an item is selected */
    onSelect: PropTypes.func,
    /** The currently selected item */
    selectedKey: PropTypes.number,
    /** Accordion sections */
    children: PropTypes.node,
  }

  constructor(props) {
    super(props);

    this.state = {
      open: [],
    };
  }

  componentWillReceiveProps(newProps) {
    if (newProps.maintainState) {
      return;
    } else if (newProps.open) {
      const open = [];
      open.length = this.props.children.length;
      for (let i = 0; i < open.length; i++) {
        open[i] = true;
      }

      this.setState({ open });
    } else if (newProps.open === false) {
      this.setState({ open: [] });
    } else if (newProps.section !== null && !this.state.open.length) {
      this.setState({ open: [newProps.section] });
    }
  }

  handleToggleAccordion = (index) => {
    if (!this.props.multiple) {
      this.setState({
        open: index !== this.state.open[0] ? [index] : [-1],
      });
    } else {
      const open = _.clone(this.state.open);
      open[index] = !this.state.open[index];

      this.setState({ open });
    }

    this.props.onSelect(null);
  }

  render() {
    let isOpen;

    const children = React.Children.map(this.props.children, (child, index) => {
      if (!this.props.multiple) {
        isOpen = index === this.state.open[0];
      } else {
        isOpen = !!this.state.open[index];
      }

      // TODO pass selectedKey only to the correct child
      const props = {
        onSelect: this.props.onSelect,
        onToggleAccordion: this.handleToggleAccordion.bind(this, index),
        open: isOpen,
        selectedKey: this.props.selectedKey,
        parentIndex: index,
      };

      const clone = React.cloneElement(child, props);
      return clone;
    });

    return (
      <div data-testid="accordion-section" className="accordion">
        {children}
      </div>
    );
  }
}

export default Accordion;
