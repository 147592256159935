import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { DateRangePicker } from '@nextiva/analytics-ui';

import { VideoPopup } from 'components';
import Button from 'components/button';

import { ReactComponent as PopoutImg } from 'img/popout.svg';


const CustomDashboardControls = (props) => {
  const {
    viewOnly,
    onOpenPopout,
    isShowEditButton,
    isShowIconPopout,
  } = props;

  return (
    <>
      <div className="custom_dashboard_date_range">
        <DateRangePicker
          withRealTimeIcon
          className="date-range-popover"
          dateRange={props.timeRange}
          isRealTime={props.realTime}
          timezone={props.timezone}
          onChange={props.onTimeRangeChange}
          onRealTimeChange={props.onRealTimeChange}
          theme={{
            dateRangePicker: 'date-range',
          }}
        />


        {isShowIconPopout && (
          <PopoutImg className="open-popout" onClick={onOpenPopout} />
        )}

        {viewOnly && isShowEditButton && (
          <Button
            className="btn-transparent"
            onClick={props.editDashboard}
            aria-label="Edit dashboard info"
          >
            <span className="icon-custom-pencil" />
          </Button>
        )}

        {!viewOnly && (
          <VideoPopup
            videoUrl="https://nextiva-1.wistia.com/medias/3o83zbc1v5"
            theme={{
              link: 'video'
            }}
          />
        )}
      </div>

      {!viewOnly && (
        <div className="custom_dashboard_info">
          <input
            className={cx({ custom_dashboard_title: true, error: props.errors.title.hasError })}
            type="text"
            placeholder="Dashboard name"
            onBlur={props.onTitleChange}
            defaultValue={props.title}
            aria-label="Dashboard name"
          />
          <input
            className="custom_dashboard_description"
            type="text"
            placeholder="Dashboard description"
            onBlur={props.onDescriptionChange}
            defaultValue={props.description}
            aria-label="Dashboard description"
          />
          <div className="buttons pull-right">
            <Button className="btn" onClick={props.onAddItem}>Add Item</Button>
            <Button className="btn btn-green" onClick={props.onSave}>Save</Button>
          </div>
        </div>
      )}
    </>
  );
};

CustomDashboardControls.propTypes = {
  timeRange: PropTypes.object,
  realTime: PropTypes.bool,
  timezone: PropTypes.string,
  onTimeRangeChange: PropTypes.func,
  onRealTimeChange: PropTypes.func,
  onTitleChange: PropTypes.func,
  onDescriptionChange: PropTypes.func,
  title: PropTypes.string,
  description: PropTypes.string,
  onAddItem: PropTypes.func,
  onSave: PropTypes.func,
  errors: PropTypes.object,
  viewOnly: PropTypes.bool,
  isShowEditButton: PropTypes.bool,
  isShowIconPopout: PropTypes.bool,
  editDashboard: PropTypes.func,
  onOpenPopout: PropTypes.func,
};

export default CustomDashboardControls;
