var vex = require('vex-js');
if (!vex.dialog) {
  vex.registerPlugin(require('vex-dialog'), 'dialog');
}

vex.defaultOptions.className='vex-theme-default';

vex.dialog.defaultOptions.showCloseButton = true;

vex.error = function(errors, callback, message) {
  var overview = message || 'Oops! We could not do that.&nbsp;&nbsp;Please address the issue(s) below.';
  var modalMessage = '<div class="vex-modal-message">' + overview + '</div>';
  modalMessage += '<ul class="vex-ul">' + errors.map(function(error) { return '<li class="vex-li">' + error + '</li>'}).join('') + '</ul>';

  vex.dialog.open({
    unsafeMessage: modalMessage,
    contentClassName: 'error',
    buttons: [],
    callback: callback || function(){}
  });
};

vex.warning = function(warning, callback, yesMessage, cancelMessage) {
  var modalMessage = '<div class="vex-modal-message">' + warning + '</div>';
  vex.dialog.open({
    unsafeMessage: modalMessage,
    contentClassName: 'warning',
    buttons: [
      Object.assign({}, vex.dialog.buttons.YES, {text: yesMessage || 'OK'}),
      Object.assign({}, vex.dialog.buttons.NO, {text: cancelMessage || 'Cancel'})
    ],
    callback: callback || function(){}
  });
};

vex.success = function(success, callback) {
  var modalMessage = '<div class="vex-modal-message">' + success + '</div>';
  vex.dialog.open({
    unsafeMessage: modalMessage,
    contentClassName: 'success',
    buttons: [],
    callback: callback || function(){}
  });
};

module.exports = vex;
