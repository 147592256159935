import raf from 'raf';
import type from 'type-of';
import howler from '@nextiva/howler';
import _ from 'lodash';

/* eslint-disable no-underscore-dangle */
class Wave {
  constructor(url, target, options) {
    if (!url || !target) {
      throw new Error('Please provide url and target to WaveForm(url, target, [options])');
    }

    if (type(url) !== 'string') {
      throw new Error(`${url}: Invalid url format.`);
    }

    if (process.env.REACT_APP_DEV) {
      url = `/api/${url}`;
    }

    this.settings = Object.assign({
      nLines: 100,
      lineWidth: 3,
      canvasWidth: 500,
      canvasHeight: 120,
      bgColor: '#f3f3f3',
      emptyBlockColor: '#FFFFFF',
      filledBlockColor: '#1495f2',
      locationLineColor: '#1495f2',
      clipStart: null,
      clipEnd: null,
    }, options);

    this.player = new howler.Howl({
      src: [url],
      format: 'mp3',
      xhrWithCredentials: true,
      onload: () => {
        if (this.settings.initialClipPcts) {
          const startPct = parseFloat(this.settings.initialClipPcts.start) / this.player._duration;
          const endPct = parseFloat(this.settings.initialClipPcts.end) / this.player._duration;

          this.clipStart = startPct * this.settings.canvasWidth;
          this.clipEnd = endPct * this.settings.canvasWidth;

          setTimeout(() => {
            this.settings.onUpdate(startPct * 100, this.player._duration);
            this.settings.onClipChange(this.clipStart, this.clipEnd);
            this.seek(this.clipStart / this.settings.canvasWidth);
            this.drawBg();
          }, 0);
        }

        this.onSoundLoad();
        this.settings.onLoad && this.settings.onLoad();
      },
      onend: () => {
        this.settings.onEnd && this.settings.onEnd();
        this.seek(this.clipStart / this.settings.canvasWidth);
      },
      onloaderror: (id, err) => {
        if (this.settings.onError) {
          this.settings.onError(`Error loading url: ${err}`);
        }
      },
    });

    this.mount(target);
  }

  seek(pos) {
    this.player.seek(pos * this.player._duration);

    // redraw on click when player isnt playing
    if (!this.player.playing()) {
      this.drawBarsLoop();
    }
  }

  duration() {
    return this.player._duration;
  }

  volume(volume) {
    this.player.volume(volume);
  }

  play() {
    this.player.play();
    this.drawBarsLoop();
  }

  pause() {
    this.player.pause();
  }

  unload() {
    this.player.unload();
  }

  ready() {
    return this.player._state === 'loaded';
  }

  /** ************
  Private Methods
  **************/
  mount(target) {
    if (!target && type(target) !== 'element') {
      throw new Error('Invalid target. Please pass a domNode.');
    }

    this.bgCanvas = this.createCanvas(this.settings.canvasWidth, this.settings.canvasHeight);
    this.bgContext = this.bgCanvas.getContext('2d');
    this.barsCanvas = this.createCanvas(this.settings.canvasWidth, this.settings.canvasHeight);
    this.barsContext = this.barsCanvas.getContext('2d');

    target.appendChild(this.bgCanvas);
    target.appendChild(this.barsCanvas);

    this.barsCanvas.addEventListener('mousedown', this.handleMouseDown.bind(this));
    this.barsCanvas.addEventListener('mousemove', this.handleDrag.bind(this));
    this.barsCanvas.addEventListener('mouseup', this.handleMouseUp.bind(this));
  }

  handleMouseDown(e) {
    this.lastClipStart = this.clipStart;
    this.lastClipRight = this.clipEnd;
    this.dragging = true;
    if (e.offsetX < this.clipStart || e.offsetX > this.clipEnd) {
      this.clipStart = null;
      this.clipEnd = null;
      this.drawBg();
    }
  }

  handleDrag(e) {
    if (this.dragging) {
      this.didDrag = true;
      if (!this.clipStart && !this.clipEnd) {
        this.clipStart = e.offsetX;
        this.clipEnd = e.offsetX;
      }

      if (e.offsetX > this.clipStart) {
        this.clipEnd = e.offsetX;
      }

      if (e.offsetX < this.clipStart) {
        this.dragging = false;
        this.clipStart = null;
        this.clipEnd = null;
      }

      this.drawBg();
    }
  }

  handleMouseUp(e) {
    if (!this.ready()) return;
    let start = e.offsetX;
    if (this.dragging) {
      const clipsChanged = (this.clipStart !== this.lastClipStart || this.clipEnd !== this.lastClipEnd);

      if (clipsChanged && this.settings.onClipChange) {
        // enforce a minimum clip size
        if (type(this.clipEnd) === 'number' && this.clipEnd - this.clipStart < 10) {
          this.clipEnd = this.clipStart + 10;
          this.drawBg();
        }

        this.settings.onClipChange(this.clipStart || 0, this.clipEnd || this.settings.canvasWidth);
      }
    } else if (this.didDrag && this.clipStart === null) {
      this.settings.onClipChange(0, this.settings.canvasWidth);
      return;
    }

    this.dragging = false;
    this.didDrag = false;
    if (start === this.clipEnd) {
      start = this.clipStart;
    }

    const pos = ((start / this.settings.canvasWidth)) * this.player._duration;
    this.player.seek(pos);

    // redraw on click when player isn't playing
    if (!this.player.playing()) {
      this.drawBarsLoop();
    }
  }

  onSoundLoad() {
    if (this.player._webAudio) {
      // Play and pause for howler library to load the buffer
      this.player.play();
      this.bufferSource = this.player._sounds[0]._node.bufferSource.buffer.getChannelData(0);
      this.player.pause();
      this.generateWaveformPeaks();
    } else {
      // IE11 doesn't support web audio for drawing the waveform
      this.generateRandomPeaks();
    }

    this.drawBg();
  }


  drawBarsLoop() {
    if (this.player.state() === 'unloaded') return;
    const progress = this.player.seek() / this.player._duration;
    const bars = progress * this.settings.nLines;

    // stop when beyond clipEnd
    if (this.clipEnd && this.clipEnd / this.settings.canvasWidth < progress) {
      _.each(this.player._onend, (o) => o.fn());

      // pause after 1 more redraw cycle
      setTimeout(() => {
        this.pause();
      }, 0);
    }

    if (this.settings.onUpdate) {
      if (!this.lastProgress) {
        this.lastProgress = 0;
      }

      const newProgress = Math.floor(progress * 1000);
      if (newProgress !== this.lastProgress) {
        this.settings.onUpdate(newProgress / 10, this.player._duration);
        this.lastProgress = newProgress;
      }
    }

    this.drawBars(bars);

    if (this.player.playing()) {
      raf(this.drawBarsLoop.bind(this));
    }
  }

  createCanvas(width, height) {
    const newCanvas = document.createElement('canvas');
    newCanvas.width = width;
    newCanvas.height = height;
    return newCanvas;
  }

  drawBg() {
    const context = this.bgContext;
    // blank BG
    context.clearRect(0, 0, this.settings.canvasWidth, this.settings.canvasHeight);
    context.fillStyle = this.settings.bgColor;
    context.fillRect(0, 0, this.settings.canvasWidth, this.settings.canvasHeight);

    // Draw darker backgound in clip regions
    // IE doesn't support blend modes so emulate with darker bg
    if (this.clipStart !== null) {
      context.fillStyle = '#adb5c8';
      context.fillRect(this.clipStart, 0, this.clipEnd - this.clipStart, this.settings.canvasHeight);
    }

    // Waveform
    context.translate(0, this.settings.canvasHeight / 2);

    // Set position to middle of left side
    context.lineWidth = this.settings.lineWidth;

    const lineGap = (this.settings.canvasWidth / this.settings.nLines);

    let inClipRegion = false;
    context.beginPath();
    context.strokeStyle = this.settings.emptyBlockColor;
    for (let i = 0; i <= this.settings.nLines; i++) {
      const x = i * lineGap;
      const y = this.peaks[i] * this.scalingFactor;
      if (this.clipStart !== null && x > this.clipStart && x < this.clipEnd) {
        if (!inClipRegion) {
          // Just entered clip region, close previous path before beginning
          context.stroke();
          context.closePath();
          context.beginPath();
          context.strokeStyle = '#b5bdd0';
          inClipRegion = true;
        }
      } else if (this.clipStart !== null && x + context.lineWidth > this.clipEnd) {
        if (inClipRegion) {
          // Exiting clip region, draw bars as normal
          context.stroke();
          context.closePath();
          context.beginPath();
          context.strokeStyle = this.settings.emptyBlockColor;
          inClipRegion = false;
        }
      }

      context.moveTo(x + 0.5, y);
      context.lineTo(x + 0.5, (y * -1));
    }

    context.stroke();
    context.closePath();
    context.translate(0, -this.settings.canvasHeight / 2);
  }

  drawBars(lines) {
    const context = this.barsContext;
    context.clearRect(0, 0, this.settings.canvasWidth, this.settings.canvasHeight);
    context.translate(0, this.settings.canvasHeight / 2);
    context.lineWidth = this.settings.lineWidth;

    const lineGap = (this.settings.canvasWidth / this.settings.nLines);

    context.beginPath();
    context.strokeStyle = this.settings.filledBlockColor;

    for (let i = 0; i < Math.floor(lines); i++) {
      const x = i * lineGap;
      const y = this.peaks[i] * this.scalingFactor;
      if (this.clipStart !== null) {
        if (x < this.clipStart || x > this.clipEnd) {
          continue;
        }
      }
      context.moveTo(x + 0.5, y);
      context.lineTo(x + 0.5, (y * -1));
    }

    context.stroke();
    context.closePath();
    context.translate(0, -this.settings.canvasHeight / 2);

    // Draw last bar
    const x = Math.floor(lines) * lineGap;
    const xOffset = x + 0.5 - (context.lineWidth / 2);
    if (xOffset > this.clipStart || !this.clipStart) {
      const halfBar = this.peaks[Math.floor(lines)] * this.scalingFactor;
      const yOffset = (this.settings.canvasHeight / 2) - halfBar;
      const height = halfBar * 2;
      const width = (lines - Math.floor(lines)) * context.lineWidth + 0.5;
      context.fillStyle = this.settings.filledBlockColor;
      context.fillRect(xOffset, yOffset, width, height);
    }

    // Draw position line
    context.fillStyle = this.settings.locationLineColor;
    context.fillRect((lines * lineGap) - 1, 0, 0.5, this.settings.canvasHeight);
  }

  generateWaveformPeaks() {
    const leftChannel = this.bufferSource;
    const nLines = this.settings.nLines;
    const totalLength = leftChannel.length;
    const block = Math.floor(totalLength / (nLines * 10));
    const samples = [];
    let maxY = 0;

    // Sample 10X the nLines and average them
    for (let i = 0; i < nLines * 10; i++) {
      const audioBuffKey = Math.floor(block * i);
      const value = leftChannel[audioBuffKey];
      const y = Math.abs(value * this.settings.canvasHeight / 2);

      samples.push(y);
      // smooth previous samples
      if (i > 1) {
        samples[i - 1] = (samples[i - 1] + samples[i]) / 2;
      }
    }

    // Average every 10 samples and store maxVal
    const chunks = _.chunk(samples, 10);
    const peaks = chunks.map((chunk) => {
      const val = _.sum(chunk) / 10;
      if (val > maxY) maxY = val;
      return val;
    });

    this.peaks = peaks;

    // store a scaling factor so when bars are drawn they reach 90% of the canvas
    this.scalingFactor = 0.9 * (1 + ((this.settings.canvasHeight / 2) - maxY) / maxY);
  }

  generateRandomPeaks() {
    const bottomLimit = this.settings.canvasHeight * 0.1;
    const topLimit = this.settings.canvasHeight * 0.3;
    this.peaks = Array.from(new Array(this.settings.nLines), () => _.random(bottomLimit, topLimit));

    // No scaling required
    this.scalingFactor = 1;
  }
}

export default Wave;
