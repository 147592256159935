import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ChromePicker } from 'react-color';

class ColorPicker extends Component {
  static propTypes = {
    /** The initial color selected by the picker */
    initialColor: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    /** A handler for when the color is changed on the picker */
    onColorChange: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      displayColorPicker: false,
      color: null,
    };
  }

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  }

  handleClose = () => {
    this.setState({ displayColorPicker: false });
  }

  handleChange = (color) => {
    this.setState({ color: color.hex });
    if (this.props.onColorChange) {
      this.props.onColorChange(color);
    }
  }

  render() {
    const color = this.state.color || this.props.initialColor || '#3d85d1';
    const popover = {
      position: 'absolute',
      zIndex: '2',
    };
    const cover = {
      position: 'fixed',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    };
    const picker = (
      <div style={popover}>
        <div style={cover} onClick={this.handleClose} />
        <ChromePicker
          color={color}
          onChangeComplete={this.handleChange}
        />
      </div>
    );
    return (
      <div className="color-picker">
        <div className="swatch" onClick={this.handleClick}>
          <div className="swatch-color" style={{ backgroundColor: color }} />
        </div>
        { this.state.displayColorPicker ?
          picker
          : null }
      </div>
    );
  }
}

export default ColorPicker;
