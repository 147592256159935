import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { clamp } from 'lodash';
import moment from 'moment';

import { Player } from '../../components';
import Wave from './wave.js';


class PlayerContainer extends PureComponent {
  static propTypes = {
    /** The audio source */
    streamUrl: PropTypes.string.isRequired,
    /** Class name for the player */
    className: PropTypes.string,
    /** Options for the call recording player */
    options: PropTypes.object,
    /** Start and end percentages of the file */
    clipInfo: PropTypes.object,
    /** The width of the player */
    width: PropTypes.number,
  };

  static defaultProps = {
    options: {},
  };

  state = {
    bounds: {},
    duration: moment.utc(0).format('HH:mm:ss'),
    isPlay: false,
    isReplay: false,
    isLoading: true,
  };


  canvas = React.createRef();
  progress = React.createRef();


  componentDidMount() {
    const {
      options,
      clipInfo,
      streamUrl,
      finishTime,
    } = this.props;
    
    const canvasWidth = this.canvas.current.offsetWidth;
    
    this.setState({
      canvasWidth,
      finishTime: moment.utc(finishTime * 1000).format('HH:mm:ss'),
    });

    const waveOptions = {
      canvasWidth,
      initialClipPcts: clipInfo,
      onLoad: this.onLoad,
      onEnd: this.onSoundEnd,
      onUpdate: this.onSoundUpdate,
      onClipChange: this.onClipChange,
      onError: this.componentDidCatch,
      ...options,
    };

    this.wave = new Wave(
      streamUrl, 
      this.canvas.current, 
      waveOptions
    );

    window.addEventListener('keydown', this.keyDown, false);
  }


  keyDown = (e) => {
    if (e.path && e.path.some((tag) => tag.tagName === 'FORM')) {
      return;
    }

    const { progress } = this.state;

    switch (e.keyCode) {
      // Space
      case 32: {
        this.togglePlay();
        break;
      }

      // ArrowLeft
      case 37: {
        this.onProgressDrag(null, {
          x: progress - 25,
        });
        break;
      }

      // ArrowRight
      case 39: {
        this.onProgressDrag(null, {
          x: progress + 25,
        });
        break;
      }

      default:
        break;
    }
  };


  componentDidCatch = (error) => {
    this.setState({
      isLoading: false,
      errorMessage: error,
    });
  }

  
  componentWillUnmount() {
    if (this.wave) {
      this.wave.pause();
      this.wave.unload();
    }

    window.removeEventListener('keydown', this.keyDown, false);
  }

  onLoad = () => {
    this.setState({
      isLoading: false,
    });
  };


  onSoundEnd = () => {
    const { isReplay } = this.state;

    if (!isReplay) {
      this.setState({
        isPlay: false,
      });
    }
  };


  onSoundUpdate = (progress, duration) => {
    this.setState({
      progress: progress,
      duration: moment.utc(duration * 1000).format('HH:mm:ss'),
    });
  };

  
  togglePlay = () => {
    const { isPlay } = this.state;

    if (!this.wave.ready()) {
      return;
    }

    if (isPlay) {
      this.wave.pause();
    } else {
      this.wave.play();
    }

    this.setState((state) => ({
      isPlay: !state.isPlay,
    }));
  }

  onClipChange = (left, right) => {
    const { canvasWidth } = this.state;
    const { finishTime, onClipChange } = this.props;

    const bounds = {
      left,
      right,
    };

    const isClip = !(left === 0 && right === canvasWidth);

    this.setState({
      isClip,
      bounds,
      progress: left,
    });

    if (isClip) {
      onClipChange({
        start: left / canvasWidth * finishTime,
        end: right / canvasWidth * finishTime,
      });
    } else {
      onClipChange(null);
    }
  };


  onChangeVolume = (volume) => {
    this.wave.volume(volume);
  };


  onProgressDrag = (_, data) => {
    const { bounds, canvasWidth } = this.state;
    const progress = clamp(data.x, bounds.left, bounds.right);
    
    this.wave.seek(progress / canvasWidth);
    this.setState({
      progress,
    });
  };


  onClickProgressBar = (e) => {
    if (!this.wave.ready()) {
      return;
    }

    const { bounds, canvasWidth } = this.state;
    const rect = this.progress.current.getBoundingClientRect();
    const positionClick = e.clientX - rect.x;
    const progress = clamp(positionClick, bounds.left, bounds.right);

    this.wave.seek(progress / canvasWidth);
    this.setState({
      progress,
    });
  };


  onClickReplayButton = () => {
    const{ isReplay } = this.state;
    this.wave.loop(!isReplay);
    this.setState({
      isReplay: !isReplay,
    });
  }


  render() {
    const { clipInfo, streamUrl } = this.props;
    
    const {
      isClip,
      isPlay,
      bounds,
      duration,
      progress,
      isReplay,
      isLoading,
      finishTime,
      errorMessage,
    } = this.state;

    return (
      <Player
        canvasRef={this.canvas}
        progressRef={this.progress}
        isClip={isClip}
        isPlay={isPlay}
        bounds={bounds}
        duration={duration}
        progress={progress}
        clipInfo={clipInfo}
        isReplay={isReplay}
        streamUrl={streamUrl}
        isLoading={isLoading}
        finishTime={finishTime}
        errorMessage={this.props.errorMessage || errorMessage}
        togglePlay={this.togglePlay}
        onChangeVolume={this.onChangeVolume}
        onProgressDrag={this.onProgressDrag}
        onClickProgressBar={this.onClickProgressBar}
        onClickReplayButton={this.onClickReplayButton}
      />
    );
  }
}


export default PlayerContainer;
