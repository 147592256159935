import React from 'react';
import _ from 'lodash';
import type from 'type-of';

import AppData from 'app_data';

/**
 * Returns an ordered array containing true (up arrow) | null (none) | false (down arrow)
 * corresponding to the data array provided.
 * @param {Array} Previous array of objects that contain label keys
 * @param {Array} Next array of objects
 * @param {Array} Ordered array containing previous position mapping
 */
export function calculateTrend(prevData, nextData, trendLabels) {
  if (type(trendLabels) !== 'array') {
    trendLabels = new Array(nextData.length).fill(null);
  }

  const prevLabels = {};

  // build a map of previous keys with positions
  _.each(prevData, (row, index) => {
    prevLabels[row.label] = index;
  });

  // compare to previous results
  // trendLabels[index] will contain true (up), null, or false (down) for trend arrow
  _.each(nextData, (row, index) => {
    // check previous position in previous labels map
    const prevPosition = prevLabels[row.label];

    if (type(prevPosition) === 'undefined' || prevPosition > index) {
      trendLabels[index] = true;
    } else if (prevPosition < index) {
      trendLabels[index] = false;
    } else if (prevPosition === index) {
      // No change, reset to null
      trendLabels[index] = null;
    }
  });

  return trendLabels;
}

export default calculateTrend;
