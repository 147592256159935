import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actions } from 'react-redux-form';
import { push } from 'connected-react-router';
import { DefaultSpinner } from 'components';

import AppData from 'app_data';
import vex from 'lib/vex';
import * as QuestionsActions from 'actions/questions_actions';
import QuestionsCreateForm from './questions_create_form';


// TODO: need to add leaveRoute
class QuestionsCreate extends Component {

  static propTypes = {
    formActions: PropTypes.object,
    questionsActions: PropTypes.object,
    questionId: PropTypes.string,
    route: PropTypes.object,
    push: PropTypes.func,
  };

  state = {
    loading: true,
  };

  componentWillMount() {
    this.handleLoad();
  }

  handleLoad = () => {
    const { questionId } = this.props;
    const { formActions } = this.props;
    if (questionId) {
      AppData.getQuestionGroup(questionId).then((response) => {
        const { data } = response;
        formActions.reset('questions_group');
        formActions.change('questions_group', data);
        this.setState({ loading: false });
      });
    } else {
      this.setState({ loading: false });
    }
  };

  handleSave = (questionsGroup) => {
    const { questionId } = this.props;
    const params = Object.assign({}, questionsGroup);
    if (questionId) {
      params.id = questionId;
    }
    return AppData.saveQuestionGroup(params);
  };

  handleSuccessMessage = () => {
    const message = 'Question group successfully created.';
    vex.success(message, this.handleRedirect);
  };

  handleErrorMessage = (error) => {
    if (error.response) {
      const { errors } = error.response.data;
      vex.error(this.handleErrorParsing(errors));
    } else {
      vex.error([], null, error.message);
    }
  };

  handleErrorParsing = (errors) => {
    const parsedErrors = errors.map((error) => {
      const { description } = error;
      if (Array.isArray(description)) {
        return description;
      }
      return Object.keys(description).map((key) => {
        const questionNumber = parseInt(key, 10) + 1;
        const field = description[key];
        return Object.keys(field).map((nestedKey) => {
          const error = field[nestedKey];
          return `Question ${questionNumber}: ${nestedKey}: ${error}`;
        });
      });
    });
    return parsedErrors.reduce((a, b) => a.concat(b), []);
  };

  handleAddGroup = (questionsGroup) => {
    const { questionsActions } = this.props;
    this.handleSave(questionsGroup).then(() => {
      questionsActions.addGroup(questionsGroup);
      this.handleSuccessMessage();
    }).catch((error) => {
      this.handleErrorMessage(error);
    });
  };

  handleSubmit = (val) => {
    this.handleAddGroup(val);
  };

  handleRedirect = () => {
    const { formActions, push } = this.props;
    formActions.reset('questions_group');
    push('/settings/call-rating');
  };

  render() {
    if (this.state.loading) {
      return (
        <DefaultSpinner />
      );
    }
    return (
      <QuestionsCreateForm
        onSubmit={this.handleSubmit}
        onCancel={this.handleRedirect}
      />
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  questionsActions: bindActionCreators(QuestionsActions, dispatch),
  formActions: bindActionCreators(actions, dispatch),
  push: bindActionCreators(push, dispatch),
});

export default connect(null, mapDispatchToProps)(QuestionsCreate);
