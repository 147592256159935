

import keyMirror from 'keymirror';

// Prefix all constants with page name to avoid collisions

const AppConstants = keyMirror({
  USER_LOGIN_SUCCESS: null,
  USER_LOGIN_FAILURE: null,
  CUSTOM_RESET_STATE: null,
  CUSTOM_PARSE_TREE: null,
  CUSTOM_ENABLE_FILTER: null,
  CUSTOM_DISABLE_FILTER: null,
  CUSTOM_ENABLE_ALL: null,
  CUSTOM_DISABLE_ALL: null,
  CUSTOM_SET_DATES: null,
  CUSTOM_SET_TITLE: null,
  CUSTOM_SET_STATUS: null,
  CUSTOM_SET_REAL_TIME: null,
  CUSTOM_SET_BUCKETS: null,
  CUSTOM_SET_TIME_RANGES: null,
  CUSTOM_SET_TEAM_TYPE: null,
  CUSTOM_LOAD_REPORT: null,
  CUSTOM_LOAD_REPORT_SUCCESS: null,
  CUSTOM_UPDATE_CHART: null,
  CUSTOM_UPDATE_TREE_PATH: null,
  CUSTOM_VALIDATE: null,
  CUSTOM_CLEAR: null,
  CONTROLS_GET_METADATA: null,
  CONTROLS_GET_REPORTING_METADATA: null,
  CONTROLS_GET_ANALYTICS_METADATA: null,
  CONTROLS_SET_TIME_RANGE: null,
  CONTROLS_SET_TIME_RANGES: null,
  CONTROLS_SET_BOARD_TYPE: null,
  CONTROLS_SET_BOARD_THEME: null,
  CONTROLS_SET_CALL_FILTER: null,
  CONTROLS_SET_CALL_TYPE: null,
  CONTROLS_SET_CALL_TYPES: null,
  CONTROLS_SET_FILTERS: null,
  CONTROLS_SET_FILTER_OPTIONS: null,
  CONTROLS_SET_FILTER_TYPE: null,
  CONTROLS_SET_FILTER_TYPE_AND_OPTIONS: null,
  CONTROLS_LOAD_STATE: null,
  CONTROLS_SET_INACTIVE: null,
  CONTROLS_SET_PERIOD: null,
  CONTROLS_SET_GROUPING: null,
  CONTROLS_SET_REAL_TIME: null,
  CONTROLS_SET_MINUTE_BUCKETS: null,
  CONTROLS_SET_USER_COUNT: null,
  CONTROLS_SET_TOTAL_USERS: null,
  TEAM_CHOOSE_TYPE: null,
  TEAM_LOAD_TEAM: null,
  TEAM_CLEAR: null,
  TEAM_ENABLE_ALL: null,
  TEAM_DISABLE_ALL: null,
  TEAM_ENABLE_FILTER: null,
  TEAM_DISABLE_FILTER: null,
  TEAM_SET_STATUS: null,
  TEAM_SET_TITLE: null,
  TEAM_SET_DESCRIPTION: null,
  TEAM_VALIDATE: null,
  CUSTOM_DASHBOARD_CLEAR: null,
  CUSTOM_DASHBOARD_SET_TITLE: null,
  CUSTOM_DASHBOARD_SET_DESCRIPTION: null,
  CUSTOM_DASHBOARD_SET_TIME_RANGE: null,
  CUSTOM_DASHBOARD_SET_REAL_TIME: null,
  CUSTOM_DASHBOARD_SET_STATUS: null,
  CUSTOM_DASHBOARD_VALIDATE: null,
  CUSTOM_DASHBOARD_ADD_TILE: null,
  CUSTOM_DASHBOARD_REMOVE_TILE: null,
  CUSTOM_DASHBOARD_SET_TILE: null,
  CUSTOM_DASHBOARD_UPDATE_TILE: null,
  CUSTOM_DASHBOARD_EDIT_TILE: null,
  CUSTOM_DASHBOARD_LAYOUT_CHANGE: null,
  CUSTOM_DASHBOARD_BREAKPOINT_CHANGE: null,
  CUSTOM_DASHBOARD_LOAD_DASHBOARD_REQUEST: null,
  CUSTOM_DASHBOARD_LOAD_DASHBOARD_SUCCESS: null,
  CUSTOM_DASHBOARD_LOAD_DASHBOARD_FAILURE: null,
  CUSTOM_DASHBOARD_SAVE_DASHBOARD_REQUEST: null,
  CUSTOM_DASHBOARD_SAVE_DASHBOARD_SUCCESS: null,
  CUSTOM_DASHBOARD_SAVE_DASHBOARD_FAILURE: null,
  CUSTOM_DASHBOARD_CANCEL: null,
  CUSTOM_DASHBOARD_START_NEW_DASHBOARD: null,
  CUSTOM_DASHBOARD_SET_OPTIONS_PAGE: null,
  CUSTOM_QUERY_SET_TIME_RANGE: null,
  CUSTOM_QUERY_SET_REAL_TIME: null,
  CUSTOM_QUERY_CLEAR: null,
  CUSTOM_QUERY_SET_STATUS: null,
  CUSTOM_QUERY_SET_TITLE: null,
  CUSTOM_QUERY_SET_DESCRIPTION: null,
  CUSTOM_QUERY_SET_CURRENT_OPTION: null,
  CUSTOM_QUERY_SET_METRIC: null,
  CUSTOM_QUERY_SET_AGG: null,
  CUSTOM_QUERY_ADD_MEASURE: null,
  CUSTOM_QUERY_SET_RESULTS: null,
  CUSTOM_QUERY_SET_GROUP: null,
  CUSTOM_QUERY_SET_CHART: null,
  CUSTOM_QUERY_SET_FILTER_TYPE: null,
  CUSTOM_QUERY_CLEAR_QUERY: null,
  CUSTOM_QUERY_VALIDATE: null,
  CUSTOM_QUERY_SAVE: null,
  CUSTOM_QUERY_LOAD_QUERY: null,
  CUSTOM_QUERY_REMOVE_MEASURE: null,
  CUSTOM_QUERY_REMOVE_GROUP: null,
  CUSTOM_QUERY_SET_SEARCH_VALUE: null,
  CUSTOM_QUERY_ENABLE_FILTER: null,
  CUSTOM_QUERY_DISABLE_FILTER: null,
  CUSTOM_QUERY_ENABLE_FILTERS: null,
  CUSTOM_QUERY_DISABLE_FILTERS: null,
  CUSTOM_QUERY_ENABLE_ALL: null,
  CUSTOM_QUERY_DISABLE_ALL: null,
  CUSTOM_QUERY_LOAD_AVAILABLE_FILTERS: null,
  CUSTOM_QUERY_SET_INTERVAL: null,
  CUSTOM_QUERY_REMOVE_INTERVAL: null,
  CUSTOM_QUERY_SET_CHART_HEIGHT: null,
  USER_SET_USER_ID: null,
  USER_SET_DISPLAY_NAME: null,
  USER_SET_AVATAR: null,
  USER_LOAD_USER: null,
  USER_CLEAR: null,
  USER_STORE: null,
  QUESTIONS_ADD: null,
});

export default AppConstants;
