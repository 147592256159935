const Distribution = function (ui) {
  this.ui = ui;
  this.params = { ui };
  const params = this.params;

  this.periodSummary = () => {
    params.rand = this.aggregateRand;

    if (this.getSelects(1).length) {
      params.tableLabels = ['User', 'Call Type'];
      params.selects = this.getSelects(0);
      params.xGrouping = this.getUsers(5);
    }
  };

  this.dailyAverage = () => {
    this.periodSummary();
    params.rand = this.averageRand;
  };

  this.hourlyAverage = () => {
    params.rand = this.averageRand;
    params.xGrouping = this.generateHours();
    if (this.buckets) {
      params.selects = this.getBuckets();
    } else {
      params.selects = this.getSelects(0);
    }
  };
};

export default Distribution;
