import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { themr } from '@nextiva/analytics-lib';
import { DateRangePicker } from '@nextiva/analytics-ui';
import { DateRangePeriod } from 'components';

import Styles from './DateRanges.module.scss';


function DateRanges(props) {
  const {
    theme,
    timezone,
    timeRanges,
    onChange,
    onRealTimeChange,
    isRealTime = false,
    disabled = false,
    withRealTimeIcon = true,
  } = props;

  const onChangeDateRange = (timeRange) => {
    onChange(Array.isArray(timeRange) ? timeRange : [timeRange]);
  };

  return (
    <div className={theme.dateRange}>
      {timeRanges.length === 1
        ? (
         <DateRangePicker
            disabled={disabled}
            withRealTimeIcon={withRealTimeIcon}
            className="date-range-popover"
            isRealTime={isRealTime}
            timezone={timezone}
            dateRange={timeRanges[0]}
            onChange={onChangeDateRange}
            onRealTimeChange={onRealTimeChange}
            theme={theme}
          />
        ) : (
          <DateRangePeriod
            timezone={timezone}
            timeRanges={timeRanges}
            onChange={onChangeDateRange}
            theme={theme}
          />
        )
      }
    </div>
  );
}


DateRanges.propTypes = {
  theme: PropTypes.object,
  isRealTime: PropTypes.bool,
  timezone: PropTypes.string.isRequired,
  timeRanges: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  onRealTimeChange: PropTypes.func,
};


export default memo(themr(DateRanges, Styles));
