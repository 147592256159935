import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import _ from 'lodash';
import Collapse from 'react-collapse';
import { presets } from 'react-motion';

import InlinveSvgFactory from 'components/inline_svg';

import Styles from './Collapse.module.scss';

const Arrow = InlinveSvgFactory('arrow.svg');


class CollapseComponent extends Component {
  static propTypes = {
    /** Whether or not it is initially collapsed */
    initialCollapsed: PropTypes.bool,
    /** The className of the element */
    className: PropTypes.string,
    /** Whether or not to disable the animation */
    disableAnimation: PropTypes.bool,
    /** The animation delay in miliseconds*/
    animationDelay: PropTypes.number,
    /** The class name of the collapse arrow icon */
    arrowClass: PropTypes.string,
    /** Content for static header */
    header: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node,
    ]),
    /** Additional content for the collapse */
    children: PropTypes.node.isRequired,
  };

  state = {
    collapsed: this.props.initialCollapsed || false,
  };

  id = _.uniqueId();

  toggleCollapse = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  render() {
    const { header, disableAnimation, className } = this.props;

    return (
      <div className={Styles.collapse}>
        <div className="collapsible__header">
          {header}
        </div>
        <Collapse
          id={`collapse-${this.id}`}
          className={cx('collapseable', {
            [className]: className,
            'collapseable__open': !this.state.collapsed,
          })}
          isOpened={!this.state.collapsed}
          hasNestedCollapse={disableAnimation}
          springConfig={presets.noWobble}
        >
          <div ref={(node) => { this.content = node; }} className="collapsible__content">
            {this.props.children}
          </div>
        </Collapse>
        <Arrow
          onClick={this.toggleCollapse}
          className={cx({
            'collapse-button': true,
            [this.props.arrowClass]: true,
            closed: !this.state.collapsed,
          })}
        />
      </div>
    );
  }
}

export default CollapseComponent;
