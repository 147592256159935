import { createSelector } from 'reselect';
import { NAME } from './constants';

export const getAll = (state) => {
  const result = state[NAME].questions;
  return result.map((id) => state[NAME].entities.questionGroups[id]);
};

export const getQuestionGroups = (state) => state[NAME].entities.questionGroups;
export const getQuestions = (state) => state[NAME].entities.questions;
export const getQuestionResponses = (state) => {
  const result = state[NAME].responses;
  return result.map((id) => state[NAME].entities.questionResponses[id]);
};

export const questionsLoading = (state) => state[NAME].questionsLoading;
export const responsesLoading = (state) => state[NAME].responsesLoading;
