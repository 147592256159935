import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import Utils from 'utils/common_utils';
import Theme from 'theme';
import BaseChartMixin from 'components/charts/chart_mixins';

// charts/charts.scss

class ScatterChart extends Component {
  static propTypes = {
    /** The title of the chart */
    exportTitle: PropTypes.string,
    /** Optional: If set it will hardcode a chart height */
    height: PropTypes.string,
    /** A unique identifier for the chart being displayed */
    chartKey: PropTypes.string,
    /** The data to be displayed on the chart */
    chartData: PropTypes.object,
    /** The kind of unit that the chart is using on the value axis */
    unit: PropTypes.string,
    /** The time range that the chart will represent */
    timeRange: PropTypes.object,
    /** Whether or not download is disabled */
    disableDownload: PropTypes.bool,
    /** A method to update the chart during a drill-down */
    updateChartData: PropTypes.func,
    /** Whether or not the chart is drilling-down */
    isDrillingDown: PropTypes.bool,
    /** Whether or not the chart is in the second level of drilling-down */
    isSecondLevel: PropTypes.bool,
    /** The time zone of the chart */
    timeZone: PropTypes.string,
    /** The location of the legend ('top' 'bottom' or 'none') */
    legendPosition: PropTypes.string,
    /** Function for determining legend position based on props */
    getLegendPosition: PropTypes.func,
    /** A handler for when a drill-down node is selected to open the call recording modal */
    triggerCallRecordingModal: PropTypes.func,
  };

  graphData = () => {
    this.chart.dataProvider = [];
    this.chart.graphs = [];
    const { meta, data } = this.props.chartData;
    let hoverEnabled = false;

    if (this.props.updateChartData) {
      hoverEnabled = true;
    }

    _.each(meta.items, (item) => {
      this.chart.graphs.push({
        title: item,
        balloonFunction: this.defaultBalloon,
        bullet: 'circle',
        lineAlpha: 0,
        fillAlphas: 0,
        lineColor: Theme.getColor(item),
        xField: meta.categoryField,
        yField: item,
        showHandOnHover: hoverEnabled,
      });
    });

    _.each(data, (d) => {
      this.chart.dataProvider.push(d);
    });
  };

  buildChart = () => {
    const { start, end } = Utils.getStartEndDates(this.props.timeRange);
    this.chart = new window.AmCharts.AmXYChart();
    this.chart.autoMargins = true;
    this.chart.fontFamily = 'lato';
    this.chart.fontSize = 14;
    this.enableExport(this.props.exportTitle);

    let minDate = this.props.chartData.meta.date_format ? start.subtract(1, 'days').toDate() : undefined;
    let maxDate = this.props.chartData.meta.date_format ? end.add(1, 'days').toDate() : undefined;
    let minPeriod = 'DD';
    let title_y = '';
    let title_x = '';
    if (this.props.isDrillingDown) {
      const { start, end } = Utils.getStartEndDates(this.props.timeRange);
      if (this.props.isSecondLevel) {
        minDate = start.subtract(5, 'minutes').toDate();
        maxDate = end.add(5, 'minutes').toDate();
        minPeriod = 'mm';
        title_y = 'Minutes';
        title_x = 'Start Time';
      } else {
        minDate = start.subtract(1, 'hours');
        maxDate = end;
        minPeriod = 'L';
        title_y = 'Number of Calls';
      }
    }

    this.chart.addListener('clickGraphItem', (event) => {
      if (this.props.updateChartData) {
        const callType = event.item.graph.title;
        if (!this.props.isDrillingDown) {
          const date = event.item.dataContext.category;
          this.props.updateChartData(callType, date);
        } else if (this.props.isDrillingDown && !this.props.isSecondLevel) {
          const selectedDateTime = event.item.dataContext.category;
          const isSecondLevel = true;
          this.props.updateChartData(callType, selectedDateTime, isSecondLevel);
        } else if (this.props.isDrillingDown && this.props.isSecondLevel) {
          const callRecordingInfo = event.item.dataContext.rowData;
          this.props.triggerCallRecordingModal(callRecordingInfo);
        }
      }
    });

    if (this.props.chartData.meta.date_format) {
      this.chart.dataDateFormat = this.props.chartData.meta.date_format;
    }

    this.chart.categoryField = this.props.chartData.meta.categoryField;
    this.chart.balloon = {
      adjustBorderColor: false,
      shadowAlpha: 0,
    };
    this.chart.chartCursor = { cursorColor: '#3D85D1' };

    this.chart.legend = {
      enabled: _.isUndefined(this.props.legendPosition) || this.props.legendPosition !== 'none',
      position: this.props.getLegendPosition(),
      autoMargins: false,
      borderAlpha: 0,
      equalWidths: false,
      markerSize: 10,
      valueAlign: 'left',
      valueWidth: 0,
      divId: `${this.props.chartKey}Legend`,
    };

    this.chart.valueAxes = [
      {
        position: 'bottom',
        axisAlpha: 0,
        type: this.props.chartData.meta.x_type ? this.props.chartData.meta.x_type : 'numeric',
        dashLength: 1,
        minPeriod,
        minimumDate: minDate,
        maximumDate: maxDate,
        centerLabelOnFullPeriod: false,
        labelRotation: 45,
        equalSpacing: false,
        markPeriodChange: false,
        title: title_x,
        titleBold: false,
        dateFormats: [
          { period: 'fff', format: 'L:NN:SS A' },
          { period: 'ss', format: 'L:NN:SS A' },
          { period: 'mm', format: 'L:NN A' },
          { period: 'hh', format: 'LA' },
          { period: 'DD', format: 'MMM DD' },
          { period: 'WW', format: 'MMM DD' },
          { period: 'MM', format: 'MMM' },
          { period: 'YYYY', format: 'YYYY' },
        ],
      },
      {
        axisAlpha: 0,
        dashLength: 1,
        position: 'left',
        title: title_y,
        titleBold: false,
        type: this.props.chartData.meta.y_type ? this.props.chartData.meta.y_type : 'numeric',
        dateFormats: [
          { period: 'fff', format: 'L:NN:SS A' },
          { period: 'ss', format: 'L:NN:SS A' },
          { period: 'mm', format: 'L:NN A' },
          { period: 'hh', format: 'L:NN A' },
          { period: 'DD', format: 'MMM DD' },
          { period: 'WW', format: 'MMM DD' },
          { period: 'MM', format: 'MMM' },
          { period: 'YYYY', format: 'YYYY' },
        ],
      },
    ];
    if (this.props.unit === 'minutes') {
      this.chart.valueAxes[1].labelFunction = (seconds) => {
        const minutes = Utils.numberWithCommas(Math.floor(seconds / 60));
        return minutes;
      };
    }

    this.graphData();
    this.chart.write(this.props.chartKey);
  }

  defaultBalloon = (dataItem, config) => {
    const { dataContext } = dataItem;
    const { categoryField } = this.props.chartData.meta;
    const category = dataContext[categoryField];
    let yValue = dataContext[config.yField];

    // when hide/show legend item, need to replace title
    const visibleCharts = config.chart.graphs
      .filter(graph => !graph.hidden)
      .map(graph => graph.title);

    // when the value the same with other values
    const title = Object.keys(dataContext)
      .filter(key =>
        dataContext[key] === yValue &&
        key !== categoryField &&
        visibleCharts.includes(key)
      )
      .join(', ');

    if (this.props.unit === 'minutes') {
      yValue = Utils.humanizeSeconds(yValue);
    }

    return `<b>${title}</b><br/>${category}<br/>${yValue}`;
  };

  render() {
    const style = {};
    if (this.props.height) {
      style.height = this.props.height;
    }

    return (
      <div
        className="scatterchart"
        id={this.props.chartKey}
        style={style}
      />
    );
  }
}

export default BaseChartMixin(ScatterChart);
