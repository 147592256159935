import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';


const CustomDashboardTile = (props) => {
  const {
    children,
    className,
    ...otherProps
  } = props;
  const tileClasses = cx('custom-dashboard__tile', className);
  return (
    <div className={tileClasses} {...otherProps}>
      {children}
    </div>
  );
};

CustomDashboardTile.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default CustomDashboardTile;
