import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import FlipCounter from 'components/flip_counter';

/* Disable because react-flip-move puts refs on its children */
/* eslint-disable react/prefer-stateless-function */
class LeaderboardRow extends Component {
  static propTypes = {
    arrow: PropTypes.bool,
    place: PropTypes.number,
    suffix: PropTypes.string,
    width: PropTypes.number,
    displayType: PropTypes.string,
    name: PropTypes.string,
    userAvatar: PropTypes.string,
    avatarStyle: PropTypes.string,
    value: PropTypes.number,
  };

  render() {
    let placeContainer;
    let userAvatarContent;

    if (this.props.userAvatar) {
      userAvatarContent = (
        <div className="circle leaderboard-row-avatar">
          <img alt={`Avatar for ${this.props.name}`} src={this.props.userAvatar} />
        </div>
      );
    }
    const placeContent = (
      <div className={cx({ circle: true, blue: this.props.place === 1 })}>
        {this.props.place}<sup>{this.props.suffix}</sup>
      </div>
    );
    if (this.props.avatarStyle && this.props.userAvatar) {
      placeContainer = (
        <div className={cx({ 'place-container': true, [`${this.props.avatarStyle}`]: true })}>
          <div className={cx({ 'place-content': true, 'place-content-lg': this.props.place.toString().length > 2 })}>
            {placeContent}
          </div>
          <div className="place-avatar">
            {userAvatarContent}
          </div>
        </div>
      );
    } else {
      placeContainer = (
        <div className="place-container-no-avatar">
          <div className={cx({ 'place-content': true, 'place-content-lg': this.props.place.toString().length > 2 })}>
            {placeContent}
          </div>
        </div>
      );
    }
    return (
      <div className="leaderboard-row">
        {placeContainer}
        <div className="leaderboard-row-wrapper">
          <div className="leaderboard-row-content" style={{ width: `${this.props.width}%` }}>
            <div className="leaderboard-row-name">
              {this.props.name}
            </div>
            <div className="leaderboard-row-stats">
              <div className="border-icon">
                {this.props.arrow !== null ?
                  <span
                    className={
                      cx({ 'border-icon-content': true, 'border-icon-content-down': !this.props.arrow })
                    }
                  />
                : null}
                <div className="divider" />
              </div>
              <FlipCounter displayType={this.props.displayType} value={this.props.value} />
            </div>
            <div className="leaderboard-row-border" />
          </div>
        </div>
      </div>
    );
  }
}

export default LeaderboardRow;
