import moment from 'moment';

import AppData from 'app_data';

export const NAME = 'scheduled_report';
export const SECTIONS = [
  { key: 'schedule', title: 'Schedule' },
  {
    key: 'reporting',
    title: 'Reporting period',
    tooltip: 'Set the start date and end date for when your report will run.',
  },
  {
    key: 'distribution',
    title: 'Distribution',
    tooltip: 'Set frequency and time of day for when the report will be emailed to recipients.',
  },
  { key: 'delivery', title: 'Delivery' },
];
export const INITIAL_STATE = {
  scheduleName: '',
  email: '',
  reportId: null,
  timezone: '',
  period: 'fixed',
  excludeToday: false,
  frequency: 'daily',
  interval: 1,
  unit: 'days',
  quantity: 1,
  run: { value: 'forever', hours: '11', minutes: '59', meridiem: 'pm' },
  start: { value: moment(), hours: '12', minutes: '00', meridiem: 'am' },
  end: { value: moment(), hours: '11', minutes: '59', meridiem: 'pm' },
  distributeStart: { value: moment(), hours: '12', minutes: '00', meridiem: 'am' },
  distributeEnd: { value: moment(), hours: '11', minutes: '59', meridiem: 'pm' },
  times: [
    { hours: '11', minutes: '59', meridiem: 'pm' },
  ],
  relativeWeekdays: {
    mon: false,
    tue: false,
    wed: false,
    thu: false,
    fri: false,
    sat: false,
    sun: false,
  },
  distributeWeekdays: {
    mon: false,
    tue: false,
    wed: false,
    thu: false,
    fri: false,
    sat: false,
    sun: false,
  },
  monthly: {
    dist: 'day',
    month: 1,
    num: 1,
    weekday: 'mon',
  },
};
