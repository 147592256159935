
export default (userData) => {
  const {
    last_name: lastName,
    first_name: firstName,
    nextos_user_id: userName,
  } = userData;

  let displayName = userName;

  if (firstName) {
    displayName = lastName
      ? `${firstName} ${lastName}`
      : firstName;
  }

  return displayName;
};
