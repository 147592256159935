import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { DateRangePicker } from '@nextiva/analytics-ui';

import MultiSelect from 'components/multi_select';
import CallFilters from 'components/call_filters';
import ButtonPopover from 'components/button_popover';
import TabbedControls from 'components/tabbed_controls';
import { bindControlsActions } from 'actions/controls_actions';
import * as selectors from 'modules/reporting';

const mapStateToProps = (state) => {
  const reportingState = selectors.reportingSelector(state);
  return {
    filterType: reportingState.filterType,
    filters: reportingState.filters,
    filterOptions: reportingState.filterOptions,
    callTypes: reportingState.callTypes,
    callTypeOptions: reportingState.callTypeOptions,
    realTime: reportingState.realTime,
    timeRange: reportingState.timeRange,
    timezone: reportingState.timezone,
    section: reportingState.section,
    metadata: reportingState.metadata,
    callFilter: reportingState.callFilter,
    callFilterOptions: reportingState.callFilterOptions,
    apiFilters: reportingState.apiFilters,
  };
};

class ReportControls extends Component {
  static propTypes = {
    /** Current filter type */
    filterType: PropTypes.object,
    /** Selected filters */
    filters: PropTypes.array,
    /** Availble filters */
    filterOptions: PropTypes.array,
    /** Are we in real time mode? */
    realTime: PropTypes.bool,
    /** Time range for the section */
    timeRange: PropTypes.object,
    /** User timezone */
    timezone: PropTypes.string,
    /** The current section of the current page */
    section: PropTypes.string,
    /** Metadata for the reporting section */
    metadata: PropTypes.object,
    /** Current call filter */
    callFilter: PropTypes.string,
    /** Available call filters */
    callFilterOptions: PropTypes.array,
    /** API Filters */
    apiFilters: PropTypes.object,
  }

  componentWillMount() {
    this.actions = bindControlsActions('reporting');
  }

  render() {
    const {
      filterType,
      filters,
      filterOptions,
      realTime,
      timeRange,
      timezone,
      section,
      metadata,
      callFilter,
      callFilterOptions,
      apiFilters,
    } = this.props;
    let buttonPopover;

    if (filterOptions.length) {
      const filtersSelect = (
        <MultiSelect
          searchBar
          options={filterOptions}
          onChange={this.actions.setFilters}
          initialSelected={filters}
        />
      );
      const tabbedControls = (
        <TabbedControls
          tabs={[
            { icon: 'icon-custom-filter', label: filterType.id, content: filtersSelect },
          ]}
        />
      );
      buttonPopover = (
        <ButtonPopover
          popoverClass="controls-popover report-controls-popover"
          iconClass="icon-custom-settings"
          popoverBody={tabbedControls}
        />
      );
    }

    return (
      <div className="report-controls">
        <div className="report-controls__left">
          <DateRangePicker
            withRealTimeIcon
            timezone={timezone}
            dateRange={timeRange}
            isRealTime={realTime}
            className="date-range-popover"
            onChange={this.actions.setTimeRange}
            onRealTimeChange={this.actions.setRealTime}
            theme={{
              dateRangePicker: 'date-range',
            }}
          />
          {buttonPopover}
        </div>

        <CallFilters
          timeRange={timeRange}
          realTime={realTime}
          filterTotalsUrl={metadata.filter_totals_url}
          section={section}
          filter={callFilter}
          filterOptions={callFilterOptions}
          filters={apiFilters}
        />
      </div>
    );
  }
}

export default connect(mapStateToProps)(ReportControls);
