import React from 'react';
import PropTypes from 'prop-types';
import QualityCallsTableContainer from './component/Quality-Calls-Table/QualityCallsTableContainer';
import AppData from 'app_data';
import moment from 'moment'
import { OptionsSource } from './constants';
const excludeInternalOptionSource = ['summary', 'location', 'phone-number'];
class QualityCalls extends React.Component {
  static propTypes = {
    actions: PropTypes.object,
    state: PropTypes.object,
  };

  getTimeRange = () => {
    const { timeRange, timeRanges } = this.props.state;

    if (timeRange) {
      return timeRange;
    }

    const starts = timeRanges.map((timeRange) =>
      moment.utc(timeRange.start)
    );

    const ends = timeRanges.map((timeRange) =>
      moment.utc(timeRange.end)
    );

    return {
      type: 'absolute',
      start: moment.min(starts).tz(AppData.user.timezone).toJSON(),
      end: moment.max(ends).tz(AppData.user.timezone).toJSON(),
    };
  };
  getOptionSource = () => {
    const { activeTab } = this.props;

    if (excludeInternalOptionSource.includes(activeTab)) {
      return OptionsSource.filter((option) => option.value !== 'internal');
    }

    return OptionsSource;
  };
  render() {
    const { state, actions } = this.props;
    const {
      filters,
      realTime,
      callLogUrl,
      callLogFields,
    } = state;

    return (
      <div className="analytics-quality-calls">
        <QualityCallsTableContainer
          pagination
          url={'nextos/reports/quality/call-log/calls'}
          filters={filters}
          realTime={realTime}
          selects={[...callLogFields]}
          timeRange={this.getTimeRange()}
          errorClassName="error-placeholder"
          filterOptions={this.getOptionSource()}
        />
      </div>
    );
  }
}

export default QualityCalls;
