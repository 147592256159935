import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { 
  Button,
  Popover,
  DropdownButton,
} from '@nextiva/next-ui';

import ExportDataModal from '../ExportDataModal';

import Styles from './AdditionalButtons.module.scss';


const AdditionalButtons = memo((props) => {
  const {
    options,
    sourceType, 
    onClickExport,
    onChangeSourceType, 
  } = props;

  return (
    <>
      <DropdownButton
        kind="success"
        options={options}
        className={Styles.sourceType}
        onChange={onChangeSourceType}
      >
        {sourceType.label}
      </DropdownButton>

      <Popover
        popper={{ placement: 'left' }}
        body={({ togglePopover }) => (
          <ExportDataModal
            onCancel={togglePopover}
            onExport={onClickExport}
          />
        )}
      >
        <Button kind="primary">Export</Button>
      </Popover>
    </>
  )
});


AdditionalButtons.displayName = 'AdditionalButtons';
AdditionalButtons.propTypes = {
  sourceType: PropTypes.object, 
  onClickExport: PropTypes.func,
  onChangeSourceType: PropTypes.func,
};

export default AdditionalButtons;
