import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import Utils from 'utils/common_utils';
import BaseChartMixin from 'components/charts/chart_mixins';
import Theme from 'theme';

// charts/charts.scss

class LineChart extends Component {
  static propTypes = {
    /** The title of the chart */
    exportTitle: PropTypes.string,
    /** Optional: Will hardcode a chart height */
    height: PropTypes.string,
    /** Whether or not an update is forced */
    forceRender: PropTypes.bool,
    /** A unique identifier for the chart being displayed */
    chartKey: PropTypes.string,
    /** The data to be displayed on the chart */
    chartData: PropTypes.object,
    /** The kind of unit that the chart is using on the value axis (calls, minutes, etc..) */
    unit: PropTypes.string,
    /** The position of the legend */
    legendPosition: PropTypes.string,
    /** An array of the items in the legend */
    enabledLegendItems: PropTypes.array,
    /** The time range that the chart will represent */
    timeRange: PropTypes.object,
    /** Whether or not download is disabled */
    disableDownload: PropTypes.bool,
    /** The type of line chart being rendered */
    lineChartType: PropTypes.string,
    /** Whether or not the line is smooth (rounded) */
    lineSmooth: PropTypes.bool,
    /** Whether or not the chart is a stacked line chart */
    stacked: PropTypes.bool,
    /** Helper function for formatting seconds for balloon values */
    formatSeconds: PropTypes.func,
    /** Function for determining legend position based on props */
    getLegendPosition: PropTypes.func,
    /** Configuration options for linechart */
    lineConfig: PropTypes.shape({
      /** Whether or not the legend of the chart is enabled */
      legendEnabled: PropTypes.bool,
      /** Whether or not the chart is responsive */
      responsive: PropTypes.bool,
    }),
  };

  graphData = () => {
    const data = this.props.chartData;
    const chartType = this.props.lineChartType;
    let balloonText;

    this.chart.graphs = [];
    this.chart.dataProvider = [];

    this.chart.dataDateFormat = this.props.chartData.meta.date_format;

    this.chart.categoryAxis = {
      parseDates: this.props.chartData.meta.date_format,
      axisAlpha: 0,
      gridAlpha: 0,
      tickLength: 0,
      boldPeriodBeginning: false,
      equalSpacing: true,
      startOnAxis: true,
      markPeriodChange: false,
      fontSize: this.props.chartData.meta.categoryFontSize,
      color: this.props.chartData.meta.categoryFontColor,
      labelFunction: chartType === 'period' ? this.periodLabels : false,
    };

    // greater than or equal to 8 graph items, rotate label
    if (this.props.chartData.data.length >= 8) {
      this.chart.categoryAxis.labelRotation = 45;
    }

    if (chartType === 'trend') {
      balloonText = `<b><span style='font-size:14px;'>[[value]]<br /> [[title]] ${this.props.unit} </span></b>`;
    } else {
      balloonText = `[[category]]<br/><b><span style='font-size:14px;'>[[value]]<br /> [[title]] ${this.props.unit} </span></b>`;
    }
    _.forEach(data.meta.items, (item) => {
      this.chart.graphs.push({  
        balloonText,
        balloonFunction: this.props.unit === 'minutes' && this.props.formatSeconds || null,
        bulletBorderAlpha: 1,
        bulletColor: data.meta.bullet_color ? Theme.getColor(item) : '#FFFFFF',
        bullet: data.meta.bullet_type ? data.meta.bullet_type : undefined,
        title: data.meta.titles ? data.meta.titles[item] : item,
        valueField: item,
        lineColor: Theme.getColor(item),
        type: this.props.lineSmooth ? 'smoothedLine' : 'line',
        lineThickness: data.meta.lineThickness ? data.meta.lineThickness : 1,
        lineAlpha: data.meta.line_alpha ? 0 : 1,
        fillAlphas: this.props.stacked ? 0.6 : 0,
        useLineColorForBulletBorder: true,
        hidden: this.props.enabledLegendItems ? !_.includes(this.props.enabledLegendItems, item.replace('P1: ', '').replace('P2: ', '')) : false, // FIX THIS Replace is horible
      });
    });
    _.forEach(data.data, (d) => {
      this.chart.dataProvider.push(d);
    });
    if(this.props.exportTitle === 'Daily MOS'){
      const threshold = [
          {
            fillAlpha:0,
            value:3.5,
            lineColor:Theme.getColor('moderate'),
            lineAlpha:1,
            dashLength:5,
            inside:true,
            above:true,
            lineThickness:2,
          },
          {
            fillAlpha:0,
            value:3.8,
            lineColor:Theme.getColor('good'),
            lineAlpha:1,
            dashLength:5,
            inside:true,
            above:true,
            lineThickness:2,
          }
        ]
      
      this.chart.valueAxes[0].guides = threshold;
    }
  };

  buildChart = () => {
    this.chart = new window.AmCharts.AmSerialChart();
    this.chart.categoryField = 'title';
    this.chart.fontFamily = 'lato';
    this.chart.addClassNames = true;
    this.chart.fontSize = 14;
    this.enableExport(this.props.exportTitle);

    if (this.props.lineConfig.responsive) {
      this.chart.responsive = {
        enabled: true,
        rules: [
          { minHeight: 200, overrides: { legend: { enabled: this.props.lineConfig.legendEnabled } } },
          { maxWidth: 400, maxHeight: 400, overrides: { valueAxes: { labelsEnabled: false } } },
          { maxWidth: 400, overrides: { categoryAxis: { labelsEnabled: false } } },
        ],
      };
    }

    const meta = this.props.chartData.meta;

    if (this.props.lineConfig.legendEnabled) {
      this.chart.legend = {
        enabled: _.isUndefined(this.props.legendPosition) || this.props.legendPosition !== 'none',
        position: this.props.getLegendPosition(),
        autoMargins: false,
        borderAlpha: 0,
        equalWidths: !_.isUndefined(this.props.chartData.meta.legendEqualWidths) ? this.props.chartData.meta.legendEqualWidths : false,
        markerSize: 10,
        valueAlign: 'left',
        valueWidth: 0,
        maxColumns: meta.legendEqualWidths ? Math.floor(meta.items.length / 2) : 6,
        divId: `${this.props.chartKey}Legend`,
      };
    }

    const valueAxis = new window.AmCharts.ValueAxis();
    valueAxis.axisAlpha = 0.2;
    valueAxis.dashLength = 1;
    valueAxis.position = 'left';
    if (this.props.stacked) {
      valueAxis.stackType = 'regular';
    }

    if (this.props.unit === 'minutes') {
      valueAxis.labelFunction = (seconds) => {
        const minutes = Utils.numberWithCommas(Math.floor(seconds / 60));
        return minutes;
      };
    }

    this.chart.addValueAxis(valueAxis);

    this.chart.chartCursor = _.isUndefined(this.props.chartData.meta.showChartCursor) || this.props.chartData.meta.showChartCursor ? { cursorColor: '#3D85D1' } : undefined;
    this.chart.categoryField = this.props.chartData.meta.categoryField;
    this.chart.pathToImages = '/amcharts/images/';
    this.chart.useGraphSettings = true;
    
    this.graphData();
    this.chart.write(this.props.chartKey);
  };

  periodLabels = (valueText, data) => `Day ${data.dataContext.day_in_date_range + 1}`;

  render() {
    const style = {};
    if (this.props.height) {
      style.height = this.props.height;
    }

    return (
      <div
        className="linechart"
        id={this.props.chartKey}
        style={style}
      />
    );
  }
}

export default BaseChartMixin(LineChart);
