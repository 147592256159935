const Period = function (ui) {
  this.ui = ui;
  this.params = { ui };
  const params = this.params;

  this.dailySummary = () => {
    params.rand = this.dailyRand;
    params.tableLabels = ['Date Range', 'Call Type'];
    params.xGrouping = this.generateDates();
    params.selects = this.getSelects(0);
    params.alphaGraphs = true;
  };
};

export default Period;
