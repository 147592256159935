import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { truncate } from 'lodash';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import AppData from 'app_data';
import Button from 'components/button';
import DataTable from 'components/data_table';
import { VideoPopup } from 'components';
import vex from 'lib/vex';

import Question from './questions_create';
import QuestionResponses from './responses';


class Questions extends Component {
  static propTypes = {
    push: PropTypes.func,
    match: PropTypes.object,
    location: PropTypes.object,
  };

  state = {
    rows: null,
  };

  componentDidMount() {
    this.fetchQuestionGroups();
  }

  componentWillReceiveProps(nextProps) {
    const { match } = nextProps;
    const { questionId } = match.params;

    if (!questionId) {
      this.fetchQuestionGroups();
    }
  }

  fetchQuestionGroups = () => {
    AppData.getQuestionGroups().then((response) => {
      const { data } = response;
      this.setState({ rows: data });
    });
  };

  handleAction = (obj, action) => {
    switch (action.name) {
      case 'Edit':
        this.props.push(`/settings/call-rating/${obj.id}`);
        break;
      case 'Delete': {
        const message = `Are you sure you want to delete ${obj.title}?`;
        vex.warning(message,
          (confirmed) => {
            if (confirmed) {
              AppData.deleteQuestionGroup(obj.id)
                .then(() => {
                  window.location.reload();
                })
                .catch((error) => {
                  if (error.response && error.response.data.message) {
                    vex.error([], null, error.response.data.message);
                  }
                });
            }
          }
        );
        break;
      }
      default:
        return;

    }
  };

  handleNew = () => {
    this.props.push('/settings/call-rating/new');
  };

  render() {
    const { match, location } = this.props;
    const { questionId } = match.params;
    const isNewQuestion = questionId === 'new';

    if (location.pathname.includes('responses')) {
      return (
        <QuestionResponses questionId={questionId} />
      );
    }

    if (isNewQuestion || questionId) {
      return (
        <Question questionId={isNewQuestion ? null : questionId} />
      );
    }

    const headers = [
      { label: 'Group Name', key: 'title', labelFunc: (value) => truncate(value, { length: 30 }), tooltip: true },
      { label: 'Description', key: 'description', labelFunc: (value) => truncate(value, { length: 30 }), tooltip: true },
      { label: '# of Questions', key: 'questions', labelFunc: (value) => `${value.length} ${value.length > 1 ? 'questions' : 'question'}` },
    ];
    const actions = [
      { name: 'Edit' },
      { name: 'Delete' },
    ];
    const content = (
      <DataTable
        headers={headers}
        rows={this.state.rows}
        actions={actions}
        onAction={this.handleAction}
      />
    );

    return (
      <div className="questions-list">
        <div className="questions-list__header title">
          <h1>
            Manage call rating question groups
            <VideoPopup
              videoUrl="https://nextiva-1.wistia.com/medias/5ijgx7pj1c"
            />
          </h1>
          <Button className="btn btn-green questions-list__new-question" onClick={this.handleNew}>
            New Question Group +
          </Button>
        </div>
        <div className="questions-list__content">
          {content}
        </div>
      </div>
    );
  }
}


export default connect(
  null,
  { push },
)(Questions);
