import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { flow, sortBy, reverse } from 'lodash/fp';
import ReactTimeout from 'react-timeout';
import { DefaultSpinner } from 'components';

import { LeaderboardTableRow } from 'modules/monitoring/gamification/leaderboard_table_original';
import AppData from 'app_data';
import { calculateTrend } from 'modules/monitoring/gamification/gamification_mixins';

class LeaderboardTable extends Component {
  static propTypes = {
    realTime: PropTypes.bool.isRequired,
    displayType: PropTypes.string.isRequired,
    callType: PropTypes.object.isRequired,
    filterType: PropTypes.object.isRequired,
    filters: PropTypes.array.isRequired,
    timeRange: PropTypes.object.isRequired,
    nBuckets: PropTypes.number.isRequired,
    grouping: PropTypes.bool.isRequired,
  };

  state = {
    trendLabels: null,
    intervalID: null,
  };

  componentDidMount() {
    this.fetchData(null, this.props.displayType);
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.realTime) clearInterval(this.state.invervalID);
    this.fetchData(nextProps, nextProps.displayType);
  }

  getMetric = (displayType, callType) => {
    if (displayType === 'calls') {
      return callType;
    }
    if (callType.metric.includes('_duration')) {
      return callType;
    }
    return { label: callType.label, metric: `${callType.metric}_duration` };
  };

  fetchData = (nextProps, displayType) => {
    this.setState({
      loading: true,
      displayType,
      trendLabels: null,
    });

    const props = nextProps || this.props;
    const metric = this.getMetric(displayType, props.callType);

    const params = {
      time_range: props.timeRange,
      metric,
      n_buckets: props.nBuckets,
      bucket_type: _.snakeCase(props.filterType.id),
      filters: { [_.snakeCase(props.filterType.id)]: _.map(props.filters, 'id') },
      grouping: props.grouping,
    };

    const onSuccess = (response, tick) => {
      const { data } = response;
      const newData = flow(
        sortBy((x) => x.label),
        sortBy((x) => x.value),
        reverse
      )(data.top_n_buckets);

      this.setState({
        loading: false,
        data: newData,
        trendLabels: tick > 0 ? calculateTrend(this.state.data, newData, this.state.trendLabels) : null,
      });
    };

    let tick = 0;
    if (props.realTime) {
      delete params.end;
      AppData.getGamificationTopNByType(params).then((response) => {
        onSuccess(response, tick);
      });
      const intervalID = setInterval(() => {
        tick++;
        AppData.getGamificationTopNByType(params).then((response) => {
          onSuccess(response, tick);
        });
      }, AppData.user.refresh_rate * 1000);
      this.setState({ intervalID: intervalID });
    } else {
      AppData.getGamificationTopNByType(params).then(onSuccess);
    }
  };

  handleModeChange = (displayType) => {
    this.fetchData(null, displayType);
  };

  render() {
    if (this.state.loading) {
      return (
        <DefaultSpinner />
      );
    }
    const items = _.map(this.state.data, (item, index) => (
      <LeaderboardTableRow
        key={index}
        index={index}
        arrow={this.state.trendLabels && this.state.trendLabels[index]}
        {...item}
      />
    ));

    const displayLabel = this.props.displayType === 'calls' ? 'Calls' : 'Talk Time';

    return (
      <table className="leaderboard-table">
        <thead>
          <tr>
            <th>Rank</th>
            <th>User</th>
            <th>{displayLabel}</th>
          </tr>
        </thead>
        <tbody>
          {items}
        </tbody>
      </table>
    );
  }
}

export default ReactTimeout(LeaderboardTable);
