import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import AppData from 'app_data';
import Utils from 'utils/common_utils';
import AnalyticsComparisionPanelItem from 'components/analytics_panels/analytics_comparison_panel_item';

class AnalyticsComparisionPanels extends Component {
  static propTypes = {
    /** Handler for clicking a panel */
    onPanelClick: PropTypes.func,
    /** The selected panel */
    selected: PropTypes.object,
    /** Array of the panel items */
    panelItems: PropTypes.array,
    /** Class name for the component div */
    className: PropTypes.string,
    /** Whether or not to parse the dates [Use (true) for period over period] */
    parseDates: PropTypes.bool,
    /** The time period being represented by the panels */
    timeRange: PropTypes.object,
    /** An array of the time ranges for a period over period chart */
    timeRanges: PropTypes.array,
    /** The filtering options for which panels are shown */
    filters: PropTypes.object,
    /** Whether or not to hide the units on the panel */
    hideUnit: PropTypes.bool,
  };

  static defaultProps = {
    parseDates: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      panelItems: [],
    };
  }

  componentDidMount() {
    this.updateData(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.updateData(nextProps);
  }

  updateSelected = (index) => {
    this.props.onPanelClick(this.props.panelItems[index]);
  }

  updateData = (props) => {
    const selects = _.map(props.panelItems, (i) => _.omit(i, 'type', 'id'));

    if (props.parseDates) {
      const params = {
        selects,
        time_ranges: [
          Utils.getStartEndDates(props.timeRanges[0]),
          Utils.getStartEndDates(props.timeRanges[1]),
        ],
        filters: props.filters,
      };

      AppData.getPeriodPanelData(params)
        .then((response) => {
          const { data } = response;
          const items = _.cloneDeep(props.panelItems);

          _.each(items, (item) => {
            _.extend(item, data[item.label]);
          });

          _.each(items, (item) => {
            if (item.type === 'Min') {
              item.hideUnit = true;
              _.each(item.items, (i) => {
                i.total = Utils.humanizeSeconds(i.total);
              });
            }

            if (item.type === 'Calls') {
              _.each(item.items, (i) => {
                i.total = Utils.numberWithCommas(i.total);
              });
            }
          });

          this.setState({ panelItems: items });
        });
    } else {
      const params = {
        selects,
        time_range: props.timeRange,
        filters: props.filters,
      };

      AppData.getComparisonPanelData(params)
        .then((response) => {
          const { data } = response;
          const items = _.cloneDeep(props.panelItems);

          _.each(items, (item) => {
            if(_.has(data, item.label)) {
              _.extend(item, data[item.label]);
            }
          });

          _.each(items, (item) => {
            if (item.type === 'Min') {
              item.type = '';
              _.each(item.items, (itemTotal) => {
                itemTotal.total = Utils.humanizeSeconds(itemTotal.total);
              });
            }

            if (item.type === 'Calls') {
              _.each(item.items, (itemTotal) => {
                itemTotal.total = Utils.numberWithCommas(itemTotal.total);
              });
            }
          });

          this.setState({ panelItems: items });
        });
    }
  }

  render() {
    const len = this.state.panelItems.length;
    const borderIndex = len - (len % 3 !== 0 ? len % 3 : 3);

    const items = _.map(this.state.panelItems, (item, idx) => {
      const isSelected = this.props.selected && (this.props.selected.label === item.label);
      return (
        <AnalyticsComparisionPanelItem
          key={idx}
          valueItems={item.items}
          label={item.label}
          percent={item.percent}
          type={item.type}
          itemKey={idx}
          updateSelected={this.updateSelected}
          selected={isSelected}
          hideUnit={item.hideUnit}
          hideBorder={idx >= borderIndex}
        />
      );
    });

    return (
      <div className="AnalyticsComparisionPanels">
        <div className={this.props.className}>
          <div className="row">
            <div className="col-full">
              <div className="panel-box">
                {items}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AnalyticsComparisionPanels;
