import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import cx from 'classnames';

import AppData from 'app_data';
import Utils from 'utils/common_utils';
import inbound from 'img/inbound.png';
import outbound from 'img/outbound.png';
import missed from 'img/missed.png';
import InlinveSvgFactory from 'components/inline_svg';

const Arrow = InlinveSvgFactory('arrow.svg');

const directionIcons = {
  outbound,
  inbound,
  missed,
};

const CallLog = props => {
  const dataSource = props.data;
  const visibleHeaders = _.filter(dataSource.headers, h => !h.hidden);

  const headers = _.map(visibleHeaders, (header, index) => {
    let rowClickHandler;
    const thProps = {
      key: index,
    };

    if (header.sortable) {
      thProps.className = cx({
        'call-log-sortable-header': true,
        'arrow-down': true,
        'th-bold': props.sortBy === header.id,
      });

      if (props.onHandleSort) {
        rowClickHandler = _.partial(props.onHandleSort, header.id);
        thProps.onClick = rowClickHandler;
      }
    }

    const th = (
      <th {...thProps}>
        {header.label || ''}
        {header.sortable ? (
          <Arrow
            className={cx({
              'call-log-arrow': true,
              'arrow-up': props.sortBy === header.id && props.sortDir === 'asc',
            })}
          />
        ) : null}
      </th>
    );

    return th;
  });

  const rows = _.map(dataSource.rows, (row, trIndex) => {
    const tds = _.map(visibleHeaders, (header, tdIndex) => {
      let td = row[header.id];
      if (header.id === 'direction_icon') {
        return (
          <TdItem key={tdIndex}>
            <img src={directionIcons[td]} />
          </TdItem>
        );
      }

      const call_recording = row.call_recording_result;
      // Call Recording Icon
      if (
        header.id === 'recording_icon' &&
        call_recording &&
        call_recording === 'successful'
      ) {
        return (
          <TdItem
            index={trIndex}
            key={tdIndex}
            className="icon-custom-volume-high"
            onClick={props.handleClickCallRecording}
          />
        );
      }

      if (header.duration) {
        td = Utils.humanizeSeconds(td);
      }

      return <TdItem key={tdIndex}>{td}</TdItem>;
    });

    return <tr key={trIndex}>{tds}</tr>;
  });

  let className = '';
  if (dataSource.table_class) {
    className = dataSource.table_class;
  }
  if (props.className) {
    className += ` ${props.className}`;
  }

  return (
    <table className={className}>
      <thead>
        <tr>{headers}</tr>
      </thead>
      <tbody>{rows}</tbody>
    </table>
  );
};

CallLog.propTypes = {
  /** The className of the call log table */
  className: PropTypes.string,
  /** The data for the call log table */
  data: PropTypes.object,
  /** Handler for clicking the call log table */
  onClick: PropTypes.func,
  /** Handler for the sort on the call log table */
  onHandleSort: PropTypes.func,
  /** What the table is sorting by (header) */
  sortBy: PropTypes.string,
  /** What direction to the table is sorting in (ascending, descending)*/
  sortDir: PropTypes.string,
};

class TdItem extends React.PureComponent {
  handleClick = e => {
    this.props.onClick(e, this.props.index);
  };

  render() {
    const { onClick, className, children } = this.props;
    return (
      <td onClick={onClick && this.handleClick} className={className}>
        {children}
      </td>
    );
  }
}

TdItem.propTypes = {
  index: PropTypes.number,
  className: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.any,
};

export default CallLog;
