import axios from 'axios';
import Constants from 'constants.js';
import AppData from 'app_data';

const UserActions = {
  loadUser(userId) {
    return (dispatch, getState) => {
      AppData.getUser(userId).then((response) => {
        dispatch({ type: Constants.USER_LOAD_USER });
      });
    };
  },
  storeUser(user) {
    return (dispatch, getState) => {
      dispatch({ type: Constants.USER_STORE, user });
    };
  },
  saveAvatar(userId, img) {
    return (dispatch, getState) => {
      const params = {
        user_name: userId,
        img,
      };
      return axios.all([AppData.saveProfileImage(params), AppData.loadAvatars()]);
    };
  },
  clear() {
    return { type: Constants.USER_CLEAR };
  },
};

export default UserActions;
