import React from 'react';
import PropTypes from 'prop-types';
import ReportPageChart from 'components/charts/report_page_chart';
import _ from 'lodash';


class QualityAdoption extends React.Component {
  static propTypes = {
    actions: PropTypes.object,
    state: PropTypes.object,
  };

  shouldComponentUpdate(nextProps, nextState) {
    const propsChanged = !_.isEqual(nextProps, this.props);
    const stateChanged = !_.isEqual(nextState, this.state);

    return propsChanged || stateChanged;
  }

  render() {
    const { state, actions } = this.props;
    const chartData = [
      {
         charts: [
          {icon: "pie.svg", name: "pie", type: "pie"}
        ],
        detail_period_options: [{id: "summary", label: "summary"}],
        label: "Users",
        size: "half",
        pieLabelRadius:7,
        units: [],
        legends:['Active', 'Inactive'],
        unitSelects: {"mos": [
          {label: "Good", metric: "good"},
          {label: "Moderate", metric: "moderate"},
          {label: "Poor", metric: "poor"},
        ]},
        url: "/nextos/reports/quality/user-counts"
      },
      {
        charts: [
            {icon: "bar.svg", name: "stacked-column", type: "bar"}
          ],
        detail_period_options: [{id: "summary", label: "summary"}],
        label: "Endpoints by OS",
        size: "half",
        pieLabelRadius:7,
        units: [],
        unitSelects: {"mos": [
          {label: "Good", metric: "good"},
          {label: "Moderate", metric: "moderate"},
          {label: "Poor", metric: "poor"},
          ]},
          url: "/nextos/reports/quality/usage/device-os"
      },
      {
        charts: [
          {icon: "bar.svg", name: "stacked-column", type: "bar"}
        ],
        detail_period_options: [{id: "summary", label: "summary"}],
        label: "Total Count Endpoints",
        size: "full",
        units: ["calls", "minutes"],
        unitSelects: {"mos": [
          {label: "Good", metric: "good"},
          {label: "Moderate", metric: "moderate"},
          {label: "Poor", metric: "poor"},
          ]},
          url: "/nextos/reports/quality/usage/counts"
      },
      {
        charts: [
          {icon: "bar.svg", name: "stacked-column", type: "bar"}
        ],
        detail_period_options: [{id: "summary", label: "summary"}],
        label: "Endpoints by Device and Version",
        size: "full",
        pieLabelRadius:7,
        units: [],
        unitSelects: {"mos": [
          {label: "Good", metric: "good"},
          {label: "Moderate", metric: "moderate"},
          {label: "Poor", metric: "poor"},
          ]},
          url: "/nextos/reports/quality/usage/device-version"
      },
    ];

    let charts = null;
    /// wait for any filters to clear - see quality_summary.js for longer explanation.
    if((_.isEmpty(state.apiFilters) === true) && (state.section === 'adoption-and-usage')) {
      charts = chartData.map(function(chart, index) {
        return (
          <div key={index} className={`col-${chart.size}`}>
            <ReportPageChart
              timeout={0}
              charts={chart.charts}
              url={chart.url}
              units={chart.units}
              unitSelects={chart.unitSelects}
              title={chart.label}
              timeRange={state.timeRange}
              realTime={state.realTime}
              popoutButton
              detailPeriodOptions={chart.detail_period_options}
              legends={chart.legends && chart.legends}
            />
          </div>
        );
      });
    }

    return (
      <div className="analytics">
        <div className="reports">
          {charts}
        </div>
      </div>
    );
  }
}

export default QualityAdoption;
