import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ReactTimeout from 'react-timeout';
import { DefaultSpinner } from 'components';

import design from 'img/scorecards_design.png';
import AppData from 'app_data';
import ReportTable from 'components/report_table';
import ScorecardControls from 'components/scorecard_controls';
import { ControlsActions } from 'actions/controls_actions';

const mapStateToProps = (state) => ({
  controlsState: state.controls.scorecards || {},
});

const mapDispatchToProps = (dispatch) => ({
  controlsActions: bindActionCreators(ControlsActions, dispatch),
});

class ScorecardContainer extends Component {
  static propTypes = {
    controlsState: PropTypes.object,
    controlsActions: PropTypes.object,
  };

  componentWillMount() {
    window.addEventListener('refresh', this.storeDidChange);
    this.props.controlsActions.getMonitoringMetadata('scorecards');
  }

  componentWillUnmount() {
    window.removeEventListener('refresh', this.storeDidChange);
    this.props.controlsActions.setInactive('scorecards');
  }

  render() {
    const { controlsState } = this.props;

    if (!controlsState.ready) {
      return <DefaultSpinner />;
    }

    return (
      <>
        <ScorecardControls />
        <Scorecards
          realTime={controlsState.realTime}
          filterType={controlsState.filterType}
          filters={controlsState.filters}
          timeRange={controlsState.timeRange}
          period={controlsState.period}
          grouping={controlsState.grouping}
        />
      </>
    );
  }
}

class ScorecardsComponent extends Component {
  static propTypes = {
    realTime: PropTypes.bool.isRequired,
    filterType: PropTypes.object.isRequired,
    filters: PropTypes.array.isRequired,
    timeRange: PropTypes.object.isRequired,
    period: PropTypes.object.isRequired,
  };

  state = {
    data: null,
    loading: true,
    intervalID: null,
  };

  componentDidMount() {
    this.fetchData();
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.realTime) clearInterval(this.state.intervalID);
    this.setState({
      loading: true,
    }, () => {
      this.fetchData(nextProps);
    });
  }

  classMap = {
    'totals': 'scorecard-totals',
    'inbound calls': 'scorecard-inbound',
    'inbound': 'scorecard-inbound',
    'outbound calls': 'scorecard-outbound',
    'distributed calls': 'scorecard-outbound',
    'outbound': 'scorecard-outbound',
    'average talk time': 'scorecard-average-talk',
    'unique call ratio': 'scorecard-unique-call-ratio',
    'unique dials': 'scorecard-unique-dials',
    'missed': 'scorecard-missed',
    'longest duration': 'scorecard-longest-duration',
  }

  fetchData = (props) => {
    this.setState({ loading: true });
    props = props || this.props;

    const queryParams = {
      filters: { [_.snakeCase(props.filterType.id)]: _.map(props.filters, 'id') },
      time_range: props.timeRange,
      group_type: _.snakeCase(props.filterType.id),
      period: props.period.id,
      grouping: props.grouping,
    };

    const onSuccess = (response) => {
      const { data } = response;
      this.setState({ data: data.results, loading: false });
    };

    if (props.realTime) {
      AppData.getScorecards(queryParams).then(onSuccess);
      const intervalID = setInterval(() => {
        AppData.getScorecards(queryParams).then(onSuccess);
      }, AppData.user.refresh_rate * 1000);
      this.setState({ intervalID: intervalID });
    } else {
      clearInterval(this.state.intervalID);
      AppData.getScorecards(queryParams).then(onSuccess);
    }
  };

  render() {
    let tables;

    if (!this.state.loading) {
      let hasData = false;
      tables = _.map(this.state.data, (data, index) => {
        if (data.data.length > 0) {
          hasData = true;
        }
        const tableClass = this.classMap[data.meta.tableLabels[1].toLowerCase()];
        const className = `table-container scorecards-table ${tableClass}`;
        return (
          <div key={index} className={className}>
            <ReportTable
              className="standard-table custom-table clearfix"
              autoSize
              headerWidth={235}
              data={data}
              maxRows={6}
            />
          </div>
        );
      });
      if (!hasData) {
        return (<div className="monitoring-no-data">No data with these options.</div>);
      }
    } else {
      tables = <DefaultSpinner />;
    }

    return <div>{tables}</div>;
  }
}
const Scorecards = ReactTimeout(ScorecardsComponent);

export default connect(mapStateToProps, mapDispatchToProps)(ScorecardContainer);
