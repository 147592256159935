import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import cx from 'classnames';

const MORE = ' ...more';
const LESS = ' ...less';

class ShowMore extends Component {
  static propTypes = {
    /** The text body */
    textBody: PropTypes.string,
    /** Classname for when the text body is collapsed and showing less */
    classNameLess: PropTypes.string,
    /** Classname for when the text body is not collapsed and showing more */
    classNameMore: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = {
      isCollapsed: true,
      toggleTitle: MORE,
      isOverflowed: true,
    };
  }

  componentDidMount() {
    this.setState({
      isOverflowed: this.node && this.isOverflowed(this.node),
    });
  }

  componentWillReceiveProps(nextProps) {
    if (_.isEqual(nextProps, this.props)) {
      return;
    }
    this.setState({
      isCollapsed: true,
      toggleTitle: MORE,
      isOverflowed: this.node && this.isOverflowed(this.node),
    });
  }

  toggleCollapse = () => {
    if (this.state.isOverflowed) {
      this.setState({
        isCollapsed: !this.state.isCollapsed,
        toggleTitle: (!this.state.isCollapsed ? MORE : LESS),
      });
    }
  }

  isOverflowed = (element) => (
    element.scrollHeight > element.clientHeight
  )

  render() {
    const { isCollapsed, toggleTitle, isOverflowed } = this.state;
    const { classNameLess, classNameMore, textBody } = this.props;

    const showMoreClass = cx({
      [classNameLess]: isCollapsed,
      [classNameMore]: !isCollapsed,
    });

    const isCollapsedClass = cx({
      'collapsed': isCollapsed && isOverflowed,
      'not-collapsed': !isCollapsed || !isOverflowed,
    });

    return (
      <div onClick={this.toggleCollapse} className={showMoreClass}>
        <div
          ref={(node) => { this.node = node; }}
          className={isCollapsedClass}
        >
          <div dangerouslySetInnerHTML={{ __html: textBody }} />
        </div>
        {isOverflowed && <div className={'show-more'}>{toggleTitle}</div>}
      </div>
    );
  }
}

export default ShowMore;
